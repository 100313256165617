<template>
	<div>
		<footer class="footer">
	        © 2018 Ogéo Procurement Platform
	    </footer>
    </div>
</template>

<script>
	
	export default {
		name: "app-footer",
	}

</script>