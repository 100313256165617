 <template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Request For Proposal</h4>
                </div>
                <div class="card-body">
					<el-form :model="RFQDetails" class="demo-form-inline" :rules="rules" ref="ItemCreationForm">
                        
                        <div class="row">
                            
                            
                            
                            <div class="col-lg-12">
                                <div class="grid-content">
                                    <el-form-item label="" prop="ProjectId">
                                        <span slot="label"><b>Select Setup Name</b></span>
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" @change="RFQDetails.RequestType = '', RFQDetails.RFQType = ''" v-model="RFQDetails.ProjectId" placeholder="Select Setup Name">
                                            
                                            <el-option v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            
                            
                            <div class="col-lg-12">
                                <div class="grid-content">
                                    <el-form-item label="" prop="RFQType">
                                        <span slot="label"><b>Select Request for Proposals Type</b></span>
                                        
                                        <el-select style="width: 100%; padding: 0px;" v-model="RFQDetails.RFQType" @change="FilterRequestForProposalsList = '', OrigRequestForProposalsList = '', RequestForProposalsList = '', RFQDetails.RequestType = ''" placeholder="Select Request for Proposals Type">
                                            <el-option label="Materials" value="Materials"></el-option>  
                                            <el-option label="Service" value="Service"></el-option> 
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="grid-content">
                                    <el-form-item label="" prop="RequestType">
                                        <span slot="label"><b>Request For Proposal Category</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><strong>Requests For Proposals</strong> can be raised to secure proposals from vendors for either Purchase Enquiries or for Rate Contracts (Volume Discount Goods Supply Master Agreement)</span>
                                            </span>
                                        </span>
                                        <el-select style="width: 100%; padding: 0px;" @change="GetPEsRCsListFromProject(RFQDetails.ProjectId)" v-model="RFQDetails.RequestType" placeholder="Select Request for Proposals Category">
                                            <el-option v-if="RFQDetails.RFQType == 'Materials'" label="Purchase Enquiries For Materials" value="PE"></el-option>  
                                            <el-option v-if="RFQDetails.RFQType == 'Materials'" label="Rates’ Contracts For Materials" value="RC"></el-option> 
                                            <el-option v-if="RFQDetails.RFQType == 'Service'" label="Purchase Enquiries For Services" value="PE"></el-option>  
                                            <el-option v-if="RFQDetails.RFQType == 'Service'" label="Rates’ Contracts For Services" value="RC"></el-option> 
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            

                            <div class="col-lg-12" v-if="RFQDetails.RFQType && RFQDetails.RequestType && !RfPRecordsLoading">
                                <div class="grid-content">


                                    <hr v-if="RFQDetails.RFQType && RFQDetails.RequestType">
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE' && ListCounter.ItemFromLOM">There are ( {{ ListCounter.ItemFromLOM }} ) queued Purchase Enquiry lines for items from the Library of Materials.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE' && !ListCounter.ItemFromLOM">There are no Purchase Enquiries lines for items from the Library of Materials, queued for Requests for Proposals.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE' && ListCounter.ItemNotLOM">There are ( {{ ListCounter.ItemNotLOM }} ) queued Purchase Enquiry lines for items with a free-text description.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE' && !ListCounter.ItemNotLOM">There are no Purchase Enquiries lines for items with a free-text description, queued for Requests for Proposals.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC' && ListCounter.RCFromLOM">There are ( {{ ListCounter.RCFromLOM }} ) of items from the Library of Materials for which Rates’ Contracts are needed.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC' && !ListCounter.RCFromLOM">There are no items from the Library of Materials, queued for Rates’ Contracts.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC' && ListCounter.RCFromValidRC">There are ( {{ListCounter.RCFromValidRC}} ) of items from the Library of Materials for which Rates’ Contracts are needed that are without valid Rates’ Contract.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC' && !ListCounter.RCFromValidRC">There are no items from the Library of Materials queued for Rates’ Contracts, with existing valid Rates’ Contracts.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE' && ListCounter.ServicesPE">There are ( {{ ListCounter.ServicesPE }} ) queued Purchase Enquiries for Services.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE' && !ListCounter.ServicesPE">There are no queued Purchase Enquiries Services.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'RC' && ListCounter.ServicesRC">There are ( {{ ListCounter.ServicesRC }} ) queued Rates’ Contract Creation Requests Lines for Services.</h6>
                                        <h6 class="text-ogeo" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'RC' && !ListCounter.ServicesRC">There are no queued Rates’ Contract Creation Requests Lines for Services.</h6>
                                    

                                </div>
                            </div>

                            <div class="col-lg-12" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType && !RfPRecordsLoading && (ListCounter.ItemFromLOM > 0 || ListCounter.RCFromLOM > 0)">
                                <div class="grid-content">
                                    <el-form-item label="" prop="OgeoAuto">
                                        <span slot="label"><b>How would you like to proceed?</b></span>
                                        <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><strong>Requests For Proposals</strong> can be raised to secure proposals from vendors for either Purchase Enquiries or for Rate Contracts (Volume Discount Goods Supply Master Agreement)</span>
                                            </span>
                                        </span> -->
                                        <el-select style="width: 100%; padding: 0px;" v-model="RFQDetails.OgeoAuto" placeholder="Select Request for Proposals Category">
                                            <el-option label="Manually create Requests for Proposals for Materials Purchase Enquiries" value="No"></el-option>  
                                            <el-option label="Let Ogéo suggest Requests for Proposals for Materials Purchase Enquiries" value="Yes" disabled></el-option>
                                        </el-select> 
                                    </el-form-item>
                                </div>
                            </div>
                            
                        </div>


                      


                        <div class="row" >
                            <div class="col-lg-12">

                                <hr v-if="RFQDetails.RFQType && RFQDetails.RequestType">

                                <div class="col-lg-12 p-l-0 p-r-0 p-t-10 b-all" id="PEMatSelCol" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE' && RFQDetails.OgeoAuto == 'No'" style="display: none; min-height: 275px; max-height: 600px; overflow-y: auto; overflow-x: hidden;">

                                    <!-- Show Loader -->
                                    <div align="center" v-if="RfPRecordsLoading">
                                        <h2>Gathering Data...</h2>
                                        <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                    </div>

                                    <div align="center" v-else-if="RequestForProposalsList.length == 0">
                                        <h2>No Purchase Enquiries Available</h2>
                                    </div>
                                
                                    <div class="row" v-else>
                                        
                                        <div style="display: flex; align-items: stretch; width: 95%" v-for="(PurchaseEnquiry, key) in RequestForProposalsList" class="col-lg-6 ">
                                          
                                            <div style="cursor: pointer; margin-bottom: 7px" @click="selectedItem(PurchaseEnquiry.id)" :id="'RfPEnquiry_Id_'+PurchaseEnquiry.id" class="card card-body">
                                                <div class="row">
                                                    <div class="col-lg-2 text-center">
                                                        <img v-if="PurchaseEnquiry.item.field_1 != '' && PurchaseEnquiry.item.field_1 != null || PurchaseEnquiry.enquiry_from_item_master == 'Yes'" :src="'/uploads/ItemMasterPictures/' + (!isJSON(PurchaseEnquiry.item.picture) ? PurchaseEnquiry.item.picture : JSON.parse(PurchaseEnquiry.item.picture)[0])" class="img-rounded img-responsive" @error="(event) => event.target.src='/uploads/Logos/'+CompanySetup.logo">
                                                        <img v-else :src="'/uploads/images/'+JSON.parse(PurchaseEnquiry.p_images)[0]" class="img-rounded img-responsive" @error="(event) => event.target.src='/uploads/Logos/'+CompanySetup.logo">


                                                    </div>
                                                    <div class="col-lg-4 p-l-0">
                                                        <h6 class="box-title m-b-0"><b>
                                                            <span v-if="PurchaseEnquiry.company.pe_prefix == ''">PE</span><span v-else>{{ PurchaseEnquiry.company.pe_prefix }}</span>-{{ PurchaseEnquiry.purchase_enquiry_group_id }}-{{ PurchaseEnquiry.purchase_enquiry_ingroup_id }}</b>
                                                        </h6><br>
                                                        <h6 class="box-title m-b-0"><b>Sourcing Priority:</b> {{ PurchaseEnquiry.sourcing_priority }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Quantity:</b> {{ formatPrice(PurchaseEnquiry.quantity) }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Unit Of Measurement:</b> {{ PurchaseEnquiry.u_o_m ? PurchaseEnquiry.u_o_m.toUpperCase() : PurchaseEnquiry.item.u_o_m.toUpperCase() }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Advanced Pymt Info:</b> {{ PurchaseEnquiry.advanced_payment == 'Yes' ? "Yes, " : "N/A" }}<span v-if="PurchaseEnquiry.advanced_payment == 'Yes'">{{ PurchaseEnquiry.advanced_payment_guarantee == 'Yes' ? "Guarantee is Required" : "Guarantee isn't Required" }}</span></h6>
                                                        <h6 class="box-title m-b-0"><b>Retention Pymt Info:</b> <span v-if="PurchaseEnquiry.retention_percentage > 0">{{ PurchaseEnquiry.retention_percentage }}% | {{ PurchaseEnquiry.retention_days }} Day(s) From The Date Of Full Delivery</span><span v-else>N/A</span></h6>
                                                        <h6 v-if="PurchaseEnquiry.status == 'Send for a New Request for Proposals'" class="box-title m-b-0 text-danger"><b>Re-Run</b></h6>
                                                        <hr>
                                                        <div class="btn btn-sm btn-block btn-primary" @click="showViewModal(PurchaseEnquiry.id, RFQDetails.RFQType, RFQDetails.RequestType)">Full Info</div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b v-if="PurchaseEnquiry.enquiry_from_item_master == 'No'">Item Description:</b><b v-else>LoM-{{ PurchaseEnquiry.item.item_group_id }}-{{ PurchaseEnquiry.item.item_ingroup_id }}</b></h6>
                                                        
                                                        <h6 v-if="PurchaseEnquiry.item.field_1 != '' && PurchaseEnquiry.item.field_1 != null">
                                                            <template v-for="index in 20">    
                                                                <span v-if="PurchaseEnquiry.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                                    <b style="font-weight: 700; font-family: sans-serif; text-transform: uppercase;">{{ PurchaseEnquiry.item.item_template['field_'+index] }}:</b> {{ PurchaseEnquiry.item['field_'+index] }} | 
                                                                </span>
                                                            </template>
                                                        </h6>
                                                        <span v-else>
                                                            {{ PurchaseEnquiry.item_description }}
                                                        </span>

                                                        <hr>
                                                        
                                                        <span>
                                                            <h6 class="box-title m-b-0"><b>Deliver To:</b></h6><h6>
                                                                <span><b class="font-700 font-italic">Country of Delivery:</b> {{ PurchaseEnquiry.country_of_delivery }}</span><br>
                                                                <span><b class="font-700 font-italic">City of Delivery:</b> {{ PurchaseEnquiry.cities_of_delivery }}</span><br>
                                                                <span><b class="font-700 font-italic">Location Name:</b> {{ PurchaseEnquiry.location_name }}</span><br>
                                                                <span><b class="font-700 font-italic">Longitude:</b> {{ PurchaseEnquiry.longitude }}</span><br>
                                                                <span><b class="font-700 font-italic">Latitude:</b> {{ PurchaseEnquiry.latitude }}</span><br>  
                                                            </h6>
                                                            <span style="float: right; cursor: pointer;" class="text-primary" @click="showMap(PurchaseEnquiry.latitude, PurchaseEnquiry.longitude, PurchaseEnquiry.id)">View in Map</span>
                                                        </span>
                                                        
                                                        
                                                        
                                                    
                                                        <div class="checkbox checkbox-success check_box_css">
                                                            <input :id="'checkbox_'+PurchaseEnquiry.id" type="checkbox" :value="'checked_'+PurchaseEnquiry.id">
                                                            <label :for="'checkbox'+PurchaseEnquiry.id"></label>
                                                         </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-12 p-l-0 p-r-0 p-t-10 b-all" id="PESerSelCol" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE'" style="display: none; min-height: 275px; max-height: 1000px; overflow-y: auto; overflow-x: hidden;">
                                    

                                    <!-- Show Loader -->
                                    <div align="center" v-if="RfPRecordsLoading">
                                        <h2>Gathering Data...</h2>
                                        <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                    </div>
                                    
                                    <div align="center" v-else-if="RequestForProposalsList.length == 0">
                                        <h2>No Purchase Enquiries Available</h2>
                                    </div>

                                    <div class="row" v-else>
                                        
                                        <div style="display: flex; align-items: stretch; width: 95%" v-for="(PurchaseEnquiry, key) in RequestForProposalsList" class="col-lg-6 ">
                                          
                                            <div style="cursor: pointer; margin-bottom: 7px" @click="selSerDesc = PurchaseEnquiry.service_description,selectedItem(PurchaseEnquiry.id)" :id="'RfPEnquiry_Id_'+PurchaseEnquiry.id" class="card card-body">
                                                <div class="row">
                                                    <!-- <div class="col-lg-2 text-center">
                                                        <img v-if="PurchaseEnquiry.enquiry_from_item_master == 'Yes'" :src="'/uploads/ItemMasterPictures/'+PurchaseEnquiry.item.picture" class="img-rounded img-responsive">
                                                        <img v-else :src="'/uploads/images/'+PurchaseEnquiry.p_images[0]" class="img-rounded img-responsive">


                                                    </div> -->
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b>
                                                            <span v-if="PurchaseEnquiry.company.pe_prefix == ''">PE</span><span v-else>{{ PurchaseEnquiry.company.pe_prefix }}</span>-{{ PurchaseEnquiry.purchase_enquiry_group_id }}-{{ PurchaseEnquiry.purchase_enquiry_ingroup_id }}</b>
                                                        </h6><br>
                                                        <h6 class="box-title m-b-0">
                                                            
                                                                <!-- <span v-if="PurchaseEnquiry.company.lom_prefix == ''"><b>LoM</b></span><span v-else><b>{{ PurchaseEnquiry.company.lom_prefix }}</b></span><span v-if="PurchaseEnquiry.item.item_group_id == 'N/A'">: Not Applicable</span>
                                                                    <span v-else>
                                                                        <b>-{{ PurchaseEnquiry.item.item_group_id }}-{{ PurchaseEnquiry.item.item_ingroup_id }}</b>
                                                                    </span> -->
                                                            
                                                        </h6>
                                                        <h6 class="box-title m-b-0"><b>Sourcing Priority:</b> {{ PurchaseEnquiry.sourcing_priority }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Service Term:</b> {{ PurchaseEnquiry.service_one_off !== 'One/Off' ? (PurchaseEnquiry.service_term ? PurchaseEnquiry.service_term + ' Day(s)' : 'N/A') : (PurchaseEnquiry.service_one_off ? PurchaseEnquiry.service_one_off : 'N/A') }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Advanced Pymt Info:</b> {{ PurchaseEnquiry.advanced_payment == 'Yes' ? "Yes, " : "N/A" }}<span v-if="PurchaseEnquiry.advanced_payment == 'Yes'">{{ PurchaseEnquiry.advanced_payment_guarantee == 'Yes' ? "Guarantee is Required" : "Guarantee isn't Required" }}</span></h6>
                                                        <h6 class="box-title m-b-0"><b>Retention Pymt Info:</b> <span v-if="PurchaseEnquiry.retention_percentage > 0">{{ PurchaseEnquiry.retention_percentage }}% | {{ PurchaseEnquiry.retention_days }} Day(s) From The Date Of Full Delivery</span><span v-else>N/A</span></h6>
                                                        <h6 class="box-title m-b-0"><b>Type of Services Contract:</b> {{ PurchaseEnquiry.type_of_services_contract ? PurchaseEnquiry.type_of_services_contract : 'N/A' }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Vendor Commercial Offer Content:</b> {{ PurchaseEnquiry.vendor_commercial_offer ? PurchaseEnquiry.vendor_commercial_offer : 'N/A' }}</h6>
                                                        <h6 v-if="PurchaseEnquiry.status == 'Send for a New Request for Proposals'" class="box-title m-b-0 text-danger"><b>Re-Run</b></h6>
                                                        <hr>
                                                        <div class="btn btn-sm btn-block btn-primary" @click="showViewModal(PurchaseEnquiry.id, RFQDetails.RFQType, RFQDetails.RequestType)">Full Info</div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b>Service Contract Description:</b></h6>
                                                        
                                                        <span>
                                                            {{ PurchaseEnquiry.service_description }}
                                                        </span><br><br>
                                                                                                                
                                                        <h6 class="box-title m-b-0" v-if="PurchaseEnquiry.vendoroffercontent"><b>Vendor Offer Content Headers:</b></h6>
                                                        
                                                        <span v-if="PurchaseEnquiry.vendoroffercontent">
                                                            {{ PurchaseEnquiry.vendoroffercontent.map(item => item.header_name).toString().replaceAll(',', ', ') }}
                                                        </span>
                                                        <h6 class="box-title m-b-0" v-if="PurchaseEnquiry.childheaders"><b>Service Contract Draft Headers:</b></h6>
                                                        
                                                        <span v-if="PurchaseEnquiry.childheaders">
                                                            {{ PurchaseEnquiry.childheaders.map(({header_name}) => header_name).toString().replaceAll(',', ', ') }}
                                                        </span>
                                                        <h6 class="box-title m-b-0" v-if="PurchaseEnquiry.tendererchildheaders"><b>Instruction To Vendors Headers:</b></h6>
                                                        
                                                        <span v-if="PurchaseEnquiry.tendererchildheaders">
                                                            {{ PurchaseEnquiry.tendererchildheaders.map(({header_name}) => header_name).toString().replaceAll(',', ', ') }}
                                                        </span>

                                                        <hr>
                                                        
                                                        <span>
                                                            <h6 class="box-title m-b-0"><b>Deliver To:</b></h6><h6>
                                                                <span><b class="font-700 font-italic">Country of Delivery:</b> {{ PurchaseEnquiry.country_of_delivery }}</span><br>
                                                                <span><b class="font-700 font-italic">City of Delivery:</b> {{ PurchaseEnquiry.cities_of_delivery }}</span><br>
                                                                <span><b class="font-700 font-italic">Location Name:</b> {{ PurchaseEnquiry.location_name }}</span><br>
                                                                <span><b class="font-700 font-italic">Longitude:</b> {{ PurchaseEnquiry.longitude }}</span><br>
                                                                <span><b class="font-700 font-italic">Latitude:</b> {{ PurchaseEnquiry.latitude }}</span><br>  
                                                            </h6>
                                                            <span style="float: right; cursor: pointer;" class="text-primary" @click="showMap(PurchaseEnquiry.latitude, PurchaseEnquiry.longitude, PurchaseEnquiry.id)">View in Map</span>
                                                        </span>
                                                        
                                                        
                                                        
                                                    
                                                        <div class="checkbox checkbox-success check_box_css">
                                                            <input :id="'checkbox_'+PurchaseEnquiry.id" type="checkbox" :value="'checked_'+PurchaseEnquiry.id">
                                                            <label :for="'checkbox'+PurchaseEnquiry.id"></label>
                                                         </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-12 p-l-0 p-r-0 p-t-10 b-all" id="RCMatSelCol" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC' && RFQDetails.OgeoAuto === 'No'" style="display: none; min-height: 275px; max-height: 600px; overflow-y: auto; overflow-x: hidden;">

                                    <!-- Show Loader -->
                                    <div align="center" v-if="RfPRecordsLoading">
                                        <h2>Gathering Data...</h2>
                                        <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                    </div>

                                    <div align="center" v-else-if="RequestForProposalsList.length == 0">
                                        <h2>No Rates' Contract Requests Available</h2>
                                    </div>

                                    <div class="row" v-else>
                                        
                                        <div style="display: flex; align-items: stretch; width: 95%" v-for="(RateContractRequest, key) in RequestForProposalsList" class="col-lg-6 ">
                                        
                                            <div style="cursor: pointer; margin-bottom: 7px" @click="selectedItem(RateContractRequest.id)" :id="'RfPEnquiry_Id_'+RateContractRequest.id" class="card card-body">
                                                <div class="row">
                                                    <div class="col-lg-2 text-center">
                                                        <img v-if="!isJSON(RateContractRequest.item.picture)" :src="'/uploads/ItemMasterPictures/'+RateContractRequest.item.picture" class="img-rounded img-responsive">
                                                        <img v-else :src="'/uploads/ItemMasterPictures/'+ JSON.parse(RateContractRequest.item.picture)[0]" class="img-rounded img-responsive">


                                                    </div>
                                                    <div class="col-lg-4 p-l-0">
                                                        <h6 class="box-title m-b-0"><b>
                                                            <span>RCCR</span>-{{ RateContractRequest.rate_contract_request_group_id }}-{{ RateContractRequest.rate_contract_request_ingroup_id }}</b>
                                                        </h6><br>
                                                        <h6 class="box-title m-b-0"><b>Sourcing Priority:</b> {{ RateContractRequest.sourcing_priority }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Expected Qty:</b> {{ formatPrice(RateContractRequest.quantity) }}</h6>
                                                        <h6 class="box-title m-b-0"><b>UoM:</b> {{ RateContractRequest.u_o_m ? RateContractRequest.u_o_m.toUpperCase() : RateContractRequest.item.u_o_m.toUpperCase() }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Term:</b> {{ RateContractRequest.term }} Months</h6>
                                                        <h6 class="box-title m-b-0"><b>Advanced Pymt Info:</b> {{ RateContractRequest.advanced_payment == 'Yes' ? "Yes, " : "N/A" }}<span v-if="RateContractRequest.advanced_payment == 'Yes'">{{ RateContractRequest.advanced_payment_guarantee == 'Yes' ? "Guarantee is Required" : "Guarantee isn't Required" }}</span></h6>
                                                        <h6 class="box-title m-b-0"><b>Retention Pymt Info:</b> <span v-if="RateContractRequest.retention_percentage > 0">{{ RateContractRequest.retention_percentage }}% | {{ RateContractRequest.retention_days }} Day(s) From The Date Of Full Delivery</span><span v-else>N/A</span></h6>
                                                        <h6 class="box-title m-b-0"><b>Vol. Disc. Clause:</b> {{ RateContractRequest.need_volume_disc ? RateContractRequest.need_volume_disc : 'N/A' }}</h6>
                                                        <h6 class="box-title m-b-0" v-if="RateContractRequest.need_volume_disc"><b>Min. Val To Trig. Unit Rates’ Discount:</b> <span v-if="RateContractRequest.minimal_procured_value > 0">{{ formatPrice(RateContractRequest.minimal_procured_value) }} {{ projectcurrency.toString() }}</span><span v-else>N/A</span></h6>
                                                        <h6 v-if="RateContractRequest.status == 'Send for a New Request for Proposals'" class="box-title m-b-0 text-danger"><b>Re-Run</b></h6>
                                                        <hr>
                                                        <div class="btn btn-sm btn-block btn-primary" @click="showViewModal(RateContractRequest.id, RFQDetails.RFQType, RFQDetails.RequestType)">Full Info</div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b v-if="RateContractRequest.enquiry_from_item_master == 'No'">Item Description:</b><b v-else>LoM-{{ RateContractRequest.item.item_group_id }}-{{ RateContractRequest.item.item_ingroup_id }}</b></h6>
                                                        
                                                        <span v-if="RateContractRequest.item.field_1 != '' && RateContractRequest.item.field_1 != null">
                                                            <template v-for="index in 20" style="text-overflow: ellipsis; overflow: hidden; max-height: 100px;">    
                                                                <span v-if="RateContractRequest.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                                    <b style="font-weight: 700; font-family: sans-serif; text-transform: uppercase;">{{ RateContractRequest.item.item_template['field_'+index] }}:</b> {{ RateContractRequest.item['field_'+index] }} | 
                                                                </span>
                                                            </template>
                                                        </span>
                                                        <span v-else>
                                                            {{ RateContractRequest.item_description }}
                                                        </span>

                                                        <hr>
                                                        
                                                        <span v-if="RateContractRequest.country">
                                                            <h6 class="box-title m-b-0"><b>Countries | Cities:</b></h6>
                                                            {{ RateContractRequest.country }} | {{ RateContractRequest.cities }}
                                                        </span>
                                                        
                                                        
                                                        
                                                    
                                                        <div class="checkbox checkbox-success check_box_css">
                                                            <input :id="'checkbox_'+RateContractRequest.id" type="checkbox" :value="'checked_'+RateContractRequest.id">
                                                            <label :for="'checkbox'+RateContractRequest.id"></label>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                
                                <div class="col-lg-12 p-l-0 p-r-0 p-t-10 b-all" id="RCSerSelCol" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'RC'" style="display: none; min-height: 275px; max-height: 1000px; overflow-y: auto; overflow-x: hidden;">
                                    

                                    <!-- Show Loader -->
                                    <div align="center" v-if="RfPRecordsLoading">
                                        <h2>Gathering Data...</h2>
                                        <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                    </div>
                                    
                                    <div align="center" v-else-if="RequestForProposalsList.length == 0">
                                        <h2>No Rates' Contract Requests Available</h2>
                                    </div>

                                    <div class="row" v-else>
                                        
                                        <div style="display: flex; align-items: stretch; width: 95%" v-for="(RateContractRequest, key) in RequestForProposalsList" class="col-lg-6 ">
                                          
                                            <div style="cursor: pointer; margin-bottom: 7px" @click="!RFQDetails.RfPEnquiries.length ? serRCCRGroup = RateContractRequest.rate_contract_request_group_id : '',selectedItem(RateContractRequest.id)" :id="'RfPEnquiry_Id_'+RateContractRequest.id" class="card card-body">
                                                <div class="row">
                                                    <!-- <div class="col-lg-2 text-center">
                                                        <img v-if="ViewModalInfo.item" :src="'/uploads/ItemMasterPictures/'+RateContractRequest.item.picture" class="img-rounded img-responsive">


                                                    </div> -->
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b>
                                                            <span>RCCR</span>-{{ RateContractRequest.rate_contract_request_group_id }}-{{ RateContractRequest.rate_contract_request_ingroup_id }}</b>
                                                        </h6><br>
                                                        <h6 class="box-title m-b-0">
                                                            
                                                                <!-- <span v-if="RateContractRequest.company.lom_prefix == ''"><b>LoM</b></span><span v-else><b>{{ RateContractRequest.company.lom_prefix }}</b></span><span v-if="RateContractRequest.item.item_group_id == 'N/A'">: Not Applicable</span>
                                                                    <span v-else>
                                                                        <b>-{{ RateContractRequest.item.item_group_id }}-{{ RateContractRequest.item.item_ingroup_id }}</b>
                                                                    </span> -->
                                                            
                                                        </h6>
                                                        <h6 class="box-title m-b-0"><b>Sourcing Priority:</b> {{ RateContractRequest.sourcing_priority }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Quantity:</b> {{ RateContractRequest.quantity }}</h6>
                                                        <h6 class="box-title m-b-0"><b>UoM:</b> {{ RateContractRequest.u_o_m.toUpperCase() }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Adv. Pymt:</b> {{ RateContractRequest.advanced_payment }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Type of Services Contract:</b> {{ RateContractRequest.type_of_service_contract ? RateContractRequest.type_of_service_contract : 'N/A' }}</h6>
                                                        <h6 class="box-title m-b-0"><b>Commercial Offer Content:</b> {{ RateContractRequest.vendor_commercial_offer ? RateContractRequest.vendor_commercial_offer : 'N/A' }}</h6>
                                                        <h6 v-if="RateContractRequest.status == 'Send for a New Request for Proposals'" class="box-title m-b-0 text-danger"><b>Re-Run</b></h6>
                                                        <hr>
                                                        <div class="btn btn-sm btn-block btn-primary" @click="showViewModal(RateContractRequest.id, RFQDetails.RFQType, RFQDetails.RequestType)">Full Info</div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <h6 class="box-title m-b-0"><b>Service Contract Description:</b></h6>
                                                        
                                                        <span>
                                                            {{ RateContractRequest.item_description }}
                                                        </span><br><br>

                                                        <h6 class="box-title m-b-0" v-if="RateContractRequest.vendor_offer_content"><b>Vendor Offer Content Headers:</b></h6>
                                                        
                                                        <span v-if="RateContractRequest.vendor_offer_content">
                                                            {{ RateContractRequest.vendor_offer_content.map(item => item.header_name).toString().replaceAll(',', ', ') }}
                                                        </span>
                                                        <h6 class="box-title m-b-0" v-if="RateContractRequest.service_contract_headers"><b>Service Contract Draft Headers:</b></h6>
                                                        
                                                        <span v-if="RateContractRequest.service_contract_headers">
                                                            {{ RateContractRequest.service_contract_headers.map(({header_name}) => header_name).toString().replaceAll(',', ', ') }}
                                                        </span>
                                                        <h6 class="box-title m-b-0" v-if="RateContractRequest.vendor_instruction_headers"><b>Instruction To Vendors Headers:</b></h6>
                                                        
                                                        <span v-if="RateContractRequest.vendor_instruction_headers">
                                                            {{ RateContractRequest.vendor_instruction_headers.map(({header_name}) => header_name).toString().replaceAll(',', ', ') }}
                                                        </span>


                                                        <hr>
                                                        
                                                        <span v-if="RateContractRequest.location_name != '' && RateContractRequest.location_name != null">
                                                            <h6 class="box-title m-b-0"><b>Deliver To:</b></h6>
                                                            {{ RateContractRequest.location_name }} - <span style="font-size: 12px; cursor: pointer;" class="text-primary" @click="showMap(RateContractRequest.latitude, RateContractRequest.longitude, RateContractRequest.id)"> Map</span>
                                                        </span>
                                                        
                                                        
                                                        
                                                    
                                                        <div class="checkbox checkbox-success check_box_css">
                                                            <input :id="'checkbox_'+RateContractRequest.id" type="checkbox" :value="'checked_'+RateContractRequest.id">
                                                            <label :for="'checkbox'+RateContractRequest.id"></label>
                                                         </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <!-- List Filter RFP -->
                            <div class="col-lg-12" v-if="RFQDetails.RFQType && RFQDetails.RequestType && !RfPRecordsLoading">
                                    <hr v-if="RFQDetails.RFQType && RFQDetails.RequestType">
                                    <a style="text-decoration: none; text-align: center; color: black;" data-toggle="modal" data-target="#FilterOpsModalView"><div class="card-header">
                                        <span class="text-center h5 text-ogeo"><b>Apply/Reset Filters To The Above List Of {{ RFQDetails.RFQType + ' ' + RFQDetails.RequestType.replace(/PE|RC/g, (text) => AbrRep[text] ) }} Lines?</b></span>
                                    </div></a>
                                    <div class="card w-100 mt-2" v-if="!checkOBJ(RFQFilter)">
                                        <div data-parent="#accordion">
                                            <div class="card-body">
                                                <h6 class="font-700">Applied Filters:</h6><br>
                                                <h6><span v-if="RFQFilter.Country.length">Country(ies): {{ RFQFilter.Country.join(', ') }}; </span><span v-if="RFQFilter.City.length">City(ies): {{ RFQFilter.City.join(', ') }}; </span><span v-if="RFQFilter.LoMDesc.length">LoM Description: {{ RFQFilter.LoMDesc.join(', ') }}; </span>
                                                    <span v-if="RFQFilter.LoMRC.length">LoM Rates' Contracts: {{ RFQFilter.LoMRC.join(', ') }}; </span><span v-if="RFQFilter.FreeText.length">Free-text Described Items: {{ RFQFilter.FreeText.join(', ') }}; </span><span v-if="RFQFilter.PERef.length">Purchase Enquiry Reference: {{ RFQFilter.PERef.join(', ') }}; </span>
                                                    <span v-if="RFQFilter.RCRef.length">Rates' Contracts Reference: {{ RFQFilter.RCRef.join(', ') }}; </span><span v-if="RFQFilter.AdvP.length">Advanced Payment Info: {{ RFQFilter.AdvP.join(', ').replace(/YY|YN|NA/g, (text) => AbrRep[text] ) }}; </span><span v-if="RFQFilter.RtP.length">Retention Payment Info: {{ RFQFilter.RtP.join(', ').replace(/ND|RP|NA/g, (text) => AbrRep[text] ) }}; </span>
                                                    <span v-if="RFQFilter.SP.length">Sourcing Priority: {{ RFQFilter.SP.join(', ') }}; </span><span v-if="RFQFilter.UTR.length">Underlying Transaction: {{ RFQFilter.UTR.join(', ') }}; </span><span v-if="RFQFilter.VDC">Volume Discount Clause: {{ RFQFilter.VDC }}; </span>
                                                    <span v-if="RFQFilter.MVVal">Minimum Value Required To Trigger The Item Unit Rates’ Discount: {{ RFQMVOpr.replace(/G|L/g, (text)=> AbrRep[text]) + RFQFilter.MVVal }}; </span><span v-if="RFQFilter.SerDesc">Service Description: {{ RFQFilter.SerDesc.join(', ') }}; </span><span v-if="RFQFilter.SerTyp">Type of Service Contract: {{ RFQFilter.SerTyp }}; </span> 
                                                    <span v-if="RFQFilter.COF">Commercial Offfer Format: {{ RFQFilter.COF }}; </span><span v-if="RFQFilter.VOC">Vendor Offer Content: {{ RFQFilter.VOC }}; </span><span v-if="RFQFilter.SCD">Service Contract Draft: {{ RFQFilter.SCD }}; </span>
                                                    <span v-if="RFQFilter.ITV">Instructions to Vendors: {{ RFQFilter.ITV }}; </span><span v-if="RFQFilter.SUOM.length">Service Unit of Measurement: {{ RFQFilter.SUOM.join(', ') }}; </span><span v-if="RFQFilter.Term">Term: {{ RFQFilter.Term + (RFQDetails.RequestType == 'PE' ? ' Day(s)' : ' Month(s)') }}; </span><span v-if="RFQFilter.keyword">Keyword: {{ RFQFilter.keyword }}; </span>
                                                </h6><br>
                                                <h6 class="font-700">Number Of Items Returned By The Search: {{ RequestForProposalsList.length }}</h6><br>
                                            </div>
                                        </div>
                                    </div>
                                    

                            </div>
                            <!-- End List Filter -->
                            
                            <div class="col-lg-12" v-if="RFQDetails.RfPEnquiries.length"><hr>
                                <div class="col-lg-6 pull-left">
                                    <div class="grid-content">
                                        <el-form-item label="" prop="SourcingType">
                                            <span slot="label"><b>Select The Vendors Proposals’ Sourcing Methodology</b></span>
                                            
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span>
                                                <span class="tooltip-content4 clearfix">
                                                    <span class="tooltip-text2"><strong>Requests For Proposals</strong> can be raised to secure proposals from vendors for either Purchase Enquiries or for Rate Contracts (Volume Discount Goods Supply Master Agreement)</span>
                                                </span>
                                            </span>

                                            <el-select style="width: 100%; padding: 0px;" value-key="value" v-model="RFQDetails.SourcingType" placeholder="Select The Vendors Proposals’ Sourcing Methodology">
                                                <el-option label="Sealed Bid" value="Sealed Bid"></el-option>  
                                                <el-option label="Live Auctions" value="Live Auctions"></el-option> 
                                                <!-- <el-option label="Japanees Auction" value="Japanees Auction"></el-option>  -->
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>


                                <div class="col-lg-6 pull-left">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="RFPName">
                                            <span slot="label"><b>Request For Proposal Name</b></span>

                                            <el-input v-model="RFQDetails.RFPName" placeholder="e.g. Request For Proposal For Painting Materials"></el-input>

                                        </el-form-item>
                                    </div>
                                </div>


                                <div class="col-lg-6 pull-left">
                                    <div class="grid-content">
                                        <el-form-item label="" prop="QuotationDate">
                                            <span slot="label"><b>Select The Request For Proposals Timeframe</b></span>
                                            
                                            <el-date-picker
                                            style="width: 100%"
                                            :picker-options = "pickerOptions"
                                            v-model="RFQDetails.QuotationDate"
                                            type="datetimerange"
                                            range-separator="-"
                                            @change="QuotationDateSelected($event)"
                                            start-placeholder="Start date"
                                            end-placeholder="End date"
                                            :default-time="['00:00:00', '23:59:59']"
                                            format="dd.MMM.yyyy HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                            
                                        </el-form-item>
                                    </div>
                                </div>


                                <div class="col-lg-6 pull-left">
                                    <div class="grid-content">
                                        <el-form-item label="" prop="RFIDate">
                                            <span slot="label"><b>Select The Request For Proposals' Request For Information (RFI) Timeframe</b></span>
                                            
                                            <el-date-picker
                                            ref="RFIPicker"
                                            style="width: 100%"
                                            :picker-options = "RfIPickerOptions"
                                            v-model="RFQDetails.RFIDate"
                                            type="datetimerange"
                                            :disabled=RFIDatePickerisDisabled
                                            range-separator="-"
                                            start-placeholder="Start date"
                                            end-placeholder="End date"
                                            :default-time="['00:00:00', '23:59:59']"
                                            format="dd.MMM.yyyy HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                            
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-12" v-if="RFQDetails.RfPEnquiries.length">
                                <div class="grid-content VendorSelection">
                                    <el-form-item label="" prop="SelectedVendors">
                                        <span slot="label"><b>Select Vendors</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><strong>Euclid</strong>, also known as Euclid of Alexandria, was a Greek mathematician, often referred to as the "Father of Geometry". He was active in Alexandria during the reign of Ptolemy I. <a href="https://en.wikipedia.org/wiki/Euclid">Wikipedia</a></span>
                                            </span>
                                        </span>
									    <el-select filterable multiple style="width: 100%; padding: 0px;" v-model="RFQDetails.SelectedVendors" placeholder="Select Vendors">
											
										      <el-option
										        v-for="vendor in VendorsList"
                                                :key="vendor.id"
										        :label="(vendor.users.length > 0) ? vendor.name : vendor.name +' (No Active Users)'"
										        :value="vendor.id">
										      </el-option>
										    
										</el-select>
							      	</el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-12 VendorSelection" v-if="RFQDetails.RfPEnquiries.length">
                                <button type="button" @click="CreateQuotation" class="btn btn-success btn-block waves-effect text-center VendorSelection">Create the Request for Proposals</button>
                            </div>



                        </div>
                    </el-form>
                </div>
            </div>
        </div>

        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Delivery Location</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Filter List Modal -->
        
        <div id="FilterOpsModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="FilterOpsModalView" aria-hidden="true">
            <div class="modal-dialog modal-lg" style="max-width: 1024px">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo">Filter Request For Proposals List</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <el-form id="FilterForm" :model="RFQFilter" class="demo-form-inline" ref="RFQFilterForm" v-if="RFQDetails.RequestType && FilterRequestForProposalsList.length">

                                    <el-row :gutter="20">

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Countries: </b></span>

                                                    <el-select filterable multiple collapse-tags @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.Country" placeholder="Select Countries">
                                                            
                                                        <!-- <el-option v-for="item in uniqueItems(RequestForProposalsList, RFQDetails.RequestType == 'PE' ? 'country_of_delivery' : 'country')" :value="item.country ? item.country : item.country_of_delivery">{{ item.country ? item.country : item.country_of_delivery }}</el-option> -->
                                                        <el-option v-for="(item, index) in uniqueItems(FilterRequestForProposalsList, RFQDetails.RequestType == 'PE' ? 'country_of_delivery' : 'country')" :key="index" :value="item">{{ item }}</el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Cities: </b></span>

                                                    <el-select filterable multiple collapse-tags @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.City" placeholder="Select Cities">
                                                            <el-option v-for="(item, index) in uniqueItems(FilterRequestForProposalsList, RFQDetails.RequestType == 'PE' ? 'cities_of_delivery' : 'cities')" :key="index" :value="item">{{ item }}</el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <!-- Material Filters -->
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Library of Materials Items Description:</b></span>

                                                    <el-select filterable multiple collapse-tags @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.LoMDesc" placeholder="Library of Materials Items Description">
                                                            <el-option v-for="(item, index) in uniqueItemsArray(FilterRequestForProposalsList, 'item_id')" v-if="item.item.field_1" :key="index" :value="item.item_id" :label="'LoM-'+ item.item.item_group_id +'-'+ item.item.item_ingroup_id">LoM-{{ item.item.item_group_id }}-{{ item.item.item_ingroup_id }}  -  {{ item.item.description.substring(0,60) }}</el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE'">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Library of Materials Items with Rates’ Contract:</b></span>

                                                    <el-select disabled filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="RFQFilter.LoMRC" placeholder="Library of Materials Items with Rates’ Contract">
                                                            <el-option value="Yes">{{ 'Yes' }}</el-option>
                                                            <el-option value="Yes">{{ 'No' }}</el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'PE'">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Free-text Described Items:</b></span>

                                                    <el-select :disabled="RFQFilter.LoMDesc.length > 0" allow-create filterable multiple collapse-tags @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.FreeText"  placeholder="Free-text Described Items">
                                                            
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content PERefM" v-if="RFQDetails.RFQType && RFQDetails.RequestType == 'PE'">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Purchase Enquiry Reference: (Numeric Values Only)</b></span>

                                                    <el-select class="PERef" allow-create filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="RFQFilter.PERef" @change="filterNonNumericRef('PERef'), applyFiltersNarrow()" placeholder="Purchase Enquiry Reference">
                                                            
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content PERefM" v-if="RFQDetails.RFQType && RFQDetails.RequestType == 'RC'">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Rates' Contract Creation Request Reference: (Numeric Values Only)</b></span>

                                                    <el-select class="PERef" allow-create filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="RFQFilter.RCRef" @change="filterNonNumericRef('RCRef'), applyFiltersNarrow()" placeholder="Rates' Contract Creation Request Reference">
                                                            
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC'">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Volume Discount Clause: </b></span>

                                                    <el-select filterable collapse-tags style="width: 100%; padding: 0px;" v-model="RFQFilter.VDC" @change="applyFiltersNarrow()" placeholder="Volume Discount Clause">
                                                            <el-option value="Yes" label="Yes"></el-option>
                                                            <el-option value="No" label="No"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder;" label="temp" prop="" v-if="RFQDetails.RFQType == 'Materials' && RFQDetails.RequestType == 'RC'">
                                                    <span slot="label"><b>Minimum Value Required To Trigger The Item Unit Rates’ Discount?</b></span>

                                                    <el-select class="PERef" filterable collapse-tags style="max-width: 30%; padding: 0px; margin-right: 0px !important" v-model="RFQMVOpr" placeholder="Action">
                                                        <el-option value="G" label="> (Greater Than)">Greater Than</el-option>
                                                        <el-option value="L" label="< (Less Than)">Less Than</el-option>
                                                            
                                                    </el-select>
                                                    <el-input :disabled="!RFQMVOpr" type="number" min="0" style="display: inline-flex; max-width: 69.4%; padding: 0px; margin: 0px;" v-model="RFQFilter.MVVal" placeholder="Minimum Value Required To Trigger The Item Unit Rates’ Discount" @blur="applyFiltersNarrow()"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <!-- Material Filters End -->

                                        <!-- Service Filters -->
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE'"><b>Service Contract Description:</b></span>
                                                    <span slot="label" v-else><b>Service Rates' Contract Description:</b></span>

                                                    <el-select filterable multiple collapse-tags @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.SerDesc" placeholder="Library of Materials Items Description">
                                                            <el-option v-for="(item, index) in uniqueItemsArray(FilterRequestForProposalsList,  this.RFQDetails.RequestType == 'RC' ? 'item_description' : 'service_description')" :key="index" :value="item.service_description ? item.service_description : item.item_description" :label="item.service_description ? item.service_description : item.item_description"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Type of Service Contract</b></span>
                                                    
                                                    <el-select @change="applyFiltersNarrow()" filterable style="width: 100%; padding: 0px;" v-model="RFQFilter.SerTyp" placeholder="Type of Service Contract">
                                                            
                                                        <el-option value="Fixed-Priced Contract">Fixed-Priced Contract
                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    
                                                                </span>
                                                                <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                    <span class="tooltip-text2">
                                                                        A fixed-price contract, also known as lump sum contract,<br> 
                                                                        is used in situations where the payment doesn’t depend on the<br> 
                                                                        resources used or time expended. With fixed-price contracts, <br>
                                                                        vendors will estimate the total allowable costs of labor, <br>
                                                                        materials and equipment and perform the action specified by<br>
                                                                        the contract regardless of the actual cost.<br>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </el-option>
                                                        <el-option value="Cost-Reimbursement Contract">Cost-Reimbursement Contract
                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    
                                                                </span>
                                                                <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                    <span class="tooltip-text2">
                                                                        With a cost-reimbursement contract, the final<br> 
                                                                        total cost is determined when the project is completed<br>
                                                                        or at another predetermined date within the contract’s timeframe.<br>
                                                                        Before the project is started, the vendor will create<br>
                                                                        an estimated cost to give the organization an idea of the budget.<br>
                                                                        The purpose of setting this expectation with cost-reimbursement<br> 
                                                                        contracts is to establish a ceiling price that the contractor<br>
                                                                        shouldn’t exceed without the approval of the organization. At<br>
                                                                        the same time, if that ceiling is reached, the vendor can stop work.<br>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </el-option>
                                                        <el-option value="Cost-plus-Fixed-Fee Contract">Cost-plus-Fixed-Fee Contract
                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    
                                                                </span>
                                                                <span class="tooltip-content4 clearfix" style="position:fixed; width:500px">
                                                                    <span class="tooltip-text2">
                                                                        A cost-plus contract is type of cost reimbursement contract<br>
                                                                        for situations where the organization agrees to pay the <br>
                                                                        actual cost of the entire project, including labor, materials,<br>
                                                                        and any unexpected expenses. The word “plus” refers to the<br>
                                                                        fee that covers the contractor’s profits and overhead. <br>
                                                                        In these agreements, the organization agrees to pay that extra<br> 
                                                                        amount and expects the vendor to deliver on their promise.<br>

                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Commercial Offer Format: </b></span>
                                                    
                                                    <el-select @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.COF" placeholder="Please Select One OF Below">
                                                            
                                                        <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                                        <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                        
                                                        <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                        
                                                        <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'RC'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Service Line Unit Of Measurement: </b></span>
                                                    
                                                    <el-input @blur="applyFiltersNarrow()" v-model="RFQFilter.SUOM" placeholder="Vendor Offer Content"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType " >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Vendor Offer Content: </b></span>
                                                    
                                                    <el-input @blur="applyFiltersNarrow()" v-model="RFQFilter.VOC" placeholder="Vendor Offer Content"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType " >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType == 'PE'"><b>Service Contract Draft: </b></span>
                                                    <span slot="label" v-else><b>Service Rates' Contract Template: </b></span>
                                                    
                                                    <el-input @blur="applyFiltersNarrow()" v-model="RFQFilter.SCD" placeholder="Service Contract Draft"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType " >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Instruction To Vendors: </b></span>
                                                    
                                                    <el-input @blur="applyFiltersNarrow()" v-model="RFQFilter.ITV" placeholder="Instruction To Vendors"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <!-- Service Filters End -->

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Advance Payment Information:</b></span>

                                                    <el-select multiple filterable @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.AdvP" placeholder="Advance Payment Information">
                                                            <el-option label="Yes, Guarantee is Required" value="YY"></el-option>
                                                            <el-option label="Yes, Guarantee isn't Required" value="YN"></el-option>
                                                            <el-option label="Not Applicable" value="NA"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Retention Payment Information:</b></span>

                                                    <el-select multiple filterable @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.RtP" placeholder="Retention Payment Information">
                                                        <el-option label="Retention Percentage" value="RP"></el-option>
                                                        <el-option label="Number of Days" value="ND"></el-option>
                                                        <el-option label="Not Applicable" value="NA"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Sourcing Priority:</b></span>

                                                    <el-select multiple filterable @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.SP" placeholder="Sourcing Priority">
                                                        <el-option label="Standard" value="Standard"></el-option>
                                                        <el-option label="Urgent" value="Urgent"></el-option>
                                                        <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Underlying Transaction Reference:</b></span>

                                                    <el-select allow-create multiple filterable @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.UTR" placeholder="Underlying Transaction Reference">
                                                            
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="12" v-if="RFQDetails.RFQType == 'Service' && RFQDetails.RequestType">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Term:</b></span>

                                                    <el-select filterable style="width: 100%; padding: 0px;" @change="applyFiltersNarrow()" v-model="RFQFilter.Term" placeholder="Specify The Term?">
                                            
                                                        <el-option v-if="(RFQDetails.RequestType == 'PE' ? (item.service_one_off !== 'One/Off' && item.service_term !== 0) : 1)" v-for="(item, index) in uniqueItemsArray(FilterRequestForProposalsList,  this.RFQDetails.RequestType == 'RC' ? 'term' : 'service_term')" :key="index" :value="RFQDetails.RequestType == 'RC' ? item.term : item.service_term" :label="RFQDetails.RequestType == 'RC' ? item.term + ' Month(s)' : item.service_term + ' Day(s)'"></el-option>
                                                        <el-option v-else value="One/Off" label="One/Off"></el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="RFQDetails.RequestType == 'PE' ? 24 : 12" v-if="RFQDetails.RFQType && RFQDetails.RequestType">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="">
                                                    <span slot="label"><b>Filter Using Keyword:</b></span>

                                                    <el-input @change="applyFiltersNarrow()" style="width: 100%; padding: 0px;" v-model="RFQFilter.keyword"  placeholder="Filter Using Keyword">
                                                            
                                                            
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        
                                    </el-row>

                                    <el-row>
                                        <el-col :span="6" class="float-right m-1"><hr><button type="button" data-dismiss="modal" aria-hidden="true" class="btn btn-danger btn-block waves-effect text-center" @click="resetFilters()">Reset Filter(s)</button></el-col>
                                        <el-col :span="6" class="float-right m-1"><hr><button type="button" data-dismiss="modal" aria-hidden="true" class="btn btn-success btn-block waves-effect text-center" @click="applyFilters(RFQFilter)">Apply Filter(s)</button></el-col>
                                    </el-row>

                                </el-form>
                            </div>
                            

                        </div>
                        

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>

        <!-- Filter List Modal -->

        <!-- PE Details Modal -->

        <div v-if="RFQDetails.RFQType == 'Materials'" id="MaterialsModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="MaterialsModalView" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-if="ViewModalInfo.enquiry_type">Materials Purchase Enquiry Line ID# {{ ViewModalInfo.show_id }}</h4>
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-else>Materials Rates’ Contract Creation Request Line ID# {{ ViewModalInfo.show_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">

                            <!-- Purchase Enquiry Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700" v-if="ViewModalInfo.enquiry_type">Purchase Enquiry Underlying Transaction: </b><b class="font-700" v-else>Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type">
                                        <td width="40%"><b class="font-700">Purchase Enquiry Type: </b></td>
                                        <td>{{ ViewModalInfo.enquiry_type }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type">
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ ViewModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type">
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ ViewModalInfo.term }} Months</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type">
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.country }} | {{ ViewModalInfo.cities.toString()}}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type">
                                        <td><b class="font-700">Delivery Address Details:</b></td>
                                        <td>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span><br>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span><br>
                                            <span><b class="font-700 font-italic">Location Name:</b> {{ ViewModalInfo.location_name }}</span><br>
                                            <span><b class="font-700 font-italic">Longitude:</b> {{ ViewModalInfo.longitude }}</span><br>
                                            <span><b class="font-700 font-italic">Latitude:</b> {{ ViewModalInfo.latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Purchase Enquiry General Infomation Panel End -->

                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%" v-if="ViewModalInfo.enquiry_from_item_master == 'Yes'"><b class="font-700">What Is The Payment Retention For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td width="40%" v-else><b class="font-700">What Is The Percentage Of Retention Payment?</b></td>
                                        <td>{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage+'%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                        <td v-if="ViewModalInfo.required_documents">
                                            <ul class="d-inline-flex" v-for="(document, index) in ViewModalInfo.required_documents.split(',')">
                                                <li>{{ document }}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Documents Required
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                    </tr>
                                            
                                    <tr v-if="(ViewModalInfo.enquiry_type == 'Materials' || ViewModalInfo.rccr_type == 'Material') && (ViewModalInfo.enquiry_from_item_master == 'Yes' || ViewModalInfo.item.field_1)">
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Library Of Materials Item Details: </b></td>
                                        <td>
                                            <span v-if="!isJSON(ViewModalInfo.item.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.item.picture"></span>
                                            <span class="text-center" v-else v-for="(img, index) in JSON.parse(ViewModalInfo.item.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></span>
                                            <br><br><span><b class="font-700"><span>LoM-</span>{{ ViewModalInfo.item.item_group_id }}-{{ ViewModalInfo.item.item_ingroup_id }}</b></span><br>
                                            <span>
                                                <template v-for="index in 20">      
                                                    <span v-if="ViewModalInfo.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                        <b class="font-700 text-uppercase">{{ ViewModalInfo.item.item_template['field_'+index] }}:</b> {{ ViewModalInfo.item['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <!-- <span><b>Quantity:</b> {{ ViewModalInfo.Quantity }}</span><br> <br> -->
                                            <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ ViewModalInfo.item.u_o_m.toUpperCase() }}</span><br>
                                            <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(ViewModalInfo.item.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Material Details: </b></td>
                                        <td class="dont-break-out">
                                            <span v-if="ViewModalInfo.p_images.length > 0" v-for="(img, index) in JSON.parse(ViewModalInfo.p_images)">
                                                <img style="width: 8vw;height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/images/'+img">
                                            </span><br><br>
                                            <span> {{ ViewModalInfo.item_description }}</span><br><br>
                                            <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ ViewModalInfo.u_o_m }}</span><br>
                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Expected Quantity During The Term: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            {{  ViewModalInfo.quantity }}
                                        
                                        </td>


                                    </tr>

                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700"> Volume Discount Clause: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                                <span>{{ViewModalInfo.need_volume_disc}}</span>
                                                        
                                        
                                        </td>


                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Minimum Value Required To Trigger The Item Unit Rates' Discount: </b></td>

                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            <!-- {{  ViewModalInfo.Quantity }} -->
                                            <span>
                                                <span v-if="ViewModalInfo.need_volume_disc == 'No' ">
                                                    This Item Doesn't Have A Volume Discount Arrangement.
                                                    </span>
                                                    <span v-else>
                                                        {{ ViewModalInfo.minimal_procured_value }} {{ViewModalInfo.project.currency.substring(0, 3)}}
                                                        </span>
                                                        </span>

                                        </td>

                                        
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td><b class="font-700">Quantity: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            {{  ViewModalInfo.quantity }}
                                        
                                        </td>


                                    </tr>

                                    <!-- <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td><b class="font-700">Selected Rates’ Contract: </b></td>
                                        <td>  
                                            <div class="text-center m-0 p-0"><button class="text-center" style="background-color: red; border: none; color: white; height: 40px; width: 300px;">Materials Rates’ Contract</button></div>
                                                        
                                        
                                        </td>


                                    </tr> -->

                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td><b class="font-700">Selected Rates’ Contract: </b></td>
                                        <td v-if="ViewModalInfo.RateContractSelected">
                                            <span><b class="font-700">Vendor Name:</b> {{ ViewModalInfo.RateContractSelected.vendor_name }}</span><br>
                                            <span><b class="font-700">Vendor Score:</b> {{ ViewModalInfo.RateContractSelected.vendor_score }}</span><br>
                                            <span><b class="font-700">Expiry Date:</b> {{ ViewModalInfo.RateContractSelected.date }}</span><br>
                                            <span><b class="font-700">Unit Rate:</b> {{ formatPrice(ViewModalInfo.RateContractSelected.unit_rate) }}  {{ViewModalInfo.project.currency}}</span><br>
                                            <span><b class="font-700">Lead Time:</b> {{ ViewModalInfo.RateContractSelected.lead_time }}</span><br>
                                        </td>
                                        <td colspan="2" v-else-if="ViewModalInfo.EnquiryFromItemMaster == 'No'">
                                            This Isn't An Item From The Library of Materials. There Are No Rates’ Contracts For It.
                                        </td>
                                        <td colspan="2" v-else>
                                            No Rates’ Contract was selected for this material.
                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td><b class="font-700">Expected Cost: </b></td>

                                        <td colspan="2" v-if="ViewModalInfo.RateContractSelected"><b class="font-700"> {{ formatPrice(ViewModalInfo.RateContractSelected.unit_rate * ViewModalInfo.quantity) }} {{ViewModalInfo.project.currency}} </b> From The Selected Rates’ Contract</td>
                                        <td colspan="2" v-else-if="ViewModalInfo.enquiry_from_item_master == 'No'">Not Applicable As It Is A Free-Text Described Item (Not An Item From The Library of Materials.)</td>
                                        <td colspan="2" v-else>Not Available</td>

                                        
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td><b class="font-700">Additional Notes: </b></td>

                                        <td v-if="!ViewModalInfo.notes">Not Additional Notes</td>
                                        <td v-else>{{ ViewModalInfo.notes }}</td>



                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Awardee Vendor: </b></td>

                                        <td v-if="ViewModalInfo.purchase_orders ? !ViewModalInfo.purchase_orders.length : 1">Not Yet Awarded</td>
                                        <td v-else><span v-if="ViewModalInfo.project.company.po_prefix != ''">{{ ViewModalInfo.project.company.po_prefix }}</span><span v-else>PO</span>: {{ ViewModalInfo.purchase_orders[0].purchase_order_group_id }} - {{ ViewModalInfo.purchase_orders[0].vendor.name }}</td>



                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Materials Infomation Panel End -->

                             <!-- List of Valid Rates Contracts -->
                             <div class="col-lg-12" v-if="ViewModalInfo.enquiry_from_item_master == 'Yes' || ViewModalInfo.rccr_type == 'Material'">
                                <table class="table">
                                    <tr class="text-ogeo text-center">
                                    <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                </tr>
                                        <table>
                                            <tr>
                                                <th><b class="font-700">Vendor Name</b></th>
                                                <th><b class="font-700">Country Cities</b></th>
                                                <th><b class="font-700">Unit Rate</b></th>
                                                <th><b class="font-700">Expiry Date</b></th>
                                                <th><b class="font-700">Lead Time</b></th>
                                                <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                <th><b class="font-700">Discount Rate</b></th>
                                                <th><b class="font-700">Total Procured So Far</b></th>
                                                <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                                <th><b class="font-700">Retention Payment Arrangement</b></th>
                                            </tr>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                <td>{{ item.vendor_name }}</td>
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </table>
                                    </table>
                            </div>
                            <!-- List of Valid Rates Contracts End -->

                        </div>
                        
                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        <div class="col-lg-12" v-if = "RFQDetails.RequestType == 'RC'">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                        <td>{{ item.decision}} - <i>{{item.decision_notes}}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        
                                        <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                        <td v-else-if="item.decision == 'Permanently Cancelled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="ViewModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="ViewModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="ViewModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="ViewModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-12" v-if = "RFQDetails.RequestType == 'PE'">
                            <h3 class="text-ogeo">History</h3>   
                            <table class="display table table-bordered" style="width:80vw !important;">
                                <thead style="width:80vw !important;">
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Action</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.current_action_description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level }}</td>
                                        <td>{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <!-- <td v-else>{{ item.created_at }}</td> -->
                                        <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Purchase Enquiry Line Information';window.print()">Download PDF</button>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>

        <div v-if="RFQDetails.RFQType == 'Service'" id="ServicesModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ServicesModalView" aria-hidden="true" style="display: none;"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo" v-if="ViewModalInfo.enquiry_type">Services Purchase Enquiry Line ID# {{ ViewModalInfo.show_id }}</h4>
                            <h4 class="modal-title text-ogeo" v-else>Services Rates’ Contract Creation Request Line ID# {{ ViewModalInfo.show_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12" v-if="ViewModalInfo.rccr_type">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ ViewModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ ViewModalInfo.term }} Months</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.country }} | {{ ViewModalInfo.cities}}</span><br>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <div class="col-lg-12"v-if="ViewModalInfo.enquiry_type">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Purchase Enquiry Type: </b></td>
                                        <td>{{ ViewModalInfo.enquiry_type }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Delivery Address Details:</b></td>
                                        <td>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span><br>
                                            <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span>
                                            <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span><br>
                                            <span><b class="font-700 font-italic">Location Name:</b> {{ ViewModalInfo.location_name }}</span><br>
                                            <span><b class="font-700 font-italic">Longitude:</b> {{ ViewModalInfo.longitude }}</span><br>
                                            <span><b class="font-700 font-italic">Latitude:</b> {{ ViewModalInfo.latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <!-- <tr align="center" v-if="ViewModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in ViewModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Description: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.item_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Service Contract Description: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.service_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time: </b></td>
                                        <td>{{ ViewModalInfo.service_one_off }}</td>
                                    </tr> 
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service' && ViewModalInfo.service_one_off == 'Over A Period Of Time'">
                                        <td><b class="font-700">The Term Of The Service Contract, In Days:</b></td>
                                        <td>{{ ViewModalInfo.service_term }} Days</td>
                                    </tr> 
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Type Of Service Contract: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.type_of_services_contract }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td><b class="font-700">Vendor Commercial Offer Format: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.vendor_commercial_offer }}</span><br>

                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendor_instruction_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendor_offer_content">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 SCTH">Header</b></th>
                                                    <th><b class="font-700 SCTC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.service_contract_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.tendererchildheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendoroffercontent">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 SCTH">Header</b></th>
                                                    <th><b class="font-700 SCTC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.childheaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Unit of Measurement: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.u_o_m.toUpperCase() }}</span><br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Additional Notes: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.notes }}</span><br>

                                        </td>
                                    </tr>
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Rates Contracts -->
                            <!-- <div class="col-lg-12" v-if="ViewModalInfo.rccr_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                                <hr>   
                            </div> -->
                            <!-- List of Valid Rates Contracts End-->

                        </div>                       

                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        
                        <div class="col-lg-12" v-if="RFQDetails.RequestType == 'RC'">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-Note</b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                            <td>{{ item.decision}} - <span class="font-italic">{{item.decision_notes}}</span></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            
                                            <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                            <td v-else-if="item.decision == 'Permanently Cancelled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-else-if="ViewModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-else-if="ViewModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{item.next_approval_level}}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-if="ViewModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-if="ViewModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-12" v-if="RFQDetails.RequestType == 'PE'">
                            <h3 class="text-ogeo">History</h3>   
                            <table class="display table table-bordered" style="width:80vw !important;">
                                <thead style="width:80vw !important;">
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Action</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.current_action_description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level }}</td>
                                        <td>{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <!-- <td v-else>{{ item.created_at }}</td> -->
                                        <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><button id="PrintHidden" class="btn btn-success ml-3" onclick="document.title = 'Services Rates’ Contract Line Information';window.print()">Download PDF</button>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>             
                    </div>
                </div>
            </div>
        </div>



    </div>

</template>

<script>
	
	import {CustomJs} from '../../helpers/custom.js';
	import validate from 'validate.js';

    export default {
        name: 'request-for-quotation',
        data(){
            var dis = this;
            let minDate = '';
            let maxDate = '';
            return{
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() <= new Date(new Date().setDate(new Date().getDate()-1));
                  },
                },
                RfIPickerOptions: {
                  disabledDate(time) {
                    var mindate = moment(dis.RFQDetails.QuotationDate[0]);
                    var maxDate = moment(dis.RFQDetails.QuotationDate[1]);         
                    var isBeforeMinDate = time.getTime() < mindate;
                    var isAfterMaxDate = time.getTime() > maxDate;

                    return isBeforeMinDate || isAfterMaxDate;

                  },
                },
                RFIDatePickerisDisabled: true,
                hostName: window.location.protocol+'//'+window.location.hostname,
                RfPRecordsLoading: false,
                CompanySetup: {},
                JobNumbers: [],
                VendorsList: [],
                ViewModalInfo: {},
                viewDataLoaded: false,
                projectcurrency: "",
                selSerDesc: "",
                serRCCRGroup: "",
            	RequestForProposalsList: "",
            	OrigRequestForProposalsList: "",
            	FilterRequestForProposalsList: "",
                SourcingOption: null,
                validatorsList: [],
                ListCounter: {
                    ItemFromLOM: 0,
                    ItemNotLOM: 0,
                    RCFromLOM: 0,
                    RCFromValidRC: 0,
                    ServicesPE: 0,
                    ServicesRC: 0
                },
                RFQMVOpr: "",
                RFQFilter: {
                    Country: "",
                    City: "",
                    LoMDesc: "",
                    LoMRC: "",
                    FreeText: "",
                    PERef: "",
                    RCRef: "",
                    AdvP: "",
                    RtP: "",
                    SP: "",
                    UTR: "",
                    MVVal: "",
                    VDC: "",
                    SerDesc: "",
                    SerTyp: "",
                    COF: "",
                    VOC: "",
                    SCD: "",
                    ITV: "",
                    SUOM: "",
                    Term: "",
                    keyword: ""
                 },
                AbrRep: {
                    YY: "Yes, Gaurantee is Required",
                    YN: "Yes, Gaurantee isn't Required",
                    ND: "Number of Days",
                    RP: "Retention Percentage",
                    NA: "Not Applicable",
                    PE: "Purchase Enquiry",
                    RC: "Rates' Contracts",
                    G: "Greater Than ",
                    L: "Less Than ",
                },
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17.June.2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25.March.2022',
			        },
                    {
			          value: 3,
			          vendor_name: 'Nike',
			          vendor_score: '10',
			          unit_rate: '10',
			          lead_time: '5 Days',
			          rate_contract_reference: 'Nike',
			          date: '09.July.2023',
			        }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                RFQDetails: {
                    ProjectId: "",
                	RFQType: "",
                    RequestType: "",
                    OgeoAuto: 'No',
                	QuotationDate: "",
                    RFPName: "",
                    RFIDate: "",
                	SourcingType: "",
                	RfPEnquiries: [],
                	SelectedVendors: {},
                },
                rules: {
                	ProjectId: [{
                        required: true,
                        message: "Please Select Project",
                        trigger: ["blur"]
                    }],
                    RFQType: [{
                        required: true,
                        message: "Please Select RFQ Type",
                        trigger: ["blur"]
                    }],
                    RequestType: [{
                        required: true,
                        message: "Please Select Request Type",
                        trigger: ["blur"]
                    }],
                    QuotationDate: [{
                        required: true,
                        message: "Please select closure date",
                        trigger: ["blur"]
                    }],
                    RFPName: [{
                        required: true,
                        message: "Please Ener RFP name",
                        trigger: ["blur"]
                    }],
                    RFIDate: [{
                        required: true,
                        message: "Please select RFI date",
                        trigger: ["blur"]
                    }],
                    RfPEnquiries: [{
                        required: true,
                        message: "Please select at least one purchase enquiry",
                        trigger: ["blur"]
                    }],
                    SourcingType: [{
                        required: true,
                        message: "Please Select The Vendors Proposals’ Sourcing Methodology",
                        trigger: ["blur"]
                    }],
                    SelectedVendors: [{
                        required: true,
                        message: "Please select at least one vendor",
                        trigger: ["blur"]
                    }],
                },
                pickerOptions1: {
                    shortcuts: [{
                        text: 'Tomorrow',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    },
                    {
                        text: '1 Week From Now',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    },
                    {
                        text: '2 Week From Now',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    },
                    {
                        text: '3 Week From Now',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 21);
                            picker.$emit('pick', date);
                        }
                    },
                    {
                        text: '30 Days From Now',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        methods: {
            isJSON(text){

                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            filterNonNumericRef(name) {
					// Replace non-numeric characters with an empty string
                    if(this.RFQFilter[name].length)
					    this.RFQFilter[name][this.RFQFilter[name].length-1] = Number(this.RFQFilter[name][this.RFQFilter[name].length-1].replace(/[^0-9]/g, ""));
				},
            checkOBJ(obj) {
                for (var key in obj) {
                    if (obj[key] !== null && obj[key] != "")
                        return false;
                }
                return true;
            },
            countOBJ(obj) {
                let count = 0;
                for (var key in obj) {
                    if (obj[key] !== null && obj[key] != "")
                        count++;
                }
                return count;
            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            applyFiltersNarrow() {

                let vm = this;
                let TempFilter = [];
                
                if(this.checkOBJ(this.RFQFilter)){
                    this.FilterRequestForProposalsList = "";
                    this.FilterRequestForProposalsList = this.OrigRequestForProposalsList.slice();
                }
                else
                {
                    this.OrigRequestForProposalsList.map( item => {
                        let flag = 0
                        if(this.RFQFilter.Country.length)
                            if(this.RFQFilter.Country.some( (subitem) =>  item.country ? item.country.includes(subitem) : item.country_of_delivery.includes(subitem)))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.City.length)
                            if(this.RFQFilter.City.some((subitem) => item.cities ? item.cities.includes(subitem) : item.cities_of_delivery.includes(subitem)))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.LoMDesc.length && item.enquiry_from_item_master == 'Yes')
                            if(this.RFQFilter.LoMDesc.includes(item.item_id))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.FreeText.length && item.enquiry_from_item_master == 'No')
                            if(this.RFQFilter.FreeText.some( subitem => item.item_description.toLowerCase().includes(subitem.toLowerCase())))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.PERef.length)
                            if(this.RFQFilter.PERef.includes(item.purchase_enquiry_group_id))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.RCRef.length)
                            if(this.RFQFilter.RCRef.includes(item.rate_contract_request_group_id))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.SerDesc.length)
                            if(this.RFQFilter.SerDesc.some( subitem => this.RFQDetails.RequestType == 'RC' ? item.item_description.toLowerCase().includes(subitem.toLowerCase()) : item.service_description.toLowerCase().includes(subitem.toLowerCase())))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.SerTyp)
                            if(this.RFQFilter.SerTyp.includes(item.type_of_service_contract))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.COF)
                            if(this.RFQFilter.COF.includes(item.vendor_commercial_offer))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.SUOM)
                            if(item.u_o_m.toLowerCase().includes(this.RFQFilter.SUOM.toLowerCase()))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.VOC.length)
                            if(item[this.RFQDetails.RequestType == 'PE' ? 'vendoroffercontent' : 'vendor_offer_content'].some( subitem => subitem.header_name.toLowerCase().includes(this.RFQFilter.VOC.toLowerCase()) || subitem[subitem.header_contant ? 'header_contant' : 'header_content'].toLowerCase().includes(this.RFQFilter.VOC.toLowerCase()) ))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.SCD.length)
                            if(item[this.RFQDetails.RequestType == 'PE' ? 'childheaders' : 'service_contract_headers'].some( subitem => subitem.header_name.toLowerCase().includes(this.RFQFilter.SCD.toLowerCase()) || subitem[subitem.header_contant ? 'header_contant' : 'header_content'].toLowerCase().includes(this.RFQFilter.SCD.toLowerCase()) ))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.ITV.length)
                            if(item[this.RFQDetails.RequestType == 'PE' ? 'tendererchildheaders' : 'vendor_instruction_headers'].some( subitem => subitem.header_name.toLowerCase().includes(this.RFQFilter.ITV.toLowerCase()) || subitem[subitem.header_contant ? 'header_contant' : 'header_content'].toLowerCase().includes(this.RFQFilter.ITV.toLowerCase()) ))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.VDC)
                            if(this.RFQFilter.VDC.includes(item.need_volume_disc))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.MVVal)
                            if(this.RFQMVOpr == 'G')
                                if(Number(item.minimal_procured_value.replace(/,/g, '')) > this.RFQFilter.MVVal)
                                    flag++;
                                else
                                    flag--;
                            else if(this.RFQMVOpr == 'L')
                                if(Number(item.minimal_procured_value.replace(/,/g, '')) < this.RFQFilter.MVVal)
                                    flag++;
                                else
                                    flag--;
                            else
                                flag--;
                        if(this.RFQFilter.AdvP.length)
                            if(this.RFQFilter.AdvP.length === 3)
                                flag++;
                            else if(this.RFQFilter.AdvP == 'YY' && item.advanced_payment == 'Yes' && (item.advanced_payment_guarantee == 'Yes' || item.guarantee_Need_for_advance_payment == 'Yes'))
                                flag++;
                            else if(this.RFQFilter.AdvP == 'YN' && item.advanced_payment == 'Yes' && (item.advanced_payment_guarantee == 'No' || item.guarantee_Need_for_advance_payment == 'No'))
                                flag++;
                            else if(this.RFQFilter.AdvP == 'NA' && (item.advanced_payment == 'No' || item.advanced_payment == null))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.RtP.length)
                            if(this.RFQFilter.RtP.length === 3)
                                flag++;
                            else if(this.RFQFilter.RtP == 'RP' && item.retention_percentage)
                                flag++;
                            else if(this.RFQFilter.RtP == 'ND' && item.retention_days)
                                flag++;
                            else if(this.RFQFilter.RtP == 'NA' && !item.retention_percentage)
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.SP.length)
                            if(this.RFQFilter.SP.includes(item.sourcing_priority))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.UTR.length)
                            if(this.RFQFilter.UTR.some( subitem => item.underlying_transaction.toLowerCase().includes(subitem.toLowerCase())))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.Term)
                            if((item.service_one_off ? item.service_one_off.includes(this.RFQFilter.Term) : 0) || (item.service_term ? item.service_term == this.RFQFilter.Term : 0) || (item.term ? item.term == this.RFQFilter.Term : 0))
                                flag++;
                            else
                                flag--;
                        if(this.RFQFilter.keyword)
                            if((item.item.length ? item.item.item_description.includes(this.RFQFilter.keyword) : 0) || (item.item_description ? item.item_description.includes(this.RFQFilter.keyword) : 0) || (item.item.length ? item.item.u_o_m.includes(this.RFQFilter.keyword) : 0) || (item.u_o_m ? item.u_o_m.includes(this.RFQFilter.keyword) : 0) || (item.service_description ? item.service_description.includes(this.RFQFilter.keyword) : 0))
                                flag++;
                            else
                                flag--;

                            
                        if(flag == this.countOBJ(this.RFQFilter))
                            TempFilter.push(item);


                    });

                    this.FilterRequestForProposalsList = "";
                    this.FilterRequestForProposalsList = TempFilter.slice();
                }

                    
            },
            applyFilters(RFQFilter) {
                let vm = this;

                this.RequestForProposalsList = this.FilterRequestForProposalsList.slice();
            },
            resetFilters() {
                this.RequestForProposalsList = "";
                this.RFQFilter = {
                    Country: "",
                    City: "",
                    LoMDesc: "",
                    LoMRC: "",
                    FreeText: "",
                    PERef: "",
                    RCRef: "",
                    AdvP: "",
                    RtP: "",
                    SP: "",
                    UTR: "",
                    MVRT: "",
                    VDC: "",
                    SerDesc: "",
                    SerTyp: "",
                    COF: "",
                    VOC: "",
                    SCD: "",
                    ITV: "",
                    SUOM: "",
                    Term: "",
                    keyword: "",
                 },
                this.RequestForProposalsList = this.OrigRequestForProposalsList.slice();
                this.FilterRequestForProposalsList = this.OrigRequestForProposalsList.slice();
            },
            QuotationDateSelected(QuotationDate) {
                // var self = this;
                if(QuotationDate)
                    this.RFIDatePickerisDisabled = false;
                else
                    this.RFIDatePickerisDisabled = true;
            },
            showViewModal(recordId, RFQType, RequestType){

                this.selectedItem(recordId);

                if (RequestType == 'PE') {
                    let elquentClass = "PurchaseEnquiry";

                    this.ViewModalInfo = {};
                    axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                        .then((response) => {
                            this.ViewModalInfo = response.data;
                            console.log(this.ViewModalInfo);
                            if(response.data.p_images !== null){
                                let image_array_string = response.data.p_images;
                                let image_string = image_array_string.substring(1, image_array_string.length-1);
                                let final = image_string.replace(/"/g,"");
                                var d= final.split(',');
                                this.p_images = d;
                            }
                            this.loadValidations(response.data);
                            this.viewDataLoaded = true;
                            if (RFQType == 'Materials') {
                                $('#MaterialsModalView').modal('toggle');
                            }
                            else
                                $('#ServicesModalView').modal('toggle');
                        });
                }
                else {
                    let elquentClass = "RateContractRequest";

                    this.ViewModalInfo = {};
                    axios.post('/api/data/get_rate_contract_creation_requests_record_details', [elquentClass, recordId])
                        .then((response) => {
                            this.ViewModalInfo = response.data;

                            this.loadValidations(response.data);
                            this.viewDataLoaded = true;
                            if (RFQType == 'Materials') {
                                $('#MaterialsModalView').modal('toggle');
                            }
                            else
                                $('#ServicesModalView').modal('toggle');
                        });
                }
                
                this.viewDataLoaded = false;

            },
            showMap(latitude, longitude, PEID){

                var myLatLng = {lat: parseFloat(longitude), lng: parseFloat(latitude)};

                // Map Options
                var mapOptions = {
                    zoom: 10,
                    center: new google.maps.LatLng(parseFloat(longitude), parseFloat(latitude)),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };

                // Initialize the map with options (inside #map element)
                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                });


                // When modal window is open, this script resizes the map and resets the map center
                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                  google.maps.event.trigger(map, "resize");
                  return map.setCenter(myLatLng);
                });

                this.selectedItem(PEID);

                $('#DeliveryLocation').modal('toggle');


            },
            uniqueItems(array, name) {
                var seen = {};
                var out = [];
                var len = array.length;
                var j = 0;
                if(array.length){
                    for(var i = 0; i < len; i++) {
                        var item = array[i][name];
                        if(seen[item] !== 1) {
                            seen[item] = 1;
                            if(item.includes(', ')) 
                                out.push(...item.split(', '))
                            else if(item.includes(',')) 
                                out.push(...item.split(','))
                            else
                                out.push(item);
                        }
                    }
                    return out;
                }
            },
            uniqueItemsArray(array, name) {
                var seen = {};
                var out = [];
                var len = array.length;
                var j = 0;
                for(var i = 0; i < len; i++) {
                    var item = array[i];
                    if(seen[item[name]] !== 1) {
                        seen[item[name]] = 1;
                        out[j++] = item;
                    }
                }
                return out;
            },
            selectedItem(PEID) {

                    if($('#RfPEnquiry_Id_'+PEID).hasClass('selectedItem')){

                        $('#RfPEnquiry_Id_'+PEID).removeClass('selectedItem');
                        $('#RfPEnquiry_Id_'+PEID).css('background-color', '');
                        $('#checkbox_'+PEID).attr('checked', false);
                        this.RFQDetails.RfPEnquiries.splice(this.RFQDetails.RfPEnquiries.indexOf(PEID), 1);
                        this.RFQDetails.RFPName = "";
                        this.selSerDesc = "";

                    } else if(this.RFQDetails.RfPEnquiries.length && this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'PE'){
                            
                        $('#RfPEnquiry_Id_'+this.RFQDetails.RfPEnquiries[0]).removeClass('selectedItem');
                        $('#RfPEnquiry_Id_'+this.RFQDetails.RfPEnquiries[0]).css('background-color', '');
                        $('#checkbox_'+this.RFQDetails.RfPEnquiries[0]).attr('checked', false);
                        this.RFQDetails.RfPEnquiries.splice(this.RFQDetails.RfPEnquiries.indexOf(this.RFQDetails.RfPEnquiries[0]), 1);
                        this.RFQDetails.RFPName = "";
                        $('#RfPEnquiry_Id_'+PEID).addClass('selectedItem');
                        $('#RfPEnquiry_Id_'+PEID).css('background-color', 'rgb(69, 249, 132, 0.1)');
                        $('#checkbox_'+PEID).attr('checked', 'checked');
                        this.RFQDetails.RfPEnquiries.push(PEID);
                        this.RFQDetails.RFPName = this.selSerDesc;

                    } else if(this.RFQDetails.RfPEnquiries.length && this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'RC'){
                            
                        if(this.RequestForProposalsList.some(item => item.id == PEID && item.rate_contract_request_group_id == this.serRCCRGroup)){
                            $('#RfPEnquiry_Id_'+PEID).addClass('selectedItem');
                            $('#RfPEnquiry_Id_'+PEID).css('background-color', 'rgb(69, 249, 132, 0.1)');
                            $('#checkbox_'+PEID).attr('checked', 'checked');
                            this.RFQDetails.RfPEnquiries.push(PEID);
                            this.RFQDetails.RFPName = this.selSerDesc;
                        } else
                            Swal('Warning', 'Only Rates Contract Requests with same group are allowed', 'warning');

                    } else {
                        
                        $('#RfPEnquiry_Id_'+PEID).addClass('selectedItem');
                        $('#RfPEnquiry_Id_'+PEID).css('background-color', 'rgb(69, 249, 132, 0.1)');
                        $('#checkbox_'+PEID).attr('checked', 'checked');
                        this.RFQDetails.RfPEnquiries.push(PEID);

                        if(this.selSerDesc && this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'PE')
                            this.RFQDetails.RFPName = this.selSerDesc;
                    }

                        

                        
                    
            },
            GetRCsListFromRequestType(RequestType){
                // self = this;

                this.RfPRecordsLoading = true;
                if(this.RFQDetails.RFQType != "" && this.RFQDetails.ProjectId != ""){
                    this.RequestForProposalsList = "";
                    console.log(this.RFQDetails.RFQType);
                    axios.post('/api/data/get_pe_list_per_type', { PEType: this.RFQDetails.RFQType, ProjectId: this.RFQDetails.ProjectId, RequestType: RequestType })
                        .then((response) => {
                            this.RequestForProposalsList = response.data;
                            console.log(this.RequestForProposalsList);
                            console.log(this.RFQDetails.RFQType);
                            this.$nextTick(() => {

                                this.RfPRecordsLoading = false;

                                if(this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'PE'){
                                    //handle switching between materials and services
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#PEMatSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'PE') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'RC') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'RC') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else {
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('.VendorSelection').css('display', 'none');
                                }
                                
                            })        
                        });

                    axios.get('/api/data/get_vendors_list')
                        .then((response) => {
                            this.VendorsList = response.data;                  
                        });
                }

                
            },
            GetPEsRCsListFromProject(ProjectId){
                // self = this;
                if(!this.RFQDetails.ProjectId){
                    Swal('Select Project', 'Please Select Project Before To Display with PEs Menu', 'warning');
                    return false;
                }
                this.RFQFilter = { Country: "", City: "", LoMDesc: "", LoMRC: "", FreeText: "", PERef: "", RCRef: "", AdvP: "", RtP: "", SP: "", UTR: "", MVVal: "", VDC: "", SerDesc: "", SerTyp: "", COF: "", VOC: "", SCD: "", ITV: "", SUOM: "", Term: "", keyword: "" };
                this.RFQDetails.RfPEnquiries = [];
                this.RFQDetails.RFPName = "";
                this.RfPRecordsLoading = true;
                if(this.RFQDetails.RFQType != ""){
                    this.RequestForProposalsList = "";
                    console.log(this.RFQDetails.RFQType);
                    axios.post('/api/data/get_pe_rc_list_per_type', { ListType: this.RFQDetails.RFQType, ProjectId: ProjectId, RequestType: this.RFQDetails.RequestType })
                        .then((response) => {
                            this.RequestForProposalsList = response.data;
                            this.OrigRequestForProposalsList = response.data;
                            this.FilterRequestForProposalsList = response.data;
                            this.ListCounter = { ItemFromLOM: 0, ItemNotLOM: 0, RCFromLOM: 0, RCFromValidRC: 0, ServicesPE: 0, ServicesRC: 0 };
                            this.RequestForProposalsList.map( item => {
                                if(item.enquiry_from_item_master === 'Yes')
                                    this.ListCounter.ItemFromLOM++;
                                if(item.enquiry_from_item_master === 'No')
                                    this.ListCounter.ItemNotLOM++;
                                if(item.rccr_type === 'Material')
                                    this.ListCounter.RCFromLOM++;
                                if(item.enquiry_type === 'Service')
                                    this.ListCounter.ServicesPE++;
                                if(item.rccr_type === 'Service')
                                    this.ListCounter.ServicesRC++;
                                if(item.p_images){
                                    let image_array_string = item.p_images;
                                    let image_string = image_array_string.substring(1, image_array_string.length-1);
                                    let final = image_string.replace(/"/g,"");
                                    var d= final.split(',');
                                    this.p_images = d;
                                    }
                            } );

                            //JobNumbers.auction_types

                            this.$nextTick(() => {

                                this.RfPRecordsLoading = false;

                                if(this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'PE'){
                                    //handle switching between materials and services
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#PEMatSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'PE') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'RC') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'RC') {
                                    //handle switching between materials and services
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', '');
                                    $('.VendorSelection').css('display', '');
                                } else {
                                    $('#PEMatSelCol').css('display', 'none');
                                    $('#PESerSelCol').css('display', 'none');
                                    $('#RCMatSelCol').css('display', 'none');
                                    $('#RCSerSelCol').css('display', 'none');
                                    $('.VendorSelection').css('display', 'none');
                                }
                                
                            })        
                        });

                    axios.get('/api/data/get_vendors_list')
                        .then((response) => {
                            this.VendorsList = response.data;                  
                        });
                    axios.get('/api/data/get-project-currency/'+this.RFQDetails.ProjectId)
                        .then((response) => {
                            this.projectcurrency = response.data;                  
                        });
                    
                }

                
            },
        	GetPEsRCsList(ListType){
                // self = this;
                this.RequestForProposalsList = '';
                this.RfPRecordsLoading = true;
                this.RequestForProposalsList = "";
        		axios.post('/api/data/get_pe_rc_list_per_type', { ListType: ListType, ProjectId: this.RFQDetails.ProjectId, RequestType: this.RFQDetails.RequestType })
	                .then((response) => {
	                    this.RequestForProposalsList = response.data;
                        console.log(this.RequestForProposalsList);
                        console.log(this.RFQDetails.RFQType);
                        // $.each(this.RequestForProposalsList, function(index, val) {
                        //     if(val.p_images.length){
                        //         let image_array_string = val.p_images;
                        //         console.log(image_array_string);
                        //         let image_string = image_array_string.substring(1, image_array_string.length-1);
                        //         let final = image_string.replace(/"/g,"");
                        //         var d= final.split(',');
                        //         this.p_images = d;
                        //     }
                        // });
                        this.$nextTick(() => {
                            
                            this.RfPRecordsLoading = false;
                            if(this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'PE'){
                                //handle switching between materials and services
                                $('#PESerSelCol').css('display', 'none');
                                $('#RCMatSelCol').css('display', 'none');
                                $('#RCSerSelCol').css('display', 'none');
                                $('#PEMatSelCol').css('display', '');
                                $('.VendorSelection').css('display', '');
                            } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'PE') {
                                //handle switching between materials and services
                                $('#PEMatSelCol').css('display', 'none');
                                $('#RCMatSelCol').css('display', 'none');
                                $('#RCSerSelCol').css('display', 'none');
                                $('#PESerSelCol').css('display', '');
                                $('.VendorSelection').css('display', '');
                            } else if (this.RFQDetails.RFQType == 'Materials' && this.RFQDetails.RequestType == 'RC') {
                                //handle switching between materials and services
                                $('#PEMatSelCol').css('display', 'none');
                                $('#PESerSelCol').css('display', 'none');
                                $('#RCSerSelCol').css('display', 'none');
                                $('#RCMatSelCol').css('display', '');
                                $('.VendorSelection').css('display', '');
                            } else if (this.RFQDetails.RFQType == 'Service' && this.RFQDetails.RequestType == 'RC') {
                                //handle switching between materials and services
                                $('#PEMatSelCol').css('display', 'none');
                                $('#PESerSelCol').css('display', 'none');
                                $('#RCMatSelCol').css('display', 'none');
                                $('#RCSerSelCol').css('display', '');
                                $('.VendorSelection').css('display', '');
                            } else {
                                $('#PEMatSelCol').css('display', 'none');
                                $('#PESerSelCol').css('display', 'none');
                                $('#RCMatSelCol').css('display', 'none');
                                $('#RCSerSelCol').css('display', 'none');
                                $('.VendorSelection').css('display', 'none');
                            }
                            
                        })        
	                });

                axios.get('/api/data/get_vendors_list')
                    .then((response) => {
                        this.VendorsList = response.data;                  
                    });

        	},
            CreateQuotation(){
                //check that there is at least one purchase enquiry selected...
                if(this.RFQDetails.RfPEnquiries.length == 0){
                    Swal('No Items were selectecd for The Request For Proposals', 'You must select at least one item before a Request For Proposals can be floated', 'warning');
                } else {
                    this.$refs.ItemCreationForm.validate((validation) => {

                        if(validation){

                            axios.post('/api/quotations_management/create_new_quotation_request', this.RFQDetails)
                                .then((response) => {
                                    Swal({ 
                                        type: response.data.messageType, 
                                        title: response.data.messageTitle, 
                                        text: response.data.message,
                                        showConfirmButton: true,
                                        timer: 5000
                                    });


                                    //Remove Form Details
                                    this.RFQDetails.ProjectId = "";
                                    this.RFQDetails.RFQType = "";
                                    this.RFQDetails.RequestType = "PE";
                                    this.RFQDetails.QuotationDate = "";
                                    this.RFQDetails.RFPName = "";
                                    this.RFQDetails.RFIDate = "";
                                    this.RFQDetails.SourcingType = "";
                                    this.RFQDetails.RfPEnquiries = [];
                                    this.RFQDetails.SelectedVendors = {};
                                    



                                })
                                .catch(function(){
                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (CreatingQuotation)', 'error');
                                });

                            
                        }
                        
                    })
                }


                

                
            },

            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },

        },
        mounted(){

            //CustomJs();
            
            // let self = this;

            axios.get('/api/data/get_company_projects_list')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    
                    axios.get('/api/data/get_company_details')
                    .then((SecondResponse) => {

                        this.CompanySetup = SecondResponse.data;
                        if(this.CompanySetup.setup_mode == 'Company Wide'){
                            this.RFQDetails.ProjectId = Object.keys(this.JobNumbers)[0];
                        }

                    });
                });


	
        }

    }


</script>

<style scoped>


    
    .font-700 {
        font-weight: 700 !important;
    }
    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }

    .sptable tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    #MaterialsModalView .modal-dialog,#ServicesModalView .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    .el-date-picker.has-sidebar.has-time {
        width: 700px;
    }

    .el-select-dropdown{
        z-index: 0 !important;
       
    }
  

    .el-popper {
        z-index: 0 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 0;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 0;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 0;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 0;
    }

    .check_box_css {
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        position:absolute;
        right:0px;
        top:-15px;
        z-index:999;
        opacity:1;
    }

</style>