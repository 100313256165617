<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 style="display: contents;"><b><i>Services Purchase Enquiries</i> List</b></h4>
                    <div class="text-ogeo float-right font-500 text-wrap">
                        <el-switch style="margin-top:0px" v-model="switchbutton" @change="changeTable()" active-color="rgb(255, 47, 47)" inactive-color="rgb(255, 47, 47)" active-text="Linked To Rates’ Contracts" inactive-text="Unrelated To Rates’ Contracts" active-value="Linked" inactive-value="Unlinked"></el-switch>
                    </div>
                </div>
                <div class="card-body">
                    <table ref="table" id="ProjectPEList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th><i>Purchase Enquiry</i> ID#</th>
                                <th>GroupID</th>
                                <th><i>Account</i> Name</th>
                                <th>Initiated By</th>
                                <th>Last Updated On</th>
                                <th>Countries | Cities</th>
                                <th>Short Description</th>
                                <th>Term</th>
                                <th><i>Services Description</i> Headers</th>
                                <th><i>Instructions To Vendors</i> Headers</th>
                                <th><i>Vendor Proposal Content</i> Headers</th>
                                <th>Supporting Documents</th>
                                <th><i>Vendor Commercial Proposal</i> Type</th>  
                                <th><i>Vendor Commercial Proposal</i> Required Format</th>
                                <th><i>Advance Payment</i> Arrangement</th>
                                <th><i>Retention</i> Arrangement</th>             
                                <th>Delivery Location(s)</th>
                                <th>Sourcing Priority</th>
                                <th>Current Level Of Validation</th>
                                <th>List Of Validators</th>
                                <th>Underlying Transaction</th>                              
                                <th>Initiated On</th>           
                                <th>Status</th>
                                <th>Additional Notes</th>
                                <th>Awardee Vendor</th>
                                <th style="width: 30%">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>


        <div id="PurchaseEnquiryUpdateModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalView" aria-hidden="true" style="display: none;"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width: 50%;">
                            <p style="margin-top: 4px;">
                                <span style="margin-left: 50px; display: block; font-size: 1.5em; line-height: 1.3; color: black;" class="modal-title ogeo-text">
                                    <b><b><i>Services Purchase Enquiry</i> Line ID#:</b></b>
                                    <span style="color: rgb(252, 51, 23);"><b>{{ ViewModalInfo.show_id }}</b></span>
                                </span>
                            </p>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                X
                            </button>
                        </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                            <tr>                         
                                                <td style="width: 75%; text-align: right; padding-right: 20px; font-weight: 700;"><b><i>Account</i> Information:</b></td>   
                                                <td width="25%">
                                                        <span><b class="font-700"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                        <span><b class="font-700">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                        <span><b class="font-700">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                        <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                                </td>     
                                            </tr>  
                                            <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                            </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700" style="white-space: nowrap;">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Current Level<br>Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">List Of Validators: </b></td>
                                            <td>
                                                <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">
                                                    <li v-for="(his, index) in validatorsList" :key="index" style="padding-left: 0px;">
                                                        {{ his.name }} <i>(Level {{ his.level }})</i>
                                                        <span v-if="his.date"> | {{ his.date }} | </span>
                                                        <span v-if="his.decision" class="font-italic">({{ his.decision }})</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i><br>Underlying Transaction: </b></td>
                                            <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i>  <br>Type: </b></td>
                                            <td>{{ ViewModalInfo.enquiry_type }}s</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Delivery Address <br>Details:</b></td>
                                            <td>
                                                <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ ViewModalInfo.country_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ ViewModalInfo.country_of_delivery }} </span><br>
                                                <span v-if="ViewModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ ViewModalInfo.cities_of_delivery }}</span><br>
                                                <span><b class="font-700 font-italic">Location Name:</b> {{ ViewModalInfo.location_name }}</span><br>
                                                <span><b class="font-700 font-italic">Longitude:</b> {{ ViewModalInfo.longitude }}</span><br>
                                                <span><b class="font-700 font-italic">Latitude:</b> {{ ViewModalInfo.latitude }}</span><br>   
                                            </td>
                                        </tr>
                                        <tr style="border: none;">
                                            <td colspan="3" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                </table>

                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr>
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Is an <i>Advance Payment</i> Considered For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.advanced_payment }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%"><b class="font-700">Is A Collateral Required From The Awardee Vendor <br>Against The <i>Advance Payment</i> For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr style="border: none;">
                                            <td colspan="3" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                    </tr>
                                    <tr>
                                        <!--<td style="padding-left: 80px;" width="40%" v-if="ViewModalInfo.enquiry_from_item_master == 'Yes'"><b class="font-700">What Is The <i>Retention Percentage</i> For The Items <br>Sourced Under The <i>Services Rates' Contract</i> ?</b></td>-->
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">What Is The <i>Retention Percentage</i> For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage+'%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%"><b class="font-700">What Is The <i>Retention Timeframe</i>* For The <i>Services</i> ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;" >{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr style="border: none;">
                                        <td colspan="4" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                    </tr> 
                                </table>
                                
                                
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Services Information Panel -->
                                            
                            </div>
                            <div class="col-lg-12">  
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry Services</i> <br>Short Description: </b></td>
                                        <td>{{ ViewModalInfo.service_description }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Will These Services Be One-Off Or Will They Be<br>Delivered Over A Period Of Time: </b></td>
                                        <td>{{ ViewModalInfo.service_one_off }}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="ViewModalInfo.service_one_off == 'Over A Period Of Time'" colspan="2" style="padding: 0;"><br style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.service_one_off == 'Over A Period Of Time'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">The Term Of The <i>Services Purchase Enquiry</i>,<br>In Days:</b></td>
                                        <td>{{ ViewModalInfo.service_term }} Days</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="padding: 0;"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Vendor Commercial Proposal</i>  <br>Type: </b></td>
                                        <td>{{ ViewModalInfo.type_of_services_contract }}</td>
                                    </tr>
                                    <tr><td colspan="3"><hr></td></tr>
                                
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Vendor Commercial Proposal</i> <br>Required Format: </b></td>
                                        <td>{{ ViewModalInfo.vendor_commercial_offer }}</td>
                                    </tr>
                                
                                        </tr>
                                        <tr><td colspan="2" style="padding: 0;"><hr style="margin: 10px 0;"></td>
                                    </tr>                                   
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%; border-collapse: collapse; border: none;">
                                                <tr style="border: none;">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Supporting Documents:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                    <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">   
                                                        <li v-for="(his, index) in JSON.parse(ViewModalInfo.sd_images)" :key="index" style="padding-left: 0px;">
                                                            <a :href="'/uploads/ServiceSupportingDocs/' + his">{{ his }}</a>
                                                        </li>
                                                    </ul>
                                                    </td>
                                                </tr>
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                                <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Additional Notes:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                        <span>{{ ViewModalInfo.PELineNote }}</span>
                                                    </td>
                                                </tr> 
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                                <tr v-if="ViewModalInfo.enquiry_type == 'Service'">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Awardee Vendor:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                        <span>{{ 'Not Yet Awarded' }}</span>
                                                    </td>
                                                </tr> 
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>


                                    <tr class="vendorIns" v-if="ViewModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <!-- Container for Instructions To Vendors -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center; width: 100%; box-sizing: border-box;">
                                                    <h3 style="font-style: italic; margin: 0; color: white; width: 100%; padding: 0; text-align: center;" class="italic">
                                                        Instructions To Vendors
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <colgroup>
                                                            <col style="width: 15%;">
                                                            <col style="width: 85%;">
                                                        </colgroup>
                                                        <tr>
                                                            <th style="padding-left: 80px; border: none;" class="font-700 SCTC"><b>Header</b></th>
                                                            <th style="border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in ViewModalInfo.tendererchildheaders.slice(0, 10)" :key="header.header_name">
                                                            <td style="padding-left: 80px; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">
                                            </div>


                                            <!-- Container for Vendor Proposal Content -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center;">
                                                    <h3 style="font-style: italic; margin: 0; color: white;" class="italic">
                                                        Vendor Proposal Content
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <tr>
                                                            <th style="padding-left: 80px; width: 15%; border: none;" class="font-700 SCTH"><b>Header</b></th>
                                                            <th style="width: 85%; border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in ViewModalInfo.vendoroffercontent.slice(0, 10)" :key="header.header_name">
                                                            <td style="padding-left: 80px; width: 15%; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">
                                            </div>

                                            <!-- Container for Services Description -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center;">
                                                    <h3 style="font-style: italic; margin: 0; color: black; font-weight: bold;" class="italic">
                                                        Services Description
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <tr>
                                                            <th style="padding-left: 80px; width: 15%; border: none;" class="font-700 SCTH"><b>Header</b></th>
                                                            <th style="width: 85%; border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in ViewModalInfo.childheaders.slice(0, 15)" :key="header.header_name">
                                                            <td style="padding-left: 80px; width: 15%; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <!--<hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">-->
                                            </div>

                                        </td>
                                    </tr>
                                
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Rates Contracts -->
                            <!-- <div class="col-lg-12" v-if="ViewModalInfo.enquiry_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                                <hr>   
                            </div> -->
                            <!-- List of Valid Rates Contracts End-->

                        </div>                       

                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        
                        <div class="col-lg-12">
                            <h3 style="background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>       
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td><b>{{ item.decision }}</b> - <i>{{ item.decision_notes }}</i></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <b>Date:</b> <span style="color: rgb(0, 0, 0);">{{ new Date() }}</span><br>
                                <b>Document Generated By:</b> <span style="color: rgb(0, 0, 0);">{{ currentUser.Name }}</span> 
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                        </div>
                    <br><br><br><br>
                        <div class="col-lg-24; text-center">
                            <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                            <br>
                            <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                <div class="d-flex justify-content-center mt-3">
                                    <button class="btn btn-success waves-effect text-center" style="box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';" onclick="document.title = 'Services Purchase Enquiry Line Information';window.print()">Download PDF</button>
                                </div>
                        </div>            
                    </div>
                </div>
            </div>
        </div>

        <div id="PurchaseEnquiryUpdateModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="PurchaseEnquiryUpdateModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <span style="margin-left: 50px; display: block; font-size: 1.5em; line-height: 1.3; color: black;" class="modal-title ogeo-text">
                                    <b><i>Services Purchase Enquiry</i> Line ID#:</b>
                                    <span style="color: rgb(252, 51, 23);"><b>{{ EditModalInfo.show_id }}</b></span>
                            </span>
                        </div>
                        <div style="width:50%" v-if="this.CompanySetup.logo">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;border-radius:25px">
                        </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                X
                            </button>
                        </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">
                            
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                            <tr>                         
                                                <td style="width: 75%; text-align: right; padding-right: 20px; font-weight: 700;"><b><i>Account</i> Information:</b></td>   
                                                <td width="25%">
                                                        <span><b class="font-700"><i>Account</i> Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                        <span><b class="font-700">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                        <span><b class="font-700">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                        <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                                </td>     
                                            </tr>  
                                            <tr><td colspan="3"><hr></td></tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ EditModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ EditModalInfo.created_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700" style="white-space: nowrap;">{{ EditModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ EditModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Current Level<br>Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">List Of Validators: </b></td>
                                            <td>
                                                <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">
                                                    <li v-for="(his, index) in validatorsList" :key="index" style="padding-left: 0px;">
                                                        {{ his.name }} <i>(Level {{ his.level }})</i>
                                                        <span v-if="his.date"> | {{ his.date }} | </span>
                                                        <span v-if="his.decision" class="font-italic">({{ his.decision }})</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                           <td>{{ EditModalInfo.sourcing_priority }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i><br>Underlying Transaction: </b></td>
                                            <td>{{ EditModalInfo.underlying_transaction }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i>  <br>Type: </b></td>
                                            <td>{{ EditModalInfo.enquiry_type }}s</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Delivery Address <br>Details:</b></td>
                                            <td>
                                                <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Country of Delivery:</b> {{ EditModalInfo.country_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Countries of Delivery:</b> {{ EditModalInfo.country_of_delivery }}</span><br>
                                                <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span><br>
                                                <span><b class="font-700 font-italic">Location Name:</b> {{ EditModalInfo.location_name }}</span><br>
                                                <span><b class="font-700 font-italic">Longitude:</b> {{ EditModalInfo.longitude }}</span><br>
                                                <span><b class="font-700 font-italic">Latitude:</b> {{ EditModalInfo.latitude }}</span><br>   
                                            </td>
                                        </tr>
                                        <tr style="border: none;">
                                            <td colspan="3" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                </table>

                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    
                                    <tr>
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Is An <i>Advance Payment</i> Considered For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.advanced_payment }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%"><b class="font-700">Is A Collateral Required From The Vendors <br>Against The <i>Advance Payment</i> For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.advanced_payment_guarantee ? EditModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr style="border: none;">
                                            <td colspan="3" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                    </tr>
                                    <tr>
                                        <!--<td style="padding-left: 80px;" width="40%" v-if="EditModalInfo.enquiry_from_item_master == 'Yes'"><b class="font-700">What Is The <i>Retention Percentage</i> For The Items <br>Sourced Under The <i>Services Rates' Contract</i> ?</b></td>-->
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">What Is The <i>Retention Percentage</i> For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.retention_percentage	 ? EditModalInfo.retention_percentage+'%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%"><b class="font-700">What Is The <i>Retention Timeframe</i>* For The <i>Services</i> ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.retention_days ? EditModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr style="border: none;">
                                        <td colspan="4" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                    </tr> 
                                </table>
                                
                            </div>
                            <div class="col-lg-12">  
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry Services</i> <br>Short Description: </b></td>
                                        <td>{{ EditModalInfo.service_description }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Will These Services Be One-Off Or Will They Be<br>Delivered Over A Period Of Time: </b></td>
                                        <td>{{ EditModalInfo.service_one_off }}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="EditModalInfo.service_one_off == 'Over A Period Of Time'" colspan="2" style="padding: 0;"><br style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.service_one_off == 'Over A Period Of Time'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">The Term Of The <i>Services Purchase Enquiry</i>,<br>In Days:</b></td>
                                        <td>{{ EditModalInfo.service_term }} Days</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="padding: 0;"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Vendor Commercial Proposal</i>  <br>Type: </b></td>
                                        <td>{{ EditModalInfo.type_of_services_contract }}</td>
                                    </tr>
                                    
                                    <tr><td colspan="3"><hr></td></tr>
                                    
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Vendor Commercial Proposal</i> <br>Required Format: </b></td>
                                        <td>{{ EditModalInfo.vendor_commercial_offer }}</td>
                                    </tr>
                                        <tr><td colspan="2" style="padding: 0;"><hr style="margin: 10px 0;"></td>
                                    </tr>                                   
                                    <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%; border-collapse: collapse; border: none;">
                                                <tr style="border: none;">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Supporting Documents:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                    <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">   
                                                        <li v-for="(his, index) in JSON.parse(EditModalInfo.sd_images)" :key="index" style="padding-left: 0px;">
                                                            <a :href="'/uploads/ServiceSupportingDocs/' + his">{{ his }}</a>
                                                        </li>
                                                    </ul>
                                                    </td>
                                                </tr>
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                                <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Additional Notes:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                        <span>{{ EditModalInfo.PELineNote }}</span>
                                                    </td>
                                                </tr> 
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                                <tr v-if="EditModalInfo.enquiry_type == 'Service'">
                                                    <th style="padding-left: 80px;" width="40%"><b class="font-700">Awardee Vendor:</b></th>
                                                    <td style="padding-left: 5px; border: none;">
                                                        <span>{{ 'Not Yet Awarded' }}</span>
                                                    </td>
                                                </tr> 
                                                <tr style="border: none;">
                                                    <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                    
                                    <tr class="vendorIns" v-if="EditModalInfo.enquiry_type == 'Service'">
                                        <td colspan="2">
                                            <!-- Container for Instructions To Vendors -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center; width: 100%; box-sizing: border-box;">
                                                    <h3 style="font-style: italic; margin: 0; color: white; width: 100%; padding: 0; text-align: center;" class="italic">
                                                        Instructions To Vendors
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <colgroup>
                                                            <col style="width: 15%;">
                                                            <col style="width: 85%;">
                                                        </colgroup>
                                                        <tr>
                                                            <th style="padding-left: 80px; border: none;" class="font-700 SCTC"><b>Header</b></th>
                                                            <th style="border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in EditModalInfo.tendererchildheaders.slice(0, 10)" :key="header.header_name">
                                                            <td style="padding-left: 80px; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">
                                            </div>
                                            <!-- Container for Vendor Proposal Content -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center;">
                                                    <h3 style="font-style: italic; margin: 0; color: white;" class="italic">
                                                        Vendor Proposal Content
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <tr>
                                                            <th style="padding-left: 80px; width: 15%; border: none;" class="font-700 SCTH"><b>Header</b></th>
                                                            <th style="width: 85%; border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in EditModalInfo.vendoroffercontent.slice(0, 10)" :key="header.header_name">
                                                            <td style="padding-left: 80px; width: 15%; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">
                                            </div>
                                            <!-- Container for Services Description -->
                                            <div style="width: 100%; margin: 0 auto;">
                                                <div style="background-color: rgb(169, 169, 169); padding: 10px 0; text-align: center;">
                                                    <h3 style="font-style: italic; margin: 0; color: black; font-weight: bold;" class="italic">
                                                        Services Description
                                                    </h3>
                                                </div>
                                                <div style="max-height: 400px; overflow-y: auto;">
                                                    <table class="table" style="width: 100%; border-collapse: collapse; table-layout: fixed;">
                                                        <tr>
                                                            <th style="padding-left: 80px; width: 15%; border: none;" class="font-700 SCTH"><b>Header</b></th>
                                                            <th style="width: 85%; border: none;"><b class="font-700 SCTC">Content</b></th>
                                                        </tr>
                                                        <tr v-for="header in EditModalInfo.childheaders.slice(0, 15)" :key="header.header_name">
                                                            <td style="padding-left: 80px; width: 15%; border: none;" class="text-uppercase font-700 vendorInsH">{{ header.header_name }}:</td>
                                                            <td style="border: none; text-align: justify;" class="vendorInsC">{{ header.header_contant }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <!--<hr style="margin: 10px 0; border: none; border-top: 1px solid #ddd;">-->
                                            </div>
                                        </td>
                                    </tr>
                            </div>
                            <!-- List of Valid Purchase Enquiry End-->
                        </div>    
                        
                        <div class="row">
                            <div class="col-lg-12">
                                <hr>

                                <el-form v-if="EditModalInfo.retention_percentage > 0 && !FullyInvoiced && EditModalInfo.receipt_note" :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseRequestRetentionUpdateForm">
                                <el-row :gutter="20">
                                    <el-col :span="24">

                                        <el-col :span="24">
                                            <hr>
                                            <h5>Adjust The Retention Timeframe</h5>
                                        </el-col>
                                        
                                        <el-col :span="24" >
                                            <div class="grid-content">
                                                <el-form-item label="Adjust The Retention Timeframe" prop="RetentionDays">
                                                        
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                The Rentention Timeframe initially opted for at the time of the Purchase Enquiry origination, can be revisited at any time as long as the invoicing by the vendor, hasn't been completed in full.
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="ValidationInfo.RetentionDays" placeholder="Select Retention Days From Delivery date">
                                                        
                                                        <el-option v-for="n in ValidationInfo.RetentionDays" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <div class="col-lg-12 float-right">
                                            <button type="button" @click="PurchaseEnquiryRententionUpdate" class="btn btn-block btn-success waves-effect">Update Retention Timeframe</button>
                                        </div>
                                        
                                    </el-col>
                                </el-row>
                            </el-form>   









                            <!-- Show approval form -->
                            <div class="row" v-if="EditModalInfo.status.indexOf('Created and Assigned to ') !== -1 || EditModalInfo.status.indexOf('PE line Approved by Level') !== -1 || EditModalInfo.status.indexOf('Resubmitted and Assigned') !== -1 || EditModalInfo.status.indexOf('for Review') !== -1">
                                <div class="col-lg-12">
                                    
                                <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                            <div class="col-lg-12">   
                                                <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                                 
                                                            <div class="alert alert-info" style="background-color: rgba(0, 128, 0, 0.09); text-align: left; margin-top: 0; border: 2px solid rgba(0, 128, 0, 0.09); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">
                                                                    <table style="width: 100%; border-collapse: collapse;">
                                                                        <tr>
                                                                            <td colspan="5" style="
                                                                                background-color: rgba(0, 128, 0); 
                                                                                color: white; 
                                                                                text-align: center; 
                                                                                padding: 0;
                                                                                border: none;
                                                                            ">
                                                                                <div style="
                                                                                    display: flex;
                                                                                    align-items: center;
                                                                                    justify-content: center;
                                                                                    width: 100%;
                                                                                ">
                                                                                    <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                                    <span style="
                                                                                        color: white; 
                                                                                        padding: 5px 20px; 
                                                                                        font-size: 20px; 
                                                                                        font-weight: bold;
                                                                                    ">
                                                                                        DECISION
                                                                                    </span>
                                                                                    <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="5" style="
                                                                                border: none; 
                                                                                border-top: 1px solid rgba(0, 0, 0, 0.1); 
                                                                                height: 15px;
                                                                            ">
                                                                                <!-- Empty row for horizontal line -->
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                                <!-- Column 1 Content -->
                                                                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>
                                                                            </td>
                                                                            <td style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                                <!-- Column 2 Content -->
                                                                                <p style="margin: 0;">
                                                                                    <b>If a <i>Services Purchase Enquiry</i> line is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role. <br><br>They can either delete the line permanently from the <i>Services Purchase Enquiry</i> 
                                                                                    or modify some details and resubmit it for validation.</b>
                                                                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII="/>
                                                                                </p>
                                                                                <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                            </td>
                                                                            <td style="width: 5px; vertical-align: top; text-align: left;">
                                                                                <!-- Column 3 Content -->
                                                                                <!-- This column can contain additional content or be left empty as needed -->
                                                                            </td>
                                                                            <td style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.2; padding-left: 80px;">
                                                                                <p>Since this is a case of <i>Services Purchase Enquiry</i> that's unrelated to any <i>Services Rates' Contract</i>:  
                                                                                </p>
                                                                                <!-- Column 4 Content -->
                                                                                <ul style="margin-top: 0; padding-left: 0;">
                                                                                    <li style="margin-bottom: 5px;">
                                                                                        The <b><i>Instructions To Vendors</i></b>, the <b><i>Vendor Proposal Content</i></b>, the <b><i>Services Description</i></b>,
                                                                                        the <b><i>Vendor Commercial Proposal</i> Type</b> and <b>Format</b>, the <b><i>Advance Payment</i> Arrangement</b>, and the <b><i>Retention</i> Arrangement</b> <u>can be amended</u>.
                                                                                    </li>
                                                                                </ul>
                                                                                <ul style="margin-top: 0; padding-left: 0;">
                                                                                    <li style="margin-bottom: 5px;">
                                                                                        The <b><i>Account</i></b>, the <b><i>Purchase Enquiry</i> Type</b>, the <b>Underlying Transaction Reference</b> and the <b>Sourcing Priority</b> <u>can’t be amended</u>.
                                                                                    </li>
                                                                                </ul>
                                                                            </td>
                                                                            <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                                <!-- Column 5 Content -->
                                                                                <!-- This column can contain additional content or be left empty as needed -->
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                           
                                                            </div>
                                                    
                                                    <!-- Remaining form items as before -->
                                                    <div class="col-lg-12">
                                                        <div class="grid-content">
                                                            <el-form-item prop="DecisionNotes">
                                                                <el-input
                                                                type="textarea"
                                                                :rows="3"
                                                                placeholder="Enter Decision Notes. Notes are mandatory when the 'Services Purchase Enquiry' is rejected."
                                                                v-model="ValidationInfoApproval.DecisionNotes"
                                                                style="box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); border: 1px solid #dcdfe6; transition: box-shadow 0.3s ease;">
                                                                </el-input>
                                                            </el-form-item>
                                                        </div> 
                                                    </div>

                                                    <!-- Approve and Reject buttons -->
                                                    <div class="col-lg-6 float-right">
                                                        <button type="button" @click="DecisionApprove" 
                                                        class="btn btn-block btn-success waves-effect" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Validate
                                                    </button>
                                                    </div>

                                                    <div class="col-lg-6 float-left">
                                                        <button type="button" @click="DecisionReject" 
                                                        class="btn btn-block btn-danger waves-effect" 
                                                        style="background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Reject
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                                
                                </el-form>
 

                                </div>
                            </div>


                            <div class="row" v-else-if="EditModalInfo.status.indexOf('for Further Approval') !== -1">
                                <div class="col-lg-12">
                                    
                                    <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                
                                                <!-- <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item label="Approve Or Reject Request" prop="Decision">
                                                            <el-select @change="DecisionSelection" style="width: 100%; padding: 0px;" v-model="ValidationInfoApproval.Decision" placeholder="Select Yes/No">
                                                                  <el-option label="Approve" value="Approved"></el-option>
                                                                  <el-option label="Reject" value="Rejected"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div> 
                                                </div> -->
                                                
                                                <h3 style="background-color: rgba(0, 128, 0); color: white; padding-left: 10px; text-align: center; margin: 0; border: 5px solid rgb(0, 128, 0);">DECISION2</h3>
                                                <el-row>
                                                    <el-col :span="24">   
                                                        <div class="alert alert-info" style="background-color: rgba(0, 128, 0, 0.09); text-align: left; margin-top: 0; border: 2px solid rgba(0, 128, 0, 0.09);">
                                                            <div style="display: flex; align-items: flex-start; flex-wrap: wrap;">   
                                                                <div style="flex: 0 0 2%; color: #008000; display: flex; align-items: flex-start; justify-content: left;">
                                                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>
                                                                </div>
                                                                <div style="flex: 1; color: black; font-size: 17px; line-height: 1.3;">
                                                                    <p style="margin: 0;"><b>If a <i>Services Purchase Enquiry</i> line is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role.<br><br>They can either delete the line permanently from the <i>Services Purchase Enquiry</i> 
                                                                    or modify some details and resubmit it for validation.</b>
                                                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII="/>
                                                                    </p>
                                                                    <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                </div>
                                                                
                                                                <div style="margin-top: 10px; color: black; font-size: 17px; line-height: 1.2;">
                                                                    <ul style="margin-top: 0; padding-left: 130px;">
                                                                        <p>Since this is a case of a <i>Services Purchase Enquiry</i> that's unrelated to any <i>Services Rates' Contract</i>:  
                                                                        </p>
                                                                        <li style="margin-bottom: 5px;">The <b><i>Instructions To Vendors</i></b>, the <b><i>Vendor Proposal Content</i></b>, the <b><i>Services Purchase Enquiry</i></b> Description,
                                                                            the <b><i>Vendor Commercial Proposal</i></b> Type and <b>Format</b>, the <b><i>Advance Payment</i> Arrangement</b>, and the <b><i>Retention</i> Arrangement</b> <u>can be amended</u>.</li>
                                                                    </ul>
                                                                    <ul style="margin-top: 0; padding-left: 130px;">
                                                                        <li style="margin-bottom: 5px;">The <i>Account</i>, the <i>Purchase Enquiry</i> Type, the <b>Underlying Transaction Reference</b> and the <i>Sourcing Priority</i> can’t be amended.</li>
                                                                    </ul> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                                
                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item prop="DecisionNotes">
                                                            <el-input
                                                              type="textarea"
                                                              :rows="5"
                                                              placeholder="Enter Decision Notes"
                                                              v-model="ValidationInfoApproval.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="DecisionApprove" 
                                                        class="btn btn-block btn-success waves-effect" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Validate
                                                    </button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionReject" 
                                                        class="btn btn-block btn-danger waves-effect" 
                                                        style="background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Reject
                                                    </button>
                                                </div>

                                                <!-- <div class="col-lg-12">
                                                    <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                                </div> -->
                                                    
                                                
                                            </div>
                                        </div>
                                    </el-form> 

                                </div>
                            </div>

                            <div class="col-lg-12" v-else-if="EditModalInfo.status.includes('Rejected') && !showAmend">
                                
                                <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryApprovalForm">
                                    <div class="row">
                                        <div class="col-lg-12">   
                                            <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                                 
                                                <div class="alert alert-info" style="background-color: rgba(0, 128, 0, 0.09); text-align: left; margin-top: 0; border: 2px solid rgba(0, 128, 0, 0.09); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">
                                                        <table style="width: 100%; border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        background-color: rgba(0, 128, 0); 
                                                                        color: white; 
                                                                        text-align: center; 
                                                                        padding: 0;
                                                                        border: none;
                                                                    ">
                                                                        <div style="
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: center;
                                                                            width: 100%;
                                                                        ">
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                            <span style="
                                                                                color: white; 
                                                                                padding: 5px 20px; 
                                                                                font-size: 20px; 
                                                                                font-weight: bold;
                                                                            ">
                                                                                DECISION
                                                                            </span>
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        border: none; 
                                                                        border-top: 1px solid rgba(0, 0, 0, 0.1); 
                                                                        height: 15px;
                                                                    ">
                                                                        <!-- Empty row for horizontal line -->
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 1 Content -->
                                                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>
                                                                    </td>
                                                                    <td style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <!-- Column 2 Content -->
                                                                        <p style="margin: 0;">
                                                                            <b>If a <i>Services Purchase Enquiry</i> line is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role. <br><br>They can either delete the line permanently from the <i>Services Purchase Enquiry</i> 
                                                                            or modify some details and resubmit it for validation.</b>
                                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII="/>
                                                                        </p>
                                                                        <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                    </td>
                                                                    <td style="width: 5px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 3 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                    <td style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.2; padding-left: 80px;">
                                                                        <!-- Column 4 Content -->
                                                                        <p>Since this is a case of a <i>Services Purchase Enquiry</i> that's unrelated to any <i>Services Rates' Contract</i>:  
                                                                        </p>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                                The <b><i>Instructions To Vendors</i></b>, the <b><i>Vendor Proposal Content</i></b>, the <b><i>Services Description</i></b>,
                                                                                the <b><i>Vendor Commercial Proposal</i> Type</b> and <b>Format</b>, the <b><i>Advance Payment</i> Arrangement</b>, and the <b><i>Retention</i> Arrangement</b> <u>can be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                                The <b><i>Account</i></b>, the <b><i>Purchase Enquiry</i> Type</b>, the <b>Underlying Transaction Reference</b> and the <b>Sourcing Priority</b> <u>can’t be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 5 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                </tr>
                                                        </table>
                                                        </div>
                                                    

                                                        <div class="col-lg-12">
                                                            <div class="grid-content">
                                                                <el-form-item class="decision" prop="DecisionNotes">
                                                                    <el-input
                                                                        type="textarea"
                                                                        :rows="3"
                                                                        placeholder="Enter Decision Notes. Notes aren't mandatory at this stage, if the 'Services Purchase Enquirt' is to be permanently deleted."
                                                                        v-model="ValidationInfo.DecisionNotes"
                                                                        style="box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); border: 1px solid #dcdfe6; transition: box-shadow 0.3s ease;">
                                                                    </el-input>
                                                                </el-form-item>
                                                            </div>
                                                        </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="showAmend = true" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);" class="btn btn-block btn-success waves-effect"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Amend The <i>Services Purchase Enquiry</i> And Resubmit For Validation
                                                    </button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionRejectPermanent" 
                                                    class="btn btn-block btn-danger waves-effect" style="background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">                                                
                                                    Delete The <i>Services Purchase Enquiry</i></button>
                                                </div>

                                            <!-- <div class="col-lg-12">
                                                <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                            </div> -->
                                                
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                </el-form>
                                
                            </div>

                            <!-- Form for update and Resubmitt -->
                                <div class="col-lg-12" v-else>
                                    <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                        <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="PeEditRules" ref="PurchaseEnquiryForm">
                                            
                                            <el-row :gutter="20">
                                                <el-col :span="24">
                                                    <div class="grid-content">
                                                        <h3 style="
                                                            background-color: #008000; 
                                                            color: white; 
                                                            text-align: center; 
                                                            padding: 10px; 
                                                            position: relative; 
                                                            width: 100%; 
                                                            margin: 0; 
                                                            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);">
                                                            <span style="background-color: #008000; padding: 0 20px; position: relative; z-index: 1;">
                                                                Amend The <i>Services Purchase Enquiry</i>
                                                            </span>
                                                            <div style="
                                                                position: absolute;
                                                                top: 50%; 
                                                                left: 0; 
                                                                right: 0; 
                                                                height: 1px; 
                                                                background-color: white; 
                                                                z-index: 0;">
                                                            </div>
                                                        </h3>     
                                                    </div>
                                                    <br>
                                                </el-col>   

                                                    <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                                        <el-col :span="24">
                                                            <div class="grid-content">
                                                                <el-form-item label="temp" prop="JobNumber">
                                                                    <span slot="label"><b>The <i>Account</i></b></span>

                                                                    <el-select disabled id="SetupSelection" @change="getProjectCurrency()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select an Account">
                                                                        
                                                                        <!-- <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option> -->
                                                                        
                                                                    </el-select>
                                                                </el-form-item>
                                                            </div>
                                                        </el-col>
                                                    </transition>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                                        <div class="grid-content">
                                                            <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                                                <span slot="label"><b>The <i>Purchase Enquiry</i> type</b></span>

                                                                <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Select 'Materials Purchase Enquiry' or 'Services Purchase Enquiry'">
                                                                        
                                                                    <el-option label="Materials" value="Materials"></el-option>
                                                                    <el-option label="Services" value="Service"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                        <div class="grid-content">
                                                            <el-form-item label="temp" prop="SourcingPriority">
                                                                <span slot="label"><b>The sourcing priority</b></span>

                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span style="bottom: 23px; left: -12px; width: 680px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <p>The sourcing priority but can be set to <i>Standard</i>, <i>Urgent</i> or <i>Very Urgent</i>, as needed.</p>
                                                                <p>This setting determines the urgency with which proposals from vendors should be sourced during the tendering process.</p>
                                                            </span>
                                                        </span>
                                                        </span>
                                                                
                                                                <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                                        
                                                                    <el-option label="Standard" value="Standard"></el-option>
                                                                    <el-option label="Urgent" value="Urgent"></el-option>
                                                                    <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                                        
                                                                </el-select>

                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service')">
                                                        
                                                        <div class="grid-content">
                                                            <el-form-item label="temp" prop="UnderlyingTransaction">
                                                                <span slot="label"><b>Amend the underlying transaction reference ?</b></span>

                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="fa-stack fa-1x">
                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                    </span>
                                                                </span>
                                                        <span style="bottom: 23px; left: -200px; width: 680px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                            <strong>The Underlying Transaction Reference</strong> is 
                                                                            the reference of the transaction that has led to the initiation
                                                                            of this specific Purchase Enquiry. It can be a particular Job 
                                                                            Ticket, a Sales Order, a Work Order, a Client Request,…etc
                                                                        </span>
                                                                    </span>
                                                                </span>

                                                                <el-input disabled v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    

                                                    <!-- Delivery Location Selection -->
                                                    <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <div class="grid-content">
                                                            <el-form-item prop="Country">
                                                                <span slot="label"><b>Select Country</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                                    <span class="tooltip-item2">
                                                                        <span class="fa-stack fa-1x">
                                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                        </span>
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: 100px">
                                                                        <span class="tooltip-text2">Select the country where delivery is needed.</span>
                                                                    </span>
                                                                </span>
                                                                <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country"> -->
                                                                <el-select disabled filterable collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Country">
                                                                    
                                                                    
                                                                    <el-option
                                                                        v-for="(item,CompanyCountry) in countries"
                                                                        :key="CompanyCountry"
                                                                        :label="CompanyCountry"
                                                                        :value="CompanyCountry">
                                                                        <span style="float: left">{{ CompanyCountry }}</span>
                                                                    </el-option>

                                                                    
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>                                   
                                                    <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <div class="grid-content">
                                                            <el-form-item prop="Cities">
                                                                <span slot="label"><b>Select City</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                                    <span class="tooltip-item2">
                                                                        <span class="fa-stack fa-1x">
                                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                        </span>
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: -100px">
                                                                        <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                                                    </span>
                                                                </span>
                                                                <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                                                <el-select disabled filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select City" @change="checkCityRC($event)">
                                                                    
                                                                    <el-option
                                                                        v-for="(item,companyCity) in cities"
                                                                        :key="companyCity"
                                                                        :value="item"
                                                                        :label="item">
                                                                    <span style="float: left">{{ item }}</span>
                                                                    </el-option>
                                                                    
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <el-form-item label="temp">
                                                            <span slot="label"><b>Select the Delivery Location Address On The Map</b></span>

                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    
                                                                </span>
                                                                <span class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        You may use Google Map and drop a pin in the location of delivery.                                            
                                                                    </span>
                                                                </span>
                                                            </span>

                                                            <button disabled v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" type="button"  data-toggle="modal" id="get_map" data-target="#DeliveryLocation" class="btn btn-primary btn-block waves-effect text-center">Select Delivery Location</button>
                                                        </el-form-item>
                                                    </el-col> 
                                                    <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <el-form-item label="temp" prop="Longitude">
                                                            <span slot="label"><b>Delivery Longitude</b></span>
                                                            <el-input disabled type="number" readonly  placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <el-form-item label="temp" prop="Latitude">
                                                            <span slot="label"><b>Delivery Latitude</b></span>
                                                            <el-input disabled type="number"  readonly placeholder="Enter Location Longitude Or Select From Map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <el-form-item label="temp" prop="LocationName">
                                                            <span slot="label"><b>Location Name</b></span>
                                                            <el-input disabled type="text" placeholder="Enter Location Name" v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    
                                                    <!-- Delivery Location Selection -->
                                                    
                                                    
                                                    <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col>

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                        <div class="grid-content">
                                                            <el-form-item label="temp">
                                                                <span slot="label"><b>Amend the <i>Instructions To Vendors</i> ?</b></span>
                                                                <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                            </span>
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -125px; width: 715px; z-index: 10000;">
                                                                            <span class="tooltip-text2">
                                                                                These Headers & their Contents help structure the <i>Instructions To Vendors</i>. They shall, for example, cover aspects such as:
                                                                                <ul>
                                                                                    <li>Pre-qualification Questionnaire (if applicable);</li>
                                                                                    <li>Cover Letter;</li>
                                                                                    <li>Award Timeline;</li>
                                                                                    <li>Confidentiality Arrangements;</li>
                                                                                    <li>Proposal Evaluation Criteria;</li>
                                                                                    <li>Clarification Meeting Arrangements;</li>
                                                                                    <li>Request for Information Arrangements;</li>
                                                                                    <li>Sites Visits Arrangements;</li>
                                                                                    <li>Health and Safety Requirements;</li>
                                                                                    <li>Compliance Requirements;</li>
                                                                                    <li>Insurrance Cover Requirements;</li>
                                                                                    <li>Proposal Submission Checklist;</li>
                                                                                    <li>.......</li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                                    <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                    <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                                <tr>
                                                                                    <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                                    <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                                    <th class="text-center" style="width: 5%;"></th>
                                                                                </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr style="background-color: white;">
                                                                            <td style="width: 25%;">
                                                                                        <div style="display: flex; width: 100%;">
                                                                                            <input class="form-control" v-model="tenderercontractheader.name" placeholder="e.g. Instruction #1:" style="flex: 1;">
                                                                                        </div>
                                                                            </td>
                                                                            <td style="width: 70%; position: relative;">
                                                                                        <div style="display: flex; width: 100%;">
                                                                                            <input class="form-control" v-model="tenderercontractheader.contant" placeholder="e.g. The Evaluation Criteria are described below: ......." style="flex: 1;">
                                                                                        </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <button v-if="tenderercontractheader.name.trim().length > 0 && tenderercontractheader.contant.trim().length > 0" 
                                                                                    @click.prevent="addTendererContractHeader()"
                                                                                    class="btn-pointer btn-success btn-floating"
                                                                                    style="
                                                                                        height: 40px; 
                                                                                        width: 40px; 
                                                                                        margin: 0; 
                                                                                        margin-top: -5px; 
                                                                                        padding: 0 13px; 
                                                                                        background-color: #008000; 
                                                                                        border-color: #008000; 
                                                                                        color: white; 
                                                                                        box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                        transition: all 0.3s ease;"
                                                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                        <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.TendererContractHeaders"
                                                                            :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                            <td style="width: 25%; font-weight: bold;">
                                                                                <input v-model="tenderheader.header_name" class="form-control">
                                                                            </td>
                                                                            <td style="width: 70%; position: relative;">
                                                                                <input v-model="tenderheader.header_contant" class="form-control">
                                                                            </td>
                                                                            <td class="text-center" style="width: 5%;">
                                                                                <button type="button" @click="deleteTendererHeader(index)" 
                                                                                    class="btn-pointer btn-danger btn-floating"
                                                                                    style="
                                                                                        padding: 0px 13px; 
                                                                                        background-color: #f10623; 
                                                                                        border-color: #f10623; 
                                                                                        color: white; 
                                                                                        box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                        transition: all 0.3s ease;"
                                                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>                                           
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.EnquiryType == 'Service'" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">
                                                            <el-form-item label="temp">
                                                                <span slot="label"><b>Amend the <i>Vendor Proposal Content</i> ?</b></span>
                                                            

                                                                    <!-- Tooltip -->
                                                                    <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                            </span>
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -138px; width: 900px; z-index: 10000;">
                                                                            <span class="tooltip-text2">
                                                                                These Headers & their Contents provide clear descriptions of what the vendors must submit to support their commercial proposals.
                                                                                The <i>Vendor Proposal Content</i> may include:
                                                                                <ul>
                                                                                    <li>Company Profile;</li>
                                                                                    <li>Capability Statements;</li>
                                                                                    <li>Key Personnel Bios;</li>
                                                                                    <li>Compliance Statements;</li>
                                                                                    <li>Technical Proposal;</li>
                                                                                    <li>Method Statements;</li>
                                                                                    <li>Service Delivery Methodology;</li>
                                                                                    <li>Value Proposition;</li>
                                                                                    <li>Project Plan and Milestones;</li>
                                                                                    <li>Supply Chain Plan;</li>
                                                                                    <li>Health and Safety Requirements;</li>
                                                                                    <li>References and Case Studies;</li>                                                       
                                                                                    <li>Customers Statements;</li> 
                                                                                    <li>Warranties and Supports;</li>
                                                                                    <li>Products Catalog;</li>
                                                                                    <li>Financial Statements;</li>
                                                                                    <li>Business Licenses;</li>
                                                                                    <li>Proofs of Insurance;</li>
                                                                                    <li>Certifications by third-parties;</li>
                                                                                    <li>Audit Reports;</li>
                                                                                    <li>......</li>
                                                                                </ul>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                            <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                                <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                            <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                                <tr>
                                                                                    <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                                    <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                                    <th class="text-center" style="width: 5%;"></th>
                                                                                </tr>
                                                                            </thead>
                                                                    <tbody>
                                                                        <tr style="background-color: white;">
                                                                            <td>
                                                                                <input class="form-control" v-model="vendoroffercontent.name" placeholder=" e.g. The Service Delivery Methodology:" style="flex: 1;"> 
                                                                            </td>
                                                                            <td>
                                                                                <input class="form-control" v-model="vendoroffercontent.contant" placeholder=" e.g. The Service Delivery Methodology must include ....." style="flex: 1;">
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <button v-if="vendoroffercontent.name.trim().length > 0 && vendoroffercontent.contant.trim().length > 0" 
                                                                                    @click.prevent="addVendorOfferContent()"
                                                                                    class="btn-pointer btn-success btn-floating"
                                                                                    style="
                                                                                        height: 40px; 
                                                                                        width: 40px; 
                                                                                        margin: 0; 
                                                                                        margin-top: -5px; 
                                                                                        padding: 0px 13px; 
                                                                                        background-color: #008000; 
                                                                                        border-color: #008000; 
                                                                                        color: white; 
                                                                                        box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                        transition: all 0.3s ease;"
                                                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.VendorOfferContent"
                                                                            :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                            <td style="width: 25%; font-weight: bold;">
                                                                                <input v-model="tenderheader.header_name" class="form-control">
                                                                            </td>
                                                                            <td style="width: 70%; position: relative;">
                                                                                <input v-model="tenderheader.header_contant" class="form-control">
                                                                            </td>
                                                                            <td class="text-center" style="width: 5%;">
                                                                                <button type="button" @click="deleteTendererHeader(index)" 
                                                                                    class="btn-pointer btn-danger btn-floating"
                                                                                    style="
                                                                                        padding: 0px 13px; 
                                                                                        background-color: #f10623; 
                                                                                        border-color: #f10623; 
                                                                                        color: white; 
                                                                                        box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                        transition: all 0.3s ease;"
                                                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.EnquiryType == 'Service'" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">
                                                            <el-form-item label="temp">
                                                                <span slot="label"><b>Amend the <i>Services Description</i> ?</b></span>
                                                                <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                                    <span class="tooltip-item2">
                                                                        <span class="fa-stack fa-1x">
                                                                            <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                        </span>
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -160px; width: 915px; z-index: 10000;">
                                                                        <span class="tooltip-text2">
                                                                            These Headers and their Contents help better structure the <i>Services Purchase Enquiry</i> and describe what the Awardee Vendor must deliver and/or comply with.
                                                                            They can include, for example, detailed descriptions of:
                                                                            <ul>
                                                                                <li>Scope of Works;</li>
                                                                                <li>Deliverables;</li>
                                                                                <li>Inclusions;</li>
                                                                                <li>Exclusions;</li>
                                                                                <li>Terms and Conditions;</li>
                                                                                <li>Service Levels Agreements,</li>
                                                                                <li>KPIs, Performance Metrics, and Penalties;</li>
                                                                                <li>Activities;</li>
                                                                                <li>Project Plan and Milestones;</li>
                                                                                <li>Supply Chain Plan;</li>
                                                                                <li>Health and Safety Plan;</li>
                                                                                <li>Bank Guarrantees & Bonds;</li>
                                                                                <li>Reporting and Reviews;</li>
                                                                                <li>Confidentiality and Data Protection;</li>
                                                                                <li>Dispute Resolution;</li>
                                                                                <li>Compliance and Regulations;</li>
                                                                                <li>Indemnity and Liability;</li>
                                                                                <li>Change Management Arrangements;</li>
                                                                                <li>Contact Information;</li>
                                                                                <li>.....</li>
                                                                            </ul>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                                        <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                            <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                                <tr>
                                                                                    <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                                    <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                                    <th class="text-center" style="width: 5%;"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr style="background-color: white;">
                                                                                    <td style="width: 25%;">
                                                                                        <input class="form-control" v-model="contractheader.name" placeholder="e.g. 1. Scope of Works:" style="flex: 1; color: rgb(251, 23, 31);">
                                                                                    </td>
                                                                                    <td style="width: 70%; position: relative;">
                                                                                        <input class="form-control" v-model="contractheader.contant" placeholder="e.g. The Awardee Vendor shall deliver the following: ......." style="flex: 1; font-size: 16px;" />
                                                                                    </td>
                                                                                    <td class="text-center" style="width: 5%;">
                                                                                        <button v-if="contractheader.name.trim().length > 0 && contractheader.contant.trim().length > 0"
                                                                                            type="button" @click="addContractHeader()" 
                                                                                            class="btn-pointer btn-success btn-floating"
                                                                                            style="
                                                                                                height: 40px; 
                                                                                                width: 40px; 
                                                                                                margin: 0; 
                                                                                                margin-top: -5px; 
                                                                                                padding: 0px 13px; 
                                                                                                background-color: #008000; 
                                                                                                border-color: #008000; 
                                                                                                color: white; 
                                                                                                box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                                transition: all 0.3s ease;"
                                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                            <i class="fa fa-plus"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr v-for="(header, index) in PurchaseEnquiryLine.ContractHeaders"
                                                                                    :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                                    <td style="width: 25%; font-weight: bold;">
                                                                                        <input v-model="header.header_name" class="form-control">
                                                                                    </td>
                                                                                    <td style="width: 70%; position: relative;">
                                                                                        <input v-model="header.header_contant" class="form-control">
                                                                                    </td>
                                                                                    <td class="text-center" style="width: 5%;">
                                                                                        <button type="button" @click="deleteContractHeader(index)" 
                                                                                            class="btn-pointer btn-danger btn-floating"
                                                                                            style="
                                                                                                padding: 0px 13px; 
                                                                                                background-color: #f10623; 
                                                                                                border-color: #f10623; 
                                                                                                color: white; 
                                                                                                box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                                transition: all 0.3s ease;"
                                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                            <i class="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.EnquiryType == 'Service'" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >  
                                                        <div class="grid-content">
                                                            <el-form-item style="font-weight: bolder" label="temp" prop="TypeOfServicesContract">
                                                                <span slot="label"><b>Amend the <i>Vendor Commercial Proposal</i> Type ?</b></span>                                            
                                                                        <el-select @change="ClearServiceTypeOptions()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.TypeOfServicesContract" placeholder="Select 1 of the 3 options: 'Fixed-Price Proposal', 'Cost-Reimbursement Proposal', or 'Cost-Plus-Fixed-Fee Proposal'">         
                                                                            <el-option value="Fixed-Price Proposal">Fixed-Price Proposal
                                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                                    <span class="tooltip-item2">
                                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>       
                                                                                    </span>
                                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                                        <span class="tooltip-text2">
                                                                                            <p>A <i>Fixed-Price Proposal</i>, also known as a Lump Sum Proposal,
                                                                                            is used in situations where <br>the payment doesn’t depend on the
                                                                                            resources used or time expended. </p>
                                                                                            <p>With a <i>Fixed-Price Proposal</i>, 
                                                                                            vendors will estimate the total allowable costs of labor, <br> 
                                                                                            materials and equipment, and perform the action specified by
                                                                                            the contract regardless<br> of their actual cost.</p>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </el-option>
                                                                            <el-option value="Cost-Reimbursement Proposal">Cost-Reimbursement Proposal
                                                                                <span style="position: relative; z-index: 1" class="mytooltip tooltip-effect-2">
                                                                                    <span class="tooltip-item2">
                                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                                    </span>
                                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                                        <span class="tooltip-text2">
                                                                                            <p>With a <i>Cost-Reimbursement Proposal</i>, the final
                                                                                            total cost is determined when the <i>Service</i><br> is completed
                                                                                            or at another predetermined date within the <i>Service’s</i> timeframe.</p>
                                                                                            <p>Before the <i>Service</i> is started, the <i>Vendor Proposal</i> establishes a ceiling price that the <br>vendor
                                                                                            shouldn’t exceed without the approval of the organization.</p> 
                                                                                            <p>At the same time, if that ceiling is reached, the vendor can stop the <i>Service</i>.</p>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </el-option>
                                                                            <el-option value="Cost-Plus-Fixed-Fee Proposal">Cost-Plus-Fixed-Fee Proposal
                                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                                    <span class="tooltip-item2">
                                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>             
                                                                                    </span>
                                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                                        <span class="tooltip-text2">
                                                                                            <p>A <i>Cost-Plus-Fixed-Fee Proposal</i> is a type of <i>Vendor Proposal</i>
                                                                                            for situations where the<br> organization agrees to pay the
                                                                                            actual cost of the entire <i>Services</i>, including labor, materials,<br>
                                                                                            and any unexpected expenses.</p>
                                                                                            <p>The word “Plus” refers to the fee that covers the vendor’s profits and overhead.</p>
                                                                                            <p>In this case, the organization agrees to pay that extra
                                                                                            amount and expects the vendor<br> to deliver on their promise.</p>

                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </el-option>           
                                                                        </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-Price Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service' " >
                                                        <div class="grid-content">
                                                            <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                                <span slot="label"><b>Amend the <i>Vendor Commercial Proposal</i>  Required Format ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                            </span>
                                                                        </span>
                                                                        <span style="bottom: 25px; left: -292px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                            <span class="tooltip-text2">
                                                                                <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                                specified required format. </p>
                                                                            <p>In the case of a <i>Fixed-Price Proposal</i>, vendors are required to either submit:
                                                                                <ol>
                                                                                <li>Their total price (as a lump sum value); or</li> 
                                                                                <li>The breakdown of manpower, subcontractors, tools/equipment & materials prices.</li> 
                                                                                </ol></p>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" >
                                                                        
                                                                    <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                                                    <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Reimbursement Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">
                                                            <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                                <span slot="label"><b>Amend the <i>Vendor Commercial Proposal</i>  Required Format ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                            </span>
                                                                        </span>
                                                                        <span style="bottom: 25px; left: -292px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                            <span class="tooltip-text2">
                                                                                <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                                specified required format. </p>
                                                                                <p>In the case of a <i>Cost-Reimbursement Proposal</i>, vendors are required to either submit:
                                                                                <ol>
                                                                                    <li>Their total reimbursment cost; or</li> 
                                                                                    <li>The breakdown of manpower, subcontractors, tools/equipment & materials reimbursement costs.</li>
                                                                                </ol></p>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                
                                                                <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer">
                                                                        
                                                                    <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                                                    <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Plus-Fixed-Fee Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">
                                                            <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                                <span slot="label"><b>Amend the <i>Vendor Commercial Proposal</i>  Required Format ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                            </span>
                                                                        </span>
                                                                        <span style="bottom: 25px; left: -292px; width: 1150px;" class="tooltip-content4 clearfix">
                                                                            <span class="tooltip-text2">
                                                                                <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                                specified required format.</p>
                                                                                <p>In the case of <i>Cost-Plus-Fixed-Fee Proposal</i>, vendors are required to either submit:
                                                                                <ol>
                                                                                    <li>Their total cost (as a lump sum value), along with their profit and overhead fee as a % of the total cost; or</li> 
                                                                                    <li>The breakdown of manpower, subcontractors, tools/equipment & materials costs, along with their profit 
                                                                                    and overhead fee as a % of the total cost.</li>
                                                                                </ol></p>
                                                                            </span>
                                                                        </span>
                                                                    </span> 
                                                                
                                                                <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                                        
                                                                    <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                                                    <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.EnquiryType == 'Service'" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">
                                                            <el-form-item label="temp" prop="AdvancedPayment">
                                                                <span slot="label"><b>Amend the <i>Advance Payment</i> Arrangement ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                            <span class="tooltip-item2">
                                                                                <span class="fa-stack fa-1x">
                                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                </span>
                                                                            </span>
                                                                            <span style="bottom: 23px; left: -170px; width: 1700px;" class="tooltip-content4 clearfix">
                                                                                <span class="tooltip-text2">
                                                                                    <p>You have the possibility to signal whether or not your 
                                                                                    organization is willing to make an <i>Advance Payment</i> to
                                                                                    the Awardee Vendor, at the time of the <i>Purchase 
                                                                                    Order</i> issuance for this item. </p>
                                                                                    <p>If 'Yes' is selected, the vendors will be able to 
                                                                                    submit, in their proposal, their desired <i>Advance Payment</i> 
                                                                                    requirement, expressed in percentage of their total
                                                                                    commercial proposal.</p>
                                                                                    <p> When a <i>Materials Rates’ Contract</i> is selected for 
                                                                                    this <i>Purchase Enquiry</i> item, the <i>Advance Payments</i> 
                                                                                    arrangements are those applicable to the said 
                                                                                    <i>Materials Rates’ Contract</i>, as agreed with the 
                                                                                    concerned Awardee Vendor.</p>
                                                                                    <p>Finally, you can choose whether or not to request collaterals from the Awardee Vendor, 
                                                                                    when your organization agrees to the <i>Advance Payment</i>.</p>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will You Consider Offering An Advance Payment ?">
                                                                        
                                                                    <el-option label="Yes" value="Yes"></el-option>
                                                                    <el-option label="No" value="No"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>

                                                    <!-- <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">

                                                            <el-form-item label="temp" prop="PELineNote">
                                                                <span slot="label"><b>Title</b></span>
                                                                <el-input
                                                                    type="textarea"
                                                                    :rows="4"
                                                                    placeholder="Enter Title"
                                                                    v-model="PurchaseEnquiryLine.Title">
                                                                </el-input>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col> -->
                                                    
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment == 'Yes'" >
                                                        <div class="grid-content">
                                                            <el-form-item label="temp" prop="AdvancedPayment">
                                                                <span slot="label"><b>Amend the <i>Advance Payment</i> collateral requirment ?</b></span>

                                                                <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment" placeholder="Is a collateral needed from the Awardee Vendor against for The Advance Payment ?">
                                                                        
                                                                    <el-option label="Yes" value="Yes"></el-option>
                                                                    <el-option label="No" value="No"></el-option>
                                                                        
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                            <div class="grid-content">                                    

                                                                <el-form-item label="temp" prop="RetentionPercentage">
                                                                    <span slot="label"><b>Amend the <i>Retention Percentage</i> ?</b></span>
                                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                            <span class="tooltip-item2">
                                                                                <span class="fa-stack fa-1x">
                                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                </span>                                                
                                                                            </span>
                                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                                            <span style="bottom: 23px; left: -95px; width: 1100px;" class="tooltip-content4 clearfix">
                                                                                <span class="tooltip-text2">
                                                                                    <p>The <i>Retention Percentage</i> is the portion of a payment, expressed as a percentage, that your organization 
                                                                                        <u>temporary</u> withholds from the Awardee Vendor for all <i>Purchase Orders</i> issued under this <i>Rates' Contract</i> until the 
                                                                                        <i>Retention Timeframe</i> elapses. </p>
                                                                                        <p>This retention acts as a security or guarantee to ensure that the Awardee Vendor 
                                                                                        fulfills all contractual obligations, including rectifying any defects or deficiencies identified during or after the 
                                                                                        delivery of the <i>Materials</i> or <i>Services</i>.</p>
                                                                                        <p>The retention will be released back to the Awardee Vendor as soon as the <i>Retention Timeframe</i> elapses.</p> 
                                                                                        <p>When a <i>Materials Rates’ Contract</i> is selected for the item, the 
                                                                                    retention arrangements are those applicable to the said <i>Materials Rates’ 
                                                                                    Contract</i>, as agreed with the concerned Awardee Vendor.</p>
                                                                                </span>
                                                                            </span>
                                                                        </span>

                                                                    <el-input min='0.0001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="PurchaseEnquiryLine.RetentionPercentage">
                                                                    </el-input>
                                                                </el-form-item>

                                                            </div>
                                                    </el-col> 

                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.RetentionPercentage != 0 || PurchaseEnquiryLine.RetentionPercentage != ''" >
                                                        <div class="grid-content">
                                                            <el-form-item label="temp" prop="RetentionDays">
                                                                <span slot="label"><b>Amend the <i>Retention Timeframe</i> ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="fa-stack fa-1x">
                                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                            </span>                                                
                                                                        </span>
                                                                        <!--<span class="tooltip-content4 clearfix">-->
                                                                        <span style="bottom: 23px; left: -81px; width: 850px;" class="tooltip-content4 clearfix">
                                                                            <span class="tooltip-text2">
                                                                                <p>The <i>Retention Timeframe</i> begins on the date when either:
                                                                                                <div style="margin-left: 40px;">
                                                                                                    1. The the complete quantity specified in the <i>Purchase Order</i> is received from the Awardee Vendor; or<br>
                                                                                                    2. the <i>Purchase Order</i> is closed.                     
                                                                                                </div>
                                                                                </p>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RetentionDays" placeholder="Select Retention Timeframe From The Delivery Date">
                                                                    
                                                                    <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                                    
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>

                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                    <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                        <div class="grid-content">

                                                            <el-form-item label="temp" prop="PELineNote">
                                                                <span slot="label"><b>Amend the Additional Notes ?</b></span>
                                                                <el-input
                                                                    type="textarea"
                                                                    :rows="4"
                                                                    placeholder="Enter Purchase Enqiury Line Note."
                                                                    v-model="PurchaseEnquiryLine.PELineNote">
                                                                </el-input>
                                                            </el-form-item>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>


                                                    <button type="button" @click="SubmitUpdatePurchaseRequest" 
                                                        class="btn btn-primary btn-block waves-effect text-center" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                    
                                                        Resubmit The <i>Services Purchase Enquiry</i> For Validation 
                                                    </button>


                                                </el-row>
                                            
                                        </el-form>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <hr> 
                            </div>

                            <div class="col-lg-12">
                                <h3 style="background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>  
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Action Description</b></th>
                                            <th><b>Action By</b></th>
                                            <th><b>Action Level</b></th>
                                            <th><b>Decision-<i>Note</i></b></th>
                                            <th><b>Action Date</b></th>
                                            <th><b>Timespan Between Actions</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Approval Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td><b>{{ item.decision }}</b> - {{ item.decision_notes }}</td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <b>Date:</b> <span style="color: rgb(0, 0, 0);">{{ new Date() }}</span><br>
                                <b>Document Generated By:</b> <span style="color: rgb(0, 0, 0);">{{ currentUser.Name }}</span> 
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>
                    </div>
                                    
                    </div>
                </div>
            </div>
        </div>
        <div id="RFIModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RFIModal" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="RFIDataLoaded">
                        <div  class="modal-header">
                            <h4 class="modal-title text-ogeo">Request For Information <span style="color: black">(<span v-if="CompanySetup.rfq_prefix == ''">RfP-</span><span v-else>{{ CompanySetup.rfq_prefix }}-</span>{{ RFIDetails[0].quotation.quotation_group_id }} / <span v-if="CompanySetup.pe_prefix == ''">PE-</span><span v-else>{{ CompanySetup.pe_prefix }}-</span>{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_group_id }}-{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_ingroup_id }})</span></h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="chat-rbox" style="border: 1px dashed gray; border-radius: 10px; max-height: 450px; overflow-y: auto;">
                                        <ul class="chat-list">
                                            
                                            <!--chat Row -->
                                            <template v-for="message in RFIDetails">
                                              
                                                <li v-if="message.user_id != currentUser.userId">
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-info" >{{ message.message }}</div>
                                                    </div>
                                                    <div class="chat-time" style="min-width: 10%">{{ message.created_at }}</div>
                                                </li>
                                                <li v-else class="reverse">
                                                    <div class="chat-time">{{ message.created_at }}</div>
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - <i v-if="message.reply_id">(In Reponse to ID#: {{ message.reply_id }}) -</i> By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-ogeo" style="min-width: 10%">{{ message.message }}</div>
                                                    </div>
                                                </li>

                                            </template>
                                       
                                        </ul>
                                    </div>
                                    <div v-if="!RFIDetails[0].quotation.status.includes('Canceled') || !RFIDetails[0].quotation.status.includes('Ended') || !RFIDetails[0].quotation.status.includes('Submitted')" class="card-body b-t">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="grid-content">
                                                    <label><b>Select Message To Reply</b></label></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="RFIData.RFIMessageID" placeholder="Select Message ID">   
                                                        <el-option v-for="message in RFIDetails" :key="message.id" :label="'ID#: '+message.id" :value="message.id"></el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <br>
                                                <el-input
                                                    type="textarea"
                                                    rows="6"
                                                    placeholder="Enter Your Request For Information..."
                                                    v-model="RFIData.RFImessage">
                                                </el-input>
                                            </div>
                                            <div class="col-lg-12">
                                                <br>
                                                <button type="button" class="btn btn-block btn-success" @click="SubmitRFI">Submit Answers to RFI</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-12">
                                    <table ref="RFITable" id="RFIMessagesTable" class="display responsive table table-bordered table-hover" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Communication #</th>
                                                <th>By</th>
                                                <th>User Name</th>
                                                <th>Message</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="message in RFIDetails">
                                                <th>{{ message.id }}</th>
                                                <th>{{ message.user_type }}</th>
                                                <template v-if="message.user_type == 'Vendor'">
                                                    <th>{{ message.vendor_user.name }}</th>
                                                </template>
                                                <template v-else>
                                                    <th>{{ message.user.name }}</th>
                                                </template>
                                                <th>{{ message.message }}</th>
                                                <th>{{ message.created_at }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Locate with Marker</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select The Delivery Location by Dragging and Dropping the Pin on The Map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
            
</template>

<script>

    import validate, { isEmpty } from 'validate.js';
import { log } from 'logrocket';

    export default {
        name: 'purchase-enquiry-list',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter Retention Amount')); 
                }
                
                if (!Number.isInteger(value)) {
                    callback(new Error('Please Enter Digits Only'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select Retention Days')); 
                } else {
                    callback();
                }
            };
            return{
                E_images : [],
                p_images : [],
                sd_images : [],
                hostName: window.location.protocol+'//'+window.location.hostname,
                DataTable: "",
                CompanySetup: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                JobNumbers: false,
                searchItemsLoading: false,
                showAmend: false,
                ViewModalInfo: {},
                EditModalInfo: {},
                TmpEditModalInfo: {},
                FullyInvoiced: false,
                FoundItems: {},
                RFIDataLoaded: false,
                RFIDetails: [],
                validatorsList: "",
                RFIData: {
                    RFIMessageID: "",
                    RFImessage: "",
                    VendorId: "",
                    CompanyId: "",
                    QuotationId: "",
                    QuotationType: "",
                    ReplyBy: "Purchase Enquiry Initiator",
                    PEId: "",
                },
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    ServiceOneOff: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    ServiceRC_id: null,
                    StoreItemrequest: "No",
                    RCSelectionService: "",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    ServiceDescription: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    TypeOfServicesContract: '',
                    ContractHeaders:[],
                    TendererContractHeaders:[],
                    VendorOfferContent:[],
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                    ImagesSD: [],

                },
                switchbutton: "Unlinked",
                ValidationInfo: {
                    PurchaseEnquiryId: "",
                    RetentionDays: "",
                },
                contractheader: {
                    name: '',
                    contant: '',
                },
                tenderercontractheader: {
                    name: '',
                    contant: '',
                },
                vendoroffercontent: {
                    name: '',
                    contant: '',
                },
                rules: {
                    RetentionDays: [{
                        required: true,
                        message: "Please Select a Retention Days",
                        trigger: "blur"
                    }]
                },
                PeEditRules: {
                    JobNumber: [{
                        required: true, message: "Please Select Job Number", trigger: ["blur"]
                    }],
                    UnderlyingTransaction: [{
                        required: false,
                        message: "Please Underlying Transaction",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: false,
                        message: "Please Select Sourcing Priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please Select Transaction Type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter Service Description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please Enter Item Description",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Enter Quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: "blur"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{validator: checkRetentionDays, trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: true,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: true,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please Enter Location Name.",
                        trigger: "blur"
                    }]
                },
                ValidationInfoApproval: {
                    PurchaseEnquiryId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                ApprovalRules: {
                    Decision: [{
                        required: true,
                        message: "Please select a approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision Notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            changeTable(){
                if(this.switchbutton == 'Linked')
                    this.$router.push('/app/purchase_enquiry/linked-service-list')
                else
                    this.$router.push('/app/purchase_enquiry/service-list')
            },
            downloadDoc(his) {
                axios .get('/uploads/ServiceSupportingDocs/'+his, {responseType: 'blob'}) 
                .then(response => {
                    console.log(response) 
                let mylink = document.createElement('a');
                mylink.href = URL.createObjectURL(response.data); 
                mylink.download = his; 
                mylink.click() });
            },
            checkArray(data)
            {
                return Array.isArray(data);
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.PurchaseEnquiryLine.ContractHeaders.push({
                        header_name: this.contractheader.name,
                        header_contant: this.contractheader.contant,
                    })
                    this.contractheader.name = ''
                    this.contractheader.contant = ''
                } else {
                    Swal('Error', 'This Contract Header Already Exists.' , 'error'); 
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.ContractHeaders, index);
            },
            existContractHeader() {
                if (this.PurchaseEnquiryLine.ContractHeaders.find(item => item.header_name === this.contractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addVendorOfferContent() {
                if (this.existVendorOfferContent() == true) {
                    this.PurchaseEnquiryLine.VendorOfferContent.push({
                        header_name: this.vendoroffercontent.name,
                        header_contant: this.vendoroffercontent.contant,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.PurchaseEnquiryLine.VendorOfferContent, index);
            },
            existVendorOfferContent() {
                if (this.PurchaseEnquiryLine.VendorOfferContent.find(item => item.header_name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.PurchaseEnquiryLine.TendererContractHeaders.push({
                        header_name: this.tenderercontractheader.name,
                        header_contant: this.tenderercontractheader.contant,
                    })
                    this.tenderercontractheader.name = ''
                    this.tenderercontractheader.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.TendererContractHeaders, index);
            },
            existTendererContractHeader() {
                if (this.PurchaseEnquiryLine.TendererContractHeaders.find(item => item.header_name === this.tenderercontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfoApproval.Decision = "Approved";
                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                        self.ValidationInfoApproval.Decision = "";
                        self.ValidationInfoApproval.DecisionNotes = "";

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                this.ApprovalRules.DecisionNotes[0].required = false;
                
                

            },
            DecisionReject(){
                let self = this;
                this.ApprovalRules.DecisionNotes[0].required = true;
                this.ValidationInfoApproval.Decision = "Rejected";
                this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                self.ValidationInfoApproval.Decision = "";
                                self.ValidationInfoApproval.DecisionNotes = "";

                                self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                        this.ApprovalRules.DecisionNotes[0].required = false;

                        

                    }
                })
            },
            DecisionRejectPermanent(){
                Swal({
                    type: "warning", 
                    title: "Are You Sure You Want to Permanently Cancel This Purchase Enquiry Line ?", 

                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#f10623",
                    confirmButtonColor: "#008000",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.ApprovalRules.DecisionNotes[0].required = true;
                        this.ValidationInfoApproval.Decision = "RejectedPermanent";
                        this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                                    .then(function(response){
                                        Swal({ 
                                            type: response.data.messageType, 
                                            title: response.data.messageTitle, 
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                        self.ValidationInfoApproval.Decision = "";
                                        self.ValidationInfoApproval.DecisionNotes = "";

                                        self.DataTable.ajax.reload( null, false );

                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                                    });

                                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                                this.ApprovalRules.DecisionNotes[0].required = false;

                                

                            }
                        })
                    }
                })
            },
            CancelPurchaseEnquiry(){
                let self = this;
                axios.post( '/api/purchase_enquiry/cancel_purchase_enquiry_request', {PurchaseEnquiryId: this.EditModalInfo.id})
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

            },
            ClearServiceTypeOptions()
            {
                this.PurchaseEnquiryLine.VendorCommercialOffer = "";
            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            SubmitUpdatePurchaseRequest() {

                let self = this;
                if (event) event.preventDefault();

                if ( Object.entries(this.PurchaseEnquiryLine).toString() !== Object.entries(this.TmpEditModalInfo).toString()){
                    Swal({
                        type: 'warning',
                        title: 'Are You Sure You Want To Proceed With The Following Amendment ?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#f10623",
                        confirmButtonColor: "#008000",
                    }).then((response) => {
                        if(response.value)
                            this.SubmitChanges();
                    });
                }
                else{
                    Swal({
                        type: 'warning',
                        title: 'No Information Has been Amended. Are You Sure You Want To Proceed ?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#f10623",
                        confirmButtonColor: "#008000",
                    }).then((confirm) => {
                        if(confirm.value)
                            this.SubmitChanges();
                    });
                }


                },
            SubmitChanges(){
                let self = this;
                this.PurchaseEnquiryLine.RequiredDocuments = [];

                this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project_id;

                axios.post('/api/purchase_enquiry/update_purchase_enquiry_request', this.PurchaseEnquiryLine)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Enquiry Resubmission)', 'error');
                    });


                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');
                    
                },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items', {searchQuery: value})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                        //console.log(this.FoundItems);
                    })
            },
            showRateContract(ItemId){

                event.preventDefault();

                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                    .then((response) => {
                        this.ItemRateContractDetails = response.data;
                        this.RateContractDataLoaded = true;
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        if(response.data.sd_images !== null){
                            let image_array_string = response.data.sd_images;
                            let image_string = image_array_string.substring(1, image_array_string.length-1);
                            let final = image_string.replace(/"/g,"");
                            var d= final.split(',');
                            this.sd_images = d;
                        }
                        else
                            this.sd_images = [];
                        
                        this.loadValidations(response.data)
                        this.viewDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            showEditModal(elquentClass, recordId){
                this.EditModalInfo = {};
                this.showAmend = false
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = response.data;
                        if(response.data.sd_images !== null){
                            let image_array_string = response.data.sd_images;
                            let image_string = image_array_string.substring(1, image_array_string.length-1);
                            let final = image_string.replace(/"/g,"");
                            var d= final.split(',');
                            this.sd_images = d;
                        }
                        else
                            this.sd_images = [];
                        this.ValidationInfo.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfoApproval.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfo.RetentionDays = this.EditModalInfo.retention_days;

                        /* Check if Fully Invoiced So User Cannot Adjust The Retention Date (Known Bugs if the vendor invoices the client partially on the Retention Money */
                        if(this.EditModalInfo.invoicing_status == 'Invoicing Completed'){
                            this.FullyInvoiced = true;
                        }

                        /* Updating Edit Model Information */
                        if(this.EditModalInfo.status.indexOf('Rejected') !== -1){
                            this.PurchaseEnquiryLine.ID = this.EditModalInfo.id;
                            this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project.title;
                            this.PurchaseEnquiryLine.Country = this.EditModalInfo.country_of_delivery.split(', ');
                            this.PurchaseEnquiryLine.Cities = this.EditModalInfo.cities_of_delivery.split(', ');
                            this.PurchaseEnquiryLine.UnderlyingTransaction = this.EditModalInfo.underlying_transaction;
                            this.PurchaseEnquiryLine.SourcingPriority = this.EditModalInfo.sourcing_priority;
                            this.PurchaseEnquiryLine.EnquiryType = this.EditModalInfo.enquiry_type;
                            this.PurchaseEnquiryLine.EnquiryFromItemMaster = this.EditModalInfo.enquiry_from_item_master;
                            this.PurchaseEnquiryLine.ItemNumber = this.EditModalInfo.item_id;
                            this.PurchaseEnquiryLine.ItemDescription = this.EditModalInfo.item_description;
                            this.PurchaseEnquiryLine.Quantity = this.EditModalInfo.quantity;
                            this.PurchaseEnquiryLine.UnitOfMeasurement = this.EditModalInfo.u_o_m;
                            this.PurchaseEnquiryLine.ServiceDescription = this.EditModalInfo.service_description;
                            this.PurchaseEnquiryLine.AdvancedPayment = this.EditModalInfo.advanced_payment;
                            this.PurchaseEnquiryLine.RetentionPercentage = this.EditModalInfo.retention_percentage;
                            this.PurchaseEnquiryLine.RetentionDays = this.EditModalInfo.retention_days;
                            this.PurchaseEnquiryLine.RequiredDocuments = this.EditModalInfo.required_documents;
                            this.PurchaseEnquiryLine.PELineNote = this.EditModalInfo.notes;
                            this.PurchaseEnquiryLine.Longitude = this.EditModalInfo.longitude;
                            this.PurchaseEnquiryLine.Latitude = this.EditModalInfo.latitude;
                            this.PurchaseEnquiryLine.LocationName = this.EditModalInfo.location_name;
                            this.PurchaseEnquiryLine.TendererContractHeaders = this.EditModalInfo.tendererchildheaders;
                            this.PurchaseEnquiryLine.ContractHeaders = this.EditModalInfo.childheaders;
                            this.PurchaseEnquiryLine.VendorOfferContent = this.EditModalInfo.vendoroffercontent;
                            this.PurchaseEnquiryLine.TypeOfServicesContract = this.EditModalInfo.type_of_services_contract;
                            this.PurchaseEnquiryLine.VendorCommercialFormat = this.EditModalInfo.vendor_commercial_offer;
                            this.TmpEditModalInfo = Object.assign({}, this.PurchaseEnquiryLine);
                            this.ItemRateContractDetails = [];
                            this.RateContractDataLoaded = false;





                            /*
                             * Google Map inside modal
                             */
                            var initializeMap = function(id) {

                                var myLatLng = {lat: this.PurchaseEnquiryLine.Latitude, lng: this.PurchaseEnquiryLine.Longitude};

                                // Map Options
                                var mapOptions = {
                                    zoom: 10,
                                    center: new google.maps.LatLng(this.PurchaseEnquiryLine.Latitude, this.PurchaseEnquiryLine.Longitude),
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                };


                                // Initialize the map with options (inside #map element)
                                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                                var marker = new google.maps.Marker({
                                    position: myLatLng,
                                    map: map,
                                    draggable: true

                                });


                                google.maps.event.addListener(marker, 'position_changed', function () {
                                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                                });


                                // When modal window is open, this script resizes the map and resets the map center
                                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                                  google.maps.event.trigger(map, "resize");
                                  return map.setCenter(myLatLng);
                                });


                                google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));

                            }

                        }


                        this.loadValidations(response.data)
                        this.editDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    })
                this.editDataLoaded = false;
            },
            EnquiryItemDescriptionSelection(){
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            PurchaseEnquiryRententionUpdate(){
                let self = this;

                this.$refs.PurchaseRequestRetentionUpdateForm.validate((validation) => {
                    if(validation){

                        axios.post('/api/purchase_enquiry/update_purchase_enquiry_request_by_peo', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                 self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Update Purchase Request Details)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    }
                });

            },
            showAllRFIModal(recordId, RecordType, QuotationId) {
                this.RFIData.CompanyId = this.CompanySetup.id
                this.RFIData.QuotationId = QuotationId;
                this.RFIData.PEId = recordId;
                this.RFIData.QuotationType = RecordType;
                this.RFIDetails = [];
                axios.post('/api/data/get_all_pe_rfi_details', {PEId: recordId, QuotationType: RecordType})
                    .then((response) => {
                        this.RFIDetails = response.data;
                        this.RFIDataLoaded = true;
                        // console.log(this.RFIDetails);

                        $('#RFIModal').modal('toggle');

                        this.$nextTick(() => {
                            $('#RFIMessagesTable').DataTable({
                                responsive: true,
                                colReorder: true,
                                dom: '<"html5buttons">Brfgtip',
                                pageLength: 100,
                                lengthMenu: [
                                    [ 100, 500, 1000, -1 ],
                                    [ '100 Records', '500 Records', '1000 Records', 'All Records' ]
                                ],
                                order: [[ 4, "DESC" ]],
                                buttons: [
                                    { extend: 'pageLength', className: 'btn btn-success' },
                                    { extend: 'copy', className: 'btn btn-success' },
                                    { extend: 'excel', className: 'btn btn-success', title: 'QuotationsLinesList' },
                                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] }
                                ],
                            }); 
                        });
                    });

                this.RFIDataLoaded = false;
            },
            SubmitRFI(){
                //check if empty
                if(this.RFIData.RFImessage == ""){
                    Swal('Message Required', 'Message field can not be empty, please type your message', 'warning')
                    return false;
                } else {
                    axios.post( '/api/quotations_management/create_rfi_message', this.RFIData)
                        .then((response) => {
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                            });
                            this.RFIData.RFImessage = "";
                            this.RFIData.RFIMessageID = "";
                        })

                    

                    $('#RFIModal').modal('toggle');
                    this.DataTable.ajax.reload( null, false );
                }         
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },
        mounted(){
            $('.pe-button').addClass('router-link-exact-active router-link-active');
            let self = this;

            var groupColumn = 1;

            axios.get('/api/data/get_company_details')
                .then((response) => {
                    // console.log(response.data);
                    self.CompanySetup = response.data;
                });

            axios.get('/api/data/get_user_projects_list')
                .then((response) => {
                    this.JobNumbers = response.data;
                });


            setTimeout(() => {

                this.DataTable = $('#ProjectPEList').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                    
                    stateSave:  false,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    autoWidth: true,
                    serverSide: true,
                    ajax: '/api/data/get_service_purchase_request_list_for_peos',
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'purchase_enquiry_group_id', name: 'purchase_enquiry_group_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'creator.name', name: 'creator.name' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'countryCities', name: 'countryCities' },
                        { data: 'service_description', name: 'service_description' },
                        { data: 'service_term', render: function(data, type, full) {
                            if(full.service_one_off == 'One-Off')
                                return "One-Off"
                            else if(data)
                                return data
                            else
                                return "N/A"
                        } },
                        { data: 'contractheaders', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'tendererheaders', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'vendorofferz', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'supporting_documents', render: function(data) {
                            if(data) return data.replaceAll(',', ', ');
                        } },
                        { data: 'type_of_services_contract', name: 'type_of_services_contract' },
                        { data: 'vendor_commercial_offer', name: 'vendor_commercial_offer' },
                        { data: 'advanced_payment', render: function (data, row, full){
                        if(data == 'Yes')
                            if(full.advanced_payment_guarantee == 'Yes')
                                return 'Yes | Collateral Is Required  For The <i>Advance Payment</i>'
                            else
                                return 'Yes | No Collaterals Is Required  For The <i>Advance Payment</i>'
                            else
                                return 'Not Applicable'    

                        } },
                        { data: 'retention_percentage', render: function (data, row, full){
                            if(data > 0)
                                return data +'% | '+full.retention_days+' Day(s) From The Date Of Either Receiving The Total Quantity Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order</i>'
                            else
                                return "Not Applicable";
                        } },
                        { data: 'location_details', name: 'location_details' },
                        { data: 'sourcing_priority', name: 'sourcing_priority' },
                        { data: 'level_of_validations',
                            render: function (data, type, full) {
                                return `${full.approval_level} out of ${full.count_role}`;
                            }
                        }
,                   
                        
                        { data: 'validationList', render: function(data, type, full) {
                            if(data)
                                return data
                            else
                                return "Not Applicable"
                        } },
                        { data: 'underlying_transaction', name: 'underlying_transaction' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at_human', render: function(data, type, full){
                            if(full.updated_at_human == null){
                                return '';
                            }else{
                                return full.updated_at_human.substr(0,200);
                            }
                        }},
                        { data: 'additional_notes', name: 'additional_notes' },
                        { data: 'awardee_vendor', name: 'awardee_vendor' },
                        // { data: 'status', name: 'status' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        {"orderable":false, "targets":9},
                        { visible: false, targets: groupColumn },
                        { width: '30%', targets: 6 },
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 0 },
                        { responsivePriority: 3, targets: 22 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 9,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ groupColumn, 'ASC' ]],
                    "drawCallback": function ( settings ) {
                        var api = this.api();
                        var rows = api.rows( {page:'current'} ).nodes();
                        var last=null;

                        api.column({page:'current'} ).data().each( function ( group, i ) {
                        var res = group.slice(0, -2);
                            if ( last !== res ) {
                                $(rows).eq( i ).before(
                                    '<tr class="group"><td colspan="12"><i>Services Purchase Enquiry</i> ID#: '+res+'-1</td></tr>'
                                );
                                last = res;
                            }
                        });
                    },
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'PurchaseEnquiriesList '+new Date() },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    }, 
                    "rowCallback": function( row, data, index ) {
                        // console.log(data);
                        // if (data.status.indexOf('Rejected') !== -1) {
                        //      $(row).css('background-color', 'rgb(252, 51, 23, 0.3)')
                        // }
                        if(data.action.indexOf('edit-placeholder') !== -1){
                            $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                        }
                    } 
                });

                

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.view-similar', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    self.$router.push({ name: 'Similar Purchase Enquiry', params: { id: RecordID }})
                    // this.$router.push('/app/purchase_enquiry/similar_purchase_enquiry/' + RecordID); 
                });


                $('tbody', this.$refs.table).on('click', '.view-rfi', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    var RecordType = $(this).data('recordtype');
                    var Quotationid = $(this).data('quotationid');
                    self.showAllRFIModal(RecordID, RecordType, Quotationid);
                });

                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showEditModal(elquentClass, RecordID);
                });

            }, 500);


        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>
<style>
.el-switch__label * {
        font-size: 18px;
        font-weight: bold;
    }
.el-switch__label.is-active {
    color: rgb(252, 51, 23);
}


</style>

<style scoped>

.sptable tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 0px;
    }

@page {
    size: Executive !important;
    margin: 0mm !important;
    padding: 0mm !important;
}
@media print {
  body * {
    visibility: hidden;
  }
  #PurchaseEnquiryUpdateModalView, #PurchaseEnquiryUpdateModalView * {
    visibility: visible;
  }
  #PurchaseEnquiryUpdateModalView::-webkit-scrollbar { width: 0 !important }
  #PurchaseEnquiryUpdateModalView:last-child {
     page-break-after: auto;
    }
   #PurchaseEnquiryUpdateModalView:last-child {
     page-break-before: none;
    }
  #PurchaseEnquiryUpdateModalView {
    position: relative;
    left: 0;
    margin-top: -550mm;
    width: 400mm !important;
  }
  button {
    display: none;
  }
}
    /* @media print {
  body * {
    visibility: hidden;
  }
  #PurchaseEnquiryUpdateModalView, #PurchaseEnquiryUpdateModalView * {
    visibility: visible !important;
    
  }
  
  #PurchaseEnquiryUpdateModalView::-webkit-scrollbar { width: 0 !important }
  #PurchaseEnquiryUpdateModalView:last-child {
     page-break-after: auto;
    }
   #PurchaseEnquiryUpdateModalView:last-child {
     page-break-before: none;
    }

  #PurchaseEnquiryUpdateModalView {
    position: absolute;
    left: auto;
    top: auto;
    width:100vw !important;
    width:100vh !important;
    padding:0 !important;
    margin:0 !important;
    -ms-overflow-style: none;
}
.vendorInsT {
    display: none;
}
/*
.vendorInsT>thead {
    margin: 0;
    padding: 0;
    float: left;
}
.vendorInsT>tbody {
    margin: 0;
    padding: 0;
    float: left;
    
}
.vendorInsT>thead>th {
    min-width: 100px;

} */

/* td.vendorInsC {
    float: left;
    padding-left: 0px;
    margin-left: 0px;
}
td.vendorInsH {
    min-width: 100px;
    padding-right: 1px;
    margin-right: 0px;
} 
#SCT {
    display: none;
}
button {
    display: none;
}
thead, tbody, tr { width: inherit; }
table {
    width: 100%;
}
  .vendorIns {
    height: auto;
  }
  .vendorInsH {
    width:20% !important;
  }
  .vendorInsC {
    width:80% !important;
    height: 100px !important;
  }
  #SCT th {
    color: red;
  }
  #SCTC, .SCTC {
    width: 100% !important;
    color: grey !important;
  }
} */
    .font-500{
        font-weight: 500;
    }

    .ogeo-background {
        background-color: rgb(252, 51, 23);
    }

    #PurchaseEnquiryUpdateModalView .modal-dialog {
        min-width:80%;
    }

    #PurchaseEnquiryUpdateModalEdit .modal-dialog {
        min-width:80%;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    .el-select-dropdown {
        z-index: 20000 !important;
    }

    .el-popper {
        z-index: 20000 !important;
    }

    .el-scrollbar {
        z-index: 20000 !important;
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }
    .el-scrollbar__wrap{
        z-index: 20000 !important;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .ogeo-text{
        color: rgb(252, 51, 23, 1);
    }

</style>