<template>
        
    <div class="row">
        <div id='Rate_Contract_Request_Form' class="col-lg-7">
            <div class="card" v-if="CompanyDetailsLoaded">
                <div class="card-header">
                    <h4 v-if="this.RateContractItemLine.RCType == 'Material'"><b>Initiate A <i>Materials Rates' Contract Creation Request</i> Line</b></h4>
                    <h4 v-else-if="this.RateContractItemLine.RCType == 'Service'"><b>Initiate A <i>Services Rates' Contract Creation Request</i> Line</b></h4>
                    <h4 v-else><b>Initiate A <i>Rates' Contract Creation Request</i></b></h4>
                </div>
                <div class="card-body">
                    <el-form :model="RateContractItemLine" class="demo-form-inline" :rules="rules" ref="RateContractRequestForm">
                        <el-row :gutter="24">
                            <el-col :span="24">   
                                <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.025);text-align: justify;">
                                    <p style="color: #111111;font-size: 16px;">
                                        <i class="fa fa-comment"></i>  
                                        <i><b>Rates’ Contracts</b></i> are agreements entered with <i>Awardee Vendors</i>, intended to freeze 
                                        <b>Unit Rates</b> and <b>Lead Times</b> for <i>Materials</i> <b>(strictly those from The <i>Library of Materials</i>)</b> 
                                        and <i>Services</i>, before they are needed. 
                                    </p>
                                    <p style="color: #111111;font-size: 16px;">
                                        Once fully approved and assigned to users holding the <u><i>Request for Proposal Administrator</i></u> role, 
                                        responsible for sourcing proposals from <i>Vendors</i>, the <i>Request</i>
                                        will then be routed to the users holding the <u><i>Rates' Contract Creation Initiator</i></u> and 
                                        <u><i>Rates' Contract Creation Validator</i></u> roles for this <i>Account</i>, for review: If fully validated, 
                                        the <i>Rates' Contract</i> will formally be established with the <i>Awardee Vendor</i>.</p>
                                        <p style="color: #111111;font-size: 16px;">
                                        A <i>Rates' Contract Creation Request</i> can contain multiple items from the <i>Library of Materials</i> or multiple <i>Service Lines</i>.</p>
                                    <hr>
                                    <p style="color: rgb(252, 51, 23);font-size: 16px;">
                                        <i class="fa fa-exclamation-triangle"></i>  
                                        <b>Formally establishing <i>Rates' Contracts</i> helps reduce costs and workloads as <i>Purchase Enquiries</i>, linked to <i>Rates’ Contracts</i>, 
                                        can be <u>directly</u> turned into <i>Purchase Orders</i></b>.
                                    </p>                                                      
                                </div>
                            </el-col>                                       
                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="JobNumber" >
                                            <span slot="label"><b>Select an <i>Account</i></b></span>
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" class="setUp" id="setUp"  @change="getProjectCurrency($event)" v-model="RateContractItemLine.JobNumber" placeholder="Select an Account for which you hold the Rates' Contract Creation Request Initiator role">                   
                                                <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>      
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>                      
                            
                            <el-col :span="24"><hr v-if="RateContractItemLine.JobNumber !==''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            
                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="RCType">
                                        <span slot="label"><b>Select the <i>Rates’ Contract</i>  type</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><p><i>Rates' Contract Creation Requests</i> can be initiated to start the process of 
                                                    implementing a <i>Rates' Contract</i> for either <i>Materials</i> or <i>Services</i>.</p> <p> For <i>Materials</i>, this is strictly limited to 
                                                    items of the <i>Library of Materials</i>. Therefore, a prerequisite for initiating <i>Rates' Contracts</i> is having 
                                                    items listed in the <i>Library of Materials</i>.</p></span>
                                            </span>
                                        </span>
                                        <el-select :disabled="itemAdditionFlag == 'Y'" @change="RCTypeSelection($event)" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RCType" placeholder="Choose 'Materials Rates’ Contract' or 'Services Rates' Contract'">
                                                
                                            <el-option label="Materials Rates' Contract" value="Material"></el-option>
                                            <el-option label="Services Rates' Contract" value="Service"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24"><hr v-if="RateContractItemLine.RCType !== ''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            
                            <el-col :span="24" v-if="this.RateContractItemLine.RCType">                            
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="UnderlyingTransaction">
                                        <span slot="label"><b>Enter an underlying transaction reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 390px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The underlying transaction reference is the identifier for the transaction that initiated this specific <i>Rates' Contract Creation Request</i>.</p>
                                                    <p>Examples of such references include <u>Sales Orders</u>, <u>Work Orders</u>, <u>Job Tickets</u>, <u>Lead References</u>...</p>
                                                </span>
                                            </span>
                                        </span>

                                        <el-input 
                                            v-model="RateContractItemLine.UnderlyingTransaction" 
                                            :disabled="itemAdditionFlag == 'Y'" 
                                            placeholder="e.g. Sales Order #1213412.... Or Enquiry #1216788"
                                            @keyup.enter="handleEnterKey">
                                        </el-input>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr v-if="RateContractItemLine.RCType !== ''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            
                            <el-col :span="12" v-if="this.RateContractItemLine.RCType">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCountry">
                                        <span slot="label"><b>Select a country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 220px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><p>Select the country where the <i>Materials</i> or <i>Services</i> under the <i>Rates' Contract</i>, will be needed.</p> <p>(1 country only)</p></span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Choose a country from the dropdown list"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.CompanyCountry" placeholder="Select a country from the list">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="this.RateContractItemLine.RCType">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCities">
                                        <span slot="label"><b>Select city(ies)</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 220px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><p>Select the cities where the <i>Materials</i> or <i>Services</i>, under the <i>Rates' Contract</i>, will be needed.</p> <p>(Selecting multiple cities is possible.)</p></span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="RateContractItemLine.CompanyCities" placeholder="Select a city, or cities, from the list">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                                <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr v-if="RateContractItemLine.RCType !== ''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.RCType">
                            
                                <div class="grid-content">
                                    <el-form-item prop="SourcingPriority">
                                        <span slot="label"><b>Select a sourcing priority</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                                <span class="tooltip-item2">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
                                                                </span>
																	
																	<span style="bottom: -20px; left: 175px; width: 490px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2"><p>The default sourcing priority is set to <i>Standard</i> but can 
                                                                            be adjusted to <i>Urgent</i> or <i>Very Urgent</i> as needed.</p> <p>This setting determines the urgency 
                                                                            with which proposals from vendors should be sourced during the tendering process.</p></span>
																	</span>
		                                </span>
                                    
                                        
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr v-if="RateContractItemLine.CompanyCities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.CompanyCities.length > 0">
                                <div class="grid-content">                                     
                                    <el-form-item label="temp" prop="RateContractItemPeriod">
                                        <span slot="label"><b>What will be the term, in months, for this <i>Rates' Contract</i> ?</b></span>                   
                                         <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
                                                
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 268px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The <i>Rates' Contract</i>, signed on the back of this request, will be valid for this term, starting from the date of its establishment
                                                    with the <i>Awardee Vendor</i>.</p><p>The term must be an integer.</p>
                                                </span>
                                            </span>
                                        </span>
                                        <el-input id="expTerm" :disabled="itemAdditionFlag == 'Y'" class="expTerm"  type="number" oninput="this.value = Math.floor(this.value);" placeholder="Please specify the desired term, in months" v-model="RateContractItemLine.RateContractItemPeriod">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="RateContractItemLine.RCType == 'Material' && RateContractItemLine.RateContractItemPeriod > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <!--<el-col :span="24" v-if="RateContractItemLine.RateContractItemPeriod" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>-->
                            <el-col :span="24"  v-if="RateContractItemLine.RCType == 'Material' && RateContractItemLine.RateContractItemPeriod > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Select and/or enter the documents required from the vendors</i></b></span>
                                            
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 247px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>Vendors must submit these documents, along with their commercial proposals, 
                                                    during the tendering process. </p>
                                                    <p>This allows for the assessment of their proposals and the selection of <i>Awardee Vendors</i>.</p>

                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select and/or enter required document from bidding vendors" id="reqDocs" popper-class="reqDocs" ref="reqDocs" @change="OtherDocsVisibility()">
                                            
                                            <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                            <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                            <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                            <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                            <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                            <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                            <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                            <el-option label="Other Documents" value="Other"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                                
                            </el-col>
                            <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                            <thead class="text-white bg-dark w-100">
                                                <tr style="width: 100% !important;">
                                                    <th><span style="color:white; font-size: 13px; padding: 1px 4px; display: inline-block; line-height: 1.2; vertical-align: middle;">Enter the names of any other documents required from the vendors, if applicable.</span>
                                                        <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                    Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                    Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                    rights, Litigation...
                                                                </span>
                                                            </span>
                                                        </span> -->
                                                    </th>
                                                    
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in OtherDocTmpHolder"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="item.name" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px" type="button" @click="delOtherDocs(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="OtherDocTmp" placeholder="Enter the name of any other required documents, if applicable" maxlength="200" style="font-size: 14px">
                                                    </td>
                                                    <td class="text-center my-auto" width="10%">
                                                        <button v-if="OtherDocTmp && itemAdditionFlag =='N'" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0;" @click.prevent="addOtherDocs()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Material' && RateContractItemLine.RateContractItemPeriod > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.RateContractItemPeriod > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.RateContractItemLine.RateContractItemPeriod">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Will <i>Advance Payments</i> be considered under this <i>Rates’ Contract</i> ?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: 20px; left: -253px; width: 650px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>You can indicate whether your organization is willing to make <i>Advance Payments</i> to the <i>Awardee Vendor</i>, when issuing <i>Purchase Orders</i> under this <i>Rates' Contract</i>.</p>
                                                        <p>If 'Yes' is selected, bidding vendors will be able to include their desired <i>Advance Payments</i>, expressed as a percentage of their commercial offers.</p>
                                                        <p>Finally, you can choose whether or not to request collaterals from the <i>Awardee Vendor</i>, when your organization agrees to <i>Advance Payments</i>.</p>

                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.AdvancedPayment" placeholder="Choose 'Yes' or 'No'">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <!--<el-col :span="24" v-if="RateContractItemLine.AdvancedPayment == 'Yes'" >-->
                            <el-col :span="24" v-if="RateContractItemLine.AdvancedPayment=='Yes'">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Are collaterals, from the <i>Awardee Vendors</i>, required against the <i>Advance Payments</i> ?</b></span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.GuaranteeNeedForAdvancePayment" placeholder="Choose 'Yes' or 'No'">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="(RateContractItemLine.AdvancedPayment=='Yes' || RateContractItemLine.AdvancedPayment=='No')"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <!--<el-col :span="24" v-if="RateContractItemLine.RCType" >-->
                            <el-col :span="24" v-if="RateContractItemLine.AdvancedPayment=='No' || (RateContractItemLine.AdvancedPayment=='Yes')">
                                <div class="grid-content">                                    

                                    <el-form-item label="temp" prop="RetentionPercentage">
                                        <span slot="label"><b>Enter the <i>Retention Percentage</i>, if applicable to this <i>Rates' Contract</i></b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <!--<span class="tooltip-content4 clearfix" style="left: -150px">-->
                                            <span style="bottom: 20px; left: -210px; width: 593px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The <i>Retention Percentage</i> is the portion of a payment, expressed as a percentage, that your organization 
                                                        <u>temporary</u> withholds from the <i>Awardee Vendor</i> for all <i>Purchase Orders</i> issued under this <i>Rates' Contract</i> until the 
                                                        <i>Retention Timeframe</i> elapses. </p>
                                                        <p>This retention acts as a security or guarantee to ensure that the <i>Awardee Vendor</i> 
                                                        fulfills all contractual obligations, including rectifying any defects or deficiencies identified during or after the 
                                                        delivery of the <i>Materials</i> or <i>Services</i>.</p>
                                                        <p>The retention will be released back to the <i>Awardee Vendor</i> as soon as the <i>Retention Timeframe</i> elapses.</p>
                                                </span>
                                            </span>
                                        </span>

                                        <el-input :disabled="itemAdditionFlag == 'Y'" min='0.0001' max="100" type="number" placeholder="Enter the Retention Percentage (strictly greater than 0 and smaller than 100)" v-model.number="RateContractItemLine.RetentionPercentage">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col> 
                            
                            <el-col :span="24" v-if="RateContractItemLine.RetentionPercentage">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="RetentionDays">
                                        <span slot="label"><b>Select the <i>Retention Timeframe</i></b></span>
                                        
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <!--<span class="tooltip-content4 clearfix">-->
                                            <span style="bottom: -20px; left: 175px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The <i>Retention Timeframe</i> begins on the date when either:
                                                    <div style="margin-left: 40px;">
                                                                        1. The the complete quantity specified in the <i>Purchase Order</i> is received from the <i>Awardee Vendor</i>; or<br>
                                                                        2. the <i>Purchase Order</i> is closed.<br>                       
                                                                    </div>
                                                     </p>
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RetentionDays" placeholder="Select the Retention Timeframe duration, in days">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>


                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && (RateContractItemLine.RetentionPercentage !== 0 || RateContractItemLine.RetentionPercentage !== '')"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.AdvancedPayment && RateContractItemLine.RCType == 'Service'">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceDescription">
                                        <span slot="label"><b>Enter the <i>Services Rates’ Contract</i> title</b></span>
                                        <el-input
                                            :disabled="itemAdditionFlag == 'Y'"
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter a title for the required Services Rates' Contract"
                                            v-model="RateContractItemLine.ServiceDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.ServiceDescription"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.ServiceDescription" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorInstructionHeaders">
                                        <span slot="label"><b>Enter the <i>Instructions To The Vendors</i></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Headers 
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -20px; left: 175px; width: 620px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                        <p>These headers can provide guidance to vendors on various aspects, such as the "Format of the <i>Vendors Proposal Content</i>", the "Confidentiality Coverage", 
                                                                            the "<i>Advance Payment</i> Collateral Format", the "Compliance Requirements", the "Performance Bond Bank Letter Template", the "Amendments 
                                                                            and Clarifications Process", the "Site Visits Arrangement", the "Pre-Bid Meetings", ...</p>
                                                                        <p>Additionally, they can include information about the "Evaluation Criteria", the "Disqualification Criteria", the "Bid Scoring Mechanism", and other relevant instructions.</p>
                                                                        <p>Click on '+' to add a header.</p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorInstructionHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px;" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.name" placeholder="e.g. Instruction #1:" style="width: 150px; font-size: 13px; color: #09a3eb;">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.content" placeholder="e.g. The Evaluation Criteria are described below: .......">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendorinstructionheader.name && vendorinstructionheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && this.RateContractItemLine.ServiceDescription"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && this.RateContractItemLine.ServiceDescription" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorOfferContent">
                                        <span slot="label"><b>Describe what should be included in the <i>Vendors Proposal Content</i></b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Headers 
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -20px; left: 175px; width: 620px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>These headers provide clearer descriptions of what the vendors must submit to 
                                                                        support their commercial proposals.</p>
                                                                        <p>The <i>Vendors Proposal Content</i> may include "Company Profile", "Technical Proposal", "Method Statements", "Service Delivery Methodology", 
                                                                        "References and Case Studies", "Value Proposition", "Warranty and Support", "Customer Statements", "Project Plan and Milestones", "Product Catalogues", 
                                                                        "Compliance Statement with the <i>Services</i>", "Financial Statements", "Audit Reports", "Licenses", "Capability Statements", 
                                                                        "Key Personnel Bios" and more.</p>
                                                                        <p>Click on '+' to add a header.</p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorOfferContent"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control" ref="VendorOfferContent">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control" ref="VendorOfferContent">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px;" type="button" @click="deleteVendorOfferContent(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.name" placeholder="e.g. Submittal #1:" style="width: 150px; font-size: 13px; color: #09a3eb;">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.content" placeholder="e.g. The proposed Service Delivery Methodolofy is presented .....">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendoroffercontent.name && vendoroffercontent.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addVendorOfferContent()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && this.RateContractItemLine.ServiceDescription"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && this.RateContractItemLine.ServiceDescription" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ServiceContractHeaders">
                                        <span slot="label"><b>Create the <i>Services Rates’ Contract</i>  template</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Headers 
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span style="bottom: -20px; left: 175px; width: 620px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>These headers can include, for example, detailed descriptions 
                                                                    of the "Scope of Works", the required "Activities", "Deliverables", "Inclusions" and "Exclusions", the desired "SLAs (Service Level Agreements)", 
                                                                    "KPIs (Key Performance Indicators)", and "Penalty/Reward Matrix", the wanted "Manpower Skill Sets" and "Level of Experience", the "Invoicing Arrangements", 
                                                                    the "Payment Arrangements", the "Termination" and "Suspension Conditions", the "Arbitration and Litigation Arrangements", the "Force Majeure Definitions", 
                                                                    the "IP Rights", .... </p>
                                                                    <p>Click on '+' to add a header.</p>
                                                                    
                                                                    <p>Quantities of <i>Services</i> will be captured under the <i>Service Line Unit of Measure</i>, below.
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.ServiceContractHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px;" type="button" :disabled="itemAdditionFlag == 'Y'" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.name" placeholder="e.g. 1. Scope of Works" style="width: 180px; font-size: 13px; color: #fc3317;">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.content" placeholder="e.g. The Awardee Vendor shall deliver the following.....">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="servicecontractheader.name && servicecontractheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px; background-color: green; color: white; border: 1px solid green;" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.ServiceDescription"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.ServiceDescription">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceUOM">
                                        <span slot="label"><b>Enter A <i>Service Line Unit of Measure</i></b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 423px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>This must be a <u>highly detailed</u> <i>Service Line Unit of Measure</i>, for which vendors will provide rates in their proposals. </p>
                                                    <p>A <i>Service Line Unit of Measure</i> can be, for example, 'Housekeeping Services for 8 hours a day, 5 days a week', 
                                                    'Social Media Management Services, for 30 days, by a digital marketer with 10+ years of experience in Saas products', 
                                                    'Legal Representation Services for 30 days by a qualified common law counsel with over 5 years of experience in commercial agreements'. 
                                                    and so on.</p>
                                                </span>
                                            </span>
                                        </span>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter a highly detailed Service Line Unit Of Measure"
                                            v-model="RateContractItemLine.ServiceUOM">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Service' && RateContractItemLine.ServiceUOM !== ''"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Material' && (RateContractItemLine.AdvancedPayment == 'Yes' || RateContractItemLine.AdvancedPayment == 'No')"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="(RateContractItemLine.AdvancedPayment=='No' && RateContractItemLine.RCType == 'Material') || (RateContractItemLine.AdvancedPayment=='Yes' && RateContractItemLine.RCType == 'Material') ">
                                <div class="grid-content">
                                <el-form-item label="temp" prop="ItemNumber">
                                            <span slot="label"><span class="text-ogeo">* </span><b>Select from the <i>Library of Materials</i></b></span>
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                <span class="tooltip-item2">
                                                    <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>  
                                                </span>
                                                <span style="bottom: -20px; left: 175px; width: 428px;" class="tooltip-content4 clearfix">
                                                <!--<span class="tooltip-content4 clearfix" style="left: 150px;">-->
                                                    <span class="tooltip-text2">
                                                        <p>Only items from the <i>Library of Materials</i>, that are <u>active</u> for the designated <i>Account</i>, are selectable.</p>
                                                    </span>
                                                </span>
                                            </span>
                                            <el-select style="width: 100%"
                                                @change="getUom()"
                                                v-model="RateContractItemLine.ItemNumber"
                                                filterable
                                                remote
                                                :remote-method="searchItems"
                                                :loading="searchItemsLoading"
                                                popper-class="itemSelect"
                                                placeholder="Enter a keyword to search the Library of Materials and select an item">
                                                <el-option style="max-width: 1200px; min-height: 150px"
                                                    v-for="item in FoundItems"
                                                    :key="item.id"
                                                    :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+' || '+item.description"
                                                    :value="item.id">

                                                    <div class="row" style="border: 2px solid black">
                                                        <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                            <img v-if="!isJSON(item.picture)" class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                            <img v-else class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+JSON.parse(item.picture)[0]">
                                                            
                                                        </div>
                                                        <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                            <table class="table itemTable">
                                                                <tr>

                                                                    <td>
                                                                        <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                        <span style=" white-space: normal; line-height: 1.5em;">
                                                                            <template v-for="index in 20">      
                                                                                <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                    <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}: </b> {{ item['field_'+index] }} | 
                                                                                </span>
                                                                            </template>
                                                                        </span><br><br>
                                                                        <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </div>
                                                        <div class="col-sm-3 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; margin-right: 5%; word-wrap: break-word; border-left: 2px solid black">
                                                            <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                            <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                                <tr>
                                                                    <table>
                                                                        <tr>
                                                                            <th><b class="font-700">Vendor Name</b></th>
                                                                            <th><b class="font-700">Vendor Score</b></th>
                                                                            <th><b class="font-700">Expiry Date</b></th>
                                                                            <th><b class="font-700">Unit Rate</b></th>
                                                                            <th><b class="font-700">Lead Time</b></th>
                                                                        </tr>
                                                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                            <td>{{ item.vendor_name }}</td>
                                                                            <td>{{ item.vendor_score }}</td>
                                                                            <td>{{ item.date }}</td>
                                                                            <td>{{ item.unit_rate }} USD </td>
                                                                            <td>{{ item.lead_time }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid black">
                                                            <h4 class="text-center">Last Transactions Info</h4>
                                                            <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                                <tr>
                                                                    <table>
                                                                        <tr>
                                                                            <th><b class="font-700">Unit Rate</b></th>
                                                                            <th><b class="font-700"><i>Account</i> Name</b></th>
                                                                            <th><b class="font-700">City</b></th>
                                                                            <th><b class="font-700">Date</b></th>
                                                                            <th><b class="font-700">Quantity</b></th>
                                                                            <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                        </tr>
                                                                        <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                            <td>{{ item.unit_rate }}</td>
                                                                            <td>{{ item.concern }}</td>
                                                                            <td>{{ item.city }}</td>
                                                                            <td>{{ item.date }}</td>
                                                                            <td>{{ item.quantity }}</td>
                                                                            <td>{{ item.pobid }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                </div>
                                
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.ItemNumber !=='' && RateContractItemLine.RCType == 'Material'"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.ItemNumber && RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content">
  
                                      <!-- <el-form-item label="Enter the expected needed quantity during the term?" prop="Quantity"> -->
  
                                    <el-form-item label="temp" prop="Quantity">
                                              <span slot="label"><b>Enter the item's expected required quantity (in {{this.uom.toUpperCase()}}) throughout the term </b></span>
                                              <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: -112px; width: 428px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                <p>This information regarding the expected quantity required for the item will be communicated to vendors during the tendering process. 
                                                    It gives them an understanding of the anticipated demand throughout the term of the <i>Rates' Contract</i>.</p>
                                                    <p>However, this information is provided "FOR INFORMATION ONLY". Your organization does not commit to sourcing this exact 
                                                        quantity but rather to procuring items under the <i>Rates' Contract</i> as needed.</p></span>
                                            </span>
                                        </span>
                                          <el-input  type="number" placeholder="Enter the item's expected required quantity throughtout the term" v-model="RateContractItemLine.Quantity">
                                          </el-input>
                                    </el-form-item>
  
                                </div>
                                  
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.Quantity > 0 && RateContractItemLine.RCType == 'Material'"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.Quantity && RateContractItemLine.RCType == 'Material'">
                                <div class="grid-content" >
                                    <el-form-item label="temp" prop="VolumeDiscount">
                                            <span slot="label"><b>Do you want to have A <i>Volume-discount Arrangement</i> apply to the selected item?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>              
                                            </span>
                                            <span style="bottom: -20px; left: -150px; width: 412px;" class="tooltip-content4 clearfix">
                                                    <span class="tooltip-text2">
                                                        <p>A <i>Volume-discount Arrangement</i> is a clause that will be included in the <i>Rates' Contract</i>:</p>
                                                        <p>Under this arrangement, the <i>Awardee Vendor</i> will offer <u>discounted Unit Rates</u> once the total procurement of 
                                                            <i>Materials</i> or <i>Services</i> exceeds a specified threshold known as the <i>Minimal Procured Value</i>.</p>
                                                    </span>
                                            </span>
                                        </span>
                                        <el-select filterable collapse-tags class="volume" id="volume" @change="hideShowMin()" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.VolumeDiscount" placeholder="Select 'Yes' or 'No'">
                                            
                                            <el-option label="Yes" value="Yes"></el-option> 
                                            <el-option label="No" value="No"></el-option> 
                                           
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <transition v-if="RateContractItemLine.VolumeDiscount == 'Yes'  && RateContractItemLine.RCType == 'Material'">
                                <el-col :span="24" >
                                    <!-- <div class="grid-content">  -->             
                                        <el-form-item label="temp" prop="MinimalProcuredValue" class="custominput">
                                            <span slot="label" class="customLabel"><b>Enter the <i>Minimal Procured Value</i> (in {{this.projectcurrency.substring(0, 3)}}) which will trigger the item’s <i>Unit Rate’s</i> discount</b>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                   <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                                    
                                                    </span>
                                                    <span style="bottom: -20px; left: 175px; width: 622px;" class="tooltip-content4 clearfix">
                                                    <span class="tooltip-text2">
                                                        <p>The <i>Minimal Procured Value</i> represents the minimum monetary threshold needed to activate the <i>Volume-discount Arrangement</i>, stated in this 
                                                            <i>Rates' Contract</i>, for the selected item from the <i>Library of Materials</i>.</p> 
                                                        <p>Once this threshold is met, subsequent <i>Purchase Orders</i> for this item will qualify for a <u>discounted Unit Rate</u>.</p> 
                                                        <p>The discount on the item's <i>Unit Rate</i>, agreed upon with the <i>Awardee Vendor</i>, will be communicated by the vendors in their proposals during the tendering process.</p>
                                                    </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <el-input id="minVal" @change="commaprice()" class="minVal" type="currency" placeholder="Enter the Minimal Procured Value which will trigger the Unit Rate’s discount for the item" v-model="RateContractItemLine.MinimalProcuredValue">                       
                                            </el-input>
                                        </el-form-item>
                                    <!-- </div> -->
                                </el-col>

                            </transition>
                            <el-col :span=24 v-if="(RateContractItemLine.MinimalProcuredValue > 0 && RateContractItemLine.RCType == 'Material') || (RateContractItemLine.VolumeDiscount !== '' && RateContractItemLine.RCType == 'Material')"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <!-- ----------------------------------- -->
                            <button v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Material'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Item To The <i>Materials Rates’ Contract Creation Request</i></button>
                            <button v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Service'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Item To The <i>Services Rates’ Contract Creation Request</i></button>

                        </el-row>
                    </el-form>
                </div>
            </div>
            <div class="card" v-else>
                <div class="card-header">
                    <h4><b>Initiate A <i>Rates' Contract Creation Request</i></b></h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Details.....</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of Items in the list -->
        <div id="Rate_Contract_List" class="col-lg-5" v-if="this.RateContractItemLine.RCType !== ''">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="RateContractAllItems.length > 0" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4 style="text-align: center;"><i>Rates' Contract Creation Request</i> Lines</h4>
                            </div>
                            <div id="Rate_Contract_List_Table" class="card-body">
                                <table class="table" v-if="RateContractAllItems[0].RCType == 'Service'">
                                    <tr>
                                        <td class="text-ogeo">
                                            <span style="color: black;"><b><i>Service Rates' Contract</i> Title:</b><br></span> {{ RateContractAllItems[0].ItemLibraryDescription.description }}
                                        </td>
                                    </tr>
                                </table>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'"><i>Library of Materials</i><br> Item Details</th>
                                            <th style="font-size: smaller; text-align: center;" v-if="RateContractItemLine.RCType == 'Service'"><i>Service Line Unit of Measure</i></th>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'">Expected Required<br> Quantity</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(RateContractItem, key, index) in RateContractAllItems" :key="index">
                                            
                                            <!-- <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.description.substring(0, 20) + '...' }}</td> -->
                                            <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase().substring(0, 60) + '...' }}</td>
                                            <td v-if="RateContractItem.ItemNumber && RateContractItem.RCType == 'Material'"><span style="word-wrap: break-word; white-space: wrap;"><b>LoM-{{ RateContractItem.ItemGroupId  }}-{{ RateContractItem.ItemInGroupId  }}</b><br>{{ RateContractItem.ItemLibraryDescription.description.slice(0, 40) + '...' }}</span></td>
                                            <td v-if="RateContractItem.RCType == 'Material'">{{ RateContractItem.Quantity }} {{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase() }} </td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showRCDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <!-- <a class="btn btn-warning float-left" href="#" @click="showItemDetails(key, $event)"><i class="fa fa-edit"></i></a> -->
                                                <a class="btn btn-danger float-left m-l-5" href="#" @click="RemoveItem(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                                                        
                                    <!--<el-checkbox class="chkAgree" v-model="RateContractItemLine.lomTOC">I have read my organization’s <i>Materials Rates’ Contract</i> which will govern the relationship with the <i>Awardee Vendor</i>.</el-checkbox>-->
                                        <div>
                                            <fieldset style="width: 435px; height: 115px; background-color: rgba(252, 51, 23, 0.03); padding: 5px; border-radius: 4px; border: 1px solid black; color: black; overflow: hidden; margin-left: -10px;">
                                                <el-checkbox class="chkAgree" v-model="RateContractItemLine.lomTOC" @change="checkTOC($event)">
                                                    <p style="color: black !important; text-align: justify; margin: 0; height: 100px; width: 92%; overflow: hidden;">
                                                        I have read and understand my organization's  <em>Materials Rates’ Contract</em>, which will govern the 
                                                        relationship with the <em>Awardee Vendor</em>, for the procurement of all the <em>Library of Materials</em> items listed in this 
                                                        <em>Materials Rates' Contract Creation Request</em>.
                                                    </p>     
                                                </el-checkbox>
                                            </fieldset>
                                        </div>
                                <span>
                                    <a href="" style="font-size: smaller;">
                                        <i class="fa fa-download" style="color: rgb(252, 51, 23);"></i>
                                        <span style="color: rgb(64, 64, 64);"> Download the </span>
                                        <span style="color: rgb(64, 64, 64); font-style: italic;">Materials Rates' Contract.</span>
                                    </a>

                                </span>
                                <br><br>
                                    <span style="color:#111111; font-size: smaller;"><p><b><u>Note:</u></b> Please reach out to those holding the role of <u><i>Application Administrator</i></u>, should you need to have the <i>Materials Rates’ Contract</i> amended.</p></span>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button v-if="RateContractAllItems && RateContractAllItems[0].RCType == 'Service'" type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Services Rates’ Contract Creation Request</i></button>
                                <button v-else-if="RateContractAllItems && RateContractItemLine.lomTOC" type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Materials Rates’ Contract Creation Request</i></button>
                                <button v-else type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light" disabled>Submit The <i>Materials Rates' Contract Creation Request</i></button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                 <el-row v-else :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4 style="text-align: center;"><i>Rates' Contract Creation Request</i> Lines</h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="font-size: smaller;" v-if="RateContractItemLine.RCType == 'Material'"><b><i>Library of Materials</i> <br>Item Details</b></th>
                                        <th style="font-size: smaller; text-align: center;" v-if="RateContractItemLine.RCType == 'Service'"><b><i>Service Line Unit of Measure</i></b></th>
                                        <th style="font-size: smaller;" v-if="RateContractItemLine.RCType == 'Material'"><b>Expected Required <br>Quantity</b></th>
                                        <th style="font-size: smaller;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!--<td class="text-center" colspan="4">The <i>Rates' Contract Creation Request</i> Is Empty</td>-->
                                        <td style="text-align: center; color: red; font-size: 16px;" colspan="4">The <i>Rates' Contract Creation Request</i> Is Empty</td>

                                        <!--<td class="text-ogeo font-200" colspan="4" style="text-align: center;">The <i>Rates' Contract Creation Request</i> Is Empty</td>-->
                                    </tr>
                                </tbody>
                            </table>
                            
                            <!--<div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                <template>
                                    <div>
                                        <fieldset style="width: 450px; height: 122px; background-color: rgb(252, 51, 23, 0.030); padding: 10px; border-radius: 4px; border: 1px solid black; color: black;">
                                            <el-checkbox class="chkAgree" disabled v-model="RateContractItemLine.lomTOC" @change="checkTOC($event)">
                                                <p style="color: black !important;">
                                                I have read and I'm familiar with my organization's <em>Materials Rates’ Contract</em>, which will govern the 
                                                relationship with the <em>Awardee Vendor</em>, for the sourcing of all the <em>Library of Materials</em> items listed in this 
                                                <em>Materials Rates' Contract Creation Request</em>.
                                                </p>    
                                            </el-checkbox>
                                        </fieldset>
                                    </div>
                                </template>       
                                    <span>
                                        <a href="" style="font-size: smaller;">
                                            <i class="fa fa-download" style="color: rgb(252, 51, 23);"></i>
                                            <span style="color: rgb(64, 64, 64);"> Download the </span>
                                            <span style="color: rgb(64, 64, 64); font-style: italic;">Materials Rates' Contract.</span>
                                        </a>
                                    </span>
                                    <hr>
                                <span style="color:#111111; font-size: smaller;"><b><u>Note:</u></b> Please reach out to those holding the role of <i>Application Administrator</i>, should you need to have the <i>Materials Rates’ Contract</i> amended.</span>                  
                            </div>-->
                    </div>
                        <div class="card-footer">
                            <button v-if="RateContractItemLine.RCType == 'Material'" type="button" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Materials Rates' Contract Creation Request</i></button>
                            <button v-else type="button" @click="SubmitRateContractItemList" class="btn btn-block btn-ogeo waves-effect waves-light">Submit The <i>Services Rates’ Contract Creation Request</i></button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>
        
       

            <!-- Modal Code   -->
            <div class="modal fade" id="RCDetailsModalId" tabindex="-1" aria-labelledby="RCDetailsModalId" role="dialog" aria-hidden="true">
               
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="RCLineShow">
                    <div class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo" v-html="RCModalInfo.RCType == 'Material' ? '<em>Materials Rates’ Contract Creation Request</em> Line ID#' + RCModalInfoKey : '<em>Services Rates’ Contract Creation Request</em> Line ID#' + RCModalInfoKey"></h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:60px;height:60px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>            
                     <div class="modal-body">
                            <div class="row">

                                <!-- Rates Contracts General Information Panel -->
                                <div class="col-lg-12">
                                    <table class="table">
                                            <tr>
                                                <td width="40%"><b class="font-700"><i>Account</i> Details: </b></td>
                                                <td>
                                                    <span><b class="font-700 font-italic"><i>Account</i> Name:</b> {{ RCModalInfo.project.title }}</span><br>
                                                    <span><b class="font-700 font-italic">Start Date:</b> {{ RCModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">End Date:</b> {{ RCModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(RCModalInfo.project.value - RCModalInfo.project.consumed_budget) }} {{ RCModalInfo.project.currency }}</span>
                                                </td>
                                            </tr>

                                            
                                    </table>
                                </div>
                                <!-- <div class="col-lg-12">
                                    <table class="table">
                                        
                                        <tr>
                                            <td><b class="font-700">Line Added On: </b></td>
                                            <td>{{ moment().format("DD.MMM.YYYY - (HH:MM:SS)") }}</td>
                                        </tr>
                                    </table>
                                </div> -->
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                                <td><b class="font-700">Line Initiated By: </b></td>
                                                <td>{{ currentUser.Name }}</td>
                                            </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Rates' Contract</i>  Underlying Transaction: </b></td>
                                            <td>{{ RCModalInfo.UnderlyingTransaction }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Rates' Contract</i> Type: </b></td>
                                            <td>{{ RCModalInfo.RCType }}s</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Rates' Contract</i> Term: </b></td>
                                            <td>{{ RCModalInfo.RateContractItemPeriod }} Months</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700"><i>Rates' Contract</i> Country | City(ies): </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.CompanyCountry }} | {{ RCModalInfo.CompanyCities.toString().replaceAll(',', ', ')}}</span><br>

                                            </td>
                                        </tr>

                                    </table>
                                </div>
                                <!-- Rates Contracts General Information Panel End -->

                                <!-- Materials Information Panel -->
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700" v-html="RCModalInfo.RCType == 'Material' ? 'Are <em>Advance Payments</em> Considered For The Items<br>Procured Under The <em>Materials Rates’ Contract</em>?' : 'Are <em>Advance Payments</em> Considered For The Service Lines<br>Procured Under The <em>Services Rates’ Contract</em>?'"></b>
                                            <td>{{ RCModalInfo.AdvancedPayment }}</td>
                                            <td width="40%"><b class="font-700">Are Collaterals Required From The <br>Vendors Against <i>Advance Payments</i> ?</b></td>
                                            <td>{{ RCModalInfo.GuaranteeNeedForAdvancePayment ? RCModalInfo.GuaranteeNeedForAdvancePayment : 'Not Applicable' }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700" v-html="RCModalInfo.RCType == 'Material' ? 'What Is The <em>Retention Percentage</em> For The Items<br>Procured Under The <em>Materials Rates’ Contract</em>?' : 'What Is The <em>Retention Percentage</em> For The Service Lines<br>Procured Under This <em>Services Rates’ Contract</em>?'"></b>
                                            <td>{{ RCModalInfo.RetentionPercentage ? RCModalInfo.RetentionPercentage + '%' : 'Not Applicable' }}</td>
                                            <td width="40%"><b class="font-700"><i>Retention Timeframe*</i> : </b><i style="font-size: 0.85rem;"><br>*From the date of either receiving the complete quantity <br>specified in the Purchase Order or closing the Purchase Order.</i></td>
                                            <td>{{ RCModalInfo.RetentionDays ? RCModalInfo.RetentionDays + ' Day(s)' : 'Not Applicable' }}</td>
                                        </tr>                                 
                                    </table>
                                    <table class="table">
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                            <td v-if="RCModalInfo.RequiredDocuments">
                                                <ol style="column-count: 2;">
                                                    <li v-for="(document, index) in RCModalInfo.RequiredDocuments">{{ document }}</li>
                                                </ol>
                                            </td>
                                            <td v-else>
                                                No Documents Required
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ RCModalInfo.SourcingPriority }}</td>
                                        </tr>
                                               
                                       <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td style="width: 40%; vertical-align: center;"><b class="font-700"><i>Library of Materials</i> <br>Item Description: </b></td>
                                            <td>
                                                <span v-if="!isJSON(RCModalInfo.ItemLibraryDescription.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+RCModalInfo.ItemLibraryDescription.picture"></span>
                                                <span class="text-center" v-else v-for="(img, index) in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></img></span>
                                                <br><br><span><b class="font-700"><span v-if="RCModalInfo.ItemLibraryDescription.company.lom_prefix == null">LoM</span><span v-else>LoM-</span>{{ RCModalInfo.ItemLibraryDescription.item_group_id }}-{{ RCModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                                <span>
                                                    <template v-for="index in 20">      
                                                        <span v-if="RCModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b class="font-700 text-uppercase">{{ RCModalInfo.ItemLibraryDescription.item_template['field_'+index] }}:</b> {{ RCModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br><br>
                                                <!-- <span><b>Quantity:</b> {{ RCModalInfo.Quantity }}</span><br> <br> -->
                                                <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measure:</b> {{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                                <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                            </td>
                                        </tr> 
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Estimated Item Quantity <br>Required During The Term: </b></td>
                                            <td>  
                                                
                                                {{  RCModalInfo.Quantity }}
                                            
                                            </td>


                                        </tr>

                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700"> Is There A <i>Volume-discount Arrangement</i><br>For This Item ? </b></td>
                                            <td>  
                                               
                                                 <span>{{RCModalInfo.VolumeDiscount}}</span>
                                                         
                                            
                                            </td>


                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Is There A <i>Minimal Procured Value</i> Required To<br> Trigger The Item Unit Rates' Discount: </b></td>

                                            <td>  

                                             <span>
                                                 <span v-if="RCModalInfo.VolumeDiscount == 'No' ">
                                                    Not applicable As This Item Doesn't Have A <i>Volume-discount Arrangement</i>.
                                                     </span>
                                                     <span v-else>
                                                         {{ RCModalInfo.MinimalProcuredValue }} {{this.projectcurrency.substring(0, 3)}}
                                                         </span>
                                                          </span>

                                            </td>

                                           
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>  
                                                <!-- This Rates Contract doesn't have a volume discount clause -->
                                                Not Yet Awarded
                                            
                                            </td>


                                        </tr>
                                       
                                    </table>
                                </div>
                                
                                <!-- Materials Information Panel End -->
                                
                                <!-- Services Information Panel -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">
                                    <table class="table">
                                        <!-- <tr align="center" v-if="RCModalInfo.Images.length > 0">
                                            <td v-for="(img, index) in RCModalInfo.Images" v-bind:key="img.id">
                                                <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                            </td>
                                        </tr> -->
                                        
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700"><i>Service Rates’ Contract</i> Title: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.description }}</span><br>

                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700"><i>Instructions To Vendors</i></b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorInstructionHeaders">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700"><i>Vendors Proposal Content</i></b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorOfferContent">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="" id="SCT" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b><i>Service Rates’ Contract</i> Template</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 SCTH">Header</b></th>
                                                        <th id="SCTC"><b class="font-700 SCTC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.ServiceContractHeaders">
                                                        <td class="text-uppercase font-700 SCTH col-lg-2">{{ header.name }}</td>
                                                        <td class="SCTC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700"><i>Service Line Unit of Measure :</i> </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>

                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>
                                                <span>{{ 'Not Yet Awarded' }}</span><br>

                                            </td>
                                        </tr>
                                        
                                            
                                        
                                    </table>
                                    
                                </div>
                                <!-- Services Information Panel End -->
                                
                                <!-- List of Valid Rates Contracts -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Material'">
                                    <hr>
                                    <table class="table">
                                        <tr class="text-ogeo text-center">
                                            <th><b class="font-700">List Of Valid <i>Materials Rates’ Contracts</i> For The Item</b></th>
                                        </tr>
                                            <table>
                                                <tr>
                                                    <th><b class="font-700">Vendor Name</b></th>
                                                    <th><b class="font-700">Country | City(ies)</b></th>
                                                    <th><b class="font-700">Unit Rate</b></th>
                                                    <th><b class="font-700">Expiry Date</b></th>
                                                    <th><b class="font-700">Lead Time</b></th>
                                                    <th><b class="font-700"><i>Minimal Procured Value</i>To Trigger<br>Discount</b></th>
                                                    <th><b class="font-700">Unit Rate <br>Discount</b></th>
                                                    <th><b class="font-700">Total Procured Till<br>Document Date</b></th>
                                                    <th><b class="font-700">Advance Payment <br>Information</b></th>
                                                    <th><b class="font-700">Retention Information</b></th>
                                                </tr>
                                                <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                    <td>{{ item.vendor_name }}</td>
                                                    <td>{{ item.unit_rate }} USD </td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.lead_time }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                </tr>
                                            </table>
                                    </table>
                                </div>

                                <!-- <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">                            
                                    <table class="table mt-3">
                                        <tr class="text-center text-ogeo">
                                            <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                            <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th><b class="font-700">Reference</b></th>
                                            <th><b class="font-700">Short Description</b></th>
                                            <th><b class="font-700">Service Unit Of Measurement</b></th>
                                            <th><b class="font-700">Expiry Date</b></th>
                                        </tr>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </tr>
                                    </table>
                                    <hr>   
                                </div> -->
                                <!-- List of Valid Rates Contracts End -->

                            </div>
                            
                    </div>

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block pull-left">            
                                <span><b>Document generated by:</b> {{ currentUser.Name }}</span>
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                            <br>
                            <br>
                            <br>
                            <div class="col-lg-24; text-center">
                                <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                                <br>
                                <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                <div class="d-flex justify-content-center mt-3">
                                    <button class="btn btn-success" onclick="document.title = 'LoM Item';window.print()" style="margin-bottom: 20px;">Download PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
       
            </div>
            <!-- Modal Code  -->

    </div>


            
</template>

<script>
    

    //import { CustomJs } from '../../helpers/custom.js';
    import swal from 'sweetalert2';
import validate from 'validate.js';

    export default {
        name: 'new-request-rate-contract-creation',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.RateContractItemLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please enter the Retention Percentage (between 0 & 100)')); 
                }
                
                if ((value + '').replace(/^-?\d*\.?|0+$/g, '').length > 2) {
                    callback(new Error('Please enter no more than two decimals.'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('The Rentention Percentage should be between 0 and 100'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkItemSelection = (rule, value, callback) => {
                if (!value) { 
                    return callback(new Error('Please select an item from the Library of Materials')); 
                } else {
                    callback();
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.RateContractItemLine.RetentionPercentage != '') { 
                    return callback(new Error('Please select the Retention Timeframe')); 
                } else {
                    callback();
                }
                
            };
            return {
                hostName: window.location.protocol+'://'+window.location.hostname,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
			    cities: [],
                projectcurrency: '',
                uom: '',
                showOtDocs: false,
                ReqDocTmp: [],
                OtherDocTmp: '',
                OtherDocTmpHolder: [],
                FirstStepData:{
					CompanyLogo: "",
					curr: "",
                    companyName: "",
                    companyTime:"",
                    CompanyEmployeeRange: [],
                    CompanyIndustry: [],
                    CompanyCities: [],
                    companyCity: "",
                    CompanyCountry: "",
                    CompanyCountries: [],
				},
                vendorinstructionheader: {
                    name: '',
                    content: '',
                },
                vendoroffercontent: {
                    name: '',
                    content: '',
                },
                servicecontractheader: {
                    name: '',
                    content: '',
                },
			    EmployeesList: [],
			    ProjectKey: "",
			    JobNumbers: [],
                itemAdditionFlag: "N",
                minVal: '',
                curr: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                RCModalInfoKey: "",
                RCModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                CompanyDetailsLoaded: false,
                FoundItems: {},
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                RateContractItemLine: {
                    JobNumber: "",
                    RCType: "",
                    curr: "",
                    ItemNumber: "",
                    RateContractItemPeriod: "",
                    VolumeDiscount: "",
                    MinimalProcuredValue: "",
                    Quantity: "",
                    SetupName: "",
                    ServiceDescription: "",
                    ServiceUOM: "",
                    SourcingPriority: "Standard",
                    UnderlyingTransaction: "", 
                    ItemLibraryDescription: "",
                    VendorInstructionHeaders: [],
                    VendorOfferContent: [],
                    ServiceContractHeaders: [],
                    CompanyCities: [],
                    CompanyCountry: "",
                    uomTodisplay: "",
                    ItemGroupId: "",
                    ItemInGroupId: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    lomTOC: "",
                    rtYN: 'no',
                },
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
                      city: 'Herat'
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
                      city: 'Herat'
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                RateContractAllItems: [],
                RCItemModalInfoKey: "",
                RCItemModalInfo: "",
                RCLineShow: false,
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true,
                        message: "Please select an Account",
                        trigger: ["blur"]
                    }],
                    RCType: [{
                        required: true,
                        message: "Please select the Rates' Contract type",
                        trigger: ["blur"]
                    }],
                    VendorInstructionHeaders: [{
                        required: true, message: "At least one header for instructions and its content are required.", trigger: ["change"]
                    }],
                    VendorOfferContent: [{
                        required: true, message: "At least one header for the offer content and its content are required.", trigger: ["change"]
                    }],
                    ServiceContractHeaders: [{
                        required: true, message: "At least one header for the darft service contract and its content are required.", trigger: ["blur", "change"]
                    }],
                    CompanyCountries: [{
                        required: false,
                        message: "Please select a country.",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        validator: checkItemSelection,
                        trigger: ["blur"]
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please enter the expected required quantity.",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please select an item.",
                        trigger: "blur"
                    }],
                    RateContractItemPeriod: [{
                        required: true,
                        message: "Please enter the Rates’ Contract term.",
                        trigger: "blur"
                    }],
                    VolumeDiscount: [{
                        required: true,
                        message: "Please take a decision with regards to the Volume-discount arrangement.",
                        trigger: "blur"
                    }],
                    MinimalProcuredValue: [{
                        required: true,
                        message: "Please enter the Minimal Procured Value. Must be a number.",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please select the Unit of Measure.",
                        trigger: ["blur", "change"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please enter a title for the Services Rates’ Contract.",
                        trigger: ["blur"]
                    }],
                    ServiceUOM: [{
                        required: true,
                        message: "Please enter a detailed Service Line Unit of Measure.",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please select the term of the Services Rates’ Contract.",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please take a decisioon with regards to the Advance Payments arrangement.",
                        trigger: ["blur", "change"]
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please select the Retention Timeframe.", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please select a document.",
                        trigger: "blur"
                    }],
                    CompanyCountry: [{
                        required: true,
                        message: "Please select a country.",
                        trigger: "blur"
                    }],
                    CompanyCities: [{
                        required: true,
                        message: "Please select city(ies).",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please enter a delivery location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please enter a delivery location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please enter the delivery location name.",
                        trigger: "blur"
                    }]
                },
            }
        },
		created() {
            this.getCountries();
            console.log('carried');
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            commaprice() {
                if(!isNaN(this.RateContractItemLine.MinimalProcuredValue))
                    this.RateContractItemLine.MinimalProcuredValue = this.RateContractItemLine.MinimalProcuredValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                else
                    this.RateContractItemLine.MinimalProcuredValue = ""
            },
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            OtherDocsVisibility(){
                if(this.ReqDocTmp.includes('Other')){
                  this.$refs.reqDocs.blur();
                  $('.reqDocs').hide();
                }
            },
            searchItems(value) {
                console.log(value);
                this.RateContractItemLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.RateContractItemLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                    })
            },
            checkTOC(e){
                if(e == false){swal('Warning','A draft of <em>Materials Rates’ Contract</em> is needed to initiate a <em>Rates’ Contract Creation Request</em>.', 'warning'); this.RateContractItemLine.lomTOC = false}
            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        console.log('in the countries sections');
                        this.countries = res.data
                        console.log( this.countries );

                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {


                this.cities = [];
                this.RateContractItemLine.CompanyCities = [];
				if(this.RateContractItemLine.CompanyCountry != '' || this.RateContractItemLine.CompanyCountry != null ){
  
                  this.cities= this.countries[this.RateContractItemLine.CompanyCountry];
               }
            },
            checkProjectExpiry(e){
                var val = axios.get('/api/data/get_project_expiry/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        if(new Date(response.data[0]) > new Date())
                            return true;
                        else{
                            this.RateContractItemLine.JobNumber = '';
                            swal('The Selected <i>Account</i> Has Expired', 'Using this <i>Account</i> will be possible if those holding the <i>Budget Update Request Initiator</i> role extend its end date.', 'error');
                            return false;    
                        }
                    })
                return val;
            },
            getProjectCurrency(e)
            {
                if(this.checkProjectExpiry(e))
                    axios.get('/api/data/get-project-currency/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        this.projectcurrency = response.data[0]
                    })
             
            },
            resetForm(){
                this.RateContractItemLine.curr= "",
                this.RateContractItemLine.ItemNumber= "",
                this.RateContractItemLine.RateContractItemPeriod= "",
                this.RateContractItemLine.VolumeDiscount= "",
                this.RateContractItemLine.MinimalProcuredValue= "",
                this.RateContractItemLine.Quantity= "",
                this.RateContractItemLine.SetupName= "",
                this.RateContractItemLine.ServiceDescription= "",
                this.RateContractItemLine.ServiceUOM= "",
                this.RateContractItemLine.SourcingPriority= "Standard",
                this.RateContractItemLine.UnderlyingTransaction= "", 
                this.RateContractItemLine.ItemLibraryDescription= "",
                this.RateContractItemLine.VendorInstructionHeaders= [],
                this.RateContractItemLine.VendorOfferContent= [],
                this.RateContractItemLine.ServiceContractHeaders= [],
                this.RateContractItemLine.CompanyCities= [],
                this.RateContractItemLine.CompanyCountry= "",
                this.RateContractItemLine.uomTodisplay= "",
                this.RateContractItemLine.ItemGroupId= "",
                this.RateContractItemLine.ItemInGroupId= "",
                this.RateContractItemLine.ServiceTerm= "",
                this.RateContractItemLine.AdvancedPayment= "",
                this.RateContractItemLine.GuaranteeNeedForAdvancePayment= "",
                this.RateContractItemLine.RetentionPercentage= "",
                this.RateContractItemLine.RetentionDays= "",
                this.RateContractItemLine.RequiredDocuments= [],
                this.RateContractItemLine.lomTOC= "",
                this.RateContractItemLine.rtYN= 'no';

                this.$nextTick(( ) => {
                    this.$refs.RateContractRequestForm.clearValidate();
                })
            },
            RCTypeSelection: function(e){
                if(e) {
                    $('#Rate_Contract_List').removeClass('animated fadeOutRight');
                    $('#Rate_Contract_List').addClass('animated fadeInRight');
                    $('#Rate_Contract_Request_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);

                    this.resetForm();
                    this.ReqDocTmp = [];
                    this.OtherDocTmp = '';
                    this.OtherDocTmpHolder = [];
                }
                else {
                    this.resetForm();
                    this.ReqDocTmp = [];
                    this.OtherDocTmp = '';
                    this.OtherDocTmpHolder = [];
                    $('#Rate_Contract_List').removeClass('animated fadeInRight');
                    $('#Rate_Contract_List').addClass('animated fadeOutRight');
                    $('#Rate_Contract_Request_Form').removeClass('col-lg-7').addClass('col-lg-12').delay(500);
                }
            },
             getUom()
            {
                     let itemAtHand = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                     
                     this.uom = itemAtHand.u_o_m;

                                  
            },
            addOtherDocs() {
                if (this.existOtherDocs() == true) {
                    this.OtherDocTmpHolder.push({name: this.OtherDocTmp})
                    this.OtherDocTmp = ''
                } else {
                    Swal('Error', 'This Document Already Exists.' , 'error'); 
                }
            },
            existOtherDocs() {
                if (this.OtherDocTmpHolder.find(item => item.name === this.OtherDocTmp)) {
                    return false;
                } else {
                    return true;
                }
            },
            delOtherDocs(index) {
                this.$delete(this.OtherDocTmpHolder, index);
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorInstructionHeaders.push({
                        name: this.vendorinstructionheader.name,
                        content: this.vendorinstructionheader.content,
                    })
                    this.vendorinstructionheader.name = ''
                    this.vendorinstructionheader.content = ''
                } else {
                    Swal('Error', 'This Header Already Exists.' , 'error'); 
                }
            },
            existTendererContractHeader() {
                if (this.RateContractItemLine.VendorInstructionHeaders.find(item => item.name === this.vendorinstructionheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.RateContractItemLine.VendorInstructionHeaders, index);
            },
            addVendorOfferContent() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorOfferContent.push({
                        name: this.vendoroffercontent.name,
                        content: this.vendoroffercontent.content,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.content = ''
                    this.$ref.VendorOfferContent.validateField()
                } else {
                    Swal('Error', 'This Header Already Exists.' , 'error'); 
                }
            },
            existVendorOfferContent() {
                if (this.RateContractItemLine.VendorOfferContent.find(item => item.name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.RateContractItemLine.VendorOfferContent, index);
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.RateContractItemLine.ServiceContractHeaders.push({
                        name: this.servicecontractheader.name,
                        content: this.servicecontractheader.content,
                    })
                    this.servicecontractheader.name = ''
                    this.servicecontractheader.content = ''
                } else {
                    Swal('Error', 'This Contract Header already exists.' , 'error'); 
                }
            },
            existContractHeader() {
                if (this.RateContractItemLine.ServiceContractHeaders.find(item => item.name === this.servicecontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.RateContractItemLine.ServiceContractHeaders, index);
            },
			hideShowMin() {
				this.RateContractItemLine.MinimalProcuredValue = '';
                if(this.RateContractItemLine.VolumeDiscount=='Yes'){
                        $('#min_val').show();
                }else{
                     $('#min_val').hide();
                }

            },
            AddItem: function() {


                this.RateContractItemLine.uomTodisplay=this.uom;
                
                this.$refs.RateContractRequestForm.validate((validation) => {


                    if(validation){
                        let duplicateItem = 'N';
                        if(this.RateContractItemLine.RCType == 'Material') {
                            
                            if(this.itemAdditionFlag=="N"){
                                this.ReqDocTmp.map(item => {
                                    if(item !== 'Other')
                                        this.RateContractItemLine.RequiredDocuments.push(item)
                                })
                                if(this.OtherDocTmpHolder)
                                    this.OtherDocTmpHolder.map(item => {
                                            this.RateContractItemLine.RequiredDocuments.push(item.name)
                                    })
                            }
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                            
                            this.RateContractItemLine.ItemGroupId=  selectedItem.item_group_id;
                            this.RateContractItemLine.ItemInGroupId = selectedItem.item_ingroup_id;
                            this.RateContractItemLine.ItemLibraryDescription = selectedItem;

                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {
                    
                                        
                                            if(element.ItemNumber == this.RateContractItemLine.ItemNumber){
                                                console.log('Duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This item.','warning');
                                                duplicateItem='Y';
                                                return;
                                            }

                                        });
                                            

                                        }
                                }
                        else {
                            const descOBJ = {description: this.RateContractItemLine.ServiceDescription, u_o_m: this.RateContractItemLine.ServiceUOM};
                            this.RateContractItemLine.ItemLibraryDescription = Object.assign({}, descOBJ);                            
                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {

                                        
                                            if(element.ItemLibraryDescription.u_o_m.includes(this.RateContractItemLine.ItemLibraryDescription.u_o_m)){
                                                console.log('Duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This Item.','warning');
                                                duplicateItem='Y';
                                                return false;
                                            }

                                        });
                                            

                                        }
                                }

                        if(duplicateItem=='N'){
                            this.RateContractAllItems.push(Object.assign({}, this.RateContractItemLine));
                            
                            this.RateContractItemLine.ItemLibraryDescription= "",

                            this.RateContractItemLine.ServiceUOM = "";
                            this.RateContractItemLine.ItemNumber = "";
                            this.RateContractItemLine.MinimalProcuredValue = "";
                            this.RateContractItemLine.VolumeDiscount = "";
                            this.RateContractItemLine.Quantity = "";
                        
                        }

                        this.itemAdditionFlag="Y";
                    }
                })

                

            },
            showItemDetails: function(key, event) {
                if (event) event.preventDefault();

                this.RCItemModalInfoKey = key;
                this.RCItemModalInfo = Object.assign({}, this.RateContractAllItems[key]);
                this.$forceUpdate();

                $('#RCItemDetailsModalId').modal('toggle');

            },
            showRCDetails: function(key, event) {
                if (event) event.preventDefault();

                
                this.RCModalInfoKey = key+1;
                window.RCModalInfoKey = this.RCModalInfoKey;
                
                //this.RCModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);
                this.RCModalInfo = Object.assign({}, this.RateContractAllItems[key]);

                axios.post('/api/data/get_project_details', {id: this.RCModalInfo.JobNumber})
                .then(response => {
                    this.RCModalInfo.project = response.data;
                    this.$forceUpdate();
                    this.RCLineShow = true;
    
                    $('#RCDetailsModalId').modal('toggle');
                });


            },
            RemoveItem: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are You Sure?',
                  html: "This will permanently remove this line from the <i>Rates' Contract Creation Request</i>.",
                  type: 'Warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        this.RateContractAllItems.splice(key, 1);
                    }
                })

            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            SubmitRateContractItemList(event) {
                if (event) event.preventDefault();
                 axios.post('/api/rate_contracts/post_rate_contract_request', this.RateContractAllItems)
               // axios.post('/api/post_rate_contract_request', this.RateContractAllItems)
                    .then(response => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                        //Clear All Data
                        this.ReqDocTmp = [];
                        this.OtherDocTmp = "";
                        this.OtherDocTmpHolder = [];
                        this.RateContractAllItems = [];
                        this.itemAdditionFlag = 'N';
                        // this.JobNumbers="";
                        this.curr="";
                        this.cities=[];
                        //FoundItems
                        this.RCTypeSelection();
                    })
                    .catch(function(){
                        // check temporary

                        console.log('in the error brackets');
                        //  Swal({ 
                        //     type: 'success', 
                        //     title: 'Rate Contract Request Created.', 
                        //     text: 'Rate Contract Request Created Successfully.',
                        //     showConfirmButton: true,
                        //     timer: 10000
                        // });
                        // check temporary
                         Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Rate Contract Request Submit)', 'error');
                    });
                    


            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
        },
        mounted(){
            $('#Rate_Contract_List').removeClass('animated fadeInRight');
            $('#Rate_Contract_List').addClass('animated fadeOutRight');
            $('#Rate_Contract_Request_Form').removeClass('col-lg-7').addClass('col-lg-12').delay(500);
            //CustomJs();

            $("#expTerm").on('input' ,function (e) {
                alert(e);
                e.value = this.value.replace(/[^0-9]/g, "");
            });

            $("#reqDocs").change(function(){
                var id = $(this).find("el-option:selected").attr("id");

                if(id == 'otDocs'){
                    this.showOtDocs = true;
                }
                else
                    this.showOtDocs = false;
                });
            let self = this;

            axios.get('/api/data/get_user_projects_list_rcco')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;

                    axios.get('/api/data/get_company_details')
                        .then((SecondResponse) => {

                            this.CompanySetup = SecondResponse.data;
                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        // if(this.CompanySetup.additional_required_1 == "Yes"){
                        //    this.rules.UnderlyingTransaction[0].required = false; 
                        // }

                            self.CompanyDetailsLoaded = true;

                        });
                });
           
        },
        
        beforeRouteLeave (to, from, next) {

            if(this.RateContractAllItems.length > 0 || this.RateContractItemLine.RCType){
                Swal({
                    title: 'Are You Sure You Want To Navigate Away?',
                    text: "Unsaved data will be lost.",
                    type: 'Warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('RCCO')){
                next();
            } else if (currentUser.hasOwnProperty('RCCV')) {
                next('/app/rate_contracts/rate_contracts_creation_requests_list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

       
</script>


<style>
    .itemSelect .el-select-dropdown__wrap{
        max-height: 400px !important;
    }
.custominput{
    position: relative;
    text-align: left;
}
.custominput label{
    text-align: left;
    padding-left: 12px;
    line-height: 28px;
}
.custominput label:before{
    top: 2px;
    left: 0;
    position: absolute;
}
.customLabel{
    text-align: left;
    max-width: 90%;
    display: inline-block;
}

    #Rate_Contract_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Rate_Contract_Request_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    #RCItemDetailsModalId .modal-dialog {
        width:80%;
    }

    #RCDetailsModalId, #RCDetailsModalId * {
        visibility: visible;
      }
      /* #RCDetailsModalId {
        /* position: absolute; */
        /* left: 0; */
        /* top: 0; */
      /* } */ 

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .lomitemtable>tr>table>tr>th {
        border-top:none;
        margin-top: 0px;
        padding-top: 0px;
    }
    .lomitemtable {
        margin: 0px;
        padding: 0px;
    }
    .el-select-dropdown__item{
        height: 100% !important;
    }
    .hide{
    visibility: hidden !important;
    }
    .el-select-dropdown__wrap{
        max-height: 800px !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style>
<style scoped>
hr {
    background-color: #2874A6;
}
    @media print {
  
        html, body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }
        body * {
    visibility: hidden;
  }
  #RCDetailsModalId, #RCDetailsModalId * {
    visibility: visible;
  }
  #RCDetailsModalId {
    position: absolute;
    left: 0;
    top: 0;
  }
  button {
    display: none;
  }

  #RCDetailsModalId:last-child {
     page-break-after: auto;
    }
    #RCDetailsModalId:last-child {
     page-break-before: none;
    }
}

/* #otDocsF * :not(input) {
    border: none;
} */

.chkAgree .el-checkbox__inner {
        align-self: start;
    }
.chkAgree > .el-checkbox__label {
    display:inline-flex;
    white-space: normal !important;
}
.el-switch__label * {
        font-size: 18px;
        font-weight: bold;
    }
.el-switch__label.is-active {
    color: rgb(252, 51, 23);
}
    #RCDetailsModalId .modal-dialog {
        min-width:80%;
    }
    .el-select-dropdown{
        z-index: 1 !important;
       
    }
  

    .el-popper {
        z-index: 1 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 1;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 1;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 1;
    }

    .swal2-icon.swal2-error {
        border-color: #74f293;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    } 
</style>