<template>

	<!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar">
            <!-- Sidebar navigation-->
            <nav class="sidebar-nav">
                <ul id="sidebarnav">
                    <!-- <li class="nav-small-cap">PERSONAL</li> -->
                    <li v-if="currentUser.hasOwnProperty('SAD')">
                        <a style="cursor: auto" class="has-arrow " href="#" aria-expanded="false"><i class="fa fa-gears"></i><span class="hide-menu">System Management</span></a>
                        <ul aria-expanded="false" class="collapse">
                            <li><router-link to="/app/system_management"> System Info</router-link></li>
                            <li><router-link to="/app/system_management/frequently_asked_questions"> Frequently Asked Questions</router-link></li>
                            <li><router-link to="/app/system_management/system_errors_log"> Errors Log</router-link></li>
                            <li><router-link to="/app/system_management/broadcast_center"> Broadcast Center</router-link></li>
                            <li><router-link to="/app/system_management/charts_creation"> Charts Creation</router-link></li>
                            <li><router-link to="/app/system_management/available_charts"> Available Charts</router-link></li>
                        </ul>
                    </li>


                    <!-- <li v-if="currentUser.CompanyId == null">
                        <a style="cursor: auto" class="has-arrow" href="/app/account_settings" aria-expanded="false"><i class="fa fa-gears"></i><span>Account Settings </span></a>
                        <ul aria-expanded="false" class="collapse">
                            <li><router-link to="/app/account_settings">Account Settings</router-link></li>
                        </ul>
                    </li> -->

                    <li v-if="currentUser.CompanyId != null">
                        <a style="cursor: auto" class="has-arrow" href="#" aria-expanded="false"><i class="fa fa-bar-chart-o"></i><span class="hide-menu">Dashboard </span></a>
                        <ul aria-expanded="false" class="collapse">
                            <li><router-link to="/app/dashboard">Personal Dashboard</router-link></li>
                        </ul>
                    </li>

                    <li v-if="currentUser.CompanyId != null">
                        <a style="cursor: auto" class="has-arrow sysModule" href="#" aria-expanded="false"><i class="fa fa-navicon"></i><span class="hide-menu">Modules </span></a>
                        <ul aria-expanded="false" class="collapse menuItems">
                            <li><router-link :class="[currentUser.hasOwnProperty('LMA') || currentUser.hasOwnProperty('ALMV') || currentUser.hasOwnProperty('ALMR') ? '' : 'fadedMenu']" to="/app/items_master"><img height="20px" style="margin-right: 10px" src="/images/LOM-icon.png" alt="LOM-icon.png" >Library Of Materials</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('PRO') || currentUser.hasOwnProperty('PRV') ? '' : 'fadedMenu']" to="/app/purchase_enquiry"><img height="20px" style="margin-right: 10px" src="/images/PE-icon.png" alt="PE-icon.png" >Purchase Enquiries</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('RFQM') ? '' : 'fadedMenu']" to="/app/quotations"><img height="20px" style="margin-right: 10px" src="/images/rfp-icon.png" alt="RFP-icon.png" >Request For Proposals</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('POO') || currentUser.hasOwnProperty('POV') ? '' : 'fadedMenu']" to="/app/purchase_orders"><img height="20px" style="margin-right: 10px" src="/images/PO-icon.png" alt="PO-icon.png" >Purchase Orders</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('RNO') || currentUser.hasOwnProperty('RNV') ? '' : 'fadedMenu']" to="/app/receipt_notes"><img height="20px" style="margin-right: 10px" src="/images/RN-icon.png" alt="RN-icon.png" >Receipt Notes</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('SLM') || currentUser.hasOwnProperty('SLMV') || currentUser.hasOwnProperty('INV') ? '' : 'fadedMenu']" to="/app/vendors_management"><img height="20px" style="margin-right: 10px" src="/images/VM-icon.png" alt="VM-icon.png" >Vendors Management</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('RCCO') || currentUser.hasOwnProperty('RCCV') || currentUser.hasOwnProperty('RCO') || currentUser.hasOwnProperty('RCV') ? '' : 'fadedMenu']" to="/app/rate_contracts"><img height="20px" style="margin-right: 10px" src="/images/RC-icon.png" alt="RC-icon.png" >Rates’ Contracts</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('ASTMGR') || currentUser.hasOwnProperty('SIIRPT') ? '' : 'fadedMenu']" to="/app/stores_management"><img height="20px" style="margin-right: 10px" src="/images/SRM-icon.png" alt="SRM-icon.png" >Storeroom Management</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('RTIRS') || currentUser.hasOwnProperty('ATSIRS') || currentUser.hasOwnProperty('RTSI') || currentUser.hasOwnProperty('ATSI') ? '' : 'fadedMenu']" to="/app/store_requests"><img height="20px" style="margin-right: 10px" src="/images/SRR-icon.png" alt="SRR-icon.png" >Storeroom Requests</router-link></li>
                            <li><router-link :class="[currentUser.hasOwnProperty('SUBO') || currentUser.hasOwnProperty('SUBV') ? '' : 'fadedMenu']" to="/app/set_up_budget"><img height="20px" style="margin-right: 10px" src="/images/SUB.png" alt="SUB.png" >Setup Budget</router-link></li>

                            <!-- <li v-if="currentUser.hasOwnProperty('CAD') && currentUser.CompanyId != null"><router-link to="/app/company_settings"><i class="ti-settings"></i> Organization Configuration</router-link></li> -->
                            <li><router-link :class="[currentUser.hasOwnProperty('CAD') ? '' : 'fadedMenu']" to="/app/manage_settings"><img height="20px" style="margin-right: 10px" src="/images/setting-icon.png" alt="setting-icon.png" >Settings</router-link></li><!-- <li><router-link :class="[currentUser.hasOwnProperty('SUBO') || currentUser.hasOwnProperty('SUBV') ? '' : 'fadedMenu']" to="/app/set_up_budget">Concern Budge</router-link></li> -->
                        </ul>
                    </li>


                    <li v-if="currentUser.CompanyId != null" class="float-right">
                        <a style="cursor: auto" class="has-arrow" href="#" aria-expanded="false"><i class="fa fa-book"></i><span class="hide-menu"> FAQs </span></a>
                        <ul aria-expanded="false" class="collapse">
                            <li><router-link to="/app/process_faqs">Process FAQs </router-link></li>
                            <li><router-link to="/app/dashboard_faqs">Dashboard FAQs </router-link></li>
                            <li><router-link to="/app/system_access_faqs">System Access FAQs </router-link></li>
                            <li><router-link to="/app/purchase_enquiry_faqs">Purchase Enquiry FAQs </router-link></li>
                            <li><router-link to="/app/item_master_faqs">Items Master FAQs </router-link></li>
                            <li><router-link to="/app/quotations_faqs">Quotations FAQs </router-link></li>
                            <li><router-link to="/app/purchase_orders_faqs">Purchase Orders FAQs </router-link></li>
                            <li><router-link to="/app/receipt_notes_faqs">Receipt Notes FAQs </router-link></li>
                            <li><router-link to="/app/suppliers_faqs">Suppliers FAQs </router-link></li>
                            <li><router-link to="/app/rate_contract_faqs">Rate Contracts FAQs </router-link></li>
                        </ul>
                    </li>

                </ul>
            </nav>
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->

</template>


<script>

	export default{
        name: 'Menu',
        data(){
            return{

            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        mounted(){

        }

	}
</script>


<style scoped>

    .sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
        background-color: #2874a6 !important;
    }
    .sidebar-nav > ul > li:hover{
        color: #2874a6 !important;
    }
    .menuItems li:hover {
        transform: scale(1.02);
    }
    .menuItems li a:hover {
        transform: scale(1.02);
        color: #2874a6 !important;
    }
    .menuItems li:hover img{

        filter: invert(40%) sepia(7%) saturate(4356%) hue-rotate(161deg) brightness(97%) contrast(93%);
    }
    .router-link-active{
        font-weight: 900;
        text-decoration: underline;
        color: #2874a6 !important;
    }

    .fadedMenu{
        font-weight: 400;
        color: #DBDBDB;
        cursor: not-allowed;
    }

</style>
