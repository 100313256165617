<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4><i>Library of Materials</i> Details</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="ItemsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Item ID#</th>
                                <th><i>Account</i> Name</th>
                                <th>Requester Name</th>
                                <th><i>Category</i> Name</th>
                                <th>Description</th>
                                <th>Unit of Measure</th>
                                <th>Created On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="ItemDetailsModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ItemDetailsModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                    <div  class="modal-header" v-if="viewDataLoaded">
                        <div style="width:50%">
                            <h4 class="modal-title text-ogeo">Item ID#: <i>LoM-{{ ViewModalInfo.item_group_id }}-{{ ViewModalInfo.item_ingroup_id }}</i></h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanyDetails.logo" style="width:60px;height:60px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">General Information</h3>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded" style="margin-top: 0;">
                            <div class="col-lg-16">
                                <table class="table sptable" style="float: right; border-collapse: collapse;">
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 450px;"><b class="fw-500"></b></td>                                      
                                        <td style="border: 1px solid #ffffff; min-width: 450px; text-align: right; padding-right: 20px;"><b class="fw-500">This item was added to the <i>Library of Materials</i> under this <i>Account</i>: </b></td>    
                                        <td style="border: 1px solid #ffffff; width: 95%; colspan=2">
                                                <span><b class="font-700"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                        </td>     
                                    </tr>
                                </table>
                                <hr style="clear: both; border: 0; border-top: 1px solid #001; margin: 5px 0;">
                                <table class="table sptable">
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 350px;"><b class="fw-500">Requested By: </b></td>
                                        <td style="border: 1px solid #ffffff; width: 75%">{{ ViewModalInfo.requester }}</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 350px;"><b class="fw-500">Created By: </b></td>
                                        <td style="border: 1px solid #ffffff; width: 75%">{{ ViewModalInfo.creator.name }}</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 350px;"><b class="fw-500">Created On: </b></td>
                                        <td style="border: 1px solid #ffffff; width: 75%"><i>{{ ViewModalInfo.created_at }}</i></td>
                                    </tr>
                                  
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 350px;"><b class="fw-500"><i>Category</i> Name: </b></td>
                                        <!--<td style="border: 1px solid #ffffff; min-width: 350px;" class="fw-500"><i>Category</i> Name: </td>
                                        <td class="">{{ViewModalInfo.item_template.template_name}}</td>-->
                                        <td style="text-transform: uppercase; color: rgb(252, 51, 23); font-weight: bold;">{{ ViewModalInfo.item_template.template_name }}</td>

                                    </tr>
                                    
                                </table>
                            </div>
                            <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Item Description</h3>
                            <div class="col-lg-16">
                                <table class="table">
                                    <tr>
                                                                            
                                        <!-- <td class="align-middle text-nowrap"></td> -->
                                        <td style="border: 1px solid #ffffff; width= 30%; text-align: right; padding-right: 20px;"><b class="font-700 text-ogeo w-100">Full Description: </b></td>
                                        <td style="border: 1px solid #ffffff; width= 70%" class="align-middle">
                                            <template v-for="index in 20" style="text-transform: uppercase">    
                                                <span v-if="ViewModalInfo.item_template['field_'+(index)] !== 'Ogeo-None' && ViewModalInfo['field_'+(index)]">
                                                    <b class="font-700 text-uppercase">{{ ViewModalInfo.item_template['field_'+(index)] }}:</b> {{ ViewModalInfo['field_'+(index)] }} <b style="color: #111111;"> | </b> 
                                                </span>
                                            </template>
                                            <br><br>
                                            <span><b class="font-700 text-uppercase">Unit of Measure: </b> {{ ViewModalInfo.u_o_m.toUpperCase() }}</span><br><br>
                                            
                                            <img class="d-inline-block mr-3" v-if="!Array.isArray(ViewModalInfo.picture)"  style="width: 10vw; height: 10vw; text-align:center;" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.picture"">
                                            <img class="d-inline-block mr-3" v-else v-for="pic in ViewModalInfo.picture" style="width: 10vw; height: 10vw; text-align:center;" :src="'/uploads/ItemMasterPictures/'+pic">
                                        </td>
                                    </tr>
                                </table>

                            </div>
                            
                            <div class="col-lg-12">
                                
                                <table class="table">
                                    <tr v-if="ViewModalInfo.item_template.field_1 !== 'Ogeo-None' && ViewModalInfo.field_1">
                                        <td><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_1 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_1 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_2 !== 'Ogeo-None' && ViewModalInfo.field_2">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_2 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_2 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_3 !== 'Ogeo-None' && ViewModalInfo.field_3">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_3 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_3 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_4 !== 'Ogeo-None' && ViewModalInfo.field_4">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_4 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_4 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_5 !== 'Ogeo-None' && ViewModalInfo.field_5">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_5 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_5 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_6 !== 'Ogeo-None' && ViewModalInfo.field_6">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_6 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_6 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_7 !== 'Ogeo-None' && ViewModalInfo.field_7">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_7 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_7 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_8 !== 'Ogeo-None' && ViewModalInfo.field_8">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_8 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_8 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_9 !== 'Ogeo-None' && ViewModalInfo.field_9">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_9 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_9 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_10 !== 'Ogeo-None' && ViewModalInfo.field_10">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_10 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_10 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_11 !== 'Ogeo-None' && ViewModalInfo.field_11">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_11 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_11 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_12 !== 'Ogeo-None' && ViewModalInfo.field_12">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_12 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_12 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_13 !== 'Ogeo-None' && ViewModalInfo.field_13">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_13 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_13 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_14 !== 'Ogeo-None' && ViewModalInfo.field_14">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_14 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_14 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_15 !== 'Ogeo-None' && ViewModalInfo.field_15">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_15 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_15 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_16 !== 'Ogeo-None' && ViewModalInfo.field_16">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_16 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_16 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_17 !== 'Ogeo-None' && ViewModalInfo.field_17">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_17 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_17 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_18 !== 'Ogeo-None' && ViewModalInfo.field_18">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_18 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_18 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_19 !== 'Ogeo-None' && ViewModalInfo.field_19">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_19 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_19 }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.item_template.field_20 !== 'Ogeo-None' && ViewModalInfo.field_20">
                                        <td style="border: 1px solid #ffffff; width: 15%;"><b class="font-700 text-uppercase">{{ ViewModalInfo.item_template.field_20 }}: </b></td>
                                        <td>{{ ViewModalInfo.field_20 }}</td> 
                                    </tr>
                                </table>
                            </div>


                            <!-- <div class="col-lg-3"></div>
                            <div class="col-lg-6">
                                <img style="width: 100%" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.picture">
                            </div>
                            <div class="col-lg-3"></div> -->
                            <div class="col-lg-12">
                            
                            <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Status Records</h3>
                            <!--<h4 style="color: #FF2F2F">Status Records</h4> --> 
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid #dddddd;"><b><i>Account</i> Name</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Item Status</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, key) in CompanyItemStatus" :key="item.id">
                                            <td style="border: 1px solid #dddddd;">{{ item.project.title }}</td>
                                            <td style="border: 1px solid #dddddd;" v-if="item.active == 'Yes'"><span class="text-ogeo font-700" style="color: green;">Active - </span>Since: <i>{{item.updated_at}}</i></td>
                                            <td style="border: 1px solid #dddddd;" v-if="item.active == 'No'"><span class="text-ogeo font-700" style="color: red;">Inactive - </span>Since: <i>{{ item.updated_at }}</i></td>  
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                            <div class="col-lg-12">
                                
                                <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>
                                <!--<h3 style="color: #FF2F2F">History</h3>-->  
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="border: 1px solid #dddddd;"><b>#</b></th>
                                            <th style="border: 1px solid #dddddd;"><b>Action <br>Description</b></th>
                                            <th style="border: 1px solid #dddddd;"><b>Action <br>By</b></th>
                                            <th style="border: 1px solid #dddddd;"><b>Action Level</b></th>
                                            <th style="border: 1px solid #dddddd;" ><b>Decision - <i>Notes</i></b></th>
                                            <th style="border: 1px solid #dddddd;"><b>Action <br>Date</b></th>
                                            <th style="border: 1px solid #dddddd;"><b>Timespan Between Actions</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action <br>Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next <br>Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action Validation Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, key) in ViewModalInfo.item_creation_request.history" :key="item.id">
                                            <td style="border: 1px solid #dddddd;">{{ key + 1 }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_action_description }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_action_by.name }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_approval_level }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                            <td style="border: 1px solid #dddddd;">{{ item.created_at }}</td>
                                            <td style="border: 1px solid #dddddd; font-size: 13px" v-if="key == 0"><i>Not Applicable</i></td>
                                            <td style="border: 1px solid #dddddd;" v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_approval_level }}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>   
                    </div>
                    <div class="col-lg-12">
                        <hr>
                            <div class="d-inline-block pull-left">            
                                <span><b>Document generated by:</b> {{ currentUser.Name }}</span>
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                    </div>
                    <br><br><br>
                     <div class="col-lg-24; text-center">
                            <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                            <br>
                            <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                <div class="d-flex justify-content-center mt-3">
                                    <button class="btn btn-success" onclick="document.title = 'LoM Item';window.print()">Download PDF</button>
                                </div>
                    </div>
                        <br>
        </div>
    </div>
            </div>
        </div>

    </div>
            
</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'items-master-list',
        data(){
            return{
                DataTable: "",
                viewDataLoaded: false,
                ViewModalInfo: {},
                CompanyDetails: {},
            }
        },
        methods: {
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_item_master_record_details', [elquentClass, recordId])
                    .then((response) => {
                        if(response.data.record)
                            this.ViewModalInfo = response.data.record;
                        else
                            this.ViewModalInfo = response.data;
                        this.CompanyItemStatus = ''
                        if(response.data.companyItemStatus)
                            this.CompanyItemStatus = response.data.companyItemStatus;
                        else
                            this.CompanyItemStatus = response.data.company_item_status;
                        this.viewDataLoaded = true
                        $('#ItemDetailsModal').modal('toggle');
                    })

                this.viewDataLoaded = false;
                
            },
            ActivateDeactivate(itemId, CurrentStatus){

                let self = this;
                    
                if(CurrentStatus == 'Yes'){
                    Swal({
                    title: 'Are You Sure You Want to Deactivate This Item?',
                    text: "",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Deactivate It!'
                    }).then((result) => {
                        if (result.value) {
                            axios.post('/api/users/item_activations', {ItemId : itemId})
                            .then((response) => {
                                
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });

                                self.DataTable.ajax.reload( null, false );

                            })
                        }
                    })
                } else {
                    Swal({
                    title: 'Are You Sure You Want to Activate This Item?',
                    text: "",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Activate It!'
                    }).then((result) => {
                        if (result.value) {
                            axios.post('/api/users/item_activations', {ItemId : itemId})
                            .then((response) => {
                                
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });

                                self.DataTable.ajax.reload( null, false );

                            })
                        }
                    })
                }
                


                
                
            },
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        mounted(){

            axios.get('/api/data/get_company_details')
            .then((SecondResponse) => {
                this.CompanyDetails = SecondResponse.data; 
            });

            setTimeout(() => {

                const table = $('#ItemsList').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },

                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_item_master_list',
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'requester', name: 'requester' },
                        { data: 'item_template.template_name', name: 'item_template.template_name', searchable: false },
                        { data: 'complete_description_with_headers', name: 'complete_description_with_headers' },
                        { data: 'u_o_m', render: function (data) {
                            return data.toUpperCase();
                        } },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: 3 },
                        { responsivePriority: 2, targets: 1 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },    
                });

                const self = this

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.activations-placeholder', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    var CurrentStatus = $(this).data('currentstatus');
                    self.ActivateDeactivate(RecordID, CurrentStatus);
                });

                self.DataTable = table;

            }, 500);
            
        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>

<style scoped>
    
    .font-500{
        font-weight: 500;
    }

    #ItemDetailsModal .modal-dialog {
        min-width:80%;
    }

</style>
<style>
    @media print {
      body * {
          visibility: hidden;
      }
      #ItemDetailsModal, #ItemDetailsModal * {
        visibility: visible;
      }
      #ItemDetailsModal {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
</style>