<template>
        
    <div class="row">
        <div id='Purchase_Enqiury_Form' class="col-lg-7">
            <div class="card" v-if="ProjectDetailsLoaded">
                <div class="card-header">
                    <h4 v-if="this.PurchaseEnquiryLine.EnquiryType == 'Materials'"><b>Initiate A <i>Materials Purchase Enquiry</i> Line</b></h4>
                    <h4 v-else-if="this.PurchaseEnquiryLine.EnquiryType == 'Service'"><b>Initiate A <i>Services Purchase Enquiry</i> Line</b></h4>
                    <h4 v-else><b>Initiate A <i>Purchase Enquiry</i></b></h4>
                </div>
                <div class="card-body">
                    <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryForm">

                        <el-row :gutter="20">
                           <el-col :span="24">   
                                <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.025);text-align: justify;">
                                    <p style="color: #111111;font-size: 16px;">
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>  
                                        The initiation of a <i><b>Purchase Enquiry</b></i>, which may include multiple <i>Lines</i>, each corresponding to a different <i>Material</i> or <i>Service</i>, begins 
                                        the process of acquiring the required <i>Materials</i> or <i>Services</i> for the <i>Account</i>. 
                                    </p>
              
                                    <p style="color: #111111;font-size: 16px;">Additionally, a <i>Purchase Enquiry</i> Line may be linked or not to a <i><b>Rates' Contract</b></i>. </p>
                                    <p style="color: #111111;font-size: 16px;">
                                        A <i>Purchase Enquiry</i> Line that's isn't linked to a <i>Rates' Contract</i>, once fully approved and assigned to users holding the <u><i>Request for Proposal Administrator</i></u> role—who are responsible for sourcing proposals from <i>Vendors</i>—the <i>Purchase Enquiry</i>
                                        will be converted into a <i>Purchase Order</i>. This <i>Purchase Order</i> will then be reviewed by users in the roles of <u><i>Purchase Order Initiator</i></u> and 
                                        <u><i>Purchase Order Validator</i></u> for this <i>Account</i>. If it passes validation, 
                                        the <i>Purchase order</i> will be formally assigned to the Awardee Vendor.
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII="/>
                                    </p>
                                        <hr>
                                    <p style="color: rgb(252, 51, 23);font-size: 16px;">
                                        <i class="fa fa-exclamation-triangle"></i>  
                                        When linked to a <i>Rates' Contract</i>, a <i>Purchase Enquiry</i> Line can be converted into a <i>Purchase Order</i> 
                                        without involving the users in the <u><i>Request for Proposal Administrator</i></u> role, significantly shortening turnaround times.
                                        Thus, the importance of establishing <i>Rates' Contracts</i>.
                                    </p>                                                      
                                </div>
                            </el-col>           

                            <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="JobNumber">
                                            <span slot="label"><b>Select an <i>Account</i></b></span>

                                            <el-select id="SetupSelection" @change="getProjectCurrency($event)" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select an 'Account' for which you hold the Purchase Enquiry Initiator role">
                                                
                                                <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </transition>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                        <span slot="label"><b>Select the <i>Purchase Enquiry</i> Type</b></span>
                                        <el-select @change="EnquiryTypeSelection" :disabled="PElineUp == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Choose 'Materials Purchase Enquiry' or 'Services Purchase Enquiry'">    
                                            <el-option label="Materials Purchase Enquiry" value="Materials"></el-option>
                                            <el-option label="Services Purchase Enquiry" value="Service"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.PurchaseEnquiryLine.EnquiryType">    
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="SourcingPriority">
                                        <span slot="label"><b>Select a sourcing priority</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 498px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The sourcing priority is, by default, set to <i>Standard</i> but can be changed to <i>Urgent</i> or <i>Very Urgent</i>, as needed.</p>
                                                    <p>This setting determines the urgency with which proposals from vendors should be sourced during the tendering process.</p>
                                                </span>
                                            </span>
                                        </span>
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType">
                                <div class="grid-content">
                                    <el-form-item label="temp" :prop="CompanySetup.additional_required_1 == 'Yes' ? 'UnderlyingTransaction' : ''">
                                        <span slot="label"><b>Enter an underlying transaction reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 388px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The underlying transaction reference is the identifier for the transaction which triggered the need to initiate this specific <i>Purchase Enquiry</i>.</p>
                                                    <p>Examples of such references include <u>Sales Orders</u>, <u>Work Orders</u>, <u>Job Tickets</u>, <u>Lead References</u>...</p>
                                                </span>
                                            </span>
                                        </span>

                                        <el-input v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <!-- Delivery Location Selection -->
                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select a country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 240px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2"><p>Select the country where delivery is needed.</p></span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select a country"> -->
                                        <el-select filterable collapse-tags id="country" class="country" @change="PurchaseEnquiryLine.Cities = [], getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select a country from the list">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select a city</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 243px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select a city"> -->
                                        <el-select filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select a city from the list" @change="checkCityRC($event)">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select countries</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">Select the countries where delivery is needed (selecting multiple countries is allowed).</span>
                                            </span>
                                        </span>

                                        <el-select filterable multiple collapse-tags id="country" class="country" @change="PurchaseEnquiryLine.Cities = [], RCServiceCityCheck(), getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select countries from the list">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select cities</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: -20px; left: 175px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">Select the cities where delivery is needed (selecting multiple cities is allowed).</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" @change="RCServiceCityCheck" placeholder="Select cities from the list">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)" >
                                <el-form-item label="temp" prop="LocationName">
                                    <span slot="label"><b>Enter the delivery location name</b></span>
                                    <el-input type="text" placeholder="Enter the delivery location name. Add the name of a landmark if possible." v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)" >
                                <el-form-item label="temp">  
                                    <span slot="label"><b>Mark the delivery location address on the map</b></span>
                                    <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                        <span style="bottom: -15px; left: 175px; width: 300px;" class="tooltip-content4 clearfix">
                                            <span class="tooltip-text2">
                                                You may use Google Map and drop a marker in the delivery location.                                            
                                            </span>
                                        </span>
                                    </span>
                                    <button 
                                        v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" 
                                        type="button" 
                                        data-toggle="modal" 
                                        id="get_map" 
                                        data-target="#DeliveryLocation" 
                                        class="btn btn-primary btn-block waves-effect text-center" 
                                        style="background-color: rgb(64, 64, 64); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" 
                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                        Identify the delivery location with a marker
                                    </button>
                                    <button 
                                        v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" 
                                        type="button" 
                                        data-toggle="modal" 
                                        id="get_map" 
                                        data-target="#DeliveryLocation" 
                                        class="btn btn-primary btn-block waves-effect text-center" 
                                        style="background-color: rgb(64, 64, 64); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" 
                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                        Identify the delivery locations with markers
                                    </button>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)">
                                <br style="margin-top: 10px; margin-bottom: -2px;">
                                <div style="display: flex; align-items: center; justify-content: center; position: relative; text-align: center; color: rgb(64, 64, 64); font-size: 16px; margin: 0;">
                                    <hr style="border: 1px solid rgb(64, 64, 64); width: 100%; margin: 0;">
                                    <div style="position: absolute; background: #fff; padding: 0 10px; color: rgb(64, 64, 64);">
                                        <b>OR</b>
                                    </div>
                                </div>
                                <br style="margin-top: 10px; margin-bottom: -2px;">
                            </el-col>

                           <el-col :span="12" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)" >
                                <el-form-item label="temp" prop="Longitude">
                                    <span slot="label"><b>Enter the delivery location longitude</b></span>
                                    <el-input type="number" readonly  placeholder="Enter the longitude or use the map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)" >
                                <el-form-item label="temp" prop="Latitude">
                                    <span slot="label"><b>Enter the delivery location latitude</b></span>
                                    <el-input type="number"  readonly placeholder="Enter the latitude or use the map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                </el-form-item>
                            </el-col>


                            

                            <!--<el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length"><hr></el-col>-->
                                <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length">
                                    <div style="height: 25px;"></div>
                                    <div style="display: flex; align-items: center; justify-content: center; position: relative; text-align: center; color: black; font-size: 16px; margin: 0;">
                                        <hr style="border: 1px solid rgb(0, 114, 187); width: 100%; margin: 0;">
                                        <div style="position: absolute; background: #fff; padding: 0 10px; color: rgb(0, 114, 187); font-weight: 900; z-index: 2;">
                                            <b><u>Link To <i>Services Rates' Contracts</i> ?</u></b>
                                        </div>
                                        <span style="position: absolute; top: calc(50% - 30px); left: calc(50% + 130px); transform: translateY(0); z-index: 3;" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="bottom: 20px; left: -308px; width: 715px;">
                                                <span class="tooltip-text2">
                                                    <p><i>Services Rates’ Contracts</i> are pre-negotiated agreements with vendors that establish fixed <u>Unit Rates</u> and <u>Lead Times</u> for <i>Services</i> 
                                                    ,before the demand arises.</p><p> This approach helps to reduce costs and turnaround times:<i>Purchase Enquiries</i>, 
                                                    linked to <i>Services Rates’ Contracts</i>, can be directly turned into <i>Purchase Orders</i>.</p>
                                                    <p>A <i>Services Purchase Enquiry</i>, linked to a <i>Services Rates’ Contracts</i>, will have the latter's <i>Advance Payment</i> and 
                                                    <i>Retention</i>, Unit Rate and Lead Time Arrangements apply to it.</p>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </el-col>


                                <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length" class="text-center mt-2 mb-2 pt-2 pb-2">
                                    <el-col :span="24" v-if="RCServiceAvailableCheck">
                                        <div style="background-color: rgba(0, 114, 187,0.15); padding: 10px; border-radius: 5px; margin: 0;">
                                            <h5 style="font-weight: 300; margin: 0; color: rgb(0, 114, 187);" v-if="RCServiceAvailableCheckCount > 1">
                                                <i class="fa fa-exclamation-triangle"></i>
                                                There Are <b><u>{{ RCServiceAvailableCheckCount }}</u></b> Valid <i>Services Rates’ Contracts</i>, Available In At Least One Of The Selected Cities.
                                            </h5>
                                            <h5 style="font-weight: 300; margin: 0; color: rgb(0, 114, 187);" v-else>
                                                <i class="fa fa-exclamation-triangle"></i>
                                                There Is <b><u>1</u></b>Valid <i>Services Rates’ Contract</i>, Available In At Least One Of The Selected Cities.
                                            </h5>
                                        </div>
                                        <div style="height: 7px;"></div>
                                    </el-col>
                                    <el-col :span="24" v-if="PurchaseEnquiryLine.Cities && PurchaseEnquiryLine.JobNumber && !RCServiceAvailableCheck">
                                        <div style="background-color: rgba(0, 114, 187,0.15); padding: 10px; border-radius: 5px; margin: 0;">
                                            <h5 style="font-weight: 300; margin: 0; color: rgb(0, 114, 187);">
                                                <i class="fa fa-exclamation-triangle"></i>
                                                There Are <b><u>No</u></b> Valid <i>Services Rates’ Contracts</i>, Available In Any Of The Selected Cities.
                                            </h5>
                                        </div>
                                    </el-col>
                                    <h4 style="font-weight: 700;" v-if="PurchaseEnquiryLine.Cities == ''">No Cities | Countries Selected!</h4>
                                    <h4 style="font-weight: 700;" v-if="PurchaseEnquiryLine.Cities && !PurchaseEnquiryLine.JobNumber">Please Select An <i>Account</i> In Order To Proceed With The <i>Services Rates’ Contracts</i> Selection!</h4>
                                    <button v-if="RCServiceAvailableCheck && PurchaseEnquiryLine.JobNumber" type="button" :disabled="!PurchaseEnquiryLine.JobNumber" data-toggle="modal" id="get_rc_service" data-target="#RCSelectionService" class="btn btn-block waves-effect text-center" @click="IgnoreRCSelectionService = false" style="background-color: rgb(0, 114, 187); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">View Valid <i>Services Rates’ Contracts</i></button>
                                </el-col>

                                <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                    <div style="height: 25px;"></div>
                                    <!-- Line and Text Overlay -->
                                    <div style="position: relative; width: 100%; margin: 0; padding: 0;">
                                        <!-- Horizontal Line -->
                                        <hr style="border: 1px solid rgba(252, 51, 23, 0.90); width: 100%; margin: 0;">
                                        <!-- Text Overlay -->
                                        <div style="position: absolute; top: -10px; left: 50%; transform: translateX(-50%); background: #fff; padding: 0 10px; font-weight: 900; color: red; font-size: 16px;">
                                            <b><u>No Valid <i>Services Rates' Contracts</i></u></b>
                                        </div>
                                    </div>
                                    <!-- Delivery Location Selection Box -->
                                    <div style="background-color: rgba(252, 51, 23, 0.05); padding: 10px; border-radius: 5px; margin-top: 18px;"> 
                                        <table style="width: 100%; border-collapse: collapse;">
                                            <tbody>
                                                <tr>
                                                    <!-- First Column with the informational text (35% width) -->
                                                    <td style="width: 35%; font-weight: 700; padding: 5px; text-align: left; border-right: 2px solid black;">
                                                        <h5 style="margin: 0;">
                                                            The Below Information Will Only Apply To The Selected Cities Which Are <u><b>Without Valid <i>Services Rates’ Contracts</i></b></u>. These Cities Are:
                                                        </h5>
                                                    </td>
                                                    <!-- Second Column (65% width) -->
                                                    <td style="width: 65%; padding: 5px; text-align: left;">
                                                        <p>
                                                            <!-- Join city names with a comma separator -->
                                                            <span v-for="(city, index) in (IgnoreRCSelectionService ? PurchaseEnquiryLine.Cities : NoRCServiceAvailableCheckCities)" :key="index" style="color: black;">{{ city }}
                                                                <span v-if="index < (IgnoreRCSelectionService ? PurchaseEnquiryLine.Cities : NoRCServiceAvailableCheckCities).length - 1" style="color: black;">, </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </el-col>


                                <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ServiceDescription">
                                            <span slot="label" style="display: inline-block; margin-top: 10px;"><b>Enter a <i>Services Purchase Enquiry</i> short description</b></span>
                                            <el-input
                                                type="textarea"
                                                :rows="1"
                                                placeholder="Enter a short description of the required 'Services'"
                                                v-model="PurchaseEnquiryLine.ServiceDescription">
                                            </el-input>
                                        </el-form-item>
                                        <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->               
                                    </div>
                                </el-col>
                                <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ServiceDescription" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length && this.CompanySetup.lom_manditory == 'LibrariesNFreeText'">
                                    <div>
                                        <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                            <span slot="label"><b>Do you want to select an item from the <i>Library of Materials</i> ?</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                    <span class="tooltip-item2">
                                                        <span class="fa-stack fa-1x">
                                                            <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                        </span>
                                                    </span>
                                                    <span style="bottom: 20px; left: -235px; width: 670px;" class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <p>The <i>Library of Materials</i> serves as a catalog that consolidates the items which the organization needs to procure.</p>
                                                            <p>Using items extracted from the <i>Library of Materials</i> rather than ones that are free-text 
                                                            described helps to significantly shorten turnaround times and generates valuable data insights.</p> 
                                                            <p>Therefore, it is recommended that the item is first added to the <i>Library of Materials</i> before being selected for a 
                                                            <i>Materials Purchase Enquiry</i>.</p>                                  
                                                        </span>
                                                    </span>
                                                </span>                        
                                                <el-select @change="EnquiryItemDescriptionSelection" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryFromItemMaster" placeholder="Choose 'Yes' or 'No'">         
                                                    <el-option label="Yes" value="Yes"></el-option>
                                                    <el-option label="No" value="No"></el-option>      
                                                </el-select>
                                        </el-form-item>
                                    </div>
                                    <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryFromItemMaster" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <div v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'">
                                            <el-form-item label="temp" prop="ItemNumber">
                                                <span slot="label"><b>Select an item from the <i>Library of Materials</i></b></span>
                                                <el-select style="width: 100%"
                                                    popper-class="itemSelect"
                                                    v-model="PurchaseEnquiryLine.ItemNumber"
                                                    @change="checkRateContract()"
                                                    filterable
                                                    remote
                                                    :remote-method="searchItems"
                                                    :loading="searchItemsLoading"
                                                    placement="top-start"
                                                    placeholder="Enter a keyword to search the Library of Materials and select an item">
                                                    <el-option style="max-width: 1150px; min-height: 150px"
                                                        v-for="item in FoundItems"
                                                        :key="item.id"
                                                        :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                        :value="item.id">
                                                        <div class="row" style="border: 2px solid black">
                                                            <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                                <img v-if="!isJSON(item.picture)" class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                                    <img v-else class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+JSON.parse(item.picture)[0]">                           
                                                            </div>
                                                            <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                                <table class="table itemTable">
                                                                    <tr>
                                                                        <td>
                                                                            <span><b class="font-700">LoM: {{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                            <span style=" white-space: normal; line-height: 1.5em;">
                                                                                <template v-for="index in 20">      
                                                                                    <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                        <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}: </b> {{ item['field_'+index] }} | 
                                                                                    </span>
                                                                                </template>
                                                                            </span><br><br>
                                                                            <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="col-sm-3 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; margin-right: 5%; word-wrap: break-word; border-left: 2px solid black">
                                                                <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                                <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                                    <tr>
                                                                        <table>
                                                                            <tr>
                                                                                <th><b class="font-700">Vendor Name</b></th>
                                                                                <th><b class="font-700">Vendor Score</b></th>
                                                                                <th><b class="font-700">Expiry Date</b></th>
                                                                                <th><b class="font-700">Unit Rate</b></th>
                                                                                <th><b class="font-700">Lead Time</b></th>
                                                                            </tr>
                                                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                                <td>{{ item.vendor_name }}</td>
                                                                                <td>{{ item.vendor_score }}</td>
                                                                                <td>{{ item.date }}</td>
                                                                                <td>{{ item.unit_rate }} USD </td>
                                                                                <td>{{ item.lead_time }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid black">
                                                                <h4 class="text-center">Last Transactions Info</h4>
                                                                <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                                    <tr>
                                                                        <table>
                                                                            <tr>
                                                                                <th><b class="font-700">Unit Rate</b></th>
                                                                                <th><b class="font-700"><i>Account</i> Name</b></th>
                                                                                <th><b class="font-700">City</b></th>
                                                                                <th><b class="font-700">Date</b></th>
                                                                                <th><b class="font-700">Quantity</b></th>
                                                                                <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                            </tr>
                                                                            <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                                <td>{{ item.unit_rate }}</td>
                                                                                <td>{{ item.concern }}</td>
                                                                                <td>{{ item.city }}</td>
                                                                                <td>{{ item.date }}</td>
                                                                                <td>{{ item.quantity }}</td>
                                                                                <td>{{ item.pobid }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                            <el-form-item label="temp" prop="ItemDescription">
                                                <span slot="label"><b>Enter the item free-text detailed description</b></span>
                                                <el-input
                                                    type="textarea"
                                                    :rows="4"
                                                    placeholder="Enter the item free-text detailed description"
                                                    v-model="PurchaseEnquiryLine.ItemDescription">
                                                </el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                            <el-row :gutter="20">
                                                <el-col :span="12">
                                                    <div class="grid-content">
                                                        <el-upload
                                                            style="width:654px;hight:270px;"
                                                            action="#"
                                                            list-type="picture-card"
                                                            :limit="4"
                                                            id='Images'
                                                            :auto-upload="false"
                                                            ref="Images"
                                                            accept=".jpg, .jpeg, .png"
                                                            :on-change="handleChange"
                                                            :on-exceed="handleExceed"
                                                            :on-remove="handleRemove">
                                                            <i class="el-icon-plus"></i>
                                                        </el-upload>
                                                        <div class="el-upload__tip" slot="tip">Up to 4 pictures with less than 1Mb are allowed.</div>                                     
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ItemDescription !== ''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <div class="grid-content" v-if="PurchaseEnquiryLine.ItemDescription !== ''">
                                                <el-form-item label="temp" prop="UnitOfMeasurement">
                                                    <span slot="label"><b>Enter the item Unit of Measure</b></span>
                                                    <span style="position: absolute; top: calc(50% - 26px); left: calc(50% - 100px); transform: translateY(0); z-index: 3;" class="mytooltip tooltip-effect-1">
                                                <span class="tooltip-item2">
                                                    <span class="fa-stack fa-1x">
                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                    </span>
                                                </span>
                                                <span class="tooltip-content4 clearfix" style="bottom: 30px; left: -76px; width: 715px;">
                                                    <span class="tooltip-text2">
                                                        <p>A Unit of Measure, for a <i>Material</i>, refers to the standardized quantity in which an item is counted, 
                                                            procured, and managed. It defines how the quantities of items are recorded and tracked, 
                                                            such as by pieces, kilograms, liters, boxes of 24x16 cc bottles or other units.
                                                        </p>
                                                        <p>A Unit of Measure is crucial for accurate ordering and pricing, as it ensures 
                                                            consistency in the measurement and handling of <i>Materials</i> across various 
                                                            processes within the supply chain.
                                                        </p>
                                                    </span>
                                                </span>
                                        </span>
                                                    <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24x16 cc Bottles..."></el-input>
                                                </el-form-item>
                                            </div>
                                            
                                        </el-col>
                                        <el-col :span="24" v-else-if="this.CompanySetup.lom_manditory == 'Libraries' && PurchaseEnquiryLine.Cities.length">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemNumber">
                                                    <span slot="label"><b>Select The Item From The <i>Library of Materials</i></b></span>
                                                    <el-select style="width: 100%"
                                                        v-model="PurchaseEnquiryLine.ItemNumber"
                                                        filterable
                                                        remote
                                                        :remote-method="searchItems"
                                                        :loading="searchItemsLoading"
                                                        placeholder="Search and Select an Item">
                                                        <el-option
                                                            v-for="item in FoundItems"
                                                            :key="item.id"
                                                            :label="'Item ID: ('+item.item_group_id+'-'+item.item_ingroup_id+'), Description: '+item.description"
                                                            :value="item.id">
                                                            <div class="row">
                                                                <div class="col-lg-2 b-r" style="padding-left: 0px; padding-top: 5px; padding-buttom: 0px; padding-right: 0px;" >
                                                                <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">     
                                                                </div>
                                                                <div class="col-lg-4 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                                    <table class="table itemTable">
                                                                        <tr> 
                                                                            <td width="50%"> <span>LoM</span> || {{ item.item_group_id  }}-{{ item.item_ingroup_id  }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="50%" v-if='item.item_template.field_1 !== null'><b>{{ item.item_template.field_1.substring(0, 20) + '...'  }}:</b></td>
                                                                            <td width="50%" v-if='item.field_1 !== null'> {{ item.field_1.substring(0, 20) + '...'  }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="50%" v-if='item.item_template.field_2'><b>{{ item.item_template.field_2.substring(0, 20) + '...'  }}:</b></td>
                                                                            <td width="50%" v-if='item.field_2'>{{ item.field_2.substring(0, 20) + '...'  }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="50%" v-if='item.item_template.field_3'><b>{{ item.item_template.field_3.substring(0, 20) + '...'  }}:</b></td>
                                                                            <td width="50%" v-if='item.field_3'>{{ item.field_3.substring(0, 20) + '...'  }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="50%"><b>Purchase Enquiry:</b></td>
                                                                            <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)"> View Rates</td>
                                                                            <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)">
                                                                                <el-tooltip placement="top-start" effect="light">
                                                                                    <div slot="content">
                                                                                        <div v-if="RateContractDataLoaded">
                                                                                            <table style="min-width: 500px" class="table table-bordered">
                                                                                                <thead>
                                                                                                    <td align="center"><b>Price</b></td>
                                                                                                    <td align="center"><b>Lead Time</b></td>
                                                                                                    <td align="center"><b>Contract End Date</b></td>
                                                                                                </thead>
                                                                                                <tbody v-if="ItemRateContractDetails.length > 0">
                                                                                                    <tr v-for="(RateContract, key, index) in ItemRateContractDetails" :key="index">
                                                                                                        <td align="center">{{ formatPrice(RateContract.vendor_responses[0].unit_rate) }} {{ RateContract.vendor_responses[0].currency }}</td>
                                                                                                        <td align="center">{{ RateContract.vendor_responses[0].lead_days }} Day(s)</td>
                                                                                                        <td align="center">{{ RateContract.vendor_responses[0].rate_contract_request.rc_end_period.substring(0, 10) }}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                                <tbody v-else>
                                                                                                    <tr>
                                                                                                        <td align="center" colspan="3"> NO CONTRACT AVAILABLE </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div align="center" v-else>
                                                                                            <h2>Gathering Data...</h2>
                                                                                            <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <i style="line-height: 0px">View Rates</i>
                                                                                </el-tooltip>
                                        
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" class="col-lg-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px;">
                                                                    <div>
                                                                        <h5><b>Item Description:</b></h5>
                                                                        <p>{{ item.description }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>        
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'FreeText' && PurchaseEnquiryLine.Cities.length" >
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemDescription">
                                                    <span slot="label"><b>Enter Material Description</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Complete Description Of The Item Required."
                                                        v-model="PurchaseEnquiryLine.ItemDescription">
                                                    </el-input>
                                                </el-form-item>
                                                <!-- <span style="margin-top: -20px;" class="float-right">50/{{PurchaseEnquiryLine.ItemDescription.length}}</span> -->
                                            </div>
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="UnitOfMeasurement">
                                                    <span slot="label"><b>Enter Unit of Measure</b></span>
                                                    <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber !=='') || (PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'No' && PurchaseEnquiryLine.UnitOfMeasurement !=='')" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber !=='') || (PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'No' && PurchaseEnquiryLine.UnitOfMeasurement !=='')" >
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="Quantity">
                                                    <span slot="label"><b>Enter the item required quantity</b></span>
                                                    <el-input min='0.0001' type="number" placeholder="Enter the item's required quantity" v-model="PurchaseEnquiryLine.Quantity">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.Quantity" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24"  v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity">
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b>Select and/or enter the documents required from the vendors</b></span> 
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span style="bottom: 18px; left: -295px; width: 715px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <p>Vendors must submit these documents, along with their commercial proposals, 
                                                                during the tendering process. </p>
                                                                <p>This allows for the assessment of their proposals and the selection of <i>Awardee Vendors</i>.</p>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <el-select collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select and/or enter the documents required from the vendors" id="reqDocs" popper-class="reqDocs" ref="reqDocs" @change="OtherDocsVisibility()"> 
                                                        <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                                        <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                                        <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                                        <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                                        <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                                        <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                                        <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                                        <el-option label="Other Documents" value="Other"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b></b></span>
                                                    <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                                        <thead class="text-white bg-dark w-100">
                                                            <tr style="width: 100% !important;">
                                                                <th><span style="color:white;"></span>Enter the names of any other documents required from the vendors:</th> 
                                                                <th class="text-center" width="70"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in OtherDocTmpHolder" :key="index">
                                                                <td>
                                                                    <input :disabled="PElineUp == 'Y'" v-model="item.name" class="form-control">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button :disabled="PElineUp == 'Y'" 
                                                                            type="button" 
                                                                            @click="delOtherDocs(index)" 
                                                                            class="btn-pointer btn-danger btn-floating" 
                                                                            style="padding: 0px 13px; background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;"
                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                            <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input v-if="PElineUp == 'N'" class="form-control" v-model="OtherDocTmp" placeholder="Enter the name of any other required documents" maxlength="200" style="font-size: 14px">
                                                                </td>
                                                                <td class="text-center my-auto" width="10%">
                                                                    <button v-if="OtherDocTmp && PElineUp == 'N'" 
                                                                            @click.prevent="addOtherDocs()" 
                                                                            class="btn-pointer btn-success btn-floating" 
                                                                            style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0; background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" 
                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                        <i class="fa fa-plus"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Quantity" >
                                            <div class="grid-content" v-if="this.showQuestionRateMaterial == true">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                                    <span slot="label"><b>This item from the <i>Library of Materials</i> has (the number of existing, valid, Purchase Enquiry) Purchase Enquiry(s) attached to it. Do you want to select one of them (if there are many) it (if there is only one) to shorten the sourcing turnaround time?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>  
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                A <i>Rates' Contract</i> is a framework agreement established with an Awardee Vendor, 
                                                                strictly for items from the <i>Library of Materials</i>, which freezes both Unit Rates' & Lead Times over a 
                                                                given period of time. The use of <i>Rates' Contract</i> helps shorten turnaround times since a validated <i>Purchase Enquiry</i> line, 
                                                                linked to a <i>Rates' Contract</i>, can readily be turned into a <i>Purcase Order</i>, without the need to source 
                                                                further proposals from vendors.                                            
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <el-select @change="ShowPopUpIgnoreRateContract" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ShowPopUpIgnoreRateContract" placeholder="Do You Want To Select An Item From The Library of Materials?">    
                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>      
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType === 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster === 'Yes' && PurchaseEnquiryLine.ItemNumber && checkCity" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber && checkCity">
                                                            <el-form-item v-if="PurchaseEnquiryLine.rtYN === 'no'" label="temp">
                                                                <span slot="label"><b>Select a valid <i>Materials Rates’ Contract</i></b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                                    <span class="tooltip-item2" style="position: relative; top: -0px; left: -0px;">
                                                                        <span class="fa-stack fa-1x">
                                                                            <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                        </span>
                                                                    </span>
                                                                    <span style="bottom: 20px; left: -90px; width: 675px;" class="tooltip-content4 clearfix">
                                                                        <span class="tooltip-text2">
                                                                            <p><i>Materials Rates’ Contracts</i> are pre-negotiated agreements with vendors that establish fixed <u>Unit Rates</u> and <u>Lead Times</u>, for items sourced 
                                                                            from the <i>Library of Materials</i>, before the demand arises. This approach helps to reduce costs and turnaround times:<i>Purchase Enquiries</i>, 
                                                                            linked to <i>Materials Rates’ Contracts</i>, can be directly turned into <i>Purchase Orders</i>.</p>
                                                                            <p>A <i>Materials Purchase Enquiry</i>, linked to a <i>Materials Rates’ Contracts</i>, will have the latter's <i>Advance Payment</i> and 
                                                                            <i>Retention</i>, Unit Rate & Lead Time Arrangements apply to it.</p>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <el-select style="width: 100%" 
                                                                    v-model="PurchaseEnquiryLine.RateContractSelected" 
                                                                    @change="checkLowestRateContract()" 
                                                                    placeholder="Select from the list of valid 'Materials Rates Contract' for the selected item & city">
                                                                    <el-option style="max-width: 960px; min-height: 150px"
                                                                        v-for="item in RateContractSelection" 
                                                                        :key="item.value" 
                                                                        :label="'Vendor Name: '+item.vendor_name+' | Vendor Score: '+item.vendor_score+' | Unit Rate: '+item.unit_rate+ 'USD | Lead Time: ' +item.lead_time+' | Rates’ Contract Reference: '+item.rate_contract_reference+' | Rate Contract Expiry Date: '+item.date" 
                                                                        :value="item" 
                                                                        v-if="item.city == PurchaseEnquiryLine.Cities">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border: 2px solid">
                                                                                <table class="table itemTable lomitemtable mx-auto w-100" style="margin-left:auto;margin-right:auto;">
                                                                                    <tr>
                                                                                        <table style="margin-left:auto;margin-right:auto;">
                                                                                            <tr>
                                                                                                <th><b class="font-700">Vendor Name</b></th>
                                                                                                <th><b class="font-700">Vendor Score</b></th>
                                                                                                <th><b class="font-700">Expiry Date</b></th>
                                                                                                <th><b class="font-700">Unit Rate</b></th>
                                                                                                <th><b class="font-700">Lead Time</b></th>
                                                                                                <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                                                                <th><b class="font-700">Discount Rate</b></th>
                                                                                                <th><b class="font-700">Total Procured So Far</b></th>
                                                                                                <th><b class="font-700"><i>Advance Payment</i> Arrangement</b></th>
                                                                                                <th><b class="font-700"><i>Retention</i> Arrangement</b></th>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ item.vendor_name }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                                <td>{{ item.date }}</td>
                                                                                                <td>{{ item.unit_rate }} USD </td>
                                                                                                <td>{{ item.lead_time }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                                <td>{{ item.vendor_score }}</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </tr>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                        <div v-if="PurchaseEnquiryLine.rtYN !== 'yes'" style="text-align: center; position: relative; margin: 30px 0;">
                                                            <hr style="border: 1px solid rgb(64, 64, 64); width: 100%; margin: 0;">
                                                            <!--<hr style="border: none; border-top: 1px solid rgb(64, 64, 64); width: 100%; position: relative;">-->
                                                            <span style="position: absolute; top: -12px; left: 50%; transform: translateX(-50%); background-color: white; padding: 0 10px; color: rgb(64, 64, 64);"><b>OR</b></span>
                                                        </div>
                                                        <div style="text-align: center;">
                                                            <input type="checkbox" id="rtcheckbox" true-value="yes" false-value="no" 
                                                                @click="ResetRateContractDropdown()" 
                                                                v-model="PurchaseEnquiryLine.rtYN"
                                                                style="position: relative; top: 0; margin-right: 10px;"
                                                            >
                                                            <label for="rtcheckbox" 
                                                                :style="PurchaseEnquiryLine.rtYN == 'yes' ? 'color: rgb(252, 51, 23); font-weight: bold;' : 'color: black; font-weight: bold;'">
                                                                Proceed without selecting a valid <i>Materials Rates’ Contract</i>
                                                            </label>
                                                        </div>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceDescription.trim().length > 0">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="ServiceOneOff">
                                                        <span slot="label"><b>Will the <i>Services Purchase Enquiry</i> be One-Off or will it be for a period of time ?</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="bottom: 20px; left: -420px; width: 715px;">
                                                                <span class="tooltip-text2">
                                                                    <p>A 'One-Off' <i>Services Purchase Enquiry</i> is for <i>Services</i> that get
                                                                    delivered in a single instance. For example, when a 'Maintenance Service' is requested to fix a defective equipment.</p>
                                                                    <p>'Over A Period Of Time' is selected when the <i>Services</i> will be delivered over a certain term. 
                                                                    For example, when 'Marketing Services' are needed over 12 months.</p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceOneOff" placeholder="Choose 'One-Off' or 'Over A Period Of Time'">              
                                                            <el-option label="One-Off" value="One-Off"></el-option>
                                                            <el-option label="Over A Period Of Time" value="Over A Period Of Time"></el-option>       
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff == 'Over A Period Of Time'">
                                                    <el-form-item label="temp" prop="ServiceTerm">
                                                        <span slot="label"><b>What is the period of time for the <i>Services Purchase Enquiry</i>, in days ?</b></span>
                                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceTerm" placeholder="Select a number of days">
                                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option>   
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                                <div class="grid-content">
                                                    <el-form-item label="temp">
                                                        <!-- Tooltip for the label "Describe what should be included in the Vendor Proposal Content" -->
                                                        <span slot="label">
                                                            <b>Enter the <i>Instructions To Vendors</i></b>
                                                        </span>
                                                        <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                </span>
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -88px; width: 715px; z-index: 10000;">
                                                                <span class="tooltip-text2">
                                                                    These Headers & their Contents help structure the <i>Instructions To Vendors</i>. They shall, for example, cover aspects such as:
                                                                    <ul>
                                                                        <li>Pre-qualification Questionnaire (if applicable);</li>
                                                                        <li>Cover Letter;</li>
                                                                        <li>Award Timeline;</li>
                                                                        <li>Confidentiality Arrangements;</li>
                                                                        <li>Proposal Evaluation Criteria;</li>
                                                                        <li>Clarification Meeting Arrangements;</li>
                                                                        <li>Request for Information Arrangements;</li>
                                                                        <li>Sites Visits Arrangements;</li>
                                                                        <li>Health and Safety Requirements;</li>
                                                                        <li>Compliance Requirements;</li>
                                                                        <li>Insurrance Cover Requirements;</li>
                                                                        <li>Proposal Submission Checklist;</li>
                                                                        <li>.......</li>
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        <!-- The table below -->
                                                        <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                        <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                        <th class="text-center" style="width: 5%;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <!-- Row for adding new input -->
                                                                    <tr style="background-color: white;">
                                                                        <td style="width: 25%;">
                                                                            <div style="display: flex; width: 100%;">
                                                                                <input class="form-control" v-model="tenderercontractheader.name" placeholder="e.g. Instruction #1:" style="flex: 1;">
                                                                            </div>
                                                                        </td>
                                                                        <td style="width: 70%; position: relative;">
                                                                            <div style="display: flex; width: 100%;">
                                                                                <input class="form-control" v-model="tenderercontractheader.contant" placeholder="e.g. The Evaluation Criteria are described below: ......." style="flex: 1;">
                                                                            </div>
                                                                        </td>
                                                                        <td class="text-center" style="width: 5%;">
                                                                            <!-- Plus Button -->
                                                                            <button v-if="tenderercontractheader.name.trim().length > 0 && tenderercontractheader.contant.trim().length > 0" 
                                                                                @click.prevent="addTendererContractHeader()" 
                                                                                class="btn-pointer btn-success btn-floating" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0; background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-plus"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>

                                                                    <tr v-for="(header, index) in PurchaseEnquiryLine.TendererContractHeaders" 
                                                                    :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                        <td style="width: 25%; font-weight: bold;">
                                                                            <div v-if="!header.isEditing" @click="editHeader(index)" style="cursor: pointer;">
                                                                                {{ header.name }}
                                                                            </div>
                                                                            <input v-else v-model="header.name" @blur="stopEditing(index)" class="form-control" style="flex: 1;">
                                                                        </td>
                                                                        

                                                                        <!-- Content Column (Truncated with Ellipsis and Full View on Hover) -->
                                                                        <td style="width: 70%; position: relative;">
                                                                                <div style="position: relative;">
                                                                                    <!-- Display truncated content with ellipsis -->
                                                                                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; max-width: 90%; font-size: 17px;" 
                                                                                        :title="`${header.name}: ${header.contant}`">
                                                                                        {{ header.contant }}
                                                                                    </div>

                                                                                    <!-- Tooltip with full content display -->
                                                                                    <div style="display: none; position: absolute; background: white; border: 1px solid #ddd; padding: 10px; z-index: 1000; max-width: 500px; max-height: 1500em; overflow-y: auto; white-space: normal; word-wrap: break-word; line-height: 1.5; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);"
                                                                                        onmouseover="this.style.display='block';" 
                                                                                        onmouseout="this.style.display='none';">
                                                                                        <span style="font-weight: bold;">{{ header.name }}</span><br>
                                                                                            <div style="padding-top: 1.5em;">
                                                                                                {{ header.contant }}
                                                                                            </div>
                                                                                    </div>
                                                                                </div>
                                                                        </td>

                                                                        <td class="text-center" style="width: 5%;">
                                                                            <!-- Trash Button -->
                                                                            <button type="button" @click="deleteTendererContractHeader(index)" 
                                                                                class="btn-pointer btn-danger btn-floating" 
                                                                                style="padding: 0px 13px; background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;"
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                                <div class="grid-content">
                                                    <el-form-item label="temp">
                                                        <!-- Updated Label -->
                                                        <span slot="label"><b>Describe what should be included in the <i>Vendor Proposal Content</i></b></span>

                                                        <!-- Tooltip -->
                                                        <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                </span>
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -321px; width: 715px; z-index: 10000;">
                                                                <span class="tooltip-text2">
                                                                    These Headers & their Contents provide clear descriptions of what the vendors must submit to support their commercial proposals.
                                                                    The <i>Vendor Proposal Content</i> may include:
                                                                    <ul>
                                                                        <li>Company Profile;</li>
                                                                        <li>Capability Statements;</li>
                                                                        <li>Key Personnel Bios;</li>
                                                                        <li>Compliance Statements;</li>
                                                                        <li>Technical Proposal;</li>
                                                                        <li>Method Statements;</li>
                                                                        <li>Service Delivery Methodology;</li>
                                                                        <li>Value Proposition;</li>
                                                                        <li>Project Plan and Milestones;</li>
                                                                        <li>Supply Chain Plan;</li>
                                                                        <li>Health and Safety Requirements;</li>
                                                                        <li>References and Case Studies;</li>                                                       
                                                                        <li>Customers Statements;</li> 
                                                                        <li>Warranties and Supports;</li>
                                                                        <li>Products Catalog;</li>
                                                                        <li>Financial Statements;</li>
                                                                        <li>Business Licenses;</li>
                                                                        <li>Proofs of Insurance;</li>
                                                                        <li>Certifications by third-parties;</li>
                                                                        <li>Audit Reports;</li>
                                                                        <li>......</li>
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>

                                                        <!-- Table -->
                                                        <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                        <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                        <th class="text-center" style="width: 5%;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <!-- Row for adding new input -->
                                                                    <tr style="background-color: white;">
                                                                        <td style="width: 25%;">
                                                                            <div style="display: flex; width: 100%;">
                                                                                <input class="form-control" v-model="vendoroffercontent.name" placeholder=" e.g. The Service Delivery Methodology:" style="flex: 1;">
                                                                            </div>
                                                                        </td>
                                                                        <td style="width: 70%; position: relative;">
                                                                            <div style="display: flex; width: 100%;">
                                                                                <input class="form-control" v-model="vendoroffercontent.contant" placeholder=" e.g. The Service Delivery Methodology must include ....." style="flex: 1;">
                                                                            </div>
                                                                        </td>
                                                                        <td class="text-center" style="width: 5%;">
                                                                            <!-- Plus Button -->
                                                                            <button v-if="vendoroffercontent.name.trim().length > 0 && vendoroffercontent.contant.trim().length > 0" 
                                                                                @click.prevent="addVendorOfferContent()" 
                                                                                class="btn-pointer btn-success btn-floating" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0; background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-plus"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>

                                                                    <!-- Existing rows dynamically generated -->
                                                                    <tr v-for="(header, index) in PurchaseEnquiryLine.VendorOfferContent" 
                                                                    :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                        
                                                                        <td style="width: 25%; font-weight: bold;">
                                                                            <div v-if="!header.isEditing" @click="editHeader(index)" style="cursor: pointer;">
                                                                                {{ header.name }}
                                                                            </div>
                                                                            <input v-else v-model="header.name" @blur="stopEditing(index)" class="form-control" style="flex: 1;">
                                                                        </td>

                                                                        <!-- Content Column (Truncated with Ellipsis and Full View on Hover) -->
                                                                        <td style="width: 70%; position: relative;">
                                                                                <div style="position: relative;">
                                                                                    <!-- Display truncated content with ellipsis -->
                                                                                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; max-width: 90%; font-size: 17px;"
                                                                                        :title="`${header.name}: ${header.contant}`">
                                                                                        {{ header.contant }}
                                                                                    </div>

                                                                                    <!-- Tooltip with full content display -->
                                                                                    <div style="display: none; position: absolute; background: white; border: 1px solid #ddd; padding: 10px; z-index: 1000; max-width: 500px; max-height: 1500em; overflow-y: auto; white-space: normal; word-wrap: break-word; line-height: 1.5; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);"
                                                                                        onmouseover="this.style.display='block';" 
                                                                                        onmouseout="this.style.display='none';">
                                                                                        <span style="font-weight: bold;">{{ header.name }}</span><br>
                                                                                        <div style="padding-top: 1.5em;">
                                                                                            {{ header.contant }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                        <td class="text-center" style="width: 5%;">
                                                                            <!-- Trash Button -->
                                                                            <button type="button" @click="deleteVendorOfferContent(index)" 
                                                                                class="btn-pointer btn-danger btn-floating" 
                                                                                style="padding: 0px 13px; background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" 
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)">
                                                <div class="grid-content">
                                                    <el-form-item label="temp">
                                                            <span slot="label">
                                                                <b>Enter the <i>Services Description</i></b>
                                                            </span>
                                                            <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -65px; width: 715px; z-index: 10000;">
                                                            <span class="tooltip-text2">
                                                                These Headers and their Contents help better structure the <i>Services Purchase Enquiry</i> and describe what the Awardee Vendor must deliver and/or comply with.
                                                                They can include, for example, detailed descriptions of:
                                                                <ul>
                                                                    <li>Scope of Works;</li>
                                                                    <li>Deliverables;</li>
                                                                    <li>Inclusions;</li>
                                                                    <li>Exclusions;</li>
                                                                    <li>Terms and Conditions;</li>
                                                                    <li>Service Levels Agreements,</li>
                                                                    <li>KPIs, Performance Metrics, and Penalties;</li>
                                                                    <li>Activities;</li>
                                                                    <li>Project Plan and Milestones;</li>
                                                                    <li>Supply Chain Plan;</li>
                                                                    <li>Health and Safety Plan;</li>
                                                                    <li>Bank Guarrantees & Bonds;</li>
                                                                    <li>Reporting and Reviews;</li>
                                                                    <li>Confidentiality and Data Protection;</li>
                                                                    <li>Dispute Resolution;</li>
                                                                    <li>Compliance and Regulations;</li>
                                                                    <li>Indemnity and Liability;</li>
                                                                    <li>Change Management Arrangements;</li>
                                                                    <li>Contact Information;</li>
                                                                    <li>.....</li>
                                                                </ul>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <div style="max-height: 250px; overflow-y: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse dataex-res-configuration bg-white" style="width: 100%; table-layout: fixed;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: left; font-weight: bold;">Header</th>
                                                                        <th style="width: 70%; text-align: left; font-weight: bold;">Content</th>
                                                                        <th class="text-center" style="width: 5%;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                        <!-- Row for adding new input -->
                                                                        <tr style="background-color: white;">
                                                                            <td style="width: 25%;">
                                                                                <div style="display: flex; width: 100%;">
                                                                                    <input class="form-control" v-model="contractheader.name" placeholder="e.g. 1. Scope of Works:" style="flex: 1; color: rgb(251, 23, 31);">
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 70%; position: relative;">
                                                                                <div style="display: flex; width: 100%;">
                                                                                    <input class="form-control" 
                                                                                        v-model="contractheader.contant" 
                                                                                        placeholder="e.g. The Awardee Vendor shall deliver the following: ......." 
                                                                                        style="flex: 1; font-size: 16px;" />
                                                                                </div>
                                                                            </td>

                                                                            <td class="text-center" style="width: 5%;">
                                                                                <!-- Plus Button -->
                                                                                <button v-if="contractheader.name.trim().length > 0 && contractheader.contant.trim().length > 0" 
                                                                                    @click.prevent="addContractHeader()" 
                                                                                    class="btn-pointer btn-success btn-floating" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0; background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>

                                                                        <!-- Existing rows dynamically generated -->
                                                                        <tr v-for="(header, index) in PurchaseEnquiryLine.ContractHeaders" :key="index" style="background-color: rgba(252, 51, 23, 0.05);">
                                                                            
                                                                            <td style="width: 25%; font-weight: bold;">
                                                                            <div v-if="!header.isEditing" @click="editHeader(index)" style="cursor: pointer;">
                                                                                {{ header.name }}
                                                                            </div>
                                                                            <input v-else v-model="header.name" @blur="stopEditing(index)" class="form-control" style="flex: 1;">
                                                                            </td>
                                                                            <!-- Content Column (Truncated with Ellipsis and Full View on Hover description) -->
                                                                            <td style="width: 70%; position: relative;">
                                                                                <div style="position: relative;">
                                                                                    <!-- Display truncated content with ellipsis -->
                                                                                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; max-width: 90%; font-size: 17px;" 
                                                                                        :title="`${header.name}: ${header.contant}`">
                                                                                        {{ header.contant }}
                                                                                    </div>

                                                                                    <!-- Tooltip with full content display -->
                                                                                    <div style="display: none; position: absolute; background: white; border: 1px solid #ddd; padding: 10px; z-index: 1000; max-width: 500px; max-height: 1500em; overflow-y: auto; white-space: normal; word-wrap: break-word; line-height: 1.5; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);"
                                                                                        onmouseover="this.style.display='block';" 
                                                                                        onmouseout="this.style.display='none';">
                                                                                        <span style="font-weight: bold;">{{ header.name }}</span><br>
                                                                                        <div style="padding-top: 1.5em;">
                                                                                            {{ header.contant }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center" style="width: 5%;">
                                                                                <!-- Trash Button -->
                                                                                <button type="button" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating" style="padding: 0px 13px; background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                    <i class="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                </tbody>
                                                                </table>
                                                            </div>
                                                        </el-form-item>
                                                </div>
                                            </el-col>
                                            
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                                <div class="grid-content">
                                                    <el-form-item style="font-weight: bolder" label="temp">
                                                        <span slot="label"><b>Upload any necessary supporting documents</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                            <span class="tooltip-item2">
                                                                                    <span class="fa-stack fa-1x">
                                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                    </span>
                                                                            </span>
                                                                    <span style="bottom: 20px; left: -180px; width: 717px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>These documents could be as diverse as spreadsheets which the vendors must complete and submit along with their <i>Vendor Proposal</i>, if required.</p>                                                </span>
                                                                    </span>
                                                                </span>
                                                        <div style="margin-top: 8px;">
                                                            <el-upload
                                                                class="upload-demo"
                                                                action="#"
                                                                :limit="4"
                                                                id="Images"
                                                                :auto-upload="false"
                                                                ref="ImagesSD"
                                                                accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .xlsx"
                                                                :on-change="handleChangeSD"
                                                                :on-exceed="handleExceedSD"
                                                                :on-remove="handleRemoveSD">
                                                                <button type="button" slot="trigger" 
                                                                        class="btn btn-success"
                                                                        style="box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); border: none; transition: box-shadow 0.3s ease, transform 0.3s ease;"
                                                                        onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" 
                                                                        onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">
                                                                    Click to Select Files
                                                                </button>
                                                                <div class="el-upload__tip" slot="tip">
                                                                    JPEG, PNG, DOCX, PDF, XLSX files are allowed with a size of 1Mb or less (A maximum of 4 Files)
                                                                </div>
                                                            </el-upload>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)" >
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ContractHeaders.length && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0)"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                                <div class="grid-content">
                                                    <el-form-item style="font-weight: bolder" label="temp" prop="TypeOfServicesContract">
                                                        <span slot="label"><b>Select the <i>Vendor Commercial Proposal</i>  type</b></span>               
                                                        <el-select @change="ClearServiceTypeOptions()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.TypeOfServicesContract" placeholder="Select 1 of the 3 options: 'Fixed-Price Proposal', 'Cost-Reimbursement Proposal', or 'Cost-Plus-Fixed-Fee Proposal'">         
                                                            <el-option value="Fixed-Price Proposal">Fixed-Price Proposal
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>       
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>A <i>Fixed-Price Proposal</i>, also known as a Lump Sum Proposal,
                                                                            is used in situations where <br>the payment doesn’t depend on the
                                                                            resources used or time expended. </p>
                                                                            <p>With a <i>Fixed-Price Proposal</i>, 
                                                                            vendors will estimate the total allowable costs of labor, <br> 
                                                                            materials and equipment, and perform the action specified by
                                                                            the contract regardless<br> of their actual cost.</p>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </el-option>
                                                            <el-option value="Cost-Reimbursement Proposal">Cost-Reimbursement Proposal
                                                                <span style="position: relative; z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>With a <i>Cost-Reimbursement Proposal</i>, the final
                                                                            total cost is determined when the <i>Service</i><br> is completed
                                                                            or at another predetermined date within the <i>Service’s</i> timeframe.</p>
                                                                            <p>Before the <i>Service</i> is started, the <i>Vendor Proposal</i> establishes a ceiling price that the <br>vendor
                                                                            shouldn’t exceed without the approval of the organization.</p> 
                                                                            <p>At the same time, if that ceiling is reached, the vendor can stop the <i>Service</i>.</p>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </el-option>
                                                            <el-option value="Cost-Plus-Fixed-Fee Proposal">Cost-Plus-Fixed-Fee Proposal
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>             
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>A <i>Cost-Plus-Fixed-Fee Proposal</i> is a type of <i>Vendor Proposal</i>
                                                                            for situations where the<br> organization agrees to pay the
                                                                            actual cost of the entire <i>Services</i>, including labor, materials,<br>
                                                                            and any unexpected expenses.</p>
                                                                            <p>The word “Plus” refers to the fee that covers the vendor’s profits and overhead.</p>
                                                                            <p>In this case, the organization agrees to pay that extra
                                                                            amount and expects the vendor<br> to deliver on their promise.</p>

                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </el-option>           
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-Price Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service' " >
                                                <div class="grid-content">
                                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                        <span slot="label"><b>Select a <i>Vendor Commercial Proposal</i> required format</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 716px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format. </p>
                                                                <p>In the case of <i>Fixed-Price Proposal</i>, vendors are required to either submit:
                                                                    <ol>
                                                                    <li>Their total price (as a lump sum value); or</li> 
                                                                    <li>The breakdown of manpower, subcontractors, tools/equipment & materials prices.</li> 
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Select one of the below options">                      
                                                            <el-option label="Total Fixed (Lump Sum) Price" value="Fixed-Price (Lump Sum) Price"></el-option>
                                                            <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>                 
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Reimbursement Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                <div class="grid-content">
                                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                        <span slot="label"><b>Select a <i>Vendor Proposal</i> required format</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 716px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format. </p>
                                                                    <p>In the case of a <i>Cost-Reimbursement Proposal</i>, vendors are required to either submit:
                                                                    <ol>
                                                                        <li>Their total reimbursment cost; or</li> 
                                                                        <li>The breakdown of manpower, subcontractors, tools/equipment & materials reimbursement costs.</li>
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Select one of the below options">      
                                                            <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                                            <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Reimbursement Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Reimbursement Prices"></el-option>       
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Plus-Fixed-Fee Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                                <div class="grid-content">
                                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                        <span slot="label"><b>Select a <i>Vendor Proposal</i> required format</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 716px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format.</p>
                                                                    <p>In the case of <i>Cost-Plus-Fixed-Fee Proposal</i>, vendors are required to either submit:
                                                                    <ol>
                                                                        <li>Their total cost (as a lump sum value), along with their profit and overhead fee as a % of the total cost; or</li> 
                                                                        <li>The breakdown of manpower, subcontractors, tools/equipment & materials costs, along with their profit 
                                                                        and overhead fee as a % of the total cost.</li>
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>                  
                                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Select one of the below options">                         
                                                            <el-option label="Total Vendor Cost + Profit & Overhead Fee (in %)" value="Total Vendor Cost + Profit & Overhead Fee (in %)"></el-option>
                                                            <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Vendor Costs + Profit & Overhead Fee (in %)" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Vendor Costs + Profit & Overhead Fee (in %)"></el-option>     
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span=24"><hr v-if="PurchaseEnquiryLine.Quantity > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType === 'Materials' && PurchaseEnquiryLine.Quantity > 0 && (PurchaseEnquiryLine.rtYN === 'yes' || (Array.isArray(PurchaseEnquiryLine.RateContractSelected) && PurchaseEnquiryLine.RateContractSelected.length === 0) || (typeof PurchaseEnquiryLine.RateContractSelected === 'object' && PurchaseEnquiryLine.RateContractSelected && Object.keys(PurchaseEnquiryLine.RateContractSelected).length === 0))">
                                                    <div class="grid-content">
                                                        <el-form-item label="temp" prop="AdvancedPayment">
                                                            <span slot="label"><b>Will you consider offering an <i>Advance Payment</i> for the item ?</b></span>
                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="fa-stack fa-1x">
                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                    </span>
                                                                </span>
                                                                <span style="bottom: 18px; left: -298px; width: 714px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>You have the possibility to signal whether or not your 
                                                                        organization is willing to make an <i>Advance Payment</i> to
                                                                        the Awardee Vendor, at the time of the <i>Purchase 
                                                                        Order</i> issuance for this item. </p>
                                                                        <p>If 'Yes' is selected, the vendors will be able to 
                                                                        submit, in their proposal, their desired <i>Advance Payment</i> 
                                                                        requirement, expressed in percentage of their total
                                                                        commercial proposal.</p>
                                                                        <p> When a <i>Materials Rates’ Contract</i> is selected for 
                                                                        this <i>Purchase Enquiry</i> item, the <i>Advance Payments</i> 
                                                                        arrangements are those applicable to the said 
                                                                        <i>Materials Rates’ Contract</i>, as agreed with the 
                                                                        concerned Awardee Vendor.</p>
                                                                        <p>Finally, you can choose whether or not to request collaterals from the Awardee Vendor, 
                                                                        when your organization agrees to the <i>Advance Payment</i>.</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will you consider offering an Advance Payment? Choose 'Yes' or 'No'">        
                                                                <el-option label="Yes" value="Yes"></el-option>
                                                                <el-option label="No" value="No"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div>
                                            </el-col>
                                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.VendorCommercialOffer !==''" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.VendorCommercialOffer)">                     
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="AdvancedPayment">
                                                        <span slot="label"><b>Will you consider offering an <i>Advance Payment</i> for this <i>Services Purchase Enquiry</i> ?</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: -20px; left: 175px; width: 612px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>You have the possibility to signal whether or not your 
                                                                    organization is willing to make an <i>Advance Payment</i> to
                                                                    the Awardee Vendor, at the time of the <i>Purchase 
                                                                    Order</i> issuance for this item. </p>
                                                                    <p>If 'Yes' is selected, the vendors will be able to 
                                                                    submit, in their proposal, their desired <i>Advance Payment</i> 
                                                                    requirement, expressed in percentage of their total
                                                                    commercial proposal.</p>
                                                                    
                                                                    <p>Finally, you can choose whether or not to request collaterals from the Awardee Vendor, 
                                                                    when your organization agrees to the <i>Advance Payment</i>.</p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will you consider offering an Advance Payment? Choose 'Yes' or 'No'">                               
                                                            <el-option label="Yes" value="Yes"></el-option>
                                                            <el-option label="No" value="No"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment == 'Yes'" >
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="GuaranteeNeedForAdvancePayment">
                                                        <span slot="label"><b>Is a collateral, from the Awardee Vendor, required against the <i>Advance Payment</i> ?</b></span>
                                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment" placeholder="Is a collateral required against the Advance Payment ? Choose 'Yes' or 'No'">
                                                            <el-option label="Yes" value="Yes"></el-option>
                                                            <el-option label="No" value="No"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" >
                                                <div class="grid-content">                                    
                                                    <el-form-item label="temp" prop="RetentionPercentage">
                                                        <span slot="label"><b>Enter the <i>Retention Percentage</i>, if applicable</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: 18px; left: -170px; width: 714px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>
                                                                        The <i>Retention Percentage</i> refers to the portion of the quoted price, expressed as a percentage, 
                                                                        that the organization retains from the payment made to the Awardee Vendor.
                                                                    </p>
                                                                    <p>
                                                                        The amount is released back to the Awardee Vendor once the <i>Retention Timeframe</i> has passed.
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span style="bottom: -20px; left: -210px; width: 593px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                           
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix" style="left: -150px">-->
                                                            <span style="bottom: -20px; left: -210px; width: 593px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Percentage</i> is the portion of a payment, expressed as a percentage, that your organization 
                                                                        <u>temporary</u> withholds from the Awardee Vendor for all <i>Purchase Orders</i> issued under this <i>Rates' Contract</i> until the 
                                                                        <i>Retention Timeframe</i> elapses. </p>
                                                                        <p>This retention acts as a security or guarantee to ensure that the Awardee Vendor 
                                                                        fulfills all contractual obligations, including rectifying any defects or deficiencies identified during or after the 
                                                                        delivery of the <i>Materials</i> or <i>Services</i>.</p>
                                                                        <p>The retention will be released back to the Awardee Vendor as soon as the <i>Retention Timeframe</i> elapses.</p> 
                                                                        <p>When a <i>Materials Rates’ Contract</i> is selected for the item, the 
                                                                    retention arrangements are those applicable to the said <i>Materials Rates’ 
                                                                    Contract</i>, as agreed with the concerned Awardee Vendor.</p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-input min='0.000001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="PurchaseEnquiryLine.RetentionPercentage">
                                                        </el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col> 
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.RetentionPercentage != 0 || PurchaseEnquiryLine.RetentionPercentage != ''" >
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="RetentionDays">
                                                        <span slot="label"><b>Select the <i>Retention Timeframe</i> </b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: -20px; left: 175px; width: 450px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Timeframe</i> begins on the date when either:
                                                                                    <div style="margin-left: 40px;">
                                                                                        1. The the complete quantity specified in the <i>Purchase Order</i> is received from the Awardee Vendor; or<br>
                                                                                        2. the <i>Purchase Order</i> is closed.<br>                       
                                                                                    </div>
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RetentionDays" placeholder="Select the Retention Timeframe duration, in days">               
                                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option>    
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" >
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="PELineNote">
                                                        <span slot="label"><b>Addtional Notes</b></span>
                                                        <el-input
                                                            type="textarea"
                                                            :rows="2"
                                                            placeholder="Enter 'Purchase Enqiury Line' notes"
                                                            v-model="PurchaseEnquiryLine.PELineNote">
                                                        </el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                            <button v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.AdvancedPayment) || (Array.isArray(PurchaseEnquiryLine.RateContractSelected) && PurchaseEnquiryLine.RateContractSelected.length) || (typeof PurchaseEnquiryLine.RateContractSelected === 'object' && PurchaseEnquiryLine.RateContractSelected && Object.keys(PurchaseEnquiryLine.RateContractSelected).length)" 
                                                    type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center" 
                                                    style="background-color: rgb(252, 51, 23); box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); border: none; transition: box-shadow 0.3s ease, transform 0.3s ease;" 
                                                    onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" 
                                                    onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">
                                                    Add The Line To The <i>Materials Purchase Enquiry</i>
                                            </button>
                                            <button 
                                                v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryAll.length == 0 && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))" type="button" @click="SubmitService" 
                                                class="btn btn-block btn-success waves-effect" 
                                                style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" 
                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.4)';" 
                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                Submit The <i>Services Purchase Enquiry</i> For Approval
                                            </button>
                                            <button 
                                                :disabled="(PurchaseEnquiryAll.filter(item => item.ServiceType == 'Unlinked').length > 0)" 
                                                v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryAll.length > 0 && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"
                                                type="button" @click="AddToServiceList(null, 'Unlinked')" 
                                                class="btn btn-block waves-effect text-center btn-unlinked-service"
                                                style="background-color: rgb(252, 51, 23); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" 
                                                onmouseover="this.style.boxShadow = '4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform = 'translateY(-5px)';" 
                                                onmouseout="this.style.boxShadow = '2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform = 'translateY(0)';">
                                                Add The Line To The <i>Services Purchase Enquiry</i>
                                            </button>

<!-- v-if="(PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryAll.length > 0 && (IgnoreRCSelectionService || NoRCServiceAvailableCheckCount > 0))"-->


                        </el-row>
                    </el-form>

                </div>
            </div>

            <div class="card" v-else>
                <div class="card-header">
                    <h4>Initiate A <i>Purchase Enquiry</i> Line</h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Details...</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of PEs in the list -->
        <div id="Purchase_Enqiury_List" class="col-lg-5" v-if="PurchaseEnquiryLine.EnquiryType">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="PurchaseEnquiryAll.length > 0 && PurchaseEnquiryLine.EnquiryType == 'Materials'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4 style="text-align: center;"><i>Materials Purchase Enquiry</i> Lines</h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Items Details</th>
                                            <th>Quantity</th>
                                            <th>Expected Cost — {{this.projectcurrency.substring(0, 3)}}.</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" :key="index">
                                            <td v-if="PurchaseEnquiry.ItemNumber != ''">
                                                <span v-if="PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix == null">LoM</span>
                                                <span v-else>{{ PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix }}</span>-{{ PurchaseEnquiry.ItemLibraryDescription.item_group_id }}-{{ PurchaseEnquiry.ItemLibraryDescription.item_ingroup_id }}</td>
                                            <td v-else>{{ PurchaseEnquiry.ItemDescription.substring(0, 20) + '...' }}</td>
                                            <td>{{ PurchaseEnquiry.Quantity }}</td>
                                            <td v-if="PurchaseEnquiry.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiry.RateContractSelected.unit_rate">{{ formatPrice(PurchaseEnquiry.RateContractSelected.unit_rate * PurchaseEnquiry.Quantity)}}</td>
                                            <td v-else> - </td>
                                            <td width="25%">
                                                <a style="padding: 6px 8px; height: 30px; width: 30px; background-color: #ffc107; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-info"></i></a>
                                                <a style="padding: 6px 8px; height: 32px; width: 30px; background-color: #d33; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-remove"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p v-if="this.TotalExpectedPrice">The total estimated cost for the <i>Purchase Enquiry</i> lines, based on the selected <i>Materials Rates' Contract</i> or on past <i>Purchase Orders</i>, or on past Vendors’ offers, is:<b class="font-weight-bold"> {{formatPrice(this.TotalExpectedPrice)}} {{this.projectcurrency.substring(0, 3)}}.</b></p>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-block btn-success waves-effect" style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.4)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">Submit The <i>Materials Purchase Enquiry</i> For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="RCSelectionService && PurchaseEnquiryLine.EnquiryType == 'Service'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header" style="text-align: center;">
                                <h4><b><i>Services Purchase Enquiry</i> Lines</b></h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <h5 style="background-color: rgb(0, 114, 187); color: white; padding: 5px; text-align: center;"><i>Services Purchase Enquiry</i> Lines – <b>Linked To <i>Services Rates’ Contracts</i></b></h5>
                                <table class="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th><i>Services Rates’ Contract</i> | <i>Service Line Unit of Measure</i></th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                        <tbody style="display:;width: 100% !important; max-height: 200px; overflow-y: scroll;">
                                            <tr v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" v-if="PurchaseEnquiry.ServiceType == 'Linked'" :key="index">
                                                <td>{{ PurchaseEnquiry.UnitOfMeasurement }}</td>
                                                <td>{{ PurchaseEnquiry.Quantity }}</td>
                                                <td width="25%">
                                                    <a style="padding: 6px 8px; height: 30px; width: 30px; background-color: #ffc107; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-info"></i></a>
                                                    <a style="padding: 6px 8px; height: 32px; width: 30px; background-color: #d33; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-remove"></i></a>
                                                </td>
                                            </tr>
                                            <tr v-if="PurchaseEnquiryAll.filter(item => item.ServiceType == 'Linked').length == 0">
                                                <td class="text-center" colspan="4" style="color: rgb(0, 114, 187);"><i>No Lines To Display</i></td>
                                            </tr>
                                        </tbody>
                                </table>
                                <hr>
                                <h5 style="background-color: rgb(252, 51, 23); color: white; padding: 5px; text-align: center;"><i>Services Purchase Enquiry</i> Lines – <b>Unrelated To <i>Services Rates’ Contracts</i></b></h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Service Short Description</th>
                                            <th>Type of Service Contract</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="unlinkedServiceList">
                                        <tr class="peServiceLineUnlinked" v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" v-if="PurchaseEnquiry.ServiceType == 'Unlinked'" :key="index">
                                            <td>{{ PurchaseEnquiry.ServiceDescription }}</td>
                                            <td>{{ PurchaseEnquiry.TypeOfServicesContract }}</td>
                                            <td width="25%">
                                                <a style="padding: 6px 8px; height: 30px; width: 30px; background-color: #ffc107; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-info"></i></a>
                                                <a style="padding: 6px 8px; height: 32px; width: 30px; background-color: #d33; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease; display: inline-flex; align-items: center; justify-content: center;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';"><i class="fa fa-remove"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="PurchaseEnquiryAll.filter(item => item.ServiceType == 'Unlinked').length == 0">
                                            <td class="text-center" colspan="4" style="color: rgb(252, 51, 23);"><i>No Lines To Display</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-block btn-ogeo waves-effect waves-light" style="height: 58px; background-color: #38761d; color: white; border: 2px solid #38761d; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">Submit The <i>Services Purchase Enquiry</i> For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="RCSelectionService && PurchaseEnquiryLine.EnquiryType == 'Service'" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header" style="text-align: center;">
                                <h4><b><i>Services Purchase Enquiry</i> Lines</b></h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <h5 style="background-color: rgb(0, 114, 187); color: white; padding: 5px; text-align: center;"><i>Services Purchase Enquiry</i> Lines – <b>Linked To <i>Services Rates’ Contracts</i></b></h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><i>Services Rates’ Contract</i> | <i>Service Line Unit Of Measure</i></th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center" colspan="4" style="color: rgb(0, 114, 187);"><i>No Lines To Display</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr>
                                <h5 style="background-color: rgb(252, 51, 23); color: white; padding: 5px; text-align: center;"><i>Services Purchase Enquiry</i> Lines – <b>Unrelated To <i>Services Rates’ Contracts</i></b></h5>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Service Short Description</th>
                                            <th>Type of Service Contract</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center" colspan="4" style="color: rgb(252, 51, 23);"><i>No Lines To Display</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                 <button type="button" @click="SubmitPEList" class="btn btn-block btn-ogeo waves-effect waves-light" style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.4)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">Submit The <i>Materials Purchase Enquiry</i> For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else-if="PurchaseEnquiryAll.length == 0 && PurchaseEnquiryLine.EnquiryType == 'Materials'" :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4 style="text-align: center;"><b><i>Materials Purchase Enquiry</i> Lines</b></h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="font-size: smaller;"><b>Items Details</b></th>
                                        <th style="font-size: smaller;"><b>Required Quantity</b></th>
                                        <th style="font-size: smaller;"><b>Expected Cost — {{this.projectcurrency.substring(0, 3)}}.</b></th>
                                        <th style="font-size: smaller;"><b>Action</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center" colspan="4" style="text-align: center; color: red; font-size: 16px;"><i>No Lines To Display</i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-block btn-success waves-effect" style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.4)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">Submit The <i>Materials Purchase Enquiry</i> For Approval</button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>



        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Identify with Marker</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select the delivery location by dragging and dropping the marker on the map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="RCSelectionService" tabindex="-1" aria-labelledby="RCSelectionService" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                       <h4 style="color: rgb(0, 114, 187);"><b><i>Services Rates’ Contracts</i> Selection Panel</b></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <table class="table tg" data-component="datatable" data-locale="en" >
                            <thead>
                            <tr>
                                <th class="tg-0lax" rowspan="2">Selected <br>Country(ies) | City(ies)</th>
                                <th class="tg-0lax" colspan="5">Valid <i>Services Rates' Contracts</i></th>  
                                <th class="tg-0lax" rowspan="2" style="color: rgb(0, 114, 187);">Quantity*<br><span style="font-size: smaller; color: black;">(Of <i>Service Line Unit of Measure</i>)</span></th>
                                <th class="tg-0lax" rowspan="2">Action</th>
                            </tr>
                            <tr>
                                <th class="tg-0lax" width="15%">Vendor ID#, Name & Score</th>
                                <th class="tg-0lax">Short Description</th>
                                <th class="tg-0lax">Expiry Date</th>
                                <th class="tg-0lax">Unit Rate</th>
                                <th class="tg-0lax"><i>Service Line Unit of Measure</i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr  v-for="(item, index) in RateContractSelectionService" v-if="PurchaseEnquiryLine.Cities.includes(item.city)" :key="index">
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.city == item.city).length" v-if="index > 0 ? (item.city !== RateContractSelectionService[index-1].city) : 1">{{ item.country }} | {{ item.city }}</td>
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.reference == item.reference && PurchaseEnquiryLine.Cities.includes(subitem.city)).length" v-if="index > 0 ? (item.reference !== RateContractSelectionService[index-1].reference) : 1"><b>Vendor ID#: </b> {{ item.reference }}, <br><b>Vendor Name: </b> {{ item.vendor_name }}, <br><b>Vendor Score: </b> {{ item.vendor_score }}</td>
                                <td class="tg-0lax" :rowspan="RateContractSelectionService.filter(subitem => subitem.short_desc == item.short_desc && PurchaseEnquiryLine.Cities.includes(subitem.city)).length" v-if="index > 0 ? (item.short_desc !== RateContractSelectionService[index-1].short_desc) : 1">{{ item.short_desc }}</td>
                                <td class="tg-0lax">{{ item.exp }}</td>
                                <td class="tg-0lax">{{ item.unit_rate }}</td>
                                <td class="tg-0lax">{{ item.u_o_m }}</td>
                                
                                
                                <td class="tg-0lax"><input style="text-align: center;" class="RCAddQuan" type="number" min="0" :id="'RCServiceQ'+index" @keyup="checkQuanValue(index)" required></td>
                                <td class="tg-0lax" style="max-width: 200px;">
                                    <button disabled type="button" :id="'RCServiceBA'+index" class="btn btn-block waves-effect text-center text-wrap RCAddBtn"
                                        @click="AddToServiceList(index, 'Linked')"
                                        style="background-color: rgb(0, 114, 187); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;"
                                        onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.5)'; this.style.transform='translateY(-5px)';"
                                        onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">
                                        Link & Add To The <i>Services Purchase Enquiry</i>
                                    </button>

                                    <button hidden type="button" :id="'RCServiceBR'+index" class="btn btn-block waves-effect text-center text-wrap RCRemBtn"
                                        @click="RemoveFromServiceList(index, 1)"
                                        style="background-color: rgb(0, 114, 187); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;"
                                        onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.5)'; this.style.transform='translateY(-5px)';"
                                        onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">
                                        Delink From The <br><i>Services Purchase Enquiry</i>
                                    </button>

                                </td>

                            </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <!-- Horizontal line directly after the table -->
                    <div style="padding: 0; margin: 0;">
                        <hr style="height: 1px; background-color: lightgrey; border: none; margin: 0;">
                        <div style="display: flex; align-items: center; justify-content: space-between; margin-top: -8px;">
                            <!-- Text section on the left -->
                            <p style="margin: 0; font-size: 16px; color: rgb(0, 114, 187); padding-right: 80px; padding-top: -10px">
                                <b>*Enter the quantity of <i>Service Line Unit of Measure</i> before linking the line to a <i>Services Rates' Contract</i>.</b>
                            </p>
                            <!-- Button section on the right -->
                            <div style="display: flex; gap: 10px; padding-bottom: 20px; margin-right: 40px; padding-top: 20px">
                                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="IgnoreRCServiceSelection()" style="background-color: #d33; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">Ignore The <br><i>Services Rates' Contracts</i></button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal" style="height: 58px; background-color: #38761d; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">Done<br></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="PEDetailsModalId" tabindex="-1" aria-labelledby="PEDetailsModalId" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="PELineShow">
                    <div class="modal-header">
                        <div style="width:40%">
                            <h4 style="margin-top: 10px; margin-left: 50px; font-size: 1.4em;" class="modal-title text-ogeo" v-if="PEModalInfo.EnquiryType == 'Materials' && PEModalInfo.RateContractSelected == 'yes'">
                                <i>Materials Purchase Enquiry</i> Line ID#: {{ PEModalInfoKey + 1 }}
                                <span style="margin-left: 117px; display: block; font-size: 0.6em; color: rgb(0,0,0); font-weight: bold; margin-top: -5px;">
                                    <span style="color: white; background-color: rgb(0, 114, 187); padding-left: 10px; padding-right: 10px; border-radius: 10px; border: 2px solid white;">Linked To A <i>Materials Rates' Contract</i></span>
                                </span><br><br>
                            </h4>                    
                            <h4 style="margin-top: 10px; margin-left: 50px; font-size: 1.4em;" class="modal-title text-ogeo" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                <i>Services Purchase Enquiry</i> Line ID#: {{ PEModalInfoKey + 1 }}
                                <span style="margin-left: 117px; display: block; font-size: 0.6em; color: rgb(0,0,0); font-weight: bold; margin-top: -5px;">
                                    <span style="color: white; background-color: rgb(0, 114, 187); padding-left: 10px; padding-right: 10px; border-radius: 10px; border: 2px solid white;">Linked To A <i>Services Rates' Contract</i></span>
                                </span><br><br>
                            </h4>
                            <h4 style="margin-top: 10px; margin-left: 50px; font-size: 1.4em;" class="modal-title text-ogeo" v-if="PEModalInfo.EnquiryType == 'Materials' && PEModalInfo.RateContractSelected !== 'yes'">
                                <i>Materials Purchase Enquiry</i> Line ID#: {{ PEModalInfoKey + 1 }}
                                <br><br><br>
                            </h4>   
                            <h4 style="margin-top: 10px; margin-left: 50px; font-size: 1.4em;" class="modal-title text-ogeo" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                <i>Services Purchase Enquiry</i> Line ID#: {{ PEModalInfoKey + 1 }}
                                <br><br><br>
                            </h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                                <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:60px;height:60px;margin-left:50px;border-radius:25px" class="my-auto">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        </div>
                        <div>
                            <span style="font-size: medium; font-style: italic; color: white; background-color: rgb(252, 51, 23); margin-top: -10px; padding-left: 9px; padding-right: 9px; padding-top: 5px; padding-bottom: 5px; display: block; text-align: center;">
                               <b>----[Not Yet Submitted For Validation]----</b>
                            </span>
                        </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- Purchase Enquiry Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                        <tr>
                                                                                 
                                            <td style="border: 1px solid #ffffff; width: 75%; text-align: right; padding-right: 20px; font-weight: 700;"><b><i>Account</i> Information:</b></td>    
                                            <td style="border: 1px solid #ffffff; width: 25%; text-align: left; padding-left: 20px;">
                                                <span><b class="font-700"><i>Account</i> Name:</b> {{ PEModalInfo.project.title }}</span><br>
                                                <span><b class="font-700">Start Date:</b> {{ PEModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700">End Date:</b> {{ PEModalInfo.project.end_date | formatDateNTime }}</span><br>
                                            </td>
                                        </tr>
                                </table>
                                <table class="table sptable">
                                        
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700"><i>Purchase Enquiry</i> Line Initiated By: </b></td>
                                            <td style="padding-left: -20px;">{{ currentUser.Name }}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700"><i>Purchase Enquiry</i> Underlying Transaction: </b></td>
                                            <td style="padding-left: -20px;">{{ PEModalInfo.UnderlyingTransaction }}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700"><i>Purchase Enquiry</i> Type: </b></td>
                                            <td style="padding-left: -20px;">{{ PEModalInfo.EnquiryType }}<span v-if="PEModalInfo.EnquiryType == 'Service'">s</span></td>
                                        </tr>
                                        
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Sourcing Priority: </b></td>
                                            <td style="padding-left: -20px;">{{ PEModalInfo.SourcingPriority }}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Delivery Address Details:</b></td>
                                            <!--<td style="padding-left: 120px;"> Changed from 130px to 180px-->
                                            <td style="padding-left: -190px;">
                                                <span v-if="PEModalInfo.RateContractSelected">
                                                    <b class="font-700 font-italic">Delivery Country | City:</b> {{ PEModalInfo.Country }} | {{ PEModalInfo.Cities }}
                                                </span>
                                                <span v-else>
                                                    <b class="font-700 font-italic">Delivery Country | City:</b> {{ PEModalInfo.Country }} | {{ PEModalInfo.Country.toString() }}
                                                </span>
                                                <br>
                                                <span><b class="font-700 font-italic">Location Name:</b> {{ PEModalInfo.LocationName }}</span><br>
                                                <span><b class="font-700 font-italic">Longitude:</b> {{ PEModalInfo.Longitude }}</span><br>
                                                <span><b class="font-700 font-italic">Latitude:</b> {{ PEModalInfo.Latitude }}</span>
                                            </td>
                                        </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table class="table" v-if="PEModalInfo.EnquiryType == 'Materials'">
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.RateContractSelected == 'yes'"><b class="font-700">Are <i>Advance Payments</i> Considered For The Items <br>Sourced Under The Selected <i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">Are <i>Advance Payments</i> Considered For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ PEModalInfo.AdvancedPayment }}</td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.RateContractSelected == 'yes'"><b class="font-700">Are Collaterals Required From The Vendors Against<br> The <i>Advance Payments</i>, Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">Are Collaterals Required From The Vendors <br>Against The <i>Advance Payments</i> For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ PEModalInfo.GuaranteeNeedForAdvancePayment ? PEModalInfo.GuaranteeNeedForAdvancePayment : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.RateContractSelected == 'yes'"><b class="font-700">What Is The <i>Retention Percentage</i> For The Items <br>Sourced Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">What Is The <i>Retention Percentage</i> For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ PEModalInfo.RetentionPercentage ? PEModalInfo.RetentionPercentage + '%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.RateContractSelected == 'yes'"><b class="font-700">What Is The <i>Retention Timeframe</i>* For<br>The Items Sourced Under The Selected<br><i>Materials Rates' Contract</i> ? </b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">What Is The <i>Retention Timeframe</i>* For The Item ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ PEModalInfo.RetentionDays ? PEModalInfo.RetentionDays + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>                 
                                    <tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>
                                </table>
                                <table class="table" v-else>
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" v-if="PEModalInfo.ServiceType == 'Linked'"><b class="font-700">Are <i>Advance Payments</i> Considered, Under The <br>Selected <i>Services Rates' Contract</i>, For The <br><i>Service Line Unit of Measure</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">Are <i>Advance Payments</i> Considered For The <i>Services</i> ?</b></td>
                                        <td style="position: relative; left: -210px; padding-left: 5px;">{{ PEModalInfo.AdvancedPayment }}</td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" v-if="PEModalInfo.ServiceType == 'Linked'"><b class="font-700">Are Collaterals, Against The <i>Advance Payments</i>, <br>Needed Under The Selected <i>Services Rates' Contract</i>, <br>For The <i>Service Line Unit of Measure</i> ?</b></td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">Are Collaterals Required From The Vendors <br>Against The <i>Advance Payments</i> For The <i>Service</i>> ?</b></td>
                                        <td style="position: relative; left: -210px; padding-left: 5px;">{{ PEModalInfo.GuaranteeNeedForAdvancePayment ? PEModalInfo.GuaranteeNeedForAdvancePayment : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.ServiceType == 'Linked'"><b class="font-700">What Is The <i>Retention Timeframe</i>* Considered, <br>Under The Selected <i>Services Rates' Contract</i>, <br>For The <i>Service Line</i> ? </b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">What Is The <i>Retention Percentage</i> For The <i>Service</i>> ?</b></td>
                                        <td style="position: relative; left: -210px; padding-left: 5px;">{{ PEModalInfo.RetentionPercentage ? PEModalInfo.RetentionPercentage + '%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-if="PEModalInfo.ServiceType == 'Linked'"><b class="font-700">What Is The <i>Retention Timeframe</i>* For<br>The <i>Service Line</i> Sourced Under The Selected<br><i>Services Rates' Contract</i> ? </b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="padding-left: 20px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">What Is The <i>Retention Timeframe</i>* For The <i>Services</i> ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -210px; padding-left: 5px;">{{ PEModalInfo.RetentionDays ? PEModalInfo.RetentionDays + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>   
                                    <tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>                      
                                </table>
                            </div>
                            
                            <!-- Purchase Enquiry General Infomation Panel End -->
                           
                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryType == 'Materials'">
                                <table class="table">
                                    <tr>
                                        <td style="padding-left: 80px; width: 40%; border: none;">
                                            <b class="font-700">Documents Required From The Vendors:</b>
                                        </td>
                                        <td style="border: none;">
                                            <template v-if="PEModalInfo.RequiredDocuments && PEModalInfo.RequiredDocuments.length > 0">
                                                <ul>
                                                    <li v-for="(document, index) in PEModalInfo.RequiredDocuments" :key="index">{{ document }}</li>
                                                </ul>
                                            </template>
                                            <template v-else>
                                                No Documents Are Required From The Vendors.
                                            </template>
                                        </td>
                                    </tr>
                                    <tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>
                                </table>
                            </div>

                                    <table class="table">
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials' && PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                            <td style="padding-left: 95px; width: 40%; border: none;">
                                                <b class="font-700"><i>Library of Materials</i> <br>Item Details:</b>
                                            </td>
                                            <td style="padding-left: -60px; width: 60%; border: none;">
                                                <div style="width: 60%;">
                                                    <span v-if="!isJSON(PEModalInfo.ItemLibraryDescription.picture)">
                                                        <img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+PEModalInfo.ItemLibraryDescription.picture">
                                                    </span>
                                                    <span class="text-center" v-else v-for="(img, index) in JSON.parse(PEModalInfo.ItemLibraryDescription.picture)" :key="index">
                                                        <img style="width: 8vw; height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img">
                                                    </span>
                                                    <br><br>
                                                    <span><b class="font-700"><span v-if="PEModalInfo.ItemLibraryDescription.company.lom_prefix == null">LoM</span><span v-else>LoM-</span>{{ PEModalInfo.ItemLibraryDescription.item_group_id }}-{{ PEModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                                    <span>
                                                        <template v-for="index in 20">      
                                                            <span v-if="PEModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                                <b class="font-700 text-uppercase">{{ PEModalInfo.ItemLibraryDescription.item_template['field_'+index] }}: </b> {{ PEModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                            </span>
                                                        </template>
                                                    </span><br><br>
                                                    <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measure: </b> {{ PEModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials' && PEModalInfo.EnquiryFromItemMaster !== 'Yes'">
                                            <td style="padding-left: 95px; width: 40%; border: none;">
                                                <b class="font-700">Item <br>Description:</b>
                                            </td>
                                            <td style="padding-left: -60px; width: 60%; border: none;">
                                                <div style="width: 60%;">
                                                    <span v-if="PEModalInfo.Images.length > 0" v-for="(img, index) in PEModalInfo.Images" :key="index">
                                                        <img style="width: 7vw; height: 7vw; text-align: center;" :src="img">
                                                    </span><br><br>
                                                    <span>{{ PEModalInfo.ItemDescription }}</span><br><br>
                                                    <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ PEModalInfo.UnitOfMeasurement.toUpperCase() }}</span><br>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>
                                        <!--<tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>-->
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                            <td style="padding-left: 95px; width: 25%; border: none;">
                                                <b class="font-700">Quantity:</b>
                                            </td>
                                            <td style="padding-left: 35px; width: 60%; border: none;">  
                                                <div style="width: 60%;">
                                                    <b class="font-700">{{ PEModalInfo.Quantity }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>

                                    <table class="table">
                                        <tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials' && PurchaseEnquiryLine.RateContractSelected == 'yes'">
                                            <td style="padding-left: 90px; width: 33%; vertical-align: top;">
                                                <b style="color: rgb(0, 114, 187); font-weight: bold;">Selected <br><i>Materials Rates’ Contract</i> <br>Information:</b>
                                            </td>
                                            <td v-if="PEModalInfo.RateContractSelected" style="width: 45%; vertical-align: top;">
                                                <ul style="padding-left: 0; color: rgba(0, 0, 0, 1); list-style-type: disc;">
                                                    <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Name | ID#:</b> {{ PEModalInfo.RateContractSelected.vendor_name }}</span><br></li>
                                                    <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Score:</b> {{ PEModalInfo.RateContractSelected.vendor_score }}</span><br></li>
                                                    <li style="margin-bottom: 1px;"><span><b class="font-700">Start Date | End Date:</b> {{ PEModalInfo.RateContractSelected.date }} </span><br></li>
                                                    <li style="margin-bottom: 1px;"><span><b class="font-700">Unit Rate:</b> {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate) }} {{ PEModalInfo.project.currency }} per <b class="font-700">{{ PEModalInfo.u_o_m }}</b></span><br></li>
                                                    <li style="margin-bottom: 1px;"><span><b class="font-700">Lead Time:</b> {{ PEModalInfo.RateContractSelected.lead_time }}</span><br></li>
                                                    <li style="margin-top: 1px;"><span><b class="font-700"><i>Volume-discount Arrangement</i> | <i>Minimal Procured Value</i>:</b></span></li>
                                                    <li style="margin-top: 1px;"><span><b class="font-700">Applicable discount rate for the Unit Rate:</b></span></li>
                                                    <li style="list-style-type: none; padding: 0; margin: 0.5px 0; color: rgb(0, 114, 187);"><hr style="margin: 0;"></li>
                                                    <li style="margin-top: 1px;"><span><b class="font-700">Number Of <i>Purchase Orders</i> <br>Awarded Under This <i>Materials Rates' Contract</i>:</b></span></li>
                                                    <li style="margin-top: 1px;"><span><b class="font-700">Turnover (Purchase Orders) <br>Under This <i>Materials Rates' Contract</i>:</b></span></li>
                                                </ul>
                                            </td>
                                            <td v-if="PEModalInfo.RateContractSelected" style="padding-left: 85px; width: 20%; vertical-align: top;">
                                                <b class="font-700 text-ogeo"></b>
                                            </td>
                                            <td colspan="2" v-else-if="PEModalInfo.EnquiryFromItemMaster == 'No'">
                                                This Isn't An Item From The <i>Library of Materials</i>. There Are No <i>Materials Rates’ Contracts</i> For It.
                                            </td>
                                            <td style="padding-left: 105px;" colspan="2" v-else>
                                                No <i>Materials Rates’ Contract</i> Was Selected For This Item.
                                            </td>
                                        </tr>
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                            <td style="padding-left: 95px;">
                                                <b class="font-700">
                                                    Expected Cost:<br>
                                                    <span style="font-size: 85%;">
                                                        As per the selected <i>Materials Rates' Contract</i>.
                                                    </span>
                                                </b>
                                            </td>
                                            <td colspan="2" v-if="PEModalInfo.RateContractSelected && PEModalInfo.RateContractSelected.unit_rate">
                                                <b class="modal-title text-ogeo" style="font-weight: 900; margin-left: -1000px;">
                                                    {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate * PEModalInfo.UnitOfMeasurement) }} {{ projectcurrency }}
                                                </b>
                                            </td>
                                            <td colspan="2" v-else-if="PEModalInfo.EnquiryFromItemMaster == 'No'">
                                                <span style="margin-left: -1000px;">Not Applicable As It Is A Free-Text Described Item (Not An Item From The <i>Library of Materials</i>.)</span>
                                            </td>
                                            <td colspan="2" v-else>
                                                <span style="margin-left: -1000px;">Not Available As No <i>Materials Rates' Contract</i> Was Selected For This Item.</span>
                                            </td>
                                        </tr>

                                        <!--<tr><td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td></tr>-->
                                        <tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                            <td style="padding-left: 95px;"><b class="font-700">Additional Notes:</b></td>
                                            <td v-if="!PEModalInfo.PELineNote"><span style="margin-left: -1000px;">No Additional Notes.</span></td>
                                            <td v-else><span style="margin-left: -1000px;">{{ PEModalInfo.PELineNote }}</span></td>
                                        </tr>
                                        <!--<tr>
                                            <td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0;"></td>
                                        </tr>-->
                                        <!--<tr v-if="PEModalInfo.EnquiryType == 'Materials'">
                                            <td style="padding-left: 80px;"><b class="font-700">Awardee Vendor:</b></td>
                                            <td>Not Yet Awarded.</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0;"></td>
                                        </tr>-->
                                    </table>
                        

                                
                                
                    
                            <!-- Materials Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryType == 'Service'">
                                <table class="table">
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td style="padding-left: 80px; width: 20%; vertical-align: top;">
                                            <b style="color: rgb(0, 114, 187); font-weight: bold;">Selected <br><i>Services Rates’ Contract</i> <br>Information:</b>
                                        </td>
                                        <td>
                                            <ul style="padding-left: 0; color: rgba(0, 0, 0, 1); list-style-type: disc;">
                                                <li style="margin-bottom: 1px;"><span><b class="font-700"><i>Short Description:</i></b> {{ PEModalInfo.ServiceDescription }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Name | ID#:</b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].vendor_name }} | {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].reference }}</span><br>                                                </li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Score:</b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].vendor_score }}</span><br></li>                          
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Start Date | End Date:</b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].strt }} | {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].exp }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Unit Rate:</b> {{ RateContractSelectionService[ PEModalInfo.ServiceRC_id ].unit_rate }}</span><br></li>
                                                <!-- <li style="margin-bottom: 1px;"><span><b class="font-700">Lead Time:</b> </span><br></li> --><li style="list-style-type: none; padding: 0; margin: 0.5px 0; color: rgb(0, 114, 187);"><hr style="margin: 0;"></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Number Of <i>Purchase Orders</i> <br>Awarded Under This <i>Services Rates' Contract</i>:</b></span></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Turnover (Purchase Orders) <br>Under This <i>Services Rates' Contract</i>:</b></span></li>
                                            </ul>
                                         </td>   
                                        <td style="width: 20%; vertical-align: top; text-align: center;">
                                                <button 
                                                    style="background: rgb(0, 114, 187); border: 3px solid rgb(0, 114, 187); color: white; height: 80px; width: 250px; text-align: center; display: inline-block; font-size: 16px; cursor: pointer; border-radius: 12px; transition: all 0.3s ease; outline: none; transform-origin: center; box-shadow: inset 0 0 0 1px white, inset 0 0 0 calc(3mm + 1px) rgb(0, 114, 187), 4px 12px 20px rgba(0, 0, 0, 0.5);" 
                                                    onmouseover="this.style.background='rgba(0, 114, 187, 0.8)'; this.style.transform='scale(1.05)'; this.style.boxShadow='inset 0 0 0 1px white, inset 0 0 0 calc(3mm + 1px) rgb(0, 114, 187), 6px 16px 24px rgba(0, 0, 0, 0.6)';" 
                                                    onmouseout="this.style.background='rgb(0, 114, 187)'; this.style.transform='scale(1)'; this.style.boxShadow='inset 0 0 0 1px white, inset 0 0 0 calc(5mm + 1px) rgb(0, 114, 187), 4px 12px 20px rgba(0, 0, 0, 0.5)';" 
                                                    onmousedown="this.style.background='rgba(0, 114, 187, 0.6)'; this.style.transform='scale(0.95)'; this.style.boxShadow='inset 0 0 0 1px white, inset 0 0 0 calc(3mm + 1px) rgb(0, 114, 187), 2px 8px 16px rgba(0, 0, 0, 0.4)';" 
                                                    onmouseup="this.style.background='rgba(0, 114, 187, 0.8)'; this.style.transform='scale(1.05)'; this.style.boxShadow='inset 0 0 0 1px white, inset 0 0 0 calc(3mm + 1px) rgb(0, 114, 187), 6px 16px 24px rgba(0, 0, 0, 0.6)';"
                                                    onclick="document.title = 'Services Rates\' Contract'; window.print()">
                                                    Click To View The<br><i><b>Services Rates’ Contract</b></i>
                                                </button>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td width="40%"><b class="font-700">Service Rates’ Contract Description: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.ServiceDescription }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">                                        
                                        <td><b class="font-700"> Will these Services Be One-Off Or Will They Be Delivered Over A Period Of Time: </b></td>
                                        <td>{{ PEModalInfo.ServiceOneOff }}</td>
                                    </tr> 
                                    <tr v-if="PEModalInfo.ServiceOneOff == 'Over A Period Of Time'">
                                        <td><b class="font-700">The Term Of The Service Contract, In Days: </b></td>
                                        <td>{{ PEModalInfo.ServiceTerm }} Days</td>
                                    </tr> 
                                    
                                    <!-- <tr v-if="PEModalInfo.EnquiryType == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Supporting Documents:</b></th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul style="display: inline-flex" v-for="(his, index) in PEModalInfo.ImagesSD">
                                                            <a :href="window.URL.createObjectURL(blob)" @click=""><li>{{ his }}</li></a>
                                                        </ul>
                                                    </td>
                                                </tr>    
                                            </table>
                                        </td>
                                    </tr> -->
                                    <tr class="vendorIns" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instructions To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.TendererContractHeaders">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                    <td class="vendorInsC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor Offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.VendorOfferContent">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}</td>
                                                    <td class="vendorInsC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="" id="SCT" v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Contract Draft</b></th>
                                                </tr>
                                                <tr>
                                                    <th><b class="font-700 SCTH">Header</b></th>
                                                    <th id="SCTC"><b class="font-700 SCTC">Content</b></th>
                                                </tr>
                                                <tr v-for="header in PEModalInfo.ContractHeaders">
                                                    <td class="text-uppercase font-700 SCTH col-lg-2">{{ header.name }}</td>
                                                    <td class="SCTC">{{ header.contant }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-if="(PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked')">
                                        <td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td>
                                    </tr>
                                    <tr v-if="(PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked')">
                                        <td style="padding-left: 80px;" width="20%"><b style="color: rgb(0, 114, 187); font-weight: bold;"><i>Service Line Unit of Measure</i>:</b></td>
                                        <td>
                                            <span style="font-weight: 900;">{{ PEModalInfo.UnitOfMeasurement }}</span><br>
                                        </td>
                                    </tr>                      
                                    <tr v-if="(PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked')">
                                        <td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td>
                                    </tr>
                                    <tr v-if="(PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked')">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Quantity:</b></td>
                                        <td>
                                            <span style="font-weight: 900;"><b>{{ PEModalInfo.Quantity }}</b></span><br>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td colspan="3" style="border-top: 1px solid #f0f0f0; padding: 0; width: 100%;"></td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Linked'">
                                        <td style="padding-left: 80px;">
                                            <b class="font-700">
                                                Expected Cost:<br>
                                                <span style="font-size: 85%;">
                                                    As per the selected <i>Services Rates' Contract</i>.
                                                </span>
                                            </b>
                                        </td>
                                        <td colspan="2">
                                            <b class="modal-title text-ogeo" v-if="RateContractSelectionService[PEModalInfo.ServiceRC_id]" style="font-weight: 900;">
                                                {{ formatPrice(RateContractSelectionService[PEModalInfo.ServiceRC_id].unit_rate * PEModalInfo.Quantity) }} {{ projectcurrency }}
                                            </b>
                                            <span v-else>
                                                Not Available As No <i>Services Rates' Contract</i> Was Selected For This Line.
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Type Of Service Contract: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.TypeOfServicesContract }}</span><br>
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryType == 'Service' && PEModalInfo.ServiceType == 'Unlinked'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Commercial Offer Format: </b></td>
                                        <td>
                                            <span>{{ PEModalInfo.VendorCommercialOffer }}</span><br>

                                        </td>
                                    </tr>
                                    <!-- <tr v-if="PEModalInfo.EnquiryType == 'Service'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr> -->      
                                </table>
                            </div>
                        </div>
                            <!-- Services Information Panel End -->

                            <!-- List of Materials Valid Rates' Contracts -->
                            <div class="col-lg-12" v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                <div style="background-color: rgba(0, 114, 187, 0.75); padding: 10px 0; text-align: center; width: 100%;">
                                    <h4 style="margin: 0; color: white;">
                                        Valid <i>Materials Rates’ Contracts</i> For The Item
                                    </h4>
                                </div> 

                                <table class="display table table-bordered" style="width: 80vw !important; margin-bottom: 0; padding-bottom: 0;">
                                    <thead style="width: 80vw !important;">
                                        <tr>
                                            <th><b>Vendor Name | ID#</b></th>
                                            <th><b>Country | Cities</b></th>
                                            <th><b>Unit Rate</b></th>
                                            <th><b>Lead Time</b></th>
                                            <th><b>Start Date | End Date</b></th>
                                            <th><b><i>Volume-discount Arrangement</i> | <i>Minimal Procured Value</i></b></th>
                                            <th><b>Discount Rate To The Unit Rate</b></th>
                                            <th><b><i>Advance Payments</i> Arrangement</b></th>
                                            <th><b><i>Retention</i> Arrangement</b></th>
                                            <th><b>Total Turnover <br>Till Date</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                            <td>{{ item.vendor_name }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.unit_rate }} USD</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>

                        </div>
                        <!-- List of Servicess Valid Rates' Contracts -->
                            <div class="col-lg-12" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                <div style="background-color: rgba(0, 114, 187, 0.75); padding: 10px 0; text-align: center; width: 100%;">
                                    <h4 style="margin: 0; color: white;">
                                        Valid <i>Services Rates’ Contracts</i> For The Cities
                                    </h4>
                                </div> 

                                <table class="display table table-bordered" style="width: 80vw !important; margin-bottom: 0; padding-bottom: 0;">
                                    <thead style="width: 80vw !important;">
                                        <tr>
                                            <th><b>Countries | Cities</b></th>
                                            <th><b>Short Description</b></th>
                                            <th><b>Vendor Name | ID#</b></th>
                                            <th><b>Vendor Score</b></th>                                            
                                            <th><b>Start Date | End Date</b></th>
                                            <th><b>Service Line Unit of Measure</b></th> 
                                            <th><b>Unit Rate</b></th>             
                                            <th><b>Total Turnover <br>Till Date</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                            <td>{{ item.vendor_name }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.unit_rate }} USD</td>
                                            <td>{{ item.unit_rate }} USD</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>

                        </div>
                        </div>
                    
                    <div class="col-lg-12">
                            <hr style="border-top: 0.2px solid #f0f0f0; padding: 0;"> 
                        </div>
                        <div class="col-lg-12" style="margin-top: -0.5rem;">
                            <div class="d-inline-block pull-left">
                                <span>
                                    <b>Document Generated By:</b> <span style="color: rgb(0, 0, 0);">{{ currentUser.Name }}</span>
                                    <div class="d-flex justify-content-left" style="margin-top: 0.5rem;">
                                        <button class="btn btn-success waves-effect text-center" 
                                        style="box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                        transition: box-shadow 0.3s ease, transform 0.3s ease;" 
                                        onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" 
                                        onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';" 
                                        onclick="document.title = 'Materials Purchase Enquiry Line Information - Not Yet Submitted For Validation';window.print()">Download PDF
                                    </button>
                                    </div>
                                </span>
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                        </div>
                            <br>
                        <div class="col-lg-24; text-center">
                            <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                            <br>
                            <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                
                                <br><br>
                        </div>

                    </div>
            </div>
        </div>

    </div>


            
</template>

<script>
    
    //import { CustomJs } from '../../helpers/custom.js';
    import validate from 'validate.js';

    export default {
        name: 'new-purchase-enquiry',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please enter the Retention Percentage')); 
                }
                
                if ((value + '').replace(/^-?\d*\.?|0+$/g, '').length > 2) {
                    callback(new Error('Please enter no more than 02 decimals'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please select the Retention Timeframe')); 
                } else {
                    callback();
                }
                
            };
            return {

                hostName: window.location.protocol+'//'+window.location.hostname,
                jsDoc: document,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
			    cities: [],
                checkCity: false,
                JobNumbers: [],
                FoundItems: {},
                docFilelist: [],
                ReqDocTmp: [],
                OtherDocTmp: '',
                OtherDocTmpHolder: [],
                ProjectDetailsLoaded: false,
                ItemRateContractDetails: [],
                RateContractDataLoaded: false,
                RCSelectionService: false,
                IgnoreRCSelectionService: false,
                RCServiceAvailableCheck: false,
                RCServiceAvailableCheckCount: 0,
                NoRCServiceAvailableCheckCount: 0,
                RCServiceAvailableCheckCities: [],
                NoRCServiceAvailableCheckCities: [],
                NoRCServiceSelectedCities: [],
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                PELineShow: false,
                PElineUp: 'N',
                PElineFlag: false,
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
                      country: 'Afghanistan',
                      city: 'Kabul',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                RateContractSelectionService: [
					{
			          reference: 1,
			          vendor_name: 'Gucci',
			          vendor_email: 'vendor@gucci.com',
			          vendor_score: '25',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+15 years) Software Development Engineer',
			          unit_rate: '$3000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'Yes',
                      rtp: 90,
                      stpD: 180,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 50,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '95',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+5 years) SEO Specialist',
                      unit_rate: '$5000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Herat',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'Yes',
                      rtp: 40,
                      stpD: 130,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 50,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '95',
			          short_desc: 'Manpower Supply Arrangement',
                      u_o_m: '10 hours of 01 experienced (+5 years) Market Specialist',
                      unit_rate: '$1000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Kandahar',
			          rate_contract_reference: 'Gucci',
                      adp: 'Yes',
                      adpG: 'No',
                      rtp: 60,
                      stpD: 150,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
					{
			          reference: 10,
			          vendor_name: 'Gucci',
                      vendor_email: 'vendor@gucci.com',
			          vendor_score: '75',
                      short_desc: 'Retained Legal Services Agreement',
                      u_o_m: '10 hours of 01 experienced (+5 years) LAW Specialist',
                      unit_rate: '$4000',
			          lead_time: '1 Day',
                      country: 'Afghanistan',
                      city: 'Kabul',
			          rate_contract_reference: 'Gucci',
                      adp: 'No',
                      adpG: 'No',
                      rtp: 0,
                      stpD: null,
                      strt: '17.June.2020',
			          exp: '17.June.2050',
			        },
                    
				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10.Jan.2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    ServiceOneOff: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    ServiceRC_id: null,
                    StoreItemrequest: "No",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    ServiceDescription: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    TypeOfServicesContract: '',
                    ContractHeaders:[],
                    TendererContractHeaders:[],
                    VendorOfferContent:[],
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                    ImagesSD: [],

                },
                minVal: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                PEModalInfoKey: "",
                PEModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true, message: "Please select an Account", trigger: ["blur"]
                    }],
                    Country: [{
                        required: true, message: "Please select a country from the list", trigger: ["blur"]
                    }],
                    Cities: [{
                        required: true, message: "Please select a city from the list", trigger: ["blur"]
                    }],
                    TendererContractHeaders: [{
                        required: true, message: "At least one header and its content are required", trigger: ["blur"]
                    }],
                    VendorOfferContent: [{
                        required: true, message: "At least one header and its content are required", trigger: ["blur"]
                    }],
                    ContractHeaders: [{
                        required: true, message: "At least one header and its content are required", trigger: ["blur"]
                    }],
                    ServiceOneOff: [{
                        required: true,
                        message: "Please select",
                        trigger: "blur"
                    }],
                    ServiceTimePeriod: [{
                        required: true,
                        message: "Please select",
                        trigger: "blur"
                    }],
                    VendorCommercialOffer: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    VendorCommercialFormat: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    TypeOfServicesContract: [{
                        required: true,
                        message: "Please Type Of Services Contract",
                        trigger: "blur"
                    }],
                    UnderlyingTransaction: [{
                        required: true,
                        message: "Please enter an underlying transaction reference",
                        trigger: "blur"
                    }],
                    ContractHeaders: [{
                        required: true,
                        message: "Please Header",
                        trigger: "blur"
                    }],
                    Images: [{
                        required: true,
                        message: "Please Select Image",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: true,
                        message: "Please Select a sourcing priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please select a Purchase Enquiry type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please enter a service description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please select an item from the Library of Materials",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please enter an item free-text detailed description",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please enter the required quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please enter the Unit of Measure",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please select the Term of Service",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select 'Advance Payment' arrangements",
                        trigger: "change"
                    }],
                    GuaranteeNeedForAdvancePayment: [{
                        required: true,
                        message: "Please select the 'Advance Payment' arrangements",
                        trigger: "change"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please select the Retention Timeframe", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please select a document",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please enter the delivery location longitude",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please enter the delivery location latitude",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: false,
                        message: "Please enter the location name",
                        trigger: "blur"
                    }]
                },
                showQuestionRateMaterial: false,
                RateContractPurcase: [],
                TotalExpectedPrice: '',
                contractheader: {
                    name: '',
                    contant: '',
                },
                tenderercontractheader: {
                    name: '',
                    contant: '',
                },
                vendoroffercontent: {
                    name: '',
                    contant: '',
                },
                projectcurrency: '',
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            checkQuanValue(e) {
                if($('#RCServiceQ'+e).val() == 0)
                    $('#RCServiceBA'+e).attr('disabled', true);
                else
                    $('#RCServiceBA'+e).attr('disabled', false);
            },
            checkChildren(){
                if($('#unlinkedServiceList').children().length == 0 || !$('#unlinkedServiceList tr').hasClass('peServiceLineUnlinked'))
                    return true;
                else
                    return false;
            },
            OtherDocsVisibility(){
                if(this.ReqDocTmp.includes('Other')){
                  this.$refs.reqDocs.blur();
                  $('.reqDocs').hide();
                }
            },
            ResetRateContractDropdown()
            {
                this.PurchaseEnquiryLine.RateContractSelected = ''
            },
            checkProjectExpiry(e){
                var val = axios.get('/api/data/get_project_expiry/' + e)
                    .then((response) => {
                        if(new Date(response.data[0]) > new Date())
                            return true;
                        else{
                            this.PurchaseEnquiryLine.JobNumber = '';
                            Swal('The Selected Account Has Expired', 'Using this Account will be possible if those holding the role of <em>Account</em> Budgets Initiator extend its end date.', 'error');
                            return false;    
                        }
                    })
                return val;
            },
            checkCityRC(e){

                this.checkCity = false;
                this.RateContractSelection.forEach(item => {
                    if(item.city == e)
                        this.checkCity = true;
                })
            },
            RCServiceCityCheck(){
                this.RCServiceAvailableCheckCount = 0;
                this.NoRCServiceAvailableCheckCount = 0;
                this.RCServiceAvailableCheckCities = [];
                this.NoRCServiceAvailableCheckCities = [];
                this.NoRCServiceSelectedCities = [];
                if(this.PurchaseEnquiryLine.Cities)
                    this.PurchaseEnquiryLine.Cities.forEach( item => this.RateContractSelectionService.some(subitem => item.includes(subitem.city) ? this.RCServiceAvailableCheckCount++ : false) ? (this.RCServiceAvailableCheckCities.push(item)) : (this.NoRCServiceAvailableCheckCount++, this.NoRCServiceAvailableCheckCities.push(item)) )
                if(this.RCServiceAvailableCheckCount > 0)
                    this.RCServiceAvailableCheck = true, this.NoRCServiceSelectedCities = Array.from(this.RCServiceAvailableCheckCities);
                else
                    this.RCServiceAvailableCheck = false;    
            },
            getProjectCurrency(e)
            {
                if(this.checkProjectExpiry(e))
                    axios.get('/api/data/get-project-currency/' + e)
                    .then((response) => {   
                        this.projectcurrency = response.data[0]
                    })
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.PurchaseEnquiryLine.ContractHeaders.push({
                        name: this.contractheader.name,
                        contant: this.contractheader.contant,
                    })
                    this.contractheader.name = ''
                    this.contractheader.contant = ''
                } else {
                    Swal('Error', 'This Contract Header Already Exists.' , 'error'); 
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.ContractHeaders, index);
            },
            existContractHeader() {
                if (this.PurchaseEnquiryLine.ContractHeaders.find(item => item.name === this.contractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addVendorOfferContent() {
                if (this.existVendorOfferContent() == true) {
                    this.PurchaseEnquiryLine.VendorOfferContent.push({
                        name: this.vendoroffercontent.name,
                        contant: this.vendoroffercontent.contant,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.contant = ''
                } else {
                    Swal('Error', 'This Header/Content already exists.' , 'error'); 
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.PurchaseEnquiryLine.VendorOfferContent, index);
            },
            existVendorOfferContent() {
                if (this.PurchaseEnquiryLine.VendorOfferContent.find(item => item.name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.PurchaseEnquiryLine.TendererContractHeaders.push({
                        name: this.tenderercontractheader.name,
                        contant: this.tenderercontractheader.contant,
                    })
                    this.tenderercontractheader.name = ''
                    this.tenderercontractheader.contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.TendererContractHeaders, index);
            },
            existTendererContractHeader() {
                if (this.PurchaseEnquiryLine.TendererContractHeaders.find(item => item.name === this.tenderercontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addOtherDocs() {
                if (this.existOtherDocs() == true) {
                    this.OtherDocTmpHolder.push({name: this.OtherDocTmp})
                    this.OtherDocTmp = ''
                } else {
                    Swal('Error', 'This Document Already Exists.' , 'error'); 
                }
            },
            existOtherDocs() {
                if (this.OtherDocTmpHolder.find(item => item.name === this.OtherDocTmp)) {
                    return false;
                } else {
                    return true;
                }
            },
            delOtherDocs(index) {
                this.$delete(this.OtherDocTmpHolder, index);
            },
            ShowPopUpIgnoreRateContract()
            {
                if(this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract == "No")
                {
                    Swal('Warning', 'Please Note That You Have Ignored A <i>Materials Rates’ Contracts</i> With A Lower Unit Rate.' , 'error'); 
                    $(".swal2-tital").css('background-color', '#000');
                }
            },
            checkRateContract()
            {
                event.preventDefault();

                axios.post('/api/data/check_item_rate_contract_details', this.PurchaseEnquiryLine.ItemNumber)
                    .then((response) => {
                        if(response.data)
                        {
                            this.showQuestionRateMaterial = false
                        }
                        else
                        {
                            this.showQuestionRateMaterial = true
                        }
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact your system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            checkLowestRateContract()
            {

                var numbers = [25, 30, 10];
							
                var sorted = numbers.slice().sort(function(a, b) {
                return a - b;
                });

                var smallest = sorted[0],                      
                    secondSmallest = sorted[1],                
                    secondLargest = sorted[sorted.length - 2], 
                    largest  = sorted[sorted.length - 1];
                if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate > smallest)
                {
                    Swal('Warning', 'Please Note That You Have Ignored A <i>Materials Rates’ Contract</i> With A Lower Unit Rate.' , 'error'); 
                }

            },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.PurchaseEnquiryLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                        //console.log(this.FoundItems);
                    })
            },
            EnquiryTypeSelection: function(){
                
                if(this.PurchaseEnquiryLine.EnquiryType == "Materials"){
                    $('#Purchase_Enqiury_List').removeClass('animated fadeOutRight');
                    $('#Purchase_Enqiury_List').addClass('animated fadeInRight');
                    $('#Purchase_Enqiury_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                } else if(this.RCSelectionService) {
                    $('#Purchase_Enqiury_List').removeClass('animated fadeOutRight');
                    $('#Purchase_Enqiury_List').addClass('animated fadeInRight');
                    $('#Purchase_Enqiury_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                } else {
                    $('#Purchase_Enqiury_List').addClass('animated fadeOutRight');
                    setTimeout(function() {
                        $('#Purchase_Enqiury_Form').removeClass('col-lg-7').addClass('col-lg-12');
                    }, 100);

                }
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RateContractSelected = {};
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.PurchaseEnquiryLine.TypeOfServicesContract = ""
                this.PurchaseEnquiryLine.VendorCommercialOffer = ""
            },
            handleChangeSD(file, fileList){
                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isPDF = file.raw.type === 'application/pdf';
                const isDOC = file.raw.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isXLSX = file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                const isLt2M = file.raw.size / 1024 / 1024 < 2;

                if ((isJPG || isPNG || isPDF || isDOC || isXLSX) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                     console.log(image_array);
                    this.PurchaseEnquiryLine.ImagesSD = image_array;
                }
                else {
                    
                    
                    this.$message.error('Document can only be JPG, PNG, PDF or Doc format with size not exceeding 1MB!!');
                    fileList.splice(-1);
                    return false;
                }
                  
            },
            handleExceedSD: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 documents.'  , 'error'); 
            },
            handleRemoveSD(file, fileList) {
                if(!fileList){
                    var fileList = this.$refs.ImagesSD.uploadFiles;
                    fileList.forEach((value, index, object) => {
                    if (value.uid === file.uid) {
                        fileList.splice(index, 1)
                    }
                    })
                }
                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                const reader=new FileReader();
                reader.readAsDataURL(fileList[i].raw);
                reader.onload = function(e) {
                        var rawLog = reader.result;
                          image_array.push(rawLog);
                    };
                
             
                  
                }
                  this.PurchaseEnquiryLine.ImagesSD=image_array;
               
              
            },

            handleChange(file,fileList){

                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isLt2M = file.raw.size / 1024 / 1024 < 2;
                
                if (fileList.length == 4)
                    $(".el-upload--picture-card").hide();
                else
                    $(".el-upload--picture-card").show();

                if ((isJPG || isPNG) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                    //  console.log(image_array);
                    this.PurchaseEnquiryLine.Images=image_array;
                }
                else {
                    
                    fileList.shift();
                    fileList.shift();
                    this.imageUrl = "";
                    
                    this.$message.error('Avatar picture can only be JPG or PNG format with a size not exceeding 1MB!!');
                    return false;
                }
                  
            },
            handleExceed: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 pictures of the material.'  , 'error'); 
            },
            handleRemove(file, fileList) {
                var image_array=[];
                for(var i=0;i<fileList.length;i++){
                    
                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                        var rawLog = reader.result;
                        image_array.push(rawLog);
                    };
                    
                    
                    
                }
                this.PurchaseEnquiryLine.Images=image_array;
                setTimeout(function (){
                    $(".el-upload--picture-card").show();
                },1100);
              
            },
            AddToServiceList(e, type) {
                if(type === 'Linked'){
                    Swal.fire({
                                title: 'Are You Sure?',
                                html: "This <i>Services Purchase Enquiry</i> Line Will Be Linked To This <i>Services Rates' Contract</i>.",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#38761d',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No'
                            }).then(result => {
                            if(result.value){
                                $('#RCServiceBA'+e).attr("disabled", true);
                                $('#RCServiceBA'+e).hide();
                                $('#RCServiceBR'+e).attr("hidden", false);
                                $('#RCServiceQ'+e).attr("disabled", true);
                                let quantity = $('#RCServiceQ'+e).val();
                                this.RCSelectionService = true;
                                // this.NoRCServiceSelectedCities.splice(this.NoRCServiceSelectedCities.indexOf(this.RateContractSelectionService[e].city), 1)
                                var TempLine = {};
                                Object.assign(TempLine,this.PurchaseEnquiryLine);
                                TempLine.Quantity = quantity;
                                TempLine.ServiceRC_id = e;
                                TempLine.Country = this.RateContractSelectionService[e].country;
                                TempLine.Cities = this.RateContractSelectionService[e].city;
                                TempLine.ServiceDescription = this.RateContractSelectionService[e].short_desc;
                                TempLine.UnitOfMeasurement = this.RateContractSelectionService[e].u_o_m;
                                TempLine.AdvancedPayment = this.RateContractSelectionService[e].adp;
                                TempLine.GuaranteeNeedForAdvancePayment = this.RateContractSelectionService[e].adpG;
                                TempLine.RetentionPercentage = this.RateContractSelectionService[e].rtp;
                                TempLine.RetentionDays = this.RateContractSelectionService[e].stpD;
                                TempLine.ServiceType = type;
                                this.PurchaseEnquiryAll.push(TempLine);
                                this.EnquiryTypeSelection();
                            }
                        })
                        
                    
                }
                else {
                    var TempLine = {};
                    Object.assign(TempLine,this.PurchaseEnquiryLine);
                    TempLine.ServiceType = type;
                    TempLine.ServiceRC_id = null;
                    TempLine.Cities = Array.from(this.NoRCServiceAvailableCheckCities);
                    this.PurchaseEnquiryAll.push(Object.assign({}, TempLine));
                }



            },
            RemoveFromServiceList(e, source) {
                if(source == 1)
                    Swal({
                    title: 'Are you sure?',
                    text: "This Services Purchase Enquiry Line Will Be Unlinked From The Services Rates' Contract.",
                    type: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#f10623',
                    cancelButtonColor: '#008000',
                    confirmButtonText: 'Yes, Remove It!'
                    }).then((result) => {
                        if (result.value) {
                            let key = this.PurchaseEnquiryAll.findIndex(item => item.ServiceRC_id === key);
                            this.PurchaseEnquiryAll.splice(key, 1);
                            $('#RCServiceBA'+e).attr("disabled", false);
                            $('#RCServiceQ'+e).attr("disabled", false);
                            $('#RCServiceBR'+e).attr("hidden", true);
                            $('#RCServiceBA'+e).show();
                            if(!this.PurchaseEnquiryAll.some(item => item.ServiceRC_id !== null))
                                this.RCSelectionService = false, this.EnquiryTypeSelection();
                        }
                    })
                else {
                    
                        $('#RCServiceBA'+e).attr("disabled", false);
                        $('#RCServiceQ'+e).attr("disabled", false);
                        $('#RCServiceBR'+e).attr("hidden", true);
                        $('#RCServiceBA'+e).show();
                }

                
            },
            AddItem: function() {

                // if(this.CompanySetup.lom_manditory == 'LibrariesNFreeText'){
                //     if(this.PurchaseEnquiryLine.ItemNumber == "" && this.PurchaseEnquiryLine.ItemDescription == ""){
                //         Swal('Missing Item Information', 'Please Select an item from the list or enter item description', 'error');
                //         return false;
                //     }
                // }

                



                // if(this.PurchaseEnquiryLine.Latitude == ""){
                //     Swal('Missing Map Location', 'Please Select a Location on Map to create PE Line', 'error');
                //     return false;
                // }

                this.$refs.PurchaseEnquiryForm.validate((validation, flag) => {




                    if(validation){
                        this.ReqDocTmp.map(item => {
                            if(item !== 'Other')
                                this.PurchaseEnquiryLine.RequiredDocuments.push(item)
                                })
                        this.OtherDocTmpHolder.map(item => {
                                this.PurchaseEnquiryLine.RequiredDocuments.push(item.name)
                                })
                        this.PElineUp = 'Y';
                        // Getting Item Description of the selected item
                        if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == "Yes"){
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.PurchaseEnquiryLine.ItemNumber);
                            this.PurchaseEnquiryLine.ItemLibraryDescription = selectedItem;
                          
                        }

                        if(!this.PurchaseEnquiryLine.RateContractSelected.unit_rate && this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                        {
                            Swal({
                                title: 'Are you sure?',
                                text: "There Is At Least One Materials Rates’ Contract For This Item. Are You Sure You Don't Want To Select One?",
                                type: 'error',
                                showCancelButton: true,
                                cancelButtonText: "Yes, let me proceed without selecting a <i>Materials Rates’ Contract</i> !",
                                confirmButtonColor: '#008000',
                                cancelButtonColor: '#f10623',
                                confirmButtonText: "No, let me select a <br><i>Materials Rates’ Contract</i> !"
                            }).then((result) => {
                                if (result.value) {
                                    return false;
                                }
                                else{
                                    /* Add Setupname */
                                    //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                    this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                    if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                    {
                                        axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                        .then(function(response){
                                            if(response.data)
                                            {
                                                this.RateContractPurcase = response.data
                                            }
                                        })
                                        if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                        {
                                            Swal('Selected Materials Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                        }
                                    }
                                    // console.log(this.PurchaseEnquiryAll);
                                    this.PurchaseEnquiryLine.JobNumber = "";
                                    this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                                    this.PurchaseEnquiryLine.Cities = ""
                                    this.PurchaseEnquiryLine.Country = ""
                                    this.PurchaseEnquiryLine.AdvancedPayment = "";
                                    this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    this.PurchaseEnquiryLine.RetentionDays = ""
                                    this.PurchaseEnquiryLine.RateContractSelected = {};
                                    this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                    this.PurchaseEnquiryLine.ItemNumber = "";
                                    this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                    this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                    this.PurchaseEnquiryLine.ItemDescription = "";
                                    this.PurchaseEnquiryLine.Quantity = "";
                                    this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                    this.PurchaseEnquiryLine.ServiceDescription = "";
                                    this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                                    this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    this.PurchaseEnquiryLine.RequiredDocuments = [];
                                    this.ReqDocTmp = [];
                                    this.OtherDocTmp = "";
                                    this.OtherDocTmpHolder = [];
                                    this.PurchaseEnquiryLine.PELineNote = "";
                                    this.PurchaseEnquiryLine.Longitude = "";
                                    this.PurchaseEnquiryLine.Latitude = "";
                                    this.PurchaseEnquiryLine.LocationName = "";
                                    
                                    // this.scrollTo(0, 1000);
                                    // totalExpectedPrice(){
                                        // alert('enter');
                                        var sum=0;
                                        // var event = this.PurchaseEnquiryAll;
                                        this.PurchaseEnquiryAll.forEach(function (element) {
                                            if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                            {
                                                sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                            }
                                            console.log(sum)
                                        })
                                        this.TotalExpectedPrice = sum;
                                    // }

                                
                                }
                            })
                        }
                        else{
                                /* Add Setupname */
                                //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                {
                                    axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                    .then(function(response){
                                        if(response.data)
                                        {
                                            this.RateContractPurcase = response.data
                                        }
                                    })
                                    if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                    {
                                        Swal('Selected Materials Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                    }
                                }
                                // console.log(this.PurchaseEnquiryAll);
                                this.PurchaseEnquiryLine.JobNumber = "";
                                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                                this.PurchaseEnquiryLine.Cities = ""
                                this.PurchaseEnquiryLine.Country = ""
                                this.PurchaseEnquiryLine.AdvancedPayment = "";
                                this.PurchaseEnquiryLine.RetentionPercentage = "";
                                this.PurchaseEnquiryLine.RetentionDays = ""
                                this.PurchaseEnquiryLine.RateContractSelected = {};
                                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                this.PurchaseEnquiryLine.ItemNumber = "";
                                this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                this.PurchaseEnquiryLine.ItemDescription = "";
                                this.PurchaseEnquiryLine.Quantity = "";
                                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                this.PurchaseEnquiryLine.ServiceDescription = "";
                                this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                                this.PurchaseEnquiryLine.RetentionPercentage = "";
                                this.PurchaseEnquiryLine.RequiredDocuments = [];
                                this.ReqDocTmp = [];
                                this.OtherDocTmp = "";
                                this.OtherDocTmpHolder = [];
                                this.PurchaseEnquiryLine.PELineNote = "";
                                this.PurchaseEnquiryLine.Longitude = "";
                                this.PurchaseEnquiryLine.Latitude = "";
                                this.PurchaseEnquiryLine.LocationName = "";
                                
                                // this.scrollTo(0, 1000);
                                // totalExpectedPrice(){
                                    // alert('enter');
                                    var sum=0;
                                    // var event = this.PurchaseEnquiryAll;
                                    this.PurchaseEnquiryAll.forEach(function (element) {
                                        if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                        {
                                            sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                        }
                                        console.log(sum)
                                    })
                                    this.TotalExpectedPrice = sum;
                                // }
                            }
                            
                            
                        }
                    })
            },
            IgnoreRCServiceSelection(){
                Swal({
                    title: 'Are you sure?',
                    text: "Are You Sure You Don't Want To Select A Rates’ Contract?",
                    type: 'error',
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Yes"
                }).then((result) => {
                    if(result.value){
                        this.IgnoreRCSelectionService = true, this.RCSelectionService = false, this.PurchaseEnquiryAll = []; 
                        $('.RCAddQuan, .RCAddBtn').attr('disabled', false), $('.RCAddBtn').show(), $('.RCRemBtn').attr('hidden', true);
                        this.EnquiryTypeSelection();
                    }
                });

            },
            ClearServiceTypeOptions()
            {
                this.PurchaseEnquiryLine.VendorCommercialOffer = "";
            },
            SubmitService() {
                if(this.PurchaseEnquiryLine.ContractHeaders[0])
                {
                    if(this.PurchaseEnquiryLine.TendererContractHeaders[0])
                    {

                        if (event) event.preventDefault();

                        this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));

                        axios.post('/api/purchase_enquiry/post_purchase_enquiry_request', this.PurchaseEnquiryAll)
                        .then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 10000
                            });
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Service Purchase Enquiry Submit)', 'error');
                        });   
                    }
                    else
                    {
                        Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                    }
                }
                else
                {
                    Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                }
                
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.Country = [];
                this.PurchaseEnquiryLine.Cities = [];
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RateContractSelected = {};
                this.PurchaseEnquiryAll = [];
                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                this.PurchaseEnquiryLine.EnquiryType = "";` `
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = "";
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.ServiceOneOff = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.Images = [];
                this.PurchaseEnquiryLine.LocationName = "";
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ContractHeaders = [];
                this.contractheader = {
                    name: '',
                    contant: '',
                };
                this.PurchaseEnquiryLine.TendererContractHeaders = [];
                this.tenderercontractheader = {
                    name: '',
                    contant: '',
                };

                if(this.CompanySetup.setup_mode == 'Company Wide'){
                    this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                }

            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {
                this.cities = [];
                if(this.PurchaseEnquiryLine.EnquiryType == 'Materials')
                    this.PurchaseEnquiryLine.Cities = [];
                if ((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null) && Array.isArray(this.PurchaseEnquiryLine.Country)){
                    this.PurchaseEnquiryLine.Country.forEach((item) => {
                        this.cities.push(...this.countries[item])
                    });
                }
				else if((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null)){
                  this.cities = this.countries[this.PurchaseEnquiryLine.Country];
               }
                else {
                    this.cities = [];
                }
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            showPEDetails: function(key, event) {
                if (event) event.preventDefault();

                this.PEModalInfoKey = key;
                window.PEModalInfoKey = key;
                this.PEModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);

                axios.post('/api/data/get_project_details', {id: this.PEModalInfo.JobNumber})
                .then(response => {
                    this.PEModalInfo.project = response.data;
                    this.$forceUpdate();
                    this.PELineShow = true;
    
                    $('#PEDetailsModalId').modal('toggle');
                });

            },
            RemovePE: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are you sure?',
                  text: "This Line Will Be Permanently Removed.",
                  type: 'error',
                  showCancelButton: true,
                  confirmButtonColor: '#f10623',
                  cancelButtonColor: '#008000',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        let e = this.PurchaseEnquiryAll[key].ServiceRC_id;
                        this.PurchaseEnquiryAll.splice(key, 1);
                        if(!this.PurchaseEnquiryAll.some(item => item.ServiceRC_id !== null))
                            this.RCSelectionService = false, this.EnquiryTypeSelection(), this.RemoveFromServiceList(e, 0);
                    }
                })

            },
            SubmitPEList() {
                if (event) event.preventDefault();
                // console.log(this.PurchaseEnquiryAll);
                axios.post('/api/purchase_enquiry/post_purchase_enquiry_request', this.PurchaseEnquiryAll)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact your system administrator to solve the problem (Purchase Enquiry Submit)', 'error');
                    });

                //Clear All Data
                this.$refs["PurchaseEnquiryForm"].resetFields();
                this.PurchaseEnquiryAll = "";
                this.PurchaseEnquiryLine.Country = [];
                this.PurchaseEnquiryLine.Cities = [];
                this.PElineUp = 'N';
                this.PurchaseEnquiryLine.JobNumber = "";
                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                this.PurchaseEnquiryLine.EnquiryType = "";
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                this.PurchaseEnquiryLine.ItemLibraryUnitOfMeasurement = "",
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryAll = [];
                this.PurchaseEnquiryLine.ServiceOneOff = "";
                this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract = '';
                this.PurchaseEnquiryLine.RateContractSelected = '';
                this.PurchaseEnquiryLine.TypeOfServicesContract = '';
                this.PurchaseEnquiryLine.ContractHeaders = [];
                this.PurchaseEnquiryLine.TendererContractHeaders = [];
                this.PurchaseEnquiryLine.VendorOfferContent = [];
                this.PurchaseEnquiryLine.VendorCommercialOffer = '';
                this.RCSelectionService = false;
                this.IgnoreRCSelectionService = false;
                this.EnquiryTypeSelection();
                if(this.CompanySetup.setup_mode == 'Company Wide'){
                    this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                }

            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
            EnquiryItemDescriptionSelection(){

                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Images = "";
                
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            showRateContract(ItemId){
                event.preventDefault();
                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                .then((response) => {
                    this.ItemRateContractDetails = response.data;
                    this.RateContractDataLoaded = true;
                })
                .catch(function(){
                    Swal('Error Occured', 'A technical error has occured, please contact your system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                });
            }
        },
        created(){
            this.EnquiryTypeSelection();
            this.getCountries();
        },
        mounted(){

            //CustomJs();
            let self = this;

            axios.get('/api/data/get_user_projects_list')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    // console.log(this.JobNumbers);
                    axios.get('/api/data/get_company_details')
                    .then((SecondResponse) => {

                        this.CompanySetup = SecondResponse.data;

                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        if(this.CompanySetup.additional_required_1 == "Yes"){
                           this.rules.UnderlyingTransaction[0].required = false; 
                        }

                        self.ProjectDetailsLoaded = true;

                    });
                });


            /*
             * Google Map inside modal
             */
            var initializeMap = function(id) {

                var myLatLng = {lat: 25.2707688, lng: 55.3227913};

                // Map Options
                var mapOptions = {
                    zoom: 10,
                    center: new google.maps.LatLng(25.2707688, 55.3227913),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };


                // Initialize the map with options (inside #map element)
                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    draggable: true

                });


                google.maps.event.addListener(marker, 'position_changed', function () {
                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                });


                // When modal window is open, this script resizes the map and resets the map center
                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                  google.maps.event.trigger(map, "resize");
                  return map.setCenter(myLatLng);
                });

            };

            google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));
            
        },
        beforeRouteLeave (to, from, next) {

            if(this.PurchaseEnquiryAll.length > 0 || this.PurchaseEnquiryLine.EnquiryType != ""){
                Swal({
                    title: 'Navigate Away?',
                    text: "Are You Sure You want To Navigate Away?, Non-submitted Data Will Be Lost.",
                    type: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#f10623',
                    cancelButtonColor: '#008000',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('PRO')){
                next();
            } else if (currentUser.hasOwnProperty('PRV')) {
                next('/app/purchase_enquiry/list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

</script>
<style>
    @media print {
      body * {
          visibility: hidden;
      }
      #PEDetailsModalId, #PEDetailsModalId * {
        visibility: visible;
      }
      #PEDetailsModalId {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .itemSelect .el-select-dropdown__wrap{
        max-height: 400px !important;
    }

</style>
<style scoped>
.tg  {border-collapse:collapse;border-spacing:0; width: 95%; margin: auto;}
.tg th {text-align: center; vertical-align: middle; font-weight: 700; border: 2px solid}
.tg td {text-align: center; vertical-align: middle; font-weight: 500; border: 2px solid}
/* .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-0lax{text-align:left;vertical-align:top} */
hr {
    background-color: #2874A6;
}
hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #2874A6;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: red;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
    #Purchase_Enqiury_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Purchase_Enqiury_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        min-width:80%;
    }

    #PEDetailsModalId .modal-dialog {
        min-width:80%;
    }
    #RCSelectionService .modal-dialog {
        min-width:80%;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .lomitemtable>tr>table>tr>th {
        border-top:none;
        margin-top: 0px;
        padding-top: 0px;
    }
    .lomitemtable {
        margin: 0px;
        padding: 0px
    }
    .el-select-dropdown{
        z-index: 0 !important;
       
    }
  

    .el-popper {
        z-index: 0 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 0;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 0;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 0;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 0;
    }

    .swal2-icon.swal2-error {
        border-color: #74f293;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }



    .dont-break-out {

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

    }
</style>
