<template>
	
	<div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Japanees Auction</h4>
                </div>
                <div class="card-body">
                	<p>Japanees Auction Will Be Here...</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

	import validateJs from 'validate.js';

	export default {
		name: "japanees-auction",
		data() {
			return {

			}
		},
        computed: {
            
        },
		methods: {

		},
		mounted(){
			
		}
	}
</script>