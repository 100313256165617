<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Vendor Qualification Documents</h4>
                </div>
                <div class="card-body">
                    <!-- <b class="text-ogeo">
                        The vendors’ performance will be scored, according to up to 7 criteria, as set out in the below table. The criterion which my organization values the most can be given a higher weightage. Beside the weightage, 4 scores, ranging from 3 to 0, must be allocated to the 4 criteria values’ ranges. The overall score, expressed out of one hundred, is the sum of the criteria’s scores multiplied by their respective weightages, divided by the sum of the weightages. <br><br>

                        This Vendor Score Setting can be adjusted at will. Changes to the way the vendors’ performance, when updated, will be instantly communicated to the vendors.

                    </b><br><br><br> -->
                    <h4>List Of Existing Required Qualification Documents: </h4><br><br>
                    <div class="row" v-if="VQReqDocs !== ''">
                        <div class="col-xl-4 col-lg-6" v-for="(item, index) in VQReqDocs" style="margin: -10px 0px -10px 0px">
                            <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="d-flex align-items-center col">

                                    <div class="ms-3">
                                        <p class="fw-bold mb-1">{{ item.document_name }}</p>
                                    </div>
                                    </div>
                                    <div class="col">
                                        <a href="#" class="float-right" @click="removeVQReqDoc(item.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                    </div>
                    <div v-else><h5 style="color: gray; text-align: center; opacity: 0.7;">No Vendors Qualification Requirements Documents Yet</h5></div>
                    <hr>
                    <el-form class="demo-form-inline" ref="VQReqDocs">

                        <el-row>


                            <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="">
                                            <span slot="label"><h4><span class="text-ogeo">*</span>Select From the Documents Below ?</h4></span>
                                            <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px" v-model="VQReqDocsTempToo" @change="addVQReqDoc()" placeholder="Select From The Documents Below ?" ref="elSelect" @visible-change="adjustDropdownWidth">

                                                <el-option
                                                    v-for="(item,index) in preDefDocs"
                                                    :key="index"
                                                    :label="item"
                                                    :value="item">
                                                    <span style="float: left">{{ item }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            <h4> Or Add A New Required Qualification Document: </h4>
                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Vendor Qualification Document Name
                                                            <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                                </span>
                                                                <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                    <span class="tooltip-text2">
                                                                        These headers help better describe the required Services. They can include Scope of Works, Activities,
                                                                        Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes,
                                                                        Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP
                                                                        rights, Litigation...
                                                                    </span>
                                                                </span>
                                                            </span> -->
                                                    </th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in VQReqDocsNew"
                                                    :key="index">
                                                    <td>
                                                        <input v-model="item.name" class="form-control" disabled>
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px" type="button" @click="deleteVQReqDoc(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-model="VQReqDocsTemp">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="VQReqDocsTemp" style="padding: 0px 13px" @click.prevent="addVQReqDoc()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <br><br>

                            <el-col :span="24" style="padding: 12px; margin-top: 50px;">

                                <button type="button" @click="SubmitVQReqDoc" class="btn btn-success btn-block waves-effect text-center">Submit Changes</button>
                            </el-col>

                        </el-row>
                    </el-form>

                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h4>Vendor Score Setting</h4>
                </div>
                <div class="card-body">
                    <b class="text-ogeo">
                        The vendors’ performance will be scored, according to up to 7 criteria, as set out in the below table. The criterion which my organization values the most can be given a higher weightage. Beside the weightage, 4 scores, ranging from 3 to 0, must be allocated to the 4 criteria values’ ranges. The overall score, expressed out of one hundred, is the sum of the criteria’s scores multiplied by their respective weightages, divided by the sum of the weightages. <br><br>

                        This Vendor Score Setting can be adjusted at will. Changes to the way the vendors’ performance, when updated, will be instantly communicated to the vendors.

                    </b><br><br><br>

                    <el-form class="demo-form-inline">

                        <el-row :gutter="24">

                            <el-col :span="24">
                                <table style="width: 100%; border: 1px solid">
                                    <tr style="height: 50px;">
                                        <td colspan="8"><strong class="text-ogeo">Vendors Score Setting</strong></td>
                                    </tr>
                                    <tr height="150px">
                                        <th style="width: 110px">Active</th>
                                        <th style="width: 350px">Description</th>
                                        <th style="width: 130px">Weightage</th>
                                        <th colspan="5" style="vertical-align: middle; ">Score
                                            <!-- <table style="width:100%">
                                                <tr style="width:100%">
                                                    <td style="background:orange;color:red;width:35%">3</td>
                                                    <td style="background:palegoldenrod;color:red;width:15%;">2</td>
                                                    <td style="background:antiquewhite;color:red;width:15%;">1</td>
                                                    <td style="background:gold;color:red;width:35%;">0</td>
                                                </tr>
                                            </table> -->
                                        </th>
                                    </tr>
                                    <tr class="VSbody">
                                        <td >
                                            <el-checkbox v-model="record.c_1" :label="'Criteria #1'"></el-checkbox>

                                        </td>
                                        <td class="text-left pl-2" >
                                            What is the percentage (%) of received Requests For Proposals for which a bid/no bid decision was communicated?
                                        </td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_1 == false" v-model="record.c_1_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput class="midStretch" v-if="dataLoaded" :Enabled="record.c_1" :Range3="record.C1Slider1" :Range2="record.C1Slider2" :Range1="record.C1Slider3" @Range1="record.C1Slider3 = $event" @Range2="record.C1Slider2 = $event" @Range3="record.C1Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_2" :label="'Criteria #2'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">
                                            How frequently does the vendor abide by his bid/no bid communicated intention, in percentage (%) of received Requests For Proposals for which bid/no bids intentions were communicated?
                                        </td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_2 == false" v-model="record.c_2_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan="3" class="mid_value_dy">
                                            <VendorScoreInput v-if="dataLoaded" :Enabled="record.c_2" :Range3="record.C2Slider1" :Range2="record.C2Slider2" :Range1="record.C2Slider3" @Range1="record.C2Slider3 = $event" @Range2="record.C2Slider2 = $event" @Range3="record.C2Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_3" :label="'Criteria #3'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How many bids does the vendor submit, in percentage (%) of received Requests For Proposals?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_3 == false" v-model="record.c_3_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_3" :Range3="record.C3Slider1" :Range2="record.C3Slider2" :Range1="record.C3Slider3" @Range1="record.C3Slider3 = $event" @Range2="record.C3Slider2 = $event" @Range3="record.C3Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_4" :label="'Criteria #4'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor submit the lowest bid, in percentage (%) of total submitted bids?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_4 == false" v-model="record.c_4_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_4" :Range3="record.C4Slider1" :Range2="record.C4Slider2" :Range1="record.C4Slider3" @Range1="record.C4Slider3 = $event" @Range2="record.C4Slider2 = $event" @Range3="record.C4Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_5" :label="'Criteria #5'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor submit the shortest lead time bid, in percentage (%) of total submitted bids?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_5 == false" v-model="record.c_5_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_5" :Range3="record.C5Slider1" :Range2="record.C5Slider2" :Range1="record.C5Slider3" @Range1="record.C5Slider3 = $event" @Range2="record.C5Slider2 = $event" @Range3="record.C5Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_6" :label="'Criteria #6'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">Materials' Requests For Proposals - How frequently does the vendor deliver the materials as per the agreed lead time, in percentage (%) of total delivered materials?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_6 == false" v-model="record.c_6_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_6" :Range3="record.C6Slider1" :Range2="record.C6Slider2" :Range1="record.C6Slider3" @Range1="record.C6Slider3 = $event" @Range2="record.C6Slider2 = $event" @Range3="record.C6Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                    <tr class="VSbody">
                                        <td>
                                            <el-checkbox v-model="record.c_7" :label="'Criteria #7'"></el-checkbox>
                                        </td>
                                        <td class="text-left pl-2">How frequently does the vendor register "receiving refusals", in percentage (%) of total delivered materials?</td>
                                        <td>
                                            <el-input-number :min="1" :max="5" size="mini" :disabled="record.c_7 == false" v-model="record.c_7_w" />
                                        </td>
                                        <td class="end_value_st">
                                            100%
                                        </td>
                                        <td colspan='3' class="mid_value_dy d-table-cell" style="vertical-align: middle;">
                                            <VendorScoreInput :Enabled="record.c_7" :Range3="record.C7Slider1" :Range2="record.C7Slider2" :Range1="record.C7Slider3" @Range1="record.C7Slider3 = $event" @Range2="record.C7Slider2 = $event" @Range3="record.C7Slider1 = $event"></VendorScoreInput>
                                        </td>
                                        <td class="start_value_st">
                                            0%
                                        </td>
                                    </tr>
                                </table>
                            </el-col>
                            <br><br>

                            <el-col :span="24" style="padding: 12px; margin-top: 50px;">

                                <button type="button" @click="SubmitVendorScoreSetting" class="btn btn-success btn-block waves-effect text-center">Update The Vendors Score Setting</button>
                            </el-col>

                        </el-row>
                    </el-form>

                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import VendorScoreInput from '../../components/VendorScoreInput.vue'

    export default {
        components: {
            VendorScoreInput
        },
        name: 'VendorScoreSettings',
        data(){

            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === ''){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }

                }
            };

            return{
                dataLoaded: false,
                backupRecord: "",
                auditLog: [],
                preDefDocs: [
                    "Company Registration Certificate",
                    "Business License",
                    "Company Profile Or Brochure",
                    "Audited Financial Statements (Past 2-3 Years)",
                    "Credit Rating Report",
                    "ISO Certifications (E.G., ISO 9001)",
                    "Industry-Specific Quality Certifications",
                    "Compliance Certificates (E.G., FDA, HIPAA)",
                    "Environmental Compliance (E.G., ISO 14001)",
                    "Liability Insurance",
                    "Workers' Compensation",
                    "Professional Indemnity Or E&O Insurance",
                    "Occupational Safety Certifications (E.G., ISO 45001)",
                    "Safety Incident Records",
                    "Customers Testimonials",
                    "Case Studies Or Project Summaries",
                    "Technical Certifications (E.G., IT Certifications)",
                    "Documentation Of Technical Processes",
                    "Organizational Chart",
                    "Key Personnel Resumes Or Certifications",
                    "Workforce Certifications",
                    "Supplier Agreements",
                    "Supply Chain Resilience And Contingency Plans",
                    "Sample Contract Or SLA Template",
                    "Terms, Conditions, Warranties, Or Guarantees",
                    "Cybersecurity Certifications (E.G., ISO 27001)",
                    "Data Privacy Compliance (E.G., GDPR, CCPA)",
                    "Conflict Of Interest (COI) Declarations",
                    "Sustainability Policy Or Reports",
                    "CSR And Community Engagement Documentation"
                ],
                record: {
                    c_1: false,
                    c_2: false,
                    c_3: false,
                    c_4: false,
                    c_5: false,
                    c_6: false,
                    c_7: false,
                    c_1_w: 1,
                    c_2_w: 1,
                    c_3_w: 1,
                    c_4_w: 1,
                    c_5_w: 1,
                    c_6_w: 1,
                    c_7_w: 1,
                    C1Slider1: 80,
                    C1Slider2: 60,
                    C1Slider3: 40,
                    C2Slider1: 80,
                    C2Slider2: 60,
                    C2Slider3: 40,
                    C3Slider1: 80,
                    C3Slider2: 60,
                    C3Slider3: 40,
                    C4Slider1: 80,
                    C4Slider2: 60,
                    C4Slider3: 40,
                    C5Slider1: 80,
                    C5Slider2: 60,
                    C5Slider3: 40,
                    C6Slider1: 80,
                    C6Slider2: 60,
                    C6Slider3: 40,
                    C7Slider1: 80,
                    C7Slider2: 60,
                    C7Slider3: 40,
                },
                recordTypos: {
                    c_1: 'Criteria #1',
                    c_2: 'Criteria #2',
                    c_3: 'Criteria #3',
                    c_4: 'Criteria #4',
                    c_5: 'Criteria #5',
                    c_6: 'Criteria #6',
                    c_7: 'Criteria #7',
                    c_1_w: 'Criteria #1: Weightage',
                    c_2_w: 'Criteria #2: Weightage',
                    c_3_w: 'Criteria #3: Weightage',
                    c_4_w: 'Criteria #4: Weightage',
                    c_5_w: 'Criteria #5: Weightage',
                    c_6_w: 'Criteria #6: Weightage',
                    c_7_w: 'Criteria #7: Weightage',
                    C1Slider1: 'Criteria #1: Score "3" lower limit',
                    C1Slider2: 'Criteria #1: Score "2" lower limit',
                    C1Slider3: 'Criteria #1: Score "1" lower limit',
                    C2Slider1: 'Criteria #2: Score "3" lower limit',
                    C2Slider2: 'Criteria #2: Score "2" lower limit',
                    C2Slider3: 'Criteria #2: Score "1" lower limit',
                    C3Slider1: 'Criteria #3: Score "3" lower limit',
                    C3Slider2: 'Criteria #3: Score "2" lower limit',
                    C3Slider3: 'Criteria #3: Score "1" lower limit',
                    C4Slider1: 'Criteria #4: Score "3" lower limit',
                    C4Slider2: 'Criteria #4: Score "2" lower limit',
                    C4Slider3: 'Criteria #4: Score "1" lower limit',
                    C5Slider1: 'Criteria #5: Score "3" lower limit',
                    C5Slider2: 'Criteria #5: Score "2" lower limit',
                    C5Slider3: 'Criteria #5: Score "1" lower limit',
                    C6Slider1: 'Criteria #6: Score "3" lower limit',
                    C6Slider2: 'Criteria #6: Score "2" lower limit',
                    C6Slider3: 'Criteria #6: Score "1" lower limit',
                    C7Slider1: 'Criteria #7: Score "3" lower limit',
                    C7Slider2: 'Criteria #7: Score "2" lower limit',
                    C7Slider3: 'Criteria #7: Score "1" lower limit',
                },
                VQReqDocs: "",
                VQReqDocsNew: [],
                VQReqDocsTemp: "",
                VQReqDocsTempToo: [],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now();
                    },
                },

            }
        },
        computed : {
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            }
        },
        methods: {
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            errorPop(val) {
                val++
            },
            SubmitVendorScoreSetting: function(){
                this.record.auditLog = Object.assign({}, this.auditLog);
                axios.post('/api/data/set_vendor_score_setting', this.record)
                .then((response) => {
                    Swal({
                        type: 'success',
                        title: 'Vendors Score Settings Updated Sucessfully.',
                        showConfirmButton: true,
                        timer: 5000
                    });
                })

            },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')

            },
            addVQReqDoc(e) {
                if(this.VQReqDocsTempToo.length) {
                    this.VQReqDocsNew = this.VQReqDocsTempToo.filter(item => this.VQReqDocsTempToo.some(option => option.name === item) );
                    this.VQReqDocsTempToo.forEach(option => { if (!this.VQReqDocsNew.some(item => item.name === option)) { this.VQReqDocsNew.push({ name: option }); } });
                }
                else if (this.VQReqDocsTemp && this.existVQReqDoc() == true) {
                    this.VQReqDocsNew.push(this.VQReqDocsTemp)
                    this.VQReqDocsTemp = ''
                } else {
                    Swal('Error', 'This Vendor Qualification Document Already Exists.' , 'error');
                }
            },
            deleteVQReqDoc(index) {
                this.$delete(this.VQReqDocsNew, index);
            },
            existVQReqDoc() {
                if(Object.keys(this.VQReqDocsNew).length || Object.keys(this.VQReqDocs).length)
                    if (this.VQReqDocsNew.find(item => item.name == this.VQReqDocsTemp) || (this.VQReqDocs == '' ? 0 : this.VQReqDocs.find(item => item.document_name === this.VQReqDocsTemp))) {
                        return false;
                    }else {
                        return true;
                    }
                else
                    return true
            },
            findAndDeleteByField(array, desc) { const index = array.findIndex(obj => obj.desc === desc); if (index !== -1) { array.splice(index, 1); console.log(`Entry with desc "${desc}" has been deleted.`); } },
            adjustDropdownWidth(visible) { if (visible) { this.$nextTick(() => { const elSelect = this.$refs.elSelect.$el; const dropdown = document.querySelector('.el-select-dropdown'); if (elSelect && dropdown) { dropdown.style.minWidth = `${elSelect.clientWidth}px`; dropdown.style.width = `${elSelect.clientWidth}px`; } }); } },
            getVQReqDoc: function(){
                axios.get('/api/vendors_management/get_vendor_qualification_req_docs')
                .then((response) => {
                    this.VQReqDocs = response.data;
                    this.preDefDocs = this.preDefDocs.filter(item => !this.VQReqDocs.find(itemz => itemz.document_name === item));
                })

            },
            SubmitVQReqDoc: function(){
                axios.post('/api/vendors_management/create_qualification_req_doc', {VQReqDocsNew: this.VQReqDocsNew})
                .then((response) => {
                    Swal({
                        type: response.data.messageType,
                        title: response.data.messageTitle,
                        showConfirmButton: true,
                        timer: 5000
                    });
                    this.$refs.VQReqDocs.resetFields();
                    this.VQReqDocsNew = [];
                    this.getVQReqDoc();
                })

            },
            removeVQReqDoc: function(id){
                axios.post('/api/vendors_management/remove_qualification_req_doc', {id: id})
                .then((response) => {
                    Swal({
                        type: response.data.messageType,
                        title: response.data.messageTitle,
                        showConfirmButton: true,
                        timer: 5000
                    });
                    this.getVQReqDoc();
                })

            },
        },
        created(){
            axios.get('/api/data/get_vendor_score_setting')
                .then((response) => {
                    response.data.forEach((data, index) => {
                        if(data.criteria == index+1) {
                            this.record['c_'+(index+1)] = true;
                            this.record['c_'+(index+1)+'_w'] = data.weightage;
                            for(let i=1; i<=3; i++) {
                                this.record['C'+(index+1)+'Slider'+i] = data['score_'+(4-i)];

                            }
                            this.backupRecord = Object.assign({}, this.record);
                        }
                    });
                    this.dataLoaded = true;
                });

        },
        mounted(){
            let self = this;



            axios.get('/api/data/get_company_details')
                .then((response) => {
                    self.companyDetails = response.data;
                });

            this.getVQReqDoc();
        },
        watch: { record: { handler(newVal) { Object.keys(newVal).forEach(key => { if (newVal[key] !== this.backupRecord[key]) { const obj = { desc: this.recordTypos[key], old_val: this.backupRecord[key], new_val: newVal[key] }; const elementPos = this.auditLog.findIndex(x => x.desc === this.recordTypos[key]); if (elementPos !== -1) { this.auditLog[elementPos] = Object.assign({}, obj); } else { this.auditLog.push(obj); } } else {this.findAndDeleteByField(this.auditLog, this.recordTypos[key]);} }); }, deep: true } },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));

            if(currentUser.hasOwnProperty('CAD')){
                //check with the backend if user has SAD privileges
                axios.get('/api/users/get_user_details')
                    .then(response => {
                        //console.log(response.data);
                        if(response.data.hasOwnProperty('CAD')){
                            next();
                        } else {

                            Swal("High Fraud Offence", "You do not have the right to access this page, this instance will be reported. \n. ("+ currentUser.Name +")" , 'error');

                            //this instance must be reported to since there is fraud envolved.
                            next('/login');
                        }
                    })

            } else {
                //Swal("Access Denied", "You do not have the right to access this page, this instance will be reported. \n. ("+ currentUser.Name +")" , 'warning');

                //instance can be reported to backend here
                if(from.path != '/'){
                    Swal("Access Denied", "You do not have the right to access this page, Only the highest level VQ Validator can access this page", 'error');
                    /*$.toast({
                        heading: 'No Access Rights.',
                        text: 'You do not have the privilege to access this menu.',
                        position: 'top-right',
                        loaderBg:'#ff6849',
                        icon: 'error',
                        hideAfter: 3000,
                        stack: 6
                    });*/
                }



                next('/login');
            }
        }
    }

</script>
<style>
.el-input-number--mini{
    width: 100px;
    line-height: 38px;
    height: 40px;
}
.el-input--mini .el-input__inner{
    color: orangered !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}
.el-select-dropdown { width: auto !important; box-sizing: border-box; }
</style>
<style scoped>

    .el-select-dropdown__item{
        height: 100% !important;
    }

    .el-select-dropdown__wrap{
        max-height: 400px !important;
        max-width: 100% !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    td, th{
        border: 1px solid !important;
        text-align: center;
    }
    .VSbody {
        height: 100px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .el-input__inner {
        height: 40px !important;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }
    .el-input-number--small{
        height: 125px !important;
    }
    .el-input--small .el-input__inner{
        height: 32px !important;
    }
    .el-select-dropdown__item{
        height: 100% !important;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .start_value_st{
        width:50px;
        font-size: 18px;
        font-weight: 700;
    }
    .end_value_st{
        width:50px;
        font-size: 18px;
        font-weight: 700;
    }
    .mid_value_dy{
        align-items: space-between;
    }
    .midStretch {
        width: 100%;
    }
</style>
