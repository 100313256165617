<template>

    <div v-if="currentVendor">
        
        <VendorsHeader/>
        <VendorsMenu/>
        
        <router-view></router-view>        

        <VendorsFooter/>

        <!-- <PassportClients/> -->
        <!-- <PassportAuthorizedClients/> -->
        <!-- <PassportPersonalAccessTokens/> -->

        <!-- <div id="idle-timeout-dialog" data-backdrop="static" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Your session is expiring soon</h4>
                    </div>
                    <div class="modal-body">
                        <p>
                            <i class="fa fa-warning font-red"></i> You session will be locked in
                            <span id="idle-timeout-counter"></span> seconds.</p>
                        <p> Do you want to continue your session? </p>
                    </div>
                    <div class="modal-footer text-center">
                        <button id="idle-timeout-dialog-keepalive" type="button" class="btn btn-success" data-dismiss="modal">Yes, Keep Working</button>
                    </div>
                </div>
            </div>
        </div> -->
        
    </div>
    
</template>

<script>

    //import {CustomJs} from '../helpers/custom.js';
    import VendorsHeader from './vendor_layout/VendorsHeader.vue';
    import VendorsMenu from './vendor_layout/VendorsMenu.vue';
    import VendorsFooter from './vendor_layout/VendorsFooter.vue';

    export default {
        name: 'vendors-access',
        data(){
            return {

            }
        },
        components: {
            VendorsHeader,
            VendorsMenu,
            VendorsFooter
        },
        computed:{
            currentVendor(){
                return this.$store.getters.currentVendor
            }
        },
        mounted() {

            //CustomJs();

            // $(function () {

            //     "use strict";
            //     $(function () {
            //         $(".preloader").fadeOut();
            //     });

            //     jQuery(document).on('click', '.mega-dropdown', function (e) {
            //         e.stopPropagation()
            //     });

            //     // ============================================================== 
            //     // This is for the top header part and sidebar part
            //     // ==============================================================  
            //     var set = function () {
            //             var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            //             var topOffset = 70;
            //             if (width < 1170) {
            //                 $("body").addClass("mini-sidebar");
            //                 $('.navbar-brand span').hide();
            //                 $(".sidebartoggler i").addClass("ti-menu");
            //             }
            //             else {
            //                 $("body").removeClass("mini-sidebar");
            //                 $('.navbar-brand span').show();
            //                 $(".sidebartoggler i").removeClass("ti-menu");
            //             }
                        
            //             var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            //             height = height - topOffset;
            //             if (height < 1) height = 1;
            //             if (height > topOffset) {
            //                 $(".page-wrapper").css("min-height", (height) + "px");
            //             }
                   
            //     };
            //     $(window).ready(set);
            //     $(window).on("resize", set);


            //     // topbar stickey on scroll
            //     $(".fix-header .topbar").stick_in_parent({
                    
            //     });



            //     // this is for close icon when navigation open in mobile view
            //     $(".nav-toggler").click(function () {
            //         $("body").toggleClass("show-sidebar");
            //         $(".nav-toggler i").toggleClass("mdi mdi-menu");
            //         $(".nav-toggler i").addClass("ti-close");
            //     });
            //     $(".sidebartoggler").on('click', function () {
            //         $(".sidebartoggler i").toggleClass("ti-menu");
            //     });
            //     $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            //         $(".app-search").toggle(200);
            //     });



            //     // ============================================================== 
            //     // Auto select top navbar
            //     // ============================================================== 
            //     $(function () {
            //         var url = window.location;
            //         var element = $('ul#sidebarnav a').filter(function () {
            //             return this.href == url.href;
            //         }).addClass('active').parent().addClass('active');

            //         while (true) {
            //             if (element.is('li')) {
            //                 element = element.parent().addClass('in').parent().addClass('active');
            //             }
            //             else {
            //                 break;
            //             }
            //         }

            //         $('ul#sidebarnav a').on('click', function(event) {
            //             $('nav ul li').removeClass('active');
            //             $(this).parent().parent().parent().addClass('active');
            //         });
                    
            //     });

            //     // ============================================================== 
            //     //tooltip
            //     // ============================================================== 
            //     $(function () {
            //         $('[data-toggle="tooltip"]').tooltip()
            //     })
            //     // ============================================================== 
            //     //Popover
            //     // ============================================================== 
            //     $(function () {
            //         $('[data-toggle="popover"]').popover()
            //     })
            //     // ============================================================== 
            //     // Sidebarmenu
            //     // ============================================================== 
            //     // $(function () {
            //     //     $('#sidebarnav').metisMenu();
            //     // });


            //     // ============================================================== 
            //     // Slimscrollbars
            //     // ============================================================== 
                 
            //     $('.message-center').slimScroll({
            //         position: 'right'
            //         , size: "5px"
                    
            //         , color: '#dcdcdc'
            //      });
                
                
            //     $('.aboutscroll').slimScroll({
            //         position: 'right'
            //         , size: "5px"
            //         , height: '80'
            //         , color: '#dcdcdc'
            //     });

            //     $('.message-scroll').slimScroll({
            //         position: 'right'
            //         , size: "5px"
            //         , height: '570'
            //         , color: '#dcdcdc'
            //     });

            //     $('.chat-box').slimScroll({
            //         position: 'right'
            //         , size: "5px"
            //         , height: '470'
            //         , color: '#dcdcdc'
            //      });
                
            //     $('.slimscrollright').slimScroll({
            //         height: '100%'
            //         , position: 'right'
            //         , size: "5px"
            //         , color: '#dcdcdc'
            //      });

            //     // ============================================================== 
            //     // Resize all elements
            //     // ============================================================== 
            //     $("body").trigger("resize");



            //     // ============================================================== 
            //     // Collapsable cards
            //     // ==============================================================
            //     $('a[data-action="collapse"]').on('click',function(e){
            //         e.preventDefault();
            //         $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
            //         $(this).closest('.card').children('.card-body').collapse('toggle');
                   
            //     }); 
            //     // Toggle fullscreen
            //     $('a[data-action="expand"]').on('click',function(e){
            //         e.preventDefault();
            //         $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
            //         $(this).closest('.card').toggleClass('card-fullscreen');
            //     });

            //     // Close Card
            //     $('a[data-action="close"]').on('click',function(){
            //         $(this).closest('.card').removeClass().slideUp('fast');
            //     });



            //     // ============================================================== 
            //     // This is for the sparkline charts which is coming in the bradcrumb section
            //     // ==============================================================
            //     $('#monthchart').sparkline([5, 6, 2, 9, 4, 7, 10, 12], {
            //             type: 'bar',
            //             height: '35',
            //             barWidth: '4',
            //             resize: true,
            //             barSpacing: '4',
            //             barColor: '#1e88e5'
            //         });
            //     $('#lastmonthchart').sparkline([5, 6, 2, 9, 4, 7, 10, 12], {
            //             type: 'bar',
            //             height: '35',
            //             barWidth: '4',
            //             resize: true,
            //             barSpacing: '4',
            //             barColor: '#7460ee'
            //         });
            //     var sparkResize;

            // });
            
        },
        beforeCreate(){

            //console.log('beforeCreateHeader');

            // axios.get('/api/users/get_user_details')
            //     .then(response => {
            //         this.$store.commit('setUser', response.data)
            //     })
            
        }


    }
</script>
