<template>
        
    <div class="row">
        <div id='Purchase_Enqiury_Form' class="col-lg-7">
            <div class="card" v-if="ProjectDetailsLoaded">
                <div class="card-header">
                    <h4 v-if="this.PurchaseEnquiryLine.EnquiryType == 'Materials'">Initiate A <i>Materials Purchase Enquiry</i> Line</h4>
                    <h4 v-else-if="this.PurchaseEnquiryLine.EnquiryType == 'Service'"><b>Initiate A <i>Services Purchase Enquiry</i></b></h4>
                    <h4 v-else>Create A Purchase Enquiry</h4>
                </div>
                <div class="card-body">
                    <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryForm">

                        <el-row :gutter="20">
                            <el-col :span="24">   
                                <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.025);text-align: justify;">
                                    <p style="color: #111111;font-size: 16px;">
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>  
                                        The initiation of a <i><b>Services Purchase Enquiry</b></i>, based on an existing one, streamlines the 
                                        process of acquiring the necessary services for the <i>Account</i>. 
                                        By leveraging pre-existing information, this approach allows for the swift creation of a new enquiry.
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII="/>
                                    </p>
                                    <hr>
                                    <p style="color: rgb(252, 51, 23);font-size: 16px;">
                                        <i class="fa fa-exclamation-triangle"></i>  
                                        The user holding the <u><i>Purchase Enquiry</i> Initiator</u> role can modify the details of the existing enquiry to 
                                        align with the specific requirements of the new <i>Services Purchase Enquiry</i>.
                                    </p>                                                      
                                </div>
                            </el-col>

                            <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="JobNumber">
                                            <span slot="label"><b>Select An <i>Account</i></b></span>

                                            <el-select id="SetupSelection" @change="getProjectCurrency()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select an 'Account' for which you hold the Purchase Enquiry Initiator role">
                                                
                                                <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </transition>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                        <span slot="label"><b>The <i>Purchase Enquiry</i> Type</b></span>

                                        <el-select @change="EnquiryTypeSelection" disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Select Enquiry Type (Material Or Service)">
                                                
                                            <el-option label="Materials" value="Materials"></el-option>
                                            <el-option label="Services" value="Service"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.PurchaseEnquiryLine.JobNumber"> 
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="SourcingPriority">
                                        <span slot="label"><b>Select a sourcing priority</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: 23px; left: -42px; width: 1150px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The sourcing priority is, by default, set to <i>Standard</i> but can be changed to <i>Urgent</i> or <i>Very Urgent</i>, as needed.</p>
                                                    <p>This setting determines the urgency with which proposals from vendors should be sourced during the tendering process.</p>
                                                </span>
                                            </span>
                                        </span>
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="UnderlyingTransaction">
                                        <span slot="label"><b>Enter an underlying transaction reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                            <span style="bottom: 23px; left: -153px; width: 1150px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <p>The underlying transaction reference is the identifier for the transaction which triggered the need to initiate this specific <i>Purchase Enquiry</i>.</p>
                                                    <p>Examples of such references include <u>Sales Orders</u>, <u>Work Orders</u>, <u>Job Tickets</u>, <u>Lead References</u>...</p>
                                                </span>
                                            </span>
                                        </span>
                                        <el-input v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <!-- Delivery Location Selection -->
                            <!--<el-col :span="12" v-if="PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select Country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 100px">
                                                <span class="tooltip-text2">Select the country where delivery is needed.</span>
                                            </span>
                                        </span>
                                        <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country">
                                        <el-select filterable collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Country">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>-->
                            
                            <el-col :span="12" v-if="PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="Country">
                                        <span slot="label"><b>Select Countries</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            
                                            <span style="bottom: 23px; left: 20px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">Select the countries where delivery is needed (selecting multiple countries is allowed).</span>
                                            </span>
                                        </span>

                                        <el-select filterable multiple collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Countries">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
  

                            <!--<el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select City</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -100px">
                                                <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                            </span>
                                        </span>
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City">
                                        <el-select filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select City">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>-->

                            <el-col :span="12" v-if="PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="Cities">
                                        <span slot="label"><b>Select Cities</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span style="bottom: 23px; left: 20px; width: 450px;" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">Select the cities where delivery is needed (selecting multiple cities is allowed).</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select Cities">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.Cities.length > 0" >
                                <el-form-item label="temp">
                                    <span slot="label"><b>Mark the delivery location address on the map</b></span>

                                    <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
													<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
													<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
                                            </span>
                                        <span style="bottom: 23px; left: -186px; width: 550px;" class="tooltip-content4 clearfix">
                                            <span class="tooltip-text2">
                                                You may use Google Map and drop a marker in the delivery location.                                            
                                            </span>
                                        </span>
                                    </span>

                                    <button 
                                        v-if="PurchaseEnquiryLine.Cities.length > 0" 
                                        type="button" 
                                        data-toggle="modal" 
                                        id="get_map" 
                                        data-target="#DeliveryLocation" 
                                        class="btn btn-primary btn-block waves-effect text-center" 
                                        style="background-color: rgb(64, 64, 64); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" 
                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                        Identify the delivery locations with markers
                                    </button>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.Cities.length > 0">
                                <br style="margin-top: 10px; margin-bottom: -2px;">
                                <div style="display: flex; align-items: center; justify-content: center; position: relative; text-align: center; color: rgb(64, 64, 64); font-size: 16px; margin: 0;">
                                    <hr style="border: 1px solid rgb(64, 64, 64); width: 100%; margin: 0;">
                                    <div style="position: absolute; background: #fff; padding: 0 10px; color: rgb(64, 64, 64);">
                                        <b>OR</b>
                                    </div>
                                </div>
                                <br style="margin-top: 10px; margin-bottom: -2px;">
                            </el-col>

                            
                            <el-col  :span="12" v-if="PurchaseEnquiryLine.Cities.length > 0" >
                                <el-form-item label="temp" prop="Longitude">
                                    <span slot="label"><b>Enter the delivery location longitude</b></span>
                                    <el-input type="number" readonly  placeholder="Enter the longitude or use the map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col  :span="12" v-if="PurchaseEnquiryLine.Cities.length > 0" >
                                <el-form-item label="temp" prop="Latitude">
                                    <span slot="label"><b>Enter the delivery location latitude</b></span>
                                    <el-input type="number"  readonly placeholder="Enter the latitude or use the map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.Cities.length > 0" >
                                <el-form-item label="temp" prop="LocationName">
                                    <span slot="label"><b>Enter the delivery location latitude</b></span>
                                    <el-input type="text" placeholder="Enter the delivery location name. Add the name of a landmark if possible." v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <!-- Delivery Location Selection -->

                            <el-col :span="24" v-if="PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceDescription">
                                        <span slot="label"><b>Enter a <i>Services Purchase Enquiry</i> short description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="1"
                                            placeholder="Enter a short description of the required 'Services'"
                                            v-model="PurchaseEnquiryLine.ServiceDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ServiceDescription && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'LibrariesNFreeText'">

                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                        <span slot="label"><b>Do You Want To Select An Item From The Library of Materials?</b></span>

                                        <el-select @change="EnquiryItemDescriptionSelection" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryFromItemMaster" placeholder="Do You Want To Select An Item From The Library of Materials?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'">
                                    <el-form-item label="temp" prop="ItemNumber">
                                        <span slot="label"><b>Select From The Library Of Materials</b></span>
                                        <el-select style="width: 100%"
                                            class="itemSelect"
                                            size="small"
                                            v-model="PurchaseEnquiryLine.ItemNumber"
                                            @change="checkRateContract()"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placeholder="Search and Select an Item">
                                            <el-option style="max-width: 1200px; min-height: 150px"
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                :value="item.id">

                                                <div class="row" style="border: 2px solid black">
                                                    <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                       <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                        
                                                    </div>
                                                    <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr>

                                                                <td>
                                                                    <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                    <span style=" white-space: normal; line-height: 1.5em;">
                                                                        <template v-for="index in 20">      
                                                                            <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                            </span>
                                                                        </template>
                                                                    </span><br><br>
                                                                    <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                </td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                    <div class="col-sm-3 text-wrap mr-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                        <table class="table itemTable lomitemtable">
                                                            <tr>
                                                                <table>
                                                                    <tr>
                                                                        <th><b class="font-700">Vendor Name</b></th>
                                                                        <th><b class="font-700">Vendor Score</b></th>
                                                                        <th><b class="font-700">Expiry Date</b></th>
                                                                        <th><b class="font-700">Unit Rate</b></th>
                                                                        <th><b class="font-700">Lead Time</b></th>
                                                                    </tr>
                                                                    <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                        <td>{{ item.vendor_name }}</td>
                                                                        <td>{{ item.vendor_score }}</td>
                                                                        <td>{{ item.date }}</td>
                                                                        <td>{{ item.unit_rate }} USD </td>
                                                                        <td>{{ item.lead_time }}</td>
                                                                    </tr>
                                                                </table>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                        <table class="table itemTable lomitemtable">
                                                            <tr>
                                                                <table>
                                                                    <tr>
                                                                        <th><b class="font-700">Unit Rate</b></th>
                                                                        <th><b class="font-700">Setup Name</b></th>
                                                                        <th><b class="font-700">City</b></th>
                                                                        <th><b class="font-700">Date</b></th>
                                                                        <th><b class="font-700">Quantity</b></th>
                                                                        <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                    </tr>
                                                                    <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                        <td>{{ item.unit_rate }}</td>
                                                                        <td>{{ item.concern }}</td>
                                                                        <td>{{ item.city }}</td>
                                                                        <td>{{ item.date }}</td>
                                                                        <td>{{ item.quantity }}</td>
                                                                        <td>{{ item.pobid }}</td>
                                                                    </tr>
                                                                </table>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                </div>

                             

                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                    <el-form-item label="temp" prop="ItemDescription">
                                        <span slot="label"><b>Enter The Material Description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Complete Description Of The Item Required."
                                            v-model="PurchaseEnquiryLine.ItemDescription">
                                        </el-input>
                                    </el-form-item>
                                </div>
                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-upload
                                                    style="width:654px;hight:270px;"
                                                    action="#"
                                                    list-type="picture-card"
                                                    :limit="4"
                                                    id='Images'
                                                    :auto-upload="false"
                                                    ref="Images"
                                                    accept=".jpg, .jpeg, .png"
                                                    :on-change="handleChange"
                                                    :on-exceed="handleExceed"
                                                    :on-remove="handleRemove">
                                                    <i class="el-icon-plus"></i>
                                                </el-upload>
                                                <span>You can upload 4 Images maximum size of 1 MB.</span>
                                                
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>


                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">

                                    <el-form-item label="temp" prop="UnitOfMeasurement">
                                        <span slot="label"><b>Enter Unit Of Measure</b></span>

                                        <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                    </el-form-item>

                                </div>

                            </el-col>

                            <el-col :span="24" v-else-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'Libraries'">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ItemNumber">
                                        <span slot="label"><b>Select The Item From The Library Of Materials</b></span>
                                        <el-select style="width: 100%"
                                            v-model="PurchaseEnquiryLine.ItemNumber"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placeholder="Search and Select an Item">
                                            <el-option
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'Item ID: ('+item.item_group_id+'-'+item.item_ingroup_id+'), Description: '+item.description"
                                                :value="item.id">
                                                <div class="row">
                                                    <div class="col-lg-2 b-r" style="padding-left: 0px; padding-top: 5px; padding-buttom: 0px; padding-right: 0px;" >
                                                       <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                        
                                                    </div>
                                                    <div class="col-lg-4 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr> 
                                                                <td width="50%"> <span>LoM</span>-{{ item.item_group_id  }}-{{ item.item_ingroup_id  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_1 !== null'><b>{{ item.item_template.field_1.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_1 !== null'> {{ item.field_1.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_2'><b>{{ item.item_template.field_2.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_2'>{{ item.field_2.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" v-if='item.item_template.field_3'><b>{{ item.item_template.field_3.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%" v-if='item.field_3'>{{ item.field_3.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>Rates' Contract:</b></td>
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)"> View Rates</td>
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)">
                                                                    <el-tooltip placement="top-start" effect="light">
                                                                        <div slot="content">
                                                                            <div v-if="RateContractDataLoaded">
                                                                                <table style="min-width: 500px" class="table table-bordered">
                                                                                    <thead>
                                                                                        <td align="center"><b>Price</b></td>
                                                                                        <td align="center"><b>Lead Time</b></td>
                                                                                        <td align="center"><b>Contract End Date</b></td>
                                                                                    </thead>
                                                                                    <tbody v-if="ItemRateContractDetails.length > 0">
                                                                                        <tr v-for="(RateContract, key, index) in ItemRateContractDetails" :key="index">
                                                                                            <td align="center">{{ formatPrice(RateContract.vendor_responses[0].unit_rate) }} {{ RateContract.vendor_responses[0].currency }}</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].lead_days }} Day(s)</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].rate_contract_request.rc_end_period.substring(0, 10) }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tbody v-else>
                                                                                        <tr>
                                                                                            <td align="center" colspan="3"> NO CONTRACT AVAILABLE </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div align="center" v-else>
                                                                                <h2>Gathering Data...</h2>
                                                                                <img align="center" :src="hostName+'/assets/images/ajax-loader.gif'">
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <i style="line-height: 0px">View Rates</i>
                                                                    </el-tooltip>
                            
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-lg-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px;">
                                                        <div>
                                                            <h5><b>Item Description:</b></h5>
                                                            <p>{{ item.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>
                            
                                    </el-form-item>
                                </div>
                            </el-col>
                            


                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'FreeText'" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="ItemDescription">
                                        <span slot="label"><b>Enter Material Description</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Complete Description Of The Item Required."
                                            v-model="PurchaseEnquiryLine.ItemDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">50/{{PurchaseEnquiryLine.ItemDescription.length}}</span> -->
                                </div>

                                <div class="grid-content">

                                    <el-form-item label="temp" prop="UnitOfMeasurement">
                                        <span slot="label"><b>Enter Unit Of Measure</b></span>

                                        <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                        <!-- <el-select filterable allow-create style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="Enter Unit Of Measurement">
                                                
                                            <el-option label="Metre" value="Metre"></el-option>
                                            <el-option label="Millimeter" value="Millimeter"></el-option>
                                            <el-option label="Centimeter" value="Centimeter"></el-option>
                                            <el-option label="Decimeter" value="Decimeter"></el-option>
                                            <el-option label="Kilometer" value="Kilometer"></el-option>
                                            <el-option label="Cup" value="Cup"></el-option>
                                            <el-option label="Gill" value="Gill"></el-option>
                                            <el-option label="Pint" value="Pint"></el-option>
                                            <el-option label="Grams" value="Grams"></el-option>
                                            <el-option label="Kilogram" value="Kilogram"></el-option>
                                            <el-option label="Grain" value="Grain"></el-option>
                                            <el-option label="Dram" value="Dram"></el-option>
                                            <el-option label="Ounce" value="Ounce"></el-option>
                                            <el-option label="Pound" value="Pound"></el-option>
                                            <el-option label="Dozen" value="Dozen"></el-option>
                                                
                                        </el-select> -->
                                    </el-form-item>

                                </div>
                            </el-col>

                            

                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="Quantity">
                                        <span slot="label"><b>Enter Needed Quantity</b></span>
                                        <el-input min='0.0001' type="number" placeholder="Enter Item Quantity" v-model="PurchaseEnquiryLine.Quantity">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col>
                            
                            

                            <el-col :span="24"  v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Documents Required From The Vendors</b></span>
                                            
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    These are the documents that the vendors need to submit along with their commercial proposals, so that their proposals can be assessed.
                                                </span>
                                            </span>
                                        </span>

                                        <el-select filterable allow-create collapse-tags multiple style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RequiredDocuments" placeholder="Select Required Document">
                                            
                                            <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                            <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                            <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                            <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                            <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                            <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                            <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col>

                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <div class="grid-content" v-if="this.showQuestionRateMaterial == true">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                        <span slot="label"><b>This item from the Library of Materials has (the number of existing, valid, Rates' Contract) Rates' Contract(s) attached to it. Do you want to select one of them (if there are many) it (if there is only one) to reduce the sourcing turnaround time?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    A Rate Contract is a framework agreement signed with a Vendor, for a list of materials, which freezes both Unit Rates' & Lead Times over a given period of time. The use of Rates' Contract helps reduce turnaround times since a validated Purchase Enquiry line, linked to a Rate Contract, can reasdily be turned into a Purcase Order, without the need to source proposals.                                            
                                                </span>
                                            </span>
                                        </span>
                                        <el-select @change="ShowPopUpIgnoreRateContract" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ShowPopUpIgnoreRateContract" placeholder="Do You Want To Select An Item From The Library of Materials?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber">

                                    <el-form-item label="temp">
                                    <span slot="label"><b>Select From Existing Rates' Contract</b></span>
                                    <input type="checkbox" id="rtcheckbox" true-value="yes" false-value="no" @click="ResetRateContractDropdown()" v-model="PurchaseEnquiryLine.rtYN">
                                    <label for="rtcheckbox">I'll proceed without a Rates' Contract</label>
                                        <el-select  v-if="PurchaseEnquiryLine.rtYN === 'no'" style="width: 100%"
                                            v-model="PurchaseEnquiryLine.RateContractSelected"
                                            @change="checkLowestRateContract()"
                                            placeholder="Select Rate Contract">
                                            <el-option 
                                                v-for="item in RateContractSelection"
                                                :key="item.value"
                                                :label="'Vendor Name: '+item.vendor_name+' | Vendor Score: '+item.vendor_score+' | Unit Rate: '+item.unit_rate+ 'USD | Lead Time: ' +item.lead_time+' | Rates’ Contract Reference: '+item.rate_contract_reference+' | Rate Contract Expiry Date: '+item.date"
                                                :value="item">
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                    <hr>
                                </div>
                                <!-- <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col> -->
                                <!--<el-col :span="24"><hr v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceDescription && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>-->
                                <div class="grid-content" v-if="PurchaseEnquiryLine.ServiceDescription && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                    <el-form-item label="temp" prop="ServiceOneOff">
                                        <span slot="label"><b>Will these <i>Services</i> be One-Off or will they be delivered over a period of time?</b></span>
                                        <el-select style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceOneOff" placeholder="Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time?">
                                                
                                            <el-option label="One-Off" value="One/Off"></el-option>
                                            <el-option label="Over A Period Of Time" value="Over A Period Of Time"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                                <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff == 'Over A Period Of Time'">
                                    <el-form-item label="temp" prop="ServiceTerm">
                                        <span slot="label"><b>What Is The Term, In Days, Of The Services?</b></span>
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceTerm" placeholder="What Is The Term, In Days, Of The Services?">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            
                            <!--<el-col :span="24" v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Enter the <i>Instructions To Vendors</i></b></span>
                                        <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                </span>
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -88px; width: 1100px; z-index: 10000;">
                                                                <span class="tooltip-text2">
                                                                    These Headers & their Contents help structure the <i>Instructions To Vendors</i>. They shall, for example, cover aspects such as:
                                                                    <ul>
                                                                        <li>Cover Letter;</li>
                                                                        <li>Pre-qualification Questionnaire (if applicable);</li>
                                                                        <li>Award Timeline;</li>
                                                                        <li>Confidentiality Arrangements;</li>
                                                                        <li>Proposal Evaluation Criteria;</li>
                                                                        <li>Clarification Meeting Arrangements;</li>
                                                                        <li>Request for Information Arrangements;</li>
                                                                        <li>Sites Visits Arrangements;</li>
                                                                        <li>Health and Safety Requirements;</li>
                                                                        <li>Compliance Requirements;</li>
                                                                        <li>Insurrance Cover Requirements;</li>
                                                                        <li>Proposal Submission Checklist;</li>
                                                                        <li>.......</li>
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div class="table-wrapper" style="position: relative; height: 195px; overflow: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse bg-white" style="width: 100%; border-collapse: collapse;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: center; font-weight: bold; padding: 1px;">Header</th>
                                                                        <th style="width: 70%; text-align: center; font-weight: bold; padding: 1px;">Content</th>
                                                                        <th class="text-center" style="width: 5%; padding: 1px;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="background-color: white;">
                                                                        <td style="padding: 5px;">
                                                                            <input 
                                                                                v-model="vendoroffercontent.header_name" 
                                                                                class="form-control"
                                                                                style="font-weight: bold; color: black; background-color: white;">
                                                                        </td>
                                                                        <td style="padding: 5px;">
                                                                            <input 
                                                                                v-model="vendoroffercontent.header_contant" 
                                                                                class="form-control"
                                                                                style="color: black; background-color: white;">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <button 
                                                                                v-if="vendoroffercontent.header_name.trim().length && vendoroffercontent.header_contant.trim().length" 
                                                                                @click.prevent="addTendererContractHeader()" 
                                                                                class="btn-pointer btn-success btn-floating" 
                                                                                style="
                                                                                    height: 40px; 
                                                                                    width: 40px; 
                                                                                    margin: 0; 
                                                                                    margin-top: -5px; 
                                                                                    padding: 0; 
                                                                                    background-color: #008000; 
                                                                                    border-color: #008000; 
                                                                                    color: white; 
                                                                                    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                    transition: all 0.3s ease;
                                                                                " 
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-plus"></i>
                                                                            </button>
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3" style="padding: 1px;">
                                                                            <hr style="border-top: 3px solid black; margin: -2px 0;">
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.VendorOfferContent" 
                                                                        :key="index"
                                                                        style="background-color: white;">
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="vendoroffercontent.header_name" 
                                                                                class="form-control"
                                                                                style="font-weight: bold; color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="vendoroffercontent.header_contant" 
                                                                                class="form-control"
                                                                                :title="`${vendoroffercontent.header_name} || ${vendoroffercontent.header_contant}`"
                                                                                style="color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <button 
                                                                                type="button" 
                                                                                @click="deleteTendererHeader(index)" 
                                                                                class="btn-pointer btn-danger btn-floating" 
                                                                                style="
                                                                                    padding: 0px 13px; 
                                                                                    background-color: #f10623; 
                                                                                    border-color: #f10623; 
                                                                                    color: white; 
                                                                                    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                    transition: all 0.3s ease;
                                                                                " 
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>-->
                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Describe what should be included in the <i>Vendor Proposal Content</i></b></span>
                                            <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i>
                                                                </span>
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -321px; width: 1100px; z-index: 10000;">
                                                                <span class="tooltip-text2">
                                                                    These Headers & their Contents provide clear descriptions of what the vendors must submit to support their commercial proposals.
                                                                    The <i>Vendor Proposal Content</i> may include:
                                                                    <ul>
                                                                        <li>Company Profile;</li>
                                                                        <li>Capability Statements;</li>
                                                                        <li>Key Personnel Bios;</li>
                                                                        <li>Compliance Statements;</li>
                                                                        <li>Technical Proposal;</li>
                                                                        <li>Method Statements;</li>
                                                                        <li>Service Delivery Methodology;</li>
                                                                        <li>Value Proposition;</li>
                                                                        <li>Project Plan and Milestones;</li>
                                                                        <li>Supply Chain Plan;</li>
                                                                        <li>Health and Safety Requirements;</li>
                                                                        <li>References and Case Studies;</li>                                                       
                                                                        <li>Customers Statements;</li> 
                                                                        <li>Warranties and Supports;</li>
                                                                        <li>Products Catalog;</li>
                                                                        <li>Financial Statements;</li>
                                                                        <li>Business Licenses;</li>
                                                                        <li>Proofs of Insurance;</li>
                                                                        <li>Certifications by third-parties;</li>
                                                                        <li>Audit Reports;</li>
                                                                        <li>......</li>
                                                                    </ul>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div class="table-wrapper" style="position: relative; height: 195px; overflow: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse bg-white" style="width: 100%; border-collapse: collapse;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: center; font-weight: bold; padding: 1px;">Header</th>
                                                                        <th style="width: 70%; text-align: center; font-weight: bold; padding: 1px;">Content</th>
                                                                        <th class="text-center" style="width: 5%; padding: 1px;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="background-color: white;">
                                                                        <td style="padding: 5px;">
                                                                            <input 
                                                                                v-model="tenderercontractheader.header_name" 
                                                                                class="form-control"
                                                                                style="font-weight: bold; color: black; background-color: white;">
                                                                        </td>
                                                                        <td style="padding: 5px;">
                                                                            <input 
                                                                                v-model="tenderercontractheader.header_contant" 
                                                                                class="form-control"
                                                                                style="color: black; background-color: white;">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <button 
                                                                                v-if="tenderercontractheader.header_name.trim().length && tenderercontractheader.header_contant.trim().length" 
                                                                                @click.prevent="addTendererContractHeader()" 
                                                                                class="btn-pointer btn-success btn-floating" 
                                                                                style="
                                                                                    height: 40px; 
                                                                                    width: 40px; 
                                                                                    margin: 0; 
                                                                                    margin-top: -5px; 
                                                                                    padding: 0; 
                                                                                    background-color: #008000; 
                                                                                    border-color: #008000; 
                                                                                    color: white; 
                                                                                    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                    transition: all 0.3s ease;
                                                                                " 
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-plus"></i>
                                                                            </button>
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3" style="padding: 1px;">
                                                                            <hr style="border-top: 3px solid black; margin: -2px 0;">
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    <tr v-for="(tenderheader, index) in PurchaseEnquiryLine.TendererContractHeaders" 
                                                                        :key="index"
                                                                        style="background-color: white;">
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="tenderheader.header_name" 
                                                                                class="form-control"
                                                                                style="font-weight: bold; color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="tenderheader.header_contant" 
                                                                                class="form-control"
                                                                                :title="`${tenderheader.header_name} || ${tenderheader.header_contant}`"
                                                                                style="color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <button 
                                                                                type="button" 
                                                                                @click="deleteTendererHeader(index)" 
                                                                                class="btn-pointer btn-danger btn-floating" 
                                                                                style="
                                                                                    padding: 0px 13px; 
                                                                                    background-color: #f10623; 
                                                                                    border-color: #f10623; 
                                                                                    color: white; 
                                                                                    box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                    transition: all 0.3s ease;
                                                                                " 
                                                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                                <i class="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.ServiceOneOff && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Enter the <i>Services Purchase Enquiry Description</i></b></span>
                                        <span style="position: relative; z-index: 9999;" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="position: absolute; bottom: 20px; left: -195px; width: 1100px; z-index: 10000;">
                                                            <span class="tooltip-text2">
                                                                These Headers and their Contents help better structure the <i>Services Purchase Enquiry</i> and describe what the <i>Awardee Vendor</i> must comply with.
                                                                They can include, for example, detailed descriptions of:
                                                                <ul>
                                                                    <li>Scope of Works;</li>
                                                                    <li>Terms and Conditions;</li>
                                                                    <li>Service Levels,</li>
                                                                    <li>KPIs, Performance Metrics, and Penalties;</li>
                                                                    <li>Deliverables;</li>
                                                                    <li>Inclusions;</li>
                                                                    <li>Exclusions;</li>
                                                                    <li>Activities;</li>
                                                                    <li>Project Plan and Milestones;</li>
                                                                    <li>Supply Chain Plan;</li>
                                                                    <li>Health and Safety Plan;</li>
                                                                    <li>Bank Guarrantees & Bonds;</li>
                                                                    <li>Reporting and Reviews;</li>
                                                                    <li>Confidentiality and Data Protection;</li>
                                                                    <li>Dispute Resolution;</li>
                                                                    <li>Compliance and Regulations;</li>
                                                                    <li>Indemnity and Liability;</li>
                                                                    <li>Change Management Arrangements;</li>
                                                                    <li>Contact Information;</li>
                                                                    <li>.....</li>
                                                                </ul>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <div class="table-wrapper" style="position: relative; height: 195px; overflow: auto; width: 100%;">
                                                            <table class="table table-striped thead-inverse bg-white" style="width: 100%; border-collapse: collapse;">
                                                                <thead class="text-white bg-dark" style="position: sticky; top: 0; z-index: 10;">
                                                                    <tr>
                                                                        <th style="width: 25%; text-align: center; font-weight: bold; padding: 1px;">Header</th>
                                                                        <th style="width: 70%; text-align: center; font-weight: bold; padding: 1px;">Content</th>
                                                                        <th class="text-center" style="width: 5%; padding: 1px;"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="background-color: white;">
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="contractheader.name"
                                                                                class="form-control"
                                                                                style="font-weight: bold; color: black; background-color: white;">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <input 
                                                                                v-model="contractheader.contant"
                                                                                class="form-control"
                                                                                style="color: black; background-color: white;">
                                                                        </td>
                                                                        <td class="text-center" style="padding: 5px;">
                                                                            <button 
                                                                                v-if="contractheader.contant.trim().length > 0 && contractheader.name.trim().length > 0" 
                                                                                @click.prevent="addContractHeader()" 
                                                                                class="btn-pointer btn-success btn-floating" 
                                                                                style="
                                                                                height: 40px; 
                                                                                width: 40px; 
                                                                                margin: 0; 
                                                                                margin-top: -5px; 
                                                                                padding: 0; 
                                                                                background-color: #008000; 
                                                                                border-color: #008000; 
                                                                                color: white; 
                                                                                box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); 
                                                                                transition: all 0.3s ease;
                                                                            " 
                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                            
                                                                            <i class="fa fa-plus"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="3" style="padding: 1px;">
                                                                            <hr style="border-top: 3px solid black; margin: -2px 0;">
                                                                    </td>
                                                                </tr>
                                                                <tr v-for="(header, index) in PurchaseEnquiryLine.ContractHeaders"
                                                                    :key="index"
                                                                    style="background-color: white;">
                                                                    <td style="padding: 5px;">
                                                                        <input v-model="header.header_name" 
                                                                        class="form-control"
                                                                        style="font-weight: bold; color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                    </td>
                                                                    <td class="text-center" style="padding: 5px;">
                                                                        <input v-model="header.header_contant" 
                                                                        class="form-control"
                                                                        :title="`${header.header_name} || ${header.header_contant}`"
                                                                        style="color: black; font-size: 14px; background-color: rgba(252, 51, 23, 0.05);">
                                                                    </td>
                                                                    <td class="text-center" style="padding: 5px;">
                                                                        <button type="button" @click="deleteContractHeader(index)" 
                                                                            class="btn-pointer btn-danger btn-floating" 
                                                                            style="padding: 0px 13px; background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease;"
                                                                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                                                            <i class="fa fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.ContractHeaders.length && PurchaseEnquiryLine.Cities.length > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="12" v-if="PurchaseEnquiryLine.ContractHeaders.length && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp">
                                        <span slot="label"><b>Upload any necessary supporting documents</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                            <span class="tooltip-item2">
                                                                                    <span class="fa-stack fa-1x">
                                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                    </span>
                                                                            </span>
                                                                    <span style="bottom: 23px; left: -180px; width: 1150px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>These documents could be as diverse as spreadsheets which the vendors must complete and submit along with their <i>Vendor Proposal</i>, if required.</p>                                                </span>
                                                                    </span>
                                                                </span>
                                    <div style="margin-top: 8px;">
                                        <el-upload
                                            style=""
                                            class="upload-demo"
                                            action="#"
                                            :limit="4"
                                            id='Images'
                                            :auto-upload="false"
                                            ref="ImagesSD"
                                            accept=".jpg, .jpeg, .png, .docx, .doc, .pdf, .xlsx"
                                            :on-change="handleChangeSD"
                                            :on-exceed="handleExceedSD"
                                            :on-remove="handleRemoveSD">
                                            <button type="button" slot="trigger" 
                                                class="btn btn-success"
                                                style="box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); border: none; transition: box-shadow 0.3s ease, transform 0.3s ease;"
                                                onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" 
                                                onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';">
                                                Click to Select Files
                                            </button>
                                                <div class="el-upload__tip" slot="tip">
                                                    JPEG, PNG, DOCX, PDF, XLSX files are allowed with a size of 1Mb or less (A maximum of 4 Files)
                                                </div>
                                        </el-upload>
                                    </div>
                                    </el-form-item>

                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.ContractHeaders.length && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.ContractHeaders.length && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                             
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="TypeOfServicesContract">
                                        <span slot="label"><b>Select the <i>Vendor Proposal</i>  type</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -92px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format. </p>
                                                                    <p>In this case, vendors are required to either submit:
                                                                    <ol>
                                                                        <li>Their total reimbursment cost; or</li> 
                                                                        <li>The breakdown of manpower, subcontractors, tools/equipment & materials reimbursement costs.</li>
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>
                                        <el-select @change="ClearServiceTypeOptions()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.TypeOfServicesContract" placeholder="Type of Service Contract">
                                                
                                            <el-option value="Fixed-Price Proposal">Fixed-Priced Proposal
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>       
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>A <i>Fixed-Price Proposal</i>, also known as a Lump Sum Proposal,
                                                                            is used in situations where <br>the payment doesn’t depend on the
                                                                            resources used or time expended. </p>
                                                                            <p>With a <i>Fixed-Price Proposal</i>, 
                                                                            vendors will estimate the total allowable costs of labor, <br> 
                                                                            materials and equipment, and perform the action specified by
                                                                            the contract regardless<br> of their actual cost.</p>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                            </el-option>
                                            <el-option value="Cost-Reimbursement Proposal">Cost-Reimbursement Proposal
                                                <span style="position: relative; z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>With a <i>Cost-Reimbursement Proposal</i>, the final
                                                                            total cost is determined when the <i>Service</i><br> is completed
                                                                            or at another predetermined date within the <i>Service’s</i> timeframe.</p>
                                                                            <p>Before the <i>Service</i> is started, the <i>Vendor Proposal</i> establishes a ceiling price that the <br>vendor
                                                                            shouldn’t exceed without the approval of the organization.</p> 
                                                                            <p>At the same time, if that ceiling is reached, the vendor can stop the <i>Service</i>.</p>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                            </el-option>
                                            <el-option value="Cost-Plus-Fixed-Fee Proposal">Cost-plus-Fixed-Fee Proposal
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>             
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="position:fixed; width:700px; overflow: auto; word-wrap: break-word;">
                                                                        <span class="tooltip-text2">
                                                                            <p>A <i>Cost-Plus-Fixed-Fee Proposal</i> is a type of <i>Vendor Proposal</i>
                                                                            for situations where the<br> organization agrees to pay the
                                                                            actual cost of the entire <i>Services</i>, including labor, materials,<br>
                                                                            and any unexpected expenses.</p>
                                                                            <p>The word “Plus” refers to the fee that covers the vendor’s profits and overhead.</p>
                                                                            <p>In this case, the organization agrees to pay that extra
                                                                            amount and expects the vendor<br> to deliver on their promise.</p>

                                                                        </span>
                                                                    </span>
                                                                </span>
                                            </el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-Price Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Select a <i>Vendor Proposal</i> required format</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format. </p>
                                                                <p>In this case, vendors are required to either submit:
                                                                    <ol>
                                                                    <li>Their total price (as a lump sum value); or</li> 
                                                                    <li>The breakdown of manpower, subcontractors, tools/equipment & materials prices.</li> 
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                            <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Reimbursement Proposal' && PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Select a <i>Vendor Proposal</i> required format</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format. </p>
                                                                    <p>In this case, vendors are required to either submit:
                                                                    <ol>
                                                                        <li>Their total reimbursment cost; or</li> 
                                                                        <li>The breakdown of manpower, subcontractors, tools/equipment & materials reimbursement costs.</li>
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span>
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                            <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-Plus-Fixed-Fee Proposal' && PurchaseEnquiryLine.Cities.length > 0 && PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                        <span slot="label"><b>Select a <i>Vendor Proposal</i> required format</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>
                                                            </span>
                                                            <span style="bottom: 25px; left: -159px; width: 1000px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>Vendors must submit the commercial component of their <i>Vendor Proposal</i> in a strict accordance with the 
                                                                    specified required format.</p>
                                                                    <p>In this case, vendors are required to either submit:
                                                                    <ol>
                                                                        <li>Their total cost (as a lump sum value), along with their profit and overhead fee as a % of the total cost; or</li> 
                                                                        <li>The breakdown of manpower, subcontractors, tools/equipment & materials costs, along with their profit 
                                                                        and overhead fee as a % of the total cost.</li>
                                                                    </ol></p>
                                                                </span>
                                                            </span>
                                                        </span> 
                                        <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please Select One OF Below">
                                                
                                            <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                            <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <!-- <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="PELineNote">
                                        <span slot="label"><b>Title</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Title"
                                            v-model="PurchaseEnquiryLine.Title">
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-col> -->
                            <el-col :span="24"><hr v-if="PurchaseEnquiryLine.VendorCommercialOffer !== '' && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.VendorCommercialOffer !== '' && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Will you consider offering an <i>Advance Payment</i> for this <i>Services Purchase Enquiry</i> ?</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="fa-stack fa-1x">
                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                    </span>
                                                                </span>
                                                                <span style="bottom: 23px; left: -460px; width: 1250px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>You have the possibility to signal whether or not your 
                                                                        organization is willing to make an <i>Advance Payment</i> to
                                                                        the <i>Awardee Vendor</i>, at the time of the <i>Purchase 
                                                                        Order</i> issuance for the <i>Services</i>. </p>
                                                                        <p>If 'Yes' is selected, the vendors will be able to 
                                                                        submit, in their proposal, their desired <i>Advance Payment</i> 
                                                                        requirement, expressed in percentage of their total
                                                                        commercial proposal.</p>
                                                                        <p>Finally, you can choose whether or not to request collaterals from the <i>Awardee Vendor</i>, 
                                                                        when your organization agrees to the <i>Advance Payment</i>.</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will You Consider Offering An Advance Payment?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment == 'Yes' && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Is a collateral, from the <i>Awardee Vendor</i>, required against the <i>Advance Payment</i> ?</b></span>

                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment" placeholder="Is a collateral required against the Advance Payment ? Choose 'Yes' or 'No'">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">                                    

                                    <el-form-item label="temp" prop="RetentionPercentage">
                                        <span slot="label">
                                            <b>Enter the <i>Retention Percentage</i>, if applicable</b>
                                        </span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: 23px; left: -170px; width: 1250px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Percentage</i> is the portion of a payment, expressed as a percentage, that your organization 
                                                                        <u>temporary</u> withholds from the <i>Awardee Vendor</i> for all <i>Purchase Orders</i> issued under this <i>Rates' Contract</i> until the 
                                                                        <i>Retention Timeframe</i> elapses. </p>
                                                                        <p>This retention acts as a security or guarantee to ensure that the <i>Awardee Vendor</i> 
                                                                        fulfills all contractual obligations, including rectifying any defects or deficiencies identified during or after the 
                                                                        delivery of the <i>Materials</i> or <i>Services</i>.</p>
                                                                        <p>The retention will be released back to the <i>Awardee Vendor</i> as soon as the <i>Retention Timeframe</i> elapses.</p>
                                                                </span>
                                                            </span>
                                                        </span>

                                        <el-input min="0" max="100" type="number" placeholder="Enter The Retention Percentage" v-model.number="PurchaseEnquiryLine.RetentionPercentage"></el-input>
                                    </el-form-item>


                                </div>
                            </el-col> 

                            <el-col :span="24" v-if="PurchaseEnquiryLine.Cities.length > 0 && (PurchaseEnquiryLine.RetentionPercentage != 0 || PurchaseEnquiryLine.RetentionPercentage != '') && PurchaseEnquiryLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="RetentionDays">
                                        <span slot="label"><b>Select the <i>Retention Timeframe</i> </b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: 23px; left: -89px; width: 600px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Timeframe</i> begins on the date when either:
                                                                                    <div style="margin-left: 40px;">
                                                                                        1. The the complete quantity specified in the <i>Purchase Order</i> is received from the <i>Awardee Vendor</i>; or<br>
                                                                                        2. the <i>Purchase Order</i> is closed.<br>                       
                                                                                    </div>
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        
                                        
                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RetentionDays" placeholder="Select Retention Timeframe From The Delivery Date">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>


                            <el-col :span=24 v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0">
                                <div class="grid-content">

                                    <el-form-item label="temp" prop="PELineNote">
                                        <span slot="label"><b>Addtional Notes</b></span>
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="Enter Purchase Enqiury Line Note."
                                            v-model="PurchaseEnquiryLine.PELineNote">
                                        </el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0"><hr style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                           <button v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Line To The Purchase Enquiry</button>

                           <button v-if="PurchaseEnquiryLine.AdvancedPayment && PurchaseEnquiryLine.JobNumber && PurchaseEnquiryLine.Cities.length > 0" type="button" @click="SubmitService" 
                                    class="btn btn-block btn-success waves-effect" style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.4)';" onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)';">
                                    Submit The <i>Services Purchase Enquiry</i> For Approval
                            </button>

                        </el-row>
                    </el-form>

                </div>
            </div>

            <div class="card" v-else>
                <div class="card-header">
                    <h4>Initiate A <i>Services Purchase Enquiry</i></h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Data From Similar <i>Services Purchase Enquiry</i>...</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of PEs in the list -->
        <div id="Purchase_Enqiury_List" class="col-lg-5" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="PurchaseEnquiryAll.length > 0" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Material(s) Purchase Enquiry Lines</h4>
                            </div>
                            <div id="Purchase_Enqiury_List_Table" class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Material Description</th>
                                            <th>Qty</th>
                                            <th>Expected Cost, {{this.projectcurrency.substring(0, 3)}}</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(PurchaseEnquiry, key, index) in PurchaseEnquiryAll" :key="index">
                                            <td v-if="PurchaseEnquiry.ItemNumber != ''">
                                                <span v-if="PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix == null">LoM</span>
                                                <span v-else>{{ PurchaseEnquiry.ItemLibraryDescription.company.lom_prefix }}</span>-{{ PurchaseEnquiry.ItemLibraryDescription.item_group_id }}-{{ PurchaseEnquiry.ItemLibraryDescription.item_ingroup_id }}</td>
                                            <td v-else>{{ PurchaseEnquiry.ItemDescription.substring(0, 20) + '...' }}</td>
                                            <td>{{ PurchaseEnquiry.Quantity }}</td>
                                            <td v-if="PurchaseEnquiry.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiry.RateContractSelected.unit_rate">{{ formatPrice(PurchaseEnquiry.RateContractSelected.unit_rate * PurchaseEnquiry.Quantity)}}</td>
                                            <td v-else> - </td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showPEDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <a style="padding: 6px 8px;" class="btn btn-danger float-left m-l-5" href="#" @click="RemovePE(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p v-if="this.TotalExpectedPrice">Total Expected Cost For the Lines With Rates' Contract, Past Purchase Orders or Past Vendors’ Offers:<b class="font-weight-bold"> {{formatPrice(this.TotalExpectedPrice)}} {{this.projectcurrency.substring(0, 3)}}.</b></p>
                            </div>
                            <div class="card-footer">
                                <button type="button" @click="SubmitPEList" class="btn btn-primary btn-block waves-effect text-center">Submit The Materials Purchase Enquiry For Approval</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-else :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4>Material(s) Purchase Enquiry Lines</h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>Line.</b></th>
                                        <th><b>Item Info</b></th>
                                        <th><b>Quantity</b></th>
                                        <th><b>Actions</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center" colspan="4">Purchase Enquiry List is Empty</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-primary btn-block waves-effect text-center disabled">Submit The Purchase Enquiry For Approval </button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>



        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Locate with Marker</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select The Delivery Location by Dragging and Dropping the Pin on The Map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="PEDetailsModalId" tabindex="-1" aria-labelledby="PEDetailsModalId" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="PELineShow">
                    <div class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo">Material Purchase Enquiry Line ID# {{ PEModalInfoKey + 1 }}</h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Setup Name: </b></td>
                                        <td>{{ JobNumbers[PEModalInfo.JobNumber] }}</td>
                                    </tr>
                                    <tr v-if="PEModalInfo.UnderlyingTransaction">
                                        <td><b class="font-700">Underlying Transaction: </b></td>
                                        <td>{{ PEModalInfo.UnderlyingTransaction }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ PEModalInfo.SourcingPriority }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Enquiry Type: </b></td>
                                        <td>{{ PEModalInfo.EnquiryType }}</td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                        <td><b class="font-700">Material Details: </b></td>
                                        <td>
                                            <span><b class="font-700">LoM-{{ PEModalInfo.ItemLibraryDescription.item_group_id }}-{{ PEModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                            
                                            <span>
                                                <template v-for="index in 20">      
                                                    <span v-if="PEModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                        <b style="text-transform: uppercase; font-weight: 700;">{{ PEModalInfo.ItemLibraryDescription.item_template['field_'+index] }}:</b> {{ PEModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ PEModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                            <span><img :src="'/uploads/ItemMasterPictures/'+PEModalInfo.ItemLibraryDescription.picture" class="img-rounded img-responsive"></span><br>
                                        </td>
                                    </tr>

                                    <tr v-else>
                                        <td><b class="font-700">Material Details: </b></td>
                                        <td class="dont-break-out">
                                            <span> {{ PEModalInfo.ItemDescription }}</span><br><br>
                                            <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ PEModalInfo.UnitOfMeasurement.toUpperCase() }}</span><br>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span><b class="font-700">Quantity:</b> </span><br><br>  
                                        </td>
                                        <td>{{ PEModalInfo.Quantity }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Is Advance Payment Considered? </b></td>
                                        <td>{{ PEModalInfo.AdvancedPayment }}</td>
                                    </tr>
                                    <tr v-if="PEModalInfo.AdvancedPayment == 'Yes'">
                                        <td><b class="font-700">Is A Guarantee Required Against The Advance Payment: </b></td>
                                        <td v-if="PEModalInfo.guarantee_Need_for_advance_payment == 'Yes'">Guarantee Required Against The Advance Payment.</td>
                                        <td v-else>No Guarantee Required Against The Advance Payment.</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">What Is The Percentage Of Retention Payment? </b></td>
                                        <td v-if="PEModalInfo.RetentionPercentage != ''">{{ PEModalInfo.RetentionPercentage }}%</td>
                                        <td v-else>0%</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Retention Timeframe: </b></td>
                                        <td v-if="PEModalInfo.RetentionDays != ''">{{ PEModalInfo.RetentionDays }} Day(s) From The Date Of Full Delivery.</td>
                                        <td v-else>Not Applicable</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr align="center" v-if="PEModalInfo.Images.length > 0" style="display: table-caption;">
                                        <td v-for="(img, index) in PEModalInfo.Images">
                                            <img style="width: 7vw; height: 7vw; text-align: center !important;" :src="img">
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td colspan="2"><b class="font-700">Required Documents: </b></td>
                                        <td colspan="2" v-if="PEModalInfo.RequiredDocuments.length > 0">
                                            <ul v-for="(document, index) in PEModalInfo.RequiredDocuments">
                                                <li>{{ document }}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Documents Required
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><b class="font-700">Additional Note: </b></td>
                                        <td colspan="2">{{ PEModalInfo.PELineNote }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><b class="font-700">Delivery Address Details: </b></td>
                                        <td>
                                            <span v-if="PEModalInfo.EnquiryType == 'Materials'"><b>Country of Delivery:</b> {{ PEModalInfo.Country }}</span>
                                            <span v-else><b>Countries of Delivery:</b> {{ PEModalInfo.Country }}</span><br>
                                            <span v-if="PEModalInfo.EnquiryType == 'Materials'"><b>City of Delivery:</b> {{ PEModalInfo.Cities }}</span>
                                            <span v-else><b>Cities of Delivery:</b> {{ PEModalInfo.Cities }}</span><br>
                                            <span><b>Location Name:</b> {{ PEModalInfo.LocationName }}</span><br>
                                            <span><b>Longitude:</b> {{ PEModalInfo.Longitude }}</span><br>
                                            <span><b>Latitude:</b> {{ PEModalInfo.Latitude }}</span><br>  
                                            
                                        </td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                        <td colspan="2" v-if="PEModalInfo.RateContractSelected.unit_rate"><b class="font-700">Expected Cost: </b></td>
                                        <td colspan="2" v-if="PEModalInfo.RateContractSelected.unit_rate"><b class="font-700"> {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate * PEModalInfo.Quantity) }} {{this.projectcurrency}} </b> From The Selected Rates' Contract </td>

                                    </tr>
                                    <tr v-else>
                                        <td colspan="2"><b class="font-700">Expected Cost: </b></td>
                                        <td colspan="2">This isn't an item from the Library of Materials: There are no Rates’ Contracts for it.</td>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                        <td colspan="2"><b class="font-700">List of Valid Rates’ Contract(s)</b></td>
                                        <table>
                                            <tr>
                                                <th>Vendor Name</th>
                                                <th>Vendor Score</th>
                                                <th>Expiry Date</th>
                                                <th>Unit Rate</th>
                                                <th>Lead Time</th>
                                            </tr>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                <td>{{ item.vendor_name }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.lead_time }}</td>
                                            </tr>
                                        </table>
                                    </tr>
                                    <tr v-if="PEModalInfo.EnquiryFromItemMaster == 'Yes'">
                                        <td colspan="2"><b class="font-700">Selected Rates' Contract:</b></td>
                                        <td colspan="2" v-if="PEModalInfo.RateContractSelected.vendor_name">
                                            <span><b class="font-700">Vendor Name:</b> {{ PEModalInfo.RateContractSelected.vendor_name }}</span><br>
                                            <span><b class="font-700">Vendor Score:</b> {{ PEModalInfo.RateContractSelected.vendor_score }}</span><br>
                                            <span><b class="font-700">Expiry Date:</b> {{ PEModalInfo.RateContractSelected.date }}</span><br>
                                            <span><b class="font-700">Unit Rate:</b> {{ formatPrice(PEModalInfo.RateContractSelected.unit_rate) }}  {{this.projectcurrency}}</span><br>
                                            <span><b class="font-700">Lead Time:</b> {{ PEModalInfo.RateContractSelected.lead_time }}</span><br>
                                        </td>
                                        <td colspan="2" v-else>
                                            No Rates’ Contract was selected for this material.
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                        
                    </div>
                    

                    <div class="col-lg-12">
                        <hr>
                        <div class="d-inline-block">
                            <span><b>Date:</b> {{ new Date() }}</span><br>
                            <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Purchase Enquiry Line Information';window.print()">Download PDF</button>   <br><br>
                        </div>
                        <div class="d-inline-block pull-right">
                            <span><img width="250px" :src="hostName+'/assets/images/poweredby.png'" alt=""></span>
                        </div>
                    </div>

                </div>
            </div>v 
        </div>

    </div>


            
</template>

<script>
    
    //import { CustomJs } from '../../helpers/custom.js';
    import validate from 'validate.js';

    export default {
        name: 'new-purchase-enquiry',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter Retention Amount')); 
                }
                
                if (!Number.isInteger(value)) {
                    callback(new Error('Please Enter Digits Only'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select Retention Days')); 
                } else {
                    callback();
                }
                
            };
            return {

                hostName: window.location.protocol+'//'+window.location.hostname,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
                cities: [],
                JobNumbers: [],
                FoundItems: {},
                docFilelist: [],
                ProjectDetailsLoaded: false,
                ItemRateContractDetails: [],
                RateContractDataLoaded: false,
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                PELineShow: false,
                PElineUp: 'N',
                RateContractSelection: [
                    {
                    value: 1,
                    vendor_name: 'Gucci',
                    vendor_score: '25',
                    unit_rate: '25',
                    lead_time: '1 Day',
                    rate_contract_reference: 'Gucci',
                    date: '17 june 2022',
                    },
                    {
                    value: 2,
                    vendor_name: 'Versace',
                    vendor_score: '30',
                    unit_rate: '30',
                    lead_time: '2 Days',
                    rate_contract_reference: 'Versace',
                    date: '25 march 2022',
                    },
                    // {
                    //   value: 3,
                    //   vendor_name: 'Nike',
                    //   vendor_score: '10',
                    //   unit_rate: '10',
                    //   lead_time: '5 Days',
                    //   rate_contract_reference: 'Nike',
                    //   date: '09 july 2023',
                    // }

                ],
                LastPurchase: [
                    {
                    value: 1,
                    unit_rate: '25 USD',
                    concern: 'Dubai Main Branch',
                    city: 'Dubai',
                    date: '1 Jan 2019',
                    quantity: '50',
                    pobid: 'Purchase Order',
                    },
                    {
                    value: 2,
                    unit_rate: '35 USD',
                    concern: 'Abu Dhabi Branch',
                    city: 'Abu Dhabi',
                    date: '10 Jan 2020',
                    quantity: '150',
                    pobid: 'Purchase Order',
                    }
                
                ],
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    GuaranteeNeedForAdvancePayment: "",
                    ServiceOneOff: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    StoreItemrequest: "No",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    ServiceDescription: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: "",
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    TypeOfServicesContract: '',
                    ContractHeaders:[],
                    TendererContractHeaders:[],
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                    ImagesSD: [],

                },
                minVal: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                PEModalInfoKey: "",
                PEModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true, message: "Please Select A Setup", trigger: ["blur"]
                    }],
                    Country: [{
                        required: true, message: "Please Select A Country", trigger: ["blur"]
                    }],
                    Cities: [{
                        required: true, message: "Please Select A City", trigger: ["blur"]
                    }],
                    TendererContractHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur"]
                    }],
                    ContractHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur"]
                    }],
                    ServiceOneOff: [{
                        required: true,
                        message: "Please Select",
                        trigger: "blur"
                    }],
                    ServiceTimePeriod: [{
                        required: true,
                        message: "Please Select",
                        trigger: "blur"
                    }],
                    VendorCommercialOffer: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    VendorCommercialFormat: [{
                        required: true,
                        message: "Please Vendor Commercial Format",
                        trigger: "blur"
                    }],
                    TypeOfServicesContract: [{
                        required: true,
                        message: "Please Type Of Services Contract",
                        trigger: "blur"
                    }],
                    UnderlyingTransaction: [{
                        required: false,
                        message: "Please Underlying Transaction",
                        trigger: "blur"
                    }],
                    ContractHeaders: [{
                        required: true,
                        message: "Please Header",
                        trigger: "blur"
                    }],
                    Images: [{
                        required: true,
                        message: "Please Select Image",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: true,
                        message: "Please Select Sourcing Priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please Select Transaction Type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter Service Description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please Enter Item Description",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Enter Quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please Select Term of Service",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: "blur"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please Select Retention Days", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: false,
                        message: "Please Enter Location Name.",
                        trigger: "blur"
                    }]
                },
                showQuestionRateMaterial: false,
                RateContractPurcase: [],
                TotalExpectedPrice: '',
                contractheader: {
                    name: '',
                    contant: '',
                },
                tenderercontractheader: {
                    header_name: '',
                    header_contant: '',
                },
                projectcurrency: '',
                }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            ResetRateContractDropdown()
            {
                this.PurchaseEnquiryLine.RateContractSelected = ''
            },
            getProjectCurrency()
            {
                axios.get('/api/data/get-project-currency/' + this.PurchaseEnquiryLine.JobNumber)
                .then((response) => {
                    this.projectcurrency = response.data[0]
                })
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.PurchaseEnquiryLine.ContractHeaders.push({
                        header_name: this.contractheader.name,
                        header_contant: this.contractheader.contant,
                    })
                    this.contractheader.name = ''
                    this.contractheader.contant = ''
                } else {
                    Swal('Error', 'This Contract Header already exist.' , 'error'); 
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.ContractHeaders, index);
            },
            existContractHeader() {
                if (this.PurchaseEnquiryLine.ContractHeaders.find(item => item.header_name === this.contractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.PurchaseEnquiryLine.TendererContractHeaders.push({
                        header_name: this.tenderercontractheader.header_name,
                        header_contant: this.tenderercontractheader.header_contant,
                    })
                    this.tenderercontractheader.header_name = ''
                    this.tenderercontractheader.header_contant = ''
                } else {
                    Swal('Error', 'The Header Already Exists.' , 'error'); 
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.PurchaseEnquiryLine.TendererContractHeaders, index);
            },
            existTendererContractHeader() {
                if (this.PurchaseEnquiryLine.TendererContractHeaders.find(item => item.header_name === this.tenderercontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            ShowPopUpIgnoreRateContract()
            {
                if(this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract == "No")
                {
                    Swal('Warning', 'Please Note That You Have Ignored Rates Contracts With A Lower Unit Rate.' , 'error'); 
                    $(".swal2-tital").css('background-color', '#000');
                }
            },
            checkRateContract()
            {
                event.preventDefault();

                axios.post('/api/data/check_item_rate_contract_details', this.PurchaseEnquiryLine.ItemNumber)
                    .then((response) => {
                        if(response.data)
                        {
                            this.showQuestionRateMaterial = false
                        }
                        else
                        {
                            this.showQuestionRateMaterial = true
                        }
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            checkLowestRateContract()
            {

                var numbers = [25, 30, 10];
							
                var sorted = numbers.slice().sort(function(a, b) {
                return a - b;
                });

                var smallest = sorted[0],                      
                    secondSmallest = sorted[1],                
                    secondLargest = sorted[sorted.length - 2], 
                    largest  = sorted[sorted.length - 1];
                if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate > smallest)
                {
                    Swal('Warning', 'Please Note That You Have Ignored Rates Contracts With A Lower Unit Rate.' , 'error'); 
                }

            },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.PurchaseEnquiryLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                    })
            },
            EnquiryTypeSelection: function(){
                
                if(this.PurchaseEnquiryLine.EnquiryType == "Service" || this.PurchaseEnquiryLine.EnquiryType == ""){
                    $('#Purchase_Enqiury_List').addClass('animated fadeOutRight');
                    setTimeout(function() {
                        $('#Purchase_Enqiury_Form').removeClass('col-lg-7').addClass('col-lg-12');
                    }, 100);

                } else {
                    $('#Purchase_Enqiury_List').removeClass('animated fadeOutRight');
                    $('#Purchase_Enqiury_List').addClass('animated fadeInRight');
                    $('#Purchase_Enqiury_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                }
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RateContractSelected = {};
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RequiredDocuments = "";
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.PurchaseEnquiryLine.TypeOfServicesContract = ""
                this.PurchaseEnquiryLine.VendorCommercialOffer = ""
            },
            handleChangeSD(file, fileList){
                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isPDF = file.raw.type === 'application/pdf';
                const isDOC = file.raw.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isXLSX = file.raw.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                const isLt2M = file.raw.size / 1024 / 1024 < 2;

                if ((isJPG || isPNG || isPDF || isDOC || isXLSX) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                    this.PurchaseEnquiryLine.ImagesSD = image_array;
                }
                else {
                    
                    
                    this.$message.error('Document must be JPG, PNG, PDF or Doc format with size not exceeding 1MB!!');
                    fileList.splice(-1);
                    return false;
                }
                  
            },
            handleExceedSD: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 documents.'  , 'error'); 
            },
            handleRemoveSD(file, fileList) {
                if(!fileList){
                    var fileList = this.$refs.ImagesSD.uploadFiles;
                    fileList.forEach((value, index, object) => {
                    if (value.uid === file.uid) {
                        fileList.splice(index, 1)
                    }
                    })
                }
                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                const reader=new FileReader();
                reader.readAsDataURL(fileList[i].raw);
                reader.onload = function(e) {
                        var rawLog = reader.result;
                          image_array.push(rawLog);
                    };
                
             
                  
                }
                  this.PurchaseEnquiryLine.ImagesSD=image_array;
               
              
            },
            handleChange(file,fileList){

                const isJPG = file.raw.type === 'image/jpeg';
                const isPNG = file.raw.type === 'image/png';
                const isLt2M = file.raw.size / 1024 / 1024 < 2;

                if ((isJPG || isPNG) && isLt2M) {

                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                    const reader=new FileReader();
                    reader.readAsDataURL(fileList[i].raw);
                    reader.onload = function(e) {
                            var rawLog = reader.result;
                            image_array.push(rawLog);
                        };
                    
                    }
                    this.PurchaseEnquiryLine.Images=image_array;
                }
                else {
                    
                    fileList.shift();
                    fileList.shift();
                    this.imageUrl = "";
                    
                    this.$message.error('Avatar picture must be JPG or PNG format with size not exceeding 1MB!!');
                    return false;
                }
                  
            },
            	handleExceed: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 4 pictures of the material.'  , 'error'); 
            },
            handleRemove(file, fileList) {
                var image_array=[];
                for(var i=0;i<fileList.length;i++){

                const reader=new FileReader();
                reader.readAsDataURL(fileList[i].raw);
                reader.onload = function(e) {
                        var rawLog = reader.result;
                          image_array.push(rawLog);
                    };
                
             
                  
                }
                  this.PurchaseEnquiryLine.Images=image_array;
               
              
            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
            getCities() {
                this.cities = [];
                if ((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null) && Array.isArray(this.PurchaseEnquiryLine.Country)){
                    this.PurchaseEnquiryLine.Country.forEach((item) => {
                        this.cities.push(...this.countries[item])
                    });
                }
				else if((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null)){
                  this.cities = this.countries[this.PurchaseEnquiryLine.Country];
               }
                else {
                    this.cities = [];
                }
            },
            AddItem: function() {

                // if(this.CompanySetup.lom_manditory == 'LibrariesNFreeText'){
                //     if(this.PurchaseEnquiryLine.ItemNumber == "" && this.PurchaseEnquiryLine.ItemDescription == ""){
                //         Swal('Missing Item Information', 'Please Select an item from the list or enter item description', 'error');
                //         return false;
                //     }
                // }

                



                // if(this.PurchaseEnquiryLine.Latitude == ""){
                //     Swal('Missing Map Location', 'Please Select a Location on Map to create PE Line', 'error');
                //     return false;
                // }

                this.$refs.PurchaseEnquiryForm.validate((validation) => {




                    if(validation){

                        // Getting Item Description of the selected item
                        if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == "Yes"){
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.PurchaseEnquiryLine.ItemNumber);
                            this.PurchaseEnquiryLine.ItemLibraryDescription = selectedItem;
                          
                        }

                        if(!this.PurchaseEnquiryLine.RateContractSelected.unit_rate && this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                        {
                            Swal({
                                title: 'Are you sure?',
                                text: "There Is At Least One Rates Contract That Covers This Material. Are You Sure You Don't Want To Select A Rates Contract?",
                                type: 'error',
                                showCancelButton: true,
                                cancelButtonText: 'Yes, let me proceed without selecting a Rates Contract!',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'No, let me select a Rates Contract!'
                            }).then((result) => {
                                if (result.value) {
                                    return false;
                                }
                                else{
                                    /* Add Setupname */
                                    //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                    this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                    if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                    {
                                        axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                        .then(function(response){
                                            if(response.data)
                                            {
                                                this.RateContractPurcase = response.data
                                            }
                                        })
                                        if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                        {
                                            Swal('Selected Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                        }
                                    }
                                    this.PurchaseEnquiryLine.AdvancedPayment = "";
                                    this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    this.PurchaseEnquiryLine.RetentionDays = ""
                                    this.PurchaseEnquiryLine.RateContractSelected = {};
                                    // this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                    // this.PurchaseEnquiryLine.ItemNumber = "";
                                    // this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                    // this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                    // this.PurchaseEnquiryLine.ItemDescription = "";
                                    // this.PurchaseEnquiryLine.Quantity = "";
                                    // this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                    // this.PurchaseEnquiryLine.ServiceDescription = "";
                                    // this.PurchaseEnquiryLine.AdvancedPayment = "";
                                    // this.PurchaseEnquiryLine.RetentionPercentage = "";
                                    // this.PurchaseEnquiryLine.RequiredDocuments = "";
                                    // this.PurchaseEnquiryLine.PELineNote = "";
                                    // this.PurchaseEnquiryLine.Longitude = "";
                                    // this.PurchaseEnquiryLine.Latitude = "";
                                    // this.PurchaseEnquiryLine.LocationName = "";

                                    // this.scrollTo(0, 1000);
                                    // totalExpectedPrice(){
                                        // alert('enter');
                                        var sum=0;
                                        // var event = this.PurchaseEnquiryAll;
                                        this.PurchaseEnquiryAll.forEach(function (element) {
                                            if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                            {
                                                sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                            }
                                        })
                                        this.TotalExpectedPrice = sum;
                                    // }
                                }
                            })
                        }
                        else{
                                /* Add Setupname */
                                //this.PurchaseEnquiryLine.SetupName = $('#SetupSelection option:selected').text();

                                this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));
                                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes')
                                {
                                    axios.post('/api/purchase_enquiry/check_purchase_in_past', this.PurchaseEnquiryLine)
                                    .then(function(response){
                                        if(response.data)
                                        {
                                            this.RateContractPurcase = response.data
                                        }
                                    })
                                    if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate )
                                    {
                                        Swal('Selected Rates Contract', 'Unit Rate: '+ '<b>'+this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+ this.projectcurrency+'</b>' + '<br>Expected Cost: ' + '<b>'+this.PurchaseEnquiryLine.Quantity * this.PurchaseEnquiryLine.RateContractSelected.unit_rate+' '+this.projectcurrency+'</b>', 'error');

                                    }
                                }
                                this.PurchaseEnquiryLine.AdvancedPayment = "";
                                this.PurchaseEnquiryLine.RetentionPercentage = "";
                                this.PurchaseEnquiryLine.RetentionDays = ""
                                this.PurchaseEnquiryLine.RateContractSelected = {};
                                // this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                                // this.PurchaseEnquiryLine.ItemNumber = "";
                                // this.PurchaseEnquiryLine.ItemLibraryDescription= "",
                                // this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                                // this.PurchaseEnquiryLine.ItemDescription = "";
                                // this.PurchaseEnquiryLine.Quantity = "";
                                // this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                                // this.PurchaseEnquiryLine.ServiceDescription = "";
                                // this.PurchaseEnquiryLine.AdvancedPayment = "";
                                // this.PurchaseEnquiryLine.RetentionPercentage = "";
                                // this.PurchaseEnquiryLine.RequiredDocuments = "";
                                // this.PurchaseEnquiryLine.PELineNote = "";
                                // this.PurchaseEnquiryLine.Longitude = "";
                                // this.PurchaseEnquiryLine.Latitude = "";
                                // this.PurchaseEnquiryLine.LocationName = "";

                                // this.scrollTo(0, 1000);
                                // totalExpectedPrice(){
                                    // alert('enter');
                                    var sum=0;
                                    // var event = this.PurchaseEnquiryAll;
                                    this.PurchaseEnquiryAll.forEach(function (element) {
                                        if(element.EnquiryType == 'Materials' && element.RateContractSelected.unit_rate)
                                        {
                                            sum = parseInt(sum) + (element.RateContractSelected.unit_rate * element.Quantity)
                                        }
                                    })
                                    this.TotalExpectedPrice = sum;
                                // }
                            }
                        
                    }
                })
            },
            ClearServiceTypeOptions()
            {
                this.PurchaseEnquiryLine.VendorCommercialOffer = "";
            },
            SubmitService() {
                this.$refs.PurchaseEnquiryForm.validate((validation) => {




                if(validation){
                if(this.PurchaseEnquiryLine.ContractHeaders[0])
                {
                    if(this.PurchaseEnquiryLine.TendererContractHeaders[0])
                    {

                        if (event) event.preventDefault();

                        this.PurchaseEnquiryAll.push(Object.assign({}, this.PurchaseEnquiryLine));

                        axios.post('/api/purchase_enquiry/post_similar_purchase_enquiry_request', this.PurchaseEnquiryAll)
                        .then((response) => {
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 10000
                            });

                            this.PurchaseEnquiryLine.AdvancedPayment = "";
                            this.PurchaseEnquiryLine.RetentionPercentage = "";
                            this.PurchaseEnquiryLine.RetentionDays = ""
                            this.PurchaseEnquiryLine.RateContractSelected = {};
                            this.PurchaseEnquiryAll = [];
                            this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                            this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                            this.PurchaseEnquiryLine.EnquiryType = "";` `
                            this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                            this.PurchaseEnquiryLine.ItemNumber = "";
                            this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                            this.PurchaseEnquiryLine.ItemDescription = "";
                            this.PurchaseEnquiryLine.Quantity = "";
                            this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                            this.PurchaseEnquiryLine.ServiceDescription = "";
                            this.PurchaseEnquiryLine.AdvancedPayment = "";
                            this.PurchaseEnquiryLine.RetentionPercentage = "";
                            this.PurchaseEnquiryLine.RetentionDays = "";
                            this.PurchaseEnquiryLine.RequiredDocuments = "";
                            this.PurchaseEnquiryLine.PELineNote = "";
                            this.PurchaseEnquiryLine.ServiceOneOff = "";
                            this.PurchaseEnquiryLine.Longitude = "";
                            this.PurchaseEnquiryLine.Latitude = "";
                            this.PurchaseEnquiryLine.Images = [];
                            this.PurchaseEnquiryLine.LocationName = "";
                            this.ItemRateContractDetails = [];
                            this.RateContractDataLoaded = false;
                            this.PurchaseEnquiryLine.ContractHeaders = [];
                            this.contractheader = {
                                name: '',
                                contant: '',
                            };
                            this.PurchaseEnquiryLine.TendererContractHeaders = [];
                            this.tenderercontractheader = {
                                name: '',
                                contant: '',
                            };

                            if(this.CompanySetup.setup_mode == 'Company Wide'){
                                this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                            }

                            setTimeout(() => {
                                this.$router.push('/app/purchase_enquiry/service-list')
                                }, 2000)
                        })
                        .catch(function(e){
                            console.log(e);
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Service Purchase Enquiry Submit)', 'error');
                        });   
                    }
                    else
                    {
                        Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                    }
                }
                else
                {
                    Swal('Error Occured', 'Please Fill All Mandatory Field!', 'error');
                }
                }
            })

            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            showPEDetails: function(key, event) {
                if (event) event.preventDefault();

                this.PEModalInfoKey = key;
                this.PEModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);
                this.$forceUpdate();

                this.PELineShow = true;

                $('#PEDetailsModalId').modal('toggle');

            },
            RemovePE: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are you sure?',
                  text: "This Line Will Be Removed Permanently.",
                  type: 'error',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        this.PurchaseEnquiryAll.splice(key, 1);
                    }
                })

            },
            SubmitPEList() {
                if (event) event.preventDefault();
                axios.post('/api/purchase_enquiry/post_purchase_enquiry_request', this.PurchaseEnquiryAll)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Enquiry Submit)', 'error');
                    });

                //Clear All Data
                this.PurchaseEnquiryAll = "";
                this.PurchaseEnquiryLine.JobNumber = "";
                this.PurchaseEnquiryLine.UnderlyingTransaction = "";
                this.PurchaseEnquiryLine.SourcingPriority = "Standard";
                this.PurchaseEnquiryLine.EnquiryType = "";
                this.PurchaseEnquiryLine.EnquiryFromItemMaster = "";
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemLibraryDescription = "",
                this.PurchaseEnquiryLine.ItemLibraryUnitOfMeasurement = "",
                this.PurchaseEnquiryLine.ItemDescription = "";
                this.PurchaseEnquiryLine.Quantity = "";
                this.PurchaseEnquiryLine.UnitOfMeasurement = "";
                this.PurchaseEnquiryLine.ServiceDescription = "";
                this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = "";
                this.PurchaseEnquiryLine.AdvancedPayment = "";
                this.PurchaseEnquiryLine.RetentionPercentage = "";
                this.PurchaseEnquiryLine.RetentionDays = ""
                this.PurchaseEnquiryLine.RequiredDocuments = "";
                this.PurchaseEnquiryLine.PELineNote = "";
                this.PurchaseEnquiryLine.Longitude = "";
                this.PurchaseEnquiryLine.Latitude = "";
                this.PurchaseEnquiryLine.LocationName = "";
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryAll = [];
                this.PurchaseEnquiryLine.ServiceOneOff = "";
                this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract = '';
                this.PurchaseEnquiryLine.RateContractSelected = '';
                this.PurchaseEnquiryLine.TypeOfServicesContract = '';
                this.PurchaseEnquiryLine.ContractHeaders = [];
                this.PurchaseEnquiryLine.VendorCommercialOffer = '';
                if(this.CompanySetup.setup_mode == 'Company Wide'){
                    this.PurchaseEnquiryLine.JobNumber = Object.keys(this.JobNumbers)[0];
                }

            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
            EnquiryItemDescriptionSelection(){

                this.PurchaseEnquiryLine.ItemNumber = "";
                this.PurchaseEnquiryLine.ItemDescription = "";
                
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            showRateContract(ItemId){
                event.preventDefault();
                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                .then((response) => {
                    this.ItemRateContractDetails = response.data;
                    this.RateContractDataLoaded = true;
                })
                .catch(function(){
                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                });
            },
            getDataPESimilar(){
                axios.get('/api/data/get_similar_PE_data/' + this.$route.params.id)
                .then((response) => {

                    // this.PurchaseEnquiryLine.JobNumber = response.data.project_id;
                    // this.PurchaseEnquiryLine.SetupName = response.data.;
                    // this.PurchaseEnquiryLine.Country = response.data.country_of_delivery.split(', ');
                    // this.PurchaseEnquiryLine.Cities = response.data.cities_of_delivery.split(', ');
                    this.PurchaseEnquiryLine.UnderlyingTransaction = response.data.underlying_transaction;
                    this.PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment = response.data.guarantee_Need_for_advance_payment;
                    this.PurchaseEnquiryLine.ServiceOneOff = response.data.service_one_off;
                    this.PurchaseEnquiryLine.SourcingPriority = response.data.sourcing_priority;
                    this.PurchaseEnquiryLine.EnquiryType = response.data.enquiry_type;
                    this.PurchaseEnquiryLine.StoreItemrequest = response.data.store_item_request;
                    // this.PurchaseEnquiryLine.EnquiryFromItemMaster = response.data.;
                    this.PurchaseEnquiryLine.ItemNumber = response.data.item_id;
                    // this.PurchaseEnquiryLine.ItemLibraryDescription = response.data.;
                    this.PurchaseEnquiryLine.ItemDescription = response.data.item_description;
                    this.PurchaseEnquiryLine.Quantity = response.data.quantity;
                    // this.PurchaseEnquiryLine.Images = response.data.;
                    this.PurchaseEnquiryLine.UnitOfMeasurement = response.data.u_o_m;
                    this.PurchaseEnquiryLine.ServiceDescription = response.data.service_description;
                    this.PurchaseEnquiryLine.AdvancedPayment = response.data.advanced_payment;
                    this.PurchaseEnquiryLine.RetentionPercentage = response.data.retention_percentage;
                    this.PurchaseEnquiryLine.RetentionDays = response.data.retention_days;
                    this.PurchaseEnquiryLine.RequiredDocuments = response.data.required_documents;
                    this.PurchaseEnquiryLine.PELineNote = response.data.notes;
                    this.PurchaseEnquiryLine.Longitude = response.data.longitude;
                    this.PurchaseEnquiryLine.Latitude = response.data.latitude;
                    this.PurchaseEnquiryLine.LocationName = response.data.location_name;
                    // this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract = response.data.;
                    this.PurchaseEnquiryLine.RateContractSelected = response.data.expected_price;
                    this.PurchaseEnquiryLine.TypeOfServicesContract = response.data.type_of_services_contract;
                    this.PurchaseEnquiryLine.ContractHeaders = response.data.childheaders;
                    this.PurchaseEnquiryLine.TendererContractHeaders = response.data.tendererchildheaders;
                    this.PurchaseEnquiryLine.Title = response.data.title;
                    this.PurchaseEnquiryLine.VendorCommercialOffer = response.data.vendor_commercial_offer;
                    this.PurchaseEnquiryLine.RetentionDaysPercentage = response.data.retention_days_percentage;
                });
            }
        },
        created(){
            this.EnquiryTypeSelection();
            this.getDataPESimilar();
            this.getCountries();

        },
        mounted(){
            
            //CustomJs();
            let self = this;

            axios.get('/api/data/get_user_projects_list')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    axios.get('/api/data/get_company_details')
                    .then((SecondResponse) => {

                        this.CompanySetup = SecondResponse.data;

                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        if(this.CompanySetup.additional_required_1 == "Yes"){
                           this.rules.UnderlyingTransaction[0].required = false; 
                        }

                        self.ProjectDetailsLoaded = true;

                    });
                });


            /*
             * Google Map inside modal
             */
            var initializeMap = function(id) {

                var myLatLng = {lat: 25.2707688, lng: 55.3227913};

                // Map Options
                var mapOptions = {
                    zoom: 10,
                    center: new google.maps.LatLng(25.2707688, 55.3227913),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };


                // Initialize the map with options (inside #map element)
                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    draggable: true

                });


                google.maps.event.addListener(marker, 'position_changed', function () {
                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                });


                // When modal window is open, this script resizes the map and resets the map center
                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                  google.maps.event.trigger(map, "resize");
                  return map.setCenter(myLatLng);
                });

            };

            google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));
            
            this.getCities();
        },
        beforeRouteLeave (to, from, next) {

            if(this.PurchaseEnquiryAll.length > 0 || this.PurchaseEnquiryLine.EnquiryType != ""){
                Swal({
                    title: 'Navigate Away?',
                    text: "Are you sure you want to navigate away, all unsubmitted data will be lost?",
                    type: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('PRO')){
                next();
            } else if (currentUser.hasOwnProperty('PRV')) {
                next('/app/purchase_enquiry/list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

</script>
<style>
    @media print {
      body * {
          visibility: hidden;
      }
      #PEDetailsModalId, #PEDetailsModalId * {
        visibility: visible;
      }
      #PEDetailsModalId {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
</style>
<style scoped>
    
    #Purchase_Enqiury_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Purchase_Enqiury_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        min-width:80%;
    }

    #PEDetailsModalId .modal-dialog {
        min-width:80%;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }
    
    .el-select-dropdown{
        z-index: 1 !important;
       
    }
  

    .el-popper {
        z-index: 1 !important;
        
    }

    .el-scrollbar {
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }
    .el-scrollbar__wrap{
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .el-select-dropdown__item{
        height: 100% !important;
        z-index: 1;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        z-index: 1;
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important;
        z-index: 1;
    }

    .swal2-icon.swal2-error {
        border-color: #74f293;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }



    .dont-break-out {

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

    }
</style>