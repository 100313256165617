<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Vendors Details</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="VendorsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Vendor ID</th>
                                <th>Vendor Name & Alias</th>
                                <th>Vendor Users</th>
                                <th>Rep. Name</th>
                                <th>Landline</th>
                                <th>Address</th>
                                <th>PO BOX</th>
                                <th>Rep. Title</th>
                                <th>Rep. Phone</th>
                                <th>License #</th>
                                <th>License Expiry Date</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>Countries of Operation</th>
                                <th>Cities of Operation</th>
                                <th>Highest Office Name</th>
                                <th>Highest Office Phone</th>
                                <th>What Can Be Sourced From Vendor ?</th>
                                <th>Applicable Library Of Material Templates</th>
                                <th>Vendor Bank</th>
                                <th>Vendor Swift</th>
                                <th>Vendor IBAN</th>
                                <th>Last 3 Years Turnover</th>
                                <th>Establishment Year</th>
                                <th>Represented Brand</th>
                                <th>Exclusive Brand</th>
                                <th>Vendor Type</th>
                                <th>Vendor Score</th>
                                <th>Qualification Status</th>
                                <th>Activation Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="VendorInfoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="VendorInfoModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h3 class="modal-title text-ogeo">Vendor ID#: {{ ViewModalInfo.id }} </h3>
                        </div>
                        <div style="width:10%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;border-radius:25px">
                        </div>
                        <div style="width:45%" v-if="viewDataLoaded">
                            <span style="font-size: 24px; float: right;"><b class="text-ogeo">Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor_score ? ViewModalInfo.vendor_score + '/100' : 'N/A'  }}</b></span>
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="">×</button>
                        </div>

                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table sptable">

                                    <tr>
                                        <td width="40%"><b class="font-500 text-ogeo">Vendor Status: </b></td>
                                        <td class="text-ogeo" v-if="ViewModalInfo.qualified_at">{{ (new Date(ViewModalInfo.lic_expiry_date) > Date.now()) ? 'Valid Qualification' : 'Expired Qualification' }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500 text-ogeo">Vendor Activation Status: </b></td>
                                        <td class="text-ogeo" v-if="ViewModalInfo.qualified_at && ViewModalInfo.active">{{ ViewModalInfo.active == 'Yes' ? 'Active' : 'Inactive' }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Qualified On: </b></td>
                                        <td v-if="ViewModalInfo.qualified_at">{{ ViewModalInfo.qualified_at | formatDateNTime }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500 text-ogeo">Vendor Qualification Expiry Date: </b></td>
                                        <td v-if="ViewModalInfo.qualified_at" class="text-ogeo">{{ ViewModalInfo.lic_expiry_date | formatDateNTime }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <!-- <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-700">Vendor Demand lD#: </b></td>
                                        <td>Not Applicable</td>
                                    </tr> -->
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Legal Name: </b></td>
                                        <td>{{ ViewModalInfo.name }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Alias: </b></td>
                                        <td>{{ ViewModalInfo.vendor_alias }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Website: </b></td>
                                        <td>{{ ViewModalInfo.url }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Phone Number: </b></td>
                                        <td>{{ ViewModalInfo.phone }} </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Vendor Location:</b></td>
                                        <td>
                                            <span><b class="font-700 font-italic">Address:</b> {{ ViewModalInfo.address }}</span><br>
                                            <span><b class="font-700 font-italic">PO Box:</b> {{ ViewModalInfo.po_box }}</span><br>
                                            <span><b class="font-700 font-italic">Country:</b> {{ ViewModalInfo.country }}</span><br>
                                            <span><b class="font-700 font-italic">City:</b> {{ ViewModalInfo.city }}</span><br>

                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Trade License Reference: </b></td>
                                        <td>{{ ViewModalInfo.lic_num }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Trade License Issuing Authority Name: </b></td>
                                        <td>{{ ViewModalInfo.lic_authority }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Establishment Year: </b></td>
                                        <td>{{ ViewModalInfo.open_since }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Countries Of Operations: </b></td>
                                        <td>{{ ViewModalInfo.operating_countries }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Cities Of Operations: </b></td>
                                        <td>{{ ViewModalInfo.operating_cities }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Highest Ranking Officer Name: </b></td>
                                        <td>{{ ViewModalInfo.officer_name }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Highest Ranking Officer Phone: </b></td>
                                        <td>{{ ViewModalInfo.officer_phone }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Name: </b></td>
                                        <td>{{ ViewModalInfo.rep_name }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Title: </b></td>
                                        <td>{{ ViewModalInfo.rep_title }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Phone Number: </b></td>
                                        <td>{{ ViewModalInfo.mobile }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Applicable Library Of Materials Categories: </b></td>
                                        <td><ul class="p-0"><li v-for="(item, index) in ViewModalInfo.item_templates">{{ item.template_name }}</li></ul></td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>

                                </table>
                            </div>

                            <div class="col-lg-6">
                                <table class="table sptable">
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Name: </b></td>
                                        <td>{{ ViewModalInfo.bank_name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Address: </b></td>
                                        <td>{{ ViewModalInfo.bank_address }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Identifier Code (BIC) / SWIFT Code: </b></td>
                                        <td>{{ ViewModalInfo.bank_swift }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor International Bank Account Number (IBAN): </b></td>
                                        <td>{{ ViewModalInfo.bank_iban }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Was The IBAN Verified? </b></td>
                                        <td v-if="!isEmpty(ViewModalInfo.bank_iban_verify)">{{ ViewModalInfo.bank_iban_verify.verified == true ? 'Verified Using '+ViewModalInfo.bank_iban_verify.tool : 'Unverified' }}</td>
                                        <td v-else>Unverified</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Average Of The Last 03 Years' Turnover, In Thousands Of US Dollars: </b></td>
                                        <td>{{ formatPrice(ViewModalInfo.turnover) }} USD</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Type: </b></td>
                                        <td>{{ ViewModalInfo.vendor_type }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Business Activities: </b></td>
                                        <td>{{ ViewModalInfo.provided_service }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">What Can Be Sourced From The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.vendor_dealsIn }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Brands Represented By The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.sold_brands }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Brands Exclusively Represented By The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.exculsive_brands }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <th class="font-700">User Full Name</th>
                                                    <th class="font-700">User Status</th>
                                                    <th class="font-700">User Role</th>
                                                    <th class="font-700">User Assigned At</th>
                                                </thead>
                                                <tbody v-if="ViewModalInfo.users.length">
                                                    <tr v-for="user in ViewModalInfo.users">
                                                        <td>{{ user.name }}</td>
                                                        <td>{{ user.active == 'Yes' ? 'Active' : 'Inactive' }}</td>
                                                        <td v-if="user.pivot.sbm_role == 'Yes' || user.pivot.sim_role == 'Yes'">{{ user.pivot.sbm_role == 'Yes' ? 'Bid Manager, ' : ''  }}{{user.pivot.sim_role == 'Yes' ? 'Invoicing Manager' : ''}}</td><td v-else>N/A</td>
                                                        <td>{{ user.pivot.created_at | formatDateNTime }}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="4" align="center">No Representative Information Available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td colspan="2">
                                            <b class="font-700 text-ogeo" >Qualification History: </b>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <th class="font-700" width="10%">#</th>
                                                    <th class="font-700">Past Qualifications Expiry Dates</th>
                                                </thead>
                                                <tbody v-if="ViewModalInfo.past_qualifications.length">
                                                    <tr v-for="(item, index) in ViewModalInfo.past_qualifications">
                                                        <td>{{ index+1 }}</td>
                                                        <td>{{ item ? item : "Data Missing" | formatDateNTime }}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="4" align="center">No Past Qualification Information Available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>

                                </table>

                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="modal-title text-ogeo">Vendor Qualification Documents:</h4>
                                <div class="col-lg-12">

                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">File Name</th>
                                            <th class="font-700">Uploaded At</th>
                                            <th class="font-700">Action</th>
                                        </thead>
                                        <tbody v-if="ViewModalInfo.qualification_documents.length">
                                            <tr v-for="document in ViewModalInfo.qualification_documents">
                                                <td>{{ document.document_name }}</td>
                                                <td>{{ document.created_at }}</td>
                                                <td>
                                                    <a class="btn btn-success" :href="'/uploads/vendorsQualificationDocs/' + document.auto_generated_name " download> Download</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" align="center">No Qualification Documents Available</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="modal-title text-ogeo">Total Purchase Orders Since Qualificatiion:</h4>
                                <div class="col-lg-12">

                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">Setup Name</th>
                                            <th class="font-700">Month-To-Date Value + Currency</th>
                                            <th class="font-700">Year-To-Date Value + Currency</th>
                                        </thead>
                                        <tbody v-if="ViewModalInfo.purchase_orders">
                                            <tr v-for="document in ViewModalInfo.qualification_documents">
                                                <td colspan="3" align="center">Data Computation Error</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" align="center">No Data Available</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>


                            <div class="col-lg-12">
                                <hr>
                            </div>

                            <!-- <div class="col-lg-12">
                                <h3 class="text-ogeo">History</h3>
                                <table class="display table table-bordered" style="width:80vw !important;">
                                    <thead style="width:80vw !important;">
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Action Description</b></th>
                                            <th><b>Action By</b></th>
                                            <th><b>Action Level</b></th>
                                            <th><b>Decision-<i>Note</i></b></th>
                                            <th><b>Action Date</b></th>
                                            <th><b>Timespan Between Action</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td>{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>

                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div> -->

                            <div class="col-lg-12">
                                <div class="d-inline-block">
                                    <span><b>Date:</b> {{ new Date() }}</span><br>
                                    <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Vendor Information';window.print()">Download PDF</button>   <br><br>
                                </div>
                                <div class="d-inline-block pull-right">
                                    <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>

</template>

<script>

    import validate, { isEmpty } from 'validate.js';

    export default {
        name: 'vendors-list',
        data(){
            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === '' || value === null){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }

                }
            };
            var CheckAlias = (rule, value, callback) => {
                this.checkVendorAlias(value);
                setTimeout(() => {
                if (this.flag) {
                    callback();
                } else {

                    if(value === '' || value === null){
                        callback(new Error('Please Enter Vendor Alias'));
                    } else if(!this.flag) {
                        callback(new Error('Vendor Alias Already Taken!'));
                    }
                    else
                        callback();
                }
            }, 1000);
            };
            return{
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() < Date.now();
                  },
                },
                DataTable: "",
                OriginalName: "",
                CompanySetup: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                ViewModalInfo: {},
                VerifyInformation: false,
                ItemTemplates: [],
                Countries: {},
                VendorSC: [],
                TmpVC: [],
                flag: "",
                cities: [],
                multiCities: [],
                FieldOfBusiness: [
                    {name: "Information Technology Services"},
                    {name: "Professional Services - Legal"},
                    {name: "Professional Services - Marketing"},
                    {name: "Professional Services - Consultancy"},
                    {name: "Professional Services - Design"},
                    {name: "Professional Services - Project Management"},
                    {name: "Professional Services - Security"},
                    {name: "Professional Services - Gardening"},
                    {name: "Professional Services - Cleaning"},
                    {name: "Transportation Services"},
                    {name: "Medicine And Wellness Services"},
                    {name: "Convenience Services"},
                    {name: "Financial Services"},
                    {name: "Contracting Services"},
                    {name: "Construction & Fit-out Services"},
                    {name: "Supply of Mechanical Spare Parts"},
                    {name: "Supply of Electrical Spare Parts"},
                    {name: "Supply of Construction Materials"},
                    {name: "Supply of Foodstuff & Edible Products"},
                    {name: "Supply of Tools"},
                    {name: "Supply of OEM Equipment"},
                    {name: "Supply of Furniture"},
                    {name: "Supply of Vehicles"},
                    {name: "Supply of Textile Products"},
                    {name: "Supply of IT Products"},
                    {name: "Supply of Fossil Fuels"},
                    {name: "Supply of Stationary Products"},
                    {name: "Supply of Packaging Products"},
                    {name: "Supply of Fragrance Products"},
                    {name: "Supply of Cosmetic Products"},
                    {name: "Supply of Cleaning Products"},
                    {name: "Supply of Personal Hygienic Products"},
                    {name: "Supply of Consumer Electronics Products"},
                    {name: "Supply of Whitegoods Products"},
                    {name: "Supply of Garment Products"},
                ],
                EditModalInfo: {},
                rules: {
                    name: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    vendor_alias: [{
                        required: false,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    rep_name: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    rep_title: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    address: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    po_box: [{
                        required: true,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    phone: [{
                        required: true,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    mobile: [{
                        required: true,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    lic_expiry_date: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    url: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    notes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    VendorDocuments: [{
                        required: false,
                        message: "Please Upload At least one documenet",
                        trigger: "blur"
                    }],
                    city: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    country: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    provided_service: [{
                        required: true,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    officer_name: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    officer_phone: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    bank_name: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_iban: [{
                        required: false,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    turnover: [{
                        required: false,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "blur"
                    }],
                    open_since: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    sold_brands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    exculsive_brands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    item_templates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    vendor_type: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                }
            }
        },
        methods: {
            RemoveFile(DocumentId, Index){
                if (event) event.preventDefault();
                let self = this;

                Swal({
                    title: 'Delete This Document?',
                    text: "Are you sure you what to permanently delete this document.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {

                        axios.post('/api/vendors_management/remove_qualification_doc', {DocumentId: DocumentId})
                            .then((response) => {

                                Swal({
                                    type: response.data.messageType,
                                    title: response.data.messageTitle,
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });

                                self.EditModalInfo.qualification_documents.splice(Index, 1);
                            })
                    }

                })
            },
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            FileCount(file) {
                this.EditModalInfo.qualification_documents.push({ name:file.name.split('.').slice(0, -1).join('.'), ext: file.name.split('.').pop(), timestamp:moment().format('DD,MMM,YYYY')})
            },
            getCountries() {
                axios.get('api/get/countries')
                    .then((res) => {
                        this.Countries = res.data
                    })
                    .catch((err) => {
                    })
            },
            getCities(e) {
                // this.cities= this.countries[this.EditModalInfo.VendorCountry];
                axios.get('api/get/cities/' + e)
                .then((res) => {
                    this.cities = res.data
                })
            },
            getMultiCities(e) {
                axios.post('api/get/cities-all', e)
                .then((res) => {
                    this.multiCities = res.data
                })
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            checkVendorAlias(e){
                 axios.post('/api/vendors_management/check_vendor_alias_duplicates', {name: e, id: this.EditModalInfo.id})
                .then((response) => {
                    if(response.data == 'Yes') this.flag = false;
                    else this.flag = true;
                     });
            },
            EditVendorDetailsForm: function(){

                let self = this;

                if(this.VerifyInformation){

                    this.EditModalInfo.operating_countries = [];
                    this.Countries.some((el) => {
                                    if(this.VendorSC.includes(el.id))
                                        this.EditModalInfo.operating_countries.push(el.country_name);
                                    if(this.TmpVC == el.id)
                                        this.EditModalInfo.country = el.country_name;
                                });

                    if (event) event.preventDefault();

                    this.$refs.VendorEditRequestForm.validate((validation) => {

                        if(validation){


                            //Validate File Size
                            /*const isJPG = file.type === 'image/jpeg';
                            const isLt2M = file.size / 1024 / 1024 < 2;

                            if (!isJPG) {
                              this.$message.error('Avatar picture must be JPG format!');
                            }
                            if (!isLt2M) {
                              this.$message.error('Avatar picture size can not exceed 2MB!');
                            }
                            return isJPG && isLt2M;*/


                            axios.post('/api/vendors_management/check_vendor_name_duplicates', {name: this.EditModalInfo.name})
                                .then((response) => {

                                    if(response.data == 'Yes' && this.OriginalName != this.EditModalInfo.name){

                                        Swal({
                                            title: 'Vendor Name Duplicate!',
                                            text: "There is Another Vendor with the Same Name, Do You Want to Proceed?",
                                            type: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Yes',
                                            cancelButtonText: 'No'
                                        }).then((result) => {

                                            if (result.value) {

                                                let formData = new FormData();

                                                for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                                    let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                                    formData.append('FileUploads['+i+']', file);
                                                }


                                                formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));
                                                this.EditModalInfo.item_templates.forEach((data, index) => {
                                                    formData.append('EditModalInfo.item_templates[]', data);
                                                });

                                                axios.post( '/api/vendors_management/edit_vendor_details',
                                                    formData,
                                                    {
                                                        headers: {
                                                            'Content-Type': 'multipart/form-data'
                                                        }
                                                    }
                                                ).then(function(response){
                                                    Swal({
                                                        type: response.data.messageType,
                                                        title: response.data.messageTitle,
                                                        text: response.data.message,
                                                        showConfirmButton: true,
                                                        timer: 5000
                                                    });

                                                    self.VerifyInformation = false;

                                                    self.DataTable.ajax.reload( null, false );

                                                    $('#EditViewModal').modal('toggle');
                                                })
                                                .catch(function(){
                                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                                });

                                            }

                                        })



                                    } else {

                                        let formData = new FormData();

                                        for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                            let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                            formData.append('FileUploads['+i+']', file);
                                        }


                                        formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));

                                        this.EditModalInfo.item_templates.forEach((data, index) => {
                                                formData.append('EditModalInfo.item_templates[]', data);
                                            });
                                        axios.post( '/api/vendors_management/edit_vendor_details',
                                            formData,
                                            {
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }
                                            }
                                        ).then(function(response){
                                            Swal({
                                                type: response.data.messageType,
                                                title: response.data.messageTitle,
                                                text: response.data.message,
                                                showConfirmButton: true,
                                                timer: 5000
                                            });

                                            self.VerifyInformation = false;

                                            self.DataTable.ajax.reload( null, false );

                                            $('#EditViewModal').modal('toggle');
                                        })
                                        .catch(function(){
                                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                        });


                                    }

                                })






                        }

                    });
                }

            },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')

            },
            showViewModal(recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_vendor_details', {VendorID: recordId})
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        if(this.ViewModalInfo.bank_iban_verify)
                            this.ViewModalInfo.bank_iban_verify = JSON.parse(this.ViewModalInfo.bank_iban_verify);
                        this.viewDataLoaded = true
                        $('#VendorInfoModal').modal('toggle');
                    })

                this.viewDataLoaded = false;

            },
            editViewModal(recordId){
                let self = this;
                this.ViewModalInfo = {};
                axios.post('/api/data/get_vendor_details', {VendorID: recordId})
                    .then((response) => {
                        self.EditModalInfo = response.data;

                        self.OriginalName = self.EditModalInfo.name;
                        if(self.EditModalInfo.provided_service)
                            self.EditModalInfo.provided_service = self.EditModalInfo.provided_service.split(',');
                        if(self.EditModalInfo.country)
                            self.TmpVC = self.EditModalInfo.country;
                        if(self.EditModalInfo.operating_countries)
                            self.VendorSC = self.EditModalInfo.operating_countries.split(',');
                        if(self.EditModalInfo.operating_cities)
                            self.EditModalInfo.operating_cities = self.EditModalInfo.operating_cities.split(',');
                        if(self.EditModalInfo.lic_expiry_date)
                            self.EditModalInfo.lic_expiry_date = new Date(self.EditModalInfo.lic_expiry_date);

                        var TmpTemp = self.EditModalInfo.item_templates;
                        self.EditModalInfo.item_templates = [];

                        TmpTemp.forEach((data, index) => {
                            self.EditModalInfo.item_templates.push(data.id);
                        });
                        self.editDataLoaded = true;
                        $('#EditViewModal').modal('toggle');
                    })

                this.viewDataLoaded = false;

            },
            ToggleActivation(recordId){
                let self = this;
                axios.post('/api/vendors_management/toggle_vendor_activation', {VendorID: recordId})
                    .then((response) => {

                        Swal({
                            type: response.data.messageType,
                            title: response.data.messageTitle,
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            },

        },
        mounted(){
            this.getCountries();
            let self = this;
            axios.get('/api/data/get_item_template_list')
            .then((response) => {
                self.ItemTemplates = response.data;
            })
            axios.get('/api/data/get_company_details')
            .then((response) => {
                // console.log(response.data);
                self.CompanySetup = response.data;
            });
            setTimeout(() => {
            const table = $('#VendorsList').DataTable({
                fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_vendors_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'name', render: (data, row, full) => {
                        return data + " (" + full.vendor_alias + ")"
                    } },
                    { data: 'vendor_users', name: 'vendor_users' },
                    { data: 'rep_name', name: 'rep_name' },
                    { data: 'phone', name: 'phone' },
                    { data: 'address', name: 'address' },
                    { data: 'po_box', name: 'phone' },
                    { data: 'rep_title', name: 'rep_title' },
                    { data: 'mobile', name: 'mobile' },
                    { data: 'lic_num', name: 'lic_num' },
                    { data: 'lic_expiry_date', name: 'lic_expiry_date' },
                    { data: 'country', name: 'country' },
                    { data: 'city', name: 'city' },
                    { data: 'operating_countries', name: 'operating_countries' },
                    { data: 'operating_cities', name: 'operating_cities' },
                    { data: 'officer_name', name: 'officer_name' },
                    { data: 'officer_phone', name: 'officer_phone' },
                    { data: 'vendor_dealsIn', name: 'vendor_dealsIn' },
                    { data: 'linked_item_templates', render: (data, row, full) => {
                        var templates = "";
                        full.item_templates.map(item => templates = templates + item.template_name + ", ")
                        return templates
                    } },
                    { data: 'bank_name', name: 'bank_name' },
                    { data: 'bank_swift', name: 'bank_iban' },
                    { data: 'bank_iban', name: 'bank_iban' },
                    { data: 'turnover', render: function(data) {
                        if(data){
                            var currency = (data/1).toFixed(2).replace(',', '.');
                            return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD";
                        }
                        else
                            return "0 USD";
                    } },
                    { data: 'open_since', name: 'open_since' },
                    { data: 'sold_brands', name: 'sold_brands' },
                    { data: 'exculsive_brands', name: 'exculsive_brands' },
                    { data: 'vendor_type', name: 'vendor_type' },
                    { data: 'vendor_score', render: (data, row, full) => {
                        if(data)
                            return data
                        else
                            return 'N/A'
                    } },
                    { data: 'status', name: 'status' },
                    { data: 'active', name: 'active' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                columnDefs: [
                    {  targets: 30, responsivePriority: 1 },
                    {  targets: 0, responsivePriority: 2 },
                    {  targets: 29, responsivePriority: 4 },
                    {  targets: 28, responsivePriority: 3 },
                    {  targets: 27, responsivePriority: 3 },
                    {  targets: 2, width: '30%' },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 0, "ASC" ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'VendorsList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
            });

            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $this.parent().parent().find(">:first-child").text();
                //Check if Id has prefix and resolve
                if (RecordID.includes("-")){
                    RecordID = RecordID.substring(RecordID.indexOf("-") + 1);
                }
                self.showViewModal(RecordID);
            });

            self.DataTable = table;

        }, 500);


        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>
<style>
@media print {
  body * {
    visibility: hidden;
  }
  #VendorInfoModal, #VendorInfoModal * {
    visibility: visible;
  }
  #VendorInfoModal {
    position: relative;
    left: 0;
    top: 0;
  }
}



</style>
<style scoped>
    .sptable >tr>td{
      height:20px;
      padding:5px;
      border-top: 0px;
    }


    .font-500{
        font-weight: 700;
    }
    .font-700{
        font-weight: 700;
    }

    #VendorInfoModal .modal-dialog {
        min-width:80%;
    }

    #EditViewModal .modal-dialog {
        min-width:80%;
    }

</style>
