<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div v-if="showList == false">
                <span slot="label"><b>Select A Organization</b></span>
                <el-select filterable style="width: 100%; padding: 0px;" @change="getData()" v-model="record.company_id" placeholder="Select Organization">
                    <el-option v-for="(item, index) in companies" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <div class="card-header">
                    <h4>Library of Materials</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="ItemsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Created Under Setup</th>
                                <th>Requester Name</th>
                                <th>Template Name</th>
                                <th>Description</th>
                                <th>Unit of Measurement</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
            
</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'purchase-enquiry-list',
        data(){
            return{
                DataTable: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                cancelDataLoaded: false,
                RFIDataLoaded: false,
                POLineRFIDataLoaded: false,
                RequiredDocsDataLoaded: false,
                showList: false,
                companies: [],
                record: {
                    company_id: '',
                },
                EditModalInfo: {
                    purchase_order: '',
                },
                CancellationInfo: {
                    ReceiptNoteId: "",
                    CancelledQty: "",
                    CancellationReason: "",
                    CancellationNotes: "",
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            getData(){
                const table = $('#ItemsList').DataTable({
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_item_master_list_uncovered/' + this.record.company_id,
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'creator.name', name: 'creator.name' },
                        { data: 'item_template.template_name', name: 'item_template.template_name', searchable: false },
                        { data: 'complete_description_with_headers', name: 'complete_description_with_headers' },
                        { data: 'u_o_m', name: 'u_o_m' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: 3 },
                        { responsivePriority: 2, targets: 1 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },    
                });

                const self = this

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.activations-placeholder', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    var CurrentStatus = $(this).data('currentstatus');
                    self.ActivateDeactivate(RecordID, CurrentStatus);
                });

                self.DataTable = table;
            }
        },
        mounted(){
            axios.get('/api/data/get_materials_companies_list')
            .then((response) => {
                this.companies = response.data
            });

        },
    }

</script>


<style scoped>

    .font-500{
        font-weight: 500;
    }

    #PurchaseOrderUpdateModalView .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderCancellationModal .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderValidationModalEdit .modal-dialog {
        min-width:90%;
    }

    #RFIModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #RequiredDocsModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #POLineRFIDetailsModal .modal-dialog {
        top: 3%;
        min-width:75%;
    }

    .poline-approved {
        background-color: rgb(69, 249, 132, 0.1);
    }

    .poline-rejected {
        background-color: rgb(249, 69, 72, 0.08);
    }

    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }

</style>