<template>
        
        <div class="row">
        <div id='Rate_Contract_Request_Form' class="col-lg-7">
            <div class="card" v-if="CompanyDetailsLoaded">
                <div class="card-header">
                    <h4>Create a Rates' Contract Creation Request</h4>
                </div>
                <div class="card-body">
                    <el-form :model="RateContractItemLine" class="demo-form-inline" :rules="rules" ref="RateContractRequestForm">

                        <el-row :gutter="20">  


                            <el-col :span="24">
                                <div class="grid-content">
                                    <span class="text-ogeo font-700">Rates’ Contracts are pre-negotiated agreements with 
                                    vendors intended to freeze unit prices and lead times for materials 
                                    (strictly those from the Library of Materials) and services, before the 
                                    demand for them actually arises, so as to reduce cost and workload 
                                    (Purchase Enquiries linked to Rates’ Contracts can be directly turned into 
                                    Purchase Orders). Establishing Rates’ Contract starts with raising a 
                                    formal Rates’ Contract Creation Requests.</span>
                                </div><br>
                            </el-col>                     
                            <el-col :span="24">
                                <div class="grid-content">

                                            <el-form-item label="temp" prop="JobNumber" >
                                            <span slot="label"><b>Select A Setup</b></span>


                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" class="setUp" id="setUp"  @change="getProjectCurrency($event)" v-model="RateContractItemLine.JobNumber" placeholder="Select Setup ">
                                            
                                            <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>                      
                            
                            <el-col :span="24"><hr></el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item style="font-weight: bolder" label="temp" prop="RCType">
                                        <span slot="label"><b>Select Rates’ Contract Type</b></span>

                                        <el-select disabled @change="RCTypeSelection($event)" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RCType" placeholder="Select Rates’ Contract Type (Materials Or Services)">
                                                
                                            <el-option label="Materials" value="Material"></el-option>
                                            <el-option label="Services" value="Service"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber"><hr></el-col>

                            <el-col :span="12" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCountry">
                                        <span slot="label"><b>Select Country</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 50px">
                                                <span class="tooltip-text2">Select the country where the materials under the Rates' Contract are needed. (1 country only)</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.CompanyCountry" placeholder="Select Country">
                                            
                                            
                                            <el-option
                                                v-for="(item,CompanyCountry) in countries"
                                                :key="CompanyCountry"
                                                :label="CompanyCountry"
                                                :value="CompanyCountry">
                                                <span style="float: left">{{ CompanyCountry }}</span>
                                            </el-option>

                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="12" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item prop="CompanyCities">
                                        <span slot="label"><b>Select Cities</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                            <span class="tooltip-item2">
                                                <span class="fa-stack fa-1x">
                                                    <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                </span>
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -100px">
                                                <span class="tooltip-text2">Select the cities where the materials under the Rates' Contract are needed . ( Multiple Cities Possible)</span>
                                            </span>
                                        </span>
                                        <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="RateContractItemLine.CompanyCities" placeholder="Select City">
                                            
                                            <el-option
                                                v-for="(item,companyCity) in cities"
                                                :key="companyCity"
                                                :value="item"
                                                :label="item">
                                            <span style="float: left">{{ item }}</span>
                                            </el-option>
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24"><hr></el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="SourcingPriority">
                                        <span slot="label"><b>Sourcing Priority</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                </span>
                                            </span>
                                        </span>
                                        
                                        <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" v-model="RateContractItemLine.SourcingPriority" placeholder="">
                                                
                                            <el-option label="Standard" value="Standard"></el-option>
                                            <el-option label="Urgent" value="Urgent"></el-option>
                                            <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                
                                        </el-select>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="UnderlyingTransaction">
                                        <span slot="label"><b>Rates’ Contract Underlying Transaction Reference</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    <strong>The Underlying Transaction Reference</strong> is the reference of the transaction  that has led to the creation of this specific Purchase Enquiry. It can be a particular Job Ticket, a Sales Order, a Work Order…etc
                                                </span>
                                            </span>
                                        </span>

                                        <el-input v-model="RateContractItemLine.UnderlyingTransaction" :disabled="itemAdditionFlag == 'Y'" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber"><hr></el-col>

                            <el-col :span="24" v-if="this.RateContractItemLine.JobNumber">
                                <div class="grid-content">

                                    <!-- <el-form-item label="What should be the term, in months,of the requested Rates' Contract?" prop="RateContractItemPeriod"> -->
                                        
                                    <el-form-item label="temp" prop="RateContractItemPeriod">
                                        <span slot="label"><b>What Will Be The Term, In Months, Of The Requested Rates' Contract?</b></span>
                                        
                                         <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"> <span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -120px;">
                                                <span class="tooltip-text2">
                                                    Rates' Contract signed on the back of this request will be valid for this term, starting from the date of the Rates' Contract implementation.
                                                </span>
                                            </span>
                                        </span>
                                        <el-input id="expTerm" :disabled="itemAdditionFlag == 'Y'" class="expTerm"  type="number" oninput="this.value = Math.floor(this.value);" placeholder="Enter Expected Term" v-model="RateContractItemLine.RateContractItemPeriod">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col>
 
                            <el-col :span=24 v-if="RateContractItemLine.RCType"><hr></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label" class=""><b>Will Advance Payments Be Considered For Items Sourced Under The Rates’ Contract?</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -150px">
                                                <span class="tooltip-text2">
                                                    I have the possibility to signal whether or 
                                                    not my organization is willing to make 
                                                    advance payments to the awardee vendor at 
                                                    the time of the items’ Purchase Orders. If “yes” 
                                                    is selected, the vendors will be able to submit,
                                                    in their offers, their desired advance 
                                                    payments, expressed in percentage of their 
                                                    total offer.
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.AdvancedPayment" placeholder="Will Advance Payments Be Considered For Items Sourced Under The Rates’ Contract?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="RateContractItemLine.AdvancedPayment == 'Yes'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="AdvancedPayment">
                                        <span slot="label"><b>Are Guarantees Required Against The Advance Payments?</b></span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.GuaranteeNeedForAdvancePayment" placeholder="Are Guarantees Required Against The Advance Payments?">
                                                
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                                
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType"><hr></el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType" >
                                <div class="grid-content">                                    

                                    <el-form-item label="temp" prop="RetentionPercentage">
                                        <span slot="label"><b>Enter The Retention Percentage (If Applicable)</b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    Retention is a percentage of the amount certified as due to 
                                                    the awardee vendor, which is temporarily deducted from the amount due and 
                                                    retained by the organization. The purpose of retention is to ensure that the vendors 
                                                    properly complete the activities required of them. This amount will be released back 
                                                    to the vendor at the expiry of the Retention Timeframe.                                                
                                                </span>
                                            </span>
                                        </span>

                                        <el-input :disabled="itemAdditionFlag == 'Y'" min='0.0001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="RateContractItemLine.RetentionPercentage">
                                        </el-input>
                                    </el-form-item>

                                </div>
                            </el-col> 
                            
                            <el-col :span="24" v-if="RateContractItemLine.RetentionPercentage != 0 || RateContractItemLine.RetentionPercentage != ''" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="RetentionDays">
                                        <span slot="label"><b>Select The Retention Timeframe</b></span>
                                        
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    The Retention Timeframe starts from the date of either receiving the complete quantity specified in the Purchase Order or closing the Purchase Order.                                           
                                                </span>
                                            </span>
                                        </span>

                                        <el-select :disabled="itemAdditionFlag == 'Y'" filterable style="width: 100%; padding: 0px;" v-model="RateContractItemLine.RetentionDays" placeholder="Select The Retention Timeframe">
                                            
                                            <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                            
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>


                            <el-col :span="24" v-if="RateContractItemLine.RCType"><hr></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceDescription">
                                        <span slot="label"><b>Enter Service Rates’ Contract Description</b></span>
                                        <el-input
                                            :disabled="itemAdditionFlag == 'Y'"
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter Complete Description Of The Service Required."
                                            v-model="RateContractItemLine.ServiceDescription">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorInstructionHeaders">
                                        <span slot="label"><b>Instruction To Vendors</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the instructions to vendors. as well as the conditions of award. They can 
                                                                    include Inclusions, Exclusions, Vendors Offers’ Contents, Quantity, Biding Instructions, Date of bid 
                                                                    submittals, Bid Scoring Sheet, .....
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorInstructionHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.name">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.content">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendorinstructionheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorOfferContent">
                                        <span slot="label"><b>Vendor Offer Content</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe what the consulted vendors must submit to support their commercial 
                                                                    proposal. The Vendor Offer Content can include, Method Statements, Service Delivery Methodology, Reports, 
                                                                    Attestations, Customers Statements, Products Catalogue, Compliance Statements, Financial Statements, Bank 
                                                                    Guarantee Letter, Licenses, Proof of Capability, CVs, Timetables ...
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.VendorOfferContent"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button :disabled="itemAdditionFlag == 'Y'" style="padding: 0px 13px" type="button" @click="deleteVendorOfferContent(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.name">
                                                    </td>
                                                    <td>
                                                        <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendoroffercontent.content">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="vendoroffercontent.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addVendorOfferContent()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'" >
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="ServiceContractHeaders">
                                        <span slot="label"><b>Service Rates’ Contract Draft</b></span>
                                        <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                            <thead class="text-white bg-dark">
                                                <tr style="width:25%">
                                                    <th><span style="color:white">*</span>Header
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                
                                                            </span>
                                                            <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                <span class="tooltip-text2">
                                                                    These headers help better describe the Service Rates’ Contract which will govern the relationship 
                                                                    with the awardee vendor. The Service Contract can include, for example, a detailed description of the 
                                                                    Scope of Works, the required Activities, Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs 
                                                                    (Key Performance Indicators), Penalty Matrixes, desired Manpower Skills, Invoicing Instructions, 
                                                                    Payment Arrangement, Termination & Suspension Conditions, Arbitration, Force Majeure, IP rights, 
                                                                    Arbitration & Litigation...... Quantities are captured under the Service Rates’ Contract Unit Of 
                                                                    Measurement.
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th style="width:70%">Content</th>
                                                    <th class="text-center" width="70"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(header, index) in RateContractItemLine.ServiceContractHeaders"
                                                    :key="index">
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.name" class="form-control">
                                                    </td>
                                                    <td>
                                                        <input :disabled="itemAdditionFlag == 'Y'" v-model="header.content" class="form-control">
                                                    </td>
                                                    <td class="text-center">
                                                        <button style="padding: 0px 13px" type="button" :disabled="itemAdditionFlag == 'Y'" @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.name">
                                                    </td>
                                                    <td>
                                                        <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.content">
                                                    </td>
                                                    <td class="text-center">
                                                        <button v-if="servicecontractheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'"><hr></el-col>

                            <el-col :span="24" v-if="RateContractItemLine.RCType == 'Service'">
                                <div class="grid-content">
                                    
                                    <el-form-item label="temp" prop="ServiceUOM">
                                        <span slot="label"><b>Enter A Service Line Unit Of Measurement</b></span>
                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: -140px;">
                                                <span class="tooltip-text2">
                                                    This must be a very detailed Service Line Unit Of Measurement for which rates are sought from The Vendors. A Unit Of Measurement can be, for example, “Housekeeping services for 8 hours a day, 5 days a week.”, “Social Media Management services, for 30 days, by a digital marketer with 10+ years of experience in Saas products.”
                                                </span>
                                            </span>
                                        </span>
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="Enter A Service Line Unit Of Measurement."
                                            v-model="RateContractItemLine.ServiceUOM">
                                        </el-input>
                                    </el-form-item>
                                    <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                    
                                </div>
                            </el-col>
                            <el-col :span=24 v-if="RateContractItemLine.RCType == 'Service'"><hr></el-col>


                            <!-- ----------------------------------- -->
                            <button v-if="this.RateContractItemLine.JobNumber && RateContractItemLine.RCType == 'Service'" type="button" @click="AddItem" class="btn btn-success btn-block waves-effect text-center">Add The Item To The Service Rates’ Contract Creation Request</button>

                        </el-row>
                    </el-form>

                </div>
                <!-- <div class="card-body" v-else>
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-danger">Rates' Contract Request Are Available Only For Library of Materials Items</h3>
                        </el-col>
                    </el-row>
                </div> -->
            </div>
            <div class="card" v-else>
                <div class="card-header">
                    <h4>Create A Rates' Contract Creation Request</h4>
                </div>
                <div class="card-body">
                    <el-row :gutter="20">  
                        <el-col :span="24">
                            <h3 align="center" class="text-success">Gathering Company Details.....</h3>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- List of Items in the list -->
        <div id="Rate_Contract_List" class="col-lg-5" v-if="this.RateContractItemLine.RCType !== ''">
            <div v-stick-in-parent="stikyKit">
                <el-row v-if="RateContractAllItems.length > 0" :gutter="20">
                    <el-col :span="24">
                        <div class="card">
                            <div class="card-header">
                                <h4>Rates’ Contract Creation Request Lines</h4>
                            </div>
                            <div id="Rate_Contract_List_Table" class="card-body">
                                <table class="table" v-if="RateContractAllItems[0].RCType == 'Service'">
                                    <tr>
                                        <td class="text-ogeo">{{ RateContractAllItems[0].ItemLibraryDescription.description}}</td>
                                    </tr>
                                </table>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'">Library Of Materials Item Details</th>
                                            <th v-if="RateContractAllItems[0].RCType == 'Service'">Service Lines Unit of Measurement</th>
                                            <th v-if="RateContractAllItems[0].RCType == 'Material'">Expected Required Quantity</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(RateContractItem, key, index) in RateContractAllItems" :key="index">
                                            
                                            <!-- <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.description.substring(0, 20) + '...' }}</td> -->
                                            <td v-if="RateContractItem.RCType == 'Service'">{{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase().substring(0, 20) + '...' }}</td>
                                            <td v-if="RateContractItem.ItemNumber && RateContractItem.RCType == 'Material'"><span style="word-wrap: break-word; white-space: wrap;"><b>LoM-{{ RateContractItem.ItemGroupId  }}-{{ RateContractItem.ItemInGroupId  }}</b><br>{{ RateContractItem.ItemLibraryDescription.description.slice(0, 20) + '...' }}</span></td>
                                            <td v-if="RateContractItem.RCType == 'Material'">{{ RateContractItem.Quantity }} {{ RateContractItem.ItemLibraryDescription.u_o_m.toUpperCase() }} </td>
                                            <td width="25%">
                                                <a style="padding: 6px 9px;" class="btn btn-warning float-left" href="#" @click="showRCDetails(key, $event)"><i class="fa fa-info"></i></a>
                                                <!-- <a class="btn btn-warning float-left" href="#" @click="showItemDetails(key, $event)"><i class="fa fa-edit"></i></a> -->
                                                <a class="btn btn-danger float-left m-l-5" href="#" @click="RemoveItem(key, $event)"><i class="fa fa-remove"></i></a> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                                                        
                                    <el-checkbox class="chkAgree" v-model="RateContractItemLine.lomTOC">I have read my organization’s Materials Rates’ Contract which will govern the relationship with the awardee vendor.</el-checkbox>

                                    <br><span><a href="">Download <span class="text-ogeo">the Materials Rates’ Contract</span></a></span><br><br>
                                    <span>Note: <i>Please reach out to those holding the role of Application Administrator should you need to have the Materials Rates’ Contract amended.</i></span>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button v-if="RateContractAllItems && RateContractAllItems[0].RCType == 'Service'" type="button" @click="SubmitRateContractItemList" class="btn btn-primary btn-block waves-effect text-center">Submit The Services Rates’ Contract Creation Request</button>
                                <button v-else-if="RateContractAllItems && RateContractItemLine.lomTOC" type="button" @click="SubmitRateContractItemList" class="btn btn-primary btn-block waves-effect text-center">Submit The Materials Rates’ Contract Creation Request</button>
                                <button v-else type="button" @click="SubmitRateContractItemList" class="btn btn-primary btn-block waves-effect text-center disabled" disabled>Submit The Materials Rates' Contract Creation Request</button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                 <el-row v-else :gutter="20">
                    <div class="card">
                        <div class="card-header">
                            <h4>Rates' Contract Creation Request Lines</h4>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th v-if="RateContractItemLine.RCType == 'Material'">Library Of Materials Item Details</th>
                                        <th v-if="RateContractItemLine.RCType == 'Service'">Service Lines Unit of Measurement</th>
                                        <th v-if="RateContractItemLine.RCType == 'Material'">Expected Required Quantity</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center" colspan="4">Rates' Contract Creation Request List is Empty</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="container" v-if="RateContractItemLine.RCType == 'Material'">
                                                                        
                                <el-checkbox class="chkAgree" disabled v-model="RateContractItemLine.lomTOC" @change="checkTOC($event)">I have read my organization's Materials Rates’ Contract Terms & Conditions which will govern the relationship with the awardee vendor.</el-checkbox>

                                <br><span><a href="">Download the Materials Rates’ Contract</a></span><br><br>
                                <span>Note: <i>Please reach out to those holding the role of Application Administrator should you need to have the Materials Rates’ Contract amended.</i></span>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-primary btn-block waves-effect text-center disabled">Submit Rates' Contract Creation Request</button>
                        </div>
                    </div>
                 </el-row>
            </div>
        </div>
        
       

            <!-- Modal Code   -->
            <div class="modal fade" id="RCDetailsModalId" tabindex="-1" aria-labelledby="RCDetailsModalId" role="dialog" aria-hidden="true">
               
                <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="RCLineShow">
                    <div class="modal-header">
                        <div style="width:50%">
                            <h4 style="margin-top:10px" class="modal-title text-ogeo">Materials Rates’ Contract Creation Request Line ID# 1-{{ RCModalInfoKey + 1 }}</h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    
                     <div class="modal-body">
                            <div class="row">

                                <!-- Rates Contracts General Information Panel -->
                                <div class="col-lg-8">
                                    <table class="table">
                                            <tr>
                                                <td width="40%"><b class="font-700">Setup Details: </b></td>
                                                <td>
                                                    <span><b class="font-700 font-italic">Setup Name:</b> {{ RCModalInfo.project.title }}</span><br>
                                                    <span><b class="font-700 font-italic">Start Date:</b> {{ RCModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">End Date:</b> {{ RCModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                    <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(RCModalInfo.project.value - RCModalInfo.project.consumed_budget) }} {{ RCModalInfo.project.currency }}</span>
                                                </td>
                                            </tr>
                                        
                                    </table>
                                </div>
                                <div class="col-lg-4">
                                    <table class="table">
                                        <tr>
                                            <td><b class="font-700">Line Added By: </b></td>
                                            <td>{{ currentUser.Name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Added On: </b></td>
                                            <td>{{ moment().format("DD.MMM.YYYY - (HH:MM:SS)") }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                            <td>{{ RCModalInfo.UnderlyingTransaction }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                            <td>{{ RCModalInfo.RCType }}s</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                            <td>{{ RCModalInfo.RateContractItemPeriod }} Months</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.CompanyCountry }} | {{ RCModalInfo.CompanyCities.toString().replaceAll(',', ', ')}}</span><br>

                                            </td>
                                        </tr>

                                    </table>
                                </div>
                                <!-- Rates Contracts General Information Panel End -->

                                <!-- Materials Information Panel -->
                                <div class="col-lg-12">
                                    <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700">{{RCModalInfo.RCType == 'Material' ? "Are Advance Payments Considered For The Items Sourced Under The Materials Rates’ Contract?" : "Are Advance Payments Considered For The Service Lines Sourced Under The Services Rates’ Contract?"}}</b></td>
                                            <td>{{ RCModalInfo.AdvancedPayment }}</td>
                                            <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                            <td>{{ RCModalInfo.GuaranteeNeedForAdvancePayment ? RCModalInfo.GuaranteeNeedForAdvancePayment : 'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">{{RCModalInfo.RCType == 'Material' ? "What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Rates’ Contract?" : "What Is The Percentage Of Payment Retention For The Service Lines Sourced Under This Services Rates’ Contract?"}}</b></td>
                                            <td>{{ RCModalInfo.RetentionPercentage ? RCModalInfo.RetentionPercentage + '%' : 'N/A' }}</td>
                                            <td width="40%"><b class="font-700">Payment Retention Timeframe (From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order.): </b></td>
                                            <td>{{ RCModalInfo.RetentionDays ? RCModalInfo.RetentionDays + ' Day(s)' : 'N/A' }}</td>
                                        </tr>                                 
                                    </table>
                                    <table class="table">
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                            <td v-if="RCModalInfo.RequiredDocuments">
                                                <ul class="d-inline-flex" v-for="(document, index) in RCModalInfo.RequiredDocuments">
                                                    <li>{{ document }}</li>
                                                </ul>
                                            </td>
                                            <td v-else>
                                                No Documents Required
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ RCModalInfo.SourcingPriority }}</td>
                                        </tr>
                                               
                                       <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td style="width: 40%; vertical-align: center;"><b class="font-700">Library of Materials Item Details:: </b></td>
                                            <td>
                                                <span v-if="!isJSON(RCModalInfo.ItemLibraryDescription.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+RCModalInfo.ItemLibraryDescription.picture"></span>
                                                <span class="text-center" v-else v-for="(img, index) in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></img></span>
                                                <br><br><span><b class="font-700"><span v-if="RCModalInfo.ItemLibraryDescription.company.lom_prefix == null">LoM</span><span v-else>LoM-</span>{{ RCModalInfo.ItemLibraryDescription.item_group_id }}-{{ RCModalInfo.ItemLibraryDescription.item_ingroup_id }}</b></span><br>
                                                <span>
                                                    <template v-for="index in 20">      
                                                        <span v-if="RCModalInfo.ItemLibraryDescription.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b class="font-700 text-uppercase">{{ RCModalInfo.ItemLibraryDescription.item_template['field_'+index] }}:</b> {{ RCModalInfo.ItemLibraryDescription['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br><br>
                                                <!-- <span><b>Quantity:</b> {{ RCModalInfo.Quantity }}</span><br> <br> -->
                                                <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>
                                                <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(RCModalInfo.ItemLibraryDescription.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                            </td>
                                        </tr> 
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Estimated Quantity During The Term: </b></td>
                                            <td>  
                                                
                                                {{  RCModalInfo.Quantity }}
                                            
                                            </td>


                                        </tr>

                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700"> Volume Discount Clause: </b></td>
                                            <td>  
                                               
                                                 <span>{{RCModalInfo.VolumeDiscount}}</span>
                                                         
                                            
                                            </td>


                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Minimum Value Required To Trigger The Item Unit Rates' Discount: </b></td>

                                            <td>  

                                             <span>
                                                 <span v-if="RCModalInfo.VolumeDiscount == 'No' ">
                                                    This Item Doesn't Have A Volume Discount Arrangement.
                                                     </span>
                                                     <span v-else>
                                                         {{ RCModalInfo.MinimalProcuredValue }} {{RCModalInfo.project.currency.substring(0, 3)}}
                                                         </span>
                                                          </span>

                                            </td>

                                           
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Material'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>  
                                                <!-- This Rates Contract doesn't have a volume discount clause -->
                                                Not Yet Awarded
                                            
                                            </td>


                                        </tr>
                                       
                                    </table>
                                </div>
                                <!-- Materials Information Panel End -->
                                
                                <!-- Services Information Panel -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">
                                    <table class="table">
                                        <!-- <tr align="center" v-if="RCModalInfo.Images.length > 0">
                                            <td v-for="(img, index) in RCModalInfo.Images" v-bind:key="img.id">
                                                <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                            </td>
                                        </tr> -->
                                        
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Service Rates’ Contract Description: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.description }}</span><br>

                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH col-lg-2">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorInstructionHeaders">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}:</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="vendorIns" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b class="font-700">Vendor Offer Content</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 vendorInsH ">Header</b></th>
                                                        <th><b class="font-700 vendorInsC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.VendorOfferContent">
                                                        <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.name }}:</td>
                                                        <td class="vendorInsC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="" id="SCT" v-if="RCModalInfo.RCType == 'Service'">
                                            <td colspan="2">
                                                <table class="table" style="width:100%">
                                                    <tr class="text-center text-ogeo">
                                                        <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                    </tr>
                                                    <tr>
                                                        <th><b class="font-700 SCTH ">Header</b></th>
                                                        <th id="SCTC"><b class="font-700 SCTC">Content</b></th>
                                                    </tr>
                                                    <tr v-for="header in RCModalInfo.ServiceContractHeaders">
                                                        <td class="text-uppercase font-700 SCTH col-lg-2">{{ header.name }}:</td>
                                                        <td class="SCTC">{{ header.content }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Service Line Unit of Measurement: </b></td>
                                            <td>
                                                <span>{{ RCModalInfo.ItemLibraryDescription.u_o_m.toUpperCase() }}</span><br>

                                            </td>
                                        </tr>
                                        <tr v-if="RCModalInfo.RCType == 'Service'">
                                            <td><b class="font-700">Awardee Vendor: </b></td>
                                            <td>
                                                <span>{{ 'Not Yet Awarded' }}</span><br>

                                            </td>
                                        </tr>
                                        
                                            
                                        
                                    </table>
                                </div>
                                <!-- Services Information Panel End -->

                                <!-- List of Valid Rates Contracts -->
                                <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Material'">
                                    <table class="table">
                                        <tr class="text-ogeo text-center">
                                        <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                    </tr>
                                            <table>
                                                <tr>
                                                    <th><b class="font-700">Vendor Name</b></th>
                                                    <th><b class="font-700">Country Cities</b></th>
                                                    <th><b class="font-700">Unit Rate</b></th>
                                                    <th><b class="font-700">Expiry Date</b></th>
                                                    <th><b class="font-700">Lead Time</b></th>
                                                    <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                    <th><b class="font-700">Discount Rate</b></th>
                                                    <th><b class="font-700">Total Procured So Far</b></th>
                                                    <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                                    <th><b class="font-700">Retention Payment Arrangement</b></th>
                                                </tr>
                                                <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                    <td>{{ item.vendor_name }}</td>
                                                    <td>{{ item.unit_rate }} USD </td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.lead_time }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                    <td>{{ item.vendor_score }}</td>
                                                </tr>
                                            </table>
                                        </table>
                                </div>

                                <!-- <div class="col-lg-12" v-if="RCModalInfo.RCType == 'Service'">                            
                                    <table class="table mt-3">
                                        <tr class="text-center text-ogeo">
                                            <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                            <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                        </tr>
                                        <tr>
                                            <th><b class="font-700">Reference</b></th>
                                            <th><b class="font-700">Short Description</b></th>
                                            <th><b class="font-700">Service Unit Of Measurement</b></th>
                                            <th><b class="font-700">Expiry Date</b></th>
                                        </tr>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </tr>
                                    </table>
                                    <hr>   
                                </div> -->
                                <!-- List of Valid Rates Contracts End -->

                            </div>
                            
                        </div>

                    <div class="col-lg-12">
                        <hr>
                        <div class="d-inline-block">
                            <span><b>Date:</b> {{ new Date() }}</span><br>
                            <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Materials Rates’ Contract Creation Request Line ID# 1-'+window.RCModalInfoKey + 1;window.print()">Download PDF</button>   <br><br>
                        </div>
                        <div class="d-inline-block pull-right">
                            <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                        </div>
                    </div>

                </div>
            </div> 
       
            </div>
            <!-- Modal Code  -->

    </div>


            
</template>

<script>
    

    //import { CustomJs } from '../../helpers/custom.js';
    import swal from 'sweetalert2';
import validate from 'validate.js';

    export default {
        name: 'new-request-rate-contract-creation',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.RateContractItemLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please Enter Retention Amount')); 
                }
                
                if ((value + '').replace(/^-?\d*\.?|0+$/g, '').length > 2) {
                    callback(new Error('Please Enter No More Than Two Decimal Places.'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkItemSelection = (rule, value, callback) => {
                if (!value) { 
                    return callback(new Error('Please Select An Item from Library of Materials')); 
                } else {
                    callback();
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.RateContractItemLine.RetentionPercentage != '') { 
                    return callback(new Error('Please Select Retention Days')); 
                } else {
                    callback();
                }
                
            };
            return {
                hostName: window.location.protocol+'://'+window.location.hostname,
                CompanySetup: {},
                stikyKit: {
                    options: {
                        offset_top: 140
                    },
                },
                countries: [],
			    cities: [],
                projectcurrency: '',
                uom: '',
                FirstStepData:{
					CompanyLogo: "",
					curr: "",
                    companyName: "",
                    companyTime:"",
                    CompanyEmployeeRange: [],
                    CompanyIndustry: [],
                    CompanyCities: [],
                    companyCity: "",
                    CompanyCountry: "",
                    CompanyCountries: [],
				},
                vendorinstructionheader: {
                    name: '',
                    content: '',
                },
                vendoroffercontent: {
                    name: '',
                    content: '',
                },
                servicecontractheader: {
                    name: '',
                    content: '',
                },
			    EmployeesList: [],
			    ProjectKey: "",
			    JobNumbers: [],
                itemAdditionFlag: "N",
                minVal: '',
                curr: '',
                testdata: [],
                PurchaseEnquiryAll: [],
                RCModalInfoKey: "",
                RCModalInfo: "",
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                CompanyDetailsLoaded: false,
                FoundItems: {},
                searchItemsLoading: false,
                ItemSelectionMandatory: false,
                RateContractItemLine: {
                    JobNumber: "",
                    RCType: "",
                    curr: "",
                    ItemNumber: "",
                    RateContractItemPeriod: "",
                    VolumeDiscount: "",
                    MinimalProcuredValue: "",
                    Quantity: "",
                    SetupName: "",
                    ServiceDescription: "",
                    ServiceUOM: "",
                    SourcingPriority: "Standard",
                    UnderlyingTransaction: "", 
                    ItemLibraryDescription: "",
                    VendorInstructionHeaders: [],
                    VendorOfferContent: [],
                    ServiceContractHeaders: [],
                    CompanyCities: [],
                    CompanyCountry: "",
                    uomTodisplay: "",
                    ItemGroupId: "",
                    ItemInGroupId: "",
                    ServiceTerm: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    lomTOC: "",
                    rtYN: 'no',
                },
                 Ratecontractelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                RateContractAllItems: [],
                RCItemModalInfoKey: "",
                RCItemModalInfo: "",
                RCLineShow: false,
                toolTipImage: "/assets/images/tooltip/Euclid.png",
                toolTipProjectIcon: "/assets/images/project-icon.jpg",
                rules: {
                    JobNumber: [{
                        required: true,
                        message: "Please Select Setup",
                        trigger: ["blur"]
                    }],
                    RCType: [{
                        required: true,
                        message: "Please Select Rates' Contract Type",
                        trigger: ["blur"]
                    }],
                    VendorInstructionHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur", "change"]
                    }],
                    VendorOfferContent: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur", "change"]
                    }],
                    ServiceContractHeaders: [{
                        required: true, message: "Atleast One Header and it's Content is Required", trigger: ["blur", "change"]
                    }],
                    CompanyCountries: [{
                        required: false,
                        message: "Please Select Country",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        validator: checkItemSelection,
                        trigger: ["blur"]
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please Select an Quantity",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please Select an Item",
                        trigger: "blur"
                    }],
                    CompanyCities: [{
                        required: false,
                        message: "Please Select City",
                        trigger: "blur"
                    }],
                    RateContractItemPeriod: [{
                        required: true,
                        message: "Please Enter Rates’ Contract Term",
                        trigger: "blur"
                    }],
                    VolumeDiscount: [{
                        required: true,
                        message: "Please Select Volume Discount Arrangement",
                        trigger: "blur"
                    }],
                    MinimalProcuredValue: [{
                        required: true,
                        message: "Please Enter Minimum Value",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please Select Unit of Measurement",
                        trigger: ["blur", "change"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter Service Rates’ Contract Description",
                        trigger: ["blur"]
                    }],
                    ServiceUOM: [{
                        required: true,
                        message: "Please Enter Service Rates’ Contract Unit of Measurement",
                        trigger: ["blur"]
                    }],
                    ServiceTerm: [{
                        required: true,
                        message: "Please Select Term of Service",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please Select Advance Payment Requirements.",
                        trigger: ["blur", "change"]
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{required: true, message: "Please Select Retention Days", trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please Select A Document.",
                        trigger: "blur"
                    }],
                    CompanyCountry: [{
                        required: true,
                        message: "Please Select A Country.",
                        trigger: "blur"
                    }],
                    CompanyCities: [{
                        required: true,
                        message: "Please Select A City.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: false,
                        message: "Please Delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please Enter Location Name.",
                        trigger: "blur"
                    }]
                },
            }
        },
		created() {
            this.getCountries();
            this.getDataRCSimilar();
            this.RCTypeSelection();

        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            commaprice() {
                this.RateContractItemLine.MinimalProcuredValue = this.RateContractItemLine.MinimalProcuredValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            searchItems(value) {
                console.log(value);
                this.RateContractItemLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.RateContractItemLine.JobNumber})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                    })
            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        console.log('in the countries sections');
                        this.countries = res.data
                        console.log( this.countries );

                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {


				if(this.RateContractItemLine.CompanyCountry != '' || this.RateContractItemLine.CompanyCountry != null ){
  
                  this.cities= this.countries[this.RateContractItemLine.CompanyCountry];
               }
            },
            checkProjectExpiry(e){
                var val = axios.get('/api/data/get_project_expiry/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        if(new Date(response.data[0]) > new Date())
                            return true;
                        else{
                            this.RateContractItemLine.JobNumber = '';
                            swal('The Selected Setup Has Expired', 'Using this Setup will be possible if those holding the role of Setup Budgets Initiator extend its end date.', 'error');
                            return false;    
                        }
                    })
                return val;
            },
            getProjectCurrency(e)
            {
                if(this.checkProjectExpiry(e))
                    axios.get('/api/data/get-project-currency/' + this.RateContractItemLine.JobNumber)
                    .then((response) => {
                        this.projectcurrency = response.data[0]
                    })
               
            },
            RCTypeSelection: function(){
                if(this.RateContractItemLine.RCType) {
                    $('#Rate_Contract_List').removeClass('animated fadeOutRight');
                    $('#Rate_Contract_List').addClass('animated fadeInRight');
                    $('#Rate_Contract_Request_Form').removeClass('col-lg-12').addClass('col-lg-7').delay(500);
                }
                this.RateContractItemLine.VolumeDiscount = "";
                this.RateContractItemLine.MinimalProcuredValue = "";
                this.RateContractItemLine.Quantity = ""
                this.RateContractItemLine.CompanyCities = [];
                this.RateContractItemLine.ItemNumber = "";
                this.RateContractItemLine.ItemLibraryDescription= "",
                this.RateContractItemLine.CompanyCitiesTodisplay = [];
                this.RateContractItemLine.VendorInstructionHeaders = [];
                this.RateContractItemLine.ServiceContractHeaders = [];
                this.RateContractItemLine.CompanyCountry = "";
                this.RateContractItemLine.ServiceUOM = "";
                this.RateContractItemLine.ServiceDescription = "";
                this.RateContractItemLine.uomTodisplay = "";
                this.RateContractItemLine.ItemGroupId = "";
                this.RateContractItemLine.ItemInGroupId = "";
                this.RateContractItemLine.rtYN = "No";
            },
             getUom()
            {
                     let itemAtHand = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                     
                     this.uom = itemAtHand.u_o_m;

                                  
            },
            addTendererContractHeader() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorInstructionHeaders.push({
                        name: this.vendorinstructionheader.name,
                        content: this.vendorinstructionheader.content,
                    })
                    this.vendorinstructionheader.name = ''
                    this.vendorinstructionheader.content = ''
                } else {
                    Swal('Error', 'The Header Already Exist.' , 'error'); 
                }
            },
            existTendererContractHeader() {
                if (this.RateContractItemLine.VendorInstructionHeaders.find(item => item.name === this.vendorinstructionheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteTendererHeader(index) {
                this.$delete(this.RateContractItemLine.VendorInstructionHeaders, index);
            },
            addVendorOfferContent() {
                if (this.existTendererContractHeader() == true) {
                    this.RateContractItemLine.VendorOfferContent.push({
                        name: this.vendoroffercontent.name,
                        content: this.vendoroffercontent.content,
                    })
                    this.vendoroffercontent.name = ''
                    this.vendoroffercontent.content = ''
                } else {
                    Swal('Error', 'This Header Already Exists.' , 'error'); 
                }
            },
            existVendorOfferContent() {
                if (this.RateContractItemLine.VendorOfferContent.find(item => item.name === this.vendoroffercontent.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteVendorOfferContent(index) {
                this.$delete(this.RateContractItemLine.VendorOfferContent, index);
            },
            addContractHeader() {
                if (this.existContractHeader() == true) {
                    this.RateContractItemLine.ServiceContractHeaders.push({
                        name: this.servicecontractheader.name,
                        content: this.servicecontractheader.content,
                    })
                    this.servicecontractheader.name = ''
                    this.servicecontractheader.content = ''
                } else {
                    Swal('Error', 'This Contract Header already exist.' , 'error'); 
                }
            },
            existContractHeader() {
                if (this.RateContractItemLine.ServiceContractHeaders.find(item => item.name === this.servicecontractheader.name)) {
                    return false;
                } else {
                    return true;
                }
            },
            deleteContractHeader(index) {
                this.$delete(this.RateContractItemLine.ServiceContractHeaders, index);
            },
			hideShowMin() {
				console.log(this.RateContractItemLine.VolumeDiscount);
                if(this.RateContractItemLine.VolumeDiscount=='Yes'){
                        $('#min_val').show();
                }else{
                     $('#min_val').hide();
                }

            },
            AddItem: function() {


                this.RateContractItemLine.uomTodisplay=this.uom;

                this.$refs.RateContractRequestForm.validate((validation) => {


                    if(validation){
                        let duplicateItem = 'N';
                        if(this.RateContractItemLine.RCType == 'Material') {
                            
                            if(this.itemAdditionFlag=="N"){
                                this.ReqDocTmp.map(item => {
                                    if(item !== 'Other')
                                        this.RateContractItemLine.RequiredDocuments.push(item)
                                })
                                if(this.OtherDocTmpHolder)
                                    this.OtherDocTmpHolder.map(item => {
                                            this.RateContractItemLine.RequiredDocuments.push(item.name)
                                    })
                            }
                            let selectedItem = this.FoundItems.find(obj => obj.id == this.RateContractItemLine.ItemNumber);
                            
                            this.RateContractItemLine.ItemGroupId=  selectedItem.item_group_id;
                            this.RateContractItemLine.ItemInGroupId = selectedItem.item_ingroup_id;
                            this.RateContractItemLine.ItemLibraryDescription = selectedItem;

                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {
                    
                                        
                                            if(element.ItemNumber == this.RateContractItemLine.ItemNumber){
                                                console.log('duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This Material.','warning');
                                                duplicateItem='Y';
                                                return;
                                            }

                                        });
                                            

                                        }
                                }
                        else {
                            const descOBJ = {description: this.RateContractItemLine.ServiceDescription, u_o_m: this.RateContractItemLine.ServiceUOM};
                            this.RateContractItemLine.ItemLibraryDescription = Object.assign({}, descOBJ);                            
                            if ( this.RateContractAllItems.length !== 0) {
                            
                                    this.RateContractAllItems.forEach(element => {

                                        
                                            if(element.ItemLibraryDescription.u_o_m.includes(this.RateContractItemLine.ItemLibraryDescription.u_o_m)){
                                                console.log('duplicate Item ');
                                                Swal('Duplicated Rates’ Contract Request Line', 'This Request Already Has A Line For This Material.','warning');
                                                duplicateItem='Y';
                                                return false;
                                            }

                                        });
                                            

                                        }
                                }

                        if(duplicateItem=='N'){
                            this.RateContractAllItems.push(Object.assign({}, this.RateContractItemLine));
                            
                            this.RateContractItemLine.ItemLibraryDescription= "",

                            this.RateContractItemLine.ServiceUOM = "";
                            this.RateContractItemLine.ItemNumber = "";
                            this.RateContractItemLine.MinimalProcuredValue = "";
                            this.RateContractItemLine.VolumeDiscount = "";
                            this.RateContractItemLine.Quantity = "";
                        
                        }

                        this.itemAdditionFlag="Y";
                    }
                })



                },
            showItemDetails: function(key, event) {
                if (event) event.preventDefault();

                this.RCItemModalInfoKey = key;
                this.RCItemModalInfo = Object.assign({}, this.RateContractAllItems[key]);
                this.$forceUpdate();

                $('#RCItemDetailsModalId').modal('toggle');

            },
            showRCDetails: function(key, event) {
                if (event) event.preventDefault();
                 this.RCModalInfoKey = key;
                
                 //this.RCModalInfo = Object.assign({}, this.PurchaseEnquiryAll[key]);
                 this.RCModalInfo = Object.assign({}, this.RateContractAllItems[key]);
                 axios.post('/api/data/get_project_details', {id: this.RCModalInfo.JobNumber})
                .then(response => {
                    this.RCModalInfo.project = response.data;
                    this.$forceUpdate();
                    this.RCLineShow = true;
    
                    $('#RCDetailsModalId').modal('toggle');
                });

            },
            RemoveItem: function(key, event) {
                if (event) event.preventDefault();

                Swal({
                  title: 'Are you sure?',
                  text: "This Will Remove This Item From The Rate Contract Request.",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        this.RateContractAllItems.splice(key, 1);
                    }
                })

            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            SubmitRateContractItemList(event) {
                if (event) event.preventDefault();
                console.log(this.RateContractAllItems);
                console.log('above is the item list');
                 axios.post('/api/rate_contracts/post_rate_contract_request', this.RateContractAllItems)
               // axios.post('/api/post_rate_contract_request', this.RateContractAllItems)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });
                    })
                    .catch(function(){
                        // check temporary

                        console.log('in the error brackets');
                        //  Swal({ 
                        //     type: 'success', 
                        //     title: 'Rate Contract Request Created.', 
                        //     text: 'Rate Contract Request Created Successfully.',
                        //     showConfirmButton: true,
                        //     timer: 10000
                        // });
                        // check temporary
                         Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Rate Contract Request Submit)', 'error');
                    });

                //Clear All Data
                this.RateContractAllItems = "";
                this.RateContractItemLine.JobNumber = "";
                this.RateContractItemLine.ItemNumber = "";
                this.RateContractItemLine.ItemDescription = "";
                this.RateContractItemLine.RateContractItemPeriod = "";
                this.RateContractItemLine.Quantity = "";
                this.RateContractItemLine.UnitOfMeasurement = "";
                this.RateContractItemLine.CompanyCountry = "";
                this.RateContractItemLine.CompanyCities = "";
                this.RateContractItemLine.VolumeDiscount = "";
                this.RateContractItemLine.MinimalProcuredValue = "";
                this.RateContractItemLine.LocationName = "";
                this.RateContractAllItems = [];
                // this.JobNumbers="";
                this.curr="";
                this.JobNumbers=[];
                this.CompanySetup=[];
                this.cities=[];
                this.countries=[];
                //FoundItems



            },
            scrollTo: function(to, duration) {

                const
                element = document.scrollingElement || document.documentElement,
                start = element.scrollTop,
                change = to - start,
                startDate = +new Date(),
                // t = current time
                // b = start value
                // c = change in value
                // d = duration
                easeInOutQuad = function(t, b, c, d) {
                    t /= d/2;
                    if (t < 1) return c/2*t*t + b;
                    t--;
                    return -c/2 * (t*(t-2) - 1) + b;
                },
                animateScroll = function() {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if(currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    }
                    else {
                        element.scrollTop = to;
                    }
                };
                animateScroll();
            },
            getDataRCSimilar(){
                axios.get('/api/data/get_similar_RC_data/' + this.$route.params.id)
                .then((response) => {
                    response.data.service_contract_headers.map(item => {
                    this.RateContractItemLine.ServiceContractHeaders.push({
                        name: item.header_name,
                        content: item.header_content,
                        })
                    });
                    response.data.vendor_offer_content.map(item => {
                    this.RateContractItemLine.VendorOfferContent.push({
                        name: item.header_name,
                        content: item.header_content,
                        })
                    });
                    response.data.vendor_instruction_headers.map(item => {
                    this.RateContractItemLine.VendorInstructionHeaders.push({
                        name: item.header_name,
                        content: item.header_content,
                        })
                    });
                    this.RateContractItemLine.CompanyCountry = response.data.country;
                    this.RateContractItemLine.CompanyCities = response.data.cities.split(', ');
                    this.RateContractItemLine.ServiceDescription = response.data.item_description;
                    this.RateContractItemLine.AdvancedPayment = response.data.advanced_payment;
                    this.RateContractItemLine.GuaranteeNeedForAdvancePayment = response.data.advanced_payment_guarantee;
                    this.RateContractItemLine.RetentionPercentage = response.data.retention_percentage;
                    this.RateContractItemLine.RetentionDays = response.data.retention_days;
                    this.RateContractItemLine.ServiceDescription = response.data.item_description;
                    this.RateContractItemLine.ServiceUOM = response.data.u_o_m;
                    this.RateContractItemLine.RCType = response.data.rccr_type;
                    this.RateContractItemLine.RateContractItemPeriod = response.data.Term;
                    this.getCities();
                })
            }
        },
        mounted(){
            
            //CustomJs();

            let self = this;

            axios.get('/api/data/get_user_projects_list_rcco')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    console.log(this.JobNumbers);
                    console.log('thisJobNumbers');


                    axios.get('/api/data/get_company_details')
                        .then((SecondResponse) => {

                            this.CompanySetup = SecondResponse.data;
                        if(this.CompanySetup.lom_manditory == 'Libraries'){
                            this.rules.ItemNumber[0].required = true;
                        } else if (this.CompanySetup.lom_manditory == 'FreeText') {
                            this.rules.ItemDescription[0].required = true;
                        } else {
                            this.rules.ItemNumber[0].required = false;
                            this.rules.ItemDescription[0].required = false;
                        }

                        // if(this.CompanySetup.additional_required_1 == "Yes"){
                        //    this.rules.UnderlyingTransaction[0].required = false; 
                        // }

                            self.CompanyDetailsLoaded = true;

                        });
                });
           
        },
        
        beforeRouteLeave (to, from, next) {

            if(this.RateContractAllItems.length > 0){
                Swal({
                    title: 'Navigate Away?',
                    text: "Are you sure you want to navigate away, all unsubmitted data will be lost?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Remove It!'
                }).then((result) => {
                    if (result.value) {
                        next()
                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            next();
        }
    }

       
</script>


<style>
.custominput{
    position: relative;
    text-align: left;
}
.custominput label{
    text-align: left;
    padding-left: 12px;
    line-height: 28px;
}
.custominput label:before{
    top: 2px;
    left: 0;
    position: absolute;
}
.customLabel{
    text-align: left;
    max-width: 90%;
    display: inline-block;
}

    #Rate_Contract_List_Table{
        max-height: 570px;
        overflow-y: scroll;
    }

    #Rate_Contract_Request_Form{
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        transition: all 0.8s;
        overflow:hidden;
    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    #RCItemDetailsModalId .modal-dialog {
        width:80%;
    }

    #RCDetailsModalId, #RCDetailsModalId * {
        visibility: visible;
      }
      /* #RCDetailsModalId {
        /* position: absolute; */
        /* left: 0; */
        /* top: 0; */
      /* } */ 

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .el-select-dropdown__item{
        height: 100% !important;
    }
    .hide{
    visibility: hidden !important;
    }
    .el-select-dropdown__wrap{
        max-height: 800px !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style>
<style scoped>
    hr {
        background-color: #2874A6;
    }
    #RCDetailsModalId .modal-dialog {
        min-width:80%;
    } 
</style>