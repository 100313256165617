<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>New Request To Update Vendor Information</h4>
                </div>

                <div class="card-body">
                        <el-form>
                            <el-row>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="">
                                            <span slot="label"><b><span class="text-ogeo">*</span> Select A Setup</b></span>
                                            <el-select popper-class="setupSelection" filterable style="min-width: 100%; padding: 0px;" v-model="VendorManagementRequestInfo.ProjectID" ref="elSelect" placeholder="Select A Setup" @visible-change="adjustDropdownWidth">

                                                <el-option v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24" v-if="VendorManagementRequestInfo.ProjectID">
                                    <div class="grid-content">
                                        <el-form-item label="">
                                            <span slot="label"><b><span class="text-ogeo">*</span>What Would You Like To Do ?</b></span>
                                            <el-select filterable collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.Type" @change="handlePanels($event)" placeholder="What Would You Like To Do ?">

                                                <el-option
                                                    v-for="(item,index) in VQ_Actions"
                                                    :key="index"
                                                    :label="item"
                                                    :value="VQ_Actions_Typos[index]">
                                                    <span style="float: left">{{ item }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>

                    <hr v-if="VendorManagementRequestInfo.Type">
                    <br>

                    <!-- New Vendor Qualification Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Qualification Of A New Vendor'">
                        <div class="card">

                            <div class="card-header">
                                <span style="color: rgb(252, 51, 23); font-weight: 700">
                                    <i class="fa fa-bookmark"></i>
                                        Bulk List Of <i>Vendors</i> To Be Qualified:
                                </span>
                            </div>


                            <div class="card-body">
                                <table ref="BulkVendorsListTable" id="BulkVendorsList" class="display responsive table table-bordered table-hover" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>ID#</th>
                                            <th>Vendor</th>
                                            <th>Vendor Alias</th>
                                            <th>Landline</th>
                                            <th>PO BOX</th>
                                            <th>Address</th>
                                            <th>Rep. Name</th>
                                            <th>Rep. Title</th>
                                            <th>Rep. Phone</th>
                                            <th>Highest Office Name</th>
                                            <th>Highest Office Phone</th>
                                            <th>Vendor Bank</th>
                                            <th>Vendor Swift</th>
                                            <th>Vendor IBAN</th>
                                            <th>Last 3 Years Turnover</th>
                                            <th>License #</th>
                                            <th>Establishment Year</th>
                                            <th>Represented Brand</th>
                                            <th>Exclusive Brand</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                </table>

                            </div>
                        </div>


                        <hr>
                        <h3>Qualify <i>Vendors</i> In Bulk:</h3>
                        <form id="bulkVendorsData" enctype="multipart/form-data" class="mb-5">
                            <div class="form-group">
                                <table class="table">
                                    <!-- <tr> -->
                                        <a style="margin:5px;text-align:left;width:122px" class="btn btn-danger" href="/downloads/Ogéo-Vendors.xlsx" download>Step 1:<br>Download File</a>
                                        <label class="btn btn-success" style="margin:5px;text-align:left;width:122px">
                                            Step 2:<br>Select File
                                            <input style="display:none" type="file" class="btn btn-danger" name="vendor_bulk_file" />
                                        </label>
                                        <label class="btn btn-primary" style="margin:5px;text-align:left;width:122px">
                                            Step 3:<br>Upload File
                                            <input style="display:none" @click.prevent="getBulkVendorsFile" type="submit" name="Upload File">
                                        </label>
                                    <!-- </tr> -->
                                </table>
                            </div>
                        </form>
                        <hr>
                        <div style="text-align:center;margin-top:-37px; margin-bottom: 50px;">
                            <span class="btn" style="background:black;text-align:center;padding:10px;color:white">OR</span>
                        </div>
                        <h3 id="VendorCreationInfoFormStart">Qualify A New <i>Vendor</i>:</h3><br><br>
                        <el-form :model="VendorCreationInfo" class="demo-form-inline" :rules="rules" ref="VendorCreationRequestForm" id="VendorCreationRequestForm">

                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorName">
                                            <span slot="label"><b>Enter Vendor Legal Name</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorName" placeholder="Enter Vendor Legal Name"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorAlias">
                                            <span slot="label"><b>Enter Vendor Alias</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorAlias" placeholder="Enter Vendor Alias"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorPhone">
                                            <span slot="label"><b>Enter Vendor Phone Number</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorPhone" placeholder="Enter Vendor Phone Number"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorAddress">
                                            <span slot="label"><b>Enter Vendor Address</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorAddress" placeholder="Enter Vendor Adress"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorPOBOX">
                                            <span slot="label"><b>Enter Vendor PO.Box</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorPOBOX" placeholder="Enter Vendor PO.Box"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item prop="VendorCountry">
                                            <span slot="label"><b>Select Vendor Country</b></span>
                                            <el-select filterable collapse-tags id="country" class="country" @change="getCities($event)" style="width: 100%; padding: 0px" v-model="VendorCreationInfo.VendorCountry" placeholder="Select Country">

                                                <el-option
                                                    v-for="(item,CompanyCountry) in countries"
                                                    :key="CompanyCountry"
                                                    :label="item.country_name"
                                                    :value="item.country_name">
                                                    <span style="float: left">{{ item.country_name }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item prop="VendorCity">
                                            <span slot="label"><b>Select Vendor City</b></span>
                                            <el-select filterable style="width: 100%; padding: 0px;" class="city" id="city" v-model="VendorCreationInfo.VendorCity" placeholder="Select City">

                                                <el-option
                                                    v-for="(item,companyCity) in cities"
                                                    :key="companyCity"
                                                    :value="item.city_name"
                                                    :label="item.city_name">
                                                <span style="float: left">{{ item.city_name }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorHighestRankingOfficerName">
                                            <span slot="label"><b>Enter Vendor Highest Ranking Officer Name</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorHighestRankingOfficerName" placeholder="Enter Vendor Highest Ranking Officer Name"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorHighestRankingOfficerPhoneNumber">
                                            <span slot="label"><b>Enter Vendor Highest Ranking Phone Number</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorHighestRankingOfficerPhoneNumber" placeholder="Enter Vendor Highest Ranking Phone Number"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepName">
                                            <span slot="label"><b>Enter Vendor Representative Name</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorRepName" placeholder="Enter Vendor Representative Name"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepTitle">
                                            <span slot="label"><b>Enter Vendor Representative Title</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorRepTitle" placeholder="Enter Vendor Representative Title"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorMobile">
                                            <span slot="label"><b>Enter Vendor Representative Phone Number</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorMobile" placeholder="Enter Vendor Representative  Phone Number"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorBankName">
                                            <span slot="label"><b>Enter Vendor Bank Name</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorBankName" placeholder="Enter Vendor Bank Name"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorSwiftCode">
                                            <span slot="label"><b>Enter Vendor Bank Identifier Code
                                                (BIC)/SWIFT Code</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorSwiftCode" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorBankAddress">
                                            <span slot="label"><b>Enter Vendor Bank Address</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorBankAddress" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorBankIBAN">
                                            <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorBankIBAN" @change="VendorCreationInfo.VerifyIBAN={verified: false, tool: ''}" placeholder="Enter Vendor International Bank Account Number (IBAN)"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="" prop="VendorBankIBAN">
                                            <!-- <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span> -->
                                            <el-checkbox class="chkAgree" v-model="VendorCreationInfo.VerifyIBAN.verified" @change="VerifyIBAN($event)" label="I Have Verified The Vendor IBAN"/><br>
                                            <!-- <el-checkbox class="chkAgree" :value="false" v-model="VendorCreationInfo.VerifyIBAN.verified" label="I Have NOT Verified The Vendor IBAN"/> -->

                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorOpenSince">
                                            <span slot="label"><b>Select Vendor Esablishment Year</b></span>
                                            <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorCreationInfo.VendorOpenSince"  placeholder="Select Vendor Esablishment Year">
                                                <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorTurnover">
                                            <span slot="label"><b>Enter Average of The Last 3 Years Turnover(in US Dollars)</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorTurnover" @input="commaprice()" placeholder="Enter Average of The Last 3 Years Turnover"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item prop="VendorOperationCountries">
                                            <span slot="label"><b>Select Countries Of Operations</b></span>
                                            <el-select filterable multiple collapse-tags id="country" class="country" @change="getMultiCities($event)" style="width: 100%; padding: 0px" v-model="VendorCreationInfo.VendorOperationCountries" placeholder="Select Countries Of Operations">
                                                <el-option style="min-width:145px !important"
                                                    v-for="(item,CompanyCountry) in countries"
                                                    :key="CompanyCountry"
                                                    :label="item.country_name"
                                                    :value="item.country_name">
                                                    <span style="float: left;">{{ item.country_name }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item prop="VendorOperationCities">
                                            <span slot="label"><b>Select Cities Where The Vendor Operates</b></span>
                                            <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="VendorCreationInfo.VendorOperationCities" placeholder="Select City">

                                                <el-option
                                                    v-for="(item,companyCity) in multiCities"
                                                    :key="companyCity"
                                                    :value="item.city_name"
                                                    :label="item.city_name">
                                                <span style="float: left">{{ item.city_name }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorURL">
                                            <span slot="label"><b>Enter Vendor Website</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorURL" placeholder="http://www.vendorwebsite.com/otherurl/232/wsd"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorLicenseNumber">
                                            <span slot="label"><b>Enter Vendor License Number</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorLicenseNumber" placeholder="Enter Vendor  License Number"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorLicenseAuthority">
                                            <span slot="label"><b>Enter The Name Of The Trade License Issuing Authority</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorLicenseIssuingAuthority" placeholder="Enter The Name Of The Trade License Issuing Authority"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorType">
                                            <span slot="label"><b>Select Vendor Type</b></span>
                                            <el-select filterable collapse-tags multiple allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorCreationInfo.VendorType"  placeholder="Select Vendor Type">

                                                <el-option label="Manufacturer" value="Manufacturer"></el-option>
                                                <el-option label="Distributor" value="Distributor"></el-option>
                                                <el-option label="Wholesaler" value="Wholesaler"></el-option>
                                                <el-option label="Retailer" value="Retailer"></el-option>
                                                <el-option label="Consultant" value="Consultant"></el-option>
                                                <el-option label="Contractor" value="Contractor"></el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorProvidedServices">
                                            <span slot="label"><b>Select Vendor Business Sector</b></span>
                                            <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorCreationInfo.VendorProvidedServices"  placeholder="Select Vendor Business Sector">

                                                <el-option label="Information Technology Services" value="Information Technology Services"></el-option>
                                                <el-option label="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services" value="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services"></el-option>
                                                <el-option label="Transportation Services" value="Transportation Services"></el-option>
                                                <el-option label="Medicine And Wellness Services" value="Medicine And Wellness Services"></el-option>
                                                <el-option label="Convenience Services" value="Convenience Services"></el-option>
                                                <el-option label="Financial Services" value="Financial Services"></el-option>
                                                <el-option label="Contracting Services" value="Contracting Services"></el-option>
                                                <el-option label="Construction & Fit-out Services" value="Construction & Fit-out Services"></el-option>
                                                <el-option label="Supply of Mechanical Spare Parts" value="Supply of Mechanical Spare Parts"></el-option>
                                                <el-option label="Supply of Electrical Spare Parts" value="Supply of Electrical Spare Parts"></el-option>
                                                <el-option label="Supply of Construction Materials" value="Supply of Construction Materials"></el-option>
                                                <el-option label="Supply of Foodstuff & Edible Products" value="Supply of Foodstuff & Edible Products"></el-option>
                                                <el-option label="Supply of Tools" value="Supply of Tools"></el-option>
                                                <el-option label="Supply of OEM Equipment" value="Supply of OEM Equipment"></el-option>
                                                <el-option label="Supply of Furniture" value="Supply of Furniture"></el-option>
                                                <el-option label="Supply of Vehicles" value="Supply of Vehicles"></el-option>
                                                <el-option label="Supply of Textile Products" value="Supply of Textile Products"></el-option>
                                                <el-option label="Supply of IT Products" value="Supply of IT Products"></el-option>
                                                <el-option label="Supply of Fossil Fuels" value="Supply of Fossil Fuels"></el-option>
                                                <el-option label="Supply of Stationary Products" value="Supply of Stationary Products"></el-option>
                                                <el-option label="Supply of Packaging Products" value="Supply of Packaging Products"></el-option>
                                                <el-option label="Supply of Fragrance Products" value="Supply of Fragrance Products"></el-option>
                                                <el-option label="Supply of Cosmetic Products" value="Supply of Cosmetic Products"></el-option>
                                                <el-option label="Supply of Cleaning Products" value="Supply of Cleaning Products"></el-option>
                                                <el-option label="Supply of Personal Hygienic Products" value="Supply of Personal Hygienic Products"></el-option>
                                                <el-option label="Supply of Consumer Electronics Products" value="Supply of Consumer Electronics Products"></el-option>
                                                <el-option label="Supply of Whitegoods Products" value="Supply of Whitegoods Products"></el-option>
                                                <el-option label="Supply of Garment Products" value="Supply of Garment Products"></el-option>

                                                <!-- Add Additional Options From Existing Vendors -->

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorBrandsRepresented">
                                            <span slot="label"><b>Enter Brands Represented By The Vendor In His Cities Of Operations</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorSoldBrands" placeholder="Enter Brands Represented By The Vendor"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorExclusiveBrands">
                                            <span slot="label"><b>Enter Brands Exclusively Represented By The Vendor In His Cities Of Operations</b></span>
                                            <el-input v-model="VendorCreationInfo.VendorExclusiveBrands" placeholder="Brands Exclusively Represented By The Vendor"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorSource">
                                            <span slot="label"><b>Select What Can Be Sourced From The Vendor</b></span>
                                            <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorCreationInfo.VendorSource"  placeholder="*Select What Can Be Sourced From The Vendor">

                                                <el-option label="Materials" value="Materials"></el-option>
                                                <el-option label="Services" value="Services"></el-option>
                                                <el-option label="Materials + Services" value="Materials + Services"></el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <transition v-if="VendorCreationInfo.VendorSource == 'Materials' || VendorCreationInfo.VendorSource == 'Materials + Services'" >
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="SelectedItemTemplates">
                                                <span slot="label"><b>Select Applicable Library of Materials Templates</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>I’m offered the possibility to link existing
                                                            applicable templates to the vendor. These
                                                            templates are pertaining to materials likely to be
                                                            offered by the vendor.</strong>
                                                        </span>
                                                    </span>
                                                </span>
                                                <el-select id="SelectedItemTemplates" filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="VendorCreationInfo.SelectedItemTemplates" placeholder="Select Library of Materials Template">

                                                    <el-option v-for="Template in ItemTemplates" :key="Template.id" :label="Template.template_name" :value="Template.id"></el-option>

                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                </transition>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <hr/>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item prop="VendorQualificationDocs">
                                            <span slot="label"><b>“Upload The Required
                                                Qualification Documents</b></span>

                                                <ol class="mt-5">
                                                    <li style="width: auto;" v-for="(item, index) in VQReqDocNames">
                                                        <h6 class="d-inline-block w-50">{{  item.document_name }}</h6>
                                                        <el-upload
                                                            style=""
                                                            class="upload-demo d-inline-flex"
                                                            name="Hello"
                                                            action="#"
                                                            :limit="1"
                                                            id='VQReqDocs'
                                                            :auto-upload="false"
                                                            ref="VQReqDocs"
                                                            accept=".pdf"
                                                            :on-change="handleChangeSD"
                                                            :on-exceed="handleExceedSD"
                                                            :on-remove="handleRemoveSD">
                                                            <button type="button" slot="trigger" @click="UploadKeyName = {req_id: item.id, name: item.document_name}" class="btn btn-success">Click to Select File</button>

                                                            <!-- <div class="el-upload__tip" slot="tip">JPEG, PNG, DOCX, PDF, XLSX file types are accepted with a size less than 1Mb (Max 4 Files)</div> -->
                                                        </el-upload>


                                                    </li>

                                                </ol>
                                            <div class="float-left">
                                            </div>

                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorLicenseExpiryDate">
                                            <span slot="label"><b>Select The Vendor’s Qualification Expiry Date</b></span>

                                            <el-date-picker
                                            :picker-options = "pickerOptions"
                                            style="width: 100%"
                                            v-model="VendorCreationInfo.VendorLicenseExpiryDate"
                                            type="date"
                                            format="dd.MMM.yyyy"
                                            value-format="dd-MM-yyyy">
                                            </el-date-picker>

                                        </el-form-item>
                                    </div>
                                    <h6 v-if="VendorCreationInfo.VendorLicenseExpiryDate">
                                        Transactions With The Vendor Will Be suspended
                                        When The Qualification Expires
                                    </h6>
                                </el-col>

                                <!-- <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorQualificationExpires">
                                            <span slot="label"><b>Do You Want To Suspend Transactions With The Vendor As Soon As His Qualification Expires?</b></span>
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                <span class="tooltip-item2">
                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                </span>
                                                <span class="tooltip-content4 clearfix">
                                                    <span class="tooltip-text2">
                                                        <strong>If the
                                                        transactions
                                                        are suspended,
                                                        the vendor will
                                                        not be asked
                                                        to submit
                                                        proposals.</strong>
                                                    </span>
                                                </span>
                                            </span>
                                            <el-select filterable style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorCreationInfo.VendorQualificationExpires"  placeholder="*Do You Want To Suspend Transactions With The Vendor As Soon As His Qualification Expires?">

                                                <el-option label="Yes" value="Yes"></el-option>
                                                <el-option label="No" value="No"></el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col> -->
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="24">
                                    <hr>
                                </el-col>
                                <el-col :span="23">
                                    <el-checkbox class="chkAgree" v-model="VerifyInformation" label="I have read all the documents submitted by the vendor for his qualification and found them in order and in line with the organization’s internal procedure for vendors’ qualification."/>
                                </el-col>

                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :class="[{ 'disabled': !VerifyInformation },'btn btn-success btn-block waves-effect text-center']">Create A Request To Add A New Vendor</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- New Vendor Qualification Request Section End -->

                    <!-- Update The Information Of A Qualified Vendor Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Information Amendment'">

                        <h3>Update The Information Of A <i>Qualified Vendor</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorUpdateRequestForm" id="VendorUpdateRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select From The List Of Vendors">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }}) {{ (new Date(item.lic_expiry_date) > Date.now()) ? '' : ' - (Expired Qualification)' }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <h4>Selected Vendor: </h4><br><br>
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12" style="margin: -10px 0px -10px 0px">
                                            <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="d-flex align-items-center col-lg-9">

                                                    <div class="ms-3">
                                                        <p class="fw-bold mb-1">{{ VendorsList[selectedVendor.index].name }} ({{ VendorsList[selectedVendor.index].vendor_alias ? VendorsList[selectedVendor.index].vendor_alias : 'N/A' }})</p>
                                                    </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <button class="btn btn-block btn-primary float-right" type="button" @click="editViewModal(VendorManagementRequestInfo.VendorID)"><i class="fa fa-edit"></i> Amend Vendor Information</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>


                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="23" v-if="VendorManagementRequestInfo.VendorID">
                                    <el-checkbox class="chkAgree" v-model="VerifyInformation" label="I have read all the documents submitted by the vendor for his qualification and found them in order and in line with the organization’s internal procedure for vendors’ qualification."/>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">

                                    <button type="button" @click="SubmitVendorManagementRequest" :disabled="!VerifyInformation" :class="['btn btn-success btn-block waves-effect text-center']">Create A Request To Update Vendor Information</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Update The Information Of A Qualified Vendor Request Section End -->

                    <!-- Inactivate An Active Vendor Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Inactivation'">
                        <h3>Inactivate An <i>Active Vendor</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorInactivationRequestForm" id="VendorInactivationRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Active Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select An Active Vendors">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'Yes' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :disabled="!VendorManagementRequestInfo.VendorID" :class="['btn btn-success btn-block waves-effect text-center']">Create A Request To Inactivate A Vendor</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Inactivate An Active Vendor Request Section End -->

                    <!-- Reactivate An Inactive Vendor Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Reactivation'">
                        <h3>Reactivate An <i>Inactive Vendor</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorReactivationRequestForm" id="VendorReactivationRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Inactive Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select An Inactive Vendors">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'No' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :disabled="!VendorManagementRequestInfo.VendorID" :class="['btn btn-success btn-block waves-effect text-center']">Create A Request To Reactivate A Vendor</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Reactivate An Inactive Vendor Request Section End -->

                    <!-- Renew A Vendor Expired Qualification Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Expired Qualification Renewal'">
                        <h3>Renew A Vendor <i>Expired Qualification</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorUpdateRequestForm" id="VendorUpdateRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Vendors With Expired Qualification</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select From The List Of Vendors With Expired Qualification">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.qualified_at && new Date(item.lic_expiry_date) < Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <h4>Selected Vendor: </h4><br><br>
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12" style="margin: -10px 0px -10px 0px">
                                            <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="d-flex align-items-center col-lg-9">

                                                    <div class="ms-3">
                                                        <p class="fw-bold mb-1">{{ VendorsList[selectedVendor.index].name }} ({{ VendorsList[selectedVendor.index].vendor_alias ? VendorsList[selectedVendor.index].vendor_alias : 'N/A' }})</p>
                                                    </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <button class="btn btn-block btn-primary float-right" type="button" @click="editViewModal(VendorManagementRequestInfo.VendorID)"><i class="fa fa-edit"></i> Amend Vendor Information</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>


                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="23" v-if="VendorManagementRequestInfo.VendorID">
                                    <el-checkbox class="chkAgree" v-model="VerifyInformation" label="I have read all the documents submitted by the vendor for his qualification and found them in order and in line with the organization’s internal procedure for vendors’ qualification."/>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">

                                    <button type="button" @click="SubmitVendorManagementRequest" :disabled="!VerifyInformation" :class="['btn btn-success btn-block waves-effect text-center']">Create A Request To Renew A Vendor Expired Qualification</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Renew A Vendor Expired Qualification Request Section End -->

                    <!-- New Vendor Representative Creation Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Addition Of A New Vendor Representative'">
                        <div class="card">

                            <div class="card-header">
                                <span style="color: rgb(252, 51, 23);">
                                    <i class="fa fa-bookmark"></i>
                                        Bulk List of <i>Vendor Users</i> :
                                </span>
                            </div>


                            <div class="card-body">
                                <table ref="BulkVendorUsersListTable" id="BulkVendorUsersList" class="display responsive table table-bordered table-hover" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>ID#</th>
                                            <th>Name</th>
                                            <th>Email Address</th>
                                            <th>Bid Manager Role</th>
                                            <th>Invoicing Manager Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>


                                </table>

                            </div>
                        </div>


                        <hr>
                        <h3>Create <i>Vendor Representatives</i> In Bulk:</h3>
                        <form id="bulkVendorUsersData" enctype="multipart/form-data" class="mb-5">
                            <div class="form-group">
                                <table class="table">
                                    <!-- <tr> -->
                                        <a style="margin:5px;text-align:left;width:122px" class="btn btn-danger" href="/downloads/Ogéo-VendorsUsers.xlsx" download>Step 1:<br>Download File</a>
                                        <label class="btn btn-success" style="margin:5px;text-align:left;width:122px">
                                            Step 2:<br>Select File
                                            <input style="display:none" type="file" class="btn btn-danger" name="vendor_user_bulk_file" />
                                        </label>
                                        <label class="btn btn-primary" style="margin:5px;text-align:left;width:122px">
                                            Step 3:<br>Upload File
                                            <input style="display:none" @click.prevent="getBulkVendorUsersFile" type="submit" name="Upload File">
                                        </label>
                                    <!-- </tr> -->
                                </table>
                            </div>
                        </form>
                        <hr>
                        <div style="text-align:center;margin-top:-37px; margin-bottom: 50px;">
                            <span class="btn" style="background:black;text-align:center;padding:10px;color:white">OR</span>
                        </div>
                        <h3>Create A New <i>Vendor  Representative</i>:</h3><br><br>
                        <el-form :model="VendorRepCreationInfo" class="demo-form-inline" :rules="rules" ref="VendorRepCreationForm" id="VendorRepCreationForm">

                            <el-row :gutter="24">

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorId">
                                            <span slot="label"><b>Select A Vendor to Create Users For</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorRepCreationInfo.VendorId" placeholder="Search and Select A Vendor">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'Yes' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepName">
                                            <span slot="label"><b>Enter User’s Full Name</b></span>
                                            <el-input v-model="VendorRepCreationInfo.VendorRepName" placeholder="Enter User Name"
                                                ></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepEmail">
                                            <span slot="label"><b>Enter User's Email</b></span>
                                            <el-input v-model="VendorRepCreationInfo.VendorRepEmail" placeholder="Enter User Email"
                                                ></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepRole">
                                            <span slot="label"><b>Select User Role</b></span>
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 200px">
                                                <span class="tooltip-text2">
                                                        The Vendor Bid Manager acts on behalf of the Vendor and, strictly through Ogeo, is responsible for submitting Sealed Bids, participating in Live Auctions, raising Requests For Information, and accepting Purchase Orders.     <br>        The Vendor Invoicing Manager is responsible for submitting, strictly through Ogeo, the Vendor's Invoices once the materials/services are received by my organization.
                                                </span>
                                            </span>
                                        </span>
                                            <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" @change="handleAlerts()" v-model="VendorRepCreationInfo.VendorRepRole" placeholder="Select User Role">
                                                <el-option label="Vendor’s Bid Manager" value="SBM"></el-option>
                                                <el-option label="Vendor’s Invoicing Manager" value="SIM"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <hr>
                                    <button type="button" @click="SubmitVendorManagementRequest" class="btn btn-success btn-block waves-effect text-center">Create A Request To Add A New Vendor Representative</button>
                                </el-col>

                            </el-row>
                        </el-form>

                        <br><br>
                        <div class="card">
                            <div class="card-header">
                                <h4>Vendors Users List</h4>
                            </div>
                            <div class="card-body">
                                <table ref="RepTable" id="VendorRepsList" class="display responsive table table-bordered table-hover" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Vendor Name</th>
                                            <th>User Name</th>
                                            <th>User Email</th>
                                            <th>Vendor Bid Manager Role</th>
                                            <th>Vendor Invoicing Manager Role</th>
                                            <th>Created At</th>
                                            <th>Active</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- New Vendor Representative Creation Request Section End -->

                    <!-- Inactivate An Active Vendor Representative Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Representative Inactivation'">
                        <h3>Inactivate A <i>Vendor Representative</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorRepInactivationRequestForm" id="VendorRepInactivationRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Qualified & Active Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select A Qualified & Active Vendor" @change="getVendorRepList($event)">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'Yes' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepID">
                                            <span slot="label"><b>Select From The List Of Active Vendor Representatives</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorRepID" placeholder="Search and Select An Active Vendor Representative">

                                                <el-option
                                                    v-for="(item,index) in VendorRepsList[0].users"
                                                    v-show="item.active == 'Yes'"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    <span @click="" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} - ({{ item.pivot.sbm_role == 'Yes' ? 'Bid Manager, ' : ''}}{{ item.pivot.sim_role == 'Yes' ? 'Invoicing Manager' : '' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :class="'btn btn-success btn-block waves-effect text-center'">Create A Request To Inactivate A Vendor Representative</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Inactivate An Active Vendor Representative Request Section End -->

                    <!-- Reactivate An Inactive Vendor Representative Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Representative Reactivation'">
                        <h3>Reactivate A <i>Vendor Representative</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorReactivationRequestForm" id="VendorReactivationRequestForm">

                            <el-row :gutter="24">


                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Qualified & Active Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select A Qualified & Active Vendors" @change="getVendorRepList($event)">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'Yes' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name + ' ('+ item.vendor_alias +')'"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepID">
                                            <span slot="label"><b>Select From The List Of Inactive Vendor Representatives</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorRepID" placeholder="Search and Select An Inactive Vendor Representative">

                                                <el-option
                                                    v-for="(item,index) in VendorRepsList[0].users"
                                                    v-show="item.active == 'No'"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    <span @click="" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} - ({{ item.pivot.sbm_role == 'Yes' ? 'Bid Manager, ' : ''}}{{ item.pivot.sim_role == 'Yes' ? 'Invoicing Manager' : '' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :class="'btn btn-success btn-block waves-effect text-center'">Create A Request To Reactivate A Vendor Representative</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Reactivate An Inactive Vendor Representative Request Section End -->

                    <!-- Change A Vendor Representative Role Request Section -->
                    <div v-if="VendorManagementRequestInfo.Type == 'Vendor Representative Role Change'">
                        <h3>Change A <i>Vendor Representative Role</i>:</h3><br><br>
                        <el-form :model="VendorManagementRequestInfo" class="demo-form-inline" :rules="rules" ref="VendorRoleChangeRequestForm" id="VendorRoleChangeRequestForm">

                            <el-row :gutter="24">
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorID">
                                            <span slot="label"><b>Select From The List Of Qualified & Active Vendors</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorID" placeholder="Search and Select A Qualified & Active Vendors" @change="getVendorRepList($event)">

                                                <el-option
                                                    v-for="(item,index) in VendorsList"
                                                    v-show="item.active == 'Yes' && item.qualified_at && new Date(item.lic_expiry_date) > Date.now()"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24" v-if="VendorManagementRequestInfo.VendorID">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="VendorRepID">
                                            <span slot="label"><b>Select From The List Of Active Vendor Representatives</b></span>
                                            <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorManagementRequestInfo.VendorRepID" placeholder="Search and Select An Active Vendor Representative">

                                                <el-option
                                                    v-for="(item,index) in VendorRepsList[0].users"
                                                    v-show="item.active == 'Yes'"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="item.id">
                                                    <span @click="repRoleCheck(index)" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }}</span>
                                                </el-option>

                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="20" v-if="VendorManagementRequestInfo.VendorRepID">
                                    <div class="grid-content" >
                                        <el-form-item label="" prop="RoleChange">
                                            <span slot="label"><b>Select Vendor Representative Role(s)</b></span>
                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                            <span class="tooltip-item2">
                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                            </span>
                                            <span class="tooltip-content4 clearfix" style="left: 200px">
                                                <span class="tooltip-text2">
                                                        The Vendor Bid Manager acts on behalf of the Vendor and, strictly through Ogeo, is responsible for submitting Sealed Bids, participating in Live Auctions, raising Requests For Information, and accepting Purchase Orders.     <br>        The Vendor Invoicing Manager is responsible for submitting, strictly through Ogeo, the Vendor's Invoices once the materials/services are received by my organization.
                                                </span>
                                            </span>
                                            </span>
                                            <div style="display: flex; justify-content: space-around; margin-top: 20px; width: 100%;">
                                                <div style="text-align: center; flex: 1;">
                                                    <h6>Bid Manager Role</h6>
                                                    <el-switch v-model="VendorManagementRequestInfo.formData.RoleChange[0]" @change="handleRoleAssignment" active-value="SBM" :inactive-value="null" active-color="rgb(255, 47, 47)" inactive-color="rgb(220, 220, 220)" ></el-switch>
                                                </div>
                                                <div style="text-align: center; flex: 1;">
                                                    <h6>Invoicing Manager Role</h6>
                                                    <el-switch v-model="VendorManagementRequestInfo.formData.RoleChange[1]" @change="handleRoleAssignment" active-value="SIM" :inactive-value="null" active-color="rgb(255, 47, 47)" inactive-color="rgb(220, 220, 220)" ></el-switch>
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </el-col>

                                <el-col :span="24">
                                    <div class="grid-content">
                                        <el-form-item label="temp" prop="ReasonForRequest">
                                            <span slot="label"><b>Enter Reason for Request</b></span>
                                            <el-input v-model="VendorManagementRequestInfo.Reason" placeholder="Enter Reason For The Request"></el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>


                                <el-col :span="24">

                                    <button type="button" @click="SubmitVendorManagementRequest" :class="'btn btn-success btn-block waves-effect text-center'">Create A Request To Change Vendor Representative Role</button>
                                </el-col>

                            </el-row>
                        </el-form>
                    </div>
                    <!-- Change A Vendor Representative Role Request Section End -->
                </div>
            </div>

        </div>


        <!--


        #############################################################################################################
        #                                                                                                           #
        #                                         MODALS SECTION STARTS HERE                                        #
        #                                                                                                           #
        #############################################################################################################


        -->


        <!-- Create A Request To Update The Information Of A Qualified Vendor Modal-->
        <div id="EditViewModal" ref="EditViewModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="EditViewModal" data-backdrop="static" data-keyboard="false" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-xl" >
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h3 class="modal-title text-ogeo">Vendor ID: {{ EditModalInfo.id }} </h3>
                        </div>
                        <div style="width:10%">
                            <img :src="'/uploads/Logos/'+this.companyDetails.logo" style="width:60px;height:60px;border-radius:25px">
                        </div>
                        <div style="width:45%">
                            <span style="font-size: 24px; float: right;"><b class="text-ogeo">Score:</b> <b class="text-ogeo" v-if="EditModalInfo.vendor_score">{{ EditModalInfo.vendor_score }}/100</b><b class="text-ogeo" v-else>N/A</b></span>
                        </div>
                        <div >
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            <!-- <span style="float: right; text-align: right; font-size: 24px"><b>Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor_score }} 10</b></span> -->
                        </div>
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <el-form :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="VendorEditRequestForm">
                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="name">
                                                    <span slot="label"><b>Enter Vendor Legal Name</b></span>
                                                    <el-input v-model="EditModalInfo.name" placeholder="Enter Vendor Legal Name"  @change="handleLogs('Vendor Legal Name', 'name', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="vendor_alias">
                                                    <span slot="label"><b>Enter Vendor Alias</b></span>
                                                    <el-input v-model="EditModalInfo.vendor_alias" placeholder="Enter Vendor Alias"  @change="handleLogs('Vendor Alias', 'vendor_alias', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="phone">
                                                    <span slot="label"><b>Enter Vendor Phone Number</b></span>
                                                    <el-input v-model="EditModalInfo.phone" placeholder="Enter Vendor Phone Number"  @change="handleLogs('Vendor Phone Number', 'phone', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="address">
                                                    <span slot="label"><b>Enter Vendor Address</b></span>
                                                    <el-input v-model="EditModalInfo.address" placeholder="Enter Vendor Adress"  @change="handleLogs('Vendor Address', 'address', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="po_box">
                                                    <span slot="label"><b>Enter Vendor PO.Box</b></span>
                                                    <el-input v-model="EditModalInfo.po_box" placeholder="Enter Vendor PO.Box"  @change="handleLogs('Vendor PO.Box', 'po_box', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="country">
                                                    <span slot="label"><b>Select Vendor Country</b></span>
                                                    <el-select filterable collapse-tags id="country" class="country" style="width: 100%; padding: 0px" v-model="EditModalInfo.country" placeholder="Select Country" @change="getCities($event); handleLogs('Vendor Country', 'country', $event);">

                                                        <el-option
                                                            v-for="(item,CompanyCountry) in countries"
                                                            :key="CompanyCountry"
                                                            :label="item.country_name"
                                                            :value="item.country_name">
                                                            <span style="float: left">{{ item.country_name }}</span>
                                                        </el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="city">
                                                    <span slot="label"><b>Select Vendor City</b></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.city" placeholder="Select City"  @change="handleLogs('Vendor City', 'city', $event)">

                                                        <el-option
                                                            v-for="(item,companyCity) in cities"
                                                            :key="companyCity"
                                                            :value="item.city_name"
                                                            :label="item.city_name">
                                                        <span style="float: left">{{ item.city_name }}</span>
                                                        </el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="officer_name">
                                                    <span slot="label"><b>Enter Vendor Highest Ranking Officer Name</b></span>
                                                    <el-input v-model="EditModalInfo.officer_name" placeholder="Enter Vendor Highest Ranking Officer Name"  @change="handleLogs('Vendor Highest Ranking Officer Name', 'officer_name', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="officer_phone">
                                                    <span slot="label"><b>Enter Vendor Highest Ranking Phone Number</b></span>
                                                    <el-input v-model="EditModalInfo.officer_phone" placeholder="Enter Vendor Highest Ranking Phone Number"  @change="handleLogs('Vendor Highest Ranking Phone Number', 'officer_phone', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="rep_name">
                                                    <span slot="label"><b>Enter Vendor Representative Name</b></span>
                                                    <el-input v-model="EditModalInfo.rep_name" placeholder="Enter Vendor Representative Name"  @change="handleLogs('Vendor Representative Name', 'rep_name', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="rep_title">
                                                    <span slot="label"><b>Enter Vendor Representative Title</b></span>
                                                    <el-input v-model="EditModalInfo.rep_title" placeholder="Enter Vendor Representative Title"  @change="handleLogs('Vendor Representative Title', 'rep_title', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="mobile">
                                                    <span slot="label"><b>Enter Vendor Representative Phone Number</b></span>
                                                    <el-input v-model="EditModalInfo.mobile" placeholder="Enter Vendor Representative  Phone Number"  @change="handleLogs('Vendor Representative Phone Number', 'mobile', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_name">
                                                    <span slot="label"><b>Enter Vendor Bank Name</b></span>
                                                    <el-input v-model="EditModalInfo.bank_name" placeholder="Enter Vendor Bank Name"  @change="handleLogs('Vendor Bank Name', 'bank_name', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_swift">
                                                    <span slot="label"><b>Enter Vendor Bank Identifier Code
                                                        (BIC)/SWIFT Code</b></span>
                                                    <el-input v-model="EditModalInfo.bank_swift" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code"  @change="handleLogs('Vendor Bank Identifier Code', 'bank_swift', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_address">
                                                    <span slot="label"><b>Enter Vendor Bank Address</b></span>
                                                    <el-input v-model="EditModalInfo.bank_address" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code"  @change="handleLogs('Vendor Bank Address', 'bank_address', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="bank_iban">
                                                    <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span>
                                                    <el-input v-model="EditModalInfo.bank_iban" placeholder="Enter Vendor International Bank Account Number (IBAN)"  @change="IbanVeifyTemp=false, EditModalInfo.bank_iban_verify={verified: false, tool: ''}, handleLogs('Vendor International Bank Account Number', 'bank_iban', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item label="" prop="VendorBankIBANVerify">
                                                    <!-- <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span> -->
                                                    <el-checkbox class="chkAgree" v-model="IbanVeifyTemp" @change="VerifyIBAN($event)" label="I Have Verified The Vendor IBAN"/><br>
                                                    <!-- <el-checkbox class="chkAgree" :value="false" v-model="EditModalInfo.VerifyIBAN.verified" label="I Have NOT Verified The Vendor IBAN"/> -->

                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="open_since">
                                                    <span slot="label"><b>Select Vendor Esablishment Year</b></span>
                                                    <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.open_since"  placeholder="Select Vendor Esablishment Year"  @change="handleLogs('Vendor Establishment Year', 'open_since', $event)">
                                                        <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="turnover">
                                                    <span slot="label"><b>Enter Average of The Last 3 Years Turnover(in US Dollars)</b></span>
                                                    <el-input v-model="EditModalInfo.turnover" @input="commaprice()" placeholder="Enter Average of The Last 3 Years Turnover"  @change="handleLogs('Vendor Average Of The Last 3 Years Turnover', 'turnover', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="operating_countries">
                                                    <span slot="label"><b>Select Countries Of Operations</b></span>
                                                    <el-select filterable multiple collapse-tags id="country" class="country" style="width: 100%; padding: 0px" v-model="EditModalInfo.operating_countries" placeholder="Select Countries Of Operations"  @change="getMultiCities($event), handleLogs('Vendor Countries of Operation', 'operating_countries', $event)">
                                                        <el-option style="min-width:145px !important"
                                                            v-for="(item,CompanyCountry) in countries"
                                                            :key="CompanyCountry"
                                                            :label="item.country_name"
                                                            :value="item.country_name">
                                                            <span style="float: left;">{{ item.country_name }}</span>
                                                        </el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item prop="operating_cities">
                                                    <span slot="label"><b>Select Cities Where The Vendor Operates</b></span>
                                                    <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.operating_cities" placeholder="Select City"  @change="handleLogs('Vendor Cities Of Operation', 'operating_cities', $event)">

                                                        <el-option
                                                            v-for="(item,companyCity) in multiCities"
                                                            :key="companyCity"
                                                            :value="item.city_name"
                                                            :label="item.city_name">
                                                        <span style="float: left">{{ item.city_name }}</span>
                                                        </el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="url">
                                                    <span slot="label"><b>Enter Vendor Website</b></span>
                                                    <el-input v-model="EditModalInfo.url" placeholder="http://www.vendorwebsite.com/otherurl/232/wsd"  @change="handleLogs('Vendor Website', 'url', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="lic_num">
                                                    <span slot="label"><b>Enter Vendor License Number</b></span>
                                                    <el-input v-model="EditModalInfo.lic_num" placeholder="Enter Vendor  License Number"  @change="handleLogs('Vendor License Number', 'lic_num', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="lic_authority">
                                                    <span slot="label"><b>Enter The Name Of The Trade License Issuing Authority</b></span>
                                                    <el-input v-model="EditModalInfo.lic_authority" placeholder="Enter The Name Of The Trade License Issuing Authority"  @change="handleLogs('Vendor License Issuing Authority', 'lic_authority', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="vendor_type">
                                                    <span slot="label"><b>Select Vendor Type</b></span>
                                                    <el-select filterable collapse-tags multiple allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.vendor_type"  placeholder="Select Vendor Type"  @change="handleLogs('Vendor Type', 'vendor_type', $event)">

                                                        <el-option label="Manufacturer" value="Manufacturer"></el-option>
                                                        <el-option label="Distributor" value="Distributor"></el-option>
                                                        <el-option label="Wholesaler" value="Wholesaler"></el-option>
                                                        <el-option label="Retailer" value="Retailer"></el-option>
                                                        <el-option label="Consultant" value="Consultant"></el-option>
                                                        <el-option label="Contractor" value="Contractor"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="provided_service">
                                                    <span slot="label"><b>Select Vendor Business Sector</b></span>
                                                    <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.provided_service"  placeholder="Select Vendor Business Sector"  @change="handleLogs('Vendor Business Sector', 'provided_service', $event)">

                                                        <el-option label="Information Technology Services" value="Information Technology Services"></el-option>
                                                        <el-option label="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services" value="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services"></el-option>
                                                        <el-option label="Transportation Services" value="Transportation Services"></el-option>
                                                        <el-option label="Medicine And Wellness Services" value="Medicine And Wellness Services"></el-option>
                                                        <el-option label="Convenience Services" value="Convenience Services"></el-option>
                                                        <el-option label="Financial Services" value="Financial Services"></el-option>
                                                        <el-option label="Contracting Services" value="Contracting Services"></el-option>
                                                        <el-option label="Construction & Fit-out Services" value="Construction & Fit-out Services"></el-option>
                                                        <el-option label="Supply of Mechanical Spare Parts" value="Supply of Mechanical Spare Parts"></el-option>
                                                        <el-option label="Supply of Electrical Spare Parts" value="Supply of Electrical Spare Parts"></el-option>
                                                        <el-option label="Supply of Construction Materials" value="Supply of Construction Materials"></el-option>
                                                        <el-option label="Supply of Foodstuff & Edible Products" value="Supply of Foodstuff & Edible Products"></el-option>
                                                        <el-option label="Supply of Tools" value="Supply of Tools"></el-option>
                                                        <el-option label="Supply of OEM Equipment" value="Supply of OEM Equipment"></el-option>
                                                        <el-option label="Supply of Furniture" value="Supply of Furniture"></el-option>
                                                        <el-option label="Supply of Vehicles" value="Supply of Vehicles"></el-option>
                                                        <el-option label="Supply of Textile Products" value="Supply of Textile Products"></el-option>
                                                        <el-option label="Supply of IT Products" value="Supply of IT Products"></el-option>
                                                        <el-option label="Supply of Fossil Fuels" value="Supply of Fossil Fuels"></el-option>
                                                        <el-option label="Supply of Stationary Products" value="Supply of Stationary Products"></el-option>
                                                        <el-option label="Supply of Packaging Products" value="Supply of Packaging Products"></el-option>
                                                        <el-option label="Supply of Fragrance Products" value="Supply of Fragrance Products"></el-option>
                                                        <el-option label="Supply of Cosmetic Products" value="Supply of Cosmetic Products"></el-option>
                                                        <el-option label="Supply of Cleaning Products" value="Supply of Cleaning Products"></el-option>
                                                        <el-option label="Supply of Personal Hygienic Products" value="Supply of Personal Hygienic Products"></el-option>
                                                        <el-option label="Supply of Consumer Electronics Products" value="Supply of Consumer Electronics Products"></el-option>
                                                        <el-option label="Supply of Whitegoods Products" value="Supply of Whitegoods Products"></el-option>
                                                        <el-option label="Supply of Garment Products" value="Supply of Garment Products"></el-option>

                                                        <!-- Add Additional Options From Existing Vendors -->

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="sold_brands">
                                                    <span slot="label"><b>Enter Brands Represented By The Vendor In His Cities Of Operations</b></span>
                                                    <el-input v-model="EditModalInfo.sold_brands" placeholder="Enter Brands Represented By The Vendor"  @change="handleLogs('Brands Represented By The Vendor', 'sold_brands', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="12">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="exculsive_brands">
                                                    <span slot="label"><b>Enter Brands Exclusively Represented By The Vendor In His Cities Of Operations</b></span>
                                                    <el-input v-model="EditModalInfo.exculsive_brands" placeholder="Brands Exclusively Represented By The Vendor"  @change="handleLogs('Brands Exclusively Represented By The Vendor', 'exclusive_brands', $event)"></el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="vendor_dealsIn">
                                                    <span slot="label"><b>Select What Can Be Sourced From The Vendor</b></span>
                                                    <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="EditModalInfo.vendor_dealsIn"  placeholder="*Select What Can Be Sourced From The Vendor"  @change="handleLogs('What Can Be Sourced From Vendor', 'vendor_dealsIn', $event)">

                                                        <el-option label="Materials" value="Materials"></el-option>
                                                        <el-option label="Services" value="Services"></el-option>
                                                        <el-option label="Materials + Services" value="Materials + Services"></el-option>

                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <transition v-if="EditModalInfo.vendor_dealsIn == 'Materials' || EditModalInfo.vendor_dealsIn == 'Materials + Services'" >
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="linked_item_templates">
                                                        <span slot="label"><b>Select Applicable Library of Materials Templates</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                            </span>
                                                            <span class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <strong>I’m offered the possibility to link existing
                                                                    applicable templates to the vendor. These
                                                                    templates are pertaining to materials likely to be
                                                                    offered by the vendor.</strong>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <el-select id="SelectedItemTemplates" filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="EditModalInfo.linked_item_templates" placeholder="Select Library of Materials Template"  @change="handleLogs('Vendor Applicable Library of Material Templates', 'linked_item_templates', $event)">

                                                            <el-option v-for="Template in ItemTemplates" :key="Template.id" :label="Template.template_name" :value="Template.id"></el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </transition>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr/>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <hr>
                                                <h6 class="">Vendor Qualification Documents:</h6>
                                                <el-form-item prop="VendorQualificationDocsEdit">
                                                    <table class="table table-bordered">
                                                        <thead class="text-center">
                                                            <th class="font-700">File Name</th>
                                                            <th class="font-700" width="50%">Action</th>
                                                        </thead>
                                                        <tbody v-if="VQReqDocNames.length">
                                                            <tr v-for="(item, index) in VQDocsWNames" :key="index">
                                                                <td>{{ item.document_name}}</td>
                                                                <td v-if="item.uploaded" style="text-align:center" :class="'modal-vqd-rem-button modal-vq-del'+index" id="modal-vqd-rem-button">
                                                                    <button style="width: 120px" type="button" class="btn btn-danger" @click="deleteDocument(index)" >Remove File</button>
                                                                </td>
                                                                <td v-else style="text-align:center" :class="'modal-vq-docs'+index" id="modal-vqd-add-button">
                                                                    <el-upload
                                                                        style=""
                                                                        class="upload-demo d-inline-flex"
                                                                        name=""
                                                                        action="#"
                                                                        :limit="1"
                                                                        id='VQReqDocs'
                                                                        :auto-upload="false"
                                                                        ref="VQReqDocs"
                                                                        accept=".pdf"
                                                                        :on-change="handleChangeSD"
                                                                        :on-exceed="handleExceedSD"
                                                                        :on-remove="handleRemoveSD">
                                                                        <button style="width: 120px" type="button" slot="trigger" @click="UploadKeyName = {req_id: item.id, name: item.document_name}" class="btn btn-success" >Upload File</button>

                                                                        <!-- <div class="el-upload__tip" slot="tip">JPEG, PNG, DOCX, PDF, XLSX file types are accepted with a size less than 1Mb (Max 4 Files)</div> -->
                                                                    </el-upload>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td colspan="3" align="center">No Qualification Documents Available</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </el-form-item>
                                            </div>
                                            <!-- <div class="grid-content">
                                                <el-form-item prop="VendorQualificationDocs">
                                                    <span slot="label"><b>“Upload The Required
                                                        Qualification Documents</b></span>

                                                        <ol class="mt-5">
                                                            <li style="width: auto;" v-for="(item, index) in VQReqDocNames">
                                                                <h6 class="d-inline-block w-50">{{  item.document_name }}</h6>
                                                                <el-upload
                                                                    style=""
                                                                    class="upload-demo d-inline-flex"
                                                                    name="Hello"
                                                                    action="#"
                                                                    :limit="1"
                                                                    id='VQReqDocs'
                                                                    :auto-upload="false"
                                                                    ref="VQReqDocs"
                                                                    accept=".pdf"
                                                                    :disabled="this.VendorManagementRequestInfo.Type == VQ_Actions_Typos[8]"
                                                                    :on-change="handleChangeSD"
                                                                    :on-exceed="handleExceedSD"
                                                                    :on-remove="handleRemoveSD">
                                                                    <button type="button" slot="trigger" @click="UploadKeyName = item.document_name" class="btn btn-success">Click to Select File</button>

                                                                </el-upload>


                                                            </li>

                                                        </ol>
                                                    <div class="float-left">
                                                    </div>

                                                </el-form-item>
                                            </div> -->
                                        </el-col>
                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="lic_expiry_date">
                                                    <span slot="label"><b>Select The Vendor’s Qualification Expiry Date</b></span>

                                                    <el-date-picker
                                                    :picker-options = "pickerOptions"
                                                    style="width: 100%"
                                                    v-model="EditModalInfo.lic_expiry_date"
                                                    :disabled="VendorManagementRequestInfo.Type == VQ_Actions_Typos[1]"
                                                    @change="handleLogs('Vendor Qualification Expiry Date', 'lic_expiry_date', $event)"
                                                    type="date"
                                                    format="dd.MMM.yyyy"
                                                    value-format="dd-MM-yyyy">
                                                    </el-date-picker>

                                                </el-form-item>
                                            </div>
                                            <h6 v-if="EditModalInfo.lic_expiry_date">
                                                Transactions With The Vendor Will Be suspended
                                                When The Qualification Expires
                                            </h6>
                                        </el-col>


                                        <el-col :span="24">
                                            <hr>
                                        </el-col>

                                        <el-col :span="24">

                                            <button type="button" @click="validateAndSaveForm('VendorEditRequestForm')" :class="['btn btn-success btn-block waves-effect text-center']">Verify And Save Information</button>
                                        </el-col>

                                    </el-row>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Create A Request To Update The Information Of A Qualified Vendor Modal End-->
    </div>


</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'NewVendorManagementRequest',
        data(){

            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === ''){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }

                }
            };
            var checkVQDocs = (rule, value, callback) => {
                if (this.VQReqDocNames.length == Object.keys(this.VendorCreationInfo.VendorDocuments).length) {
                    callback();
                } else {
                    callback(new Error('Please Upload All Required Documents For Qualification!'));
                    // if(Object.keys(this.VendorCreationInfo.VendorDocuments).length == 0){
                    //     callback(new Error('Vendor Qualification Documents Are Required!'));
                    // } else {
                    //     callback(new Error('Please Upload All Required Documents For Qualification!'));
                    // }
                }
            };
            var checkVQDocsEdit = (rule, value, callback) => {
                this.checkEditModalDocs()
                    .then(response => {
                        if(response)
                            callback()
                        else
                            callback(new Error('Please Upload All Required Documents For Qualification!'));
                    })
                    .catch(error => console.error(error));
            };
            var checkRoR = (rule, value, callback) => {
                if (this.VendorManagementRequestInfo.Reason === '' || this.VendorManagementRequestInfo.Reason === null) {
                    callback(new Error('Please Enter Reason For The Request.'));
                } else {
                    callback();
                }
            };
            var CheckAlias = (rule, value, callback) => {
                if (value === '' || value === null) {
                    callback(new Error('Vendor Alias is Required.'));
                } else {
                        this.checkVendorAlias(value)
                            .then(response => {
                                if(response){
                                    callback();
                                }
                                else
                                    callback(new Error('Vendor Alias Already Taken!'));
                            })
                            .catch(error => console.error(error));
                }
            };
            var checkUserEmail = (rule, value, callback) => {
                if(value === '' || value === null)
                    callback(new Error('User Email is Required!'));
                else{
                    if(value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
                        callback();
                }
                else
                    callback(new Error('Please Enter Valid Email Format! (e.g john@doe.com)'));

                }
            };
            return{
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() < Date.now();
                  },
                },
                DataTable: "",
                editDataLoaded: false,
                ItemTemplates: [],
                VerifyInformation: false,
                companyDetails: {},
                JobNumbers: {},
                countries: {},
                cities: {},
                multiCities: {},
                VendorSC: [],
                VQReqDocNames: "",
                FilesToDelete: [],
                UploadKeyName:"",
                ReferenceFormName:"",
                TmpVC: "",
                TempRole: {SIM: "", SBM: ""},
                IbanVeifyTemp: false,
                flag: "",
                existingRepRole: "",
                BulkUploadFlag: false,
                SIMalert: "",
                SBMalert: "",
                auditLog: [],
                VQDocsWNames: [],
                VQ_Actions: [
                    'Add A New Vendor',
                    'Update The Information Of A Qualified Vendor',
                    'Inactivate An Active Vendor',
                    'Reactivate An Inactive Vendor',
                    'Add A Vendor Representative',
                    'Inactivate A Vendor Representative',
                    'Reactivate A Vendor Representative',
                    'Change A Vendor Representative Role',
                    'Renew A Vendor Expired Qualification'
                ],
                VQ_Actions_Typos: [
                    'Qualification Of A New Vendor',
                    'Vendor Information Amendment',
                    'Vendor Inactivation',
                    'Vendor Reactivation',
                    'Addition Of A New Vendor Representative',
                    'Vendor Representative Inactivation',
                    'Vendor Representative Reactivation',
                    'Vendor Representative Role Change',
                    'Vendor Expired Qualification Renewal'
                ],
                VendorsList: "",
                VendorRepsList: "",
                selectedVendor: {},
                EditModalInfo: {},
                BackupModalInfo: {},
                ViewModalInfo: {},
                VendorManagementRequestInfo: {
                    ProjectID: "",
                    VendorID: "",
                    VendorRepID: "",
                    formData: {},
                    Reason:"",
                    Type: ""
                },
                VendorRepCreationInfo: {
                    VendorId: "",
                    VendorRepName: "",
                    VendorRepEmail: "",
                    VendorRepRole: [],
                },
                VendorCreationInfo: {
                    VendorName: "",
                    VendorAlias: "",
                    VendorRepName: "",
                    VendorRepTitle: "",
                    VendorAddress: "",
                    VendorPOBOX: "",
                    VendorPhone: "",
                    VendorMobile: "",
                    VendorURL: "",
                    VendorLicenseNumber: "",
                    VendorLicenseExpiryDate: "",
                    VendorLicenseIssuingAuthority: "",
                    VendorProvidedServices: [],
                    VendorCity: "",
                    VendorCountry: "",
                    VendorOperationCountries: [],
                    VendorOperationCities: [],
                    VendorHighestRankingOfficerName: "",
                    VendorHighestRankingOfficerPhoneNumber: "",
                    VendorBankName: "",
                    VendorBankAddress: "",
                    VendorBankIBAN: "",
                    VendorSwiftCode: "",
                    VerifyIBAN: {verified: false, tool: ""},
                    VendorTurnover: "",
                    VendorSource: "",
                    VendorOpenSince: "",
                    VendorSoldBrands: "",
                    VendorExclusiveBrands: "",
                    SelectedItemTemplates: [],
                    VendorType: "",
                    VendorQualificationExpires: "",
                    VendorNotes: "",
                    VendorDocuments: {},

                },
                rules: {
                    VendorID: [{
                        required: true,
                        message: "Please Select A Vendor",
                        trigger: "blur"
                    }],
                    VendorRepID: [{
                        required: true,
                        message: "Please Select A Vendor Representative",
                        trigger: "blur"
                    }],
                    RoleChange: [{
                        required: true,
                        validator: (rule, value, callback) =>
                        {
                            if(this.VendorManagementRequestInfo.formData.RoleChange.includes('SBM')
                                || this.VendorManagementRequestInfo.formData.RoleChange.includes('SIM'))
                                callback();
                            else
                                callback(new Error('A Vendor Representative Role Is Required!'))
                        },
                        trigger: "blur"
                    }],
                    ReasonForRequest: [{
                        required: true,
                        validator: checkRoR,
                        trigger: "blur"
                    }],
                    VendorName: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    VendorAlias: [{
                        required: true,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    VendorRepName: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    VendorRepTitle: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    VendorAddress: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    VendorPOBOX: [{
                        required: false,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    VendorPhone: [{
                        required: false,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    VendorMobile: [{
                        required: false,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    VendorQualificationExpires: [{
                        required: true,
                        message: "Please Enter Vendor Qualification Expires",
                        trigger: "blur"
                    }],
                    VendorQualificationDocs: [{
                        required: true,
                        validator: checkVQDocs,
                        trigger: "blur"
                    }],
                    VendorQualificationDocsEdit: [{
                        required: true,
                        validator: checkVQDocsEdit,
                        trigger: "blur"
                    }],
                    VendorOperationCountries: [{
                        required: true,
                        message: "Please Enter Country",
                        trigger: "blur"
                    }],
                    VendorOperationCities: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    VendorLicenseExpiryDate: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    VendorSource: [{
                        required: true,
                        message: "Please Enter Vendor Source",
                        trigger: "blur"
                    }],
                    VendorURL: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    VendorNotes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    VendorDocuments: [{
                        required: false,
                        message: "Please Upload At least one documenet",
                        trigger: "blur"
                    }],
                    VendorCity: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    VendorCountry: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    VendorProvidedServices: [{
                        required: false,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    VendorHighestRankingOfficerName: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    VendorHighestRankingOfficerPhoneNumber: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    VendorBankName: [{
                        required: true,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    VendorBankIBAN: [{
                        required: true,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    VendorSwiftCode: [{
                        required: true,
                        message: "Please Enter Vendor Bank Identifier Code",
                        trigger: "blur"
                    }],
                    VendorBankAddress: [{
                        required: true,
                        message: "Please Enter Vendor Bank Address",
                        trigger: "blur"
                    }],
                    VendorTurnover: [{
                        required: true,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "change"
                    }],
                    VendorOpenSince: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    VendorSoldBrands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    VendorExclusiveBrands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    SelectedItemTemplates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    VendorType: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                    VendorId: [{
                        required: true,
                        message: "Please Select A Vendor",
                        trigger: "blur"
                    }],
                    VendorRepName: [{
                        required: true,
                        message: "Please Enter User Name",
                        trigger: "blur"
                    }],
                    VendorRepEmail: [
                    {
                        required: true,
                        validator: checkUserEmail,
                        trigger: "blur"
                    }
                    ],
                    VendorRepRole: [{
                        required: true,
                        message: "Please Select Vendor Role",
                        trigger: ["blur"]
                    }],
                    // EditModalInfo RuleSet
                    name: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    vendor_alias: [{
                        required: true,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    rep_name: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    rep_title: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    address: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    po_box: [{
                        required: true,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    phone: [{
                        required: true,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    mobile: [{
                        required: true,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    lic_expiry_date: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    url: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    notes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    vendor_dealsIn: [{
                        required: true,
                        message: "Please Enter Vendor Source",
                        trigger: "blur"
                    }],
                    city: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    country: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    operating_cities: [{
                        required: true,
                        message: "Please Enter Cities of Operation",
                        trigger: "blur"
                    }],
                    operating_countries: [{
                        required: true,
                        message: "Please Select A Countries of Operation",
                        trigger: "blur"
                    }],
                    provided_service: [{
                        required: true,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    officer_name: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    officer_phone: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    bank_name: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_address: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_swift: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_iban: [{
                        required: false,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    turnover: [{
                        required: false,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "blur"
                    }],
                    open_since: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    sold_brands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    exculsive_brands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    linked_item_templates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    vendor_type: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                },
            }
        },
        computed : {
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            },
            isEmptyObj() {
                return obj => obj ? Object.keys(obj).length === 0 : false;
            },
        },
        methods: {
            // getCity() {
            //     axios.get('/data/countries_mini.json').
            //         then((response) => {
            //             this.Countries = response.data;
            //             console.log();
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            commaprice() {
                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0])
                    this.VendorCreationInfo.VendorTurnover = this.VendorCreationInfo.VendorTurnover.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                else
                    this.EditModalInfo.turnover = this.EditModalInfo.turnover.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
            getCountries() {
                axios.get('api/get/countries')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
            getCities(e) {
                // this.cities= this.countries[this.VendorCreationInfo.VendorCountry];
                axios.get('api/get/cities-solo/'+ e)
                .then((res) => {
                    this.cities = res.data
                })
            },
            getMultiCities(e) {
                axios.post('api/get/cities-multi', e)
                .then((res) => {
                    this.multiCities = res.data
                })
            },
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            VerifyIBAN(e){
                if(e){
                    var inputValueIbanCal = (this.EditModalInfo.hasOwnProperty('bank_iban_verify') && this.EditModalInfo.bank_iban_verify.tool) ? this.EditModalInfo.bank_iban_verify.tool : '';
                    var inputTargetCal = this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0] ? 'body' : document.getElementById('EditViewModal');
                    Swal.fire({
                        title: "Verification",
                        text: "Enter The Tool Used To Check The IBAN:",
                        input: 'text',
                        target: inputTargetCal,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        confirmButtonText: "Submit",
                        inputValue: inputValueIbanCal,
                        inputPlaceholder: 'Enter the tool used to Verify IBAN!',
                        inputValidator: (value) => {
                                return !value && 'You need to write the tool used to verify IBAN!'
                            }
                    }).then((result) => {
                        if (result.value) {
                            if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0])
                                this.VendorCreationInfo.VerifyIBAN.tool = result.value;
                            else
                                this.EditModalInfo.bank_iban_verify = {verified: e, tool: result.value};
                        }
                    });
                }
                else{
                    if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0])
                        this.VendorCreationInfo.VerifyIBAN = {verified: false, tool: ""};
                    else
                        this.EditModalInfo.bank_iban_verify = {verified: false, tool: ""};
                }
            },
            checkVendorAlias(e){
                return new Promise((resolve) => {
                            var VendorID = this.EditModalInfo.id ? this.EditModalInfo.id : '';
                            axios.post('/api/vendors_management/check_vendor_alias_duplicates', {name: e, id:VendorID })
                            .then( (response) => {
                                if(response.data == 'Yes') resolve(false);
                                else resolve(true);
                            })
                            .catch(error => { console.error(error) });
                        });
            },
            checkEditModalDocs(){
                return new Promise((resolve) => {
                            let elements = $('#EditViewModal').find('.modal-vqd-rem-button');
                            let uploadCount = 0;
                            $('#EditViewModal').find('#VQReqDocs input[type="file"]').each((index, element) => { var files = $(element).get(0).files; if (files && files.length > 0) uploadCount++ });
                            let docsCount = uploadCount + (elements && elements.length ? elements.length : 0);
                            console.log(docsCount);
                            if(docsCount == this.VQReqDocNames.length)
                                resolve(true);
                            else
                                resolve(false);
                        });
            },
            checkUserEmail(e){
                axios.post( '/api/vendors_management/check_vendor_user', this.VendorRepCreationInfo)
                            .then((response) => {
                                if(response.data.messageType == 'warning') this.flag = false;
                                else this.flag = true;
                                });
            },
            getVQReqDocNames: function(){
                axios.get('/api/vendors_management/get_vendor_qualification_req_docs')
                .then((response) => {
                    this.VQReqDocNames = response.data;
                })

            },
            FileCount(file) {
                this.EditModalInfo.qualification_documents.push({ name:file.name.split('.').slice(0, -1).join('.'), ext: file.name.split('.').pop(), timestamp:moment().format('DD,MMM,YYYY')})
            },
            handleRoleAssignment() {
                var arr = this.VendorManagementRequestInfo.formData.RoleChange.filter(item => item !== null);
                this.handleLogs('Vendor Representative Role', 'RoleChange', arr);
            },
            handleLogs(n, ref ,nv) {
                var obj = {};
                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[7])
                    obj = { desc: n, old_val: this.existingRepRole , new_val: nv};
                else
                    obj = { desc: n, old_val: this.BackupModalInfo[ref] , new_val: nv};
                var elementPos = this.auditLog.map(function(x) {return x.desc; }).indexOf(n);
                if(elementPos !== -1)
                    this.auditLog[elementPos] = obj;
                else
                    this.auditLog.push(obj);
            },
            handleChangeSD(file, fileList){

                const isPDF = file.raw.type === 'application/pdf';

                const isLt2M = file.raw.size / 1024 / 1024 < 1.1;

                if ((isPDF) && isLt2M) {

                // var array=this.VendorCreationInfo.VendorDocuments;
                var array = {};
                var filename = this.UploadKeyName.name;
                var req_id = this.UploadKeyName.req_id;
                const reader=new FileReader();
                reader.readAsDataURL(file.raw);
                reader.onload = (e) => {
                        var rawLog = reader.result;
                        Object.assign(array, {req_id: req_id, name: filename, data: rawLog});
                    };
                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0])
                    this.VendorCreationInfo.VendorDocuments[file.uid] = array;
                else
                    this.EditModalInfo.VendorDocuments[file.uid] = array;

                }
                else {
                    this.$message.error('Document must be PDF format with size not exceeding 1MB!!');
                    fileList.splice(-1);
                    return false;
                }

            },
            handleExceedSD: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 1 document.'  , 'error');
            },
            handleRemoveSD(file, fileList) {

                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0])
                   delete this.VendorCreationInfo.VendorDocuments[file.uid];
                else
                   delete this.EditModalInfo.VendorDocuments[file.uid];


            },
            deleteDocument(e){
                Swal({
                        type: 'warning',
                        title: 'Warning!',
                        text: 'Are you sure you want to remove this Vendor Qualificaton Document?',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        if(confirm.value) {
                            var FileID = this.VQDocsWNames[e].uploaded.id
                            this.FilesToDelete.push(this.VQDocsWNames[e].id);
                            this.$delete(this.VQDocsWNames[e], 'uploaded');
                        }
                    });

            },
            getVendorRepList(e){
                axios.get( '/api/data/get_vendor_users/' + e)
                            .then((response)=>{
                                this.VendorRepsList = response.data;
                                console.log(this.VendorRepsList)
                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                            });
            },
            repRoleCheck(e){
                var arr = new Array();
                this.VendorManagementRequestInfo.formData = {   RoleChange: []  };
                if(this.VendorRepsList[0].users[e].pivot.sim_role == 'Yes')
                    arr[1] = "SIM";
                else
                    arr[1] = null;
                if(this.VendorRepsList[0].users[e].pivot.sbm_role == 'Yes')
                    arr[0] = "SBM";
                else
                    arr[0] = null;
                this.VendorManagementRequestInfo.formData.RoleChange = arr;
                this.existingRepRole = arr.filter(arr => arr !== null);
            },
            handleAlerts() {
                if(this.VendorRepCreationInfo.VendorRepRole.includes('SIM') && !this.SIMalert){
                                        Swal.fire({
                                            type: 'warning',
                                            title: 'Should this user be assigned the role of "Invoicing Manager"',
                                            text:`He will be responsible for raising invoices against already receipted materials and services.`,
                                            showConfirmButton: true,
                                            confirmButtonText: 'Ok',
                                        });
                                        this.SIMalert = true;
                                    }
                else if(this.VendorRepCreationInfo.VendorRepRole.includes('SBM') && !this.SBMalert){
                                        Swal.fire({
                                            type: 'warning',
                                            title: 'Should this user be assigned the role of "Bid Manager"',
                                            text:`He will be responsible for the following: 1. Signaling willingness/unwillingness to submit Sealed Bids and participate in Live Auctions; 2. Actually, tabling offers to supply materials and services; and 3. Finally accepting/rejecting Purchase Orders.`,
                                            showConfirmButton: true,
                                            confirmButtonText: 'Ok',
                                        });
                                        this.SBMalert = true;
                                    }
            },
            handlePanels(e){
                this.BulkUploadFlag =  false;
                this.VerifyInformation = false;
                if(this.DataTable){
                        this.DataTable.clear()
                        this.DataTable.destroy()
                    }
                $(document).ready(() => {
                    this.VendorManagementRequestInfo.VendorID = "";
                    this.VendorManagementRequestInfo.VendorRepID = "";
                    this.VendorManagementRequestInfo.formData = {};
                    this.VendorManagementRequestInfo.Reason = "";
                    this.flag = "";

                    this.ReferenceFormName = $('.demo-form-inline').attr('id');
                    if(this.$refs[this.ReferenceFormName]) this.$refs[this.ReferenceFormName].resetFields(), this.$refs[this.ReferenceFormName].clearValidate();

                    if(e !== 'Qualification Of A New Vendor' && e !== 'Addition Of A New Vendor Representative'){
                        this.VendorsList = "";
                        axios.get( '/api/data/get_all_vendors_list')
                                .then((response)=>{
                                    this.VendorsList = response.data;
                                })
                                .catch(function(){
                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                });
                    }
                    else if(e == 'Qualification Of A New Vendor') {
                            this.getBulkVendorsDatatable();
                    }
                    else if(e == 'Addition Of A New Vendor Representative') {
                            this.VendorRepDatatable();
                            this.getBulkVendorUsersDatatable();
                    }
                    else {
                        this.VerifyInformation = false;
                    }
            });
            },
            getBulkVendorsFile(){
                let self = this;
                var $bulkVendorsData = $('#bulkVendorData');
                var data = new FormData(bulkVendorsData);
                var DatatableData = "";
                axios.post('/api/data/get_bulk_vendor_data_excel', data)
                .then((response) => {
                    if(response.data.messageType == 'success')
                        self.DataTable.ajax.reload( null, false );
                })

            },
            getBulkVendorsDatatable(){
                let self = this;
                var table = $('#BulkVendorsList').DataTable({
                    fixedHeader: {
                            header: true,
                            headerOffset: 117
                        },
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_bulk_vendor_qualification_datatable',
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'name', name: 'name' },
                        { data: 'vendor_alias', name: 'vendor_alias' },
                        { data: 'phone', name: 'phone' },
                        { data: 'po_box', name: 'phone' },
                        { data: 'address', name: 'address' },
                        { data: 'rep_name', name: 'rep_name' },
                        { data: 'rep_title', name: 'rep_title' },
                        { data: 'mobile', name: 'mobile' },
                        { data: 'officer_name', name: 'officer_name' },
                        { data: 'officer_phone', name: 'officer_phone' },
                        { data: 'bank_name', name: 'bank_name' },
                        { data: 'bank_swift', name: 'bank_swift' },
                        { data: 'bank_iban', name: 'bank_iban' },
                        { data: 'turnover', render: function(data) {
                            if(data){
                                var currency = (data/1).toFixed(2).replace(',', '.');
                                return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD";
                            }
                            else
                                return "0 USD";
                        } },
                        { data: 'lic_num', name: 'lic_num' },
                        { data: 'open_since', name: 'open_since' , "defaultContent": "N/A" },
                        { data: 'sold_brands', name: 'sold_brands', "defaultContent": "N/A" },
                        { data: 'exculsive_brands', name: 'exculsive_brands', "defaultContent": "N/A" },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                    {  targets: 19, responsivePriority: 1 },
                    {  targets: 0, responsivePriority: 2 },
                    {  targets: 2, width: '30%' },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },
                    initComplete: function(settings, json) {
                        $('#BulkVendorsList tbody').on('click', '.review-placeholder', function() {
                            // var $this = $(this);
                            var row = $(this).closest('tr');
                            var data = table.row(row).data();
                            $.ajax({
                                url: '/api/data/get_bulk_vendor_qualification_datatable/delete-row/' + data['id'],
                                type: 'get',
                                success: function(result) {
                                    // Remove row from DataTable
                                    table.row(row).remove().draw();
                                },
                                error: function(xhr, status, error) {
                                    console.error('Error deleting row:', error);
                                }
                            });
                            self.BulkUploadFlag = true;
                            self.VendorCreationInfo.VendorName = data.name;
                            self.VendorCreationInfo.VendorAlias = data.vendor_alias;
                            self.VendorCreationInfo.VendorRepName = data.rep_name;
                            self.VendorCreationInfo.VendorRepTitle = data.rep_title;
                            self.VendorCreationInfo.VendorAddress = data.address;
                            self.VendorCreationInfo.VendorPOBOX = data.po_box;
                            self.VendorCreationInfo.VendorPhone = data.phone;
                            self.VendorCreationInfo.VendorMobile = data.mobile;
                            self.VendorCreationInfo.VendorURL = data.url;
                            self.VendorCreationInfo.VendorLicenseNumber = data.lic_num;
                            self.VendorCreationInfo.VendorLicenseExpiryDate = "";
                            self.VendorCreationInfo.VendorProvidedServices = [];
                            self.VendorCreationInfo.VendorNotes = "";
                            self.VendorCreationInfo.VendorDocuments = [];
                            self.VendorCreationInfo.VendorSource= "";
                            self.VendorCreationInfo.VendorCity = "";
                            self.VendorCreationInfo.VendorCountry = "";
                            self.VendorCreationInfo.VendorOperationCities = [];
                            self.VendorCreationInfo.VendorOperationCountries = [];
                            self.VendorCreationInfo.VendorHighestRankingOfficerName = data.officer_name;
                            self.VendorCreationInfo.VendorHighestRankingOfficerPhoneNumber = data.officer_phone;
                            self.VendorCreationInfo.VendorBankName = data.bank_name;
                            self.VendorCreationInfo.VendorBankIBAN = data.bank_iban;
                            self.VendorCreationInfo.VendorSwiftCode = data.bank_swift;
                            self.VendorCreationInfo.VendorBankAddress = data.bank_address;
                            self.VendorCreationInfo.VendorTurnover = data.turnover;
                            self.VendorCreationInfo.VendorOpenSince = data.open_since;
                            self.VendorCreationInfo.VendorSoldBrands = data.sold_brands;
                            self.VendorCreationInfo.VendorExclusiveBrands = data.exculsive_brands;
                            self.VendorCreationInfo.VendorType = "";
                            self.VendorCreationInfo.SelectedItemTemplates = [];

                            const element = document.getElementById("VendorCreationRequestForm");
                            if(element)
                                element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                        });
                    }
                });


                self.DataTable = table;


            },
            getBulkVendorUsersFile(){
                let self = this;
                var $bulkVendorUsersData = $('#bulkVendorUsersData');
                var data = new FormData(bulkVendorUsersData);
                var DatatableData = "";
                axios.post('/api/data/get_bulk_vendor_user_data_excel', data)
                .then((response) => {
                    if(response.data.messageType == 'success')
                        self.DataTable.ajax.reload( null, false );
                })

            },
            getBulkVendorUsersDatatable(){
                let self = this;
                var table = $('#BulkVendorUsersList').DataTable({
                    fixedHeader: {
                            header: true,
                            headerOffset: 117
                        },
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_bulk_vendor_users_datatable',
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'name', name: 'name' },
                        { data: 'email', name: 'email' },
                        { data: 'sbm_role', name: 'sbm_role' },
                        { data: 'sim_role', name: 'sim_role' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                    {  targets: 5, responsivePriority: 1 },
                    {  targets: 0, responsivePriority: 2 },
                    {  targets: 2, width: '20%' },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },
                    initComplete: function (settings, json) {
                        $('tbody', self.$refs.BulkVendorUsersListTable).on('click', '.review-placeholder', function() {
                            // var $this = $(this);
                            var row = $(this).closest('tr');
                            var data = self.DataTable.row(row).data();
                            $.ajax({
                                url: '/api/data/get_bulk_vendor_users_datatable/delete-row/' + data.id,
                                type: 'get',
                                success: function(result) {
                                    // Remove row from DataTable
                                    table.row(row).remove().draw();
                                },
                                error: function(xhr, status, error) {
                                    console.error('Error deleting row:', error);
                                }
                            });
                            self.BulkUploadFlag = true;
                            self.VendorRepCreationInfo.VendorRepName = data.name;
                            self.VendorRepCreationInfo.VendorRepEmail = data.email;
                            self.VendorRepCreationInfo.VendorRepRole = [];
                            if(data.sbm_role == 'Yes')
                                self.VendorRepCreationInfo.VendorRepRole.push('SBM');
                            if(data.sim_role == 'Yes')
                                self.VendorRepCreationInfo.VendorRepRole.push('SIM');

                            const element = document.getElementById("VendorRepCreationForm");
                            if(element)
                                element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                        });

                        }
                });

                self.DataTable = table;



            },
            editViewModal(recordId){
                let self = this;
                self.BackupModalInfo = {};
                self.EditModalInfo = {};
                self.auditLog = [];
                self.VQDocsWNames = [];
                self.FilesToDelete = [];
                axios.post('/api/data/get_vendor_details', {VendorID: recordId})
                    .then((response) => {
                        self.EditModalInfo = response.data;
                        self.BackupModalInfo = Object.assign({}, response.data);
                        self.OriginalName = self.EditModalInfo.name;
                        if(self.EditModalInfo.vendor_type) this.EditModalInfo.vendor_type = self.EditModalInfo.vendor_type.split(', ')
                        if(self.EditModalInfo.country)
                            self.getCities(self.EditModalInfo.country);
                        if(self.EditModalInfo.provided_service)
                            self.EditModalInfo.provided_service = self.EditModalInfo.provided_service.split(',');
                        if(self.EditModalInfo.operating_countries)
                            self.EditModalInfo.operating_countries = self.EditModalInfo.operating_countries.split(','), self.getMultiCities(self.EditModalInfo.operating_countries);
                        if(self.EditModalInfo.operating_cities)
                            self.EditModalInfo.operating_cities = self.EditModalInfo.operating_cities.split(',');
                        if(self.EditModalInfo.lic_expiry_date)
                            self.EditModalInfo.lic_expiry_date = new Date(self.EditModalInfo.lic_expiry_date);
                        if(self.isEmpty(self.EditModalInfo.bank_iban_verify))
                            self.EditModalInfo.bank_iban_verify = {verified: false, tool: ''}
                        else
                            self.EditModalInfo.bank_iban_verify = JSON.parse(self.EditModalInfo.bank_iban_verify), self.IbanVeifyTemp = self.EditModalInfo.bank_iban_verify.verified;
                        self.EditModalInfo.VendorDocuments = {};
                        self.VQReqDocNames.map((item, index) => {
                            self.VQDocsWNames.push(item);
                            var data = self.EditModalInfo.qualification_documents.find(i => i.req_id === item.id)
                            if(data)
                                self.VQDocsWNames[index].uploaded = Object.assign({}, data);
                        });

                        self.EditModalInfo.linked_item_templates = [];
                        self.EditModalInfo.item_templates.forEach((data, index) => {
                            self.EditModalInfo.linked_item_templates.push(data.id);
                        });
                        self.editDataLoaded = true;
                        $('#EditViewModal').modal('toggle');
                    })
                    .catch((error) => { console.error("Error fetching vendor details:", error); });
                    self.viewDataLoaded = false;
            },
            VendorRepDatatable() {
                let self = this;

                axios.get('/api/data/get_vendors_list')
                    .then((response) => {
                        this.VendorsList = response.data;
                    });

                var table = $('#VendorRepsList').DataTable({
                    fixedHeader: {
                            header: true,
                            headerOffset: 117
                        },
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_vendor_users_datatable',
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'vendors[0].name', name: 'Vendor Name', orderable: false, searchable: false},
                        { data: 'name', name: 'name' },
                        { data: 'email', name: 'email' },
                        { data: 'sbm_role', name: 'sbm_role', orderable: false, searchable: false},
                        { data: 'sim_role', name: 'sim_role', orderable: false, searchable: false},
                        { data: 'created_at', name: 'created_at', searchable: false},
                        { data: 'active', name: 'active' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: 3 },
                        { responsivePriority: 2, targets: 1 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },
                });

                self.DataTable = table;

                $('tbody', this.$refs.RepTable).on('click', '.UserDeactivate', function(){
                    var $this = $(this);
                    var UserID = $(this).data('vendoruserid');
                    Swal({
                        type: 'warning',
                        title: 'Warning!',
                        text: 'Are you sure you want to deactivate this Vendor User?',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        console.log(confirm.value);
                        if(confirm.value) {
                        axios.post('/api/vendors_management/deactivate_vendor_user', [UserID])
                        .then((response) => {
                            Swal({
                                type: response.data.messageType,
                                title: response.data.messageTitle,
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            });

                            self.DataTable.ajax.reload( null, false );
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                        });
                        }
                        else {
                            Swal('Unchanged', 'No Action Done!', 'info')
                        }
                    });

                });
            },
            SubmitVendorManagementRequest: function(){
                if(!(this.VendorManagementRequestInfo.Type in [this.VQ_Actions_Typos[0], this.VQ_Actions_Typos[1], this.VQ_Actions_Typos[8]])) this.VerifyInformation = true;
                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[4]) this.VendorManagementRequestInfo.VendorID = this.VendorRepCreationInfo.VendorId;

                if(this.VerifyInformation){

                let self = this;
                var localRequestInfo = Object.assign({}, self.VendorManagementRequestInfo);
                localRequestInfo.formData = Object.assign({}, this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[0] ? self.VendorCreationInfo : self.VendorRepCreationInfo);
                if(localRequestInfo.formData.hasOwnProperty('VendorDocuments'))
                    delete localRequestInfo.formData.VendorDocuments;
                this.$refs[this.ReferenceFormName].validate((validation) => {
                    if(validation){

                        axios.post( '/api/vendors_management/check_vendor_management_requests', localRequestInfo)
                            .then(function(response){
                                localRequestInfo = {};
                                if(response.data.messageType == 'warning'){
                                                Swal.fire({
                                                    type: response.data.messageType,
                                                    title: response.data.messageTitle,
                                                    text: response.data.message,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'OK',
                                                    timer: 15000
                                                })
                                            }
                                else{
                                    const handleCase = new Promise((resolve, reject) => {
                                        switch (self.VendorManagementRequestInfo.Type) {
                                            case 'Qualification Of A New Vendor':
                                                self.VendorManagementRequestInfo.formData.VendorInfo = Object.assign({}, self.VendorCreationInfo);
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Information Amendment':
                                                self.VendorManagementRequestInfo.formData.VendorInfo = Object.assign({}, self.EditModalInfo);
                                                self.VendorManagementRequestInfo.formData.filesToDelete = Object.assign({}, self.FilesToDelete);
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Inactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Reactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Addition Of A New Vendor Representative':
                                                    axios.post( '/api/vendors_management/check_vendor_user', self.VendorRepCreationInfo)
                                                        .then(function(response){
                                                            if(response.data.messageType == 'error'){
                                                                Swal.fire({
                                                                                type: response.data.messageType,
                                                                                title: response.data.messageTitle,
                                                                                text: response.data.message,
                                                                                showConfirmButton: true,
                                                                                confirmButtonText: 'Acknowledged',
                                                                                timer: 15000
                                                                            });
                                                                reject('User Cannnot Be Register With Another Vendor')
                                                            }
                                                            else if(response.data.messageType == 'warning'){
                                                                            Swal.fire({
                                                                                type: response.data.messageType,
                                                                                title: response.data.messageTitle,
                                                                                text: response.data.message,
                                                                                showConfirmButton: true,
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'I Agree',
                                                                                cancelButtonText: 'I Disagree',
                                                                                timer: 15000
                                                                            }).then(function(confirmed){
                                                                                if(confirmed.value) {
                                                                                    self.VendorManagementRequestInfo.formData.VendorRepInfo = Object.assign({}, self.VendorRepCreationInfo);
                                                                                    self.VendorManagementRequestInfo.VendorID = self.VendorRepCreationInfo.VendorId;
                                                                                    self.VendorManagementRequestInfo.VendorRepID = response.data.VendorUserID;
                                                                                    resolve('Confirmed');
                                                                                    }
                                                                                else {
                                                                                        Swal({
                                                                                            type: 'success',
                                                                                            title: 'No Action Done',
                                                                                            text:`You opted not to let the already resgistered used be assigned to this or any other vendor as Vendor Management User! Change email and try again.`,
                                                                                            showConfirmButton: true,
                                                                                            confirmButtonText: 'OK',
                                                                                        });
                                                                                        reject('NotConfirmed')
                                                                                        return false;
                                                                                    }
                                                                            });
                                                                        }
                                                            else{
                                                                self.VendorManagementRequestInfo.formData.VendorRepInfo = Object.assign({}, self.VendorRepCreationInfo);
                                                                self.VendorManagementRequestInfo.VendorID = self.VendorRepCreationInfo.VendorId;
                                                                self.VendorManagementRequestInfo.VendorRepID = response.data.VendorUserID;
                                                                resolve('Confirmed');
                                                            }
                                                            });
                                                break;
                                            case 'Vendor Representative Inactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Representative Reactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Representative Role Change':
                                                self.VendorManagementRequestInfo.formData.RoleChange = self.VendorManagementRequestInfo.formData.RoleChange.filter(item => item !== null);
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Expired Qualification Renewal':
                                                self.VendorManagementRequestInfo.formData.VendorInfo = Object.assign({}, self.EditModalInfo);
                                                self.VendorManagementRequestInfo.formData.filesToDelete = Object.assign({}, self.FilesToDelete);
                                                resolve('Confirmed')
                                                break;
                                            default:
                                                alert('Request Type Not Defined');
                                                reject('Type Not Specified')
                                                return false;

                                        }
                                        });
                                        if(Object.keys(self.auditLog).length) {
                                            self.VendorManagementRequestInfo.formData.auditLog = Object.assign({}, self.auditLog);
                                        }
                                        handleCase.then((result) => {
                                            axios.post( '/api/vendors_management/create_new_vm_request', self.VendorManagementRequestInfo)
                                                .then(function(response) {
                                                    if(response.data.messageType == 'success'){

                                                        Swal({
                                                                type: response.data.messageType,
                                                                title: response.data.messageTitle,
                                                                text: response.data.message,
                                                                showConfirmButton: true,
                                                                timer: 15000
                                                            }).then(result => {
                                                                self.$refs[self.ReferenceFormName].resetFields();
                                                                if(self.$refs.VQReqDocs?.length)
                                                                    self.$refs.VQReqDocs.map(item => item.clearFiles());
                                                                else
                                                                    self.$refs.VQReqDocs.clearFiles();
                                                                self.VendorManagementRequestInfo.VendorID = "";
                                                                self.VendorManagementRequestInfo.VendorRepID = "";
                                                                self.VendorManagementRequestInfo.formData = {};
                                                                self.VendorManagementRequestInfo.Reason = "";
                                                                self.VerifyInformation =  false;
                                                                self.VendorCreationInfo.VerifyIBAN.verified = false;
                                                                // self.VendorCreationInfo = {
                                                                //         VendorName: "",
                                                                //         VendorAlias: "",
                                                                //         VendorRepName: "",
                                                                //         VendorRepTitle: "",
                                                                //         VendorAddress: "",
                                                                //         VendorPOBOX: "",
                                                                //         VendorPhone: "",
                                                                //         VendorMobile: "",
                                                                //         VendorURL: "",
                                                                //         VendorLicenseNumber: "",
                                                                //         VendorLicenseExpiryDate: "",
                                                                //         VendorProvidedServices: [],
                                                                //         VendorCity: "",
                                                                //         VendorCountry: "",
                                                                //         VendorOperationCountries: [],
                                                                //         VendorOperationCities: [],
                                                                //         VendorHighestRankingOfficerName: "",
                                                                //         VendorHighestRankingOfficerPhoneNumber: "",
                                                                //         VendorBankName: "",
                                                                //         VendorBankAddress: "",
                                                                //         VendorBankIBAN: "",
                                                                //         VendorSwiftCode: "",
                                                                //         VerifyIBAN: {verified: false, tool: ""},
                                                                //         VendorTurnover: "",
                                                                //         VendorOpenSince: "",
                                                                //         VendorSoldBrands: "",
                                                                //         VendorExclusiveBrands: "",
                                                                //         SelectedItemTemplates: [],
                                                                //         VendorType: "",
                                                                //         VendorQualificationExpires: "",
                                                                //         VendorNotes: "",
                                                                //         VendorDocuments: {},
                                                                //     };
                                                                self.EditModalInfo = {};
                                                            });
                                                            }

                                                }.bind(self))
                                                .catch(function(){
                                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (CreatingNewVendorManagementRequest) CODE:001', 'error');
                                                });
                                            });
                                }
                        });

                    }
                });

            }
            },
            SubmitVendorCreationForm: function(){

                if(this.VerifyInformation){
                    this.VendorCreationInfo.VendorOperationCountries = [];
                    this.countries.some((el) => {
                                    if(this.VendorSC.includes(el.id))
                                        this.VendorCreationInfo.VendorOperationCountries.push(el.country_name);
                                    if(this.TmpVC == el.id)
                                        this.VendorCreationInfo.VendorCountry = el.country_name;
                                });


                    if (event) event.preventDefault();

                    this.$refs.VendorCreationRequestForm.validate((validation) => {

                        if(validation){


                            //Validate File Size
                            /*const isJPG = file.type === 'image/jpeg';
                            const isLt2M = file.size / 1024 / 1024 < 2;

                            if (!isJPG) {
                              this.$message.error('Avatar picture must be JPG format!');
                            }
                            if (!isLt2M) {
                              this.$message.error('Avatar picture size can not exceed 2MB!');
                            }
                            return isJPG && isLt2M;*/


                            let formData = new FormData();

                            // for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                            //     let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                            //     formData.append('FileUploads['+i+']', file);
                            // }

                            formData.append('VendorName', this.VendorCreationInfo.VendorName);
                            formData.append('VendorAlias', this.VendorCreationInfo.VendorAlias);
                            formData.append('VendorRepName', this.VendorCreationInfo.VendorRepName);
                            formData.append('VendorRepTitle', this.VendorCreationInfo.VendorRepTitle);
                            formData.append('VendorAddress', this.VendorCreationInfo.VendorAddress);
                            formData.append('VendorPOBOX', this.VendorCreationInfo.VendorPOBOX);
                            formData.append('VendorPhone', this.VendorCreationInfo.VendorPhone);
                            formData.append('VendorMobile', this.VendorCreationInfo.VendorMobile);
                            formData.append('VendorLicenseNumber', this.VendorCreationInfo.VendorLicenseNumber);
                            formData.append('VendorLicenseExpiryDate', this.VendorCreationInfo.VendorLicenseExpiryDate);
                            formData.append('VendorQualificationExpires', this.VendorCreationInfo.VendorQualificationExpires);
                            formData.append('VendorProvidedServices', this.VendorCreationInfo.VendorProvidedServices);
                            formData.append('VendorURL', this.VendorCreationInfo.VendorURL);

                            formData.append('VendorCity', this.VendorCreationInfo.VendorCity);
                            formData.append('VendorCountry', this.VendorCreationInfo.VendorCountry);
                            formData.append('VendorOperationCities', this.VendorCreationInfo.VendorOperationCities);
                            formData.append('VendorOperationCountries', this.VendorCreationInfo.VendorOperationCountries);
                            formData.append('VendorHighestRankingOfficerName', this.VendorCreationInfo.VendorHighestRankingOfficerName);
                            formData.append('VendorHighestRankingOfficerPhoneNumber', this.VendorCreationInfo.VendorHighestRankingOfficerPhoneNumber);
                            formData.append('VendorBankName', this.VendorCreationInfo.VendorBankName);
                            formData.append('VendorSwiftCode', this.VendorCreationInfo.VendorSwiftCode);
                            formData.append('VendorBankIBAN', this.VendorCreationInfo.VendorBankIBAN);
                            formData.append('VendorTurnover', this.VendorCreationInfo.VendorTurnover);
                            formData.append('VendorOpenSince', this.VendorCreationInfo.VendorOpenSince);
                            formData.append('VendorSoldBrands', this.VendorCreationInfo.VendorSoldBrands);
                            formData.append('VendorExclusiveBrands', this.VendorCreationInfo.VendorExclusiveBrands);
                            this.VendorCreationInfo.SelectedItemTemplates.forEach((data, index) => {
                                formData.append('SelectedItemTemplates[]', data);
                            });

                            formData.append('VendorType', this.VendorCreationInfo.VendorType);
                            formData.append('VendorSource', this.VendorCreationInfo.VendorSource);

                            formData.append('VendorNotes', this.VendorCreationInfo.VendorNotes);

                            axios.post( '/api/vendors_management/created_new_vendor',
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then(function(response){
                                Swal({
                                    type: response.data.messageType,
                                    title: response.data.messageTitle,
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });
                                self.$refs.VendorCreationRequestForm.reset();
                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                            });

                            this.VendorCreationInfo.VendorName = "";
                            this.VendorCreationInfo.VendorAlias = "";
                            this.VendorCreationInfo.VendorRepName = "";
                            this.VendorCreationInfo.VendorRepTitle = "";
                            this.VendorCreationInfo.VendorAddress = "";
                            this.VendorCreationInfo.VendorPOBOX = "";
                            this.VendorCreationInfo.VendorPhone = "";
                            this.VendorCreationInfo.VendorMobile = "";
                            this.VendorCreationInfo.VendorURL = "";
                            this.VendorCreationInfo.VendorLicenseNumber = "";
                            this.VendorCreationInfo.VendorLicenseExpiryDate = "";
                            this.VendorCreationInfo.VendorProvidedServices = [];
                            this.VendorCreationInfo.VendorNotes = "";
                            this.VendorCreationInfo.VendorDocuments = [];
                            this.VendorCreationInfo.VendorSource= "";
                            this.VendorCreationInfo.VendorCity = "";
                            this.VendorCreationInfo.VendorCountry = "";
                            this.VendorCreationInfo.VendorOperationCities = [];
                            this.VendorCreationInfo.VendorOperationCountries = [];
                            this.VendorCreationInfo.VendorHighestRankingOfficerName = "";
                            this.VendorCreationInfo.VendorHighestRankingOfficerPhoneNumber = "";
                            this.VendorCreationInfo.VendorBankName = "";
                            this.VendorCreationInfo.VendorBankIBAN = "";
                            this.VendorCreationInfo.VendorSwiftCode = "";
                            this.VendorCreationInfo.VendorTurnover = "";
                            this.VendorCreationInfo.VendorOpenSince = "";
                            this.VendorCreationInfo.VendorSoldBrands = "";
                            this.VendorCreationInfo.VendorExclusiveBrands = "";
                            this.VendorCreationInfo.VendorType = "";
                            this.VendorCreationInfo.SelectedItemTemplates = [];


                        }

                    });
                }

            },
            validateAndSaveForm: function(formname){

                let self = this;

                if (event) event.preventDefault();

                // if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[8])
                //     this.$refs[formname].validateField('lic_expiry_date', (validation) => {
                //         if(!validation) $('#EditViewModal').modal('toggle');
                //     });
                // else
                    this.$refs[formname].validate((validation) => {
                        if(validation) $('#EditViewModal').modal('toggle');

                    });

                },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')

            },
            adjustDropdownWidth(visible) { if (visible) { this.$nextTick(() => { const elSelect = this.$refs.elSelect.$el; const dropdown = document.querySelector('.el-select-dropdown'); if (elSelect && dropdown) { dropdown.style.minWidth = `${elSelect.clientWidth}px`; dropdown.style.width = `${elSelect.clientWidth}px`; } }); } },

        },
        created(){
            this.getCountries();
        },
        mounted(){

            let self = this;
            axios.get('/api/data/get_item_template_list')
                .then((response) => {
                    self.ItemTemplates = response.data;
                })

            axios.get('/api/data/get_company_details')
                .then((response) => {
                    self.companyDetails = response.data;
                })

            axios.get('/api/data/get_user_projects_list_slm')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                })
            this.getVQReqDocNames();
            // this.getCity();

        },
        beforeRouteLeave (to, from, next) {

                if(this.VendorManagementRequestInfo.Type){
                    Swal({
                        title: 'Navigate Away?',
                        text: "Are You Sure You want To Navigate Away?, Non-submitted Data Will Be Lost.",
                        type: 'error',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Remove It!'
                    }).then((result) => {
                        if (result.value) {
                            next()
                        } else {
                            next(false)
                        }
                    })
                } else {
                    next();
                }
            },
    }


</script>

<style scoped>

    #EditViewModal .modal-dialog {
        min-width:80%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .el-input__inner {
        height: 40px !important;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .el-select-dropdown__item{
        height: 100% !important;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        max-width: 100% !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


</style>

<style>
.swal2-container { z-index: 9000 !important;  }
    .setupSelection .el-select-dropdown { min-width: 100% !important; max-width: none !important; box-sizing: border-box; }
    .VendorSelect .el-select-dropdown__list{
        max-height: 400px;
    }
     #VQReqDocs .el-upload-list--text {
        overflow-x: hidden;
        max-width: 300px;
     }
    .chkAgree .el-checkbox__inner {
        align-self: start;
    }
    .chkAgree > .el-checkbox__label {
        display:inline-flex;
        white-space: normal !important;
    }
</style>
