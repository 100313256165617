<template>
    <div class="VSImain">
    <span class="scoreDivider" style="border-left: none">3</span>
    <div class="ScoreInput">
        <div class="input-group-prepend">
            <button :disabled="Enabled == false" type="button" @click="Range3 !== 99 ? valUpdate('Range3', ++lRange3): errorPop('Score 3', 'overstep', '99%')" class="btn btn-outline-secondary btn-plus">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <!-- <input class="form-control quantity" type="number" :disabled="record.c_1 == false" v-model.number="Range3"/> -->
        <div class="input-group-prepend innerText-Area">
            <b class="innerText" :class="{disabled: Enabled == false}">{{Range3}}%</b>
        </div>
        <div class="input-group-append">
            <button :disabled="Enabled == false" type="button" @click="Range3 !== Range2+1 ? valUpdate('Range3', --lRange3): errorPop('Score 3', 'understep', 'Score2')" class="btn btn-outline-secondary btn-minus">
                <i class="fa fa-minus"></i>
            </button>
        </div>
    </div>
    <span class="scoreDivider" style="font-size:30px">2</span>
    <div class="ScoreInput">
        <div class="input-group-prepend">
            <button :disabled="Enabled == false" type="button" @click="Range2 !== Range3-1 ? valUpdate('Range2', ++lRange2): errorPop('Score 2', 'overstep', 'Score3')" class="btn btn-outline-secondary btn-plus">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <!-- <input type="number" min="1" max="99" v-model="Range3" hidden/> -->
        <div class="input-group-prepend innerText-Area">
            <b class="innerText" :class="{disabled: Enabled == false}">{{Range2}}%</b>
        </div>
        <div class="input-group-append">
            <button :disabled="Enabled == false" type="button" @click="Range2 !== Range1+1 ? valUpdate('Range2', --lRange2): errorPop('Score 2', 'understep', 'Score1')" class="btn btn-outline-secondary btn-minus">
                <i class="fa fa-minus"></i>
            </button>
        </div>
    </div>
    <span class="scoreDivider" style="font-size:25px">1</span>
    <div class="ScoreInput">
        <div class="input-group-prepend">
            <button :disabled="Enabled == false" type="button" @click="Range1 !== Range2-1 ? valUpdate('Range1', ++lRange1): errorPop('Score 1', 'overstep', 'Score2')" class="btn btn-outline-secondary btn-plus">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <!-- <input type="number" :disabled="record.c_1 == false" v-model="Range3" hidden/> -->
        <div class="input-group-prepend innerText-Area">
            <b class="innerText" :class="{disabled: Enabled == false}">{{Range1}}%</b>
        </div>
        <div class="input-group-append">
            <button :disabled="Enabled == false" type="button" @click="Range1 !== 1 ? valUpdate('Range1', --lRange1): errorPop('Score 1', 'understep', '1%')" class="btn btn-outline-secondary btn-minus">
                <i class="fa fa-minus"></i>
            </button>
        </div>
    </div>
    <span class="scoreDivider" style="font-size:20px; border-right: none">0</span>
    </div>
</template>

<script>
import swal from 'sweetalert2';

export default {
    name: 'VendorScoreInput',
    props: ['Enabled', 'Range3', 'Range2', 'Range1'],
    data() {
        return {
            lRange3: this.Range3,
            lRange2: this.Range2,
            lRange1: this.Range1,
        }
    },
    methods: {
        errorPop(label, opr, val) {
            swal('Invalid Operaion!', 'The range for "'+label+'" cannot '+opr+' that of "'+val+'"', 'warning');
        },
        valUpdate(label, val) {
            this.$emit(label, val++);
        },
    },

}
</script>

<style>
    .ScoreInput{
        width: 35px;
        display: inline-grid;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        
    }
    .innerText {
        display: grid;
        align-content: center;
        text-align: center;
        margin: 0%;
        padding: 0%;
        font-size: 18px;
    }
    .innerText-Area {
        height: 35px;
        background-color: white;
        border-radius: 1px;
    }

    .scoreDivider {
        display: inline-grid;
        color: red;
        width: 18.5%;
        height: 110px;
        text-align: center;
        font-size: 35px;
        vertical-align: top;
        align-content: space-around;
        border-left: 1px solid black;
        border-right: 1px solid black;

    }
    .disabled {
        color: #C0C4CC;
        cursor: not-allowed;
    }
    .btn-plus{
        right: 1%;
    }
    .btn-plus:disabled {
        color: #C0C4CC;
        cursor: not-allowed;
    }
    .btn-minus{
        left: 1%;
    }
    .btn-minus:disabled {
        color: #C0C4CC;
        cursor: not-allowed;
    }
</style>