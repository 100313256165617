import { render, staticRenderFns } from "./RateContractRequestsApprovals.vue?vue&type=template&id=127042ad&scoped=true&"
import script from "./RateContractRequestsApprovals.vue?vue&type=script&lang=js&"
export * from "./RateContractRequestsApprovals.vue?vue&type=script&lang=js&"
import style0 from "./RateContractRequestsApprovals.vue?vue&type=style&index=0&lang=css&"
import style1 from "./RateContractRequestsApprovals.vue?vue&type=style&index=1&id=127042ad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127042ad",
  null
  
)

export default component.exports