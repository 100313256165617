<template>
    
    <div class="container">
        <div class="row justify-content-center">
            

            <div class="col-md-8">
                
                <div align="center" class="row m-b-20 m-t-20">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                        <img width="80%" :src="OgeoLogo" alt="Ogeo Logo">
                    </div>
                    <div class="col-lg-4"></div>
                </div>

                <div style="font-size: 18px; color: #636b6f; font-family: 'Raleway', sans-serif; font-weight: 500;" class="title m-b-md">
                    <h2 style="color:rgb(252, 51, 23);text-align:center">Thank you for registering with Ogéo.</h2><h2 style="color:rgb(252, 51, 23);text-align:center">We are thrilled to be able to serve you!!</h2>
                    <p>It’s important to note that the information you are about to enter during the registration process can be revisited at will, and anytime. This includes adding an unlimited number of <b style="color:rgb(252, 51, 23)">Accounts*</b> to your organization.</p>
                    <p><b style="color:rgb(252, 51, 23)">The registration process shouldn’t take you more than <u>15 minutes</u></b> if you readily have at your disposal the following information:</p>
                </div>

                <div style="font-size: 18px; color: #636b6f; font-family: 'Raleway', sans-serif; font-weight: 500;" class="title m-b-md">
                    <ol type="A">
                        <li>The users details (names, designations & emails) of at least one person from a group of users holding one of the <b style="color:rgb(252, 51, 23)">Roles**</b> on Ogéo.</li>
                        <li>The <b style="color:rgb(252, 51, 23)">Delegations of Authorities (DoA)</b> with, when becessary, their applicable financial limits, which apply to at least one Account.</li>
                        <li>The budget of at least one Account.</li>
                    </ol>
                    <!-- <p><b>It’s important to note that the information captured during the registration process can be revisited and updated at will. That include adding new Setups.</b></p> -->
                    <br>
                    <center><h1 style="text-align: center !important; color: #FF2F2F; font-weight: bolder">No Credit Card Needed</h1></center>
                    <br>
                </div>
                  
                
                <div style="" class="card">
                    <div style="text-align: center !important; color: #FF2F2F; font-weight: bolder" class="card-header"><b>Registration Form</b></div>

                    <div class="card-body">
                        <form autocomplete="off" @submit.prevent="register" method="post">

                            <div class="form-group row">
                                <label for="name" class="col-md-4 col-form-label text-md-right">Full Name</label>

                                <div class="col-md-6">
                                    <input id="name" type="text" class="form-control" v-model="name" required autofocus>
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="designation" class="col-md-4 col-form-label text-md-right">Designation</label>

                                <div class="col-md-6">
                                    <input id="designation" type="text" class="form-control" v-model="designation" required>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>

                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" v-model="email" required>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" v-model="password" required>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Confirm Password</label>

                                <div class="col-md-6">
                                    <input id="password-confirm" type="password" class="form-control" v-model="password_confirmation" required>
                                </div>
                            </div>
                          
                            <div class="form-group row">
                                <div v-if="registerationError" class="col-md-6 offset-md-4 text-danger animated rubberBand text-center">
                                    <strong>{{ message }}</strong>
                                </div>
                                <div v-if="registerationInProgress" class="col-md-6 offset-md-4 text-primary animated fadeIn text-center">
                                    <strong>Registering...</strong>
                                </div>
                                <div v-if="registerationSuccess" class="col-md-6 offset-md-4 text-success animated fadeIn text-center">
                                    <strong>{{ message }}</strong>
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-6 offset-md-4">
                                    <button type="submit" class="btn btn-block btn-ogeo waves-effect waves-light">
                                        Register
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div style="font-size: 18px; color: #636b6f; font-family: 'Raleway', sans-serif; font-weight: 500;" class="title m-b-md">
                    <hr>
                    <ol>
                        <p><b style="color:rgb(252, 51, 23)">*</b>Accounts can be subsidiaries, affiliates, projects, storerooms, divisons of the organization, points of sale, or ... the whole of the organization.</p>
                        <p><b style="color:rgb(252, 51, 23)">**</b>Below are descriptions of the roles:</p>
                        <li style="color:rgb(252, 51, 23)"> <i>Application Administrator</i> Roles:
                            <h6 style="display: inline">(See the FAQ section for more information.)</h6>
                        </li>

                            <ol type="a">
                                <li><b>Creating Accounts, assigning & widthrawing roles to/from users, and <br>
                                    amending, post go-live, some of the general information.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Purchase Enquiry</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Initiating Purchase Enquiries for <i>Materials</i> & <i>Services</i>.</b></li>
                                <li><b>Approving/rejecting Purchase Enquiries.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Request for Proposals</i> Role: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Creating & floating Requests for Proposals, under the forms of <i>Sealed Bids</i><br> & <i>Live Auctions</i>, and on the back of approved Purchase Enquiries.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Purchase Order</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Initiating Purchase Orders for <i>Materials</i> & <i>Services</i>.</b></li>
                                <li><b>Approving/rejecting Purchase Orders.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Receipt Note</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Initiating Receipt Notes.</b></li>
                                <li><b>Approving/rejecting Receipt Notes.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Vendors Qualification & Enrolment</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Collecting Vendor Qualification Documents & Initiating Vendor Qualification Requests.</b></li>
                                <li><b>Approving/rejecting Vendor Qualification Requests.</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Vendors Invoice</i> Role: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                            <ol type="a">
                                <li><b>Checking Vendors Invoices (uploaded by the vendors on Ogéo)</b></li>
                            </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Libraries of Materials</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                        <ol type="a">
                            <li><b>Initiating Requests to Update the Library of Materials.</b></li>
                            <li><b>Reviewing/Correcting the Requests to Update the Library of Materials.</b></li>
                            <li><b>Approving/Rejecting the Requests to Update the Library of Materials.</b></li>
                        </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Rates' Contracts</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></b></li>
                        <ol type="a">
                            <li><b>Initiating Requests to Source Offers for Rates' Contracts for <i>Materials</i> & <i>Services</i></b></li>
                            <li><b>Approving/Rejecting the Requests to Source Offers for Rates' Contracts.</b></li>
                            <li><b>Initiating Requests to actually establish of Rates' Contracts.</b></li>
                            <li><b>Approving/Rejecting Requests to actually establish of Rates' Contracts.</b></li>
                        </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Budget</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                        <ol type="a">
                            <li><b>Initiating Requests to Update Accounts Budgets.</b></li>
                            <li><b>Approving/Rejecting Requests to Update Accounts Budget.</b></li>
                        </ol>
                        <li style="color:rgb(252, 51, 23)"><i>Storerooms</i> Roles: <h6 style="display: inline">(See the FAQ section for more information.)</h6></li>
                        <ol type="a">
                            <li><b>Creating Storerooms & Adding Storerooms’ Items to the Storerooms</b></li>
                            <li><b>Originating Requests to create Storerooms’ Items</b></li>
                            <li><b>Approving Requests to create Storerooms’ Items</b></li>
                            <li><b>Creating Storerooms’ Items Issue Notes</b></li>
                            <li><b>Approving Storerooms’ Items Issue Notes</b></li>
                            <li><b>Creating Storerooms’ Items Return Notes</b></li>
                            <li><b>Approving Storerooms’ Items Return Notes</b></li>
                        </ol>
                        
                    </ol>
                </div>

                

            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'registeration',
    data(){
      return {
        OgeoLogo: '/assets/images/logo_transparent.png',
        name: null,
        designation: null,
        email: null,
        password: null,
        ref_id:this.$route.query.ref_id?this.$route.query.ref_id:0,
        password_confirmation: null,
        registerationInProgress: false,
        registerationSuccess: false,
        registerationError: false,
        message: "",
      }
    },
    methods: {
      register: function () {
            this.registerationInProgress = true
            this.registerationSuccess = false;
            this.registerationError = false;
            const self = this;
            const { name, designation, email, password, password_confirmation  } = this;
            let ref_id=this.$data.ref_id;
            console.log(ref_id);
            this.$store.dispatch('POST_AUTH_REQUEST_REGISTER', { name, designation, email, password, password_confirmation,ref_id }).then((res) => {
                self.registerationInProgress = false;
                self.registerationSuccess = true;
                if(res.data.status == "incomplete"){
                    Swal({
						title: 'Already Registered!',
						text: "Do you want to complete your incomplete registration?",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes',
                        cancelButtonText : 'No'
					  }).then((result) => {
						  if (result.value) {
                            this.$store.dispatch('POST_AUTH_REQUEST_EMAIL', { email, password }).then(() => {
                                self.registerationInProgress = false;
                                self.registerationSuccess = true;
                                    document.cookie = "LoginImage=/uploads/ProfileImages/"+this.$store.state.currentUser.LoginImage+"; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                                    
                                if(this.$store.state.currentUser.onboarding == 1){
                                    this.$router.push('/app/account_settings')
                                } else {
                                    this.$router.push('/app/profile')
                                }
                            }).catch((err) => {
                                self.message = "Invalid Credentials. Account Already Exist";   
                                self.registerationInProgress = false;
                                self.error = true;
                            })
						  }else{
							axios({url: '/api/delete_user', data: { email, password }, method: 'POST' })
							.then(resp => {
								localStorage.removeItem('user');
                                delete axios.defaults.headers.common['Authorization'];
                                delete $.ajaxSettings.headers["Authorization"];
                                window.location.href = '/registration';

                            });
						  }
					  });
                }else{
                    this.$router.push('/app/account_settings');
                }
            }).catch((error) => {
                self.registerationInProgress = false;
                self.registerationError = true;
                if(error.response.data.error){   
                    self.message = error.response.data.error;
                }
                if(error.response.data.errors){ 
                    self.message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                   
                }
               
                //console.log(err.response.data.message);
            })
        },
        geturlparam:function(){
             console.log(this.$data.ref_id);
        },
    }
    ,
    created: function(){
        this.geturlparam();
    }
  } 
</script>