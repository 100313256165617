<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div v-if="showList == false">
                <span slot="label"><b>Select A Organization</b></span>
                <el-select filterable style="width: 100%; padding: 0px;" @change="getPurchaseOrder()" v-model="record.company_id" placeholder="Select Organization">
                    <el-option v-for="(item, index) in companies" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <div class="card-body">
                    <table ref="table" id="ProjectPOList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>PO #</th>
                                <th>PO Generator</th>
                                <th>Setup Name</th>
                                <th>Approval Criteria</th>
                                <th>Total PO Value</th>
                                <th>PE Line IDs</th>
                                <th>Awardee Vendor</th>
                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Status</th>
                                <th>Action</th> 
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
        <div id="PurchaseOrderUpdateModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="PurchaseOrderUpdateModalView" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content"  v-if="viewDataLoaded">
                    <div  class="modal-header">
                        <h4 class="modal-title text-ogeo">Purchase Order ID# <span v-if="ViewModalInfo.company.po_prefix==''">PO-{{ ViewModalInfo.purchase_order_group_id }}</span><span else>{{ ViewModalInfo.company.po_prefix }}-{{ ViewModalInfo.purchase_order_group_id }}</span></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr>
                                        <td style="width: 50%"><b>Purchase Order ID#</b></td>
                                        <td><span v-if="ViewModalInfo.company.po_prefix==''">PO-{{ ViewModalInfo.purchase_order_group_id }}</span><span else>{{ ViewModalInfo.company.po_prefix }}-{{ ViewModalInfo.purchase_order_group_id }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><b>Commercial Evalutation ID#</b></td>
                                        <td><span v-if="ViewModalInfo.company.ce_prefix==''">CE-{{ ViewModalInfo.commercial_evaluation.commerical_evaluation_group_id }}</span><span else>{{ ViewModalInfo.company.ce_prefix }}-{{ ViewModalInfo.commercial_evaluation.commerical_evaluation_group_id }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><b>RfP ID#</b></td>
                                        <td><span v-if="ViewModalInfo.company.rfq_prefix==''">RfP-{{ ViewModalInfo.quotation.quotation_group_id }}</span><span else>{{ ViewModalInfo.company.rfq_prefix }}-{{ ViewModalInfo.quotation.quotation_group_id }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><b>RfP Name</b></td>
                                        <td>{{ ViewModalInfo.quotation.rfp_name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Originated By</b></td>
                                        <td>{{ ViewModalInfo.creator.name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Originated At</b></td>
                                        <td>{{ ViewModalInfo.created_at }}</td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr>
                                        <td style="width: 40%"><b>Awardee Vendor:</b></td>
                                        <td>{{ ViewModalInfo.vendor.name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Consulted Vendors </b></td>
                                        <td>
                                            <ol>
                                                <li v-for="vendor in ViewModalInfo.quotation.vendors" :key="vendor.id">{{ vendor.name }}-ID#{{ vendor.id }}</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 40%"><b>Total PO Value:</b></td>
                                        <td class="text-ogeo"><b>{{ formatPrice(ViewModalInfo.vendor_responses.reduce((acc, responses) => acc + responses.price, 0)) }} {{ ViewModalInfo.vendor_responses[0].currency }}</b></td>
                                    </tr>
                                    
                                </table>
                            </div>
                        </div>


                        <hr>
                        <h3 class="text-ogeo">Purchase Enquiry lines</h3>

                        <div class="col-lg-12">
                            <div class="table-wrapper">
                                <table ref="ViewPOPELines" id="ViewPOPELines" class="responsive table table-bordered cell-border" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>PE ID# (Status)</th>
                                            <th style="min-width: 600px">Requested Materials</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Quantity</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Delivery Location Details</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Underlying Transaction</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Retention Percentage</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Vendor Response</th>
                                            <th v-if="ViewModalInfo.purchase_enquiries[0].item_id">Communication</th>
                                            <th>Approval Notes</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr :key="PEResponse.id" v-for="PEResponse in ViewModalInfo.vendor_responses">

                                            <td>
                                                <span v-if="ViewModalInfo.company.pe_prefix == ''">PE-</span><span v-else>{{ ViewModalInfo.company.pe_prefix }}-</span>{{ PEResponse.purchase_enquiry.purchase_enquiry_group_id }}-{{ PEResponse.purchase_enquiry.purchase_enquiry_ingroup_id }} <br><br>
                                                (<b>{{ PEResponse.purchase_enquiry.purchase_orders[0].pivot.status }}</b>)
                                            </td>

                                            <td v-if="PEResponse.purchase_enquiry.item.item_template">
                                                <span>
                                                    <b><span><span v-if="ViewModalInfo.company.lom_prefix == ''">LoM</span><span v-else>{{ ViewModalInfo.company.lom_prefix }}</span>-{{ PEResponse.purchase_enquiry.item.item_group_id }}-{{ PEResponse.purchase_enquiry.item.item_ingroup_id }}</span></b><br>
                                                    <template v-for="index in 20">    
                                                        <span :key="index" v-if="PEResponse.purchase_enquiry.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b>{{ PEResponse.purchase_enquiry.item.item_template['field_'+index] }}:</b> {{ PEResponse.purchase_enquiry.item['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br>
                                            </td>
                                            <td v-else style="min-width: 200px">{{ PEResponse.purchase_enquiry.item_description }}</td>

                                            <td v-if="PEResponse.purchase_enquiry.item_id">
                                            <b>{{ PEResponse.purchase_enquiry.quantity }}</b> - <span v-if="PEResponse.purchase_enquiry.item.item_template">{{ PEResponse.purchase_enquiry.item.u_o_m }}</span><span v-else>{{ PEResponse.purchase_enquiry.u_o_m }}</span>
                                                
                                            </td>     
                                            <td v-if="PEResponse.purchase_enquiry.item_id">
                                                <b>Location Name:</b><br>{{ PEResponse.purchase_enquiry.location_name }}<br>
                                                <b>Latitude:</b><br>{{ PEResponse.purchase_enquiry.latitude }}<br>
                                                <b>Longitude:</b><br>{{ PEResponse.purchase_enquiry.longitude }}
                                            </td>
                                            <!-- <td v-if="PEResponse.purchase_enquiry.item_id">{{ PEResponse.purchase_enquiry.advanced_payment }}</td> -->
                                            <td v-if="PEResponse.purchase_enquiry.item_id">{{ PEResponse.purchase_enquiry.underlying_transaction }}</td>
                                            <td v-if="PEResponse.purchase_enquiry.item_id">{{ PEResponse.purchase_enquiry.retention_percentage }}% - {{ PEResponse.purchase_enquiry.retention_days }} Day(s)</td>
                                            
                        
                                            <td class="text-ogeo" style="min-width: 150px">
                                                <span><b>Unit Rate:</b> {{ PEResponse.unit_rate }} {{ PEResponse.currency }}</span><br>
                                                <span><b>Total Price:</b> {{ formatPrice( PEResponse.unit_rate * PEResponse.purchase_enquiry.quantity) }} {{ PEResponse.currency }}</span><br>
                                                <span><b>Lead Days:</b> {{ PEResponse.lead_days }} Days</span><br>
                                                <span><b>Advanced Payment:</b> {{ PEResponse.advanced_percentage }}%</span><br>
                                                <span v-if="PEResponse.notes">Vednor Notes: 
                                                    <span>{{ PEResponse.notes }}</span>
                                                </span>
                                            </td>


                                            <td style="min-width: 240px;">
                                                <!-- RFI Review -->
                                                <template v-if="PEResponse.purchase_enquiry.rfis.length">
                                                    <button type="button" @click="showRFIModal(PEResponse.purchase_enquiry.id, PEResponse.purchase_enquiry.rfis[0].vendor_id)" class="btn btn-info">View RFIs <div v-if="PEResponse.purchase_enquiry.rfis.length > 0" class="numberCircle pull-right"><span>{{ PEResponse.purchase_enquiry.rfis.length }}</span></div></button>
                                                </template>
                                                <template v-else>
                                                    <button type="button" class="btn btn-info disabled">No RFI</button>
                                                </template>

                                                <!-- Uploaded Files Review -->
                                                <template v-if="PEResponse.purchase_enquiry.uploaded_docs.length">
                                                    <button class="btn btn-info required-docs-placeholder">View Files <div v-if="PEResponse.purchase_enquiry.uploaded_docs.length > 0" class="numberCircle pull-right"><span>{{ PEResponse.purchase_enquiry.uploaded_docs.length }}</span></div></button>
                                                </template>
                                                <template v-else>
                                                    <button type="button" class="btn btn-info disabled">No Files</button>
                                                </template>
                                            </td>


                                            <td class="alignVertically" align="center">
                                                <p v-if="PEResponse.purchase_enquiry.purchase_orders[0].pivot.approval_notes">{{ PEResponse.purchase_enquiry.purchase_orders[0].pivot.approval_notes }}</p>
                                                <p v-else>No Notes</p>
                                            </td>
                                            
                                        </tr>
                                        
                                    
                                    </tbody>
                                        
                                </table>
                            </div>
                        </div>

                        <!-- <div class="col-lg-12">
                            <hr>
                            <span><b>Date:</b> {{ new Date() }}</span><br>
                            <span><b>By:</b> {{ currentUser.Name }}</span><br><br>
                        </div> -->
                 
                    </div>
                </div>
            </div>
        </div>
       

    </div>
            
</template>

<script>
    export default {
        name: 'purchase-enquiry-list',
        data(){
            return{
                DataTable: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                cancelDataLoaded: false,
                RFIDataLoaded: false,
                POLineRFIDataLoaded: false,
                RequiredDocsDataLoaded: false,
                showList: false,
                companies: [],
                record: {
                    company_id: '',
                },
                EditModalInfo: {
                    purchase_order: '',
                },
                CancellationInfo: {
                    ReceiptNoteId: "",
                    CancelledQty: "",
                    CancellationReason: "",
                    CancellationNotes: "",
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {
                    purchase_order: '',
                };
                axios.post('/api/data/get_po_details', {POId: recordId})
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        this.viewDataLoaded = true;
                        $('#PurchaseOrderUpdateModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            getPurchaseOrder(){
                var groupColumn = 1;
                const DataTable = $('#ProjectPOList').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_po_list_uncovered/'+this.record.company_id,
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'creator.name', name: 'creator.name' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'project.po_doa_criterion', name: 'project.po_doa_criterion' },
                        { data: 'total_po_value', name: 'total_po_value' },
                        { data: 'pe_lines', name: 'pe_lines' },
                        { data: 'vendor.name', name: 'vendor.name' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'status', name: 'status' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: -1 },
                        { responsivePriority: 2, targets: 1 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 1, 'ASC' ]],
                    "drawCallback": function ( settings ) {
                        var api = this.api();
                        var rows = api.rows( {page:'current'} ).nodes();
                        var last=null;  
                    },
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'PEApprovals' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    }, 
                    "rowCallback": function( row, data, index ) {
                        // console.log(data.status);
                        if(data.status.indexOf('Created and Assigned to') !== -1){
                            $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                        } else if (data.status.indexOf('Resubmitted and Assigned to') !== -1) {
                            $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                        } else if (data.status.indexOf('sent for sourcing') !== -1) {
                            $(row).css('background-color', 'rgb(69, 249, 132, 0.1)')
                        } else if (data.status.indexOf('Rejected by ') !== -1) {
                            $(row).css('background-color', 'rgb(249, 69, 72, 0.08)')
                        } else {
                            $(row).css('background-color', 'rgb(249, 69, 237, 0.1)')
                        }
                    } 
                });

                const self = this;

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.cancel-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.cancelEditModal(elquentClass, RecordID);
                });
                
                
                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    self.showEditModal(RecordID);
                });
            }
        },
        mounted(){
            axios.get('/api/data/get_cost_companies_list')
            .then((response) => {
                this.companies = response.data
            });
         
           
        },
    }

</script>


<style scoped>

    .font-500{
        font-weight: 500;
    }

    #PurchaseOrderUpdateModalView .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderCancellationModal .modal-dialog {
        min-width:80%;
    }

    #PurchaseOrderValidationModalEdit .modal-dialog {
        min-width:90%;
    }

    #RFIModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #RequiredDocsModal .modal-dialog {
        top: 3%;
        min-width:85%;
    }

    #POLineRFIDetailsModal .modal-dialog {
        top: 3%;
        min-width:75%;
    }

    .poline-approved {
        background-color: rgb(69, 249, 132, 0.1);
    }

    .poline-rejected {
        background-color: rgb(249, 69, 72, 0.08);
    }

    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }

</style>