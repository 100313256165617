<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>List of <i>Requests to Update the Library of Materials</i></h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="NewItemCreationRequests" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th><i>Request</i> ID#</th>
                                <th><i>Account</i> Name</th>
                                <th>Requester Name</th>
                                <th>Description</th>
                                <th>URL</th>
                                <th>Requested On</th>
                                <th>Last Updated On</th>
                                <th>Request Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <!-- <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot> -->
                    </table>
                    
                </div>
            </div>
        </div>


        <div id="ItemCreationRequestModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ItemCreationRequestModalView" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <div style="width:50%">
                            <h4 class="modal-title text-ogeo" style="margin-top:10px"><i>Request to Update the Library of Materials</i> ID#:<i> {{ ViewModalInfo.item_request_group_id }}</i> </h4>
                        </div>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanyDetails.logo" style="width:40px;height:40px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <!--<h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">General Information</h3>-->
                    
                                <table class="table sptable" style="float: right; border-collapse: collapse;">
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 400px;"><b class="fw-500"></b></td>                                      
                                        <td style="border: 1px solid #ffffff; min-width: 400px; text-align: right; padding-right: 20px;"><b class="fw-500"><i>Account</i> <br>Information: </b></td>    
                                        <td style="border: 1px solid #ffffff; width: 95%; colspan=2">
                                                <span><b class="font-700"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                        </td>     
                                    </tr>
                                </table>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded" style="margin-top: 0;">
                        
                        <div class="row">
                        <div class="col-lg-12">
                            <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Request Details</h3>
                            <table class="table">
                                <!--<tr>
                                    <td><b class="fw-500"><i>Account</i> Name: </b></td>
                                    <td>{{ ViewModalInfo.project.title }}</td>
                                </tr>-->
                                <tr>
                                    <td><b class="fw-500">Requester Name: </b></td>
                                    <td>{{ ViewModalInfo.requester.name }}</td>
                                </tr>
                                <tr>
                                    <td><b class="fw-500">Requested On: </b></td>
                                    <td>{{ ViewModalInfo.created_at }}</td>
                                </tr>
                                <tr>
                                    <td><b class="fw-500">Last Updated On: </b></td>
                                    <td>{{ ViewModalInfo.updated_at }}</td>
                                </tr>
                                <tr>
                                    <td><b class="fw-500">Request Type: </b></td>
                                    <td v-if="ViewModalInfo.item_status_for == 'add_item'"><b class="fw-500">Addition to the Library of Materials</b></td>
                                    <td v-if="ViewModalInfo.item_status_for == 'inactivate_item'"><b class="fw-500">Library of Materials Item Inactivation</b></td>
                                    <td v-if="ViewModalInfo.item_status_for == 'reactivate_item'"><b class="fw-500">Library of Materials Item Reactivation</b></td>
                                </tr>
                                <tr class="text-ogeo">
                                    <td><b class="fw-500">Status: </b></td>
                                    <td><b class="fw-500">{{ ViewModalInfo.status }}</b></td>
                                </tr>
                                <tr>
                                    <td><b class="font-500">Current Approval Level: </b></td>
                                    <td v-if="ViewModalInfo.approval_level == 0">Not applicable</td>
                                    <td v-else>{{ ViewModalInfo.approval_level }} out of {{ViewModalInfo.countRole}}</td>
                                </tr>
                                <tr v-if="ViewModalInfo.item_template_l_o_m != null">
                                    <td><b class="fw-500"><i>Library of Materials</i> Item Description:</b></td>
                                    <td>
                                        <span class="fw-500">LoM-{{ ViewModalInfo.item_template_l_o_m.item_group_id }}-{{ ViewModalInfo.item_template_l_o_m.item_ingroup_id }}</span><br>
                                        <!-- <span v-if="ViewModalInfo.item_description != null">{{ViewModalInfo.item_description}}</span><br> -->

                                        <span v-if="ViewModalInfo.item_template_l_o_m.item_template != null"> 
                                            <template v-for="index in 20" style="text-transform: uppercase">    
                                                <span v-if="ViewModalInfo.item_template_l_o_m.item_template['field_'+index] !== 'Ogeo-None' && ViewModalInfo.item_template_l_o_m['field_'+index]">
                                                    <b class="fw-500 text-uppercase">{{ ViewModalInfo.item_template_l_o_m.item_template['field_'+index] }}:</b> {{ ViewModalInfo.item_template_l_o_m['field_'+index] }} | 
                                                </span>
                                            </template>
                                        </span><br><br>
                                        <span><b class="fw-500">UNIT OF MEASURE:</b> {{ ViewModalInfo.item_template_l_o_m.u_o_m.toUpperCase() }}</span><br>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td><b class="fw-500">Request's Item Description: </b></td>
                                    <td>{{ ViewModalInfo.item_description }}</td>
                                </tr>
                                <tr v-if="ViewModalInfo.item_link !== null && ViewModalInfo.item_link !== 'null' && ViewModalInfo.item_link !== ''">
                                    <td><b class="fw-500">Item's Link: </b></td>
                                    <td><a target="_blank" :href="ViewModalInfo.item_link">{{ ViewModalInfo.item_link }}</a></td>
                                </tr>

                                <tr v-if="ViewModalInfo.file1">
                                    <td><b class="fw-500">Available Files: </b></td>
                                    <td>
                                        <a v-if="ViewModalInfo.file1" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+ViewModalInfo.file1" download> File 1</a>
                                        <a v-if="ViewModalInfo.file2" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+ViewModalInfo.file2" download>File 2</a>
                                        <a v-if="ViewModalInfo.file3" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+ViewModalInfo.file3" download>File 3</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-6">
                                <table class="table">
                                    <tr v-if="ViewModalInfo.item_template_l_o_m != null">
                                        <td>  
                                            <img v-if="ViewModalInfo.item_template_l_o_m.picture" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.item_template_l_o_m.picture" style="width:15vw;margin-left:20px;border-radius:5px">
                                        </td>
                                    </tr>
                                </table>
                        </div>
                    
                        <div class="col-lg-12" v-if="ViewModalInfo.item && ViewModalInfo.item_status_for == 'add_item' && ViewModalInfo.status.indexOf('Assigned to Validator') !== -1">
                            <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">
                                Recommended Listing <span style="font-style: italic; font-size: 16px;">(How will the item be described in the Library of Materials)</span>
                            </h3>

                            <!--<h4 style="color: #FF2F2F">Suggested Listing Details <i style="font-size: 14px">(How it'll be added to the Library of Materials)</i></h4>-->
                            <table class="table">
                                <tr>
                                    <td><b class="fw-500">Recommended <i>Category</i>: </b></td>
                                    <td>{{ ViewModalInfo.item.item_template.template_name.toUpperCase()}}</td>
                                </tr>
                                <tr>
                                    <td><b class="font-700 text-ogeo w-100">Recommended Item Description: </b>
                                    <td>
                                        <template v-for="index in 20">    
                                            <span v-if="ViewModalInfo.item.item_template['field_'+(index)] !== 'Ogeo-None'">
                                                <b>{{ ViewModalInfo.item.item_template['field_'+(index)] }}:</b> {{ ViewModalInfo.item['field_'+(index)] }} | 
                                            </span>
                                        </template>
                                        <br><br>
                                        <b>UNIT OF MEASURE:</b> {{ViewModalInfo.item.u_o_m.toUpperCase()}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b class="fw-500">Recommended On: </b></td>
                                    <td>{{ ViewModalInfo.item.created_at }}</td>
                                </tr>
                            </table>
                            <hr>

                            <div align="center" v-if="ViewModalInfo.item" class="d-inline-flex justify-content-center ">
                                <div class="mr-4" v-for="(img, index) in JSON.parse(ViewModalInfo.item.picture)" :key="index" >
                                    <img style="width: 10vw;height: 10vw; text-align: center;"  :src="'/uploads/ItemMasterPictures/'+img">
                                </div>
                            </div>
                        </div>
                        
                        <!-- <div class="col-lg-12" v-if="ViewModalInfo.item == null && ViewModalInfo.item_template_l_o_m && ViewModalInfo.item_status_for == 'add_item'">
                            <hr> 
                            <h4 style="color: #FF2F2F">Suggested Listing Details <i style="font-size: 14px">(How it'll be added to the Library of Materials)</i></h4>
                            <table class="table">
                                <tr>
                                    <td><b>Suggested Item Description: </b></td>
                                    <td>
                                        <template v-for="index in 20">    
                                            <span v-if="ViewModalInfo.item_template_l_o_m.item_template['field_'+index] !== 'Ogeo-None'">
                                                <b>{{ ViewModalInfo.item_template_l_o_m.item_template['field_'+index] }}:</b> {{ ViewModalInfo.item_template_l_o_m['field_'+index] }} | 
                                            </span>
                                        </template>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Suggested At: </b></td>
                                    <td>{{ ViewModalInfo.item_template_l_o_m.created_at }}</td>
                                </tr>
                            </table>
                        </div> -->

                        <div class="col-lg-12" id="histories">
                            <br>
                            <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3> 
                            <table class="display table table-bordered">
                                <thead id="historieshead">
                                    <tr>
                                        <th style="border: 1px solid #dddddd;"><b>#</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>Description</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>By</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action Level</b></th>
                                         <th style="border: 1px solid #dddddd;" ><b>Decision - <i>Notes</i></b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>Date</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action <br>Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next <br>Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action Validation Level</b></th>
                                    </tr>
                                </thead>
                                <tbody id="historiesbody">
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td style="border: 1px solid #dddddd;">{{ key + 1 }}</td>
                                        <td style="border: 1px solid #dddddd;">{{ item.current_action_description }}</td>
                                        <td style="border: 1px solid #dddddd;">{{ item.current_action_by.name }}</td>
                                        <td style="border: 1px solid #dddddd;">{{ item.current_approval_level }}</td>
                                        <td style="border: 1px solid #dddddd;">{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                        <td style="border: 1px solid #dddddd;">{{ item.created_at }}</td>
                                        <td style="border: 1px solid #dddddd; font-size: 13px" v-if="key == 0" class="non-print"><i>Not Applicable</i></td>
                                        <!-- <td v-else>{{ item.created_at }}</td> -->
                                        <td style="border: 1px solid #dddddd;" v-else class="non-print">{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_description }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_owners }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_approval_level }}</td>
                                        
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                            
                        </div>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block pull-left">            
                                <span><b>Document generated by:</b> {{ currentUser.Name }}</span>
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                            <br>
                            <br>
                            <br>
                            <div class="col-lg-24; text-center">
                                <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                                <br>
                                <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                <div class="d-flex justify-content-center mt-3">
                                    <button class="btn btn-success" onclick="document.title = 'LoM Item';window.print()" style="margin-bottom: 20px;">Download PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div id="ItemCreationRequestModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ItemCreationRequestModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <h4 class="modal-title text-ogeo"><i>Request to Update the Library of Materials</i> ID#: <i>{{ EditModalInfo.item_request_group_id }} </i></h4>
                        <div style="width:50%">
                            <img :src="'/uploads/Logos/'+this.CompanyDetails.logo" style="width:40px;height:40px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <!--<h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">General Information</h3>-->
                                <table class="table sptable" style="float: right; border-collapse: collapse;">
                                    <tr>
                                        <td style="border: 1px solid #ffffff; min-width: 400px;"><b class="fw-500"></b></td>                                      
                                        <td style="border: 1px solid #ffffff; min-width: 400px; text-align: right; padding-right: 20px;"><b class="fw-500"><i>Account</i> <br>Information: </b></td>    
                                        <td style="border: 1px solid #ffffff; width: 95%; colspan=2">
                                                <span><b class="font-700"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                        </td>     
                                    </tr>
                                </table>
                    
                    </div>
                    <div class="modal-body"  v-if="editDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Request Details</h3>
                                <table class="table">
                                    <!--<tr>
                                        <td><b class="fw-500"><i>Account</i> Name: </b></td>
                                        <td>{{ EditModalInfo.project.title }}</td>
                                    </tr>-->
                                    <tr>
                                        <td><b class="fw-500">Requester Name: </b></td>
                                        <td>{{ EditModalInfo.requester.name }}</td>
                                    </tr>
                                    <tr>
                                    <td><b class="fw-500">Requested At: </b></td>
                                    <td>{{ EditModalInfo.created_at }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="fw-500">Last Updated At: </b></td>
                                        <td>{{ EditModalInfo.updated_at }}</td>
                                    </tr>
                                    <tr>
                                    <td><b class="fw-500">Request Type: </b></td>
                                        <td v-if="EditModalInfo.item_status_for == 'add_item'"><b class="fw-500">Addition to the Library of Materials</b></td>
                                        <td v-if="EditModalInfo.item_status_for == 'inactivate_item'"><b class="fw-500">Library of Materials Item Inactivation</b></td>
                                        <td v-if="EditModalInfo.item_status_for == 'reactivate_item'"><b class="fw-500">Library of Materials Item Reactivation</b></td>
                                    </tr>
                                    <tr class="text-ogeo">
                                        <td><b class="fw-500">Status: </b></td>
                                        <td><b class="fw-500">{{ EditModalInfo.status }}</b></td>
                                    </tr>
                                    <tr>
                                        <td><b class="fw-500">Current Approval Level: </b></td>
                                        <td v-if="EditModalInfo.approval_level == 0">Not Applicable</td>
                                        <td v-else>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.countRole }}</td>
                                    </tr>
                                    <tr v-if="EditModalInfo.item_template_l_o_m != null">
                                        <td><b class="fw-500">Library of Materials Item Description:</b></td>
                                        <td>
                                            <span class="fw-500">LoM-{{ EditModalInfo.item_template_l_o_m.item_group_id }}-{{ EditModalInfo.item_template_l_o_m.item_ingroup_id }}</span><br>
                                            <!-- <span v-if="EditModalInfo.item_description != null">{{EditModalInfo.item_description}}</span><br> -->

                                            <span v-if="EditModalInfo.item_template_l_o_m.item_template != null"> 
                                                <template v-for="index in 20" style="text-transform: uppercase">    
                                                    <span v-if="EditModalInfo.item_template_l_o_m.item_template['field_'+index] !== 'Ogeo-None' && EditModalInfo.item_template_l_o_m['field_'+index]">
                                                        <b class="fw-500 text-uppercase">{{ EditModalInfo.item_template_l_o_m.item_template['field_'+index] }}:</b> {{ EditModalInfo.item_template_l_o_m['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <span><b class="fw-500">UNIT OF MEASURE:</b> {{ EditModalInfo.item_template_l_o_m.u_o_m.toUpperCase() }}</span><br>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td><b class="fw-500">Request's Item Description: </b></td>
                                        <td>{{ EditModalInfo.item_description }}</td>
                                    </tr>

                                    <tr v-if="EditModalInfo.item_link !== null && EditModalInfo.item_link !== 'null' && EditModalInfo.item_link !== ''">
                                        <td><b class="fw-500">Item's Link: </b></td>
                                        <td><a target="_blank" :href="EditModalInfo.item_link">{{ EditModalInfo.item_link }}</a></td>
                                    </tr>

                                    <tr v-if="EditModalInfo.file1">
                                        <td class="fw-500"><b>Available Files: </b></td>
                                        <td>
                                            <a v-if="EditModalInfo.file1" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+EditModalInfo.file1" download> File 1</a>
                                            <a v-if="EditModalInfo.file2" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+EditModalInfo.file2" download>File 2</a>
                                            <a v-if="EditModalInfo.file3" class="btn btn-success" :href="'/uploads/NewItemCreationRequests/'+EditModalInfo.file3" download>File 3</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr v-if="EditModalInfo.item_template_l_o_m != null" style="display: table-caption;">
                                        <td>  
                                            <img v-if="EditModalInfo.item_template_l_o_m.picture" :src="'/uploads/ItemMasterPictures/'+EditModalInfo.item_template_l_o_m.picture" style="width:15vw;margin-left:20px;border-radius:5px">
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        
                            <div class="col-lg-12" v-if="EditModalInfo.item && EditModalInfo.item_status_for == 'add_item' && EditModalInfo.status.indexOf('Assigned to Validator') !== -1">
                                
                                <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">
                                Recommended Listing <span style="font-style: italic; font-size: 16px;">(How will the item be described in the Library of Materials)</span>
                                </h3>
                                
                                <table class="table">
                                    <tr>
                                        <td><b class="fw-500">Recommended <i>Category</i>: </b></td>
                                        <td>{{ EditModalInfo.item.item_template.template_name.toUpperCase()}}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="fw-500">Recommended Item Description: </b></td>
                                        <td>
                                            <template v-for="index in 20">    
                                                <span v-if="EditModalInfo.item.item_template['field_'+(index)] !== 'Ogeo-None'">
                                                    <b>{{ EditModalInfo.item.item_template['field_'+(index)] }}:</b> {{ EditModalInfo.item['field_'+(index)] }} | 
                                                </span>
                                            </template>
                                            <br><br>
                                            <b>UNIT OF MEASURE:</b> {{EditModalInfo.item.u_o_m.toUpperCase()}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b class="fw-500">Suggested At: </b></td>
                                        <td>{{ EditModalInfo.item.created_at }}</td>
                                    </tr>
                                </table>
                                <hr>

                                    <div align="center" v-if="EditModalInfo.item" class="d-inline-flex justify-content-center ">
                                        <div class="mr-4" v-for="(img, index) in JSON.parse(EditModalInfo.item.picture)" :key="index" >
                                            <img style="width: 10vw;height: 10vw; text-align: center;"  :src="'/uploads/ItemMasterPictures/'+img">
                                        </div>
                                    </div>

                            </div>
                            <!-- <div class="col-lg-12" v-if="EditModalInfo.item == null && EditModalInfo.item_template_l_o_m && ViewModalInfo.item_status_for == 'add_item'">
                                <hr> 
                                <h4 style="color: #FF2F2F">Suggested Listing Details <i style="font-size: 14px">(How it'll be added to the Library of Materials)</i></h4>
                                <table class="table">
                                    <tr>
                                        <td><b>Suggested Item Description: </b></td>
                                        <td>
                                            <template v-for="index in 20">    
                                                <span v-if="EditModalInfo.item_template_l_o_m.item_template['field_'+index] !== 'Ogeo-None'">
                                                    <b>{{ EditModalInfo.item_template_l_o_m.item_template['field_'+index] }}:</b> {{ EditModalInfo.item_template_l_o_m['field_'+index] }} | 
                                                </span>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Suggested At: </b></td>
                                        <td>{{ EditModalInfo.item_template_l_o_m.created_at }}</td>
                                    </tr>
                                </table>
                            </div> -->

                            <div class="col-lg-12 b-l" v-if="EditModalInfo.status.indexOf('Created and Assigned to the Library of Materials Administrator') !== -1 || EditModalInfo.status.indexOf('Resubmitted and Assigned') !== -1">
                                
                                <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Decision</h3>
                                
                                    
                                <el-form :model="NewItemInfo" class="demo-form-inline" :rules="rules" ref="ItemCreationForm">
                                    <div class="row">
                                        <div class="col-lg-12">
                                        <div class="col-lg-12">
                                            <div class="grid-content">
                                                <el-form-item prop="DecisionNotes">
                                                    <el-input
                                                    type="textarea"
                                                    :rows="5"
                                                    placeholder="Type Decision Notes here, if any. They are mandarory in case the Request to Update the Library of Materials is rejected"
                                                    v-model="ItemRejectionNote">
                                                    </el-input>
                                                </el-form-item>
                                            </div> 
                                        </div>
                                
                                        <div class="col-lg-6 float-right">
                                            <button type="button" v-if="EditModalInfo.item_status_for == 'add_item' && this.showSecondBtn == false" @click="FirstApprovedBtnClick()" class="btn btn-block btn-success waves-effect" style="background-color: green; border-color: green;">Approve</button>
                                            <button type="button" v-if="EditModalInfo.item_status_for == 'add_item' && this.showSecondBtn == true" @click="SubmitNewItemCreation" class="btn btn-block btn-success waves-effect" style="background-color: green; border-color: green;">Assign To Validator</button>
                                            <button type="button" v-if="EditModalInfo.item_status_for == 'inactivate_item' || this.EditModalInfo.item_status_for == 'reactivate_item'" @click="DecisionApprove" class="btn btn-block btn-success waves-effect" style="background-color: green; border-color: green;">Approve</button>
                                        </div>
                                
                                        <div class="col-lg-6 float-left">
                                            <button type="button" @click="RejectItemRequest" class="btn btn-block btn-danger waves-effect">Reject</button>
                                        </div>
                                        
                                            <div class="grid-content" v-if="this.showSecondBtn == true">
                                                
                                                <el-form-item style="font-weight: bolder" label="temp" prop="ItemStatus">
                                                    
                                                    <span slot="label"><b>Select an appropriate <i>Category</i> to use for the item addition to the <i>Library of Materials</i></b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
														</span>
                                                        <span style="bottom: -20px; left: 175px; width: 850px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <p>An item cannot be added to the <i>Library of Materials</i> without first being linked to an appropriate <i>Category</i>.<br>
                                                                 This ensures a more organized and accurate description of the item. <br>
                                                                 If none of the existing <i>Categories</i> are suitable, a new, more appropriate <i>Category</i> must be created. <br><br>
                                                                 Once this <i>Category</i> is created, the process of adding the item to the <i>Library of Materials</i> can progress.</p>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <el-select style="width: 100%; padding: 0px;"
                                                        v-model="NewItemInfo.TemplateID" 
                                                        filterable
                                                        @change="GetTemplateStructure"
                                                        placeholder="Select a Category">

                                                        <el-option v-for="(option, key) in TemplatesList" 
                                                            :key="key" 
                                                            :label="option" 
                                                            :value="key">
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="TemplateDetailsLoaded">


                                        <div class="col-lg-4">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="UnitOfMeasure">
                                                    <span slot="label"><b>Enter UNIT OF MEASURE</b></span>
                                                    <el-input ref="UnitOfMeasure" v-model="NewItemInfo.UnitOfMeasure" placeholder="Enter UNIT OF MEASURE"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_1 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_1">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_1 }}</b></span>
                                                    <el-input ref="Template_Name" v-model="NewItemInfo.ItemField_1" :placeholder="'Enter '+ItemMasterSetupInfo.field_1"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_2 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_2">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_2 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_2" :placeholder="'Enter '+ItemMasterSetupInfo.field_2"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_3 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_3">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_3 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_3" :placeholder="'Enter '+ItemMasterSetupInfo.field_3"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_4 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_4">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_4 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_4" :placeholder="'Enter '+ItemMasterSetupInfo.field_4"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_5 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_5">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_5 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_5" :placeholder="'Enter '+ItemMasterSetupInfo.field_5"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_6 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_6">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_6 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_6" :placeholder="'Enter '+ItemMasterSetupInfo.field_6"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_7 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_7">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_7 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_7" :placeholder="'Enter '+ItemMasterSetupInfo.field_7"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_8 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_8">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_8 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_8" :placeholder="'Enter '+ItemMasterSetupInfo.field_8"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_9 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_9">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_9 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_9" :placeholder="'Enter '+ItemMasterSetupInfo.field_9"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_10 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_10">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_10 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_10" :placeholder="'Enter '+ItemMasterSetupInfo.field_10"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_11 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_11">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_11 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_11" :placeholder="'Enter '+ItemMasterSetupInfo.field_11"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_12 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_12">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_12 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_12" :placeholder="'Enter '+ItemMasterSetupInfo.field_12"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_13 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_13">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_13 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_13" :placeholder="'Enter '+ItemMasterSetupInfo.field_13"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_14 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_14">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_14 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_14" :placeholder="'Enter '+ItemMasterSetupInfo.field_14"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_15 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_15">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_15 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_15" :placeholder="'Enter '+ItemMasterSetupInfo.field_15"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_16 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_16">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_16 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_16" :placeholder="'Enter '+ItemMasterSetupInfo.field_16"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_17 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_17">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_17 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_17" :placeholder="'Enter '+ItemMasterSetupInfo.field_17"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_18 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_18">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_18 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_18" :placeholder="'Enter '+ItemMasterSetupInfo.field_18"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_19 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_19">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_19 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_19" :placeholder="'Enter '+ItemMasterSetupInfo.field_19"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-4" v-if="ItemMasterSetupInfo.field_20 != 'Ogeo-None'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemField_20">
                                                    <span slot="label"><b>Enter {{ ItemMasterSetupInfo.field_20 }}</b></span>
                                                    <el-input  v-model="NewItemInfo.ItemField_20" :placeholder="'Enter '+ItemMasterSetupInfo.field_20"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <el-upload
                                                action="#"
                                                list-type="picture-card"
                                                :limit="3"
                                                id='UploadDocuments'
                                                :auto-upload="false"
                                                ref="UploadDocuments"
                                                :on-exceed="handleExceedItemCreation"
                                                :on-remove="handleRemove">
                                                <i class="el-icon-plus"></i>
                                            </el-upload>
                                            <span>You can upload a maximum of 3 pictures per item.</span>
                                            <br>
                                        </div>


                                        <div class="col-lg-12">
                                            <hr>
                                            <button type="button" @click="SubmitNewItemCreation" class="btn btn-success btn-block waves-effect text-center" style="background-color: green; border-color: green;">Assign To Validator</button>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-lg-12">
                                            <br>
                                            <button type="button" @click="ShowRejectionForm" :class="[ShowRejectionNotes ? 'btn-primary' : 'btn-danger' ]" class="btn  btn-block waves-effect text-center">{{ RejectionButton }}</button>
                                            
                                            <br><br>
                                            
                                            <div v-if="ShowRejectionNotes" class="grid-content">
                                                
                                                <el-input
                                                    type="textarea"
                                                    :rows="4"
                                                    placeholder="Enter Rejection Notes"
                                                    v-model="ItemRejectionNote">
                                                </el-input>

                                            <br><br>

                                            <button type="button" @click="RejectItemRequest" class="btn btn-danger btn-block waves-effect text-center">Submit Rejection</button>

                                            </div>
                                        </div>
                                    </div> -->


                                </el-form>
                            </div> 

                            <div class="col-lg-12" v-if="EditModalInfo.status.indexOf('Rejected By') !== -1">
                                <el-form :model="ItemCreationResubmission" class="demo-form-inline" :rules="ItemCreationResubmissionRules" ref="ItemCreationRequestForm">

                                    <el-row :gutter="24">

                                        <transition v-if="CompanyDetails.setup_mode == 'Project Wise'">
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Decision</h3>
                                                    <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.080);color: #111111;" >
																<!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button> -->
																<!-- <h4 class="text-info"> Role Completing Information</h4> -->
                                                                <!--<p style="color:red">Thank You !!</p>-->
																<p><i class="fa fa-comment"></i>  A rejected <i>Request to Update the Library of Materials</i> can either be cancelled or amended and 
                                                                    resubmitted for validation.
                                                                    The elements of the <i>Request</i> that can be amended are:                                                                    
                                                                     <div style="margin-left: 40px;">
                                                                        1. The <b><i>Account</i></b>;<br>
                                                                        2. The <b>item</b> which is the subject of the <i>Request</i>; and<br>
                                                                        3. When applicale, the <b>link</b> & <b>documents</b> that better describe the item.                       
                                                                    </div>
                                                                </p>
                                                                <p>The <i>Request</i> Type cannot be amended.</p>
                                                                <p>If you wish to resubmit the <i>Request</i>, please update the below information if needed, and click 
                                                                on <b>"Initiate A New <i>Request to Update the Library of Materials</i>"</b> button. Otherwise, click on 
                                                                <b>"Cancel <i>Request to Update the Library of Materials</i>"</b> button to <b>permanently</b> delete the <i>Request</i>.</p>
                                                    </div>
                                                    <el-form-item label="temp" prop="ProjectId">
                                                        <span slot="label"><b>Amend the <i>Account</i> ?</b></span>

                                                        <el-select filterable style="width: 100%; padding: 0px;" v-model="ItemCreationResubmission.ProjectId" placeholder="Select An Account ?">
                                                            <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </transition>

                                        <el-col :span="24">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="item_status_for">
                                                    <span slot="label"><b>Request Type</b></span>
                                                    <!--<span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                            
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>I have the possibility to request: 1. An item is added to the Library of Materials; 2. An active item is deactivated for a given Setup; and 3. A deactivated item is reactivated for a given Setup. Deactivated items, under a Setup, can't be accessible under that Setup for the creation of Purchase Enquiries or Rates' Contract.</strong>
                                                        </span>
                                                    </span>-->
                                                    </span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" disabled @change="EmptyFoundItems()" v-model="ItemCreationResubmission.item_status_for" placeholder="What Would You Like To Do?">
                                                        <el-option label="Add An Item To The Library Of Materials" value="add_item"></el-option>
                                                        <el-option label="Inactivate An Item of The Library Of Materials" value="inactivate_item"></el-option>
                                                        <el-option label="Reactivate An Item of The Library Of Materials" value="reactivate_item"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="ItemCreationResubmission.item_status_for == 'add_item'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemDescription">
                                                    <span slot="label"><b>Amend the item description ?</b></span>

                                                    <!--<span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>Quasars</strong> are believed to be powered by accretion of material into supermassive black holes in the nuclei of distant galaxies, making these luminous versions of the general...
                                                            </span>
                                                        </span>
                                                    </span>-->
                                                    
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Complete Description Of The Required Item."
                                                        v-model="ItemCreationResubmission.ItemDescription">
                                                    </el-input>
                                                    
                                                </el-form-item>
                                            </div>

                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemLink">
                                                    <span slot="label"><b>Amend the item's link ? (If Available)</b></span>
                                                    <el-input v-model="ItemCreationResubmission.ItemLink" placeholder="https://www.itemsinfo.com/itemlink/232/wsd"></el-input>
                                                </el-form-item>
                                            </div>

                                            
                                            <div style="width: 100%" class="grid-content">
                                                <el-form-item style="margin-bottom: 0px;" label="temp" prop="ItemDocuments">
                                                    <span slot="label"><b>Amend the item's description documents ?</b></span>
                                                </el-form-item>

                                                    <el-upload
                                                        class="upload-demo"
                                                        id='UploadDocumentsResubmit'
                                                        :auto-upload="false"
                                                        ref="UploadDocumentsResubmit"
                                                        multiple
                                                        action="#"
                                                        :limit="3"
                                                        :on-exceed="handleExceedResubmittion"
                                                        :file-list="ItemCreationResubmission.ItemDocuments">

                                                        <button type="button" slot="trigger" class="btn btn-success">Select File</button>
                                                      
                                                        <div class="el-upload__tip" slot="tip">All file types are accepted, each with a size of less than 3MB (up to 3 files maximum).</div>
                                                    </el-upload>

                                                

                                            </div>

                        
                                        </el-col>

                                        <el-col :span="24" v-if="ItemCreationResubmission.item_status_for == 'inactivate_item' || ItemCreationResubmission.item_status_for == 'reactivate_item'">
                                    <div class="grid-content">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Select a different item from the <i>Library of Materials</i> ?</b></span>
                                        <el-select style="width: 100%"
                                            v-model="ItemCreationResubmission.LOM"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placeholder="Search and Select an Item">
                                            <el-option style="max-width: 674.656px; max-height: 150px"
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                :value="item.id">
                                                <div class="row">
                                                    <div class="col-sm-2 b-r" style="padding-left: 0px; padding-top: 5px; padding-buttom: 0px; padding-right: 0px;" >
                                                       <img  class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                        
                                                    </div>
                                                    <div class="col-sm-5 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr>
                                                                <td width="50%"> <span v-if="item.company.lom_prefix == null || item.company.lom_prefix == ''">LoM</span><span v-else>{{ item.company.lom_prefix }}</span>-{{ item.item_group_id  }}-{{ item.item_ingroup_id  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>{{ item.item_template.field_1.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%"> {{ item.field_1.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>{{ item.item_template.field_2.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%">{{ item.field_2.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>{{ item.item_template.field_3.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%">{{ item.field_3.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>Rate Contract:</b></td>
                                                                <!-- <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)"> View Rates</td> -->
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)">
                                                                    <el-tooltip placement="top-start" effect="light">
                                                                        <div slot="content">
                                                                            <div v-if="RateContractDataLoaded">
                                                                                <table style="min-width: 500px" class="table table-bordered">
                                                                                    <thead>
                                                                                        <td align="center"><b>Price</b></td>
                                                                                        <td align="center"><b>Lead Time</b></td>
                                                                                        <td align="center"><b>Contract End Date</b></td>
                                                                                    </thead>
                                                                                    <tbody v-if="ItemRateContractDetails.length > 0">
                                                                                        <tr v-for="(RateContract, key, index) in ItemRateContractDetails" :key="index">
                                                                                            <td align="center">{{ formatPrice(RateContract.vendor_responses[0].unit_rate) }} {{ RateContract.vendor_responses[0].currency }}</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].lead_days }} Day(s)</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].rate_contract_request.rc_end_period.substring(0, 10) }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tbody v-else>
                                                                                        <tr>
                                                                                            <td align="center" colspan="3"> NO CONTRACT AVAILABLE </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div align="center" v-else>
                                                                                <h2>Gathering Data...</h2>
                                                                                <img align="center" :src="'/assets/images/ajax-loader.gif'">
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <i style="line-height: 0px">View Rates</i>
                                                                    </el-tooltip>

                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-sm-3 text-wrap" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                        <div>
                                                            <h5><b>Item Description:</b></h5>
                                                            <p style="word-wrap: break-word;">{{ item.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                </div>
                                            
                                        </el-col>
                                        
                                        
                                        <el-col :span="24">
                                            <hr>
                                            <button type="button" @click="SubmitItemCreationRequestResubmit" 
        class="btn btn-block waves-effect text-center" 
        style="background-color: green; border-color: green; color: white;">
  Initiate A New <i>Request to Update the Library of Materials</i>
</button>

                                        </el-col>


                                        <el-col :span="24">
                                            <hr>
                                            <button type="button" @click="CancelItemCreationRequestResubmit" class="btn btn-danger btn-block waves-effect text-center">Cancel <i>Request to Update the Library of Materials</i></button>
                                        </el-col>

                                    </el-row>
                                </el-form>
                            </div>

                            <div class="col-lg-12" v-if="EditModalInfo.status.indexOf('Assigned to Validator') !== -1">
                                
                                 <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">Decision</h3>
                                <el-form :model="ValidationInfo" class="demo-form-inline" :rules="ItemApprovalRules" ref="ItemCreationValidationForm">
                            
                                    <div class="col-lg-12">
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="DecisionNotes">
                                                <span slot="label"><b>Enter Decision Notes</b></span>
                                                <el-input
                                                  type="textarea"
                                                  :rows="5"
                                                  placeholder="Type Decision Notes here, if any. They are mandarory in case the Request to Update the Library of Materials is rejected"
                                                  v-model="ValidationInfo.DecisionNotes">
                                                </el-input>
                                            </el-form-item>
                                        </div> 
                                    </div>
                            
                                    <div class="col-lg-6 float-right">
                                        <button type="button" @click="DecisionApprove" style="background-color: green; border-color: green;" class="btn btn-block btn-success waves-effect">Approve</button>
                                    </div>
                            
                                    <div class="col-lg-6 float-left">
                                        <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                    </div>
                            
                                    
                                </el-form>
                            </div>
                            

                            <div class="col-lg-12">
                                <br>
                                <h3 style="font-style: italic; background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                        <th style="border: 1px solid #dddddd;"><b>#</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>Description</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>By</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action Level</b></th>
                                         <th style="border: 1px solid #dddddd;" ><b>Decision - <i>Notes</i></b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Action <br>Date</b></th>
                                        <th style="border: 1px solid #dddddd;"><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action <br>Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next <br>Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;"><b>Next Action Validation Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                            <td style="border: 1px solid #dddddd;">{{ key + 1 }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_action_description }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_action_by.name }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.current_approval_level }}</td>
                                            <td style="border: 1px solid #dddddd;">{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                            <td style="border: 1px solid #dddddd;">{{ item.created_at }}</td>
                                            <td style="border: 1px solid #dddddd; font-size: 13px" v-if="key == 0"><i>Not Applicable</i></td>
                                            <td style="border: 1px solid #dddddd;" v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);border: 1px solid #dddddd;">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="col-lg-12">
                                <hr>
                                <h4 style="color: #FF2F2F">Item Status Record</h4>   
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Setup Name</b></th>
                                            <th><b>Status</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in CompanyItemStatus" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.project.title }}</td>
                                            <td v-if="item.active == 'Yes'">Active - <br>  Since({{ moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }})</td>
                                            <td v-if="item.active == 'No'">Inactive - <br> Since({{ moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }})</td>
                                            
                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div> -->

                        </div>                      
                    </div>
                </div>
            </div>
        </div>

    </div>
            
</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'item-creation-requests',
        data(){

            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value == '' || value == null){
                        callback();
                    } else {
                        callback(new Error('Please enter a valid URL. (e.g. https://ogeo.me/example)'));
                    }
                    
                }
            };

            return{
                showSecondBtn: false,
                DataTable: "",
                TemplatesList: {},
                JobNumbers: {},
                CompanyDetails: {},
                TemplateDetailsLoaded: false,
                ItemMasterSetupInfo: {},
                ShowRejectionNotes: false,
                ItemRejectionNote: "",
                RejectionButton: "Reject Request",
                editDataLoaded: false,
                viewDataLoaded: false,
                ViewModalInfo: {},
                EditModalInfo: {},
                searchItemsLoading: false,
                FoundItems: {},
                NewItemInfo: {
                    OldProjectId: "",
                    ItemRequestProjectId: "",
                    ItemRequestId: "",
                    ItemCreationRequestId:"",
                    ExistingItemId: "",
                    UnitOfMeasure: "",
                    TemplateID: "",
                    ItemField_1: "",
                    ItemField_2: "",
                    ItemField_3: "",
                    ItemField_4: "",
                    ItemField_5: "",
                    ItemField_6: "",
                    ItemField_7: "",
                    ItemField_8: "",
                    ItemField_9: "",
                    ItemField_10: "",
                    ItemField_11: "",
                    ItemField_12: "",
                    ItemField_13: "",
                    ItemField_14: "",
                    ItemField_15: "",
                    ItemField_16: "",
                    ItemField_17: "",
                    ItemField_18: "",
                    ItemField_19: "",
                    ItemField_20: "",
                },
                rules: {
                    TemplateID: [{
                        required: true,
                        message: "Please select a Category",
                        trigger: "blur"
                    }],
                    
                    UnitOfMeasure: [{
                        required: true,
                        message: "Please enter a UNIT OF MEASURE",
                        trigger: "blur"
                    }],
                    ItemField_1: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_2: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_3: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_4: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_5: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_6: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_7: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_8: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_9: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_10: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_11: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_12: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_13: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_14: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_15: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_16: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_17: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_18: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_19: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                    ItemField_20: [{
                        required: false,
                        message: "this is a required field",
                        trigger: "blur"
                    }],
                },
                ValidationInfo: {
                    ItemId: "",
                    Decision: "",
                    DecisionNotes: "",
                    ItemStatusId: "",
                    ItemRequestId: "",
                },
                ItemApprovalRules: {
                    Decision: [{
                        required: true,
                        message: "Please approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision Notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
                CompanyItemStatus: '',
                ItemCreationResubmission: {
                    ProjectId: "",
                    ItemStatusId: "",
                    ItemCreationRequestId: "",
                    item_status_for: '',
                    ItemId: "",
                    LOM: '',
                    ItemDescription: "",
                    ItemLink: "",
                    OldProjectId: '',
                    ItemDocuments: []
                },
                ItemCreationResubmissionRules: {
                    ProjectId: [{
                        required: true,
                        message: "Please select an Account",
                        trigger: ["blur"]
                    }],
                    item_status_for: [{
                        required: true,
                        message: "Please Specify, What You Like To Do",
                        trigger: ["blur"]
                    }],
                    ItemDescription: [{
                        required: true,
                        message: "Please enter the item description",
                        trigger: "blur"
                    }],
                    ItemDocuments: [{
                        required: false,
                        message: "Please upload at least one documenet",
                        trigger: "blur"
                    }],
                    ItemLink: [{
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                } 

            }
        },
        methods: {
            FirstApprovedBtnClick(){
                if(this.EditModalInfo.item_status_for == 'add_item'){
                    this.showSecondBtn = true;
                }
            },
            showRateContract(ItemId){
                event.preventDefault();
                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                .then((response) => {
                    this.ItemRateContractDetails = response.data;
                    this.RateContractDataLoaded = true;
                })
                .catch(function(){
                    Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                });
            },
            EmptyFoundItems(){
                this.ItemCreationResubmission.LOM = '';
                this.FoundItems = [];
            },
            searchItems(value) {
                if(isNaN(this.ItemCreationResubmission.ProjectId))
                    var ProjectIdTMP = this.ItemCreationResubmission.OldProjectId;
                else
                    var ProjectIdTMP = this.ItemCreationResubmission.ProjectId;
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: this.EditModalInfo.item_status_for, project_id: ProjectIdTMP})
                    .then((response) => {
                        if(response.data.length > 0){
                            this.FoundItems = response.data;
                        }
                        this.searchItemsLoading = false;
                    })
            },
            CancelItemCreationRequestResubmit(){

                let self = this;

                axios.post( '/api/users/cancel_item_request_by_initiator', {ItemRequestId: this.ItemCreationResubmission.ItemCreationRequestId})
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        });

                        self.DataTable.ajax.reload( null, false );

                        self.$store.dispatch("update_user_pending_tasks")


                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem', 'error');
                    });

                  
                    $('#ItemCreationRequestModalEdit').modal('toggle');
                   


            },  

            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },

            SubmitItemCreationRequestResubmit: function(){
                let self = this;

                if (event) event.preventDefault();

                this.$refs.ItemCreationRequestForm.validate((validation) => {

                    if(validation){

                        let formData = new FormData();
                        if(this.ItemCreationResubmission.item_status_for == 'add_item'){
                        for( var i = 0; i < this.$refs.UploadDocumentsResubmit.uploadFiles.length; i++ ){
                            let file = this.$refs.UploadDocumentsResubmit.uploadFiles[i].raw;
                            formData.append('FileUploads['+i+']', file);
                        }
                        formData.append('ItemDescription', this.ItemCreationResubmission.ItemDescription);
                        formData.append('ItemLink', this.ItemCreationResubmission.ItemLink);
                        }
                        if(isNaN(this.ItemCreationResubmission.ProjectId))
                            formData.append('ProjectId', this.ItemCreationResubmission.OldProjectId);
                        else
                            formData.append('ProjectId', this.ItemCreationResubmission.ProjectId);
                        formData.append('ItemCreationRequestId', this.ItemCreationResubmission.ItemCreationRequestId);
                        if(this.ItemCreationResubmission.item_status_for !== 'add_item'){
                            if(isNaN(this.ItemCreationResubmission.LOM))    
                                formData.append('lom', this.ItemCreationResubmission.ItemId);
                            else
                                formData.append('lom', this.ItemCreationResubmission.LOM);
                            }
                        formData.append('itemStatus', this.ItemCreationResubmission.item_status_for);

                        axios.post( '/api/users/new_item_creation_request',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            });

                            self.DataTable.ajax.reload( null, false );
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem', 'error');
                        });

                        $('#ItemCreationRequestModalEdit').modal('toggle');



                        this.ItemCreationResubmission.ProjectId = "";
                        this.ItemCreationResubmission.ItemCreationRequestId = "";
                        this.ItemCreationResubmission.ItemDescription = "";
                        this.ItemCreationResubmission.ItemLink = "";

                        if(this.CompanyDetails.setup_mode == 'Company Wide'){
                            this.ItemCreationResubmission.ProjectId = Object.keys(this.JobNumbers)[0];
                        }

                    }

                });
                
            },
            handleExceedResubmittion(files, fileList) {
                Swal('The Limit is 3 Files', 'The limit is 3 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')
                
            },


            GetTemplateStructure(templateId){

                axios.post('/api/data/get_item_master_template_details', {TemplateID: templateId})
                .then((response) => {

                    this.ItemMasterSetupInfo = response.data;
                    this.TemplateDetailsLoaded = true;
                    
                    if(this.ItemMasterSetupInfo.field_1_required == 'Yes'){
                        this.rules.ItemField_1[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_2_required == 'Yes'){
                        this.rules.ItemField_2[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_3_required == 'Yes'){
                        this.rules.ItemField_3[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_4_required == 'Yes'){
                        this.rules.ItemField_4[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_5_required == 'Yes'){
                        this.rules.ItemField_5[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_6_required == 'Yes'){
                        this.rules.ItemField_6[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_7_required == 'Yes'){
                        this.rules.ItemField_7[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_8_required == 'Yes'){
                        this.rules.ItemField_8[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_9_required == 'Yes'){
                        this.rules.ItemField_9[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_10_required == 'Yes'){
                        this.rules.ItemField_10[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_11_required == 'Yes'){
                        this.rules.ItemField_11[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_12_required == 'Yes'){
                        this.rules.ItemField_12[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_13_required == 'Yes'){
                        this.rules.ItemField_13[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_14_required == 'Yes'){
                        this.rules.ItemField_14[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_15_required == 'Yes'){
                        this.rules.ItemField_15[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_16_required == 'Yes'){
                        this.rules.ItemField_16[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_17_required == 'Yes'){
                        this.rules.ItemField_17[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_18_required == 'Yes'){
                        this.rules.ItemField_18[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_19_required == 'Yes'){
                        this.rules.ItemField_19[0].required = true;
                    }
                    if(this.ItemMasterSetupInfo.field_20_required == 'Yes'){
                        this.rules.ItemField_20[0].required = true;
                    }

                })
                
            },

            ShowRejectionForm(){
                if(this.ShowRejectionNotes == false){
                    this.ShowRejectionNotes = true;
                    this.RejectionButton = "Close Rejection Form";
                } else {
                    this.ShowRejectionNotes = false;
                    this.ItemRejectionNote = "";
                    this.RejectionButton = "Reject Request";
                }
                
            },
            RejectItemRequest(){

                let self = this;

                if(this.ItemRejectionNote == ""){
                    Swal('Rejection Notes Are Required', 'Rejected Notes are required for successful rejection', 'warning');
                    return false;
                }

                axios.post('/api/users/reject_item_request_by_lma', {RejectionNotes: this.ItemRejectionNote, ItemRequestId: this.NewItemInfo.ItemRequestId})
                    .then(function(response){

                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        });

                       
                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please the contact system administrator to solve the problem (ItemCreationForm)', 'error');
                    });

                    
                    $('#ItemCreationRequestModalEdit').modal('toggle');
                    

                    this.ItemRejectionNote = '';
                    this.ShowRejectionNotes = false;
               
               
            },

            SubmitNewItemCreation() {

                let self = this;

                this.$refs.ItemCreationForm.validate((validation) => {

                    if(validation){

                        let formData = new FormData();

                        if(!this.$refs.UploadDocuments.uploadFiles[0]){
                            Swal('Item Picture Required', 'You must upload atleast one picutre for the item', 'warning')
                            return false;
                        }

                        for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                            let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                            formData.append('ItemPictures['+i+']', file);
                        }

                        formData.append('NewItemInfo', JSON.stringify(this.NewItemInfo));

                        axios.post( '/api/users/item_master_creation',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(function(response){

                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            });

                            self.DataTable.ajax.reload( null, false );
                            

                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (ItemCreationForm Code:001)', 'error');
                        });

                        
                        $('#ItemCreationRequestModalEdit').modal('toggle');
                        
                    }

                })

                
            },
            handleExceedItemCreation(files, fileList) {
                Swal('The Limit is ' + fileList.length + ' Picture', 'The limit is ' + fileList.length + ' pictures per item, if you would like to upload more than one picture, please reach out to Ogéo support team', 'warning')
                
            },
            handleRemove(file, fileList) {
                // console.log(file, fileList);
            },
            showViewModal(elquentClass, recordId){

                this.ViewModalInfo = {};
                axios.post('/api/data/get_item_master_record_details', [elquentClass, recordId])
                    .then((response) => {
                        if(response.data.record)
                            this.ViewModalInfo = response.data.record;
                        else
                            this.ViewModalInfo = response.data;
                        // this.CompanyItemStatus = ''
                        // this.CompanyItemStatus = response.data.companyItemStatus;
                        window.ViewModalInfo = this.ViewModalInfo;
                        this.viewDataLoaded = true
                        $('#ItemCreationRequestModalView').modal('toggle');
                    })

                    // console.log(this.ViewModalInfo);

                this.viewDataLoaded = false;
                
            },
            showEditModal(elquentClass, recordId){
                this.EditModalInfo = {};
                this.ValidationInfo.Decision= "";
                this.ValidationInfo.DecisionNotes= "";
                axios.post('/api/data/get_item_master_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = response.data.record;
                        this.CompanyItemStatus = ''
                        this.CompanyItemStatus = response.data.companyItemStatus;
                        this.NewItemInfo.ItemRequestId = this.EditModalInfo.id;
                        this.NewItemInfo.ItemRequestProjectId = this.EditModalInfo.project_id;
                        this.NewItemInfo.ItemCreationRequestId = this.EditModalInfo.id;

                        this.ItemCreationResubmission.ItemCreationRequestId = this.EditModalInfo.id;
                        this.ItemCreationResubmission.OldProjectId = this.EditModalInfo.project_id;
  
                        
                        if(this.EditModalInfo.status.indexOf('Rejected By') !== -1)
                        {
                            this.ItemCreationResubmission.ProjectId = this.EditModalInfo.project.title;
                            // this.ItemCreationResubmission.ItemStatusId = this.EditModalInfo.item_status.id;
                            this.ItemCreationResubmission.item_status_for = this.EditModalInfo.item_status_for;
                            this.ItemCreationResubmission.ItemDescription = this.EditModalInfo.item_description;
                            this.ItemCreationResubmission.ItemLink = this.EditModalInfo.item_link;
                            if(this.ItemCreationResubmission.item_status_for != 'add_item')    
                                this.ItemCreationResubmission.LOM = "LOM-"+this.EditModalInfo.item_template_l_o_m.item_group_id +"-"+ this.EditModalInfo.item_template_l_o_m.item_ingroup_id+ ": "+ this.EditModalInfo.item_template_l_o_m.description;
                            if(this.EditModalInfo.file1){
                                this.ItemCreationResubmission.ItemDocuments.push(this.EditModalInfo.file1);
                            }
                            if(this.EditModalInfo.file2){
                                this.ItemCreationResubmission.ItemDocuments.push(this.EditModalInfo.file2);
                            }
                            if(this.EditModalInfo.file3){
                                this.ItemCreationResubmission.ItemDocuments.push(this.EditModalInfo.file3);
                            }
                            
                        }
                        else{
                        if(this.EditModalInfo.item){
                            this.NewItemInfo.OldProjectId = this.EditModalInfo.project_id;
                            this.NewItemInfo.ExistingItemId = this.EditModalInfo.item.id;
                            this.NewItemInfo.ItemStatusId = this.EditModalInfo.item_status.id;

                            this.ValidationInfo.ItemStatusId = this.EditModalInfo.item_status.id;
                            this.ValidationInfo.ItemRequestId = this.EditModalInfo.id;
                            this.ValidationInfo.ItemId = this.EditModalInfo.item.id;
                            
                            this.ItemCreationResubmission.ItemStatusId = this.EditModalInfo.item_status.id;
                            this.ItemCreationResubmission.item_status_for = this.EditModalInfo.item_status_for;
                            
                        }
                        if(this.EditModalInfo.item_template_l_o_m){
                            this.NewItemInfo.OldProjectId = this.EditModalInfo.project_id;
                            this.NewItemInfo.ExistingItemId = this.EditModalInfo.item_template_l_o_m.id;
                            this.NewItemInfo.ItemStatusId = this.EditModalInfo.item_status.id;

                            this.ValidationInfo.ItemId = this.EditModalInfo.item_template_l_o_m.id;
                            this.ValidationInfo.ItemStatusId = this.EditModalInfo.item_status.id;
                            this.ValidationInfo.ItemRequestId = this.EditModalInfo.id;

                            this.ItemCreationResubmission.ItemStatusId = this.EditModalInfo.item_status.id;
                            this.ItemCreationResubmission.ItemId = this.EditModalInfo.item_template_l_o_m.id;
                            this.ItemCreationResubmission.item_status_for = this.EditModalInfo.item_status_for;
                        }
                        }

                        this.editDataLoaded = true

                        $('#ItemCreationRequestModalEdit').modal('toggle');
                    })

                    this.editDataLoaded = false

            },
            DecisionApprove(){

                let self = this;

                this.ValidationInfo.Decision = "Approved";
                axios.post('/api/users/validate_item_creation', this.ValidationInfo)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (ItemCreationForm)', 'error');
                    });

            
                $('#ItemCreationRequestModalEdit').modal('toggle');
                this.ItemApprovalRules.DecisionNotes[0].required = false;
                    
              

            },
            DecisionReject(){

                let self = this;

                this.ItemApprovalRules.DecisionNotes[0].required = true;
                this.ValidationInfo.Decision = "Rejected";
                this.$refs.ItemCreationValidationForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/users/validate_item_creation', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (ItemCreationForm)', 'error');
                            });

                        

                      
                        $('#ItemCreationRequestModalEdit').modal('toggle');
                        this.ItemApprovalRules.DecisionNotes[0].required = false;


                    }
                })
            },
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        created(){
            
        },
        mounted(){

            axios.get('/api/data/get_user_projects_list_almr')
            .then((FirstResponse) => {
                this.JobNumbers = FirstResponse.data;
                axios.get('/api/data/get_company_details')
                .then((SecondResponse) => {
                    this.CompanyDetails = SecondResponse.data;
                    if(this.CompanyDetails.setup_mode == 'Company Wide'){
                        this.ItemCreationResubmission.ProjectId = Object.keys(this.JobNumbers)[0];
                    }
                });
            });

            
            axios.get('/api/data/get_item_templates_list')
                .then((response) => {
                    this.TemplatesList = response.data;
                });

            setTimeout(() => {
                
                const table = $('#NewItemCreationRequests').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                    stateSave:  true,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    serverSide: true,
                    ajax: '/api/data/get_new_item_requests_list',
                    columns: [
                        { data: 'item_request_group_id', name: 'item_request_group_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'requester.name', name: 'requester.name' },
                        { data: 'complete_description_with_headers', render: function(data, type, full){
                            if(data == null){
                                return '';
                            }else{
                                let dec = data.length >= 300 ? data.substr(0,300)+'...' :data;
                                return '<p class="text-uppercase">'+dec+'</p>';
                            }
                        }},
                        { data: 'item_link', name: 'item_link' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'type', name: 'type' },
                        { data: 'status', name: 'status' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        { responsivePriority: 1, targets: 8 },
                        { responsivePriority: 8, targets: 5 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ 0, "ASC" ]],
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'NewItemCreationRequests' },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    },    
                });

                const self = this

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showEditModal(elquentClass, RecordID);
                });

                self.DataTable = table;

            }, 500);

        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>

<style scoped>
    
    .font-500{
        font-weight: 500;
    }

    #ItemCreationRequestModalView .modal-dialog {
        min-width:80%;
    }

    #ItemCreationRequestModalEdit .modal-dialog {
        min-width:80%;
    }

    .dataTables_wrapper .dataTables_processing {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30%;
        text-align: center;
        margin-left: -15%;
        margin-top: -25px;
        padding-top: 30px;
        padding-bottom: 50px;
        font-size: 1.5em;
        background-color: none;
    }

</style>
<style>
    @page {
        size: A4;
        margin: 5mm -20mm 5mm -20mm !important;
    }
    @media print {
      body * {
          visibility: hidden;
      }
      thead {
          display: table-row-group;
      }
      table {
          -fs-table-paginate: paginate;
          page-break-after: auto;
          table-layout: fixed;
          font-size: 9pt !important;
      }
      tr{
          page-break-inside: auto;
          page-break-after: auto;
      }
      td{
          page-break-inside: avoid;
          page-break-after: auto;
      }
      .non-print {
          display: none;
      }
      tbody::after {
          content: ''; display:block;
          page-break-after: auto;
          page-break-inside: avoid;
          page-break-before: avoid;
      }
      #ItemCreationRequestModalView, #ItemCreationRequestModalView * {
        visibility: visible;
        overflow: visible !important;

      }
      #ItemCreationRequestModalView {
          position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100%;
        overflow: visible !important;

      }
    }
</style>