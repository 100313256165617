<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header d-inline-block">
                    <h4 style="display: contents;"><b><i>Materials Rates’ Contract Creation Requests</i> List</b></h4>
                    <div class="text-ogeo float-right font-500">
                        <el-switch style="margin-top:0px; padding-top: 0%;" v-model="switchbutton" @change="changeTable()" active-color="rgb(255, 47, 47)" inactive-color="rgb(255, 47, 47)" active-text="Materials" inactive-text="Services" active-value="Material" inactive-value="Service"></el-switch>
                    </div>
                </div>
                <div class="card-body">
                    <table ref="table" id="NewRateContractItemRequestsApprovals" class="display responsive table table-bordered table-hover" style="width: 100%;" >
                        <thead>
                            <tr>
                                <th><i>Request</i> ID#</th>
                                <th>GroupID</th>
                                <th><i>Account</i> <br>Name</th>
                                <th>Initiated By</th>
                                <th><i>Library of Materials</i> <br>Item Details</th>
                                <th>Expected Quantity <br>During The Term</th>
                                <th><i>Unit of Measure</i></th>
                                <th>List of Validators</th>
                                <th>Awardee Vendor</th>
                                <th>Required Documents <br>From The Vendors</th>
                                <th>Country | City(ies)</th>
                                <th><i>Rates’ Contract</i> Term</th>
                                <th><i>Rates’ Contract</i> Underlying Transaction</th>
                                <th><i>Volume-discount <br>Arrangement</i></th>
                                <th><i>Minimum Procured Value</i> <br>To Trigger The Unit Rate's Discount</th>
                                <th><i>Advance Payment</i> <br>Arrangement</th>
                                <th><i>Retention </i> <br>Arrangement</th>
                                <th>Current Level <br>of Validation</th>
                                <th>Initiated On</th>
                                <th>Last Updated On</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="RateContractItemRequestValidationModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalView" aria-hidden="true" style="display: none;"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo"><i>Materials Rates’ Contract Creation Request</i> Line ID# {{ ViewModalInfo.rate_contract_request_group_id}} - {{ViewModalInfo.rate_contract_request_ingroup_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Account</i> Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ ViewModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ ViewModalInfo.term }} Months</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.country }} | {{ ViewModalInfo.cities.toString()}}</span><br>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                        <td v-if="ViewModalInfo.required_documents">
                                            <ul class="d-inline" v-for="(document, index) in ViewModalInfo.required_documents.split(',')">
                                                <li>{{ document }}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Documents Required
                                        </td>
                                    </tr>
                                    <tr class="text-center">
                                        <td class="text-center" colspan="2">
                                            <div class="text-center m-0 p-0"><button class="text-center" style="background-color: red; border: none; color: white; height: 50px; width: 300px; margin: 10px;">Materials Rates’ Contract</button></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                    </tr>
                                            
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Library of Materials Item Details: </b></td>
                                        <td>
                                            <span v-if="!isJSON(ViewModalInfo.item.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.item.picture"></span>
                                            <span class="text-center" v-else v-for="(img, index) in JSON.parse(ViewModalInfo.item.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></img></span>
                                            <br><br><span><b class="font-700"><span>LoM-</span>{{ ViewModalInfo.item.item_group_id }}-{{ ViewModalInfo.item.item_ingroup_id }}</b></span><br>
                                            <span>
                                                <template v-for="index in 20">      
                                                    <span v-if="ViewModalInfo.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                        <b class="font-700 text-uppercase">{{ ViewModalInfo.item.item_template['field_'+index] }}:</b> {{ ViewModalInfo.item['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <!-- <span><b>Quantity:</b> {{ ViewModalInfo.Quantity }}</span><br> <br> -->
                                            <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ ViewModalInfo.item.u_o_m.toUpperCase() }}</span><br>
                                            <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(ViewModalInfo.item.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                        </td>
                                    </tr> 
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Expected Quantity During The Term: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            {{  ViewModalInfo.quantity }}
                                        
                                        </td>


                                    </tr>

                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700"> Volume Discount Clause: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                                <span>{{ViewModalInfo.need_volume_disc}}</span>
                                                        
                                        
                                        </td>


                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Minimum Value Required To Trigger The Item Unit Rates' Discount: </b></td>

                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            <!-- {{  ViewModalInfo.Quantity }} -->
                                            <span>
                                                <span v-if="ViewModalInfo.need_volume_disc == 'No' ">
                                                    This Item Doesn't Have A Volume Discount Arrangement.
                                                    </span>
                                                    <span v-else>
                                                        {{ ViewModalInfo.minimal_procured_value }} {{ViewModalInfo.project.currency.substring(0, 3)}}
                                                        </span>
                                                        </span>

                                        </td>

                                        
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            Not Yet Awarded
                                        
                                        </td>


                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Materials Infomation Panel End -->
                            
                            <!-- List of Valid Rates Contracts -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr class="text-ogeo text-center">
                                    <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                </tr>
                                        <table>
                                            <tr>
                                                <th><b class="font-700">Vendor Name</b></th>
                                                <th><b class="font-700">Country Cities</b></th>
                                                <th><b class="font-700">Unit Rate</b></th>
                                                <th><b class="font-700">Expiry Date</b></th>
                                                <th><b class="font-700">Lead Time</b></th>
                                                <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                <th><b class="font-700">Discount Rate</b></th>
                                                <th><b class="font-700">Total Procured So Far</b></th>
                                                <th><b class="font-700">Advanced Payment Arrangement</b></th>
                                                <th><b class="font-700">Retention Payment Arrangement</b></th>
                                            </tr>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                <td>{{ item.vendor_name }}</td>
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </table>
                                    </table>
                            </div>
                            <!-- List of Valid Rates Contracts End -->

                        </div>
                        
                         
                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        <div class="col-lg-12">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                        <td>{{ item.decision}} - <i>{{item.decision_notes}}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        
                                        <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                        <td v-else-if="item.decision == 'Permanently Cancelled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="ViewModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="ViewModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="ViewModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="ViewModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><button id="PrintHidden" class="btn btn-success ml-3" onclick="document.title = 'Rates’ Contract Line Information';window.print()">Download PDF</button>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div> 
                                         
                    </div>
                </div>
            </div>
        </div>


        <div id="RateContractItemRequestValidationModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo">Materials Rates’ Contract Creation Request Line ID# {{ EditModalInfo.rate_contract_request_group_id}} - {{EditModalInfo.rate_contract_request_ingroup_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>                    
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">

                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Account</i> Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic"><i>Account</i> Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(EditModalInfo.project.value - EditModalInfo.project.consumed_budget) }} {{ EditModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ EditModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ EditModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ EditModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ EditModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <!-- Old Template -->
                            <!-- <div class="col-lg-6">
                                <b class="font-700 m-2">Setup Details: </b>
                                <table class="table">
                                        <tr>
                                            <td width="40%"><b class="font-700">Setup Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic">Setup Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(EditModalInfo.project.value - EditModalInfo.project.consumed_budget) }} {{ EditModalInfo.project.currency }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-6">
                                <table class="table">
                                    <tr>
                                        <td><b class="font-700">Line Initiated By: </b></td>
                                        <td>{{ EditModalInfo.creator.name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Line Initiated On: </b></td>
                                        <td>{{ EditModalInfo.created_at }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                        <td class="text-ogeo">{{ EditModalInfo.status }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Last Updated On: </b></td>
                                        <td>{{ EditModalInfo.updated_at }}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <b class="font-700" style="margin: 13px">List of Validators: </b>
                                <table class="table">
                                    <tr>
                                        <td><b class="font-700">List of Validatons: </b></td>
                                        <td>
                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                <li>{{ his.name }}<span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div> -->
                            <!-- Old Template End -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ EditModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ EditModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ EditModalInfo.term }} Months</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.country }} | {{ EditModalInfo.cities.toString()}}</span><br>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment_guarantee ? EditModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.retention_percentage	 ? EditModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ EditModalInfo.retention_days ? EditModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Documents Required From The Vendors: </b></td>
                                        <td v-if="EditModalInfo.required_documents">
                                            <ul class="d-inline" v-for="(document, index) in EditModalInfo.required_documents.split(',')">
                                                <li>{{ document }}</li>
                                            </ul>
                                        </td>
                                        <td v-else>
                                            No Documents Required
                                        </td>
                                    </tr>
                                    <tr class="text-center">
                                        <td class="text-center" colspan="2">
                                            <div class="text-center m-0 p-0"><button class="text-center" style="background-color: red; border: none; color: white; height: 50px; width: 300px; margin: 10px;">Materials Rates’ Contract</button></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ EditModalInfo.sourcing_priority }}</td>
                                    </tr>
                                            
                                    <tr v-if="EditModalInfo.rccr_type == 'Material'">
                                        <td style="width: 40%; vertical-align: center;"><b class="font-700">Library of Materials Item Details: </b></td>
                                        <td>
                                            <span v-if="!isJSON(EditModalInfo.item.picture)"><img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+EditModalInfo.item.picture"></span>
                                            <span class="text-center" v-else v-for="(img, index) in JSON.parse(EditModalInfo.item.picture)" :key="index" ><img style="width: 8vw;height: 8vw;  text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img"></img></span>
                                            <br><br><span><b class="font-700"><span>LoM-</span>{{ EditModalInfo.item.item_group_id }}-{{ EditModalInfo.item.item_ingroup_id }}</b></span><br>
                                            <span>
                                                <template v-for="index in 20">      
                                                    <span v-if="EditModalInfo.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                        <b class="font-700 text-uppercase">{{ EditModalInfo.item.item_template['field_'+index] }}:</b> {{ EditModalInfo.item['field_'+index] }} | 
                                                    </span>
                                                </template>
                                            </span><br><br>
                                            <!-- <span><b>Quantity:</b> {{ EditModalInfo.Quantity }}</span><br> <br> -->
                                            <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measurement:</b> {{ EditModalInfo.item.u_o_m.toUpperCase() }}</span><br>
                                            <!-- <span><img style="margin: 10px;display: inline-block; width: 50px; height:50px;" v-for="img in JSON.parse(EditModalInfo.item.picture)" :src="'/uploads/ItemMasterPictures/'+img" class="img-rounded img-responsive"></span><br> -->
                                        </td>
                                    </tr> 
                                    <tr v-if="EditModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Expected Quantity During The Term: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            {{  EditModalInfo.quantity }}
                                        
                                        </td>


                                    </tr>

                                    <tr v-if="EditModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700"> Volume Discount Clause: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                                <span>{{EditModalInfo.need_volume_disc}}</span>
                                                        
                                        
                                        </td>


                                    </tr>
                                    <tr v-if="EditModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Minimum Value Required To Trigger The Item Unit Rates' Discount: </b></td>

                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            <!-- {{  EditModalInfo.Quantity }} -->
                                            <span>
                                                <span v-if="EditModalInfo.need_volume_disc == 'No' ">
                                                    This Item Doesn't Have A Volume Discount Arrangement.
                                                    </span>
                                                    <span v-else>
                                                        {{ EditModalInfo.minimal_procured_value }} {{EditModalInfo.project.currency.substring(0, 3)}}
                                                        </span>
                                                        </span>

                                        </td>

                                        
                                    </tr>
                                    <tr v-if="EditModalInfo.rccr_type == 'Material'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>  
                                            <!-- This Rates Contract doesn't have a volume discount clause -->
                                            Not Yet Awarded
                                        
                                        </td>


                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Materials Infomation Panel End -->
                            
                            <!-- List of Valid Rates Contracts -->
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr class="text-ogeo text-center">
                                        <th><b class="font-700">List Of Valid Rates’ Contracts For The Item</b></th>
                                    </tr>
                                    <table>
                                        <tr>
                                            <th><b class="font-700">Vendor Name</b></th>
                                            <th><b class="font-700">Country Cities</b></th>
                                            <th><b class="font-700">Unit Rate</b></th>
                                            <th><b class="font-700">Expiry Date</b></th>
                                            <th><b class="font-700">Lead Time</b></th>
                                            <th><b class="font-700">Volume To Trigger Discount</b></th>
                                            <th><b class="font-700">Discount Rate</b></th>
                                            <th><b class="font-700">Total Procured So Far</b></th>
                                            <th><b class="font-700">Advance Payment Arrangement</b></th>
                                            <th><b class="font-700">Retention Payment Arrangement</b></th>
                                        </tr>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index">
                                            <td>{{ item.vendor_name }}</td>
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </table>
                                </table>
                            </div>
                            <!-- List of Valid Rates Contracts End -->

                        </div>

                        <!-- Decision Panel -->
                        <div class="col-lg-12" v-if="!EditModalInfo.status.includes('Rejected')">
                            <hr>
                            <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="RateContractItemRequestValidationForm">
                            <div class="row">
                                <div class="col-lg-12">
                            
                                    <h3 class="text-ogeo">Decision:</h3>
                                    <b class="text-ogeo">A Rejected Line Will Be Sent Back To The Request Initiator Who Can Either Permanently Cancel It From The Materials Rates’ Contract Request, Or Amend Few Details And Resubmit For Validation, As The Case May Be. Only The Expected Required Quantity And The Volume-Discount Arrangement Are Amendable.</b><br><br>   
                                    <span class="text-ogeo">The <i>Account</i>, The Rates’ Contract Type, The Sourcing Priority, The Material Details, The Advance And Retention Payment Arrangements, The Term, The Underlying Transaction & Country/Cities Can’t Be Amended, As They Are Common To All The Lines Of The Materials Rates’ Contract Creation Request.</span><br><br>   
                                    <div class="col-lg-12">
                                        <div class="grid-content">
                                            <el-form-item class="decision" prop="DecisionNotes" >
                                                <el-input
                                                  type="textarea"
                                                  :rows="5"
                                                  placeholder="Enter Decision Notes"
                                                  v-model="ValidationInfo.DecisionNotes">
                                                </el-input>
                                            </el-form-item>
                                        </div> 
                                    </div>

                                    <div class="col-lg-6 float-right">
                                        <button type="button" @click="DecisionApprove" style="background-color: #32CD32; border-color: #32CD32;" class="btn btn-block btn-success waves-effect">Approve</button>
                                    </div>

                                    <div class="col-lg-6 float-left">
                                        <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                    </div>

                                    <!-- <div class="col-lg-12">
                                        <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                    </div> -->
                                        
                                    
                                </div>
                                <br>
                            </div>
                        </el-form>
                            <hr>
                        </div> 
                        <div class="col-lg-12" v-else-if="EditModalInfo.status.includes('Rejected') && !showAmend">
                            <hr>
                            <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="RateContractItemRequestValidationForm">
                            <div class="row">
                                <div class="col-lg-12">
                            
                                    <h3 class="text-ogeo">Decision:</h3>
                                    <b class="text-ogeo">A Rejected Line Can Either Be Altogether Canceled From The Materials Rates’ Contract Request, Or Be Amended And Resubmitted For Validation. Only The Expected Required Quantity And The Volume-Discount Arrangement Are Amendable.</b><br><br>   
                                    <span class="text-ogeo">The <i>Account</i>, The Rates’ Contract Type, The Sourcing Priority, The Material Details, The Advance And Retention Payment Arrangements, The Term, The Underlying Transaction & Country/Cities Can’t Be Amended, As They Are Common To all The Lines Of The Materials Rates’ Contract Creation Request.</span><br><br>   
                                    <div class="col-lg-12">
                                        <div class="grid-content">
                                            <el-form-item class="decision" prop="DecisionNotes" >
                                                <el-input
                                                  type="textarea"
                                                  :rows="5"
                                                  placeholder="Enter Decision Notes"
                                                  v-model="ValidationInfo.DecisionNotes">
                                                </el-input>
                                            </el-form-item>
                                        </div> 
                                    </div>

                                    <div class="col-lg-6 float-right">
                                        <button type="button" @click="showAmend = true" style="background-color: #32CD32; border-color: #32CD32;" class="btn btn-block btn-success waves-effect">Amend Line Information And Resubmit For Approval</button>
                                    </div>

                                    <div class="col-lg-6 float-left">
                                        <button type="button" @click="DecisionRejectPermanent" class="btn btn-block btn-danger waves-effect">Cancel Line From the Material Rates’ Contract Creation Request</button>
                                    </div>

                                    <!-- <div class="col-lg-12">
                                        <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                    </div> -->
                                        
                                    
                                </div>
                                <br>
                            </div>
                        </el-form>
                            <hr>
                        </div>
                        <div class="col-lg-12" v-else>
                            <el-form :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="RateContractRequestForm">

                                <el-row :gutter="20">  


                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <h3>Amend A Rates’ Contract Creation Request Line</h3>
                                            <span class="text-ogeo font-700">Rates’ Contracts are pre-negotiated agreements with 
                                            vendors intended to freeze unit prices and lead times for materials 
                                            (strictly those from the Library of Materials) and services, before the 
                                            demand for them actually arises, so as to reduce cost and workload 
                                            (Purchase Enquiries linked to Rates’ Contracts can be directly turned into 
                                            Purchase Orders). Establishing Rates’ Contract starts with raising a 
                                            formal Rates’ Contract Creation Requests.</span>
                                        </div><br>
                                    </el-col>                     
                                    <el-col :span="24">
                                        <div class="grid-content">

                                                    <el-form-item label="temp" prop="JobNumber" >
                                                    <span slot="label"><b>Select A <i>Account</i></b></span>


                                                <el-select filterable disabled style="width: 100%; padding: 0px;" class="setUp" id="setUp"  @change="getProjectCurrency($event)" v-model="EditModalInfo.project.title" placeholder="Select Set up ">
                                                    
                                                    <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>                      
                                    
                                    <el-col :span="24"><hr></el-col>

                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <el-form-item style="font-weight: bolder" label="temp" prop="RCType">
                                                <span slot="label"><b>Select Rates’ Contract Type</b></span>

                                                <el-select disabled @change="" filterable style="width: 100%; padding: 0px;" v-model="EditModalInfo.rccr_type" placeholder="Select Enquiry Type (Material Or Service)">
                                                        
                                                    <el-option label="Materials" value="Material"></el-option>
                                                    <el-option label="Services" value="Service"></el-option>
                                                        
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="12" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item prop="CompanyCountry">
                                                <span slot="label"><b>Select Country</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                    <span class="tooltip-item2">
                                                        <span class="fa-stack fa-1x">
                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                        </span>
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: 50px">
                                                        <span class="tooltip-text2">Select the country where the materials under the Rates' Contract are needed. (1 country only)</span>
                                                    </span>
                                                </span>
                                                <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.country" placeholder="Select Country"> -->
                                                <el-select filterable disabled collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="EditModalInfo.country" placeholder="Select Country">
                                                    
                                                    
                                                    <el-option
                                                        v-for="(item,CompanyCountry) in countries"
                                                        :key="CompanyCountry"
                                                        :label="CompanyCountry"
                                                        :value="CompanyCountry">
                                                        <span style="float: left">{{ CompanyCountry }}</span>
                                                    </el-option>

                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="12" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item prop="CompanyCities">
                                                <span slot="label"><b>Select Cities</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                    <span class="tooltip-item2">
                                                        <span class="fa-stack fa-1x">
                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                        </span>
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -100px">
                                                        <span class="tooltip-text2">Select the cities where the materials under the Rates' Contract are needed . ( Multiple Cities Possible)</span>
                                                    </span>
                                                </span>
                                                <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                                <el-select filterable disabled collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.cities" placeholder="Select City">
                                                    
                                                    <el-option
                                                        v-for="(item,companyCity) in cities"
                                                        :key="companyCity"
                                                        :value="item"
                                                        :label="item">
                                                    <span style="float: left">{{ item }}</span>
                                                    </el-option>
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <el-form-item prop="SourcingPriority">
                                                <span slot="label"><b>Sourcing Priority</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                        </span>
                                                    </span>
                                                </span>
                                                
                                                <el-select filterable disabled style="width: 100%; padding: 0px;" v-model="EditModalInfo.sourcing_priority" placeholder="">
                                                        
                                                    <el-option label="Standard" value="Standard"></el-option>
                                                    <el-option label="Urgent" value="Urgent"></el-option>
                                                    <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                        
                                                </el-select>

                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="UnderlyingTransaction">
                                                <span slot="label"><b>Rates’ Contract Underlying Transaction Reference</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>The Underlying Transaction Reference</strong> is the reference of the transaction  that has led to the creation of this specific Purchase Enquiry. It can be a particular Job Ticket, a Sales Order, a Work Order…etc
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-input disabled v-model="EditModalInfo.underlying_transaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">

                                            <!-- <el-form-item label="What should be the term, in months,of the requested Rates' Contract?" prop="RateContractItemPeriod"> -->
                                                
                                                <el-form-item label="temp" prop="RateContractItemPeriod">
                                                    <span slot="label"><b>What Will Be The Term, In Months, Of The Requested Rates' Contract?</b></span>
                                                
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"> <span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -120px;">
                                                        <span class="tooltip-text2">
                                                            Rates' Contract signed on the back of this request will be valid for this term, starting from the date of the Rates' Contract implementation.
                                                        </span>
                                                    </span>
                                                </span>
                                                <el-input id="expTerm" disabled class="expTerm"  type="number" oninput="this.value = Math.floor(this.value);" placeholder="Enter Expected term" v-model="EditModalInfo.term">
                                                </el-input>
                                            </el-form-item>

                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="24"  v-if="EditModalInfo.rccr_type == 'Material'">
                                        <div class="grid-content">
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Documents Required From The Vendors</b></span>
                                                    
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            These are the documents that the vendors need to submit along with their commercial proposals, so that their proposals can be assessed.
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-select disabled filterable allow-create collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select Required Document" id="reqDocs">
                                                    
                                                    <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                                    <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                                    <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                                    <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                                    <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                                    <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                                    <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                                    <el-option label="Other Documents" value="Other"></el-option> 
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        
                                    </el-col>
                                    <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                        <div class="grid-content">
                                            <el-form-item label="temp">
                                                <span slot="label"><b></b></span>
                                                <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                                    <thead class="text-white bg-dark w-100">
                                                        <tr style="width: 100% !important;">
                                                            <th><span style="color:white;"></span>Other Documents Required From The Vendors
                                                                <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                        
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                        <span class="tooltip-text2">
                                                                            These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                            Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                            Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                            rights, Litigation...
                                                                        </span>
                                                                    </span>
                                                                </span> -->
                                                            </th>
                                                            
                                                            <th class="text-center" width="70"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in OtherDocTmpHolder"
                                                            :key="index">
                                                            <td>
                                                                <input disabled v-model="item.name" class="form-control">
                                                            </td>
                                                            <td class="text-center">
                                                                <button disabled style="padding: 0px 13px" type="button" @click="delOtherDocs(index)" class="btn-pointer btn-danger btn-floating">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="OtherDocTmp" placeholder="Please Enter Name of the Required Document" maxlength="200">
                                                            </td>
                                                            <td class="text-center my-auto" width="10%">
                                                                <button v-if="OtherDocTmp && itemAdditionFlag =='N'" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0;" @click.prevent="addOtherDocs()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </el-form-item>
                                        </div>

                                    </el-col>
                                    <el-col :span=24 v-if="EditModalInfo.rccr_type"><hr></el-col>
                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Material' || EditModalInfo.rccr_type == 'Service'" >
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="AdvancedPayment">
                                                <span slot="label"><b>Will You Consider Offering Advance Payments For Items Sourced Under The Rates’ Contract?</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            I have the possibility to signal whether or 
                                                            not my organization is willing to make 
                                                            advance payments to the awardee vendor at 
                                                            the time of the items’ Purchase Orders. If “yes” 
                                                            is selected, the vendors will be able to submit,
                                                            in their offers, their desired advance 
                                                            payments, expressed in percentage of their 
                                                            total offer.
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="EditModalInfo.advanced_payment" placeholder="Will You Consider Offering Advance Payments For Items Sourced Under The Rates’ Contract?">
                                                        
                                                    <el-option label="Yes" value="Yes"></el-option>
                                                    <el-option label="No" value="No"></el-option>
                                                        
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="24" v-if="EditModalInfo.AdvancedPayment == 'Yes'" >
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="AdvancedPayment">
                                                <span slot="label"><b>Are Guarantees Required Against The Advance Payments?</b></span>

                                                <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="EditModalInfo.advanced_payment_guarantee" placeholder="Are Guarantees Required Against The Advance Payments?">
                                                        
                                                    <el-option label="Yes" value="Yes"></el-option>
                                                    <el-option label="No" value="No"></el-option>
                                                        
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    <el-col :span=24 v-if="EditModalInfo.rccr_type"><hr></el-col>
                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Material' || EditModalInfo.rccr_type == 'Service'" >
                                        <div class="grid-content">                                    

                                            <el-form-item label="temp" prop="RetentionPercentage">
                                                <span slot="label"><b>Enter The Retention Percentage (If Applicable)</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            Retention is a percentage of the amount certified as due to 
                                                            the awardee vendor, which is temporarily deducted from the amount due and 
                                                            retained by the organization. The purpose of retention is to ensure that the vendors 
                                                            properly complete the activities required of them. This amount will be released back 
                                                            to the vendor at the expiry of the Retention Timeframe.                                                
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-input disabled min='0.0001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="EditModalInfo.retention_percentage">
                                                </el-input>
                                            </el-form-item>

                                        </div>
                                    </el-col> 
                                    
                                    <el-col :span="24" v-if="EditModalInfo.retention_percentage != 0 || EditModalInfo.retention_percentage != ''" >
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="RetentionDays">
                                                <span slot="label"><b>Select Retention Timeframe From The Delivery Date</b></span>
                                                
                                                <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="EditModalInfo.retention_days" placeholder="Select Retention Timeframe From The Delivery Date">
                                                    
                                                    <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>


                                    <el-col :span="24" v-if="EditModalInfo.project.title && EditModalInfo.rccr_type == 'Material'">
                                    <el-form-item label="temp" prop="ItemNumber">
                                                <span slot="label"><b>Select From The Library Of Materials</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                        <span class="tooltip-text2">
                                                            Only Library of Materials’ items which are active for the designated concern are selectable.
                                                        </span>
                                                    </span>
                                                </span>
                                                <el-select style="width: 100%"
                                                @change="getUom()"
                                                    v-model="EditModalInfo.item_id"
                                                    filterable
                                                    remote
                                                    disabled
                                                    :remote-method="searchItems"
                                                    :loading="searchItemsLoading"
                                                    :placeholder="'LoM-'+EditModalInfo.item.item_group_id+'-'+EditModalInfo.item.item_ingroup_id+': '+EditModalInfo.item.description">
                                                    <el-option style="max-width: 1200px; min-height: 150px"
                                                        v-for="item in FoundItems"
                                                        :key="item.id"
                                                        :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                        :value="item.id">
                                                        <div class="row" style="border: 2px solid black">
                                                            <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                            <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                                
                                                            </div>
                                                            <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                                <table class="table itemTable">
                                                                    <tr>

                                                                        <td>
                                                                            <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                            <span style=" white-space: normal; line-height: 1.5em;">
                                                                                <template v-for="index in 20">      
                                                                                    <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                        <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                                    </span>
                                                                                </template>
                                                                            </span><br><br>
                                                                            <span><b class="font-700 text-uppercase">Unit of Measurement:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                        </td>
                                                                    </tr>

                                                                </table>
                                                            </div>
                                                            <div class="col-sm-3 text-wrap mr-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                                <table class="table itemTable lomitemtable">
                                                                    <tr>
                                                                        <table>
                                                                            <tr>
                                                                                <th><b class="font-700">Vendor Name</b></th>
                                                                                <th><b class="font-700">Vendor Score</b></th>
                                                                                <th><b class="font-700">Expiry Date</b></th>
                                                                                <th><b class="font-700">Unit Rate</b></th>
                                                                                <th><b class="font-700">Lead Time</b></th>
                                                                            </tr>
                                                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                                <td>{{ item.vendor_name }}</td>
                                                                                <td>{{ item.vendor_score }}</td>
                                                                                <td>{{ item.date }}</td>
                                                                                <td>{{ item.unit_rate }} USD </td>
                                                                                <td>{{ item.lead_time }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                                <table class="table itemTable lomitemtable">
                                                                    <tr>
                                                                        <table>
                                                                            <tr>
                                                                                <th><b class="font-700">Unit Rate</b></th>
                                                                                <th><b class="font-700"><i>Account</i> Name</b></th>
                                                                                <th><b class="font-700">City</b></th>
                                                                                <th><b class="font-700">Date</b></th>
                                                                                <th><b class="font-700">Quantity</b></th>
                                                                                <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                            </tr>
                                                                            <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                                <td>{{ item.unit_rate }}</td>
                                                                                <td>{{ item.concern }}</td>
                                                                                <td>{{ item.city }}</td>
                                                                                <td>{{ item.date }}</td>
                                                                                <td>{{ item.quantity }}</td>
                                                                                <td>{{ item.pobid }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        
                                                    </el-option>
                                                </el-select>

                                            </el-form-item>

                                            <hr>
                                    </el-col >

                                    <el-col :span="24" v-if="EditModalInfo.item_id && EditModalInfo.project.title && EditModalInfo.rccr_type == 'Material'">
                                        
                                        <div class="grid-content">

                                            <!-- <el-form-item label="Enter the expected needed quantity during the term?" prop="Quantity"> -->

                                                    <el-form-item label="temp" prop="Quantity">
                                                    <span slot="label"><b>Enter The Expected Required Quantity, in {{this.uom ? this.uom.toUpperCase() : EditModalInfo.u_o_m.toUpperCase()}}, During The Term?</b></span>
                                                


                                                <el-input  type="number" placeholder="Enter Expected Required Quantity" v-model="EditModalInfo.quantity">
                                                </el-input>
                                            </el-form-item>

                                        </div>
                                        <hr>
                                    </el-col>


                                    <el-col :span="24" v-if="this.EditModalInfo.project.title && EditModalInfo.rccr_type == 'Material'">
                                        <div class="grid-content" >
                                                    

                                            <el-form-item label="temp" prop="VolumeDiscount">
                                                    <span slot="label"><b>Do You Want To Have A Volume Discount Arrangement Apply To This Item?</b></span>


                                                <span style="z-index: 999" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"> <span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -200px">
                                                        <span class="tooltip-text2">
                                                        A volume-discount clause forces the contracted vendor to discount every Purchase Order, under this Rates' Contract, as soon as a set minimal procured value is reached during the Rates' Contract's term. No discount is applicable when the procured value,
                                                        under the Rates' Contract, is still below the minimal procured value.
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-select filterable collapse-tags class="volume" id="volume" @change="hideShowMin()" style="width: 100%; padding: 0px;" v-model="EditModalInfo.need_volume_disc" placeholder="Select Yes or No">
                                                    
                                                    <el-option label="Yes" value="Yes"></el-option> 
                                                    <el-option label="No" value="No"></el-option> 
                                                
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    <el-col :span=24 v-if="EditModalInfo.rccr_type == 'Material'"><hr></el-col>

                                <transition v-if="EditModalInfo.need_volume_disc == 'Yes'  && EditModalInfo.rccr_type == 'Material'">
                                    <el-col :span="24" >
                                        <!-- <div class="grid-content">  -->

                                                
                                            <el-form-item label="temp" prop="MinimalProcuredValue" class="custominput">
                                                    <span slot="label" class="customLabel"><b>What Should Be The Minimal Procured Value, in {{EditModalInfo.project.currency.substring(0, 3)}}, Which Will Trigger the Item’s Unit Rate’s Discount?</b>
                                                    
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"> <span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: 200px">
                                                        <span class="tooltip-text2">
                                                            A volume-discount clause forces the contracted 
                                                            vendor to discount every Purchase Order for the 
                                                            item, under this Rates' Contract, as soon as a set 
                                                            minimal procured value is reached during the Rates' 
                                                            Contract term. No discount is applicable when the 
                                                            procured value, under the Rates' Contract, is still
                                                            below the minimal procured value.
                                                        </span>
                                                    </span>
                                                </span>

                                                    </span>
                                                
                                                
                                                

                                                <el-input id="minVal" @change="commaprice()" class="minVal" type="text" placeholder="Enter the minimal procured value which wiwill trigger the unit rate’s discount" v-model="EditModalInfo.minimal_procured_value">
                                                
                                                </el-input>
                                            </el-form-item>

                                        <!-- </div> -->
                                    </el-col>

                                </transition>

                                    <!-- ----------------------------------- -->
                                    <button v-if="this.EditModalInfo.project.title && EditModalInfo.rccr_type == 'Material'" type="button" @click="SubmitUpdateItemRateContractRequest" class="btn btn-success btn-block waves-effect text-center">Resubmit The Item With The Materials Rates’ Contract Creation Request</button>
                                    <button v-if="this.EditModalInfo.project.title && EditModalInfo.rccr_type == 'Service'" type="button" @click="SubmitUpdateItemRateContractRequest" class="btn btn-success btn-block waves-effect text-center">Add The Item To The Service Rates’ Contract Creation Request</button>

                                </el-row>
                            </el-form>
                        </div>
                        <!-- Decision Panel End -->
                        
                        <div class="col-lg-12">
                            <hr>
                        </div> 
                        <div class="col-lg-12">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                        <td>{{ item.decision}} - <i>{{item.decision_notes}}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <td v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                        
                                        <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                        <td v-else-if="item.decision == 'Permanently Canceled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="EditModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="EditModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{item.next_approval_level}}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="EditModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="EditModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><!--<button class="btn btn-success ml-3" onclick="document.title = 'Purchase Enquiry Line Information';window.print()">Download PDF</button>-->   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>               
                    </div>
                </div>
            </div>
        </div>


    </div>
            
</template>

<script>
    //import {CustomJs} from '../../helpers/custom.js';
    import validate from 'validate.js';

    export default {
        name: 'purchase-enquiry-list',
        data(){
            return{
                DataTable: "",
                hostName: window.location.protocol+'://'+window.location.hostname,
                switchbutton: 'Material',
                searchItemsLoading: false,
                viewDataLoaded: false,
                editDataLoaded: false,
                projectcurrency: "",
                CompanySetup: "",
                ReqDocTmp: "",
                countries: [],
                cities: [],
                uom: "",
                showAmend: false,
                submitDecision: false,
                FoundItems: {},
                JobNumbers: [],
                ViewModalInfo: {},
                EditModalInfo: {},
                TmpEditModalInfo: {},
                validatorsList: [],
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                ValidationInfo: {
                    ProjectID: "",
                    RCItemRequestId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                rules: {
                    Decision: [{
                        required: true,
                        message: "Please select a approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        methods: {
            changeTable(){
                if(this.switchbutton == 'Service')
                    this.$router.push('/app/rate_contracts/rate_contracts_creation_requests_service_list')
                else
                    this.$router.push('/app/rate_contracts/rate_contracts_creation_requests_list')
            },
            commaprice() {
                this.EditModalInfo.minimal_procured_value = this.EditModalInfo.minimal_procured_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            getProjectCurrency(id)
            {
                
                axios.get('/api/data/get-project-currency/' + id)
                .then((response) => {
                    this.projectcurrency = response.data[0]
                })
            },
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            SubmitUpdateItemRateContractRequest() {
                if (event) event.preventDefault();
                if ( Object.entries(this.EditModalInfo).toString() !== Object.entries(this.TmpEditModalInfo).toString()){
                    Swal({
                        type: 'warning',
                        title: 'Are You Sure You Want To Proceed With The Following Amendment?',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((response) => {
                        if(response.value)
                            this.SubmitChanges();
                    });
                }
                else{
                    Swal({
                        type: 'warning',
                        title: 'No Information Has been Amended. Are You Sure You Want To Proceed?',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        if(confirm.value)
                            this.SubmitChanges();
                    });
                }

            },
            SubmitChanges(){
                let self = this;
                    let selectedItem = this.FoundItems.find(obj => obj.id == this.EditModalInfo.item_id);
                    this.EditModalInfo.item_description = selectedItem.description;
                    this.EditModalInfo.u_o_m = selectedItem.u_o_m;
                    this.EditModalInfo.ItemLibraryDescription = selectedItem;
                    axios.post('/api/rate_contracts/update_rate_contract_item_request', this.EditModalInfo)
                        .then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 10000
                            });

                            self.DataTable.ajax.reload( null, false );
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Item Rate Contract Request Submit)', 'error');
                        });

                        this.showAmend = false;
                    this.$nextTick(() => {
                        $('#RateContractItemRequestValidationModalEdit').modal('toggle');
                        this.DataTable.draw('full-hold');
                    });


            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_rate_contract_creation_requests_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        //this.ViewModalInfo.required_documents_split = this.ViewModalInfo.required_documents.split(",");
                        //console.log(this.ViewModalInfo);
                        this.getProjectCurrency(this.ViewModalInfo.project_id)
                        this.loadValidations(response.data)
                        this.viewDataLoaded = true;
                        $('#RateContractItemRequestValidationModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            showEditModal(elquentClass, recordId){
                this.showAmend = false;
                this.EditModalInfo = {};
                this.ValidationInfo.Decision= "";
                this.ValidationInfo.DecisionNotes= "";
                console.log('edit modal changes');
                axios.post('/api/data/get_rate_contract_creation_requests_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = Object.assign({}, response.data);
                        this.TmpEditModalInfo = Object.assign({}, response.data);
                        this.ReqDocTmp = this.EditModalInfo.required_documents ? this.EditModalInfo.required_documents.split(',') : '';
                        this.EditModalInfo.cities = this.EditModalInfo.cities.split(',');
                        this.ValidationInfo.RCItemRequestId = this.EditModalInfo.id;
                        this.ValidationInfo.ProjectID = this.EditModalInfo.project_id;
                        this.searchItems(' ');
                        this.loadValidations(response.data)
                       // this.ViewModalInfo.required_documents_split = this.ViewModalInfo.required_documents.split(",");
                        this.editDataLoaded = true;
                        $('#RateContractItemRequestValidationModalEdit').modal('toggle');
                    })
                this.editDataLoaded = false;
            },
            searchItems(value) {
                // this.EditModalInfo.item_id = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.EditModalInfo.project.id})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                    })
            },
            getUom()
            {
                     let itemAtHand = this.FoundItems.find(obj => obj.id == this.EditModalInfo.item_id);
                     
                     this.uom = itemAtHand.u_o_m;

                                  
            },
            hideShowMin() {
				console.log(this.RateContractItemLine.VolumeDiscount);
                if(this.RateContractItemLine.VolumeDiscount=='Yes'){
                        $('#min_val').show();
                }else{
                     $('#min_val').hide();
                }

            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfo.Decision = "Approved";
                axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                    });

                    $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                this.rules.DecisionNotes[0].required = false;

            },
            DecisionReject(){

                let self = this;
                this.rules.DecisionNotes[0].required = true;
                this.ValidationInfo.Decision = "Rejected";
                this.$refs.RateContractItemRequestValidationForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                });

                                self.DataTable.ajax.reload( null, false );
                            
                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                            });

                            $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                        this.rules.DecisionNotes[0].required = false;

                    }
                })
            },
            DecisionApproveAmend(){
                let self = this;
                this.ValidationInfo.Decision = "Approved";
                axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                    });

                    $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                this.rules.DecisionNotes[0].required = false;

            },
            DecisionRejectPermanent(){
                Swal({
                    type: "warning", 
                    title: "Are You Sure You Want to Permanently Cancel This Rates' Contract Line ?", 

                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#FF0000",
                    confirmButtonColor: "#32CD32",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.rules.DecisionNotes[0].required = true;
                        this.ValidationInfo.Decision = "RejectedPermanent";
                        this.$refs.RateContractItemRequestValidationForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                                    .then(function(response){
                                        Swal({ 
                                            type: response.data.messageType, 
                                            title: response.data.messageTitle, 
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.DataTable.ajax.reload( null, false );
                                    
                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                                    });

                                    $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                                this.rules.DecisionNotes[0].required = false;

                            }
                        });
                    }
                });
            },
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        mounted(){
            var groupColumn = 1;
             //$.noConflict();
           //  console.debug('foo2');


            axios.get('/api/data/get_company_details')
                .then((SecondResponse) => {

                    this.CompanySetup = SecondResponse.data;

                });

            setTimeout(() => {
             this.DataTable = $('#NewRateContractItemRequestsApprovals').DataTable({
                fixedHeader: {
                    header: true,
                    headerOffset: 117
                },
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_rate_contract_creation_requests_list',
                columns: [
                    { data: 'show_id', name: 'show_id' },
                    { data: 'rate_contract_request_group_id', name: 'rate_contract_request_group_id' },
                    { data: 'project.title', name: 'project.title' },
                    { data: 'creator.name', name: 'creator.name' },
                    { data: 'complete_description_with_headers', render: function(data, type, full){
                        if(data == null){
                            return '';
                        }else{
                                let dec = data.substring(0, 250)+'...';
                                return '<span style="z-index: 0" class="mytooltip tooltip-effect-1"><span class="tooltip-item3" style="z-index: -1"><span class="">'+dec+'</span></span><span class="tooltip-content4 clearfix" style="width: 35vh"><span class="tooltip-text4">'+data+'</span></span></span>';
                            }
                    }},
                    { data: 'quantity', render: function (data, row, full){
                        return data;
                    }  },
                    { data: 'item.u_o_m', render: function (data, row, full){
                        return data.toUpperCase();
                    }, orderable: false, searchable: false },
                    { data: 'validationList', name: 'validationList' },
                    
                    { data: 'awardee_vendor',render: function (data, row, full){
                        if(data)
                            return data;
                        else
                            return "Not Yet Awarded";
                    } },
                    { data: 'required_documents',render: function (data, row, full){
                        if(data)
                            return data;
                        else
                            return "N/A";
                    } },
                    { data: 'countryCities', name: 'countryCities' },
                    { data: 'term', render: function (data, row, full){
                        if(data)
                            return data + " Months";
                        else
                            return "N/A";
                    }  },
                    { data: 'underlying_transaction', render: function (data, row, full){
                        if(data)
                            return data;
                        else
                            return "N/A";
                    }  },
                    { data: 'need_volume_disc', name: 'need_volume_disc' },
                    { data: 'minimal_procured_value', render: function (data, row, full){
                        if(full.need_volume_disc == 'Yes')
                            if (data)
                                return data + " " + full.project.currency.substring(0, 3);
                            else
                                return 'Not Applicable'
                        else
                            return "This Item Doesn't Have A Volume Discount Arrangement."
                    } },
                    { data: 'advanced_payment', render: function (data, row, full){
                        if(data == 'Yes')
                            if(full.advanced_payment_guarantee == 'Yes')
                                return 'Yes | Guarantees Against The Advanced Payment Will Be Required.'
                            else
                                return 'Yes | No Guarantees Against The Advanced Payment Will Be Required.'
                        else
                            return 'No'    

                    } },
                    { data: 'retention_percentage', render: function (data, row, full){
                        if(data > 0)
                            return data +'% | '+full.retention_days+' Day(s) From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order.'
                        else
                            return "Not Applicable";
                    } },
                    { data: 'level_of_validations', name: 'level_of_validations', orderable: false, searchable: false },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'status', name: 'status' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                columnDefs: [
                    { visible: false, targets: groupColumn },
                    { width: '40%', targets: 4 },
                    { responsivePriority: 1, targets: 21 },
                    { responsivePriority: 2, targets: 20 },
                    { responsivePriority: 3, targets: 0 },
                ],
                fixedColumns: true,
                dom: '<"html5buttons">Brfgtip',
                pageLength: 8,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ groupColumn, 'ASC' ]],
                "drawCallback": function ( settings ) {
                    var api = this.api();
                    var rows = api.rows( {page:'current'} ).nodes();
                    var last=null;
         
                    api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {
                        if ( last !== group ) {
                            $(rows).eq( i ).before(
                                '<tr class="group"><td colspan="12">Rates’ Contract Creation Request ID# RCCR-'+group+'</td></tr>'
                            );
         
                            last = group;
                        }
                    } );
                },
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'RateContractRequests' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                }, 
                "rowCallback": function( row, data, index ) {
                    if(data.action.indexOf('edit-placeholder') !== -1){
                        $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                    } 
                    // else if (data.status.indexOf('Resubmitted and Assigned to') !== -1) {
                    //     $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                    // } else if (data.status.indexOf('Sent for Sourcing') !== -1) {
                    //     $(row).css('background-color', 'rgb(69, 249, 132, 0.1)')
                    // } else if (data.status.indexOf('Rejected by ') !== -1) {
                    //     $(row).css('background-color', 'rgb(249, 69, 72, 0.08)')
                    // } else {
                    //     // $(row).css('background-color', 'rgb(249, 69, 237, 0.1)')
                    // }
                } 
            });

            const self = this;

            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $(this).data('recordid');
                self.showViewModal(elquentClass, RecordID);
            });

            $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $(this).data('recordid');
                self.showEditModal(elquentClass, RecordID);
            });

        }, 1000);
        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
        
    }

</script>

<style>
.lomBold {
    font-weight: 500;
}
.decision label.el-form-item__label {
    color: red !important;
    font-weight: 700;
    font-size: 1.575rem;
   }
</style>
<style scoped>
.lomBold {
    font-weight: 500;
}
.sptable tr td {
    border: none;
    padding-top: 5px;
    padding-bottom: 0px;
}


@media print {
  html body * {
    visibility: hidden;
  }
    #PrintHidden {
        display: none;
    }
  #RateContractItemRequestValidationModalView, #RateContractItemRequestValidationModalView * {
    visibility: visible;
    overflow: visible;
  }
  #RateContractItemRequestValidationModalView::-webkit-scrollbar { width: 0 !important }
  #RateContractItemRequestValidationModalView:last-child {
     page-break-after: auto;
    }
  #RateContractItemRequestValidationModalView:last-child {
     page-break-before: none;
    }

  #RateContractItemRequestValidationModalView {
    position: relative;
    margin-top: -500mm;
    -ms-overflow-style: none;

  }

}

    .font-500{
        font-weight: 500;
    }

    #RateContractItemRequestValidationModalView .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    #RateContractItemRequestValidationModalEdit .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

</style>