<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Initiate A <i>Request to Update the Library of Materials</i></h4>
                </div>
                <div class="card-body">
                    <el-form :model="ItemCreationInfo" class="demo-form-inline" :rules="rules" ref="ItemCreationRequestForm">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <div class="alert alert-info" style="background-color: rgb(252, 51, 23, 0.025); color: #111111;">
                                    <p style="color: #111111;font-size: 16px;">
                                    <i class="fa fa-comment"></i> Regardless of its type (<b>Adding an item to the <i>Library of Materials</i></b>; 
                                    <b>Inactivating an active item</b>; or <b>Reactivating an inactive item</b>), a <i>Request To Update The Library of Materials</i>, once initiated, 
                                    is sent for review, to the users holding the <u><i>Library of Material Administrator</i></u> role, for the selected <i>Account</i>.
                                    </p>
                                    <p style="color: #111111;font-size: 16px;">
                                    If approved by a <u><i>Library of Material Administrator</i></u>, the request is routed to the users holding the <u><i>Library of Material Validator</i></u> role for this <i>Account</i>, 
                                    for review: If fully validated, the <i>Library of Materials</i> will formally be updated.
                                    </p><hr>
                                    <p style="color: rgb(252, 51, 23);font-size: 16px;">
                                    <i class="fa fa-exclamation-triangle"></i> <b>Adding items to the <i>Library of Materials</i> is the first step towards generating 
                                    <i>Materials Rates' Contracts</i>, which are paramount to reducing costs and turnaround times, when sourcing <i>Materials</i></b>.
                                    </p>
                                </div>
                            </el-col>

                            <transition v-if="CompanyDetails.setup_mode == 'Project Wise'">
                                <el-col :span="24">
                                    <div class="grid-content">
                                        <!--<i class="fa fa-exclamation-circle"></i> Please click the button to play the tutorial video, if you need any guidance about adding any of the required information below.-->
                                        
                                        <el-form-item label="temp" prop="ProjectId">
                                            <span slot="label"><b>Select an <i>Account</i></b></span>
                                            <el-select filterable style="width: 100%; padding: 0px;" v-model="ItemCreationInfo.ProjectId" placeholder="Select an Account for which you hold the Library of Material Update Request Initiator role">
                                                <el-option v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId">{{ JobNumber }}</el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </transition>

                            <el-col :span="24"><hr v-if="ItemCreationInfo.ProjectId" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                            <transition v-if="CompanyDetails.setup_mode == 'Project Wise'">
                                
                                <el-col :span="24" v-if="this.ItemCreationInfo.ProjectId">
                                    <div class="grid-content">
                                        <el-form-item style="font-weight: bolder" label="temp" prop="ItemStatus">
                                            <span slot="label"><b>What would you like to do ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
														</span>
                                                        
                                                        
                                                        <!--<span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                
                                                        </span>-->
                                                        <span style="bottom: -20px; left: 175px; width: 700px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>You have the possibility to request that:<br> 
                                                                    <br>1. An item is added to the <i>Library of Materials</i>; 
                                                                    <br>2. An active item is inactivated solely for this <i>Account</i>; and 
                                                                    <br>3. An inactive item is reactivated solely for for this <i>Account</i>. 
                                                                    <hr> When a new item is added to the <i>Library of Materials</i>, it is initially 
                                                                    active only for the <i>Account</i> under which it was added. However, users 
                                                                    holding the <i>Library of Materials Update Requests Initiator</i> role, for other <i>Accounts</i>, 
                                                                    have the option to initiate requests for activating the 
                                                                    newly added <i>Library of Materials</i> item for their own respective <i>Accounts</i>.
                                                                    <hr>It won't be possible 
                                                                    to create <i>Purchase Enquiries</i> or <i>Rates' Contracts</i>, for an <i>Account's</i> inactivated items.
                                                                    </strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                            <el-select filterable style="width: 100%; padding: 0px;" @change="EmptyFoundItems()" v-model="ItemCreationInfo.ItemStatus" placeholder="Choose 1 out of 3 possible options">
                                                <!--<el-option label="1. Add an item to the Library of Materials" value="add_item"> : style="{ fontStyle: 'italic' }"</el-option>-->
                                                <el-option label="1. Add an item to the Library of Materials" value="add_item" :style="{ fontStyle: 'italic' }"></el-option>
                                                <el-option label="2. Inactivate an active item of the Library of Materials" value="inactivate_item"></el-option>
                                                <el-option label="3. Reactivate an inactive Item of the Library of Materials" value="reactivate_item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </transition>

                            <el-col :span="24"><hr v-if="ItemCreationInfo.ItemStatus" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24" v-if="this.ItemCreationInfo.ItemStatus">
                                <div class="grid-content" v-if="ItemCreationInfo.ItemStatus == 'inactivate_item' || ItemCreationInfo.ItemStatus == 'reactivate_item'">
                                    <el-form-item label="temp">
                                        <span slot="label"><b>Select an item from the <i>Library of Materials</i></b></span>
                                        <el-select style="width: 100%"
                                            v-model="ItemCreationInfo.LOM"
                                            filterable
                                            remote
                                            :remote-method="searchItems"
                                            :loading="searchItemsLoading"
                                            placeholder="Enter a keyword & select the Library of Materials item you want to inactivate/reactivate for this Account">
                                            <el-option style="max-width: 674.656px; max-height: 150px"
                                                v-for="item in FoundItems"
                                                :key="item.id"
                                                :label="'LOM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description.replaceAll(':', ': ')"
                                                :value="item.id">
                                                <div class="row">
                                                    <div class="col-sm-2 b-r" style="padding-left: 0px; padding-top: 5px; padding-buttom: 0px; padding-right: 0px;" >
                                                       <img  class="img-thumbnail vertical-middle" :src="'/uploads/ItemMasterPictures/'+item.picture">
                                                        
                                                    </div>
                                                    <div class="col-sm-5 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                        <table class="table itemTable">
                                                            <tr>
                                                                <td width="50%"> <span style="text-transform: uppercase" v-if="item.company.lom_prefix == null || item.company.lom_prefix == ''">LoM</span><span v-else>{{ item.company.lom_prefix }}</span>-{{ item.item_group_id  }}-{{ item.item_ingroup_id  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" style="text-transform: uppercase"><b>{{ item.item_template.field_1.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%"> {{ item.field_1.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" style="text-transform: uppercase"><b>{{ item.item_template.field_2.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%">{{ item.field_2.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" style="text-transform: uppercase"><b>{{ item.item_template.field_3.substring(0, 20) + '...'  }}:</b></td>
                                                                <td width="50%">{{ item.field_3.substring(0, 20) + '...'  }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><b>Rate Contract:</b></td>
                                                                <!-- <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)"> View Rates</td> -->
                                                                <td width="50%" style="cursor: pointer;" class="text-primary" @mouseover="showRateContract(item.id)">
                                                                    <el-tooltip placement="top-start" effect="light">
                                                                        <div slot="content">
                                                                            <div v-if="RateContractDataLoaded">
                                                                                <table style="min-width: 500px" class="table table-bordered">
                                                                                    <thead>
                                                                                        <td align="center"><b>Price</b></td>
                                                                                        <td align="center"><b>Lead Time</b></td>
                                                                                        <td align="center"><b>Contract End Date</b></td>
                                                                                    </thead>
                                                                                    <tbody v-if="ItemRateContractDetails.length > 0">
                                                                                        <tr v-for="(RateContract, key, index) in ItemRateContractDetails" :key="index">
                                                                                            <td align="center">{{ formatPrice(RateContract.vendor_responses[0].unit_rate) }} {{ RateContract.vendor_responses[0].currency }}</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].lead_days }} Day(s)</td>
                                                                                            <td align="center">{{ RateContract.vendor_responses[0].rate_contract_request.rc_end_period.substring(0, 10) }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                    <tbody v-else>
                                                                                        <tr>
                                                                                            <td align="center" colspan="3"> NO CONTRACT AVAILABLE </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div align="center" v-else>
                                                                                <h2>Gathering Data...</h2>
                                                                                <img align="center" :src="'/assets/images/ajax-loader.gif'">
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <i style="line-height: 0px">View Rates</i>
                                                                    </el-tooltip>

                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div class="col-sm-3 text-wrap" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word;">
                                                        <div>
                                                            <h5><b>Item Description:</b></h5>
                                                            <p style="word-wrap: break-word;">{{ item.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </el-option>
                                        </el-select>

                                    </el-form-item>
                                </div>

                                <div class="grid-content" v-if="ItemCreationInfo.ItemStatus == 'add_item'">
                                    <el-form-item label="temp" prop="ItemDescription">
                                        <span slot="label"><b>Describe the item which you would like to add to the <i>Library of Materials</i></b></span>

                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
														</span>
                                            <span style="bottom: -15px; left: 175px" class="tooltip-content4 clearfix">
                                                <span class="tooltip-text2">
                                                    The description shall be detailed enough to enable a proper presentation of the new item, at the time of its addition to the <i>Library of Materials</i>.
                                                </span>
                                            </span>
                                        </span>
                                        
                                        <el-input
                                            type="textarea"
                                            :rows="4"
                                            placeholder="A detailed description of the item, that you would like to add to the Library of Materials, is needed here."
                                            v-model="ItemCreationInfo.ItemDescription">
                                        </el-input>
                                        
                                    </el-form-item>
                                </div>
                                <el-col :span="24"><hr v-if="ItemCreationInfo.ItemStatus" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                <div class="grid-content" v-if="ItemCreationInfo.ItemStatus == 'add_item'">
                                    <el-form-item label="temp" prop="ItemLink">
                                        <span slot="label"><b>Provide a link, if available, that describes the item you wish to add to the <i>Library of Materials</i></b></span>
                                        <el-input v-model="ItemCreationInfo.ItemLink" placeholder="https://www.itemsinfo.com/itemlink/232/wsd"></el-input>
                                    </el-form-item>
                                </div>

                                <el-col :span="24"><hr v-if="ItemCreationInfo.ItemStatus" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                <div style="width: 100%" class="grid-content" v-if="ItemCreationInfo.ItemStatus == 'add_item'">
                                    <el-form-item style="margin-bottom: 0px;" label="temp" prop="ItemDocuments">
                                        <span slot="label"><b>Upload any documents that help better describe the item you wish to add to the <i>Library of Materials</i></b></span>
                                    </el-form-item>

                                    <el-upload
                                        class="upload-demo"
                                        id='UploadDocuments'
                                        :auto-upload="false"
                                        ref="UploadDocuments"
                                        multiple
                                        action="#"
                                        :limit="3"
                                        :on-exceed="handleExceed"
                                        :file-list="ItemCreationInfo.ItemDocuments"
                                        >

                                        <button type="button" slot="trigger" class="btn btn-success">Select & Upload File(s)</button>
                                      
                                        <div class="el-upload__tip" slot="tip">All file types are accepted. File size limited to 3Mb. No more than 3 files.</div>
                                    </el-upload>
                                    

                                </div>

                            </el-col>


                            <el-col :span="24"><hr v-if="ItemCreationInfo.ItemStatus" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                            <el-col :span="24">
                                
                                
                                <button type="button" v-if="ItemCreationInfo.ItemStatus == 'add_item'" @click="SubmitItemCreationRequest" class="btn btn-block btn-ogeo waves-effect waves-light">Initiate a <i>Request to Add an Item to The Library of Materials</i></button>
                                <button type="button" v-if="ItemCreationInfo.ItemStatus == 'inactivate_item'" @click="SubmitItemCreationRequest" class="btn btn-block btn-ogeo waves-effect waves-light">Initiate a <i>Request to Inactivate an Active Item of The Library of Materials</i></button>
                                <button type="button" v-if="ItemCreationInfo.ItemStatus == 'reactivate_item'" @click="SubmitItemCreationRequest" class="btn btn-block btn-ogeo waves-effect waves-light">Initiate a <i>Request to Reactivate an Inactive Item of The Library of Materials</i></button>
                            </el-col>

                        </el-row>
                    </el-form>

                </div>
            </div>
            
        </div>
    </div>
            
</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'new-item-creation-request',
        data(){

            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === ''){
                        callback();
                    } else {
                        callback(new Error('Please enter a valide URL. (e.g. https://ogeo.me/example)'));
                    }
                    
                }
            };

            return{
                CompanyDetails: {},
                JobNumbers: {},
                ItemCreationInfo: {
                    ProjectId: "",
                    ItemDescription: "",
                    ItemLink: null,
                    ItemDocuments: [],
                    LOM: '',
                    ItemStatus: ''
                },
                searchItemsLoading: false,
                FoundItems: {},
                ItemRateContractDetails: [],
                rules: {
                    ProjectId: [{
                        required: true,
                        message: "Please select an <i>Account</i>",
                        trigger: ["blur"]
                    }],
                    ItemStatus: [{
                        required: true,
                        message: "Please select a <i>Request To Update The Library of Materials</i> type",
                        trigger: ["blur"]
                    }],
                    ItemDescription: [{
                        required: true,
                        message: "Please enter a description of the item",
                        trigger: "blur"
                    }],
                    ItemDocuments: [{
                        required: false,
                        message: "Please upload at least one document",
                        trigger: "blur"
                    }],
                    ItemLink: [{
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                }
            }
        },
        methods: {
            EmptyFoundItems(){
                this.FoundItems = [];
            },
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            showRateContract(ItemId){
                event.preventDefault();
                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                .then((response) => {
                    this.ItemRateContractDetails = response.data;
                    this.RateContractDataLoaded = true;
                })
                .catch(function(){
                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                });
            },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: this.ItemCreationInfo.ItemStatus, project_id: this.ItemCreationInfo.ProjectId})
                    .then((response) => {
                        if(response.data.length > 0){
                            this.FoundItems = response.data;
                        }
                        this.searchItemsLoading = false;
                    })
            },

            SubmitItemCreationRequest: function(){

                let self = this;

                if (event) event.preventDefault();

                this.$refs.ItemCreationRequestForm.validate((validation) => {

                    if(validation){


                        //Validate File Size
                        /*const isJPG = file.type === 'image/jpeg';
                        const isLt2M = file.size / 1024 / 1024 < 2;

                        if (!isJPG) {
                          this.$message.error('Avatar picture must be JPG format!');
                        }
                        if (!isLt2M) {
                          this.$message.error('Avatar picture size can not exceed 2MB!');
                        }
                        return isJPG && isLt2M;*/


                        let formData = new FormData();
                        if(this.ItemCreationInfo.ItemStatus == 'add_item'){
                        for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                            let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                            formData.append('FileUploads['+i+']', file);
                        }
                        formData.append('ItemLink', this.ItemCreationInfo.ItemLink);
                        formData.append('ItemDescription', this.ItemCreationInfo.ItemDescription);
                        }
                        formData.append('ProjectId', this.ItemCreationInfo.ProjectId);
                        formData.append('lom', this.ItemCreationInfo.LOM);
                        formData.append('itemStatus', this.ItemCreationInfo.ItemStatus);

                        axios.post( '/api/users/new_item_creation_request',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 5000
                            });

                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                        });

                        this.ItemCreationInfo = {
                            ProjectId: "",
                            ItemDescription: "",
                            ItemLink: "",
                            ItemDocuments: [],
                            LOM: '',
                            ItemStatus: ''
                        };

                        if(this.CompanyDetails.setup_mode == 'Company Wide'){
                            this.ItemCreationInfo.ProjectId = Object.keys(this.JobNumbers)[0];
                        }

                    } else {

                    }

                });
                
            },
            handleExceed(files, fileList) {
                Swal('The Limit is 3 Files', 'The limit is 3 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')
                
            }
        },
        mounted(){

            axios.get('/api/data/get_user_projects_list_almr')
                .then((FirstResponse) => {
                    this.JobNumbers = FirstResponse.data;
                    axios.get('/api/data/get_company_details')
                    .then((SecondResponse) => {
                        this.CompanyDetails = SecondResponse.data;
                        if(this.CompanyDetails.setup_mode == 'Company Wide'){
                            this.ItemCreationInfo.ProjectId = Object.keys(this.JobNumbers)[0];
                        }
                    });
                });

        },
        beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));
            
            if(currentUser.hasOwnProperty('ALMR')){
                next();
            } else if (currentUser.hasOwnProperty('LMA') || currentUser.hasOwnProperty('ALMV')) {
                next('/app/items_master/requests_list');
            } else {
                next('/app/dashboard');
            }
            
        }
    }

</script>