<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Main Dashboard</h4>
                </div>
                <div class="card-body">
                    <p>Main Dashboard List Will Be Here...</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import validateJs from 'validate.js';

    export default {
        name: "main-dashboard",
        data() {
            return {

            }
        },
        computed: {
            
        },
        methods: {

        },
        mounted(){
            
        }
    }
</script>