<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Create Demand To Vendors</h4>
                </div>
                <div class="card-body">
                    <el-form :model="DemandCreationInfo" class="demo-form-inline" :rules="rules" ref="DemandCreationForm">

                        <el-row :gutter="24">

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VendorID">
                                        <span slot="label"><b>Select A Qualified, Active Vendor:</b></span>
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="DemandCreationInfo.VendorID" placeholder="Select A Qualified, Active Vendor">
                                            <el-option v-for="vendor in VendorsList" :key="vendor.id" :label="vendor.name" :value="vendor.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="VQReqDocs">
                                        <span slot="label"><b>Select The Documents Which The Vendors Must Submit Through A ”Demand To Organization”</b></span>
                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="DemandCreationInfo.VQReqDocs" placeholder="Select The Documents">
                                            <el-option v-for="item in VQReqDocsList" :key="item.id" :label="item.document_name" :value="item.document_name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24">
                                <div class="grid-content">
                                    <el-form-item label="temp" prop="FreeText">
                                        <span slot="label"><b>Please Enter A Message:</b></span>
                                        <el-input v-model="DemandCreationInfo.FreeText" placeholder="Please Enter A Message"
                                            ></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>

                            <el-col :span="24">
                                <hr>
                                <button type="button" @click="SubmitDemandCreationForm" class="btn btn-success btn-block waves-effect text-center">Create New Demand To The Vendor(s)</button>
                            </el-col>

                        </el-row>
                    </el-form>

                </div>
            </div>

        </div>

        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>List Of Demands To Vendors</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="DemandsToVendorsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Message ID</th>
                                <th>Issued On</th>
                                <th>Issued By</th>
                                <th>Vendor Name & Alias</th>
                                <th>Requested Qualificatiion Documents</th>
                                <th>Free Text Question</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import validate from 'validate.js';

    export default {
        name: 'DemandsToVendors',
        data(){
            return{
                DataTable: "",
                viewDataLoaded: false,
                ViewModalInfo: {},
                VendorsList: {},
                VQReqDocsList: {},
                SIMalert: false,
                SBMalert: false,
                DemandCreationInfo: {
                    VendorIDs: "",
                    ReqDocs: "",
                    FreeText: "",
                },
                rules: {
                    VendorID: [{
                        required: true,
                        message: "Please Select Vendors",
                        trigger: ["blur"]
                    }],
                    VQReqDocs: [{
                        required: true,
                        message: "Please Select Documents",
                        trigger: "blur"
                    }],
                    FreeText: [{
                        required: false,
                        message: "Please Enter A Message",
                        trigger: "blur"
                    }],
                }
            }
        },
        methods: {
            SubmitDemandCreationForm: function(){

                let self = this;

                this.$refs.DemandCreationForm.validate((validation) => {

                    if(validation){

                        axios.post( '/api/data/create_new_demand_to_vendors', this.DemandCreationInfo)
                            .then(function(response){
                                                Swal.fire({
                                                    type: response.data.messageType,
                                                    title: response.data.messageTitle,
                                                    text: response.data.message,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Ok',
                                                    timer: 15000
                                                })
                                                self.$refs.DemandCreationForm.resetFields();
                                                self.DataTable.ajax.reload( null, false );
                                            });
                    }

                });

            },
        },
        mounted(){

            const self = this;

            axios.get('/api/data/get_vendors_list')
                    .then((response) => {
                        this.VendorsList = response.data;
                    });
            axios.get('/api/vendors_management/get_vendor_qualification_req_docs')
                .then((response) => {
                    this.VQReqDocsList = response.data;
                })
            const table = $('#DemandsToVendorsList').DataTable({
                fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_demands_to_vendors_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'created_at', name: 'Created At', orderable: false, searchable: false},
                    { data: 'requester.name', name: 'Issued By', orderable: false, searchable: false},
                    { data: 'vendor', render: function(data) {
                        return data.name + ' ('+ (data.vendor_alias ? data.vendor_alias : 'N/A')  +')'
                    }},
                    { data: 'req_docs', name: 'Required Documents' },
                    { data: 'free_text', name: 'Free Text Question' },

                ],
                columnDefs: [
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 0, "ASC" ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'ItemMasterList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
            });


            this.DataTable = table;



        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>

<style scoped>

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .el-input__inner {
        height: 40px !important;
    }

    .itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }

    .el-select-dropdown__item{
        height: 100% !important;
    }

    .el-select-dropdown__wrap{
        max-height: 800px !important;
        max-width: 100% !important
    }

    .el-select-dropdown .el-popper {
        max-width: 700px !important
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


</style>
