<template>
	
	<div class="row">
    
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Errors Log List (User Generated)</h4>
                </div>
                <div class="card-body">
                    <table ref="ErrorsTable" id="ErrorsTable" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Level</th>
                                <th>Level Name</th>
                                <th>User Agent</th>
                                <th>Generated By</th>
                                <th>Generator Name</th>
                                <th>Resolution Status</th>
                                <th>Occured At</th>
                                <th style="min-width: 95px">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>


        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Errors Log List (Not User Generated)</h4>
                </div>
                <div class="card-body">
                    <table ref="ErrorsTableNoneUser" id="ErrorsTableNoneUser" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Level</th>
                                <th>Level Name</th>
                                <th>User Agent</th>
                                <th>Resolution Status</th>
                                <th>Occured At</th>
                                <th style="min-width: 95px">Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>


        <div id="ViewErrorDetailsModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ViewErrorDetailsModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <h4 class="modal-title">Error ID: {{ ViewModalInfo.id }}</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4 class="m-b-10 m-t-10">User Agent:</h4><span>{{ ViewModalInfo.user_agent }}</span><br>
                                <h4 class="m-b-10 m-t-10">Level:</h4><span>{{ ViewModalInfo.level }}</span><br>
                                <h4 class="m-b-10 m-t-10">Level Name:</h4><span>{{ ViewModalInfo.level_name }}</span><br>
                                <h4 class="m-b-10 m-t-10">Error Message:</h4><span>{{ ViewModalInfo.message }}</span><br>
                                <h4 class="m-b-10">URI:</h4><span>{{ ViewModalInfo.uri }}</span><br>
                                <h4 class="m-b-10 m-t-10">Error File Location:</h4><span>{{ ViewModalInfo.error_file_location }}</span><br>
                                <h4 class="m-b-10 m-t-10">Error Line Location:</h4><span>{{ ViewModalInfo.error_line_location }}</span><br>
                                <h4 class="m-b-10 m-t-10">Posted Data:</h4><span>{{ ViewModalInfo.posted_data }}</span><br>
                                <h4 class="m-b-10  m-t-10">Formated Error:</h4><span>{{ ViewModalInfo.formatted_error }}</span><br>
                                <h4 class="m-b-10 m-t-10">Context:</h4><span>{{ ViewModalInfo.context }}</span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

	import validateJs from 'validate.js';

	export default {
		name: "system-errors-log",
		data() {
			return {
                DataTable: "",
				DataTableNoneUserGenerated: "",
				viewDataLoaded: false,
				ViewModalInfo: {},
			}
		},
		methods: {
			showViewModal(recordId){
                this.ViewModalInfo = {};
                axios.post('/api/system_management/get_error_log_details', {recordId: recordId})
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        this.viewDataLoaded = true;
                        $('#ViewErrorDetailsModal').modal('toggle');
                    })

                this.viewDataLoaded = false;  
            }
		},
		mounted(){

            const self = this
            
			const table = $('#ErrorsTable').DataTable({
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/system_management/get_error_log_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'level', name: 'level' },
                    { data: 'level_name', name: 'level_name' },
                    { data: 'user_agent', name: 'user_agent' },
                    { data: 'creator_type', name: 'creator_type' },
                    { data: 'name', name: 'name' },
                    { data: 'resolve_status', name: 'resolve_status'},
                    { data: 'created_at', name: 'created_at' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 1, 'ASC' ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'FAQsList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                } 
            });


            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');
                self.showViewModal(RecordID);
            });

            self.DataTable = table;


            $('tbody', this.$refs.ErrorsTable).on('click', '.resolveErrorLog', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/resolve_error_log', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });


            $('tbody', this.$refs.ErrorsTable).on('click', '.unableToReplicate', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/unable_to_replicate_error', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });


            $('tbody', this.$refs.ErrorsTable).on('click', '.deleteErrorLog', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/delete_error_log', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });






            /* Error Log None User Generated */
            const tableNoneUser = $('#ErrorsTableNoneUser').DataTable({
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/system_management/get_error_log_none_user_generated_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'level', name: 'level' },
                    { data: 'level_name', name: 'level_name' },
                    { data: 'user_agent', name: 'user_agent' },
                    { data: 'resolve_status', name: 'resolve_status'},
                    { data: 'created_at', name: 'created_at' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 1, 'ASC' ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'FAQsList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                } 
            });


            $('tbody', this.$refs.ErrorsTableNoneUser).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');
                self.showViewModal(RecordID);
            });

            self.DataTableNoneUserGenerated = tableNoneUser;


            $('tbody', this.$refs.ErrorsTableNoneUser).on('click', '.resolveErrorLog', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/resolve_error_log', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTableNoneUserGenerated.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });


            $('tbody', this.$refs.ErrorsTableNoneUser).on('click', '.unableToReplicate', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/unable_to_replicate_error', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTableNoneUserGenerated.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });


            $('tbody', this.$refs.ErrorsTableNoneUser).on('click', '.deleteErrorLog', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');

                axios.post('/api/system_management/delete_error_log', {recordId: RecordID})
                    .then((response) => {
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 2000
                        });

                        self.DataTableNoneUserGenerated.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                    });
            });
			
		}
	}
</script>


<style>
	
	.el-select-dropdown__wrap {
        max-height: 1000px !important;
    }
    
    .font-500{
        font-weight: 500;
    }

    #ViewErrorDetailsModal .modal-dialog {
        min-width:80%;
    }

</style>