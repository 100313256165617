<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 style="display: contents;"><b><i>Services Rates’ Contract Creation Requests</i> List</b></h4>
                    <div class="text-ogeo float-right font-500">
                        <el-switch style="margin-top:0px" v-model="switchbutton" @change="changeTable()" active-color="rgb(255, 47, 47)" inactive-color="rgb(255, 47, 47)" active-text="Materials" inactive-text="Services" active-value="Material" inactive-value="Service"></el-switch>
                    </div>
                </div>
                <div class="card-body">
                    <table ref="table" id="NewRateContractItemRequestsApprovals" class="display responsive table table-bordered table-hover" style="width: 100%;" >
                        <thead>
                            <tr>
                                <th><i>Request</i> ID#</th>
                                <th>GroupID</th>
                                <th><i>Account</i> <br>Name</th>
                                <th>Initiated By</th>
                                <th><i>Services Rates’ Contract</i> Title</th>
                                <th><i>Service Line <br>Unit of Measure</i></th>
                                <th>Sourcing Priority</th>
                                <th><i>Rates’ Contract</i> <br>Underlying Transaction</th>
                                <th><i>Rates’ Contract</i> <br>Type</th>
                                <th><i>Rates’ Contract</i> <br>Term</th>
                                <th>Country | Cities</th>
                                <th><i>Advance Payment</i> <br>Arrangement</th>
                                <th><i>Retention </i> <br>Arrangement</th>
                                <th><i>Instruction To Vendors</i> <br>Headers</th>
                                <th><i>Vendors Proposal Content</i> <br>Headers</th>
                                <th><i>Services Rates’ Contract</i> <br>Template Headers</th>
                                <th>List of Validators</th>
                                <th>Current Level <br>of Validation</th>
                                <th>Initiated On</th>
                                <th>Last Updated On</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="RateContractItemRequestValidationModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalView" aria-hidden="true" style="display: none;"> 
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo">Services Rates’ Contract Creation Request Line ID# {{ ViewModalInfo.rate_contract_request_group_id}} - {{ViewModalInfo.rate_contract_request_ingroup_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            
                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Account</i> Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ ViewModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ ViewModalInfo.term }} Months</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.country }} | {{ ViewModalInfo.cities.toString()}}</span><br>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12" v-if="ViewModalInfo.rccr_type == 'Service'">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Services Rates' Contract?</b></td>
                                        <td>{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage + '%' : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <!-- <tr align="center" v-if="ViewModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in ViewModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Description: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.item_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendor_instruction_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Vendor offer Content</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.vendor_offer_content">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 SCTH">Header</b></th>
                                                    <th><b class="font-700 SCTC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in ViewModalInfo.service_contract_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Unit of Measurement: </b></td>
                                        <td>
                                            <span>{{ ViewModalInfo.u_o_m.toUpperCase() }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr>
                                    
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Rates Contracts -->
                            <!-- <div class="col-lg-12" v-if="ViewModalInfo.rccr_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                                <hr>   
                            </div> -->
                            <!-- List of Valid Rates Contracts End-->

                        </div>                       

                        <div class="col-lg-12">
                            <hr> 
                        </div>

                        
                        <div class="col-lg-12">
                            <h3 class="text-ogeo">History:</h3>   
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-Note</b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                            <td>{{ item.decision}} - <span class="font-italic">{{item.decision_notes}}</span></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            
                                            <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                            <td v-else-if="item.decision == 'Permanently Cancelled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-else-if="ViewModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-else-if="ViewModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{item.next_approval_level}}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-if="ViewModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                            <td v-if="ViewModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                            <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><button id="PrintHidden" class="btn btn-success ml-3" onclick="document.title = 'Services Rates’ Contract Line Information';window.print()">Download PDF</button>   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>             
                    </div>
                </div>
            </div>
        </div>


        <div id="RateContractItemRequestValidationModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RateContractItemRequestValidationModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h4 class="modal-title text-ogeo">Services Rates’ Contract Creation Request Line ID# {{ EditModalInfo.rate_contract_request_group_id}} - {{EditModalInfo.rate_contract_request_ingroup_id }}</h4>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;margin-left:50px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>                    
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">

                            <!-- Rates Contracts General Infomation Panel -->
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td width="40%"><b class="font-700"><i>Account</i> Details: </b></td>
                                            <td colspan="2">
                                                <span><b class="font-700 font-italic"><i>Account</i> Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                <span><b class="font-700 font-italic">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                <span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(EditModalInfo.project.value - EditModalInfo.project.consumed_budget) }} {{ EditModalInfo.project.currency }}</span>
                                            </td>
                                           
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ EditModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ EditModalInfo.created_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700">{{ EditModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ EditModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td><b class="font-700">Current Level Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                        <tr><td colspan="2"><hr></td></tr>
                                        <tr>
                                            <td colspan="2">
                                                <b class="font-700 text-dark" >List of Validators: </b>
                                                <table class="table">
                                                    <tr>
                                                        <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                        <td>
                                                            <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                                <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    
                                </table>
                                <table class="table">
                                    
                                </table>
                                
                            </div>
                            <div class="col-lg-12">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Underlying Transaction: </b></td>
                                        <td>{{ EditModalInfo.underlying_transaction }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Type: </b></td>
                                        <td>{{ EditModalInfo.rccr_type }}s</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">Rates' Contract Term: </b></td>
                                        <td>{{ EditModalInfo.term }} Months</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Rates' Contract Country & Cities: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.country }} | {{ EditModalInfo.cities.toString()}}</span><br>

                                        </td>
                                    </tr>

                                </table>
                            </div>
                            <!-- Rates Contracts General Infomation Panel End -->

                            <!-- Services Information Panel -->
                            <div class="col-lg-12" v-if="EditModalInfo.rccr_type == 'Service'">
                                <table class="table">
                                    <tr>
                                        <td width="40%"><b class="font-700">Are Advance Payments Considered For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment }}</td>
                                        <td width="40%"><b class="font-700">Are Guarantees Required From The Vendors Against The Advance Payments?</b></td>
                                        <td>{{ EditModalInfo.advanced_payment_guarantee ? EditModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-700">What Is The Percentage Of Payment Retention For The Items Sourced Under The Materials Rates' Contract?</b></td>
                                        <td>{{ EditModalInfo.retention_percentage	 ? EditModalInfo.retention_percentage + '%'	 : 'Not Applicable' }}</td>
                                        <td width="40%"><b class="font-700">Payment Retention Timeframe: </b><i style="font-size: 0.8rem;">(From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order)</i></td>
                                        <td>{{ EditModalInfo.retention_days ? EditModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                <table class="table">
                                    <!-- <tr align="center" v-if="EditModalInfo.Images.length > 0">
                                        <td v-for="(img, index) in EditModalInfo.Images" v-bind:key="img.id">
                                            <img class="img-responsive" style="width: 7vw;height: 7vw; text-align: center;" :src="img">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                        <td>{{ EditModalInfo.sourcing_priority }}</td>
                                    </tr>
                                    <tr v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Description: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.item_description }}</span><br>

                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.vendor_instruction_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="vendorIns" v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table" style="width:100%">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b class="font-700">Instruction To Vendors</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2"><b class="font-700 vendorInsH">Header</b></th>
                                                    <th><b class="font-700 vendorInsC">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.vendor_offer_content">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-2">{{ header.header_name }}:</td>
                                                    <td class="vendorInsC">{{ header.header_content }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr class="" id="SCT" v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td colspan="2">
                                            <table class="table SCT" style="width:100%" cellspacing="0">
                                                <tr class="text-center text-ogeo">
                                                    <th colspan="2"><b>Service Rates’ Contract Template</b></th>
                                                </tr>
                                                <tr>
                                                    <th class="col-lg-2  SCTH"><b class="font-700">Header</b></th>
                                                    <th class="SCTC"><b class="font-700">Content</b></th>
                                                </tr>    
                                                <tr v-for="header in EditModalInfo.service_contract_headers">
                                                    <td class="text-uppercase font-700 vendorInsH col-lg-1">{{ header.header_name }}:</td>
                                                    <td class="SCTC">{{ header.header_content }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Service Rates’ Contract Unit of Measurement: </b></td>
                                        <td>
                                            <span>{{ EditModalInfo.u_o_m.toUpperCase() }}</span><br>

                                        </td>
                                    </tr>
                                    <tr v-if="EditModalInfo.rccr_type == 'Service'">
                                        <td><b class="font-700">Awardee Vendor: </b></td>
                                        <td>
                                            <span>{{ 'Not Yet Awarded' }}</span><br>

                                        </td>
                                    </tr>
                                    
                                        
                                    
                                </table>
                            </div>
                            <!-- Services Information Panel End -->
                            
                            
                            <!-- List of Valid Rates Contracts -->
                            <!-- <div class="col-lg-12" v-if="EditModalInfo.rccr_type == 'Service'">                            
                                <table class="table mt-3">
                                    <tr class="text-center text-ogeo">
                                        <th colspan="5"><b class="font-700">List Of Valid Services Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" style="vertical-align: middle;" align="center"><b class="font-700">Country Cities</b></th>
                                        <th rowspan="1" colspan="8" class="text-center"><b class="font-700 text-center">Valid Service Rates’ Contracts</b></th>
                                    </tr>
                                    <tr>
                                        <th><b class="font-700">Reference</b></th>
                                        <th><b class="font-700">Short Description</b></th>
                                        <th><b class="font-700">Service Unit Of Measurement</b></th>
                                        <th><b class="font-700">Expiry Date</b></th>
                                    </tr>
                                    <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                        <td :rowspan="4" style="vertical-align: middle;" v-if="index > 0 ? item.city !== RateContractSelection[index - 1].city : true">{{ item.city }}</td>
                                        <tr v-for="(item, index) in RateContractSelection" :key="index" >
                                            <td>{{ item.unit_rate }} USD </td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.lead_time }}</td>
                                            <td>{{ item.vendor_score }}</td>
                                        </tr>
                                    </tr>
                                </table>
                            </div> -->
                            <!-- List of Valid Rates Contracts End-->
                            
                        </div>
                        
                        <!-- Decision Panel -->
                        <div class="col-lg-12" v-if="!EditModalInfo.status.includes('Rejected')">
                            <hr>
                            <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="RateContractItemRequestValidationForm">
                            <div class="row">
                                <div class="col-lg-12">
                            
                                    <h3 class="text-ogeo">Decision:</h3>
                                    <b class="text-ogeo">A Rejected Service Line Unit of Measurement Will Be Sent Back To The Request Initiator Who Can Either Cancel It From The Services Rates’ Contract Request, Or Amend Its Description And Resubmit For Validation, As The Case May Be.</b><br><br>   
                                    <span class="text-ogeo">The <i>Account</i>, The Rates’ Contract Type, The Sourcing Priority, The Instruction To Vendors, The Vendor Offer Content, The Service Rates’ Contract Template, The Advance And Retention Payment Arrangements, The Term, The Underlying Transaction & The Country/Cities Can’t Be Amended, As They Are Common To All The Lines Of The Services Rates’ Contract Creation Request.</span><br><br>   
                                    <div class="col-lg-12">
                                        <div class="grid-content">
                                            <el-form-item class="decision" prop="DecisionNotes" >
                                                <el-input
                                                  type="textarea"
                                                  :rows="5"
                                                  placeholder="Enter Decision Notes"
                                                  v-model="ValidationInfo.DecisionNotes">
                                                </el-input>
                                            </el-form-item>
                                        </div> 
                                    </div>

                                    <div class="col-lg-6 float-right">
                                        <button type="button" @click="DecisionApprove" style="background-color: #32CD32; border-color: #32CD32;" class="btn btn-block btn-success waves-effect">Approve</button>
                                    </div>

                                    <div class="col-lg-6 float-left">
                                        <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                    </div>

                                    <!-- <div class="col-lg-12">
                                        <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                    </div> -->
                                        
                                    
                                </div>
                                <br>
                            </div>
                        </el-form>
                            <hr>
                        </div> 
                        <div class="col-lg-12" v-else-if="EditModalInfo.status.includes('Rejected') && !showAmend">
                            <hr>
                            <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="RateContractItemRequestValidationForm">
                            <div class="row">
                                <div class="col-lg-12">
                            
                                    <h3 class="text-ogeo">Decision:</h3>
                                    <b class="text-ogeo">A Rejected Service Line Unit of Measurement Can Either Be Altogether Canceled From The Services Rates’ Contract Request, Or Be Amended And Resubmitted For Validation. Only The Service Line Unit of Measurement Can Be Amended.</b><br><br>   
                                    <span class="text-ogeo">The <i>Account</i>, The Rates’ Contract Type, The Sourcing Priority, The Instruction To Vendors, The Vendor Offer Content, The Service Rates’ Contract Template, The Advance And Retention Payment Arrangements, The Term, The Underlying Transaction & The Country/Cities Can’t Be Amended, As They Are Common To All The Lines Of The Services Rates’ Contract Creation Request.</span><br><br>   
                                    <div class="col-lg-12">
                                        <div class="grid-content">
                                            <el-form-item class="decision" prop="DecisionNotes" >
                                                <el-input
                                                  type="textarea"
                                                  :rows="5"
                                                  placeholder="Enter Decision Notes"
                                                  v-model="ValidationInfo.DecisionNotes">
                                                </el-input>
                                            </el-form-item>
                                        </div> 
                                    </div>

                                    <div class="col-lg-6 float-right">
                                        <button type="button" @click="showAmend = true" style="background-color: #32CD32; border-color: #32CD32;" class="btn btn-block btn-success waves-effect">Amend Line Information And Resubmit For Approval</button>
                                    </div>

                                    <div class="col-lg-6 float-left">
                                        <button type="button" @click="DecisionRejectPermanent" class="btn btn-block btn-danger waves-effect">Cancel Line From the Material Rates’ Contract Creation Request</button>
                                    </div>

                                    <!-- <div class="col-lg-12">
                                        <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                    </div> -->
                                        
                                    
                                </div>
                                <br>
                            </div>
                        </el-form>
                            <hr>
                        </div>
                        <div class="col-lg-12" v-else>
                            <el-form :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="RateContractRequestForm">

                                <el-row :gutter="20">  


                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <h3>Amend A Rates’ Contract Creation Request Line</h3>
                                            <span class="text-ogeo font-700">Rates’ Contracts are pre-negotiated agreements with 
                                            vendors intended to freeze unit prices and lead times for materials 
                                            (strictly those from the Library of Materials) and services, before the 
                                            demand for them actually arises, so as to reduce cost and workload 
                                            (Purchase Enquiries linked to Rates’ Contracts can be directly turned into 
                                            Purchase Orders). Establishing Rates’ Contract starts with raising a 
                                            formal Rates’ Contract Creation Requests.</span>
                                        </div><br>
                                    </el-col>                     
                                    <el-col :span="24">
                                        <div class="grid-content">

                                                    <el-form-item label="temp" prop="JobNumber" >
                                                    <span slot="label"><b>Select A <i>Account</i></b></span>


                                                <el-select filterable disabled style="width: 100%; padding: 0px;" class="setUp" id="setUp"  @change="getProjectCurrency($event)" v-model="EditModalInfo.project.title" placeholder="Select Set up ">
                                                    
                                                    <el-option v-for="(JobNumber, JobNumberId) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>                      
                                    
                                    <el-col :span="24"><hr></el-col>

                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <el-form-item style="font-weight: bolder" label="temp" prop="RCType">
                                                <span slot="label"><b>Select Rates’ Contract Type</b></span>

                                                <el-select disabled @change="" filterable style="width: 100%; padding: 0px;" v-model="EditModalInfo.rccr_type" placeholder="Select Enquiry Type (Material Or Service)">
                                                        
                                                    <el-option label="Materials" value="Material"></el-option>
                                                    <el-option label="Services" value="Service"></el-option>
                                                        
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="12" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item prop="CompanyCountry">
                                                <span slot="label"><b>Select Country</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                    <span class="tooltip-item2">
                                                        <span class="fa-stack fa-1x">
                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success"></i>
                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                        </span>
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: 50px">
                                                        <span class="tooltip-text2">Select the country where the materials under the Rates' Contract are needed. (1 country only)</span>
                                                    </span>
                                                </span>
                                                <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.country" placeholder="Select Country"> -->
                                                <el-select filterable disabled collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="EditModalInfo.country" placeholder="Select Country">
                                                    
                                                    
                                                    <el-option
                                                        v-for="(item,CompanyCountry) in countries"
                                                        :key="CompanyCountry"
                                                        :label="CompanyCountry"
                                                        :value="CompanyCountry">
                                                        <span style="float: left">{{ CompanyCountry }}</span>
                                                    </el-option>

                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="12" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item prop="CompanyCities">
                                                <span slot="label"><b>Select Cities</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                    <span class="tooltip-item2">
                                                        <span class="fa-stack fa-1x">
                                                            <i style="" class="fa fa-circle fa-lg fa-stack-1x text-success" ></i>
                                                            <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                        </span>
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -100px">
                                                        <span class="tooltip-text2">Select the cities where the materials under the Rates' Contract are needed . ( Multiple Cities Possible)</span>
                                                    </span>
                                                </span>
                                                <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                                <el-select filterable disabled collapse-tags multiple style="width: 100%; padding: 0px;" class="city" id="city" v-model="EditModalInfo.cities" placeholder="Select City">
                                                    
                                                    <el-option
                                                        v-for="(item,companyCity) in cities"
                                                        :key="companyCity"
                                                        :value="item"
                                                        :label="item">
                                                    <span style="float: left">{{ item }}</span>
                                                    </el-option>
                                                    
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="24">
                                        <div class="grid-content">
                                            <el-form-item prop="SourcingPriority">
                                                <span slot="label"><b>Sourcing Priority</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>The sourcing priority is, by default, set to Standard but can be changed to Urgent or Very Urgent.</strong> It is meant to indicate how quickly the sourcing of proposals should be conducted.
                                                        </span>
                                                    </span>
                                                </span>
                                                
                                                <el-select filterable :disabled="itemAdditionFlag == 'Y'" style="width: 100%; padding: 0px;" v-model="EditModalInfo.sourcing_priority" placeholder="">
                                                        
                                                    <el-option label="Standard" value="Standard"></el-option>
                                                    <el-option label="Urgent" value="Urgent"></el-option>
                                                    <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                        
                                                </el-select>

                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">
                                            <el-form-item label="temp" prop="UnderlyingTransaction">
                                                <span slot="label"><b>Rates’ Contract Underlying Transaction Reference</b></span>

                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            <strong>The Underlying Transaction Reference</strong> is the reference of the transaction  that has led to the creation of this specific Purchase Enquiry. It can be a particular Job Ticket, a Sales Order, a Work Order…etc
                                                        </span>
                                                    </span>
                                                </span>

                                                <el-input disabled v-model="EditModalInfo.underlying_transaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title"><hr></el-col>

                                    <el-col :span="24" v-if="this.EditModalInfo.project.title">
                                        <div class="grid-content">

                                            <!-- <el-form-item label="What should be the term, in months,of the requested Rates' Contract?" prop="RateContractItemPeriod"> -->
                                                
                                                <el-form-item label="temp" prop="RateContractItemPeriod">
                                                    <span slot="label"><b>What Will Be The Term, In Months, Of The Requested Rates' Contract?</b></span>
                                                
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"> <span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -120px;">
                                                        <span class="tooltip-text2">
                                                            Rates' Contract signed on the back of this request will be valid for this term, starting from the date of the Rates' Contract implementation.
                                                        </span>
                                                    </span>
                                                </span>
                                                <el-input id="expTerm" disabled class="expTerm"  type="number" oninput="this.value = Math.floor(this.value);" placeholder="Enter Expected term" v-model="EditModalInfo.term">
                                                </el-input>
                                            </el-form-item>

                                        </div>
                                    </el-col>
                                    



                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'"><hr></el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'">
                                        <div class="grid-content">
                                            
                                            <el-form-item label="temp" prop="ServiceDescription">
                                                <span slot="label"><b>Enter Service Rates’ Contract Description</b></span>
                                                <el-input
                                                    disabled
                                                    type="textarea"
                                                    :rows="2"
                                                    placeholder="Enter Complete Description Of The Service Required."
                                                    v-model="EditModalInfo.item_description">
                                                </el-input>
                                            </el-form-item>
                                            <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                            
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'" >
                                        <div class="grid-content">
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Instruction To Vendors</b></span>
                                                <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                    <thead class="text-white bg-dark">
                                                        <tr style="width:25%">
                                                            <th><span style="color:white">*</span>Header
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                        
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                        <span class="tooltip-text2">
                                                                            These headers help better describe the instructions to vendors. as well as the conditions of award. They can 
                                                                            include Inclusions, Exclusions, Vendors Offers’ Contents, Quantity, Biding Instructions, Date of bid 
                                                                            submittals, Bid Scoring Sheet, .....
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <th style="width:70%">Content</th>
                                                            <th class="text-center" width="70"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(header, index) in EditModalInfo.vendor_instruction_headers"
                                                            :key="index">
                                                            <td>
                                                                <input disabled v-model="header.header_name" class="form-control">
                                                            </td>
                                                            <td>
                                                                <input disabled v-model="header.header_content" class="form-control">
                                                            </td>
                                                            <td class="text-center">
                                                                <button disabled style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td>
                                                                <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.name">
                                                            </td>
                                                            <td>
                                                                <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.content">
                                                            </td>
                                                            <td class="text-center">
                                                                <button v-if="vendorinstructionheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'" >
                                        <div class="grid-content">
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Vendor Offer Content</b></span>
                                                <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                    <thead class="text-white bg-dark">
                                                        <tr style="width:25%">
                                                            <th><span style="color:white">*</span>Header
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                        
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                        <span class="tooltip-text2">
                                                                            These headers help better describe the instructions to vendors. as well as the conditions of award. They can 
                                                                            include Inclusions, Exclusions, Vendors Offers’ Contents, Quantity, Biding Instructions, Date of bid 
                                                                            submittals, Bid Scoring Sheet, .....
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <th style="width:70%">Content</th>
                                                            <th class="text-center" width="70"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(header, index) in EditModalInfo.vendor_offer_content"
                                                            :key="index">
                                                            <td>
                                                                <input disabled v-model="header.header_name" class="form-control">
                                                            </td>
                                                            <td>
                                                                <input disabled v-model="header.header_content" class="form-control">
                                                            </td>
                                                            <td class="text-center">
                                                                <button disabled style="padding: 0px 13px" type="button" @click="deleteTendererHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td>
                                                                <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.name">
                                                            </td>
                                                            <td>
                                                                <input v-if="itemAdditionFlag == 'N'" class="form-control" v-model="vendorinstructionheader.content">
                                                            </td>
                                                            <td class="text-center">
                                                                <button v-if="vendorinstructionheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addTendererContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'" >
                                        <div class="grid-content">
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Service Rates’ Contract Template</b></span>
                                                <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white">
                                                    <thead class="text-white bg-dark">
                                                        <tr style="width:25%">
                                                            <th><span style="color:white">*</span>Header
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                    <span class="tooltip-item2">
                                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                        
                                                                    </span>
                                                                    <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                        <span class="tooltip-text2">
                                                                            These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                            Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                            Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                            rights, Litigation...
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <th style="width:70%">Content</th>
                                                            <th class="text-center" width="70"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(header, index) in EditModalInfo.service_contract_headers"
                                                            :key="index">
                                                            <td>
                                                                <input disabled v-model="header.header_name" class="form-control">
                                                            </td>
                                                            <td>
                                                                <input disabled v-model="header.header_content" class="form-control">
                                                            </td>
                                                            <td class="text-center">
                                                                <button style="padding: 0px 13px" type="button" disabled @click="deleteContractHeader(index)" class="btn-pointer btn-danger btn-floating">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td>
                                                                <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.name">
                                                            </td>
                                                            <td>
                                                                <input class="form-control" v-if="itemAdditionFlag == 'N'" v-model="servicecontractheader.content">
                                                            </td>
                                                            <td class="text-center">
                                                                <button v-if="servicecontractheader.content && itemAdditionFlag == 'N'" style="padding: 0px 13px" @click.prevent="addContractHeader()" class="btn-pointer btn-success btn-floating"><i class="fa fa-plus"></i></button>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-form-item>
                                        </div>
                                    </el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'"><hr></el-col>

                                    <el-col :span="24" v-if="EditModalInfo.rccr_type == 'Service'">
                                        <div class="grid-content">
                                            
                                            <el-form-item label="temp" prop="ServiceUOM">
                                                <span slot="label"><b>Enter A Service Line Unit Of Measurement</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                    <span class="tooltip-item2">
                                                        <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                        
                                                    </span>
                                                    <span class="tooltip-content4 clearfix" style="left: -140px;">
                                                        <span class="tooltip-text2">
                                                            This must be a very detailed service’s Unit Of Measure for which rates are sought from 
                                                            The vendors. A Unit Of Measure can be, for example, “Housekeeping services for 
                                                            8 hours a day, 5 days a week”
                                                        </span>
                                                    </span>
                                                </span>
                                                <el-input
                                                    type="textarea"
                                                    :rows="2"
                                                    placeholder="Enter A Description Of the Service Rates’ Contract Unit Of Measurement."
                                                    v-model="EditModalInfo.u_o_m">
                                                </el-input>
                                            </el-form-item>
                                            <!-- <span style="margin-top: -20px;" class="float-right">100/{{PurchaseEnquiryLine.ServiceDescription.length}}</span> -->
                                            
                                        </div>
                                    </el-col>
                                    <el-col :span=24 v-if="EditModalInfo.rccr_type == 'Service'"><hr></el-col>



                                    <!-- ----------------------------------- -->
                                    <button v-if="this.EditModalInfo.project.title && EditModalInfo.rccr_type == 'Service'" type="button" @click="SubmitUpdateItemRateContractRequest" class="btn btn-success btn-block waves-effect text-center">Resubmit The Item With The Service Rates’ Contract Creation Request</button>

                                </el-row>
                            </el-form>
                        </div>
                        <!-- Decision Panel End -->
                        
                        <div class="col-lg-12">
                            <hr>
                        </div> 

                        <div class="col-lg-12">
                            <h3 class="text-ogeo">History:</h3>    
                            <table class="display table table-bordered">
                                <thead>
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-Note</b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level > 0 ?  item.current_approval_level : 'N/A' }}</td>
                                        <td>{{ item.decision}} - <span class="font-italic">{{item.decision_notes}}</span></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <td v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                        
                                        <td v-if="item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates’ Contract line Under correction By The Rates’ Contract Request Initiator</td>
                                        <td v-else-if="item.decision == 'Permanently Cancelled'" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="EditModalInfo.count_role == item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-else-if="EditModalInfo.count_role !== item.next_approval_level" style="background-color: rgb(252, 51, 23, 0.2); text-transform: capitalize;">Rates' Contract Line Under Review By The Rates' Contract Creation Request Validator level {{item.next_approval_level}}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="EditModalInfo.count_role != item.current_approval_level || item.decision == 'Rejected'" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                        <td v-if="EditModalInfo.count_role != item.current_approval_level" style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>
                                        <td v-else style="background-color: rgb(252, 51, 23, 0.2);">N/A</td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <span><b>Date:</b> {{ new Date() }}</span><br>
                                <span><b>By:</b> {{ currentUser.Name }}</span><!--<button class="btn btn-success ml-3" onclick="document.title = 'Purchase Enquiry Line Information';window.print()">Download PDF</button>-->   <br><br>
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>              
                    </div>
                </div>
            </div>
        </div>


    </div>
            
</template>

<script>
    //import {CustomJs} from '../../helpers/custom.js';
    import validate from 'validate.js';

    export default {
        name: 'purchase-enquiry-list',
        data(){
            return{
                DataTable: "",
                switchbutton: 'Service',
                CompanySetup: [],
                viewDataLoaded: false,
                editDataLoaded: false,
                showAmend: false,
                countries: [],
                cities: [],
                JobNumbers: [],
                ViewModalInfo: {},
                EditModalInfo: {},
                TmpEditModalInfo: {},
                validatorsList:[],
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
                      city: 'Herat'
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
                      city: 'Herat'
			        },
                    // {
			        //   value: 3,
			        //   vendor_name: 'Nike',
			        //   vendor_score: '10',
			        //   unit_rate: '10',
			        //   lead_time: '5 Days',
			        //   rate_contract_reference: 'Nike',
			        //   date: '09 july 2023',
			        // }

				],
                vendorinstructionheader: {
                    name: '',
                    content: '',
                },
                vendoroffercontent: {
                    name: '',
                    content: '',
                },
                servicecontractheader: {
                    name: '',
                    content: '',
                },
                ValidationInfo: {
                    ProjectID: "",
                    RCItemRequestId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                rules: {
                    Decision: [{
                        required: true,
                        message: "Please select a approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        methods: {
            changeTable(){
                if(this.switchbutton == 'Service')
                    this.$router.push('/app/rate_contracts/rate_contracts_creation_requests_service_list')
                else
                    this.$router.push('/app/rate_contracts/rate_contracts_creation_requests_list')
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_rate_contract_creation_requests_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        //this.ViewModalInfo.required_documents_split = this.ViewModalInfo.required_documents.split(",");
                        //console.log(this.ViewModalInfo);
                        this.loadValidations(response.data);
                        this.viewDataLoaded = true;
                        $('#RateContractItemRequestValidationModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            showEditModal(elquentClass, recordId){
                this.EditModalInfo = {};
                this.ValidationInfo.Decision= "";
                this.ValidationInfo.DecisionNotes= "";
                console.log('edit modal changes');
                axios.post('/api/data/get_rate_contract_creation_requests_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = Object.assign({}, response.data);
                        this.TmpEditModalInfo = Object.assign({}, response.data);
                        // this.ReqDocTmp = this.EditModalInfo.required_documents.split(',');
                        this.EditModalInfo.cities = this.EditModalInfo.cities.split(',');
                        this.ValidationInfo.RCItemRequestId = this.EditModalInfo.id;
                        this.ValidationInfo.ProjectID = this.EditModalInfo.project_id;
                        this.loadValidations(response.data);
                        // this.searchItems(' ');
                       // this.ViewModalInfo.required_documents_split = this.ViewModalInfo.required_documents.split(",");
                        this.editDataLoaded = true;
                        $('#RateContractItemRequestValidationModalEdit').modal('toggle');
                    })
                this.editDataLoaded = false;
            },
            SubmitUpdateItemRateContractRequest() {
                if (event) event.preventDefault();
                if ( Object.entries(this.EditModalInfo).toString() !== Object.entries(this.TmpEditModalInfo).toString()){
                    Swal({
                        type: 'warning',
                        title: 'Are You Sure You Want To Proceed With The Following Amendment?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((response) => {
                        if(response.value)
                            this.SubmitChanges();
                    });
                }
                else{
                    Swal({
                        type: 'warning',
                        title: 'No Information Has been Amended. Are You Sure You Want To Proceed?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        if(confirm.value)
                            this.SubmitChanges();
                    });
                }

            },
            SubmitChanges(){
                let self = this;
                    axios.post('/api/rate_contracts/update_rate_contract_item_request', this.EditModalInfo)
                        .then(function(response){
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                                timer: 10000
                            });

                            self.DataTable.ajax.reload( null, false );
                        })
                        .catch(function(){
                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Item Rate Contract Request Submit)', 'error');
                        });

                        this.showAmend = false;
                    this.$nextTick(() => {
                        $('#RateContractItemRequestValidationModalEdit').modal('toggle');
                        this.DataTable.draw('full-hold');
                    });


            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfo.Decision = "Approved";
                axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                    });

                    $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                this.rules.DecisionNotes[0].required = false;

            },
            DecisionReject(){

                let self = this;
                this.rules.DecisionNotes[0].required = true;
                this.ValidationInfo.Decision = "Rejected";
                this.$refs.RateContractItemRequestValidationForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.DataTable.ajax.reload( null, false );
                            
                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                            });

                            $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                        this.rules.DecisionNotes[0].required = false;

                    }
                })
            },
            DecisionRejectPermanent(){
                Swal({
                    type: "warning", 
                    title: "Are You Sure You Want to Permanently Cancel This Rates's Contract Line ?", 
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#FF0000",
                    confirmButtonColor: "#32CD32",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.rules.DecisionNotes[0].required = true;
                        this.ValidationInfo.Decision = "RejectedPermanent";
                        this.$refs.RateContractItemRequestValidationForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/rate_contracts/validate_rate_contract_item_creation', this.ValidationInfo)
                                    .then(function(response){
                                        Swal({ 
                                            type: response.data.messageType, 
                                            title: response.data.messageTitle, 
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.DataTable.ajax.reload( null, false );
                                    
                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (ItemCreationForm)', 'error');
                                    });

                                    $('#RateContractItemRequestValidationModalEdit').modal('toggle');

                                this.rules.DecisionNotes[0].required = false;

                            }
                        });
                    }
                });
            },
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
            
        },
        mounted(){
            var groupColumn = 1;
             //$.noConflict();
           //  console.debug('foo2');

            axios.get('/api/data/get_company_details')
                .then((SecondResponse) => {

                    this.CompanySetup = SecondResponse.data;

                });
                

           setTimeout(() => {
             this.DataTable = $('#NewRateContractItemRequestsApprovals').DataTable({
                fixedHeader: {
                    header: true,
                    headerOffset: 117
                },
                stateSave:  true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_rate_contract_creation_service_requests_list',
                columns: [
                    { data: 'show_id', name: 'show_id' },
                    { data: 'rate_contract_request_group_id', name: 'rate_contract_request_group_id' },
                    { data: 'project.title', name: 'Setup' },
                    { data: 'creator.name', name: 'creator.name' },
                    { data: 'item_description', name: 'item_description' },
                    { data: 'u_o_m', render: function (data, row, full){
                        return data.toUpperCase();
                    } },
                    { data: 'sourcing_priority', name: 'sourcing_priority' },
                    { data: 'underlying_transaction', name: 'underlying_transaction' },
                    { data: 'rccr_type', render: function (data, row, full){
                        if(data)
                            return data + "s";
                        else
                            return "N/A"; 
                        }, },
                    { data: 'term', render: function (data, row, full){
                        if(data)
                            return data + " Months";
                        else
                            return "N/A";
                    } },
                    { data: 'countryCities', name: 'countryCities' },
                    { data: 'advanced_payment', render: function (data, row, full){
                        if(data == 'Yes')
                            if(full.advanced_payment_guarantee == 'Yes')
                                return 'Yes | Guarantees Against The Advanced Payment Will Be Required.'
                            else
                                return 'Yes | No Guarantees Against The Advanced Payment Will Be Required.'
                        else
                            return 'No'    

                    } },
                    { data: 'retention_percentage', render: function (data, row, full){
                        if(data > 0)
                            return data +'% | '+full.retention_days+' Day(s) From The Date Of Either Receiving The Complete Quantity Specified In The Purchase Order, Or Closing The Purchase Order.'
                        else
                            return "Not Applicable";
                    } },
                    { data: 'vendor_instruction_headers', render: (data) => {
                       if(Object.keys(data).length !== 0){
                        let arr = "";
                        data.map(item => {
                            arr = arr + item.header_name + ", ";
                        })
                        return arr.toUpperCase().substring(0, arr.length-2);
                        }
                        else
                         return 'N/A';
                    } },
                    { data: 'vendor_offer_content', render: (data) => {
                       if(Object.keys(data).length !== 0){
                            let arr = "";
                            data.map(item => {
                                arr = arr + item.header_name + ", ";
                            })
                            return arr.toUpperCase().substring(0, arr.length-2);
                        }
                        else
                         return 'N/A';
                    } },
                    { data: 'service_contract_headers', render: (data) => {
                        if(Object.keys(data).length !== 0){
                        let arr = [];
                        data.map(item => {
                            arr = arr + item.header_name + ", ";
                        })
                        return arr.toUpperCase().substring(0, arr.length-2);
                        }
                        else
                         return 'N/A';
                    } },
                    { data: 'validationList', name: 'validationList' },
                    { data: 'level_of_validations', name: 'level_of_velidations' },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'status', name: 'status' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                columnDefs: [
                    { visible: false, targets: groupColumn },
                    { responsivePriority: 1, targets: 21 },
                    { responsivePriority: 2, targets: 20 },
                    { responsivePriority: 3, targets: 0 },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 8,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ groupColumn, 'ASC' ]],
                "drawCallback": function ( settings ) {
                    var api = this.api();
                    var rows = api.rows( {page:'current'} ).nodes();
                    var last=null;
         
                    api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {
                        if ( last !== group ) {
                            $(rows).eq( i ).before(
                                '<tr class="group"><td colspan="12">Rates’ Contract Creation Request ID# RCCR-'+group+'</td></tr>'
                            );
         
                            last = group;
                        }
                    } );
                },
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'RateContractRequests' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                }, 
                "rowCallback": function( row, data, index ) {                   
                    if(data.action.indexOf('edit-placeholder') !== -1){
                        $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                    } 
                    // else if (data.status.indexOf('Resubmitted and Assigned to') !== -1) {
                    //     $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                    // } else if (data.status.indexOf('sent for sourcing') !== -1) {
                    //     $(row).css('background-color', 'rgb(69, 249, 132, 0.1)')
                    // } else if (data.status.indexOf('Rejected by ') !== -1) {
                    //     $(row).css('background-color', 'rgb(249, 69, 72, 0.08)')
                    // } else {
                    //     $(row).css('background-color', 'rgb(249, 69, 237, 0.1)')
                    // }
                } 
            });

            const self = this;

            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $(this).data('recordid');
                self.showViewModal(elquentClass, RecordID);
            });

            $('tbody', this.$refs.table).on('click', '.view-similar', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    self.$router.push({ name: 'Similar Rates’ Contract Creation Request', params: { id: RecordID }})
                    // this.$router.push('/app/purchase_enquiry/similar_purchase_enquiry/' + RecordID); 
                });

            $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $(this).data('recordid');
                self.showEditModal(elquentClass, RecordID);
            });

            }, 1000);

        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>


<style scoped>
.sptable tr td {
    border: none;
    padding-top: 5px;
    padding-bottom: 0px;
}
@media print {
  html body * {
    visibility: hidden;
  }
    #PrintHidden {
        display: none;
    }
  #RateContractItemRequestValidationModalView, #RateContractItemRequestValidationModalView * {
    visibility: visible;
    overflow: visible;
  }
  #RateContractItemRequestValidationModalView::-webkit-scrollbar { width: 0 !important }
  #RateContractItemRequestValidationModalView:last-child {
     page-break-after: auto;
    }
  #RateContractItemRequestValidationModalView:last-child {
     page-break-before: none;
    }

  #RateContractItemRequestValidationModalView {
    position: relative;
    margin-top: -400mm;
    bottom: 0;
    top: 0;
    -ms-overflow-style: none;

  }

}

    .font-500{
        font-weight: 500;
    }

    #RateContractItemRequestValidationModalView .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    #RateContractItemRequestValidationModalEdit .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
</style>