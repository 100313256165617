<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h3>Changes to the Vendor Score Setting</h3>
                    <table ref="table" id="VendorScoreSettingChanges" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Initial Value</th>
                                <th>Current Value</th>
                                <th>Change by</th>
                                <th>Change Date & Time</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h3>Changes to the Qualification Documents</h3>
                    <table ref="table" id="VendorDocsChanges" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Initial Value</th>
                                <th>Current Value</th>
                                <th>Change by</th>
                                <th>Change Date & Time</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h3>Changes to the Vendors Information</h3>
                    <table ref="table" id="VendorInformationChanges" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Vendor Alias</th>
                                <th>Initial Value</th>
                                <th>Current Value</th>
                                <th>Change by</th>
                                <th>Change Date & Time</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h3>Changes to the Vendors Users</h3>
                    <table ref="table" id="VendorUserInformationChanges" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Vendor Alias</th>
                                <th>User Name</th>
                                <th>Initial Value</th>
                                <th>Current Value</th>
                                <th>Change by</th>
                                <th>Change Date & Time</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                DataTable: "",
                VQ_Actions_Typos: [
                    'Qualification Of A New Vendor',
                    'Vendor Information Amendment',
                    'Vendor Inactivation',
                    'Vendor Reactivation',
                    'Addition Of A New Vendor Representative',
                    'Vendor Representative Inactivation',
                    'Vendor Representative Reactivation',
                    'Vendor Representative Role Change',
                    'Vendor Expired Qualification Renewal'
                ],
                criteriaTypos: ['Criteria #1',
                                'Criteria #2',
                                'Criteria #3',
                                'Criteria #4',
                                'Criteria #5',
                                'Criteria #6',
                                'Criteria #7'],
                weitageTypos: ['Criteria #1: Weightage',
                                'Criteria #2: Weightage',
                                'Criteria #3: Weightage',
                                'Criteria #4: Weightage',
                                'Criteria #5: Weightage',
                                'Criteria #6: Weightage',
                                'Criteria #7: Weightage'],
                lowerlimitTypos: ['Criteria #1: Score "3" lower limit',
                                'Criteria #1: Score "2" lower limit',
                                'Criteria #1: Score "1" lower limit',
                                'Criteria #2: Score "3" lower limit',
                                'Criteria #2: Score "2" lower limit',
                                'Criteria #2: Score "1" lower limit',
                                'Criteria #3: Score "3" lower limit',
                                'Criteria #3: Score "2" lower limit',
                                'Criteria #3: Score "1" lower limit',
                                'Criteria #4: Score "3" lower limit',
                                'Criteria #4: Score "2" lower limit',
                                'Criteria #4: Score "1" lower limit',
                                'Criteria #5: Score "3" lower limit',
                                'Criteria #5: Score "2" lower limit',
                                'Criteria #5: Score "1" lower limit',
                                'Criteria #6: Score "3" lower limit',
                                'Criteria #6: Score "2" lower limit',
                                'Criteria #6: Score "1" lower limit',
                                'Criteria #7: Score "3" lower limit',
                                'Criteria #7: Score "2" lower limit',
                                'Criteria #7: Score "1" lower limit'],
            }
        },
        methods: {

        },
        mounted() {
            setTimeout(() => {
            const table1 = $('#VendorScoreSettingChanges').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_audit_trail_vendor_score_settings_info',
                columns: [
                {
                        data: 'description',
                        name: 'description'
                    },
                    {
                        data: 'old_value',
                        render: (data, row, full) => {
                            if(this.criteriaTypos.includes(full.description))
                                if(data == 0) return 'Disabled'; else return 'Enabled';
                            else if(this.weitageTypos.includes(full.description))
                                return data;
                            else
                                return data+"%";
                        }
                    },
                    {
                        data: 'new_value',
                        render: (data, row, full) => {
                            if(this.criteriaTypos.includes(full.description))
                                if(data == 0) return 'Disabled'; else return 'Enabled';
                            else if(this.weitageTypos.includes(full.description))
                                return data;
                            else
                                return data+"%";
                        }
                    },
                    {
                        data: 'change_by.name',
                        name: 'changeBy'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 5,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [4, 'desc']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
            const table2 = $('#VendorDocsChanges').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_audit_trail_vendor_docs_info',
                columns: [
                {
                        data: 'description',
                        name: 'description'
                    },
                    {
                        data: 'old_value',
                        name: 'old_value'
                    },
                    {
                        data: 'new_value',
                        name: 'new_value'
                    },
                    {
                        data: 'change_by.name',
                        name: 'changeBy'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 5,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [4, 'ASC']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
            const table3 = $('#VendorInformationChanges').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_audit_trail_vendors_info',
                columns: [
                {
                        data: 'description',
                        name: 'description'
                    },
                    {
                        data: 'vendor.vendor_alias',
                        name: 'vendor_alias'
                    },
                    {
                        data: 'old_value',
                        name: 'old_value'
                    },
                    {
                        data: 'new_value',
                        name: 'new_value'
                    },
                    {
                        data: 'change_by.name',
                        name: 'changeBy'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [

                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 5,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [5, 'DSC']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
            const table4 = $('#VendorUserInformationChanges').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/data/get_audit_trail_vendor_users_info',
                columns: [
                    {
                        data: 'description',
                        name: 'description'
                    },
                    {
                        data: 'vendor.vendor_alias',
                        name: 'vendor_alias'
                    },
                    {
                        data: 'vendor_user.name',
                        name: 'vendor_user_name'
                    },
                    {
                        data: 'old_value',
                        render: (data, row, full) => {
                            if(data.includes('SBM') && data.includes('SIM'))
                                    return "Bid Manager Role, Invoicing Manager Role"
                                else if(data.includes('SIM') && !data.includes('SBM'))
                                    return 'Invoicing Manager Role'
                                else if(data.includes('SBM') && !data.includes('SIM'))
                                    return 'Bidding Manager Role'
                                else return data;
                    }
                    },
                    {
                        data: 'new_value',
                        render: (data, row, full) => {
                                if(data.includes('SBM') && data.includes('SIM'))
                                    return "Bid Manager Role, Invoicing Manager Role"
                                else if(data.includes('SIM') && !data.includes('SBM'))
                                    return 'Invoicing Manager Role'
                                else if(data.includes('SBM') && !data.includes('SIM'))
                                    return 'Bidding Manager Role'
                                else return data;
                        }
                    },
                    {
                        data: 'change_by.name',
                        name: 'changeBy'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [

                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 5,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [6, 'DSC']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
        }, 1000);
        },
    }

</script>
