<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>List Of Requests To Update Vendor Information</h4>
                </div>
                <div class="card-body">
                    <table ref="table" id="VMRequestsList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th>Request ID</th>
                                <th>Setup</th>
                                <th>Requester</th>
                                <th>Vendor</th>
                                <th>Reason For The Request</th>
                                <th>Vendor Status</th>
                                <!-- <th>Purchase Orders Awarded ((Currency) | MtD)</th>
                                <th>Purchase Orders Awarded ((Currency) | YtD)</th>
                                <th>Vendor Number of Representatives</th> -->
                                <!-- <th>Vendor Qualification Expiry Date</th> -->
                                <!-- <th>Vendor Score</th> -->
                                <th>Created At</th>
                                <th>Last Updated At</th>
                                <th>Type</th>
                                <th>Request Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="VMRequestModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="VMRequestModal" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:45%">
                            <h3 class="modal-title text-ogeo">Request To Update The Vendors Register ID#: {{ ViewModalInfo.id }} </h3>
                        </div>
                        <div style="width:10%">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;border-radius:25px">
                        </div>
                        <div style="width:45%" v-if="viewDataLoaded">
                            <span style="font-size: 24px; float: right;"><b class="text-ogeo">Score:</b> <b class="text-ogeo">{{ ViewModalInfo.vendor.vendor_score ? ViewModalInfo.vendor.vendor_score + '/100' : 'N/A'  }}</b></span>
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="">×</button>
                        </div>

                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table sptable">
                                    <tr>
                                        <td><b class="font-500 text-ogeo">Request Type: </b></td>
                                        <td class="font-700 text-ogeo">{{ ViewModalInfo.type }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Request's Setup: </b></td>
                                        <td>{{ ViewModalInfo.project.title }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Requested By: </b></td>
                                        <td>{{ ViewModalInfo.requester.name ? ViewModalInfo.requester.name : 'N/A' }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Requested On: </b></td>
                                        <td>{{ ViewModalInfo.created_at }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500 text-ogeo">Request Status: </b></td>
                                        <td class="font-700 text-ogeo">{{ ViewModalInfo.status }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Last Updated On: </b></td>
                                        <td>{{ ViewModalInfo.updated_at }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-700">Current Level Of Validation: </b></td>
                                        <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.countRole }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td colspan="2">
                                            <b class="font-700 text-dark" >List of Validators: </b>
                                            <table class="table">
                                                <tr>
                                                    <!-- <td><b class="font-700">List of Validatons: </b></td> -->
                                                    <td>
                                                        <ul style="display: inline-flex" v-for="(his, index) in validatorsList">
                                                            <li>{{ his.name }} <i>(Level {{ his.level }})</i> <span v-if="his.date"> | {{ his.date }} | </span><span v-if="his.decision" class="font-italic">({{ his.decision }})</span></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-700">Vendor Demand lD#: </b></td>
                                        <td>Not Applicable</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Legal Name: </b></td>
                                        <td>{{ ViewModalInfo.vendor.name }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Alias: </b></td>
                                        <td>{{ ViewModalInfo.vendor.vendor_alias }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Website: </b></td>
                                        <td>{{ ViewModalInfo.vendor.url }} </td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Phone Number: </b></td>
                                        <td>{{ ViewModalInfo.vendor.phone }} </td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-700">Vendor Location:</b></td>
                                        <td>
                                            <span><b class="font-700 font-italic">Address:</b> {{ ViewModalInfo.vendor.address }}</span><br>
                                            <span><b class="font-700 font-italic">PO Box:</b> {{ ViewModalInfo.vendor.po_box }}</span><br>
                                            <span><b class="font-700 font-italic">Country:</b> {{ ViewModalInfo.vendor.country }}</span><br>
                                            <span><b class="font-700 font-italic">City:</b> {{ ViewModalInfo.vendor.city }}</span><br>

                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Trade License Reference: </b></td>
                                        <td>{{ ViewModalInfo.vendor.lic_num }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Trade License Issuing Authority Name: </b></td>
                                        <td>{{ ViewModalInfo.vendor.lic_authority }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Establishment Year: </b></td>
                                        <td>{{ ViewModalInfo.vendor.open_since }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Countries Of Operations: </b></td>
                                        <td>{{ ViewModalInfo.vendor.operating_countries }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Cities Of Operations: </b></td>
                                        <td>{{ ViewModalInfo.vendor.operating_cities }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Highest Ranking Officer Name: </b></td>
                                        <td>{{ ViewModalInfo.vendor.officer_name }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Highest Ranking Officer Phone: </b></td>
                                        <td>{{ ViewModalInfo.vendor.officer_phone }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Name: </b></td>
                                        <td>{{ ViewModalInfo.vendor.rep_name }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Title: </b></td>
                                        <td>{{ ViewModalInfo.vendor.rep_title }}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Representative Phone Number: </b></td>
                                        <td>{{ ViewModalInfo.vendor.mobile }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Status: </b></td>
                                        <td class="text-ogeo" v-if="ViewModalInfo.vendor.qualified_at">{{ (new Date(ViewModalInfo.vendor.lic_expiry_date) > Date.now()) ? 'Valid Qualification' : 'Expired Qualification' }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Activation Status: </b></td>
                                        <td class="" v-if="ViewModalInfo.vendor.qualified_at && ViewModalInfo.vendor.active">{{ ViewModalInfo.vendor.active == 'Yes' ? 'Active' : 'Inactive' }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Vendor Qualified On: </b></td>
                                        <td v-if="ViewModalInfo.type !== VQ_Actions_Typos[0] && ViewModalInfo.vendor.qualified_at">{{ ViewModalInfo.vendor.qualified_at | formatDateNTime }}</td>
                                        <td v-else class="text-ogeo">Qualification Awaited</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td width="40%"><b class="font-500">Applicable Library Of Materials Categories: </b></td>
                                        <td><ul class="p-0"><li v-for="(item, index) in ViewModalInfo.vendor.item_templates">{{ item.template_name }}</li></ul></td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>

                                </table>
                            </div>

                            <div class="col-lg-6">
                                <table class="table sptable">
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Name: </b></td>
                                        <td>{{ ViewModalInfo.vendor.bank_name }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Address: </b></td>
                                        <td>{{ ViewModalInfo.vendor.bank_address }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Bank Identifier Code (BIC) / SWIFT Code: </b></td>
                                        <td>{{ ViewModalInfo.vendor.bank_swift }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor International Bank Account Number (IBAN): </b></td>
                                        <td>{{ ViewModalInfo.vendor.bank_iban }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Was The IBAN Verified? </b></td>
                                        <td v-if="!isEmpty(ViewModalInfo.vendor.bank_iban_verify)">{{ ViewModalInfo.vendor.bank_iban_verify.verified == true ? 'Verified Using '+ViewModalInfo.vendor.bank_iban_verify.tool : 'Unverified' }}</td>
                                        <td v-else>Unverified</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Average Of The Last 03 Years' Turnover, In Thousands Of US Dollars: </b></td>
                                        <td>{{ formatPrice(ViewModalInfo.vendor.turnover) }} USD</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Type: </b></td>
                                        <td>{{ ViewModalInfo.vendor.vendor_type }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Vendor Business Activities: </b></td>
                                        <td>{{ ViewModalInfo.vendor.provided_service }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">What Can Be Sourced From The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.vendor.vendor_dealsIn }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500">Brands Represented By The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.vendor.sold_brands }}</td>
                                    </tr>
                                    <tr>
                                        <td><b class="font-500">Brands Exclusively Represented By The Vendor: </b></td>
                                        <td>{{ ViewModalInfo.vendor.exculsive_brands }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <th class="font-700">User Full Name</th>
                                                    <th class="font-700">User Status</th>
                                                    <th class="font-700">User Role</th>
                                                    <th class="font-700">User Assigned At</th>
                                                </thead>
                                                <tbody v-if="ViewModalInfo.vendor.users.length">
                                                    <tr v-for="user in ViewModalInfo.vendor.users">
                                                        <td>{{ user.name }}</td>
                                                        <td>{{ user.active == 'Yes' ? 'Active' : 'Inactive' }}</td>
                                                        <td v-if="user.pivot.sbm_role == 'Yes' || user.pivot.sim_role == 'Yes'">{{ user.pivot.sbm_role == 'Yes' ? 'Bid Manager, ' : ''  }}{{user.pivot.sim_role == 'Yes' ? 'Invoicing Manager' : ''}}</td><td v-else>N/A</td>
                                                        <td>{{ user.pivot.created_at | formatDateNTime }}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="4" align="center">No Representative Information Available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500 text-ogeo">Reason For The Request: </b></td>
                                        <td class="text-ogeo">{{ ViewModalInfo.reason }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr v-if="ViewModalInfo.type == VQ_Actions_Typos[1] || ViewModalInfo.type == VQ_Actions_Typos[7]">
                                        <td colspan="2">
                                            <b class="font-700 text-ogeo" >Modified Information: </b>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <th class="font-700" width="10%">#</th>
                                                    <th class="font-700">Field Description</th>
                                                    <th class="font-700">Old Value</th>
                                                    <th class="font-700">New Value</th>
                                                </thead>
                                                <tbody v-if="ViewModalInfo.data.auditLog">
                                                    <tr v-for="(item, index) in ViewModalInfo.data.auditLog">
                                                        <td>{{ index+1 }}</td>
                                                        <td>{{ item.desc }}</td>
                                                        <td v-if="ViewModalInfo.type == VQ_Actions_Typos[1]">{{ item.old_val ? item.old_val : 'Unspecified' }}</td>
                                                        <td v-else>{{ item.old_val.includes('SBM') ? 'Bid Manager Role, ': '' }} {{ item.old_val.includes('SIM') ? 'Invoicing Manager Role': '' }} {{ !item.old_val.includes('SIM') && !item.old_val.includes('SBM') ? 'Unspecified' : '' }}</td>
                                                        <td v-if="ViewModalInfo.type == VQ_Actions_Typos[1]">{{ item.new_val }}</td>
                                                        <td v-else>{{ item.new_val.includes('SBM') ? 'Bid Manager Role, ': '' }} {{ item.new_val.includes('SIM') ? 'Invoicing Manager Role': '' }}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="4" align="center">No Modification Information Available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.type == VQ_Actions_Typos[4] || ViewModalInfo.type == VQ_Actions_Typos[5] || ViewModalInfo.type == VQ_Actions_Typos[6] || ViewModalInfo.type == VQ_Actions_Typos[7]">
                                        <td colspan="2">
                                            <b class="font-700 text-ogeo" v-if="ViewModalInfo.type == VQ_Actions_Typos[4]">New Vendor Representative Details: </b>
                                            <b class="font-700 text-ogeo" v-else>Selected Vendor Representative Details: </b>
                                            <table class="table ">
                                                <tr>
                                                    <td><b class="font-500">Full Name: </b></td>
                                                    <td>{{ ViewModalInfo.vendor_rep.name }}</td>
                                                </tr>
                                                <tr>
                                                    <td><b class="font-500">Email Address: </b></td>
                                                    <td>{{ ViewModalInfo.vendor_rep.email }}</td>
                                                </tr>
                                                <tr>
                                                    <td><b class="font-500">Bid Manager Role ? </b></td>
                                                    <td>{{ ViewModalInfo.vendor_rep.pivot.sbm_role == 'Yes' ? 'Yes' : 'No' }}</td>
                                                </tr>
                                                <tr>
                                                    <td><b class="font-500">Invoicing Manager Role ? </b></td>
                                                    <td>{{ ViewModalInfo.vendor_rep.pivot.sim_role == 'Yes' ? 'Yes' : 'No' }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.type == VQ_Actions_Typos[1] || ViewModalInfo.type == VQ_Actions_Typos[7]"><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td><b class="font-500 text-ogeo">Vendor Qualification Expiry Date: </b></td>
                                        <td class="text-ogeo">{{ ViewModalInfo.vendor.lic_expiry_date | formatDateNoTime }}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>

                                </table>

                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <h4 class="modal-title text-ogeo">Vendor Qualification Documents:</h4>
                                <div class="col-lg-12">

                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">File Name</th>
                                            <th class="font-700">Uploaded At</th>
                                            <th class="font-700">Action</th>
                                        </thead>
                                        <tbody v-if="VQDocsWNames.length && ((VendorInfo.hasOwnProperty('VendorDocuments') && VendorInfo.VendorDocuments.length) || (ViewModalInfo.vendor.hasOwnProperty('qualification_documents') && ViewModalInfo.vendor.qualification_documents.length))">
                                            <tr v-for="document in VQDocsWNames" v-if="document.hasOwnProperty('uploaded')">
                                                <td>{{ document.document_name }}</td>
                                                <td v-if="document.hasOwnProperty('uploaded')">{{ document.uploaded.hasOwnProperty('created_at')?document.uploaded.created_at:ViewModalInfo.created_at }}</td>
                                                <td v-else>N/A</td>
                                                <td>
                                                    <a v-if="document.hasOwnProperty('uploaded')" class="btn btn-success" :href="'/uploads/vendorsQualificationDocs/' + (document.hasOwnProperty('uploaded') && document.uploaded.hasOwnProperty('auto_generated_name') ? document.uploaded.auto_generated_name:document.uploaded.storedName) " download> Download</a>
                                                    <span v-else>No Document Available</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" align="center">No Qualification Documents Available</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>

                            <div class="col-lg-12" v-if="ViewModalInfo.type !== VQ_Actions_Typos[0]">
                                <hr>
                                <h4 class="modal-title text-ogeo">Total Purchase Orders Since Qualificatiion:</h4>
                                <div class="col-lg-12">

                                    <table class="table table-bordered">
                                        <thead>
                                            <th class="font-700">Setup Name</th>
                                            <th class="font-700">Month-To-Date Value + Currency</th>
                                            <th class="font-700">Year-To-Date Value + Currency</th>
                                        </thead>
                                        <tbody v-if="ViewModalInfo.vendor.purchase_orders">
                                            <tr v-for="document in ViewModalInfo.vendor.qualification_documents">
                                                <td colspan="3" align="center">Data Computation Error</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3" align="center">No Data Available</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>


                            <div class="col-lg-12" v-if="editDataLoaded">
                                <hr>
                                <!-- Show approval form -->
                                <div class="row" v-if="(EditModalInfo.status.indexOf('Created and Assigned to ') !== -1 || EditModalInfo.status.indexOf('for Further Approval') !== -1 || EditModalInfo.status.indexOf('Resubmitted and Assigned') !== -1 || EditModalInfo.status.indexOf('for Review') !== -1 || (EditModalInfo.status.includes('Rejected')) && showAmend == false)">
                                    <div class="col-lg-12" v-if="showAmend == false">

                                        <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                            <div class="row">
                                                <div class="col-lg-12">

                                                    <h3 class="text-ogeo">Decision:</h3>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[0]">A Rejected Request To Qualify A New Vendor Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. All The Data Pertaining To This Vendor (Names, Officers, Address, Type, Trade License Information, Locations Of Operations, Bank Information,…) & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[1]">A Rejected Request To Amend A Vendor Information Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Data Whose Amendment Was Required In The Request & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[2]">A Rejected Request To Inactivate A Vendor Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Name & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[3]">A Rejected Request To Activate A Vendor Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Name & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[4]">A Rejected Request To Add A New Vendor Representative Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Representative Details (Name, Email Address) & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[5]">A Rejected Request To Inactivate A Vendor Representative Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Representative (Name, Email Address) & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[6]">A Rejected Request To Activate A Vendor Representative Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Representative Details (Name, Email Address) & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[7]">A Rejected Request To Change A Vendor Representative Role Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Only The Vendor Representative Details (Name, Email Address), The Role & The Reason For The Request Can Be Amended.</b>
                                                    <b class="text-ogeo" v-if="EditModalInfo.type == VQ_Actions_Typos[8]">A Rejected Request To Renew An Expired A Vendor Qualification Can Either Be Canceled, Or Be Amended And Resubmitted For Validation. Except from the Vendor Name & The Alias, All The Other Data Pertaining To This Vendor (Officers, Address, Type, Trade License Information, Locations Of Operations, Bank Information,…) & The Reason For The Request Can Be Amended.</b><br><br>

                                                    <div class="col-lg-12">
                                                        <div class="grid-content">
                                                            <el-form-item prop="DecisionNotes">

                                                                <el-input
                                                                type="textarea"
                                                                :rows="5"
                                                                placeholder="Enter Decision Notes"
                                                                v-model="ValidationInfoApproval.DecisionNotes">
                                                                </el-input>
                                                            </el-form-item>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-6 float-right" v-if="!EditModalInfo.status.includes('Rejected')">
                                                        <button type="button" @click="DecisionApprove" class="btn btn-block btn-success waves-effect" style="background-color: #32CD32; border-color: #32CD32;">Approve</button>
                                                    </div>

                                                    <div class="col-lg-6 float-right" v-if="EditModalInfo.status.includes('Rejected')">
                                                        <button type="button" @click="showAmend = true" style="background-color: green; border-color: green;" class="btn btn-block btn-success waves-effect">Amend The Request For {{ EditModalInfo.type }}</button>
                                                    </div>

                                                    <div class="col-lg-6 float-right" v-if="EditModalInfo.status.includes('Rejected')">
                                                        <button type="button"  @click="DecisionCancel" class="btn btn-block btn-danger waves-effect">Cancel The Request For {{ EditModalInfo.type }}</button>
                                                    </div>

                                                    <div class="col-lg-6 float-left" v-if="!EditModalInfo.status.includes('Rejected')">
                                                        <button type="button" @click="DecisionReject" class="btn btn-block btn-danger waves-effect">Reject</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </el-form>

                                    </div>

                                </div>

                                <!-- <div class="col-lg-12 ModifyRejectedInfo" v-if="showAmend">
                                    <h4>Ammend Information</h4>

                                </div> -->


                                <!-- Form for update and Resubmitt -->
                                <div class="col-lg-12 pb-5 pt-2" v-else>
                                    <h4 class="modal-title text-ogeo pb-4">Amend Information and Resubmit:</h4>

                                    <el-form v-if="EditModalInfo.type == VQ_Actions_Typos[0] || EditModalInfo.type == VQ_Actions_Typos[1] || EditModalInfo.type == VQ_Actions_Typos[8]" :model="VendorInfo" class="demo-form-inline" :rules="rules" ref="VendorRequestModificationForm">
                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="name">
                                                        <span slot="label"><b>Enter Vendor Legal Name</b></span>
                                                        <el-input v-model="VendorInfo.name" placeholder="Enter Vendor Legal Name" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Legal Name', 'name', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="vendor_alias">
                                                        <span slot="label"><b>Enter Vendor Alias</b></span>
                                                        <el-input v-model="VendorInfo.vendor_alias" placeholder="Enter Vendor Alias" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Alias', 'vendor_alias', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="phone">
                                                        <span slot="label"><b>Enter Vendor Phone Number</b></span>
                                                        <el-input v-model="VendorInfo.phone" placeholder="Enter Vendor Phone Number" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Phone Number', 'phone', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="address">
                                                        <span slot="label"><b>Enter Vendor Address</b></span>
                                                        <el-input v-model="VendorInfo.address" placeholder="Enter Vendor Adress" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Address', 'address', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="po_box">
                                                        <span slot="label"><b>Enter Vendor PO.Box</b></span>
                                                        <el-input v-model="VendorInfo.po_box" placeholder="Enter Vendor PO.Box" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor PO.Box', 'po_box', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item prop="country">
                                                        <span slot="label"><b>Select Vendor Country</b></span>
                                                        <el-select filterable collapse-tags id="country" class="country" style="width: 100%; padding: 0px" v-model="VendorInfo.country" placeholder="Select Country":disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="getCities($event); handleLogs('Vendor Country', 'country', $event);">

                                                            <el-option
                                                                v-for="(item,CompanyCountry) in countries"
                                                                :key="CompanyCountry"
                                                                :label="item.country_name"
                                                                :value="item.country_name">
                                                                <span style="float: left">{{ item.country_name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item prop="city">
                                                        <span slot="label"><b>Select Vendor City</b></span>
                                                        <el-select filterable style="width: 100%; padding: 0px;" class="city" id="city" v-model="VendorInfo.city" placeholder="Select City" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor City', 'city', $event)">

                                                            <el-option
                                                                v-for="(item,companyCity) in cities"
                                                                :key="companyCity"
                                                                :value="item.city_name"
                                                                :label="item.city_name">
                                                            <span style="float: left">{{ item.city_name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="officer_name">
                                                        <span slot="label"><b>Enter Vendor Highest Ranking Officer Name</b></span>
                                                        <el-input v-model="VendorInfo.officer_name" placeholder="Enter Vendor Highest Ranking Officer Name" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Highest Ranking Officer Name', 'officer_name', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="officer_phone">
                                                        <span slot="label"><b>Enter Vendor Highest Ranking Phone Number</b></span>
                                                        <el-input v-model="VendorInfo.officer_phone" placeholder="Enter Vendor Highest Ranking Phone Number" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Highest Ranking Phone Number', 'officer_phone', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="rep_name">
                                                        <span slot="label"><b>Enter Vendor Representative Name</b></span>
                                                        <el-input v-model="VendorInfo.rep_name" placeholder="Enter Vendor Representative Name" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Representative Name', 'rep_name', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="rep_title">
                                                        <span slot="label"><b>Enter Vendor Representative Title</b></span>
                                                        <el-input v-model="VendorInfo.rep_title" placeholder="Enter Vendor Representative Title" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Representative Title', 'rep_title', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="mobile">
                                                        <span slot="label"><b>Enter Vendor Representative Phone Number</b></span>
                                                        <el-input v-model="VendorInfo.mobile" placeholder="Enter Vendor Representative  Phone Number" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Representative Phone Number', 'mobile', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="bank_name">
                                                        <span slot="label"><b>Enter Vendor Bank Name</b></span>
                                                        <el-input v-model="VendorInfo.bank_name" placeholder="Enter Vendor Bank Name" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Bank Name', 'bank_name', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="bank_swift">
                                                        <span slot="label"><b>Enter Vendor Bank Identifier Code
                                                            (BIC)/SWIFT Code</b></span>
                                                        <el-input v-model="VendorInfo.bank_swift" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Bank Identifier Code', 'bank_swift', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="bank_address">
                                                        <span slot="label"><b>Enter Vendor Bank Address</b></span>
                                                        <el-input v-model="VendorInfo.bank_address" placeholder="Enter Vendor Bank Identifier Code (BIC)/SWIFT Code" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Bank Address', 'bank_address', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="bank_iban">
                                                        <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span>
                                                        <el-input v-model="VendorInfo.bank_iban" placeholder="Enter Vendor International Bank Account Number (IBAN)" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor International Bank Account Number', 'bank_iban', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="" prop="VendorBankIBANVerify">
                                                        <!-- <span slot="label"><b>Enter Vendor International Bank Account Number (IBAN)</b></span> -->
                                                        <el-checkbox class="chkAgree" v-model="VendorInfo.bank_iban_verify.verified" @change="VerifyIBAN($event)" label="I Have Verified The Vendor IBAN"/><br>
                                                        <!-- <el-checkbox class="chkAgree" :value="false" v-model="VendorInfo.VerifyIBAN.verified" label="I Have NOT Verified The Vendor IBAN"/> -->

                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="open_since">
                                                        <span slot="label"><b>Select Vendor Esablishment Year</b></span>
                                                        <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorInfo.open_since"  placeholder="Select Vendor Esablishment Year" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Establishment Year', 'open_since', $event)">
                                                            <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="turnover">
                                                        <span slot="label"><b>Enter Average of The Last 3 Years Turnover(in US Dollars)</b></span>
                                                        <el-input v-model="VendorInfo.turnover" @input="commaprice()" placeholder="Enter Average of The Last 3 Years Turnover" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Average Of The Last 3 Years Turnover', 'turnover', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item prop="operating_countries">
                                                        <span slot="label"><b>Select Countries Of Operations</b></span>
                                                        <el-select filterable multiple collapse-tags id="country" class="country" style="width: 100%; padding: 0px" v-model="VendorInfo.operating_countries" placeholder="Select Countries Of Operations" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="getMultiCities($event), handleLogs('Vendor Countries of Operation', 'operating_countries', $event)">
                                                            <el-option style="min-width:145px !important"
                                                                v-for="(item,CompanyCountry) in countries"
                                                                :key="CompanyCountry"
                                                                :label="item.country_name"
                                                                :value="item.country_name">
                                                                <span style="float: left;">{{ item.country_name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item prop="operating_cities">
                                                        <span slot="label"><b>Select Cities Where The Vendor Operates</b></span>
                                                        <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="VendorInfo.operating_cities" placeholder="Select City" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Cities Of Operation', 'operating_cities', $event)">

                                                            <el-option
                                                                v-for="(item,companyCity) in multiCities"
                                                                :key="companyCity"
                                                                :value="item.city_name"
                                                                :label="item.city_name">
                                                            <span style="float: left">{{ item.city_name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="url">
                                                        <span slot="label"><b>Enter Vendor Website</b></span>
                                                        <el-input v-model="VendorInfo.url" placeholder="http://www.vendorwebsite.com/otherurl/232/wsd" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Website', 'url', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="lic_num">
                                                        <span slot="label"><b>Enter Vendor License Number</b></span>
                                                        <el-input v-model="VendorInfo.lic_num" placeholder="Enter Vendor  License Number" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor License Number', 'lic_num', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="lic_authority">
                                                        <span slot="label"><b>Enter The Name Of The Trade License Issuing Authority</b></span>
                                                        <el-input v-model="VendorInfo.lic_authority" placeholder="Enter The Name Of The Trade License Issuing Authority" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor License Issuing Authority', 'lic_authority', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="vendor_type">
                                                        <span slot="label"><b>Select Vendor Type</b></span>
                                                        <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorInfo.vendor_type"  placeholder="Select Vendor Type" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Type', 'vendor_type', $event)">

                                                            <el-option label="Manufacturer" value="Manufacturer"></el-option>
                                                            <el-option label="Wholesaler" value="Wholesaler"></el-option>
                                                            <el-option label="Retailer" value="Retailer"></el-option>
                                                            <el-option label="Consultant" value="Consultant"></el-option>
                                                            <el-option label="Contractor" value="Contractor"></el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="provided_service">
                                                        <span slot="label"><b>Select Vendor Business Sector</b></span>
                                                        <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorInfo.provided_service"  placeholder="Select Vendor Business Sector" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Business Sector', 'provided_service', $event)">

                                                            <el-option label="Information Technology Services" value="Information Technology Services"></el-option>
                                                            <el-option label="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services" value="Professional (Legal, Marketing, Consultancy, Design, Project Management, Security, Gardening, Cleaning…) Services"></el-option>
                                                            <el-option label="Transportation Services" value="Transportation Services"></el-option>
                                                            <el-option label="Medicine And Wellness Services" value="Medicine And Wellness Services"></el-option>
                                                            <el-option label="Convenience Services" value="Convenience Services"></el-option>
                                                            <el-option label="Financial Services" value="Financial Services"></el-option>
                                                            <el-option label="Contracting Services" value="Contracting Services"></el-option>
                                                            <el-option label="Construction & Fit-out Services" value="Construction & Fit-out Services"></el-option>
                                                            <el-option label="Supply of Mechanical Spare Parts" value="Supply of Mechanical Spare Parts"></el-option>
                                                            <el-option label="Supply of Electrical Spare Parts" value="Supply of Electrical Spare Parts"></el-option>
                                                            <el-option label="Supply of Construction Materials" value="Supply of Construction Materials"></el-option>
                                                            <el-option label="Supply of Foodstuff & Edible Products" value="Supply of Foodstuff & Edible Products"></el-option>
                                                            <el-option label="Supply of Tools" value="Supply of Tools"></el-option>
                                                            <el-option label="Supply of OEM Equipment" value="Supply of OEM Equipment"></el-option>
                                                            <el-option label="Supply of Furniture" value="Supply of Furniture"></el-option>
                                                            <el-option label="Supply of Vehicles" value="Supply of Vehicles"></el-option>
                                                            <el-option label="Supply of Textile Products" value="Supply of Textile Products"></el-option>
                                                            <el-option label="Supply of IT Products" value="Supply of IT Products"></el-option>
                                                            <el-option label="Supply of Fossil Fuels" value="Supply of Fossil Fuels"></el-option>
                                                            <el-option label="Supply of Stationary Products" value="Supply of Stationary Products"></el-option>
                                                            <el-option label="Supply of Packaging Products" value="Supply of Packaging Products"></el-option>
                                                            <el-option label="Supply of Fragrance Products" value="Supply of Fragrance Products"></el-option>
                                                            <el-option label="Supply of Cosmetic Products" value="Supply of Cosmetic Products"></el-option>
                                                            <el-option label="Supply of Cleaning Products" value="Supply of Cleaning Products"></el-option>
                                                            <el-option label="Supply of Personal Hygienic Products" value="Supply of Personal Hygienic Products"></el-option>
                                                            <el-option label="Supply of Consumer Electronics Products" value="Supply of Consumer Electronics Products"></el-option>
                                                            <el-option label="Supply of Whitegoods Products" value="Supply of Whitegoods Products"></el-option>
                                                            <el-option label="Supply of Garment Products" value="Supply of Garment Products"></el-option>

                                                            <!-- Add Additional Options From Existing Vendors -->

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="sold_brands">
                                                        <span slot="label"><b>Enter Brands Represented By The Vendor In His Cities Of Operations</b></span>
                                                        <el-input v-model="VendorInfo.sold_brands" placeholder="Enter Brands Represented By The Vendor" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Brands Represented By The Vendor', 'sold_brands', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="exculsive_brands">
                                                        <span slot="label"><b>Enter Brands Exclusively Represented By The Vendor In His Cities Of Operations</b></span>
                                                        <el-input v-model="VendorInfo.exculsive_brands" placeholder="Brands Exclusively Represented By The Vendor" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Brands Exclusively Represented By The Vendor', 'exclusive_brands', $event)"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="vendor_dealsIn">
                                                        <span slot="label"><b>Select What Can Be Sourced From The Vendor</b></span>
                                                        <el-select filterable allow-create style="width: 100%; padding: 0px; min-height: 40px" v-model="VendorInfo.vendor_dealsIn"  placeholder="*Select What Can Be Sourced From The Vendor" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('What Can Be Sourced From Vendor', 'vendor_dealsIn', $event)">

                                                            <el-option label="Materials" value="Materials"></el-option>
                                                            <el-option label="Services" value="Services"></el-option>
                                                            <el-option label="Materials + Services" value="Materials + Services"></el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <transition v-if="VendorInfo.vendor_dealsIn == 'Materials' || VendorInfo.vendor_dealsIn == 'Materials + Services'" >
                                                <el-col :span="12">
                                                    <div class="grid-content">
                                                        <el-form-item label="temp" prop="linked_item_templates">
                                                            <span slot="label"><b>Select Applicable Library of Materials Templates</b></span>
                                                            <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                                </span>
                                                                <span class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <strong>I’m offered the possibility to link existing
                                                                        applicable templates to the vendor. These
                                                                        templates are pertaining to materials likely to be
                                                                        offered by the vendor.</strong>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <el-select id="SelectedItemTemplates" filterable multiple collapse-tags style="width: 100%; padding: 0px;" v-model="VendorInfo.linked_item_templates" placeholder="Select Library of Materials Template" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]" @change="handleLogs('Vendor Applicable Library of Material Templates', 'linked_item_templates', $event)">

                                                                <el-option v-for="Template in ItemTemplates" :key="Template.id" :label="Template.template_name" :value="Template.id"></el-option>

                                                            </el-select>
                                                        </el-form-item>
                                                    </div>
                                                </el-col>
                                            </transition>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr/>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <hr>
                                                    <h6 class="">Vendor Qualification Documents:</h6>

                                                        <table class="table table-bordered">
                                                            <thead class="text-center">
                                                                <th class="font-700">File Name</th>
                                                                <th class="font-700" width="50%">Action</th>
                                                            </thead>
                                                            <tbody v-if="VQReqDocNames.length">
                                                                <tr v-for="(item, index) in VQDocsWNames">
                                                                    <td>{{ item.document_name}}</td>
                                                                    <td v-if="item.uploaded" style="text-align:center" :class="'modal-vq-del'+index">
                                                                        <button style="width: 120px" type="button" class="btn btn-danger" @click="deleteDocument(index)" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]">Remove File</button>
                                                                    </td>
                                                                    <td v-else style="text-align:center" :class="'modal-vq-docs'+index">
                                                                        <el-upload
                                                                            style=""
                                                                            class="upload-demo d-inline-flex"
                                                                            name=""
                                                                            action="#"
                                                                            :limit="1"
                                                                            id='VQReqDocs'
                                                                            :auto-upload="false"
                                                                            ref="VQReqDocs"
                                                                            accept=".pdf"
                                                                            :on-change="handleChangeSD"
                                                                            :on-exceed="handleExceedSD"
                                                                            :on-remove="handleRemoveSD">
                                                                            <button style="width: 120px" type="button" slot="trigger" @click="UploadKeyName = {req_id: item.id, name: item.document_name}" class="btn btn-success" :disabled="EditModalInfo.type == VQ_Actions_Typos[8]">Upload File</button>

                                                                            <!-- <div class="el-upload__tip" slot="tip">JPEG, PNG, DOCX, PDF, XLSX file types are accepted with a size less than 1Mb (Max 4 Files)</div> -->
                                                                        </el-upload>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody v-else>
                                                                <tr>
                                                                    <td colspan="3" align="center">No Qualification Documents Available</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                </div>
                                                <!-- <div class="grid-content">
                                                    <el-form-item prop="VendorQualificationDocs">
                                                        <span slot="label"><b>“Upload The Required
                                                            Qualification Documents</b></span>

                                                            <ol class="mt-5">
                                                                <li style="width: auto;" v-for="(item, index) in VQReqDocNames">
                                                                    <h6 class="d-inline-block w-50">{{  item.document_name }}</h6>
                                                                    <el-upload
                                                                        style=""
                                                                        class="upload-demo d-inline-flex"
                                                                        name="Hello"
                                                                        action="#"
                                                                        :limit="1"
                                                                        id='VQReqDocs'
                                                                        :auto-upload="false"
                                                                        ref="VQReqDocs"
                                                                        accept=".pdf"
                                                                        :disabled="this.EditModalInfo.type == VQ_Actions_Typos[8]"
                                                                        :on-change="handleChangeSD"
                                                                        :on-exceed="handleExceedSD"
                                                                        :on-remove="handleRemoveSD">
                                                                        <button type="button" slot="trigger" @click="UploadKeyName = item.document_name" class="btn btn-success">Click to Select File</button>

                                                                    </el-upload>


                                                                </li>

                                                            </ol>
                                                        <div class="float-left">
                                                        </div>

                                                    </el-form-item>
                                                </div> -->
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="lic_expiry_date">
                                                        <span slot="label"><b>Select The Vendor’s Qualification Expiry Date</b></span>

                                                        <el-date-picker
                                                        :picker-options = "pickerOptions"
                                                        style="width: 100%"
                                                        v-model="VendorInfo.lic_expiry_date"
                                                        :disabled="EditModalInfo.type == VQ_Actions_Typos[1]"
                                                        @change="handleLogs('Vendor Qualification Expiry Date', 'lic_expiry_date', $event)"
                                                        type="date"
                                                        format="dd.MMM.yyyy"
                                                        value-format="dd-MM-yyyy">
                                                        </el-date-picker>

                                                    </el-form-item>
                                                </div>
                                                <h6 v-if="VendorInfo.lic_expiry_date">
                                                    Transactions With The Vendor Will Be suspended
                                                    When The Qualification Expires
                                                </h6>
                                            </el-col>


                                            <el-col :span="24">
                                                <hr>
                                            </el-col>

                                            <el-col :span="24">
                                                <br><hr>
                                                <button type="button" @click="ResubmitVendorManagementRequest('VendorRequestModificationForm')" :class="['btn btn-success btn-block waves-effect text-center']">Amend And Resubmit Request For Approval</button>
                                            </el-col>

                                        </el-row>
                                    </el-form>

                                    <el-form v-else-if="EditModalInfo.type == VQ_Actions_Typos[7]" :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="VendorRoleChangeRequestForm" id="VendorRoleChangeRequestForm">

                                        <el-row :gutter="24">


                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorID">
                                                        <span slot="label"><b>Select From The List Of Active Vendors</b></span>
                                                        <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="EditModalInfo.vendor_id" placeholder="Search and Select An Active Vendors" @change="getVendorRepList($event)">

                                                            <el-option
                                                                v-for="(item,index) in VendorsList"
                                                                v-if="item.active == 'Yes' && new Date(item.lic_expiry_date) > Date.now()"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id">
                                                                <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24" v-if="EditModalInfo.vendor_id">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorRepID">
                                                        <span slot="label"><b>Select From The List Of Active Vendor Representatives</b></span>
                                                        <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="EditModalInfo.vendor_rep_id" placeholder="Search and Select An Active Vendor Representative">

                                                            <el-option
                                                                v-for="(item,index) in VendorRepsList[0].users"
                                                                v-if="item.active == 'Yes'"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id">
                                                                <span style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="20" v-if="EditModalInfo.vendor_rep_id">
                                                <div class="grid-content" >
                                                    <el-form-item label="" prop="RoleChange">
                                                        <span slot="label"><b>Select Vendor Representative Role(s)</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="left: 200px">
                                                            <span class="tooltip-text2">
                                                                    The Vendor Bid Manager acts on behalf of the Vendor and, strictly through Ogeo, is responsible for submitting Sealed Bids, participating in Live Auctions, raising Requests For Information, and accepting Purchase Orders.     <br>        The Vendor Invoicing Manager is responsible for submitting, strictly through Ogeo, the Vendor's Invoices once the materials/services are received by my organization.
                                                            </span>
                                                        </span>
                                                        </span>
                                                        <div style="display: flex; justify-content: space-around; margin-top: 20px; width: 100%;">
                                                            <div style="text-align: center; flex: 1;">
                                                                <h6>Bid Manager Role</h6>
                                                                <el-switch v-model="EditModalInfo.data.RoleChange[0]" @change="handleRoleAssignment" active-value="SBM" :inactive-value="null" active-color="rgb(255, 47, 47)" inactive-color="rgb(220, 220, 220)" ></el-switch>
                                                            </div>
                                                            <div style="text-align: center; flex: 1;">
                                                                <h6>Invoicing Manager Role</h6>
                                                                <el-switch v-model="EditModalInfo.data.RoleChange[1]" @change="handleRoleAssignment" active-value="SIM" :inactive-value="null" active-color="rgb(255, 47, 47)" inactive-color="rgb(220, 220, 220)" ></el-switch>
                                                            </div>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="ReasonForRequest">
                                                        <span slot="label"><b>Enter Reason for Request</b></span>
                                                        <el-input v-model="EditModalInfo.reason" placeholder="Enter Reason For The Request"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span="24">
                                                <br><hr>
                                                <button type="button" @click="ResubmitVendorManagementRequest('VendorRoleChangeRequestForm')" :class="['btn btn-success btn-block waves-effect text-center']">Amend And Resubmit Request For Approval</button>
                                            </el-col>

                                        </el-row>
                                    </el-form>

                                    <el-form v-else-if="EditModalInfo.type == VQ_Actions_Typos[4]" :model="EditModalInfo.data.VendorRepInfo" class="demo-form-inline" :rules="rules" ref="VendorRepCreationForm" id="VendorRepCreationForm">

                                        <el-row :gutter="24">

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorId">
                                                        <span slot="label"><b>Select A Vendor to Create Users For</b></span>
                                                        <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="VendorRepInfo" placeholder="Search and Select A Vendor">

                                                            <el-option
                                                                v-for="(item,index) in VendorsList"
                                                                v-if="item.active == 'Yes' && new Date(item.lic_expiry_date) > Date.now()"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id">
                                                                <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorRepName">
                                                        <span slot="label"><b>Enter User’s Full Name</b></span>
                                                        <el-input v-model="VendorRepInfo.VendorRepName" placeholder="Enter User Name"
                                                            ></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorRepEmail">
                                                        <span slot="label"><b>Enter User's Email</b></span>
                                                        <el-input v-model="VendorRepInfo.VendorRepEmail" placeholder="Enter User Email"
                                                            ></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorRepRole">
                                                        <span slot="label"><b>Select User Role</b></span>
                                                        <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>

                                                        </span>
                                                        <span class="tooltip-content4 clearfix" style="left: 200px">
                                                            <span class="tooltip-text2">
                                                                    The Vendor Bid Manager acts on behalf of the Vendor and, strictly through Ogeo, is responsible for submitting Sealed Bids, participating in Live Auctions, raising Requests For Information, and accepting Purchase Orders.     <br>        The Vendor Invoicing Manager is responsible for submitting, strictly through Ogeo, the Vendor's Invoices once the materials/services are received by my organization.
                                                            </span>
                                                        </span>
                                                    </span>
                                                        <el-select filterable multiple collapse-tags style="width: 100%; padding: 0px;" @change="handleAlerts()" v-model="VendorRepInfo.VendorRepRole" placeholder="Select User Role">
                                                            <el-option label="Vendor’s Bid Manager" value="SBM"></el-option>
                                                            <el-option label="Vendor’s Invoicing Manager" value="SIM"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="ReasonForRequest">
                                                        <span slot="label"><b>Enter Reason for Request</b></span>
                                                        <el-input v-model="EditModalInfo.reason" placeholder="Enter Reason For The Request"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <br><hr>
                                                <button type="button" @click="ResubmitVendorManagementRequest('VendorRepCreationForm')" :class="['btn btn-success btn-block waves-effect text-center']">Amend And Resubmit Request For Approval</button>
                                            </el-col>

                                        </el-row>
                                    </el-form>

                                    <el-form v-else :model="EditModalInfo" class="demo-form-inline" :rules="rules" ref="ActivationToggleRequestForm" id="ActivationToggleRequestForm">

                                        <el-row :gutter="24">


                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorID">
                                                        <span slot="label"><b>Select From The List Of Vendors</b></span>
                                                        <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="EditModalInfo.vendor_id" placeholder="Search and Select A Vendors" @change="EditModalInfo.type.includes('Representative') ? getVendorRepList($event) : ''">

                                                            <el-option
                                                                v-for="(item,index) in VendorsList"
                                                                v-if="item.active == (EditModalInfo.type.includes('Inactivation') || EditModalInfo.type.includes('Representative') ? 'Yes' : 'No')  && new Date(item.lic_expiry_date) > Date.now()"
                                                                :key="index"
                                                                :label="item.name + ' ('+ item.vendor_alias +')'"
                                                                :value="item.id">
                                                                <span @click="selectedVendor.index = index" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }} ({{ item.vendor_alias ? item.vendor_alias : 'N/A' }})</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24" v-if="EditModalInfo.vendor_id && EditModalInfo.type.includes('Representative')">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="VendorRepID">
                                                        <span slot="label"><b>Select From The List Of Vendor Representatives</b></span>
                                                        <el-select filterable popper-class="VendorSelect" collapse-tags style="width: 100%; padding: 0px" v-model="EditModalInfo.vendor_rep_id" placeholder="Search and Select A Vendor Representative">

                                                            <el-option
                                                                v-for="(item,index) in VendorRepsList[0].users"
                                                                v-if="item.active == (EditModalInfo.type.includes('Inactivation') ? 'Yes':'No')"
                                                                :key="index"
                                                                :label="item.name"
                                                                :value="item.id">
                                                                <span @click="" style="display:block; float: left; width: 100%; height: 100%;">{{ item.name }}</span>
                                                            </el-option>

                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>

                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="ReasonForRequest">
                                                        <span slot="label"><b>Enter Reason for Request</b></span>
                                                        <el-input v-model="EditModalInfo.reason" placeholder="Enter Reason For The Request"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </el-col>


                                            <el-col :span="24">
                                                <br><hr>
                                                <button type="button" @click="ResubmitVendorManagementRequest('ActivationToggleRequestForm')" :class="['btn btn-success btn-block waves-effect text-center']">Amend And Resubmit Request For Approval</button>
                                            </el-col>

                                        </el-row>
                                    </el-form>
                                </div>
                            </div>


                            <div class="col-lg-12">
                                <hr>
                            </div>

                            <div class="col-lg-12">
                                <h3 class="text-ogeo">History</h3>
                                <table class="display table table-bordered" style="width:80vw !important;">
                                    <thead style="width:80vw !important;">
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Action Description</b></th>
                                            <th><b>Action By</b></th>
                                            <th><b>Action Level</b></th>
                                            <th><b>Decision-<i>Note</i></b></th>
                                            <th><b>Action Date</b></th>
                                            <th><b>Timespan Between Action</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.2);"><b>Next Action Approval Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td>{{ item.decision }} - <i>{{ item.decision_notes }}</i></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.2);">{{ item.next_approval_level }}</td>

                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-lg-12">
                                <hr>
                                <div class="d-inline-block">
                                    <span><b>Date:</b> {{ new Date() }}</span><br>
                                    <span><b>By:</b> {{ currentUser.Name }}</span><button class="btn btn-success ml-3" onclick="document.title = 'Vendor Information';window.print()">Download PDF</button>   <br><br>
                                </div>
                                <div class="d-inline-block pull-right">
                                    <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>

    import validate, { isEmpty } from 'validate.js';

    export default {
        name: 'VendorManagementRequests',
        data(){
            var CheckURL = (rule, value, callback) => {
                if (this.isUrlValid(value)) {
                    callback();
                } else {

                    if(value === '' || value === null){
                        callback();
                    } else {
                        callback(new Error('Please Enter Valide URL. (e.g. http://ogeo.me/example Or http://www.ogeo.me/example)'));
                    }

                }
            };
            var checkRoR = (rule, value, callback) => {
                if (this.VendorManagementRequestInfo.Reason === '' || this.VendorManagementRequestInfo.Reason === null) {
                    callback(new Error('Please Enter Reason For The Request.'));
                } else {
                    callback();
                }
            };
            var CheckAlias = (rule, value, callback) => {
                if (value === '' || value === null) {
                    callback(new Error('Vendor Alias is Required.'));
                } else {
                    this.checkVendorAlias(value);
                    setTimeout(() => {
                        if(this.flag){
                            callback();
                        }
                        else
                            callback(new Error('Vendor Alias Already Taken!'));
                    }, 500);
                }
            };
            var checkUserEmail = (rule, value, callback) => {
                if(value === '' || value === null)
                    callback(new Error('User Email is Required!'));
                else{
                    if(value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
                        callback();
                }
                else
                    callback(new Error('Please Enter Valid Email Format! (e.g john@doe.com)'));

                }
            };
            return{
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() < Date.now();
                  },
                },
                DataTable: "",
                OriginalName: "",
                CompanySetup: "",
                VQReqDocNames: "",
                VQDocsWNames: "",
                FilesToDelete: [],
                ReferenceFormName:"",
                UploadKeyName:"",
                auditLog: [],
                showAmend: false,
                viewDataLoaded: false,
                editDataLoaded: false,
                ViewModalInfo: {},
                VerifyInformation: false,
                ItemTemplates: [],
                countries: {},
                VendorSC: [],
                TmpVC: [],
                flag: "",
                existingRepRole: "",
                cities: [],
                multiCities: [],
                validatorsList: [],
                VendorRepInfo: {},
                BackupVendorRepInfo: {},
                VendorInfo: {},
                BackupVendorInfo: {},
                VendorsList: [],
                VendorRepsList: [],
                ValidationInfoApproval: {
                    VMRequestId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                VendorManagementRequestInfo: {
                    ProjectID: "",
                    VendorID: "",
                    VendorRepID: "",
                    formData: {},
                    Reason:"",
                    Type: ""
                },
                FieldOfBusiness: [
                    {name: "Information Technology Services"},
                    {name: "Professional Services - Legal"},
                    {name: "Professional Services - Marketing"},
                    {name: "Professional Services - Consultancy"},
                    {name: "Professional Services - Design"},
                    {name: "Professional Services - Project Management"},
                    {name: "Professional Services - Security"},
                    {name: "Professional Services - Gardening"},
                    {name: "Professional Services - Cleaning"},
                    {name: "Transportation Services"},
                    {name: "Medicine And Wellness Services"},
                    {name: "Convenience Services"},
                    {name: "Financial Services"},
                    {name: "Contracting Services"},
                    {name: "Construction & Fit-out Services"},
                    {name: "Supply of Mechanical Spare Parts"},
                    {name: "Supply of Electrical Spare Parts"},
                    {name: "Supply of Construction Materials"},
                    {name: "Supply of Foodstuff & Edible Products"},
                    {name: "Supply of Tools"},
                    {name: "Supply of OEM Equipment"},
                    {name: "Supply of Furniture"},
                    {name: "Supply of Vehicles"},
                    {name: "Supply of Textile Products"},
                    {name: "Supply of IT Products"},
                    {name: "Supply of Fossil Fuels"},
                    {name: "Supply of Stationary Products"},
                    {name: "Supply of Packaging Products"},
                    {name: "Supply of Fragrance Products"},
                    {name: "Supply of Cosmetic Products"},
                    {name: "Supply of Cleaning Products"},
                    {name: "Supply of Personal Hygienic Products"},
                    {name: "Supply of Consumer Electronics Products"},
                    {name: "Supply of Whitegoods Products"},
                    {name: "Supply of Garment Products"},
                ],
                EditModalInfo: {},
                VQ_Actions_Typos: [
                    'Qualification Of A New Vendor',
                    'Vendor Information Amendment',
                    'Vendor Inactivation',
                    'Vendor Reactivation',
                    'Addition Of A New Vendor Representative',
                    'Vendor Representative Inactivation',
                    'Vendor Representative Reactivation',
                    'Vendor Representative Role Change',
                    'Vendor Expired Qualification Renewal'
                ],
                rules: {
                    vendor_id: [{
                        required: true,
                        message: "Please Select A Vendor",
                        trigger: "blur"
                    }],
                    vendor_rep_id: [{
                        required: true,
                        message: "Please Select A Vendor Representative",
                        trigger: "blur"
                    }],
                    RoleChange: [{
                        required: true,
                        validator: (rule, value, callback) =>
                        {
                            if(this.EditModalInfo.data.RoleChange.includes('SBM')
                                || this.EditModalInfo.data.RoleChange.includes('SIM'))
                                callback();
                            else
                                callback(new Error('A Vendor Representative Role Is Required!'))
                        },
                        trigger: "blur"
                    }],
                    reason: [{
                        required: true,
                        validator: checkRoR,
                        trigger: "blur"
                    }],
                    VendorName: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    VendorAlias: [{
                        required: true,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    VendorRepName: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    VendorRepTitle: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    VendorAddress: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    VendorPOBOX: [{
                        required: false,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    VendorPhone: [{
                        required: false,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    VendorMobile: [{
                        required: false,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    VendorQualificationExpires: [{
                        required: true,
                        message: "Please Enter Vendor Qualification Expires",
                        trigger: "blur"
                    }],
                    VendorQualificationDocs: [{
                        required: true,
                        trigger: "blur"
                    }],
                    VendorOperationCountries: [{
                        required: true,
                        message: "Please Enter Country",
                        trigger: "blur"
                    }],
                    VendorOperationCities: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    VendorLicenseExpiryDate: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    VendorSource: [{
                        required: true,
                        message: "Please Enter Vendor Source",
                        trigger: "blur"
                    }],
                    VendorURL: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    VendorNotes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    VendorDocuments: [{
                        required: false,
                        message: "Please Upload At least one documenet",
                        trigger: "blur"
                    }],
                    VendorCity: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    VendorCountry: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    VendorProvidedServices: [{
                        required: false,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    VendorHighestRankingOfficerName: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    VendorHighestRankingOfficerPhoneNumber: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    VendorBankName: [{
                        required: true,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    VendorBankIBAN: [{
                        required: true,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    VendorSwiftCode: [{
                        required: true,
                        message: "Please Enter Vendor Bank Identifier Code",
                        trigger: "blur"
                    }],
                    VendorBankAddress: [{
                        required: true,
                        message: "Please Enter Vendor Bank Address",
                        trigger: "blur"
                    }],
                    VendorTurnover: [{
                        required: true,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "change"
                    }],
                    VendorOpenSince: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    VendorSoldBrands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    VendorExclusiveBrands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    SelectedItemTemplates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    VendorType: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                    VendorId: [{
                        required: true,
                        message: "Please Select A Vendor",
                        trigger: "blur"
                    }],
                    VendorRepName: [{
                        required: true,
                        message: "Please Enter User Name",
                        trigger: "blur"
                    }],
                    VendorRepEmail: [
                    {
                        required: true,
                        validator: checkUserEmail,
                        trigger: "blur"
                    }
                    ],
                    VendorRepRole: [{
                        required: true,
                        message: "Please Select Vendor Role",
                        trigger: ["blur"]
                    }],
                    // EditModalInfo RuleSet
                    name: [{
                        required: true,
                        message: "Please Enter Vendor Name",
                        trigger: "blur"
                    }],
                    vendor_alias: [{
                        required: true,
                        validator: CheckAlias,
                        trigger: "blur"
                    }],
                    rep_name: [{
                        required: true,
                        message: "Please Enter Vendor Representative Name",
                        trigger: "blur"
                    }],
                    rep_title: [{
                        required: false,
                        message: "Please Enter Vendor Representative Title",
                        trigger: "blur"
                    }],
                    address: [{
                        required: true,
                        message: "Please Enter Vendor Address",
                        trigger: "blur"
                    }],
                    po_box: [{
                        required: true,
                        message: "Please Enter Vendor PO.Box",
                        trigger: "blur"
                    }],
                    phone: [{
                        required: true,
                        message: "Please Enter Vendor Phone",
                        trigger: "blur"
                    }],
                    mobile: [{
                        required: true,
                        message: "Please Enter Vendor Mobile",
                        trigger: "blur"
                    }],
                    lic_expiry_date: [{
                        required: true,
                        message: "Please Enter License Expiry Date",
                        trigger: "blur"
                    }],
                    url: [{
                        required: false,
                        validator: CheckURL,
                        trigger: "blur"
                    }],
                    notes: [{
                        required: false,
                        message: "Please Enter Vendor Notes",
                        trigger: "blur"
                    }],
                    vendor_dealsIn: [{
                        required: true,
                        message: "Please Enter Vendor Source",
                        trigger: "blur"
                    }],
                    city: [{
                        required: true,
                        message: "Please Enter City",
                        trigger: "blur"
                    }],
                    country: [{
                        required: true,
                        message: "Please Select A Country",
                        trigger: "blur"
                    }],
                    operating_cities: [{
                        required: true,
                        message: "Please Enter Cities of Operation",
                        trigger: "blur"
                    }],
                    operating_countries: [{
                        required: true,
                        message: "Please Select A Countries of Operation",
                        trigger: "blur"
                    }],
                    provided_service: [{
                        required: true,
                        message: "Please Select A Field of Business",
                        trigger: "blur"
                    }],
                    officer_name: [{
                        required: false,
                        message: "Please Enter Highest Ranking Officer Name",
                        trigger: "blur"
                    }],
                    officer_phone: [{
                        required: false,
                        message: "Please Enter Vendor Highest Ranking Officer Phone Number",
                        trigger: "blur"
                    }],
                    bank_name: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_address: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_swift: [{
                        required: false,
                        message: "Please Enter Vendor Bank",
                        trigger: "blur"
                    }],
                    bank_iban: [{
                        required: false,
                        message: "Please Enter Vendor IBAN",
                        trigger: "blur"
                    }],
                    turnover: [{
                        required: false,
                        message: "Please Enter Average of The Last 03 years Turnover",
                        trigger: "blur"
                    }],
                    open_since: [{
                        required: false,
                        message: "Please Enter Vendor’s Operations Start Year",
                        trigger: "blur"
                    }],
                    sold_brands: [{
                        required: false,
                        message: "Please Enter Brands Sold By The Vendors",
                        trigger: "blur"
                    }],
                    exculsive_brands: [{
                        required: false,
                        message: "Please Enter Brands Exclusively Represented By The Vendor",
                        trigger: "blur"
                    }],
                    linked_item_templates: [{
                        required: false,
                        message: "Please Select Item Template",
                        trigger: "blur"
                    }],
                    vendor_type: [{
                        required: true,
                        message: "Please Select Vendor Type",
                        trigger: "blur"
                    }],
                },
                ApprovalRules: {
                    Decision: [{
                        required: true,
                        message: "Please select a approve or reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,
                        message: "Decision Notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        methods: {
            RemoveFile(DocumentId, Index){
                if (event) event.preventDefault();
                let self = this;

                Swal({
                    title: 'Delete This Document?',
                    text: "Are you sure you what to permanently delete this document.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {

                        axios.post('/api/vendors_management/remove_qualification_doc', {DocumentId: DocumentId})
                            .then((response) => {

                                Swal({
                                    type: response.data.messageType,
                                    title: response.data.messageTitle,
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 5000
                                });

                                self.EditModalInfo.qualification_documents.splice(Index, 1);
                            })
                    }

                })
            },
            isUrlValid(url) {
                return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
            },
            FileCount(file) {
                this.EditModalInfo.qualification_documents.push({ name:file.name.split('.').slice(0, -1).join('.'), ext: file.name.split('.').pop(), timestamp:moment().format('DD,MMM,YYYY')})
            },
            commaprice() {
                this.VendorInfo.turnover = this.VendorInfo.turnover.toString().replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
            getCountries() {
                axios.get('api/get/countries')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
            getCities(e) {
                // this.cities= this.countries[this.EditModalInfo.VendorCountry];
                axios.get('api/get/cities-solo/' + e)
                .then((res) => {
                    this.cities = res.data
                })
            },
            getMultiCities(e) {
                axios.post('api/get/cities-multi', e)
                .then((res) => {
                    this.multiCities = res.data
                })
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            checkVendorAlias(e){
                 axios.post('/api/vendors_management/check_vendor_alias_duplicates', {name: e, id: this.EditModalInfo.id})
                .then((response) => {
                    if(response.data == 'Yes') this.flag = false;
                    else this.flag = true;
                     });
            },
            VerifyIBAN(e){
                if(e){
                    var inputValueIbanCal = (this.VendorInfo.hasOwnProperty('bank_iban_verify') && this.VendorInfo.bank_iban_verify.tool) ? this.VendorInfo.bank_iban_verify.tool : '';
                    var inputTargetCal = document.getElementById('VMRequestModal');
                    Swal.fire({
                        title: "Verification",
                        text: "Enter The Tool Used To Check The IBAN:",
                        input: 'text',
                        target: inputTargetCal,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        confirmButtonText: "Submit",
                        inputValue: inputValueIbanCal,
                        inputPlaceholder: 'Enter the tool used to Verify IBAN!',
                        inputValidator: (value) => {
                                return !value && 'You need to write the tool used to verify IBAN!'
                            }
                    }).then((result) => {
                        if (result.value) {
                                this.EditModalInfo.bank_iban_verify.tool = result.value;
                        }
                    });
                }
                else{
                        this.VendorInfo.bank_iban_verify = {verified: false, tool: ""};
                }
            },
            getVendorRepList(e){
                axios.get( '/api/data/get_vendor_users/' + e)
                            .then((response)=>{
                                this.VendorRepsList = response.data;
                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                            });
            },
            handleLogs(n, ref ,nv) {
                var obj = {};
                if(this.EditModalInfo.type == this.VQ_Actions_Typos[7]){
                    obj = { desc: n, old_val: this.existingRepRole , new_val: nv};
                }
                else
                    obj = { desc: n, old_val: this.BackupVendorInfo[ref] , new_val: nv};
                var elementPos = this.auditLog.map(function(x) {return x.desc; }).indexOf(n);
                if(elementPos !== -1)
                    this.auditLog[elementPos] = obj;
                else
                    this.auditLog.push(obj);

            },
            handleChangeSD(file, fileList){

                const isPDF = file.raw.type === 'application/pdf';

                const isLt2M = file.raw.size / 1024 / 1024 < 1.1;

                if ((isPDF) && isLt2M) {

                // var array=this.VendorCreationInfo.VendorDocuments;
                var array = {};
                var filename = this.UploadKeyName.name;
                var req_id = this.UploadKeyName.req_id;
                const reader=new FileReader();
                reader.readAsDataURL(file.raw);
                reader.onload = (e) => {
                        var rawLog = reader.result;
                        Object.assign(array, {req_id: req_id, name: filename, data: rawLog});
                    };

                this.VendorInfo.VendorDocuments[file.uid] = array;

                }
                else {
                    this.$message.error('Document must be PDF format with size not exceeding 1MB!!');
                    fileList.splice(-1);
                    return false;
                }

            },
            handleExceedSD: function(files, fileList){
                Swal('The Limit is ' + fileList.length , 'You can’t upload more than 1 document.'  , 'error');
            },
            handleRemoveSD(file, fileList) {

                   delete this.VendorInfo.VendorDocuments[file.uid];


            },
            deleteDocument(e){
                Swal({
                        type: 'warning',
                        title: 'Warning!',
                        text: 'Are you sure you want to remove this Vendor Qualificaton Document?',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#FF0000",
                        confirmButtonColor: "#32CD32",
                    }).then((confirm) => {
                        if(confirm.value) {
                            var FileID = this.VQDocsWNames[e].uploaded.id;
                            this.FilesToDelete.push(FileID);
                            this.$delete(this.VQDocsWNames[e], 'uploaded');
                        }
                    });

            },
            EditVendorDetailsForm: function(){

                let self = this;

                if(this.VerifyInformation){

                    this.EditModalInfo.operating_countries = [];
                    this.Countries.some((el) => {
                                    if(this.VendorSC.includes(el.id))
                                        this.EditModalInfo.operating_countries.push(el.country_name);
                                    if(this.TmpVC == el.id)
                                        this.EditModalInfo.country = el.country_name;
                                });

                    if (event) event.preventDefault();

                    this.$refs.VendorEditRequestForm.validate((validation) => {

                        if(validation){


                            //Validate File Size
                            /*const isJPG = file.type === 'image/jpeg';
                            const isLt2M = file.size / 1024 / 1024 < 2;

                            if (!isJPG) {
                              this.$message.error('Avatar picture must be JPG format!');
                            }
                            if (!isLt2M) {
                              this.$message.error('Avatar picture size can not exceed 2MB!');
                            }
                            return isJPG && isLt2M;*/


                            axios.post('/api/vendors_management/check_vendor_name_duplicates', {name: this.EditModalInfo.name})
                                .then((response) => {

                                    if(response.data == 'Yes' && this.OriginalName != this.EditModalInfo.name){

                                        Swal({
                                            title: 'Vendor Name Duplicate!',
                                            text: "There is Another Vendor with the Same Name, Do You Want to Proceed?",
                                            type: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Yes',
                                            cancelButtonText: 'No'
                                        }).then((result) => {

                                            if (result.value) {

                                                let formData = new FormData();

                                                for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                                    let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                                    formData.append('FileUploads['+i+']', file);
                                                }


                                                formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));
                                                this.EditModalInfo.item_templates.forEach((data, index) => {
                                                    formData.append('EditModalInfo.item_templates[]', data);
                                                });

                                                axios.post( '/api/vendors_management/edit_vendor_details',
                                                    formData,
                                                    {
                                                        headers: {
                                                            'Content-Type': 'multipart/form-data'
                                                        }
                                                    }
                                                ).then(function(response){
                                                    Swal({
                                                        type: response.data.messageType,
                                                        title: response.data.messageTitle,
                                                        text: response.data.message,
                                                        showConfirmButton: true,
                                                        timer: 5000
                                                    });

                                                    self.VerifyInformation = false;

                                                    self.DataTable.ajax.reload( null, false );

                                                    $('#EditViewModal').modal('toggle');
                                                })
                                                .catch(function(){
                                                    Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                                });

                                            }

                                        })



                                    } else {

                                        let formData = new FormData();

                                        for( var i = 0; i < this.$refs.UploadDocuments.uploadFiles.length; i++ ){
                                            let file = this.$refs.UploadDocuments.uploadFiles[i].raw;
                                            formData.append('FileUploads['+i+']', file);
                                        }


                                        formData.append('EditModalInfo', JSON.stringify(this.EditModalInfo));

                                        this.EditModalInfo.item_templates.forEach((data, index) => {
                                                formData.append('EditModalInfo.item_templates[]', data);
                                            });
                                        axios.post( '/api/vendors_management/edit_vendor_details',
                                            formData,
                                            {
                                                headers: {
                                                    'Content-Type': 'multipart/form-data'
                                                }
                                            }
                                        ).then(function(response){
                                            Swal({
                                                type: response.data.messageType,
                                                title: response.data.messageTitle,
                                                text: response.data.message,
                                                showConfirmButton: true,
                                                timer: 5000
                                            });

                                            self.VerifyInformation = false;

                                            self.DataTable.ajax.reload( null, false );

                                            $('#EditViewModal').modal('toggle');
                                        })
                                        .catch(function(){
                                            Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem', 'error');
                                        });


                                    }

                                })






                        }

                    });
                }

            },
            handleExceed(files, fileList) {
                Swal('The Limit is 10 Files', 'The limit is 10 files per request, you selected ' + files.length + ' file(s) this time, adding up to '+ parseInt(files.length + fileList.length) + ' totally', 'warning')

            },
            handleRoleAssignment() {
                var arr = this.EditModalInfo.data.RoleChange.filter(item => item !== null);
                this.handleLogs('Vendor Representative Role', 'RoleChange', arr);
            },
            getVQReqDocNames: function(){
                axios.get('/api/vendors_management/get_vendor_qualification_req_docs')
                .then((response) => {
                    this.VQReqDocNames = response.data;
                })

            },
            loadValidations(d) {
                console.log(d);
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })

            },
            showViewModal(recordId){
                let self = this;
                self.ViewModalInfo = {};
                self.VQDocsWNames = [];
                self.VendorInfo = {};
                self.VendorRepInfo = {};
                axios.post('/api/data/get_vm_request_details', {RecordID: recordId})
                    .then((response) => {
                        self.ViewModalInfo = response.data;
                        if(self.ViewModalInfo.type == self.VQ_Actions_Typos[5] || self.ViewModalInfo.type == self.VQ_Actions_Typos[6] || self.ViewModalInfo.type == self.VQ_Actions_Typos[7])
                            self.ViewModalInfo.vendor_rep = self.ViewModalInfo.vendor.users.find(item => item.id === self.ViewModalInfo.vendor_rep_id);
                        if(self.ViewModalInfo.type == self.VQ_Actions_Typos[4]){
                            self.ViewModalInfo.vendor_rep = {};
                            self.ViewModalInfo.vendor_rep.name = self.ViewModalInfo.data.VendorRepInfo.VendorRepName;
                            self.ViewModalInfo.vendor_rep.email = self.ViewModalInfo.data.VendorRepInfo.VendorRepEmail;
                            self.ViewModalInfo.vendor_rep.pivot = {};
                            self.ViewModalInfo.vendor_rep.pivot.sbm_role = self.ViewModalInfo.data.VendorRepInfo.VendorRepRole.includes('SBM') ? 'Yes' : 'No';
                            self.ViewModalInfo.vendor_rep.pivot.sim_role = self.ViewModalInfo.data.VendorRepInfo.VendorRepRole.includes('SIM') ? 'Yes' : 'No';
                        }
                        if(self.ViewModalInfo.type == self.VQ_Actions_Typos[0] || self.ViewModalInfo.type == self.VQ_Actions_Typos[1] || self.ViewModalInfo.type == self.VQ_Actions_Typos[8])
                            self.VendorInfo = response.data.data.VendorInfo;
                        if(self.ViewModalInfo.vendor.bank_iban_verify)
                            self.ViewModalInfo.vendor.bank_iban_verify = JSON.parse(self.ViewModalInfo.vendor.bank_iban_verify);
                        self.VQReqDocNames.map((item, index) => {
                            self.VQDocsWNames.push(item);
                            var data = "";
                            if(self.ViewModalInfo.vendor.qualification_documents.length && self.ViewModalInfo.data.filesToDelete)
                                data = self.ViewModalInfo.vendor.qualification_documents.find(i => i.req_id === item.id && !(i.req_id in self.ViewModalInfo.data.filesToDelete))
                            else
                                data = self.ViewModalInfo.vendor.qualification_documents.find(i => i.req_id === item.id)
                            if(data)



                                self.VQDocsWNames[index].uploaded = Object.assign({}, data);
                            else {
                                if(self.VendorInfo.hasOwnProperty('VendorDocuments'))
                                    var newData = self.VendorInfo.VendorDocuments.find(i=>i.req_id === item.id);
                                if(newData)
                                    self.VQDocsWNames[index].uploaded = Object.assign({}, newData);
                            }
                        });
                        this.loadValidations(response.data);
                        this.viewDataLoaded = true;
                        $('#VMRequestModal').modal('toggle');
                    })
                this.viewDataLoaded = false;

            },
            editViewModal(recordId){
                let self = this;
                self.ViewModalInfo = {};
                self.showAmend = false;
                self.VQDocsWNames = [];
                self.VendorInfo = {};
                self.VendorRepInfo = {};
                axios.post('/api/data/get_vm_request_details', {RecordID: recordId})
                    .then((response) => {
                        self.ViewModalInfo = Object.assign({}, response.data);
                        self.EditModalInfo = response.data;
                        self.BackupModalInfo = Object.assign({}, response.data);
                        self.ValidationInfoApproval.VMRequestId = self.EditModalInfo.id;

                        if(self.ViewModalInfo.type == self.VQ_Actions_Typos[5] || self.ViewModalInfo.type == self.VQ_Actions_Typos[6] || self.ViewModalInfo.type == self.VQ_Actions_Typos[7])
                            self.ViewModalInfo.vendor_rep = self.ViewModalInfo.vendor.users.find(item => item.id === self.ViewModalInfo.vendor_rep_id);
                        if(self.ViewModalInfo.type == self.VQ_Actions_Typos[4]){
                            self.ViewModalInfo.vendor_rep = {};
                            self.ViewModalInfo.vendor_rep.name = self.ViewModalInfo.data.VendorRepInfo.VendorRepName;
                            self.ViewModalInfo.vendor_rep.email = self.ViewModalInfo.data.VendorRepInfo.VendorRepEmail;
                            self.ViewModalInfo.vendor_rep.pivot = {};
                            self.ViewModalInfo.vendor_rep.pivot.sbm_role = self.ViewModalInfo.data.VendorRepInfo.VendorRepRole.includes('SBM') ? 'Yes' : 'No';
                            self.ViewModalInfo.vendor_rep.pivot.sim_role = self.ViewModalInfo.data.VendorRepInfo.VendorRepRole.includes('SIM') ? 'Yes' : 'No';
                        }
                        if(self.EditModalInfo.type == self.VQ_Actions_Typos[0] || self.EditModalInfo.type == self.VQ_Actions_Typos[1] || self.EditModalInfo.type == self.VQ_Actions_Typos[8])
                            self.VendorInfo = response.data.data.VendorInfo;
                        if(self.EditModalInfo.type == self.VQ_Actions_Typos[4])
                            self.VendorRepInfo = response.data.data.VendorRepInfo;
                        if(self.VendorInfo.length)
                            self.getMultiCities(self.VendorInfo.operating_countries), self.getCities(self.VendorInfo.country)
                        if(self.EditModalInfo.type.includes('Representative') || self.EditModalInfo.type.includes('Role'))
                            self.getVendorRepList(self.EditModalInfo.vendor_id);
                        if(self.isEmpty(self.VendorInfo.bank_iban_verify))
                            self.VendorInfo.bank_iban_verify = {verified: false, tool: ''};
                        if(self.ViewModalInfo.vendor.bank_iban_verify)
                            self.ViewModalInfo.vendor.bank_iban_verify = JSON.parse(self.ViewModalInfo.vendor.bank_iban_verify);
                        // if(self.VendorInfo.provided_service)
                        //     self.VendorInfo.provided_service = self.VendorInfo.provided_service.split(',');
                        // if(self.VendorInfo.operating_countries)
                        //     self.VendorInfo = self.VendorInfo.operating_countries.split(', ');
                        // if(self.VendorInfo.operating_cities)
                        //     self.VendorInfo.operating_cities = self.VendorInfo.operating_cities.split(', ');
                        // if(self.EditModalInfo.lic_expiry_date)
                        //     self.EditModalInfo.lic_expiry_date = new Date(self.EditModalInfo.lic_expiry_date);
                        if(self.EditModalInfo.type == self.VQ_Actions_Typos[7]){
                            self.existingRepRole = Array.from(self.EditModalInfo.data.RoleChange);

                            var arr = [];
                            if(self.EditModalInfo.data.RoleChange.includes('SBM'))
                                arr[0] = 'SBM'
                            else arr[0] = null;
                            if(self.EditModalInfo.data.RoleChange.includes('SIM'))
                                arr[1] = 'SIM'
                            else arr[1] =  null;

                            self.EditModalInfo.data.RoleChange = arr;
                        }


                        self.VQReqDocNames.map((item, index) => {
                            self.VQDocsWNames.push(item);
                            var data = "";
                            if(self.ViewModalInfo.vendor.qualification_documents.length && self.ViewModalInfo.data.filesToDelete)
                                data = self.EditModalInfo.vendor.qualification_documents.find(i => i.req_id === item.id && !(i.req_id in self.EditModalInfo.data.filesToDelete))
                            else
                                data = self.EditModalInfo.vendor.qualification_documents.find(i => i.req_id === item.id)
                            if(data)
                                self.VQDocsWNames[index].uploaded = Object.assign({}, data);
                            else {
                                if(self.VendorInfo.hasOwnProperty('VendorDocuments'))
                                    var newData = self.VendorInfo.VendorDocuments.find(i=>i.req_id === item.id);
                                if(newData)
                                    self.VQDocsWNames[index].uploaded = Object.assign({}, newData);
                            }
                        });
                        // var TmpTemp = self.EditModalInfo.item_templates;
                        // self.EditModalInfo.item_templates = [];

                        // TmpTemp.forEach((data, index) => {
                        //     self.EditModalInfo.item_templates.push(data.id);
                        // });
                        this.BackupVendorInfo = Object.assign({}, this.VendorInfo);
                        this.BackupVendorRepInfo = Object.assign({}, this.VendorRepInfo);
                        this.loadValidations(response.data);
                        self.editDataLoaded = true;
                        self.viewDataLoaded = true;
                        $('#VMRequestModal').modal('toggle');
                    })

                this.viewDataLoaded = false;

            },
            handleRejected(){
                let self = this;

                axios.post('/api/vendors_management/update_new_vm_request', this.VMRequestInfo)
                    .then(function(response){
                        Swal({
                            type: response.data.messageType,
                            title: response.data.messageTitle,
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.ValidationInfoApproval.VMRequestId = "";
                        self.ValidationInfoApproval.Decision = "";
                        self.ValidationInfoApproval.DecisionNotes = "";

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                    });

                    $('#VMRequestModal').modal('toggle');

                this.ApprovalRules.DecisionNotes[0].required = false;


            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfoApproval.Decision = "Approved";
                axios.post('/api/vendors_management/validate_vm_request', this.ValidationInfoApproval)
                    .then(function(response){
                        Swal({
                            type: response.data.messageType,
                            title: response.data.messageTitle,
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.ValidationInfoApproval.VMRequestId = "";
                        self.ValidationInfoApproval.Decision = "";
                        self.ValidationInfoApproval.DecisionNotes = "";

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                    });

                    $('#VMRequestModal').modal('toggle');

                this.ApprovalRules.DecisionNotes[0].required = false;



            },
            DecisionReject(){
                let self = this;
                this.ApprovalRules.DecisionNotes[0].required = true;
                this.ValidationInfoApproval.Decision = "Rejected";
                this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/vendors_management/validate_vm_request', this.ValidationInfoApproval)
                            .then(function(response){
                                Swal({
                                    type: response.data.messageType,
                                    title: response.data.messageTitle,
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                self.ValidationInfoApproval.Decision = "";
                                self.ValidationInfoApproval.DecisionNotes = "";

                                self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                            });

                            $('#VMRequestModal').modal('toggle');

                        this.ApprovalRules.DecisionNotes[0].required = false;



                    }
                })
            },
            DecisionCancel(){
                Swal({
                    type: "warning",
                    title: "Are You Sure You Want to Permanently Cancel This Purchase Enquiry Line ?",

                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#FF0000",
                    confirmButtonColor: "#32CD32",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.ApprovalRules.DecisionNotes[0].required = true;
                        this.ValidationInfoApproval.Decision = "RejectedPermanent";
                        this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/vendors_management/validate_vm_request', this.ValidationInfoApproval)
                                    .then(function(response){
                                        Swal({
                                            type: response.data.messageType,
                                            title: response.data.messageTitle,
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.ValidationInfoApproval.VMRequestId = "";
                                        self.ValidationInfoApproval.Decision = "";
                                        self.ValidationInfoApproval.DecisionNotes = "";

                                        self.DataTable.ajax.reload( null, false );

                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Purchase Request Approval)', 'error');
                                    });

                                    $('#VMRequestModal').modal('toggle');

                                this.ApprovalRules.DecisionNotes[0].required = false;



                            }
                        })
                    }
                })
            },
            ResubmitVendorManagementRequest: function(formname){

                if(this.VendorManagementRequestInfo.Type == this.VQ_Actions_Typos[4]) this.VendorManagementRequestInfo.VendorID = this.VendorRepCreationInfo.VendorId;

                let self = this;

                this.$refs[formname].validate((validation) => {
                    if(validation){


                        axios.post( '/api/vendors_management/check_vendor_management_requests', self.VendorManagementRequestInfo)
                            .then(function(response){
                                if(response.data.messageType == 'danger'){
                                                Swal.fire({
                                                    type: response.data.messageType,
                                                    title: response.data.messageTitle,
                                                    text: response.data.message,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'OK',
                                                    timer: 15000
                                                })
                                            }
                                else{
                                    const handleCase = new Promise((resolve, reject) => {
                                        switch (self.EditModalInfo.type) {
                                            case 'Qualification Of A New Vendor':
                                            case 'Vendor Information Amendment':
                                            case 'Vendor Expired Qualification Renewal':
                                                self.EditModalInfo.data.VendorInfo = Object.assign({}, self.VendorInfo);
                                                self.EditModalInfo.data.filesToDelete = Object.assign({}, self.FilesToDelete);
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Inactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Reactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Addition Of A New Vendor Representative':
                                                    axios.post( '/api/vendors_management/check_vendor_user', self.VendorRepInfo)
                                                        .then(function(response){
                                                            if(response.data.messageType == 'error'){
                                                                Swal.fire({
                                                                                type: response.data.messageType,
                                                                                title: response.data.messageTitle,
                                                                                text: response.data.message,
                                                                                showConfirmButton: true,
                                                                                confirmButtonText: 'Acknowledged',
                                                                                timer: 15000
                                                                            });
                                                                reject('User Cannnot Be Register With Another Vendor')
                                                            }
                                                            else if(response.data.messageType == 'warning'){
                                                                            Swal.fire({
                                                                                type: response.data.messageType,
                                                                                title: response.data.messageTitle,
                                                                                text: response.data.message,
                                                                                showConfirmButton: true,
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'I Agree',
                                                                                cancelButtonText: 'I Disagree',
                                                                                timer: 15000
                                                                            }).then(function(confirmed){
                                                                                if(confirmed.value) {
                                                                                    self.EditModalInfo.data.VendorRepInfo = Object.assign({}, self.VendorRepInfo);
                                                                                    self.EditModalInfo.vendor_id = self.VendorRepInfo.VendorId;
                                                                                    self.EditModalInfo.vendor_rep_id = response.data.VendorUserID;
                                                                                    resolve('Confirmed');
                                                                                    }
                                                                                else {
                                                                                        Swal({
                                                                                            type: 'success',
                                                                                            title: 'No Action Done',
                                                                                            text:`You opted not to let the already resgistered used be assigned to this or any other vendor as Vendor Management User! Change email and try again.`,
                                                                                            showConfirmButton: true,
                                                                                            confirmButtonText: 'OK',
                                                                                        });
                                                                                        reject('NotConfirmed')
                                                                                        return false;
                                                                                    }
                                                                            });
                                                                        }
                                                            else{
                                                                self.EditModalInfo.data.VendorRepInfo = Object.assign({}, self.VendorRepInfo);
                                                                self.EditModalInfo.vendor_id = self.VendorRepInfo.VendorId;
                                                                self.EditModalInfo.vendor_rep_id = response.data.VendorUserID;
                                                                resolve('Confirmed');
                                                            }
                                                            });
                                                break;
                                            case 'Vendor Representative Inactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Representative Reactivation':
                                                resolve('Confirmed')
                                                break;
                                            case 'Vendor Representative Role Change':
                                                self.EditModalInfo.data.RoleChange = self.EditModalInfo.data.RoleChange.filter(arr => arr !== null);
                                                resolve('Confirmed')
                                                break;
                                            default:
                                                alert('Request Type Not Defined');
                                                reject('Type Not Specified')
                                                return false;

                                        }
                                        });
                                        if(Object.keys(self.auditLog).length) {
                                            self.EditModalInfo.data.auditLog = Object.assign({}, self.EditModalInfo.data.auditLog, self.auditLog);
                                        }
                                        handleCase.then((result) => {
                                            axios.post( '/api/vendors_management/update_new_vm_request', self.EditModalInfo)
                                                .then(function(response) {
                                                    if(response.data.messageType == 'success'){

                                                        Swal({
                                                                type: response.data.messageType,
                                                                title: response.data.messageTitle,
                                                                text: response.data.message,
                                                                showConfirmButton: true,
                                                                timer: 15000
                                                            }).then(result => {
                                                                self.$refs[formname].resetFields();
                                                                self.EditModalInfo = {};
                                                                self.DataTable.ajax.reload( null, false );
                                                                $('#VMRequestModal').modal('toggle');
                                                            });
                                                            }

                                                }.bind(self))
                                                .catch(error => {
                                                        console.error('Error occurred:', error);
                                                    });
                                            });
                                }
                        });

                    }
                });
            },
            ToggleActivation(recordId){
                let self = this;
                axios.post('/api/vendors_management/toggle_vendor_activation', {VendorID: recordId})
                    .then((response) => {

                        Swal({
                            type: response.data.messageType,
                            title: response.data.messageTitle,
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );

                    })
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
            years () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 1900}, (value, index) => 1901 + index)
            },

        },
        mounted(){
            $('#VMRequestModal').on('hidden.bs.modal', () => {
                this.ViewModalInfo = {};
                this.EditModalInfo = {};
                this.editDataLoaded = false;
                this.viewDataLoaded = false;
                });
            this.getVQReqDocNames();
            this.getCountries();
            let self = this;

            axios.get('/api/data/get_all_vendors_list')
                    .then((response) => {
                        this.VendorsList = response.data;
                    });

            axios.get('/api/data/get_item_template_list')
            .then((response) => {
                self.ItemTemplates = response.data;
            })
            axios.get('/api/data/get_company_details')
            .then((response) => {
                // console.log(response.data);
                self.CompanySetup = response.data;
            });
            setTimeout(() => {
            const table = $('#VMRequestsList').DataTable({
                fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },

                stateSave:  false,
                responsive: true,
                colReorder: true,
                processing: true,
                autoWidth: true,
                serverSide: true,
                ajax: '/api/data/get_vm_requests_datatable',
                columns: [
                    { data: 'id', name: 'id' },
                    { data: 'project.title', name: 'project.name' },
                    { data: 'requester.name', name: 'requester.name' },
                    { data: 'vendor.name', name: 'vendor.name' },
                    { data: 'reason', name: 'Reason' },
                    { data: 'vendorStatus', render: (data, type, full) => {
                        var exp = moment(full.vendor.lic_expiry_date);
                        var now = moment();
                        if(full.vendor){
                            if(full.type == this.VQ_Actions_Typos[0])
                                return "Vendor Qualification Awaited";
                            else
                                return full.vendor.status + ': <br><i>' + exp.format('DD.MMM.YYYY') + '</i> ' +(full.vendor.active == 'Yes' ? 'Active: Since ' +full.vendor.updated_at : 'Inactive: Since <br><i>' +full.vendor.updated_at+'</i>');
                            // else if(exp.diff(now, 'days') > 0 )return full.vendor.status + ' ' + exp.diff(now, 'days') +' day(s)';
                            // else if(exp.diff(now, 'days') <= 0 )return 'Qualification Expired';
                            // else return 'N/A'
                        }
                        else
                            return 'N/A'
                    } },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'updated_at', name: 'updated_at' },
                    { data: 'type', render: (data, row, full) => {
                        if (full.type == this.VQ_Actions_Typos[5] || full.type == this.VQ_Actions_Typos[6] || full.type == this.VQ_Actions_Typos[7]){
                            return data + ' (<i>'+ full.vendor_rep.name +'</i>)';
                        }
                        else
                            return data;
                    } },
                    { data: 'status', name: 'status' },
                    { data: 'action', name: 'action', orderable: false, searchable: false }
                ],
                columnDefs: [
                    {  targets: 10, responsivePriority: 1 },
                    {  targets: 0, responsivePriority: 2 },
                    {  targets: 9, responsivePriority: 4 },
                    {  targets: 8, responsivePriority: 3 },
                    {  targets: 3, width: '30%' },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 10,
                lengthMenu: [
                    [ 10, 25, 50, 100, 500, 1000, -1 ],
                    [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                ],
                order: [[ 0, "ASC" ]],
                buttons: [
                    { extend: 'pageLength', className: 'btn btn-success' },
                    { extend: 'copy', className: 'btn btn-success' },
                    { extend: 'excel', className: 'btn btn-success', title: 'VMRequestsList' },
                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function ( e, dt, node, config ) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
            });

            $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                var $this = $(this);
                var elquentClass = $(this).data('elquentclass');
                var RecordID = $this.data('recordid');
                self.showViewModal(RecordID);
            });

            $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                var $this = $(this);
                var RecordID = $(this).data('recordid');
                self.editViewModal(RecordID);
            });

            self.DataTable = table;


        }, 500);

        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>
<style>
@media print {
    html, body {
        visibility: hidden;

    }
  /* body * {
  } */
  #VMRequestModal, #VMRequestModal * {
    visibility: visible;
  }
  #VMRequestModal {
    position: relative;
    left: 0;
    top: -400mm;
  }
}



</style>
<style scoped>
    .sptable tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .font-500{
        font-weight: 700;
    }
    .font-700{
        font-weight: 700;
    }

    #VMRequestModal .modal-dialog {
        min-width:80%;
    }

    #EditViewModal .modal-dialog {
        min-width:80%;
    }

</style>
