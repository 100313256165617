import { render, staticRenderFns } from "./DemandsFromVendors.vue?vue&type=template&id=c804b87e&scoped=true&"
var script = {}
import style0 from "./DemandsFromVendors.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DemandsFromVendors.vue?vue&type=style&index=1&id=c804b87e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c804b87e",
  null
  
)

export default component.exports