<template>
	<!-- Validation wizard -->
	<div class="page-wrapper">
        <div class="container-fluid">
            <!-- <div class="row page-titles">
                <div class="col-md-12 col-lg-5 col-xlg-5 align-self-center">
                    <h3 class="text-themecolor m-b-0 m-t-0">Account Configuration</h3>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                        <li class="breadcrumb-item active">Account Configuration</li>
                    </ol>
                </div>
            </div> -->

            <!-- <div class="row page-titles">
            	<div class="col-md-12 col-lg-5 col-xlg-5 align-self-center">
		            <img :src="logoPreview" />
		        </div>
	        </div> -->
            
		    <div class="row">
		        <div class="col-12">
		            <div class="card">
		                <div class="card-body">
	
							<form-wizard title="Organization Regitration" subtitle="A Confguration Is Required Before Ogéo Can Be Utilized." ref="onboarding_wizard" @on-complete="onComplete" shape="circle" color="#FF2F2F" error-color="#ff4949">

							    <tab-content title="Organization Details" icon="fa fa-university" :before-change="validateFirstStep"  > <!-- :before-change="validateFirstStep" -->
							    	<hr>
							    	
								    <el-form :model="FirstStepData" class="demo-form-inline" :rules="rules1" ref="AccountSettingForm1">

								    	<el-row :gutter="20">
								    		
								    		<el-col :span="8">
												<div class="grid-content">
													
													<el-form-item prop="companyName">
														<span slot="label"><b>Organization Name</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">This is the name of my organization as it will appear in all transaction documents, reports, etc...</span>
															</span>
                                    					</span>
											        	<el-input v-model="FirstStepData.companyName" placeholder="Enter The Organization Name"></el-input>
											        </el-form-item>
												</div>
											</el-col>
											<!--<el-col :span="6">
												<div class="grid-content">
													<el-form-item  prop="TimeZone">
														<span slot="label"><b>TimeZone</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span class="tooltip-content4 clearfix">
																<span class="tooltip-text2">These are the lists of TimeZones.</span>
															</span>
                                    					</span>
													    <el-select filterable collapse-tags style="width: 100%; padding: 0px;" v-model="FirstStepData.companyTime" placeholder="Select TimeZone">

													    	<el-option v-for="industry in AllTimeZone"
													    		:key="industry"
															    :label="industry"
															    :value="industry">
													    	</el-option>

													    </el-select>
											      	</el-form-item>
												</div>
											</el-col>-->


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item prop="CompanyEmployeeRange">
														<span slot="label"><b>Organization Size</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<!-- <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span> -->
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">This is the range of employee numbers that work for my organization.</span>
															</span>
                                    					</span>
													    <el-select filterable style="width: 100%" v-model="FirstStepData.CompanyEmployeeRange" placeholder="Choose A Range Of Employee Numbers">
		
													    	<el-option
													    		v-for="item in EmployeeRange"
															    :key="item.value"
															    :label="item.label"
															    :value="item.value">
													    		<span style="float: left">{{ item.label }}</span>
      															<span style="float: right; color: #8492a6; font-size: 13px">Employees</span>
													    	</el-option>
				                                            
													    </el-select>
											      	</el-form-item>
												</div>
											</el-col>


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item prop="CompanyIndustry">
														<span slot="label"><b>Organization Industry(ies)</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">These is (are) the industry(ies) in which my organization operates.</span>
															</span>
                                    					</span>
													    <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyIndustry" placeholder="Choose Industry9ies)">

													    	<el-option v-for="industry in Industries"
													    		:key="industry.index"
															    :label="industry"
															    :value="industry">
													    	</el-option>

													    </el-select>
											      	</el-form-item>
												</div>
											</el-col>

											

											<el-col :span="12">
												<div class="grid-content">
													<el-form-item prop="CompanyCountries">
														<span slot="label"><b>Select Country(ies)</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">These will be the names of the country(ies) out of which the organization operates.</span>
															</span>
                                    					</span>
													    <el-select filterable collapse-tags multiple @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountries" placeholder="Choose Country(ies)">
															
													    	
															<el-option
													    		v-for="item in countries"
															    :key="item.id"
															    :label="item.country_name"
															    :value="item.id">
													    		<span style="float: left">{{ item.country_name }}</span>
													    	</el-option>

															
														</el-select>
											      	</el-form-item>
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content">
													<el-form-item prop="CompanyCities">
														<span slot="label"><b>Select City(ies)</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">These will be the names of the city(ies) out of which the organization operates.</span>
															</span>
                                    					</span>
													    <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCities" placeholder="Choose City(ies)">
															<span slot="label"><b>Select City(ies)</b></span>
													    	<el-option
														      v-for="city in cities"
														      :key="city.id"
															  :value="city.city_name"
														      :label="city.city_name">
															<span style="float: left">{{ city.city_name }}</span>
														    </el-option>
															
														</el-select>
											      	</el-form-item>
												</div>
											</el-col>
											</el-row>
	                                      
										  <el-row :gutter="20">
											<el-col :span="12">
												<div class="grid-content">
													
													<div v-if="this.logopreview">
														<img :src="'/uploads/LogosPreview/'+this.logopreview" style="width:150px;height:150px;border-radius:10px" v-if="this.logopreview">
														<i @click="deleteLogoPreview()" class="el-icon-delete" style="margin-left:-98px;font-size:35px;color:red;cursor:pointer"></i>
													</div>	
													<el-form-item v-show="this.logopreview == ''">
													<span slot="label"><b>Upload The Organization Logo</b></span>
													<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">This the organization logo as it will appear on the Ogéo transaction.</span>
															</span>
                                    					</span>
												
														<el-upload
															action="#"
															:file-list="fileList"
															list-type="picture-card"
															:limit="1"
															id='CompanyLogo'
															:auto-upload="false"
															ref="CompanyLogo"
															v-model="FirstStepData.CompanyLogo"
															accept=".jpg, .jpeg, .png"
															:on-exceed="handleExceed"
															:on-change="handleEditChange"
															:on-remove="handleRemove">
															<i class="el-icon-plus"></i>
														</el-upload>
												
													
													
													</el-form-item>	
					                                
													<span>You can upload 1 logo with a maximum size of 1 MB.</span>
											      	
												</div>
											</el-col>

										</el-row>
									        
								    </el-form>
							    </tab-content>

							    <tab-content title="Underlying Dealings & Transactions Labeling" icon="fa fa-tags" :before-change="validateSecondStep"> <!-- :before-change="validateSecondStep" -->
								<hr>
							    	<el-form :model="SecondStepData" class="demo-form-inline" :rules="rules2" ref="AccountSettingForm2">
										<el-row :gutter="20">
								    		<el-col :span="12">
												<div class="grid-content">
													<el-form-item prop="companyAdditionalField">
														<span slot="label"><b>Mandatorily Link <i>Purchase Enquiries</i> to Underlying Dealings?</b></span>

														<!-- <span style="display: inline-block; font-size: 14px; text-align: left; line-height: 100%; color: #606266; font-weight: 400" ><span style="color: red">*</span> Mandatorily link Purchase Enquiries to underlying transactions? -->

															<span style="z-index: 1;" class="mytooltip tooltip-effect-1">
																<span class="tooltip-item2">
																	<span class="fa-stack fa-1x">
																	  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																	  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																</span>
																<span style="bottom: 20px; left: -280px; width: 700px;" class="tooltip-content4 clearfix">
																	<span class="tooltip-text2">I’m offered the possibility to impose that an underlying dealing is compulsory referred to, whenever a <i>Purchase Enquiry</i> is initiated by my organization.<hr> 
																	Selecting 'Yes' will make it easy to link the transactions undertaken by my organization on Ogéo (<i>Purchase Enquiries, Requests For Proposals, Purchase Orders …</i>) to any underlying dealing reference captured at the time of the 
																	<i>Purchase Enquiry</i> initiation. Examples of such underlying dealings can include: Job Tickets, Work Orders, Sales Orders …</span>
																</span>
	                                    					</span>
	                                    				<!-- </span> -->
														
														


					                                    <el-select style="width: 100%; padding: 0px;" v-model="SecondStepData.companyAdditionalField" placeholder="Choose 'Yes' or 'No'">
																	
														    <el-option label="Yes" value="Yes"></el-option>
														    <el-option label="No" value="No"></el-option>
															    
														</el-select>

											        </el-form-item>
												</div>
											</el-col>


											<el-col :span="12">
												<div class="grid-content">
													<el-form-item prop="companyStoreAdditionalField">
														<span slot="label"><b>Mandatorily Link <i>Storerooms Items Issue Notes</i> to Underlying Dealings?</b></span>

														<!-- <span style="display: inline-block; font-size: 14px; text-align: left; line-height: 100%; color: #606266; font-weight: 400" ><span style="color: red">*</span> Mandatorily link Purchase Enquiries to underlying transactions? -->

															<span style="z-index: 1;margin:-10px" class="mytooltip tooltip-effect-1">
																<span class="tooltip-item2">
																	<span class="fa-stack fa-1x">
																	  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																	  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																</span>
																<span style="bottom: 20px; left: -450px; width: 700px;" class="tooltip-content4 clearfix">
																	<span class="tooltip-text2">I’m offered the possibility to impose that an underlying dealing is compulsory referred to, whenever a <i>Storeroom Item Issue Note</i> 
																	is initiated by my organization. <hr>Examples of such underlying dealings can include: Job Tickets, Work Orders, Sales Orders …</span>
																</span>
	                                    					</span>
	                                    				<!-- </span> -->
														

					                                    <el-select style="width: 100%; padding: 0px;" v-model="SecondStepData.companyStoreAdditionalField" placeholder="Choose 'Yes' or 'No'">
																	
														    <el-option label="Yes" value="Yes"></el-option>
														    <el-option label="No" value="No"></el-option>
															    
														</el-select>

											        </el-form-item>
												</div>
											</el-col>
										</el-row>
											
										<hr>
										<el-row :gutter="20">
											
								    		<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companyPEPrefix">
														<span slot="label"><b><i>Purchase Enquiry</i></b><b> Prefix</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<!-- <span class="tooltip-item2"><i class="fa fa-info-circle fa-lg text-success"></i></span> -->
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Purchase Enquiries</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Purchase Enquiries</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: PE, PR, …), followed with serial numbers.</span>
															</span>
                                    					</span>
														
											        	<el-input v-model="SecondStepData.companyPEPrefix" placeholder="Enter A Prefix For The Purchase Enquiries"></el-input>
											        </el-form-item>
												</div>
											</el-col>

											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companyRFQPrefix">
														<span slot="label"><b><i>Request for Proposals</i></b> <b>Prefix</b></span>
														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Requests For Proposals</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Requests For Proposals</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: RFP, RFQ, RfP …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companyRFQPrefix" placeholder="Enter A Prefix For The Requests For Proposals"></el-input>
											        </el-form-item>
												</div>
											</el-col>


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companyComEvalPrefix">
														<span slot="label"><b><i>Commercial Evaluation</i></b> <b>Prefix</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;left: -10px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Commercial Evaluations</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Commercial Evaluations</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: CE, CEv, …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companyComEvalPrefix" placeholder="Enter A Prefix For The Commercial Evaluations"></el-input>
											        </el-form-item>
												</div>
											</el-col>


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companyPOPrefix">
														<span slot="label"><b><i>Purchase Order</i></b> <b>Prefix</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Purchase Orders</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Purchase Orders</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: PO, …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companyPOPrefix" placeholder="Enter A Prefix For The Purchase Orders"></el-input>
											        </el-form-item>
												</div>
											</el-col>


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companySTRIPrefix">
														<span slot="label"><b><i>Storeroom Item Issue Note</i></b> <b>Prefix</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Storeroom Items Issue Notes</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Stotreroom Items Issue Notes</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: SIIN …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companySTRIPrefix" placeholder="Enter A Prefix For The Storeroom Item Issue Notes"></el-input>
											        </el-form-item>
												</div>
											</el-col>


											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companySTRRPrefix">
														<span slot="label"><b><i>Storeroom Item Return Note</i></b> <b>Prefix</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px; left: -20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Storeroom Items Return Notes</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Stotreroom Items Return Notes</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: SIRN …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companySTRRPrefix" placeholder="Enter A Prefix For The Storeroom Item Return Notes"></el-input>
											        </el-form-item>
												</div>
											</el-col>

											<el-col :span="8">
												<div class="grid-content">
													<el-form-item label="temp" prop="companyRNPrefix">
														<span slot="label"><b><i>Receipt Note</i></b> <b>Prefix</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the way Ogéo labels <i>Receipt Notes</i> 
																created by my organization. <hr>All the sequential labels, assigned by Ogéo to <i>Receipt Notes</i>, will begin with a prefix of my choosing, 
																made of 03 or less characters (Examples: RN, GRN, SRN …), followed with serial numbers.</span>
															</span>
                                    					</span>

											        	<el-input v-model="SecondStepData.companyRNPrefix" placeholder="Enter A Prefix For The Receipt Notes"></el-input>
											        </el-form-item>
												</div>
											</el-col>
											
											<el-col :span="16">
												<div class="grid-content">
													<el-form-item prop="CustomizationNumbering">
														<span slot="label"><b>Use An Alternative Numbering for Labeling the Above Transactions?</b></span>
														<span style="z-index: 10000;" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																	<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																	<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<span style="bottom: 20px; left: -10px; width: 500px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to personalize the serial numbers which follow the selected 
																	transactions' prefixes.<hr> This customization will impose the following format: “YYYYMM.Number”, where "YYYYMM" is the 
																	year & month of the transaction initiation, while the "Number" is a serial sequential number generated by Ogéo. 
																	<br>For example, should I select “PE” as a prefix for my organization’s <i>Purchase Enquiries</i>, and respond 
																	“Yes” to this question, the sixth Purchase Enquiry originated during the month of April 2025 will bear "PE 202504.6" as a label on Ogéo.</span>
															</span>
														</span>
					                                    <el-select style="width: 100%; padding: 0px;" v-model="SecondStepData.CustomizationNumbering" placeholder="Choose 'Yes' or 'No'">	
														    <el-option label="Yes" value="Yes"></el-option>
														    <el-option label="No" value="No"></el-option>
														</el-select>
											        </el-form-item>
												</div>
											</el-col>
											
										</el-row>
									</el-form>
								</tab-content>
								
								<tab-content title="Library of Materials" icon="fa fa-list" :before-change="LoMvalidateStep" > <!-- :before-change="LoMvalidateStep" -->
							    	<hr>
									<el-form :model="ThirdStepData" class="demo-form-inline" :rules="LoMrules" ref="AccountSettingFormLoM">
										<el-row :gutter="20">
											<el-col :span="24">
												<div class="grid-content">
													<el-form-item prop="companyLoMManditory">
														<span slot="label"><b>What Will Be Used to Create <i>Purchase Enquiries</i> for <i>Materials</i>?</b></span>

														<span style="z-index: 1" class="mytooltip tooltip-effect-1">
															<span class="tooltip-item2">
																<span class="fa-stack fa-1x">
																  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															</span>
															<!--<span style="bottom: -230px; left: 220px" class="tooltip-content4 clearfix">-->
															<span style="bottom: -40px; left: -220px; width: 800px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">I’m offered the possibility to impose the strict usage of the <i>Library of Materials</i> as the sole source of information, when initiaing a <i>Purchase Enquiry</i> for <i>Materials</i>. 
																<hr>Though it’s perfectly possible to use Ogéo without actually using the <i>Library of Materials</i>, it’s highly recommended 
																that my organization adopts this restriction, as it will help better qualify and control the <i>Materials</i> which the organization intends to purchase. 
																It will also help generate more insightful reports and analytics. <hr>It's important to note that this selection can be changed, post-registration, at will by authorized users. 
																The changes take effect as soon as they are captured.</span>
															</span>
                                    					</span>
											        	<el-select style="width: 100%; padding: 0px;" v-model="ThirdStepData.companyLoMManditory" placeholder="Choose 'Library of Materials' or 'Library of Materials + Free Text Description'">
						                                      <el-option label="Library of Materials" value="Libraries"></el-option>
						                                      <!-- <el-option label="Free text descriptions" value="FreeText"></el-option> -->
														      <el-option label="Library of Materials + Free Text Descriptions" value="LibrariesNFreeText"></el-option>
														</el-select>
											        </el-form-item>
												</div>
											</el-col>
											
											<transition name="slide-fade" v-if="ThirdStepData.companyLoMManditory == 'LibrariesNFreeText' || ThirdStepData.companyLoMManditory == 'Libraries'">
												<div>
												<el-col :span="24" >







			<div class="card">
				<el-form :model="ThirdPartyVendors" class="demo-form-inline" :rules="FormRules" ref="ItemStructureSetupForm">
					<div style="border: 1px solid rgb(249 237 237 / 13%);">
						<div class="card-body" style="border-bottom: 1px solid rgb(215 223 227);"> 
							<el-form-item >
								<span style="color:black;font-weight:400"><b><span style="color:red">Optional. </span>Please Select One of The Three Options to Ease The Scouting for New Vendors to Trade With:</b></span>

								<span style="z-index: 1" class="mytooltip tooltip-effect-1">
									<span class="tooltip-item2">
										<span class="fa-stack fa-1x">
											<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
											<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
										</span>
									</span>
									<!--<span style="bottom: -50px; width:700px" class="tooltip-content4 clearfix">-->
									<span style="bottom: -40px; left: -520px; width: 800px;" class="tooltip-content4 clearfix">
										<span class="tooltip-text2">On Ogéo, an organization can only trade with <i>Vendors</i> it has qualified, listed and onboarded (i.e. <i>Qualified Vendors</i>).<br><br> 
										By default, exposing the information related to my organization’s <i>Library of Materials</i> to
									<i>Vendors</i>, whether qualified and elisted by my organization or by other organizations, isn’t at all possible. <hr>
									However, in order to ease the scouting of these <i>Third-party Vendors</i>, I’m offered the option to disclose information
									related to my organization’s <i>Library of Materials</i>. <br>The level of disclosed information goes from
									exposing my <i>Library of Materials Categories</i> only, to exposing details, including unit rates,
									about the <i>Materials</i> actually listed in the <i>Library of Materials</i>. <br><i>Third-party Vendors</i>,
									interested in working with my organization, may then proceed to seek a formal qualification,
									before they can begin trading with my organization. <hr>It’s important to note that
									this authorizations can be altered, withdrawn and reinstated at will by authorized users at all times.
									The changes take effect as soon as they are captured.</span>
									</span>
								</span>
								<el-radio ref=""@click.native.prevent = "clickitem('all_vendor_active')"  v-model="ThirdPartyVendors.active" label="all_vendor_active" value="all_vendor_active" >Expose The Organization's <b><i>Library of Materials Categories</i>*</b> to All The Third-party Vendors** Active on Ogéo.</el-radio>
								<el-radio @click.native.prevent = "clickitem('excluding_unit_rate_information')" v-model="ThirdPartyVendors.active" label="excluding_unit_rate_information" value="excluding_unit_rate_information" >Expose The Organization's <b><i>Library of Materials</i> Details, <u>Excluding</u> Unit Rates <I></I>nformation,</b> to All The Third-party Vendors Active On Ogéo.</el-radio>
								<el-radio @click.native.prevent = "clickitem('including_unit_rate_information')" v-model="ThirdPartyVendors.active" label="including_unit_rate_information" value="including_unit_rate_information" >Expose The Organization's <b><i>Library of Materials</i> Details, <u>inculding</u> Unit Rates Information,</b> to All The Third-party Vendors Active On Ogéo.</el-radio>
							<hr>
							<h6 class="card-subtitle"><b>* <i>Categories</i> are meant to enable a more structured description of the <i>Materials</i>, when they are added to the organization's <i>Library of Materials</i>.</b></h6>
							<h6 class="card-subtitle"><b>**Third-party Vendors are those <i>Qualified Vendors</i> enlisted by other organizations which operate on Ogéo.</b></h6>
							<hr>
							</el-form-item>
						</div>
					</div>
				</el-form>
					<!--<div class="card-header" style="background:white">-->
					<!--	<h3 style="font-weight:500;display:inline-block">Library of Materials Categories Configuration</h3>
						<p class="text-danger font-italic">Creating a Category for The Library of Meterials Isn't a Must to Complete the Registration Process.</p>
						<!-- <span style="z-index: 1" class="mytooltip tooltip-effect-1">
							<span class="tooltip-item2">
								<span class="fa-stack fa-1x">
									<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
									<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
								</span>
							</span>
							<span style="bottom: -50px; width:700px" class="tooltip-content4 clearfix">
								<span class="tooltip-text2">Creating a template for the Library of Meterials isn't a must to complete the registration process.</span>
							</span>
						</span>
					</div>
				</el-form>
                <div class="card-body">
                    <h3 class="text-ogeo">Templates Creation</h3>
                    <p class="text-ogeo">Items added to the Library of Materials MUST first be linked to a “template”. 
						Templates are meant to enable a better description of the item, in a more orderly 
						manner. You are allowed to create as many templates as you wish. Each template 
						can have up to 20 customizable headers. You must decide whether it is mandatory 
						to capture information under a header, during the addition of the item to the 
						Library of Materials. Example: <u>Category Name:</u> TV Screens, <u>Header 1:</u>  Brand | <u>Header 2:</u>
						Panel Technology (LED, LCD, OLED...) | <u>Header 3:</u> Size | <u>Header 4:</u> Wattage (W) | <u>Header 
						5:</u> Resolution (PPI) ……</p>

                    <hr>
                   
                    <div class="card">
                        <div class="card-header">
                            <h4>List of Existing Templates For The Organization</h4>
                        </div>
                        <div class="card-body">
                            <table ref="ItemsTemplatesList" id="ItemsTemplatesList" class="display responsive table table-bordered table-hover" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Category Name</th>
                                        <th>Additional Mandatory Header</th>
                                        <th style="min-width: 500px">Assigned Headers</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                	<tr v-for="(Tempate, index) in TemplatesDetails">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ Tempate.Template_Name }}</td>
                                        <td>Unit of Measurement*</td>
                                        <td style="min-widtd: 500px" class="fw-500 text-uppercase">{{ Tempate.ConsolidatedTitles }}</td>
                                        <td @click="deleteTemplate(index)" v-html="Tempate.Action"></td>
                                    </tr>
                                </tbody>
                            </table>
							<p v-if="TemplatesDetails.length > 0">* highlights headers which must be documented during the creation of a Library of Materials’ item.</p>
                        </div>
                    </div>

					<!-- <hr> -->

                    <!--<h3>Create Categories In Bulk:</h3>
					<form id="mainFormTamplate" enctype="multipart/form-data">
						<div class="form-group">
							<table class="table">
								<!-- <tr>
                    				<a style="margin:5px;text-align:left;width:122px" class="btn btn-danger" href="/downloads/Ogéo-Library of Material Templates.xlsx" download>Step 1:<br>Download File</a>
									<label class="btn btn-success" style="margin:5px;text-align:left;width:122px">
										Step 2:<br>Select File
										<input style="display:none" type="file" class="btn btn-danger" name="select_file_tamplate" />
									</label>
									<label class="btn btn-primary" style="margin:5px;text-align:left;width:122px">
										Step 3:<br>Upload File
										<input style="display:none" @click.prevent="getExcelFileTamplate" type="submit" name="Upload File">
									</label>
								<!-- </tr>
							</table>
						</div>
					</form>
                    <hr>
					<div style="text-align:center;margin-top:-37px">
						<span class="btn" style="background:black;text-align:center;padding:10px;color:white">OR</span>
					</div>

                    <h3>Create Categories, One At The Time:</h3>-->
                    <!-- <a href="/downloads/template.csv" download>download</a>
					<form id="mainFormTamplate">
						<div class="form-group">
							<table class="table">
								<tr>
									<label>Select File for Upload</label>
									<input type="file" name="select_file_tamplate" />
									<input @click.prevent="getExcelFileTamplate" type="submit" name="upload" class="btn btn-primary" value="Upload">
								</tr>
							</table>
						</div>
					</form> -->
                    
                    <!--<el-form :model="ItemStructureSetup" class="demo-form-inline" :rules="FormRules" ref="ItemStructureSetupForm">-->
                        
                        <!--<div class="row">
                            <div class="col-lg-12">
                                <div class="grid-content">
                                    <el-form-item label="Category Name" prop="Template_Name">
                                        <el-input ref="Template_Name" v-model="ItemStructureSetup.Template_Name" placeholder="Enter Category Name"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>-->


                        <!--<div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #1" prop="Field_1_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(1, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_1_Required" placeholder="Header #1">
                                            <el-option label="Yes" :value="true"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_1_Header">

                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>

                                        <el-input ref="Header_1" @change="checkHeader1()" :disabled="ItemStructureSetup.Field_1_Required ? null : true" v-model="ItemStructureSetup.Field_1_Header" placeholder="e.g. Make/Brand"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_1_Mandatory">
                                        <el-select ref="Mandatory_1" :disabled="ItemStructureSetup.Field_1_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_1_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #2?" prop="Field_2_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(2, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_2_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_2_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_2" :disabled="ItemStructureSetup.Field_2_Required ? null : true" v-model="ItemStructureSetup.Field_2_Header" placeholder="e.g. Model"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_2_Mandatory">
                                        <el-select ref="Mandatory_2" @change="checkHeader2()" :disabled="ItemStructureSetup.Field_2_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_2_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #3?" prop="Field_3_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(3, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_3_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_3_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_3" :disabled="ItemStructureSetup.Field_3_Required ? null : true" v-model="ItemStructureSetup.Field_3_Header" placeholder="e.g. Country of Origin"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_3_Mandatory">
                                        <el-select ref="Mandatory_3" @change="checkHeader3()" :disabled="ItemStructureSetup.Field_3_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_3_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #4?" prop="Field_4_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(4, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_4_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_4_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_4" :disabled="ItemStructureSetup.Field_4_Required ? null : true" v-model="ItemStructureSetup.Field_4_Header" placeholder="e.g. Serial Number"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_4_Mandatory">
                                        <el-select ref="Mandatory_4"@change="checkHeader4()" :disabled="ItemStructureSetup.Field_4_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_4_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #5?" prop="Field_5_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(5, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_5_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_5_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_5" :disabled="ItemStructureSetup.Field_5_Required ? null : true" v-model="ItemStructureSetup.Field_5_Header" placeholder="e.g. Year of Manufacturing"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_5_Mandatory">
                                        <el-select ref="Mandatory_5" @change="checkHeader5()" :disabled="ItemStructureSetup.Field_5_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_5_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #6?" prop="Field_6_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(6, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_6_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_6_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_6" :disabled="ItemStructureSetup.Field_6_Required ? null : true" v-model="ItemStructureSetup.Field_6_Header" placeholder="e.g. Warranty Duraion (in Months)"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_6_Mandatory">
                                        <el-select ref="Mandatory_6" @change="checkHeader6()" :disabled="ItemStructureSetup.Field_6_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_6_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #7?" prop="Field_7_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(7, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_7_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_7_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_7" :disabled="ItemStructureSetup.Field_7_Required ? null : true" v-model="ItemStructureSetup.Field_7_Header" placeholder="Additional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_7_Mandatory">
                                        <el-select ref="Mandatory_7" @change="checkHeader7()" :disabled="ItemStructureSetup.Field_7_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_7_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #8?" prop="Field_8_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(8, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_8_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_8_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_8" :disabled="ItemStructureSetup.Field_8_Required ? null : true" v-model="ItemStructureSetup.Field_8_Header" placeholder="Additional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_8_Mandatory">
                                        <el-select ref="Mandatory_8" @change="checkHeader8()" :disabled="ItemStructureSetup.Field_8_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_8_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #9?" prop="Field_9_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(9, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_9_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_9_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_9" :disabled="ItemStructureSetup.Field_9_Required ? null : true" v-model="ItemStructureSetup.Field_9_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_9_Mandatory">
                                        <el-select ref="Mandatory_9" @change="checkHeader9()" :disabled="ItemStructureSetup.Field_9_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_9_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #10?" prop="Field_10_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(10, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_10_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_10_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_10" :disabled="ItemStructureSetup.Field_10_Required ? null : true" v-model="ItemStructureSetup.Field_10_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_10_Mandatory">
                                        <el-select ref="Mandatory_10" @change="checkHeader10()" :disabled="ItemStructureSetup.Field_10_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_10_Mandatory" placeholder="Choose 'Yes' or 'No'">   
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #11?" prop="Field_11_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(11, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_11_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_11_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_11" :disabled="ItemStructureSetup.Field_11_Required ? null : true" v-model="ItemStructureSetup.Field_11_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_11_Mandatory">
                                        <el-select ref="Mandatory_11" @change="checkHeader11()" :disabled="ItemStructureSetup.Field_11_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_11_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #12?" prop="Field_12_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(12, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_12_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_12_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_12" :disabled="ItemStructureSetup.Field_12_Required ? null : true" v-model="ItemStructureSetup.Field_12_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_12_Mandatory">
                                        <el-select ref="Mandatory_12" @change="checkHeader12()" :disabled="ItemStructureSetup.Field_12_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_12_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #13?" prop="Field_13_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(13, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_13_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_13_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_13" :disabled="ItemStructureSetup.Field_13_Required ? null : true" v-model="ItemStructureSetup.Field_13_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_13_Mandatory">
                                        <el-select ref="Mandatory_13" @change="checkHeader13()" :disabled="ItemStructureSetup.Field_13_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_13_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #14?" prop="Field_14_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(14, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_14_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_14_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_14" :disabled="ItemStructureSetup.Field_14_Required ? null : true" v-model="ItemStructureSetup.Field_14_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_14_Mandatory">
                                        <el-select ref="Mandatory_14" @change="checkHeader14()" :disabled="ItemStructureSetup.Field_14_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_14_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #15?" prop="Field_15_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(15, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_15_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_15_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_15" :disabled="ItemStructureSetup.Field_15_Required ? null : true" v-model="ItemStructureSetup.Field_15_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_15_Mandatory">
                                        <el-select ref="Mandatory_15" @change="checkHeader15()" :disabled="ItemStructureSetup.Field_15_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_15_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #16?" prop="Field_16_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(16, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_16_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_16_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_16" :disabled="ItemStructureSetup.Field_16_Required ? null : true" v-model="ItemStructureSetup.Field_16_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_16_Mandatory">
                                        <el-select ref="Mandatory_16" @change="checkHeader16()" :disabled="ItemStructureSetup.Field_16_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_16_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #17?" prop="Field_17_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(17, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_17_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_17_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_17" :disabled="ItemStructureSetup.Field_17_Required ? null : true" v-model="ItemStructureSetup.Field_17_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_17_Mandatory">
                                        <el-select ref="Mandatory_17" @change="checkHeader17()" :disabled="ItemStructureSetup.Field_17_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_17_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #18?" prop="Field_18_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(18, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_18_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_18_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_18" :disabled="ItemStructureSetup.Field_18_Required ? null : true" v-model="ItemStructureSetup.Field_18_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_18_Mandatory">
                                        <el-select ref="Mandatory_18" @change="checkHeader18()" :disabled="ItemStructureSetup.Field_18_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_18_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #19?" prop="Field_19_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(19, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_19_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_19_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_19" :disabled="ItemStructureSetup.Field_19_Required ? null : true" v-model="ItemStructureSetup.Field_19_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_19_Mandatory">
                                        <el-select ref="Mandatory_19" @change="checkHeader19()" :disabled="ItemStructureSetup.Field_19_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_19_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-2">
                                <div class="grid-content">
                                    <el-form-item label="Add Header #20?" prop="Field_20_Required">
                                        <el-select :disabled="Set_Required" @change="SetRequired(20, $event)" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_20_Required" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" :value="true"></el-option>    
                                            <el-option label="No" :value="false"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="grid-content">
                                    <el-form-item label="Enter Header Description" prop="Field_20_Header">
                                    	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
											<span class="tooltip-item2">
												<span class="fa-stack fa-1x">
												  <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
												  <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
												</span>
											</span>
											<span style="bottom: -50px;" class="tooltip-content4 clearfix">
												<span class="tooltip-text2">A header can't be a <i>Unit of Measurement</i>.</span>
											</span>
                    					</span>
                                        <el-input ref="Header_20" :disabled="ItemStructureSetup.Field_20_Required ? null : true" v-model="ItemStructureSetup.Field_20_Header" placeholder="Addtional Header"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="grid-content">
                                    <el-form-item label="Is this header mandatory when items are added under this Category?" prop="Field_20_Mandatory">
                                        <el-select ref="Mandatory_20" @change="checkHeader20()" :disabled="ItemStructureSetup.Field_20_Required ? null : true" style="width: 100%; padding: 0px;" v-model="ItemStructureSetup.Field_20_Mandatory" placeholder="Choose 'Yes' or 'No'">
                                            <el-option label="Yes" value="Yes"></el-option>
                                            <el-option label="No" value="No"></el-option>    
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-12">
                            <hr>
                            <button type="button" @click="SubmitItemSetupForm" class="btn btn-success btn-block waves-effect text-center">Create a Library of Materials Category</button>
                        </div>-->


                    <!--</el-form>-->
                <!--</div>-->
            </div>



												</el-col>

												
												</div>
											</transition>

										</el-row>
									</el-form>
							    </tab-content>


	
							    <!-- Project Details and Project Roles Assignment -->
							    <tab-content title="Accounts, Delegations Of Authority & Roles Assignment" icon="fa fa-sitemap" :before-change="validateThirdStep"> <!-- :before-change="validateThirdStep" -->
	
									<!-- Company Wise Or Project Wise Question -->
									<el-row :gutter="20" style="display: none">
								    	<el-col :span="24">
											<div class="grid-content">

												<label>Procurement Setup Mode</label>
												
												<span style="z-index: 1" class="mytooltip tooltip-effect-4">
													<span class="fa-stack fa-1x">
														<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
														<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
													</span>
													<span style="bottom: -230px; left: 220px" class="tooltip-content4 clearfix">
														<span class="tooltip-text2">I’m offered the possibility to create separate Setups, under my organization, for which all the procurement transactions will be conducted. Each Setup can have its own budget, its own approval workflow, its own personnel/roles combination, and its own delegation of authority. The number of separate Setups is unlimited. Setups can be shops, projects, storerooms, subsidiaries, affiliates….or the whole of the organization </span>
													</span>
													
		                                      	</span>
									        	<el-select style="width: 100%; padding: 0px;" v-model="AccountSettingForm.procurementSetupMode" placeholder="Select Procurement Setup Mode">
												      <!-- <el-option label="Company Wide" value="Company Wide"></el-option> -->
												      <el-option selected="selected" label="Project Wise" value="Project Wise"></el-option>
												</el-select>
										        
											</div>
											<hr>
										</el-col>
									</el-row>



											<!-- If Project Wise -->
											<transition name="slide-fade">
												<el-form v-if="AccountSettingForm.procurementSetupMode == 'Project Wise'" :model="ProjectInfo" class="demo-form-inline" :rules="rules4" ref="AccountSettingForm3">
													
													<el-row v-if="ProjectsDetails.length > 0" :gutter="20">
														<el-col :span="24">
															<hr>
															<h4>Existing Accounts:</h4>
						                                    <table style="background-color: rgb(252, 51, 23, 0.2)" class="table table-bordered">
						                                        <thead>
						                                            <tr>
						                                                <th><i>Account</i> Name</th>
						                                                <th><i>Account</i> Period</th>
						                                                <th><i>Account</i> Budget</th>
						                                                <th>Action</th>
						                                            </tr>
						                                        </thead>
						                                        <tbody>
						                                            <tr v-for="(ProjectDetails, key, index) in ProjectsDetails" :key="key">
						                                                <td><b>{{ ProjectDetails.ProjectTitle }}</b></td>
						                                                <td>Start Date: <b>{{ ProjectDetails.ProjectPeriod[0] | formatDateNoTime }}</b><br>End Date: <b>{{ ProjectDetails.ProjectPeriod[1] | formatDateNoTime }}</b></td>
						                                                <td><b>{{ ProjectDetails.ProjectValue }} {{ ProjectDetails.ProjectCompanyCurrency }}</b></td>
						                                                <td>
						                                                	<a class="btn btn-block btn-outline-success " href="#" @click="showProjectDetails(key, $event)">  View/Edit</a>
						                                                	<a class="btn btn-block btn-outline-danger" href="#" @click="RemoveProject(key, $event)"><!-- <i class="fa fa-remove"></i> --> Delete</a> 
						                                                </td>
						                                            </tr>
						                                        </tbody>
						                                    </table>
							                                
							                                <hr>
							                            </el-col>
													</el-row>

													<el-row :gutter="20">
														<el-col :span="24">
															<div class="alert alert-info">
																<!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button> -->
																<!-- <h4 class="text-info"> Role Completing Information</h4> -->
																<i class="fa fa-exclamation-circle"></i> Please click the button to play the tutorial video, if you need any guidance about adding any of the required information below.
																<button type="button" class="btn btn-primary btn-sm float-right" data-toggle="modal" data-target="#VideoModal"> Tutorial</button>
															</div>
														</el-col>
														<el-col :span="24">
															<h2>Account Information
																<span style="z-index: 1" class="mytooltip tooltip-effect-4">
						                                      		<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 32px; left: -90px; width: 700px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">I’m offered the possibility to create separate <i>Accounts</i>, under my organization, for which all the procurement transactions will be conducted.<hr> 
																		Each <i>Account</i> can have its own budget, its own approval workflow, its own personnel/roles combination, and its own <i>Delegation of Authority</i>. 
																		<hr>The number of <i>Accounts</i> is unlimited. <i>Accounts</i> can be subsidiaries, affiliates, projects, storerooms, divisons of the organization, points of sale, … or the whole of the organization.</span>
																	</span>
						                                      	</span>
															</h2>
														</el-col>


														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectTitle">
																<span slot="label"><b>Enter The <i>Account</i> Name</b></span>
																	<el-input v-model="ProjectInfo.ProjectTitle" placeholder="Enter The Account Name"></el-input>
														        </el-form-item>
															</div>
														</el-col>

														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="StoreSetup">
																<span slot="label"><b>Is This <i>Account</i> a Storeroom?</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<!--<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">-->
																	<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">When an <i>Account</i> is marked as a <i>Storeroom</i>, Ogéo will prompt me to assign <i>Storeroom</i>-related roles to the employees. </span>
																	</span>
		                                    					</span>

																<el-select filterable style="width: 100%; padding: 0px;" @change="StoreSetup" v-model="ProjectInfo.StoreSetup" placeholder="Choose Yes or No">
																    <el-option label="Yes" value="Yes"></el-option>
																    <el-option label="No" value="No"></el-option>
																</el-select>

														        </el-form-item>
															</div>
														</el-col>

														<el-col :span="24">
															<div class="grid-content">
																<el-form-item  prop="ProjectPeriod">
																<span slot="label"><b>Select The <i>Account</i> Start & End Dates</b></span>
																	
																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																			<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																			<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																		</span>
																	<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">These are the dates between which <i>Purchase Enquiries</i> can be initiated on Ogéo. </span>
																	</span>
		                                    					</span>

																	<el-date-picker
																	  style="width: 100%"
																      v-model="ProjectInfo.ProjectPeriod"
																      type="daterange"
																      range-separator="To"
																      start-placeholder="Account Start date"
																      end-placeholder="Account End date"
																      :default-time="['00:00:00', '23:59:59']"
																      format="dd-MMM-yyyy"
																      value-format="yyyy-MM-dd HH:mm:ss">
																    </el-date-picker>
																	
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="12">
															<div class="grid-content">
																<el-form-item  prop="ProjectValue">
																<span slot="label"><b>Enter The <i>Account</i> Budget</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																	<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																	<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															<span style="bottom: 20px; width: 500px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">Before an approved <i>Purchase Order</i> can be assigned to the <i>Awardee Vendor</i>, 
																a budget check is conducted: <br>The <i>Purchase Order</i> will be suspended in case the sum of the said <i>Purchase Order</i> value, 
																	and the value of the overall <i>Account</i> past raised <i>Purchase Orders</i>, exceeds the <i>Account</i> Budget.<br>
																	Suspended <i>Purchase Orders</i> can only be released once the <i>Account</i> Budget is increased.</span>
															</span>
		                                    					</span>
																	
																	<el-input type="text" v-on:change="commaprice()" v-model.number="ProjectInfo.ProjectValue" placeholder="Enter The Account’s Budget"></el-input>
														        </el-form-item>
															</div>
														</el-col>

														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectCompanyCurrency">
																<span slot="label"><b>Select The <i>Account</i> Budget Currency</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 20px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">All the procurement transcations for this <i>Account</i> will be reflected in the selected currency.</span>
																	</span>
		                                    					</span>

																	<el-select filterable style="width: 100%; padding: 0px;" v-model="ProjectInfo.ProjectCompanyCurrency" placeholder="Choose The Budget Currency">
																			
																		<el-option value="ALL" selected label="Albania Lek"></el-option>
																		<el-option value="AFN" label="Afghanistan Afghani ؋"></el-option>
																		<el-option value="AWG" label="Aruba Guilder ƒ"></el-option>
																		<el-option value="ARS" label="Argentina Peso $"></el-option>
																		<el-option value="AUD" label="Australian Dollar $"></el-option>
																		<el-option value="AZN" label="Azerbaijanian Manat ₼"></el-option>
																		<el-option value="DZD" label="Algerian Dinar دج"></el-option>

																		<el-option value="BDT" label="Taka ৳"></el-option>
																		<el-option value="BSD" label="Bahamas Dollar $"></el-option>
																		<el-option value="BBD" label="Barbados Dollar $"></el-option>
																		<el-option value="BYN" label="Belarus Ruble Br"></el-option>
																		<el-option value="BZD" label="Belize Dollar BZ$"></el-option>
																		<el-option value="BMD" label="Bermudian Dollar"></el-option>
																		<el-option value="BOB" label="Boliviano $b"></el-option>
																		<el-option value="BGN" label="Bulgaria Lev лв"></el-option>
																		<el-option value="BRL" label="Brazil Real R$"></el-option>
																		<el-option value="BND" label="Brunei Dollar $"></el-option>
																		<el-option value="BHD" label="Bahraini Dinar ب.د"></el-option>
																		<el-option value="BSD" label="Bahamian Dollar $"></el-option>

																		<el-option value="KHR" label="Cambodia Riel ៛"></el-option>
																		<el-option value="CAD" label="Canadian Dollar $"></el-option>
																		<el-option value="CLP" label="Chile Peso ₣"></el-option>
																		<el-option value="COP" label="Colombia Peso $"></el-option>
																		<el-option value="CRC" label="Costa Rican Colon ₡"></el-option>
																		<el-option value="HRK" label="Croatia Kuna 	kn"></el-option>
																		<el-option value="CUP" label="Cuba Peso ₱"></el-option>
																		<el-option value="DKK" label="Denmark Krone kr"></el-option>
																		<el-option value="DOP" label="Dominican Republic Peso RD$"></el-option>

																		<el-option value="XCD" label="East Caribbean Dollar $"></el-option>
																		<el-option value="EGP" label="Egypt Pound £"></el-option>
																		<el-option value="EUR" label="Euro €"></el-option>
																		<el-option value="GHS" label="Ghana Cedi ¢"></el-option>
																		<el-option value="HNL" label="Honduras Lempira £"></el-option>
																		<el-option value="HKD" label="Hong Kong Dollar 	$"></el-option>
																		<el-option value="HUF" label="Hungary Forint Ft"></el-option>
																		<el-option value="ISK" label="Iceland Krona kr"></el-option>
																		<el-option value="INR" label="Indian Rupee ₹"></el-option>
																		<el-option value="IDR" label="Indonesia Rupiah 	Rp"></el-option>
																		<el-option value="IRR" label="Iran Rial ﷼"></el-option>
																		<el-option value="GBP" label="Pound Sterling £"></el-option>
																		<el-option value="JPY" label="Japan Yen ¥"></el-option>
																		<el-option value="JEP" label="Jersey Pound £"></el-option>
																		<el-option value="KZT" label="Kazakhstan Tenge 	лв"></el-option>
																		<el-option value="KRW" label="Korea Won ₩"></el-option>
																		<el-option value="LBP" label="Lebanon Pound £"></el-option>
																		<el-option value="MKD" label="Macedonia Denar ден"></el-option>
																		<el-option value="MYR" label="Malaysia Ringgit 	RM"></el-option>
																		<el-option value="MXN" label="Mexico Peso $"></el-option>
																		<el-option value="MAD" label="Moroccan Dirham د.م."></el-option>
																		<el-option value="NPR" label="Nepal Rupee ₨"></el-option>
																		<el-option value="NZD" label="New Zealand Dollar $"></el-option>
																		<el-option value="NGN" label="Nigeria Naira ₦"></el-option>
																		<el-option value="NOK" label="Norway Krone 	kr"></el-option>
																		<el-option value="OMR" label="Oman Rial	﷼"></el-option>
																		<el-option value="PKR" label="Pakistani Rupee Rs"></el-option>
																		<el-option value="PAB" label="Panama Balboa B/."></el-option>
																		<el-option value="PYG" label="Paraguay Guarani 	Gs"></el-option>
																		<el-option value="PHP" label="Philippines Peso 	₱"></el-option>
																		<el-option value="QAR" label="Qatar Riyal ﷼"></el-option>
																		<el-option value="RON" label="Romania Leu lei"></el-option>
																		<el-option value="RUB" label="Russia Ruble 	₽"></el-option>
																		<el-option value="SAR" label="Saudi Arabia Riyal ﷼"></el-option>
																		<el-option value="RSD" label="Serbia Dinar Дин"></el-option>
																		<el-option value="SGD" label="Singapore Dollar 	$"></el-option>
																		<el-option value="ZAR" label="South Africa Rand R"></el-option>
																		<el-option value="LKR" label="Sri Lanka Rupee ₨"></el-option>
																		<el-option value="SEK" label="Sweden Krona kr"></el-option>
																		<el-option value="CHF" label="Switzerland Franc CHF"></el-option>
																		<el-option value="SYP" label="Syria Pound 	£"></el-option>
																		<el-option value="TWD" label="Taiwan New Dollar"></el-option>
																		<el-option value="THB" label="Thailand Baht TT$"></el-option>
																		<el-option value="TRY" label="Turkey Lira ₺"></el-option>
																		<el-option value="TND" label="Tunisian Dinar د.ت"></el-option>
																		<el-option value="UAH" label="Ukraine Hryvnia 	₴"></el-option>
																		<el-option value="GBP" label="United Kingdom Pound 	£"></el-option>
																		<el-option value="USD" label="United States Dollar 	$"></el-option>
																		<el-option value="UYU" label="Uruguay Peso $U"></el-option>
																		<el-option value="UZS" label="Uzbekistan Som лв"></el-option>
																		<el-option value="VND" label="Viet Nam Dong ₫"></el-option>
																		<el-option value="YER" label="Yemen Rial ﷼"></el-option>
																		<el-option value="ZWD" label="Zimbabwe Dollar Z$"></el-option>
																		
																		
																	</el-select>
														        </el-form-item>
															</div>
														</el-col>
	

														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectPODOACriterion">
																<span slot="label"><b>Select The <i>Purchase Orders’</i> Delegation of Authority Criterion</b></span>

																<span style="z-index: 1;margin: -10px" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 20px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">Approving <i>Purchase Orders</i> is always done according to my organization’s <i>Delegation of Authority</i> (DoA). 
																		I’m offered the possibility to select the <i>Purchase Orders</i>’" criterion of escalation and validation, from the following list: <br>
																		The Unit Rate of each <i>Purchase Order</i> line; The <i>Purchase Order</i> line value; or The <i>Purchase Order</i> total value.
																		<hr>It's important to note that this criterion can be changed, post-registration, at will by authorized users. The changes take effect as soon as they are
																		captured.</span>
																	</span>
		                                    					</span>

																	<el-select style="width: 100%; padding: 0px;" v-model="ProjectInfo.ProjectPODOACriterion" placeholder="Choose 1 of the 3 criteria applicable to the Purchase Orders' Delegation of Authority">
																			
																	    <el-option label="The Unit Rate of Each Purchase Order Line" value="PO line Unit Rate Value"></el-option>
																	    <el-option label="The Purchase Order Line Value" value="PO line Value"></el-option>
																	    <el-option label="The Purcahse Order Total Value" value="PO value"></el-option>
																		    
																	</el-select>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectRNDOACriterion">
																<span slot="label"><b>Select The <i>Receipt Notes'</i> Delegation of Authority Criterion</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 20px; left: -150px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">Approving <i>Receipt Notes</i> is always done according to my organization’s <i>Delegation of Authority</i> (DoA). 
																		<br>I’m offered the possibility to select the <i>Receipt Notes’</i> criterion of escalation and validation, from the following list: 
																		The Unit Rate of each <i>Receipt Note’s</i> line; or The overall value of the <i>Receipt Note</i>. 
																		<hr>It's important to note that this criterion can be changed, post-registration, at will by authorized users. The changes take effect as soon as they are
																		captured.</span>
																	</span>
		                                    					</span>

																	<el-select style="width: 100%; padding: 0px;" v-model="ProjectInfo.ProjectRNDOACriterion" placeholder="Choose 1 of the 2 criteria applicable to the Receipt Notes' Delegation of Authority">
																			
																	    <el-option label="The Unit Rate of Each Receipt Note Line" value="RN Line Unit Rate Value"></el-option>
																	    <el-option label="The Receipt Note Line Value" value="RN line Value"></el-option>

																	</el-select>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="ProjectAuctionType">
																<span slot="label"><b>Select The Vendors' Proposals Sourcing Methodology</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 20px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">I’m offered the possibility to choose the methodology that my organization will adopt to source proposals from the <i>Qualified Vendors</i>. 
																		<br>The available options are: <i>Sealed Bids</i>; <i>Live Auctions</i>; or both.
																		<hr>It's important to note that this selection can be changed, post-registration, at will by authorized users. 
																		The changes take effect as soon as they are captured. </span>
																	</span>
		                                    					</span>

																	<el-select filterable multiple style="width: 100%; padding: 0px;" v-model="ProjectInfo.ProjectAuctionType" placeholder="Choose 'Sealed Bids', 'Live Auctions', or both">
																		
																	    <el-option label="Live Auctions" value="Live Auctions"></el-option>
																	    <!-- <el-option label="Japanese Auction" value="Japanese Auction"></el-option> -->
																	    <el-option label="Sealed Bids" value="Sealed Bid"></el-option>
																		    
																		</el-select>
														        </el-form-item>
															</div>
														</el-col>

														

														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="ProjectTsNCs">
																<span slot="label"><b>Enter The <i>Account General Terms & Conditions for Procurement</i></b></span>

																	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																		<span class="fa-stack fa-1x">
																			<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																			<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																		</span>
																		<span style="bottom: 20px; width: 500px;" class="tooltip-content4 clearfix">
																			<span class="tooltip-text2">I’m offered the possibility to set the General Terms & Conditions that will govern my organization’s relatiosnship with the <i>Qualified Vendors</i>
																			for this <i>Account</i>. <hr>These terms will be automatically attached to all the <i>Requests for Proposals</i> that will be sent to the vendors. 
																			</span>
																		</span>
			                                    					</span>

																	<el-input
																	  type="textarea"
																	  :rows="4"
																	  placeholder="Copy and paste the Organization's General Terms & Conditions applicable to this Account. This document will govern the relationship with the Qualified Vendors, and as such, will be attached to all floated Requests for Proposals, for this Account."
																	  v-model="ProjectInfo.ProjectTsNCs">
																	</el-input>
												        		</el-form-item>
															</div>
														</el-col>
													</el-row>
													<hr>


											<el-row :gutter="20">
												<el-col :span="18">
			                                        <div class="card">
							                            <div class="card-body">
							                                <h3 class="card-title">Roles Assignment & Delegations of Authorities</h3>
							                                <h6 class="card-subtitle" style="color:red">1. Upload the details of the employees to which Ogéo roles, selected from the below table, will be assigned.</h6>
							                                <h6 class="card-subtitle" style="color:red">2. Select an Ogéo role, then <b>Drag & Drop</b> the name of the employee to whom the role must be assigned.</h6>
															<h6 class="card-subtitle" style="color:black"><b><i>-Every role must be assigned to at least 1 employee.</i></b></h6>
															<h6 class="card-subtitle" style="color:black"><b><i>-To foster governance, a user can't be assigned more than 1 role of the same color.</i></b></h6>
															<hr style="color: red; height:13px;">
															<!-- Nav tabs -->
							                                <div class="vtabs" >
							                                    <ul class="StickeyPanel nav nav-tabs tabs-vertical" role="tablist" style="direction: rtl;padding:0;overflow: scroll;height: 500px;display: block;width:200px">
							                                    	<li style="background-color: rgb(252, 51, 23);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#CAD" role="tab" aria-selected="true"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down"><b>Application Administrator</b></span> </a> </li>

							                                        <li style="background-color: rgb(183, 196, 207);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#PRO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Enquiry Initiator</span> </a> </li>

							                                        <li style="background-color: rgb(183, 196, 207);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#PRV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Enquiry Validator</span></a> </li>

							                                        <li style="background-color: rgb(242, 190, 34);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RFQM" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Request For Proposals Administrator</span></a> </li>

							                                        <li style="background-color: rgb(34, 166, 153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#POO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Order Initiator</span></a> </li>

							                                        <li style="background-color: rgb(34, 166, 153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#POV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Order Validator</span></a> </li>

							                                        <li style="background-color: rgb(250, 240, 228);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RNO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Receipt Note Initiator</span></a> </li>

							                                        <li style="background-color: rgb(250, 240, 228);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RNV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Receipt Note Validator</span></a> </li>

							                                        <li style="background-color: rgb(155, 205, 210);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#SLM" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Vendors Qualification Initiator</span></a> </li>

							                                        <li style="background-color: rgb(155, 205, 210);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#SLMV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Vendors Qualification Validator</span></a> </li>

							                                        <li style="background-color: rgb(255, 222, 222);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#SUBO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down"> Budget Update Request Initiator</span></a> </li>

							                                        <li style="background-color: rgb(255, 222, 222);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#SUBV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Budget Update Request Validator</span></a> </li>


							                                        <li style="background-color: #D6E4E5" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#INV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Invoice Checker</span></a> </li>
	

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ACB1D6" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ALMR" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Materials Update Request Initiator </span></a> </li>

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ACB1D6;" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#LMA" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Materials Administrator</span></a> </li>

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ACB1D6;" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ALMV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Materials Validator</span></a> </li>


							                                        <li style="background-color: rgb(236, 229, 199);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RCCO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Creation Request Initiator</span></a> </li>
							                                        
							                                        <li style="background-color: rgb(236, 229, 199);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RCCV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Creation Request Validator</span></a> </li>
							                                        

							                                        <li style="background-color: rgb(236, 229, 199);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RCO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Initiator</span></a> </li>
							                                        
							                                        <li style="background-color: rgb(236, 229, 199);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RCV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Validator</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#SIIRPT" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom In-Charge</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ASTMGR" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Manager</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RTIRS" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Transaction Request Initiator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ATSIRS" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Transaction Request Validator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#RTSI" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Items Update Request Initiator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(205, 194, 174);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ATSI" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Items Update Request Validator </span></a> </li>
								                                       

							                                    </ul>
							                                    <!-- Tab panes -->
							                                    <div v-scrollbar style="width: 100%;height:300px
																" class="tab-content">
																	<div class="tab-pane StickeyPanel active show" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Roles Assignment & Delegations of Authority:</h3><hr>
											                                    <p>Please select a tab from the left side and drag and drop the employee(s) in their specific roles.</p>
																				<hr>
																				<img :src="FirstStepData.CompanyLogo" alt="CompanyLogo">
																				
																				<!--<h5 class="card-subtitle" style="color:red"><b><u>Important Note:</u></b></h5>-->
													                        </div>
											                            </div>
											                        </div>
							                                    	<div class="tab-pane StickeyPanel" id="CAD" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Application Administrator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Application Administrator(s)</i>* will be tasked with managing the organization’s subscription to Ogéo. The role holder(s) will 
																				have the ability to create <i>Accounts</i>,
																				 assign roles to the employees, withdraw roles from users, and adjust the organization's settings on Ogéo. <br></p>
																				 <p>This is the most important role under Ogéo, and as such, shall be held by users who are 
																				 fully aware of the organization’s procurement procedures, rules and regulations.</p>
																				 <hr>
																				 <h6>
																				*Unlike holders of other roles who solely operate at the level of their respective Accounts, the <i>Application Administrator(s)</i> 
																				act at the organizational level, undertaking this role for the entire organization.
																				 </h6>

																				 <hr>

											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s 
																					Procurement Function, or to any other function which is responsible for driving the use of ERP systems (Centre of Excellence…)</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                            	<!--Holder(s) of the Application Administrator role:-->
																						<span><b>User(s) Holding The <i>Application Administrator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="CAD_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Users Holding the <i>Application Administrator</i> Role:</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>

																						<draggable :list="CAD_List" element="el-collapse" @add="onAddCAD" :options="{group:'people'}" >
																						    <el-collapse-item v-if="CAD_List.length > 0" v-for="(e, index) in CAD_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(CAD_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>

											                        <div class="tab-pane StickeyPanel" id="PRO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Purchase Enquiry Initiator <i class="italic">role assignment</i>:</h3>
											                                    
											                                    <p>The <i>Purchase Enquiry Initiator(s)</i> will be tasked with initiating multiline 
																				<i>Purchase Enquiries</i> for this <i>Account</i>. Once initiated, the <i>Purchase Enquiries</i>
																				are routed, for validation on Ogéo, to the users holding the <i>Purchase Enquiry Validator</i> role.</p>
																				<p>On Ogéo, initiating a <i>Purchase Enquiry</i>, which can be for either <i>Materials</i> or <i>Services</i>, sets in motion the process of procurement.</p>
											                                    <hr>
											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions, 
																					or to any other function responsible for utilising the Materials and/or benefiting from the 
																					Services which are the subject of the Purchase Enquiry, or any other function that holds the Account's Profit & Loss responsibility.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Purchase Enquiry Initiator role:-->
																						<span><b>User(s) Holding The <i>Purchase Enquiry Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="PRO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Users Holding The <i>Purchase Enquiry Initiator</i> Role:</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>

																						<draggable :list="PRO_List" element="el-collapse" @add="onAddPRO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="PRO_List.length > 0" v-for="(e, index) in PRO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(PRO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


											                        <div class="tab-pane StickeyPanel" id="PRV" role="tabpanel">
																		<div class="row">
																			<div class="col-lg-12">
													                            <h3>Purchase Enquiry Validator <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The <i>Purchase Enquiry Validator(s)</i> will be tasked with ruling on (approving/rejecting) the multiline <i>Purchase Enquiries</i> 
																				initiated by the users holding the <i>Purchase Enquiry Initiator</i> role, for this <i>Account</i>.</p>
																				<p>To enable this, the <i>Purchase Enquiries' Delegation of Authority (DoA)</i> for this <i>Account</i> must 
																				be generated, detailing the number of validation levels (Ogéo can accomodate up to 
																				8 levels of validation, with Level-1 being the entry level validator) and the 
																				seniority in the chain of validations. </p>
																				<hr>
													                            <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions, 
																					or to any other function responsible for utilising the Materials 
																					and/or benefiting from the Services which are the subject of the <i>Purchase Enquiry</i> 
																					or any other function that holds the <i>Account</i> Profit & Loss responsibility.</p>

																				<el-select style="width: 100%;" v-model="PRV_Number" placeholder="Choose The Purchase Enquiries DoA Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="PRV_Number != null">
																				<div class="card" v-for="iteration in parseInt(PRV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Purchase Enquiry (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="PRV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Employees List with Purchase Enquiry Validator Role</span>-->
																							<!--User(s) Holding The <i>Purchase Enquiry (Level-{{ iteration }}) Validator</i> Role:-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																						
																						<draggable :list="PRV_List['level_' + iteration]" element="el-collapse" @add="onAddPRV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="PRV_List['level_' + iteration].length != 0" v-for="(e, index) in PRV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'PRV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(PRV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>


											                        <div class="tab-pane StickeyPanel" id="RFQM" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Request for Proposals Administrator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Request for Proposals Administrator(s)</i> will be tasked with creating multiline <i>Requests for Proposals</i> pertaining to 
																				this <i>Account</i>. </p>
																				<p>The users holding this role will be responsible for selecting* <i>Vendors</i>, from the list of <i>Qualified Vendors</i>.
																				The selection is intended for the purpose of publishing the <i>Request for Proposals</i> on Ogéo, allowing the
																				<i>Vendors</i>	to submit their bids, directly on Ogéo, to the organization.
																				For the sake of governance, the <i>Requests for Proposals</i> are solely created on the back of: </p>
											                                    <ul>
											                                    	<li><i>Purchase Enquiries</i> that are fully validated by the <i>Purchase Enquiries Validator(s)</i>; or</li>
											                                    	<li><i>Rates' Contract Creation Requests**</i> that are fully validated by the <i>Rates' Contract Creation Requests Validator(s)</i>.</li>
											                                    </ul>
											                                    <p>The users holding this role interact with the <i>Selected Vendors</i> to seek <i>Sealed Bids</i>
																					and/or conduct <i>Live Auctions</i>, and review/comment on the Ogéo-generated 
																					<i>Commercial Evaluation</i> reports.</p>
																					
																					<hr>
																					<h5>*Ogéo is able to <u>recommand the <i>Qualified Vendors</i></u> that are best suited to answer the <i>Requests for Proposals</i>.</h5>
																					<h5>**See the FAQs for more information about <i>Rates' Contracts</i>.</h5>
																					<hr>
											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Request for Proposals role:-->
																						<span><b>User(s) Holding The <i>Request for Proposals Administrator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RFQM_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">Users Holding The <i>Request for Proposals Administrator</i> Role:</span>-->
																					    </div>

																						<draggable :list="RFQM_List" element="el-collapse" @add="onAddRFQM" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RFQM_List.length > 0" v-for="(e, index) in RFQM_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RFQM_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>



											                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanel" id="ALMR" role="tabpanel">
											                        	<div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Library of Materials Update Request Initiator  <i class="italic">role assignment</i>:</h3>
											                                    <!-- <p>The Library of Material Update Request Initiator(s) will be tasked with Items in lieu of materials are added to the organization’s "Library of Materials". Such requests are subject to reviews and approvals before the Items in lieu of to the "Library of Materials". </p> -->
											                                    <p>The <i>Library of Materials Update Requests Initiator(s)</i> will be tasked with raising 
																				the following types of requests, for this <i>Account</i>:
																					</p><p><ul>
																						<li>
																					Requesting that a new item is added to 
																					the organization’s <i>Library of Materials</i>. Added items will <u>initially be active only</u> for this <i>Account</i>;
																						</li>
																						<li>
																					Requesting that an active <i>Library of 
																					Materials</i> item, for this <i>Account</i>, is inactivated ; and
																						</li>
																						<li>
																					Requesting that an inactive* <i>Library of 
																					Materials</i> item, for this <i>Account</i>, is reactivated.
																						</li>
																					</ul>
																					Such requests are subject to reviews. If fully validated by the users holding the <i>Library of 
																						Materials Validator</i> role, the <i>Library of Materials</i> is formally updated.</p>
                                                                                <hr>
																				<h5>*Inactivated items, for any <i>Account</i>, are no longer accessible for the creation of 
																				<i>Purchase Enquiries</i> and <i>Rates’ Contracts Creation Request</i> for the specific <i>Account</i>.</h5>																				
																				<!--<hr>
																				<p> <u>Note:</u> The use of the <i>Library of Materials</i>, when initiating 
																				<i>Purchase Enquiries</i>, generates a valuable amount of information which will ultimately help the organization take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time. </p>-->
											                                    <hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for streamlining the 
																					procurement processes, or for continuous improvement (Centre of Excellence, for example.).</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Library of Material Update Request Initiator role:-->
																						<span><b>User(s) Holding The <i>Library of Materials Update Request Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="ALMR_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Users Holding The <i>Library of Materials Update Request Initiator</i> Role:</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>

																						<draggable :list="ALMR_List" element="el-collapse" @add="onAddALMR" :options="{group:'people'}" >
																						    <el-collapse-item v-if="ALMR_List.length > 0" v-for="(e, index) in ALMR_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ALMR_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
																	</div>




											                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanel" id="LMA" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Library of Materials Administrator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Library of Material Administrator(s)</i> will be tasked 
																				with ruling on (approving/rejecting) the requests to update the organization’s <i>
																				Library of Materials</i>, raised by the users that hold the <i>Library of Materials 
																					Update Request Initiator</i> role.</p>
																				<p>The role of the <i>Library of Material Administrator</i> entails updating the items' information 
																					on requests to add new items to the <i>Library of Materials</i>. This responsibility 
																					includes ensuring that the item's information is better structured, before seeking approval to 
																					add it to the <i>Library of Materials</i>, from those holding the <i>Library of Material Validator</i> role.
																				</p>
																				<p>The responsibility of preserving the data integrity of the <i>Library of Materials</i> and 
																					preventing duplications rests with the users who hold the <i>Library of Material Administrator</i> role.
																				</p>
																				<!--<p>It’s important to note that the use of "Libraries of Materials", to create "Purchase Enquiries", generates a valuable amount of information that will ultimately help the organization 
																					take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time. </p>-->
											                                    <hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to the Procurement Function or any other function which is responsible for continuous improvement (Centre of Excellence, for example.)</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Library of Materials Administrator role:-->
																						<span><b>User(s) Holding The <i>Library of Materials Administrator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="LMA_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Users Holding The <i>Library of Materials Administrator</i> Role:</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>

																						<draggable :list="LMA_List" element="el-collapse" @add="onAddLMA" :options="{group:'people'}" >
																						    <el-collapse-item v-if="LMA_List.length > 0" v-for="(e, index) in LMA_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(LMA_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


											                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanel" id="ALMV" role="tabpanel">
											                            <div class="row">
																			<div class="col-lg-12">
													                            <h3>Library of Materials Validator <i class="italic">role assignment</i>:</h3>
													                            <p>The <i>Library of Material Validator(s)</i> will be tasked with ruling on (approving/rejecting) the 
																				decisions made by the <i>Library of Materials Administrator(s)</i> regarding requests 
																				raised by the users holding the role of <i>Library of Materials Update Request Initiator</i>.</p>
																				<p>To enable this, the <i>Library of Materials' Delegation of Authority (DoA)</i> for this <i>Account</i> must be generated, 
																				detailing the number of validation levels (Ogéo can accomodate up to 8 levels of validation, 
																				with Level-1 being the entry level validator) and the seniority in the chain of validations.</p>
												                            	<!--<p>It’s important to note that the use of Libraries of Materials, to create "Purchase Enquiries", generates a valuable amount of information that will ultimately help the organization take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time.</p>-->
													                            <hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to the Procurement Function or any other function which is responsible for continuous improvement (Centre of Excellence, for example.)</p>

																				<el-select style="width: 100%;" v-model="ALMV_Number" placeholder="Choose The Library of Materials DoA Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="ALMV_Number != null">
																				<div class="card" v-for="iteration in parseInt(ALMV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Library of Materials (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="ALMV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Employees List with Library of Materials Validator Role</span>-->
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																						</div>
																						
																						<draggable :list="ALMV_List['level_' + iteration]" element="el-collapse" @add="onAddALMV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="ALMV_List['level_' + iteration].length != 0" v-for="(e, index) in ALMV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'ALMV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ALMV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>


											                        


											                        <div class="tab-pane StickeyPanel" id="POO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Purchase Order Initiator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Purchase Order Initiator(s)</i> will be tasked with initiating, for this <i>Account</i>, multiline 
																				<i>Purchase Orders</i> for <i>Materials</i> and/or <i>Services</i>.</p> 
																				<p>For the sake of governance, the users holding this role can initiate
																				 <i>Purchase Orders</i>, solely on the back of:</p>
											                                    <ul>
											                                    	<li>Finalised Ogéo-generated <i>Commercial Evaluations*</i> established following the completion of <i>Requests for Proposals</i>; or</li>
											                                    	<li>Fully implemented <i>Rates' Contracts</i>.</li>            
											                                    </ul>
											                                    <p>In addition, if the <i>Awardee Vendor</i> fails to comply with the <i>Purchase Order</i> arrangements 
																				or the <i>Account's General Terms & Conditions for Procurement</i>, 
																				the <i>Purchase Order Initiator(s)</i> will be in position to reaward an already awarded 
																				<i>Purchase Order</i>.</p>
																				<hr>
																					<h5>*See the FAQs for more information.</h5>
											                                    <hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations 
																				Functions or to any other function responsible for utilizing the Materials and/or the Services 
																				which are the subject of the <i>Purchase Enquiry</i>, associated with the specific <i>Purchase Order</i>,
																				 or any other function that holds the Account's Profit & Loss responsibility.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Purchase Order Initiator role:-->
																						<span><b>User(s) Holding The <i>Purchase Order Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="POO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">User(s) Holding The <i>Purchase Order Initiator</i> Role:</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>

																						<draggable :list="POO_List" element="el-collapse" @add="onAddPOO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="POO_List.length > 0" v-for="(e, index) in POO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(POO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>
																	<div class="tab-pane StickeyPanel" id="SUBV" role="tabpanel">
												                    	<div class="row">
												                            <div class="col-lg-12">
													                            <h3>Budget Update Request Validator <i class="italic">role assignment</i>:</h3>
																				<p>The <i>Budget Update Request Validator(s)</i> will be tasked with ruling on (approving/rejecting) requests to 
																				ammend details pertaining to the <i>Account</i>, as setout in requests raised by the users 
																				holding the <i>Budget Update Request Initiator</i> role.</p> 
																				<p>To enable this, the <i>Budget Update Requests' Delegation of Authority (DoA)</i> for this <i>Account</i> must be generated, detailing 
																					the seniority in the chain of validations, and the number of validation levels (Ogéo can accomodate up to 8 levels of validation, with Level-1 being the 
																					entry level validator).</p>
																				<hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to 
																				any other function which holds the Account's Profit & Loss responsibility.</p>
													                            <!-- <p>The Purchase Order Validator(s) will be tasked with ruling on (approving/rejecting) the multiline "Purchase Orders" created by the employees holding the Purchase Order Initiator role. To enable this, the Purchase Orders’ Delegation of Authority (i.e. the number of Purchase Orders’ levels of validation, and the financial limit-based seniority in the chain of approvals) for the "Setup" must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver). </p>
													                            <p>In addition, the Purchase Order Validator will be in position to rule on the "Purchase Order" cancelled by the Purchase Order Initiator.</p>
													                            <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for utilising the material and/or the service that is the subject of the Purchase Order, which is itself linked to a Purchase Enquiry.</p> -->

																				<el-select style="width: 100%;" v-model="SUBV_Number" placeholder="Choose The Budget Update Requests DoA Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="SUBV_Number != null">
																				<div class="card" v-for="iteration in parseInt(SUBV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The<i> Budget Update Request (Level-{{ iteration }}) Validator </i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="SUBV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">Employees List with Budget Update Request Validator Role</span>-->
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																						
																						<draggable :list="SUBV_List['level_' + iteration]" element="el-collapse" @add="onAddSUBV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="SUBV_List['level_' + iteration].length != 0" v-for="(e, index) in SUBV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'SUBV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(SUBV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>

																					    

																			        </div>

																			        <!-- <div align="center" v-if="iteration == SUBV_Number" class="card-footer">
																			        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
																			        </div> -->

																			        <!-- <div v-if="iteration < SUBV_Number" v-for="(currency, index) in ProjectInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
																			        	<template v-if="index == 0">
																				        	<div slot="footer" class="input-group">
													                                            <div class="input-group-prepend">
													                                                <span class="input-group-text">{{ currency }}</span>
													                                            </div>
													                                            <input :id="'SUBV_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
													                                            <input :id="'SUBV_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control" aria-label="Amount (to the nearest dollar)" :placeholder="'Enter Financial Limit ('+ ProjectInfo.ProjectPODOACriterion+')' " >
													                                            <div class="input-group-append">
													                                                <span class="input-group-text">.00</span>
													                                            </div>
													                                        </div>
													                                    </template>
																			        </div> -->

																			    </div>
																			    </template>
													                    	</div>

													                    	
												                    	</div>
												                    </div>

												                    <div class="tab-pane StickeyPanel" id="POV" role="tabpanel">
												                    	<div class="row">
												                            <div class="col-lg-12">
													                            <h3>Purchase Order Validator <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The <i>Purchase Order Validator(s)</i> will be tasked with ruling on (approving/rejecting) 
																				the multiline <i>Purchase Orders</i> initiated by the users holding the <i>Purchase Order Initiator</i> 
																					role, for this <i>Account</i>. </p> 
																				<p>To enable this, the <i>Purchase Orders' Delegation of Authority (DoA)</i>, for this <i>Account</i>, must be generated, detailing 
																					the financial limit-based seniority in the chain of validations, 
																					and the number of validation levels (Ogéo can accomodate up to 8 levels of validation, with Level-1 being the 
																					entry level validator).</p>
																				<hr>
													                            <!--<p>In addition, the <i>Purchase Order Validator will be in position to rule on the "Purchase Order" cancelled by the Purchase Order Initiator.</p>-->
													                            <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations 
																				Functions or to any other function responsible for utilizing the Materials and/or the Services 
																				which are the subject of the <i>Purchase Enquiry</i>, associated with the specific <i>Purchase Order</i>,
																				 or any other function that holds the Account's Profit & Loss responsibility.</p>

																				<el-select style="width: 100%;" v-model="POV_Number" placeholder="Choose The Purchase Orders DoA Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="POV_Number != null">
																				<div class="card" v-for="iteration in parseInt(POV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Purchase Order (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="POV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">Employees List with Purchase Order Validator Role</span>-->
																					    </div>
																						
																						<draggable :list="POV_List['level_' + iteration]" element="el-collapse" @add="onAddPOV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="POV_List['level_' + iteration].length != 0" v-for="(e, index) in POV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'POV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(POV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>

																					    

																			        </div>

																			        <div align="center" v-if="iteration == POV_Number" class="card-footer">
																			        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have A Financial Limit.</span>
																			        </div>

																			        <div v-if="iteration < POV_Number" v-for="(currency, index) in ProjectInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
																			        	<template v-if="index == 0">
																				        	<div slot="footer" class="input-group">
													                                            <div class="input-group-prepend">
													                                                <span class="input-group-text">{{ currency }}</span>
													                                            </div>
													                                            <input :id="'POV_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
													                                            <input :id="'POV_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control" aria-label="Amount (to the nearest dollar)" :placeholder="'Enter The Validation Level Financial Limit ('+ ProjectInfo.ProjectPODOACriterion+')' " >
													                                            <div class="input-group-append">
													                                                <span class="input-group-text">.00</span>
													                                            </div>
													                                        </div>
													                                    </template>
																			        </div>

																			    </div>
																			    </template>
													                    	</div>

													                    	
												                    	</div>
												                    </div>


											                        <div class="tab-pane StickeyPanel" id="RNO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Receipt Note Initiator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Receipt Note Initiator(s)</i> will be tasked with initiating multiline <i>Receipt Notes</i> to document, 
																				on Ogéo, the receipting of the delivered <i>Materials</i> and/or <i>Services</i>, for this <i>Account</i>.</p>
																				 <p>The users holding this role initiate the <i>Receipt Note</i> when the <i>Awardee Vendor</i> delivers the <i>Materials</i> or <i>Services</i> 
																				 as detailed in the <i>Purchase Order</i>. </p>
																				  <p>Once initiated, the <i>Receipt Notes</i> are routed, for validation on Ogéo, to the users 
																				  holding the <i>Receipt Notes Validator</i> role.
																				  </p>
																				  <hr>
											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s 
																					Operations Functions; or to any other function responsible for utilising the Materials and/or benefiting from the Services 
																					which are the subject of the <i>Purchase Enquiry</i> associated with the specific <i>Receipt Note</i>;
																					 or any other function that holds the Account's Profit & Loss responsibility.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Receipt Note Initiator role:-->
																						<span><b>User(s) Holding The <i>Receipt Note Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RNO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<!--<span class="text-success">User(s) Holding The <i>Receipt Note Initiator</i> Role:</span>-->
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																						</div>

																						<draggable :list="RNO_List" element="el-collapse" @add="onAddRNO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RNO_List.length > 0" v-for="(e, index) in RNO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RNO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


												                    <div class="tab-pane StickeyPanel" id="RNV" role="tabpanel">

												                        <div class="row">
												                            <div class="col-lg-12">
													                            <h3>Receipt Note Validator <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The <i>Receipt Note Validator(s)</i> will be tasked with ruling on (approving/rejecting) the multiline 
																				<i>Receipt Notes</i> initiated by the users holding the <i>Receipt Note Initiator</i> role, for this <i>Account</i>.</p>
																				<p>To enable this, the <i>Receipt Notes' Delegation of Authority (DoA)</i> for this <i>Account</i> must be generated, 
																					detailing the financial limit-based seniority in the chain of validations, and the number of 
																					validation levels (Ogéo can accomodate up to 8 levels of validation, with Level-1 being the 
																					entry level validator).</p>
																				<hr>
													                            <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will 
																					typically belong to the organization’s Operations Functions; or to any other function responsible for utilising 
																					the Materials and/or benefiting from the Services which are the subject of the Purchase Enquiry associated with the 
																					specific Receipt Note; or any other function that holds the Account's Profit & Loss responsibility.</p>

																				<el-select style="width: 100%;" v-model="RNV_Number" placeholder="Choose The Receipt Notes DoA's Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="RNV_Number != null">
																				<div class="card" v-for="iteration in parseInt(RNV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Receipt Note (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="RNV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">Employees List with Receipt Note Validator Role</span>-->
																					    </div>
																						
																						<draggable :list="RNV_List['level_' + iteration]" element="el-collapse" @add="onAddRNV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="RNV_List['level_' + iteration].length != 0" v-for="(e, index) in RNV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'RNV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RNV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>

																					    

																			        </div>

																			        <div align="center" v-if="iteration == RNV_Number" class="card-footer">
																			        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have A Financial Limit.</span>
																			        </div>

																			        <div v-if="iteration < RNV_Number" v-for="(currency, index) in ProjectInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
																			        	<template v-if="index == 0">
																				        	<div slot="footer" class="input-group">
													                                            <div class="input-group-prepend">
													                                                <span class="input-group-text">{{ currency }}</span>
													                                            </div>
													                                            <input :id="'RNV_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
													                                            <input :id="'RNV_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control" aria-label="Amount (to the nearest dollar)" :placeholder="'Enter The Validation Level Financial Limit ('+ ProjectInfo.ProjectRNDOACriterion+')'" >
													                                            <div class="input-group-append">
													                                                <span class="input-group-text">.00</span>
													                                            </div>
													                                        </div>
													                                    </template>
																			        </div>
																			    </div>
																			    </template>
													                    	</div>

													                    	
												                    	</div>
												                    </div>


											                        <div class="tab-pane StickeyPanel" id="SLM" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Vendors Qualification Initiator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Vendors Qualification Initiator(s)</i>* will be tasked with directly gathering, or reviewing the <i>Qualification Documents</i>,
																					submitted by <i>Third-party Vendors</i> directly on Ogéo, and subsequently, raising requests to 
																					qualify and enlist those <i>Vendors</i> which have been found to fulfill the requirements of the organization.</p>
																					<p>Besides this type of request, the <i>Vendors Qualification Initiator(s)</i>
																					can raise, on Ogéo, requests to:</p>
											                                    <ul>
											                                    	<li>Enrol, on Ogéo, representatives for the <i>Qualified Vendors</i>;</li>
																					<li>Update the <i>Qualified Vendors</i> information;</li>
																					<li>Suspend <i>Qualified Vendors</i>; </li>
											                                    	<li>Reactivate suspended <i>Qualified Vendors</i>; </li>
											                                    	<li>Amend the roles held by the <i>Qualified Vendors' Representatives</i>;</li>
											                                    	<li>Inactivate <i>Qualified Vendors' Representatives</i>; </li>
											                                    	<li>Reactivate inactivated <i>Qualified Vendors' Representatives</i>; and </li>
																					<li>Renew exipred qualifications, when needed.</li>
											                                    </ul>
																				<p>These requests are then routed for review, on Ogéo, to the users holding the <i>Vendors Qualification Validator</i> role.</p>
																				<hr>
											                                    <p><h5>*Unlike holders of other roles who solely operate at the level of their respective <i>Accounts</i>, the <i>Vendors Qualification Initiator(s)</i> 
																					act at the organizational level, undertaking This role for the entire organization.</h5></p>
											                                    <hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Vendors Qualification Initiator role:-->
																						<span><b>User(s) Holding The <i>Vendors Qualification Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="SLM_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">User(s) Holding The <i>Vendors Qualification Initiator</i> Role:</span>-->
																					    </div>

																						<draggable :list="SLM_List" element="el-collapse" @add="onAddSLM" :options="{group:'people'}" >
																						    <el-collapse-item v-if="SLM_List.length > 0" v-for="(e, index) in SLM_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(SLM_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>

											                        <div class="tab-pane StickeyPanel" id="SLMV" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Vendors Qualification Validator <i class="italic">role assignment</i>:</h3>
																				<hr style="background-color: red">
																				<h4 class="text-ogeo">Important Note:</h4>
																				<p class="text-ogeo">
																					This is the only role whose holders operate across all the Setups:
																					when an employee is assigned this role for any Setup, he/she shall
																					hold this role for all the Setups of the organization. Similarly,
																					withdrawing this role from a user, for any Setup, shall lead to this
																					user being stripped of this role for all the Setups.
																				</p>
																				<hr style="background-color: red">
																				
																				<p>The <i>Vendors Qualification Validator(s)</i> will be tasked with reviewing
																					(validating/rejecting) the <i>Vendor Qualification Requests</i> submitted
																					by the <i>Vendors Qualification Initiator(s)</i>. When fully validated for a
																					<i>Setup</i>, a <i>Qualified Vendor</i> ultimately accesses Ogéo to start
																					transacting with the organization for all of its <i>Setups</i>, for as long as
																					its qualification remains valid.
																				</p>
																				<p>To enable this review process, the <u class="font-italic">
																					Vendors Qualification's Delegation Of Authority</u> of this <i>Setup</i> must first be uploaded onto
																					Ogéo, specifying the number of levels of validation and the
																					seniority of each validator of <i>Vendor Qualification Requests</i>, in the
																					review chain. Ogéo can support up to 8 levels of validation, with
																					Level-1 being the entry level and Level-8 being the final one.
																				</p>
																				<p>In addition to reviewing these requests, the
																					<i>Vendors Qualification Validator(s)</i>
																					with the highest validation level, will additionally be
																					tasked with adjusting the <i>Vendors Score Setting</i>.
																				</p>
													
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
													
																				<el-select style="width: 100%;" v-model="SLMV_Number" placeholder="Enter number of validators">
																					<el-option label="1" value="1"></el-option>
																					<el-option label="2" value="2"></el-option>
																					<el-option label="3" value="3"></el-option>
																					<el-option label="4" value="4"></el-option>
																					<el-option label="5" value="5"></el-option>
																					<el-option label="6" value="6"></el-option>
																					<el-option label="7" value="7"></el-option>
																					<el-option label="8" value="8"></el-option>
																				</el-select>
																				
																				<br><hr>

																				<template v-if="SLMV_Number != null">
																					<div class="card" v-for="iteration in parseInt(SLMV_Number)" :key="iteration">
																						<div class="card-header">
																							Holder(s) of the Vendor Qualification Validator role: <i>(Level-{{ iteration }}) Approval</i>
																						</div>
																						<div class="card-body">

																							<div align="center" v-if="SLMV_List['level_' + iteration].length == 0">
																								<span class="text-danger">Drag and Drop the employee(s) name(s) below this line from the employees’ list on the right.</span>
																							</div>
																							<div align="center" v-else>
																								<span class="text-success">Employees List with Purchase Enquiry Validator Role</span>
																							</div>
																							
																							<draggable :list="SLMV_List['level_' + iteration]" element="el-collapse" @add="onAddSLMV" :options="{group:'people'}" >

																								<el-collapse-item v-if="SLMV_List['level_' + iteration].length != 0" v-for="(e, index) in SLMV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																									<div v-bind:id="'SLMV_level_'+iteration" style="display: none"></div>
																									<div class="float-left">Name: {{e.fullName}}</div><br>
																									<div class="float-left">Email: {{e.email}}</div><br>
																									<div class="float-left">Designation: {{e.designation}}</div><br>
																									<div class="float-right p-b-10">
																										<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(SLMV_List['level_' + iteration], index)"> Remove Employee</a>
																									</div>
																									</el-collapse-item>
																								
																							</draggable>
																						</div>
																					</div>
																				</template>
													                        </div>

													                        

											                            </div>
											                        </div>

																	<div class="tab-pane StickeyPanel" id="SUBO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Budget Update Request Initiator <i class="italic">role assignment</i>:</h3>
																				<p>The <i>Budget Update Request Initiator(s)</i> will be tasked with initiating
																				requests to amend details pertaining to this <i>Account</i>. These
																				amendments are strictly limited to:</p>
																				<ul>
																					<li>Increasing or decreasing the available <i>Account Budget</i>, and</li>
																					<li>Modifying the <i>Account End Date</i>.</li>
																				</ul>
																				
																				<p>These requests, raised on Ogéo, are routed for revirw to the users holding the <i>Budget Update Request Validator</i> role.</p>
																				<hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s 
																					Operations Functions; or to any other function which holds the Account's Profit & Loss responsibility.
																				</p>
											                                    <!-- <p>The Setup Budget(s) will be tasked with qualifying, approving and enlisting Vendors that the organization has chosen to work with, for all of its "Setups". These vendors will then be in position to access Ogéo in order to:</p>
											                                    <ul>
											                                    	<li>Receive "Requests for Proposals"; </li>
											                                    	<li>Submit bids; </li>
											                                    	<li>Participate in Live Auctions;</li>
											                                    	<li>Receive "Purchase Orders"; and </li>
											                                    	<li>Submit invoices. </li>
											                                    </ul>
											                                    <p>The process of vendors’ qualification involves assigning Ogéo’s access credentials to the vendors’ representatives.</p>
											                                    <p style="font-style: italic" class="italic">Holder(s) of this role will belong to the organization’s vendors.</p> -->
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Budget Update Request Initiator role:-->
																						<span><b>User(s) Holding The <i>Budget Update Request Initiator</i> Role:</b></span>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="SUBO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							
																					    </div>

																						<draggable :list="SUBO_List" element="el-collapse" @add="onAddSUBO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="SUBO_List.length > 0" v-for="(e, index) in SUBO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(SUBO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>




											                        <div class="tab-pane StickeyPanel" id="INV" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>The Invoice Checker <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Invoice Checker(s)</i> will be tasked with ruling on (approving/rejecting) the 
																				invoices raised, through Ogéo, by the <i>Awardee Vendors</i>. The role involves verifying 
																				that the invoiced amount mentioned in the invoice matches the amount entered by the <i>Awardee Vendors' Representatives</i> on Ogéo.</p>
											                                    <p><i>Awardee Vendors</i> can raise invoices for:</p>
											                                    <ul>
											                                    	<li>Amounts, contractually due to them based on the awarded <i>Purchase 
																					Order</i>, when they become due for payment as evidenced by the 
																					full validation of the <i>Receipt Notes</i>; and</li>
											                                    	<li>Amounts contractually retained by the organization, when 
																					they become due for release, after the elapse of the <i>Retention Timeframe</i>*, 
																					and the fulfilment of the specific <i>Purchase Order</i>.</li>
											                                    </ul>
																				<hr>
																				<h5>*See the FAQs for more information.</h5><hr>
											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Finance Function (Accounts Payable).</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Invoice Checker role:-->
																						<b>User(s) Holding The <i>Invoice Checker</i> Role:</b>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="INV_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">User(s) Holding The <i>Invoice Checker</i> Role:</span>-->
																					    </div>

																						<draggable :list="INV_List" element="el-collapse" @add="onAddINV" :options="{group:'people'}" >
																						    <el-collapse-item v-if="INV_List.length > 0" v-for="(e, index) in INV_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(INV_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>




											                        <div class="tab-pane StickeyPanel" id="RCCO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Rates' Contract Creation Request Initiator <i class="italic">role assignment</i>:</h3>
																				<p>The <i>Rates' Contract Creation Request Initiator(s)</i> will be tasked with 
																				initiating requests to establish <i>Rates’ Contracts</i> for items extracted 
																				from the <i>Library of Materials</i>. 
																				These requests are then sent for review by the users holding the <i>Rates' Contract Creation Request Validator</i> role.</p>
																				<p>These requests, once validated, are sent to the users holding the <i>Request for Proposals Administrator</i> 
																					role so that offers, including <i>Lead Times</i> & <i>Unit Rates</i>, can be sourced from 
																					<i>Qualified Vendors</i>, directly on Ogéo. </p>
																					<p>After the sourcing of these offers, a decision can be made to proceed with formalizing <i>Rates' Contracts</i>. 
																					If contracts are signed, users holding the <i>Purchase Enquiry* Initiator</i> role can utilize these <i>Rates' Contracts</i>, 
																					regardless of the <i>Account</i> they are enrolled under. </p>
																					<hr>
																					<h5>*<i>Purchase Enquiries</i>, initiated on the back of
																					<i>Rates' Contracts</i>, can be turned into <i>Purchase Orders</i> without the need for <i>Request for Proposals</i> to be raised (given that <i>Unit Rates</i> were secured, and 
																					agreed on during the establishment of the <i>Rates' Contracts</i>), 
																						significantly reducing turnaround times. </h5>
																					<hr>
											                                    <p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible 
																					for streamlining the procurement processes, or for continuous improvement (Centre of Excellence, for example.).</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Rates' Contract Creation Request Initiator role:-->
																						<b>User(s) Holding The <i>Rates' Contract Creation Request Initiator</i> Role:</b>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RCCO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    	<!--<span class="text-success">User(s) Holding The <i>Rates' Contract Creation Request Initiator</i> Role:</span>-->
																					    </div>

																						<draggable :list="RCCO_List" element="el-collapse" @add="onAddRCCO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RCCO_List.length > 0" v-for="(e, index) in RCCO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RCCO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>
											                        

											                        <div class="tab-pane StickeyPanel" id="RCCV" role="tabpanel">
											                            <div class="row">
																			<div class="col-lg-12">
													                            <h3>Rates' Contract Creation Request Validator <i class="italic">role assignment</i>:</h3>													                            
													                            <p>The <i>Rates' Contract Creation Request Validator(s)</i> will be tasked with ruling on (approving/rejecting) 
																				the <i>Rates' Contract Creation Requests</i> that were initiated by the users holding the <i>Rates' Contract Creation 
																				Request Initiator</i> role. 
																				</p>
																				<p>To enable this, the <i>Rates' Contract Creation Requests' Delegation of Authority (DoA) </i>
																				 for this <i>Account</i> must be generated, detailing the seniority in the chain of validations, and the number of validation levels 
																				 (Ogéo can accomodate up to 8 levels of validation, with Level-1 being the entry level validator).
																				 </p>
																				 <p>
																				These requests, once fully validated, are sent to the users holding the <i>Request for Proposals Administrator</i> 
																				role so that offers, can be sourced from <i>Qualified Vendors</i>. Such offers can then be turned into <i>Rates' Contracts</i>*,
																				 if the users holding the <i>Rates' Contract Initiator</i> role seek the contracts formalization.
																			     directly on Ogéo.	
																				 </p>
																				<hr>
																				<h5>
																				*<i>Purchase Enquiries</i>, initiated on the back of <i>Rates' Contracts</i>, can be turned into 
																				<i>Purchase Orders</i> without the need for <i>Request for Proposals</i>
																					 to be raised (given that Unit Rates were secured, and agreed on during the establishment 
																					 of the Rates' Contracts), significantly reducing turnaround times.
																				</h5>
																				<hr>
																				
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for streamlining 
																					the procurement processes, or for continuous improvement (Centre of Excellence, for example.).</p>

																				<el-select style="width: 100%;" v-model="RCCV_Number" placeholder="Choose The Rates' Contract Creation Requests DoA Number of Validation Levels">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="RCCV_Number != null">
																				<div class="card" v-for="iteration in parseInt(RCCV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Rates' Contract Creation Request (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="RCCV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">Employees List with Contract Creation Request Approver Role</span>-->
																					    </div>
																						
																						<draggable :list="RCCV_List['level_' + iteration]" element="el-collapse" @add="onAddRCCV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="RCCV_List['level_' + iteration].length != 0" v-for="(e, index) in RCCV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'RCCV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RCCV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>


											                        <div class="tab-pane StickeyPanel" id="RCO" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Rates' Contract Initiator <i class="italic">role assignment</i>:</h3>
											                                    <p>The <i>Rates' Contract Initiator(s)</i> will be tasked with initiating the process of establishing 
																				formal <i>Rates’ Contracts</i>, on the back of offers submitted by <i>Qualified Vendors</i>.</p>
																				<p>Such offers are received by the <i>Request for Proposals Administrator(s)</i>, resposnsible for floating 
																				<i>Requests for Proposals</i>, following fully validated <i>Rates' Contract Creation Requests</i>.</p> 
																				<p>Once <i>Rates’ Contracts</i> are initiated by the users holding this role for this <i>Account</i>, they are sent for 
																				full validation, and then sent to the </i>Awardee Vendors</i> for execution.</p>
											                                    <p><u>Established <i>Rates’ Contracts</i>* can be used by all <i>Accounts</i>.</u></p>
																				<hr>
																				<h5>
																				*<i>Purchase Enquiries</i>, initiated on the back of <i>Rates' Contracts</i>, can be turned into 
																				<i>Purchase Orders</i> without the need for <i>Request for Proposals</i>
																					 to be raised (given that Unit Rates were secured, and agreed on during the establishment 
																					 of the Rates' Contracts), significantly reducing turnaround times.
																				</h5>
																				<hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s 
																					Operations Functions or to any other function which is responsible for streamlining the procurement processes, or for continuous improvement (Centre of Excellence, for example.).</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                <!--Holder(s) of the Rates' Contract Initiator role:-->
																						<b>User(s) Holding The <i>Rates' Contract Initiator</i> Role:</b>
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RCO_List.length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																							<!--<span class="text-success">User(s) Holding The <i>Rates' Contract Initiator</i> Role</span>-->
																					    </div>

																						<draggable :list="RCO_List" element="el-collapse" @add="onAddRCO" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RCO_List.length > 0" v-for="(e, index) in RCO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RCO_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>



											                        <div class="tab-pane StickeyPanel" id="RCV" role="tabpanel">
												                    	<div class="row">
												                            <div class="col-lg-12">
													                            <h3>Rates' Contract Validator <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The <i>Rates' Contract Validator(s)</i> will be tasked with ruling on (approving/rejecting) the
																				establishment of <i>Rates’ Contracts</i> with <i>Awardee Vendors</i>.</p>
																				<p> To enable this, the <i>Rates' Contracts Delegation of Authority (DoA)</i> for this <i>Account</i> 
																				must be generated, detailing the seniority in the chain of validations, and the number of validation levels (Ogéo can accomodate up to 8 levels of validation, with Level-1 being the entry level validator).</p>
																				<p><u>Established <i>Rates’ Contracts</i>* can be used by all <i>Accounts</i>.</u></p>
																				<hr>
																				<h5>
																				*<i>Purchase Enquiries</i>, initiated on the back of <i>Rates' Contracts</i>, can be turned into 
																				<i>Purchase Orders</i> without the need for <i>Request for Proposals</i>
																					 to be raised (given that Unit Rates were secured, and agreed on during the establishment 
																					 of the Rates' Contracts), significantly reducing turnaround times.
																				</h5>
																				<hr>
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">     Holder(s) of this role will typically belong to the organization’s 
																					Operations Functions or to any other function which is responsible for streamlining the procurement processes, 
																					or for continuous improvement (Centre of Excellence, for example.).</p>

																				<el-select style="width: 100%;" v-model="RCV_Number" placeholder="Choose The Rates' Contracts DoA Number of Validation Levels for This Account">
																				    <el-option label="1 level" value="1"></el-option>
																				    <el-option label="2 levels" value="2"></el-option>
																				    <el-option label="3 levels" value="3"></el-option>
																				    <el-option label="4 levels" value="4"></el-option>
																				    <el-option label="5 levels" value="5"></el-option>
																				    <el-option label="6 levels" value="6"></el-option>
																				    <el-option label="7 levels" value="7"></el-option>
																				    <el-option label="8 levels" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="RCV_Number != null">
																				<div class="card" v-for="iteration in parseInt(RCV_Number)" :key="iteration">
																			        <div class="card-header">
																			            <b>User(s) Holding The <i>Rates' Contract (Level-{{ iteration }}) Validator</i> Role:</b>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="RCV_List['level_' + iteration].length == 0">
																					     	<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    </div>
																					    <div align="center" v-else>
																							<span class="text-danger"><b>Drag & Drop The Employee Name Below This Line</b></span>
																					    	<!--<span class="text-success">Employees List with Rates' Contract Validator Role</span>-->
																					    </div>
																						
																						<draggable :list="RCV_List['level_' + iteration]" element="el-collapse" @add="onAddRCV" :options="{group:'people'}" >

																						    <el-collapse-item v-if="RCV_List['level_' + iteration].length != 0" v-for="(e, index) in RCV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'POV_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RCV_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>

																					    

																			        </div>

																			        <div align="center" v-if="iteration == RCV_Number" class="card-footer">
																			        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
																			        </div>

																			        <div v-if="iteration < RCV_Number" v-for="(currency, index) in ProjectInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
																			        	<template v-if="index == 0">
																				        	<div slot="footer" class="input-group">
													                                            <div class="input-group-prepend">
													                                                <span class="input-group-text">{{ currency }}</span>
													                                            </div>
													                                            <input :id="'RCV_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
													                                            <input :id="'RCV_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control" aria-label="Amount (to the nearest figure)" :placeholder="'Enter Financial Limit (Overall value of the Rates\' Contract)'" >
													                                            <div class="input-group-append">
													                                                <span class="input-group-text">.00</span>
													                                            </div>
													                                        </div>
													                                    </template>
																			        </div>

																			    </div>
																			    </template>
													                    	</div>

													                    	
												                    	</div>
												                    </div>





												                   
	
												                    <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="ASTMGR" role="tabpanel">
											                            <div class="row">
																			<div class="col-lg-12">
													                            <h3>Storeroom Manager <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The Storeroom Manager(s) have access to ALL the Storerooms information across the organizations.</p>
													                            <p>The Storeroom Manager(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities:</p>
													                            <ul>
													                            	<li>Ruling on (approving/rejecting) the request to add new “Storeroom Items”, as originated by the Storeroom In-Charge;</li>
													                            	<li>Ruling on (approving/rejecting) the request to suspend the “Storeroom Items” status, as originated by the Storeroom In-Charge;</li>
													                            	<li>Ruling on (approving/rejecting) the outcome of the periodic Stock Count Exercises, as originated by the Storeroom In-Charge; and</li>
													                            	<li>Originating and/or approving the Storeroom Items Transfer between Storerooms. (Level 1 will originate the transfer while Level 2 and above will approve)</li>
													                            </ul>
													                            <p>To enable this, the Storerooms’ Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
													                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations</p>

																				<el-select style="width: 100%;" v-model="ASTMGR_Number" placeholder="Enter number of validators">
																				    <el-option label="1" value="1"></el-option>
																				    <el-option label="2" value="2"></el-option>
																				    <el-option label="3" value="3"></el-option>
																				    <el-option label="4" value="4"></el-option>
																				    <el-option label="5" value="5"></el-option>
																				    <el-option label="6" value="6"></el-option>
																				    <el-option label="7" value="7"></el-option>
																				    <el-option label="8" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="ASTMGR_Number != null">
																				<div class="card" v-for="iteration in parseInt(ASTMGR_Number)" :key="iteration">
																			        <div class="card-header">
																			            Holder(s) of the Storeroom Manager role: <i>(Level-{{ iteration }}) Approval</i>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="ASTMGR_List['level_' + iteration].length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom Manager Role</span>
																					    </div>
																						
																						<draggable :list="ASTMGR_List['level_' + iteration]" element="el-collapse" @add="onAddASTMGR" :options="{group:'people'}" >

																						    <el-collapse-item v-if="ASTMGR_List['level_' + iteration].length != 0" v-for="(e, index) in ASTMGR_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'ASTMGR_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ASTMGR_List['level_' + iteration], index, 'ASTMGR', iteration, e.email)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>

											                        <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="SIIRPT" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Storeroom In-Charge <i class="italic">role assignment</i>:</h3>
											                                    <p>The Storeroom In-Charge will be tasked will be tasked, for the “Setup” (in this case a Storeroom), with the following activities: </p>
											                                    <ul>
											                                    	<li>Accepting the requests to issue Storeroom Items on the back of approved “Storeroom Item Issue Notes”, and actually issuing the concerned “Storeroom Items” from the Storeroom; </li>
											                                    	<li>Accepting the requests to return Storeroom Items on the back of approved “Storeroom Item Return Notes”, and actually accepting the return of the concerned Storeroom Items to the Storeroom; </li>
											                                    	<li>Originating the addition of new Storeroom Items, as approved by the Storeroom Items Update Request Validator;</li>
											                                    	<li>Originating the suspension of “Storeroom Items” status, as approved by the Storeroom Items Update Request Validator;</li>
											                                    	<li>Incrementing the levels of “Storeroom Items”, to document the receipting of such “Storeroom Items”, when the related “Receipt Notes’ are accepted by created by the employees holding the Receipt Note Validator role;</li>
											                                    	<li>Originating periodic Stock Count Exercises; and</li>
											                                    	<li>
											                                    		Executing forced “Storeroom Items” level movement on the back of:
											                                    		<ul>
											                                    			<li>Stock Count Exercise outcome, as approved by the Storeroom Manager; and</li>
											                                    			<li>
											                                    				Storeroom Items Transfer as  approved by Storeroom Manager and, as the case may be:
											                                    				<ul>
											                                    					<li>Actually issuing the concerned “Storeroom Items”, from the Storeroom, for the Storeroom Items Transfer to happen; or</li>
											                                    					<li>Actually accepting the return of the concerned “Storeroom Items” to the Storeroom, for the Storeroom Items Transfer to happen.</li>
											                                    				</ul>
											                                    			</li>
											                                    		</ul>
											                                    	</li>
											                                    </ul>
											                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                Holder(s) of the Storeroom In-Charge role:
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="SIIRPT_List.length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom In-Charge Role</span>
																					    </div>

																						<draggable :list="SIIRPT_List" element="el-collapse" @add="onAddSIIRPT" :options="{group:'people'}" >
																						    <el-collapse-item v-if="SIIRPT_List.length > 0" v-for="(e, index) in SIIRPT_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(SIIRPT_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


											                        <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="RTIRS" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>The Storeroom Transaction Request Initiator <i class="italic">role assignment</i>:</h3>
											                                    <p>The Storeroom Transaction Request Initiator(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities:</p>
											                                    <ul>
											                                    	<li>Originating “Storeroom Item Issue Notes”; and </li>
											                                    	<li>Originating “Storeroom Item Return Notes”.</li>
											                                    </ul>
											                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Function or any other Function which is responsible for utilising the “Storeroom Item” or any other function that holds a Profit & Loss responsibility.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                Holder(s) of the Storeroom Transaction Request Initiator role:
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RTIRS_List.length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom Transaction Request Initiator Role</span>
																					    </div>

																						<draggable :list="RTIRS_List" element="el-collapse" @add="onAddRTIRS" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RTIRS_List.length > 0" v-for="(e, index) in RTIRS_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RTIRS_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


											                        <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="ATSIRS" role="tabpanel">
											                            <div class="row">
																			<div class="col-lg-12">
													                            <h3>The Storeroom Transaction Request Validator <i class="italic">role assignment</i>:</h3>
													                            <p>The Storeroom Transaction Request Validator(s) will be tasked with ruling on (approving/rejecting) the requests created by the employees holding the Storeroom Transaction Request Initiator role. </p>
													                            <p>To enable this, the Storeroom Transactions’ Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
													                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Function or any other Function which is responsible for utilising the “Storeroom Item” or any other function that holds a Profit & Loss responsibility.</p>


																				<el-select style="width: 100%;" v-model="ATSIRS_Number" placeholder="Enter number of validators">
																				    <el-option label="1" value="1"></el-option>
																				    <el-option label="2" value="2"></el-option>
																				    <el-option label="3" value="3"></el-option>
																				    <el-option label="4" value="4"></el-option>
																				    <el-option label="5" value="5"></el-option>
																				    <el-option label="6" value="6"></el-option>
																				    <el-option label="7" value="7"></el-option>
																				    <el-option label="8" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="ATSIRS_Number != null">
																				<div class="card" v-for="iteration in parseInt(ATSIRS_Number)" :key="iteration">
																			        <div class="card-header">
																			            Holder(s) of the Storeroom Transaction Request Validator role: <i>(Level-{{ iteration }}) Approval</i>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="ATSIRS_List['level_' + iteration].length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom Transaction Request Validator Role</span>
																					    </div>
																						
																						<draggable :list="ATSIRS_List['level_' + iteration]" element="el-collapse" @add="onAddATSIRS" :options="{group:'people'}" >

																						    <el-collapse-item v-if="ATSIRS_List['level_' + iteration].length != 0" v-for="(e, index) in ATSIRS_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'ATSIRS_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ATSIRS_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>

											                        <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="RTSI" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>The Storeroom Items Update Request Initiator  <i class="italic">role assignment</i>:</h3>
											                                    <p>The Storeroom Items Update Request Initiator(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities: </p>
											                                    <ul>
											                                    	<li>Requesting that new materials, extracted from the “Library of Materials” are turned into “Storeroom Items”; and </li>
											                                    	<li>Requesting, that the status of “Storeroom Items” is suspended for existing Storeroom Items; </li>
											                                    </ul>
											                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations. This role can only also be held by employees of the organization’s Operations Function.</p>
													
														                        <div class="card">
														                            <div class="card-header">
														                                Holder(s) of the Storeroom Items Update Request Initiator role:
														                            </div>
														                            <div class="card-body">

														                            	<div align="center" v-if="RTSI_List.length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom Items Update Request Initiator Role</span>
																					    </div>

																						<draggable :list="RTSI_List" element="el-collapse" @add="onAddRTSI" :options="{group:'people'}" >
																						    <el-collapse-item v-if="RTSI_List.length > 0" v-for="(e, index) in RTSI_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(RTSI_List, index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
															                            
														                            </div>
														                        </div>
													                        </div>

													                        

											                            </div>
											                        </div>


											                        <div v-if="ProjectInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanel" id="ATSI" role="tabpanel">
											                            <div class="row">
																			<div class="col-lg-12">
													                            <h3>The Storeroom Items Update Request Validator <i class="italic">role assignment</i>:</h3>
													                            
													                            <p>The Storeroom Items Update Request Validator(s) will be tasked with ruling on (approving/rejecting) the requests created by the employees holding the Storeroom Items Update Request Initiator role. </p>
													                            <p>To enable this, the Storeroom Items Update Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
													                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations. This role can only also be held by employees of the organization’s Operations Function.</p>

																				<el-select style="width: 100%;" v-model="ATSI_Number" placeholder="Enter number of validators">
																				    <el-option label="1" value="1"></el-option>
																				    <el-option label="2" value="2"></el-option>
																				    <el-option label="3" value="3"></el-option>
																				    <el-option label="4" value="4"></el-option>
																				    <el-option label="5" value="5"></el-option>
																				    <el-option label="6" value="6"></el-option>
																				    <el-option label="7" value="7"></el-option>
																				    <el-option label="8" value="8"></el-option>
																				</el-select>

																				<br><hr>
																				
																				<template v-if="ATSI_Number != null">
																				<div class="card" v-for="iteration in parseInt(ATSI_Number)" :key="iteration">
																			        <div class="card-header">
																			            Holder(s) of the Storeroom Items Update Request role: <i>(Level-{{ iteration }}) Approval</i>
																			        </div>
																			        <div class="card-body">

																			        	<div align="center" v-if="ATSI_List['level_' + iteration].length == 0">
																					     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																					    </div>
																					    <div align="center" v-else>
																					    	<span class="text-success">Employees List with Storeroom Items Update Request Role</span>
																					    </div>
																						
																						<draggable :list="ATSI_List['level_' + iteration]" element="el-collapse" @add="onAddATSI" :options="{group:'people'}" >

																						    <el-collapse-item v-if="ATSI_List['level_' + iteration].length != 0" v-for="(e, index) in ATSI_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																						    	<div v-bind:id="'ATSI_level_'+iteration" style="display: none"></div>
																						    	<div class="float-left">Name: {{e.fullName}}</div><br>
																						        <div class="float-left">Email: {{e.email}}</div><br>
																						        <div class="float-left">Designation: {{e.designation}}</div><br>
																						        <div class="float-right p-b-10">
																						        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ATSI_List['level_' + iteration], index)"> Remove Employee</a>
																						        </div>
																						     </el-collapse-item>
																						 
																						</draggable>
																			        </div>
																			    </div>
																				</template>
													                    	</div>
													                    	

											                            </div>
											                        </div>

													               


											                        


											                        
							                                    </div>
							                                </div>
							                            </div>
							                        </div>
				                                </el-col>

				                                <el-col class="StickeyPanel" :span="6">
													
													<div class="row "> <!-- b-l -->

					                                	<div class="col-lg-12 " style="overflow: scroll;height:500px"> <!-- p-t-10 b-l -->
					                                		<div class="card" style="margin-bottom: 5px">
									                            <div class="card-header">
									                                Employees List
									                            </div>
																<h4 class="col-lg-12 p-t-10" style="margin-top:20px">Add Employees in Bulk:</h4>
																<form id="mainForm" class="col-lg-12 p-t-10" style="width=100%">
																	<div class="form-group">
																		<a class="btn btn-danger btn-block" href="/downloads/Ogéo-Users.xlsx" download>Step 1: Download</a>
																		<label class="btn btn-success btn-block">Step 2: Select File
																			<input style="display:none" type="file" name="select_file" />
																		</label>
																		<label class="btn btn-primary btn-block"> Step 3: Upload
																			<input style="display:none" @click.prevent="getExcelFile" type="submit" name="upload" value="Upload">
																		</label>
																	</div>
																</form>
																<hr>
																<div style="text-align:center;margin-top: -30px ">
																	<span class="button" style="color:white;padding:10px;background:black;border-radius:10px">OR</span>
																</div>	
																<h4 class="col-lg-12 p-t-10">Add Employees, One At The Time:</h4>
																<div class="col-lg-12 p-t-10"> <!-- b-l -->
																	<!-- <label class="">New Employee Name</label> -->
																	<input type="text" v-model="NewEmployee.fullName" class="form-control" placeholder="Enter Employee Name">
																	<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.fullName">{{ NewEmployeeErrors.fullName[0] }}</span>
																</div>

																<div class="col-lg-12 p-t-10"> <!-- b-l -->
																	<!-- <label class="">New Employee Email</label> -->
																	<input type="email" v-model="NewEmployee.email" class="form-control" placeholder="Enter Employee Email">
																	<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.email">{{ NewEmployeeErrors.email[0] }}</span>
																</div>

																<div class="col-lg-12 p-t-10 p-b-10"> <!--  b-b b-l -->
																	<!-- <label class="">Designation</label> -->
																	<input type="text" v-model="NewEmployee.designation"  class="form-control" placeholder="Enter Employee Designation">
																	<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.designation">{{ NewEmployeeErrors.designation[0] }}</span>
																</div>

																<div class="col-lg-12 p-t-10 ">
																	<a class="btn btn-success btn-block" style="color: white" @click="creatNewEmployee">Create New Employee</a>
																</div>
									                            <div class="card-body">
									                            	<div class="drag">
										                                <!-- <draggable v-model="list" class="dragArea" :options="{group:{ name:'people',  pull:'clone', put:false }}">
																      		<div v-for="element in list">{{element.name}}</div>
																      	</draggable> -->
																		<draggable element="el-collapse" :list="EmployeesList" :options="{group:{ name:'people',  pull:'clone', put:false }}" >
																			
																		    <el-collapse-item v-if="EmployeesList.length != 0" v-for="(employee, index) in EmployeesList" :title="employee.fullName.substring(0, 20) + '...' " :name="employee.fullName" :key="employee.email">
																		        <div class="float-left">Name: {{employee.fullName}}</div><br>
																		        <div class="float-left"><b>Email:</b> {{employee.email}}</div><br>
																		        <div class="float-left"><b>Designation:</b> {{employee.designation}}</div><br>
																		        <div class="float-right p-b-10">
																	        		<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(EmployeesList, index, 'EmployeesList', 0, employee.email)"> Remove Employee</a>
																	        	</div>
																		     </el-collapse-item>
																	     
																		     <div align="center" v-if="EmployeesList.length == 0">
																		     	<span>Please Add Employee</span>
																		     </div>

																		</draggable>
										                            </div>
									                            </div>
									                        </div>
									                    </div>
														
														<hr>
												    </div>
				                                </el-col>
											</el-row>

											<el-row>
												<el-col :span="24">
													<br><hr>
													<a href="#" class="btn btn-block btn-ogeo waves-effect waves-light" @click="CreateNewProject">Save <i>Account</i> Details</a>
													<hr>
												</el-col>
											</el-row>	
										</el-form>
					    			</transition>
							    </tab-content>
							    


							    <tab-content title="Review & Complete" icon="fa fa-flag-checkered" >
									
									<el-row :gutter="20">
										<el-col :span="24">

											<hr>

											<h3 class="text-ogeo" style="text-align: center">Thank you for completing the registration process. By accepting the <i>Subscriber Agreement</i>, 
												you are now ready to harness the power of the world's most powerful procurement system. 
												We feel privileged to count you as an Ogéo customer.</h3>

											<hr>
											
											<article class="b-doc" id="SubscriberAgreement" style="padding:40px">
										      <div class="document">
												<h1 dir="ltr" style="text-align: center; margin-bottom: 50px;"><i>Subscriber Agreement</i></h1>
												<p v-html="this.TermConditions"></p>
												<hr>
												<div class="col-lg-12">
													<div class="d-inline-block">
														<span><b>Date:</b> {{ new Date() }}</span><br>
														<span><b>By:</b> {{ currentUser.Name }}</span>
														<button class="btn btn-success ml-3" id="non-print" onclick="document.title = 'Ogéo-Subscriber Agreement';window.print()">Download PDF</button>   <br><br>
													</div>
													<div class="d-inline-block pull-right">
														<span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
													</div>
												</div>
											</div>

											    </article>
										</el-col>
									</el-row>		    	
							    </tab-content>

							    <el-button type="info" slot="prev" style="background-color: grey;">Back</el-button>
							    <el-button type="info" slot="next"style="background-color: green;" >Continue</el-button>
							    <el-button type="info" slot="finish" style="background: rgb(252, 51, 23)">Accept <i>Subscriber Agreement</i></el-button>
							 </form-wizard>
		                	
		                </div>
		            </div>
		        </div>
		    </div>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="VideoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-video" role="document">
			    <div class="modal-content">
				    <div class="modal-body modal-body-video">
				       	<a href="#" class="close-video" data-dismiss="modal" aria-label="Close">
				        	<span style="position: relative; top: -1p1; left: 12px;" class="fa fa-remove" aria-hidden="true"></span>
				        </a>        
				        <!-- 16:9 aspect ratio -->
						<div class="embed-responsive embed-responsive-16by9">
						  <iframe class="embed-responsive-item" src="" id="video" allowfullscreen  allowscriptaccess="always"></iframe>
						</div>
				    </div>
			    </div>
			</div>
		</div> 


		<div id="ProjectDetailsModalId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="projectDetailsModals" aria-hidden="true" style="display: none;">
	        <div class="modal-dialog modal-lg">
	            <div class="modal-content">
	                <div class="modal-header">
	                    <h4 class="modal-title text-ogeo" id="projectDetailsModals">Setup Name: {{ ProjectModalInfo.ProjectTitle }} (View/Edit)</h4>
	                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	                </div>
	                <div class="modal-body">
						
						<el-form v-if="ProjectModalInfo != ''" :model="ProjectModalInfo" class="demo-form-inline" :rules="rules4" ref="AccountSettingForm3">

													<el-row :gutter="20">
														<!-- <el-col :span="24">
															<div class="alert alert-info">
																<i class="fa fa-exclamation-circle"></i>Please click the button to play the tutorial video, if you need any guidance about adding any of the required information below.
																<button type="button" class="btn btn-primary btn-sm float-right" data-toggle="modal" data-target="#VideoModal"> Tutorial</button>
															</div>
														</el-col> -->
														<el-col :span="24">
															<h2>Setup Information
																<span style="z-index: 1" class="mytooltip tooltip-effect-4">
						                                      		<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: -100px; left: 230px; width: 500px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">I’m offered the possibility to create separate "Setups", under my organization, for which all the procurement transactions will be conducted. Each "Setup" can have its own budget, its own approval workflow, its own personnel/roles combination, and its own delegation of authority. The number of separate "Setups" is unlimited. "Setups" can be shops, projects, storerooms, subsidiaries, affiliates….or the whole of the organization. </span>
																	</span>
						                                      	</span>
															</h2>
														</el-col>


														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="ProjectTitle">
																<span slot="label"><b>Enter Setup Name</b></span>
																	<el-input v-model="ProjectModalInfo.ProjectTitle" placeholder="Enter Setup name"></el-input>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="24">
															<div class="grid-content">
																<el-form-item  prop="ProjectPeriod">
																<span slot="label"><b>Select Setup Start And End Dates</b></span>
																	
																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																			<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																			<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																		</span>
																	<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">These are the dates between which all procurement transactions are authorized by Ogéo. </span>
																	</span>
		                                    					</span>

																	<el-date-picker
																	  style="width: 100%"
																      v-model="ProjectModalInfo.ProjectPeriod"
																      type="daterange"
																      range-separator="To"
																      start-placeholder="Start date"
																      end-placeholder="End date"
																      :default-time="['00:00:00', '23:59:59']"
																      format="dd-MMM-yyyy"
																      value-format="yyyy-MM-dd HH:mm:ss">
																    </el-date-picker>
																	
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="12">
															<div class="grid-content">
																<el-form-item  prop="ProjectValue">
																<span slot="label"><b>Enter The Setup’s Budget</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																	<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																	<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																</span>
															<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																<span class="tooltip-text2">Before an approved "Purchase Order" can be assigned to the awardee vendor, a budget check is conducted: The "Purchase Order" will be suspended in case the sum of the "Purchase Order" value, and the value of the overall "Setup’s" past raised "Purchase Orders", exceeds the "Setup" Budget. Suspended "Purchase Orders" can only be released once the "Setup" Budget is increased.</span>
															</span>
		                                    					</span>

																	<el-input type="text" v-on:change="commaprice()" v-model.number="ProjectModalInfo.ProjectValue" placeholder="Enter Setup’s Budget"></el-input>
														        </el-form-item>
															</div>
														</el-col>

														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectCompanyCurrency">
																<span slot="label"><b>Select The Setup’s Budget Currency</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 30px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">All the procurement transcations will be reflected with the selected currency.</span>
																	</span>
		                                    					</span>

																	<el-select filterable style="width: 100%; padding: 0px;" v-model="ProjectModalInfo.ProjectCompanyCurrency" placeholder="Select Currency">
																			
																		<el-option value="ALL" selected label="Albania Lek"></el-option>
																		<el-option value="AFN" label="Afghanistan Afghani ؋"></el-option>
																		<el-option value="AWG" label="Aruba Guilder ƒ"></el-option>
																		<el-option value="ARS" label="Argentina Peso $"></el-option>
																		<el-option value="AUD" label="Australian Dollar $"></el-option>
																		<el-option value="AZN" label="Azerbaijanian Manat ₼"></el-option>
																		<el-option value="DZD" label="Algerian Dinar دج"></el-option>

																		<el-option value="BDT" label="Taka ৳"></el-option>
																		<el-option value="BSD" label="Bahamas Dollar $"></el-option>
																		<el-option value="BBD" label="Barbados Dollar $"></el-option>
																		<el-option value="BYN" label="Belarus Ruble Br"></el-option>
																		<el-option value="BZD" label="Belize Dollar BZ$"></el-option>
																		<el-option value="BMD" label="Bermudian Dollar"></el-option>
																		<el-option value="BOB" label="Boliviano $b"></el-option>
																		<el-option value="BGN" label="Bulgaria Lev лв"></el-option>
																		<el-option value="BRL" label="Brazil Real R$"></el-option>
																		<el-option value="BND" label="Brunei Dollar $"></el-option>
																		<el-option value="BHD" label="Bahraini Dinar ب.د"></el-option>
																		<el-option value="BSD" label="Bahamian Dollar $"></el-option>

																		<el-option value="KHR" label="Cambodia Riel ៛"></el-option>
																		<el-option value="CAD" label="Canadian Dollar $"></el-option>
																		<el-option value="CLP" label="Chile Peso ₣"></el-option>
																		<el-option value="COP" label="Colombia Peso $"></el-option>
																		<el-option value="CRC" label="Costa Rican Colon ₡"></el-option>
																		<el-option value="HRK" label="Croatia Kuna 	kn"></el-option>
																		<el-option value="CUP" label="Cuba Peso ₱"></el-option>
																		<el-option value="DKK" label="Denmark Krone kr"></el-option>
																		<el-option value="DOP" label="Dominican Republic Peso RD$"></el-option>

																		<el-option value="XCD" label="East Caribbean Dollar $"></el-option>
																		<el-option value="EGP" label="Egypt Pound £"></el-option>
																		<el-option value="EUR" label="Euro €"></el-option>
																		<el-option value="GHS" label="Ghana Cedi ¢"></el-option>
																		<el-option value="HNL" label="Honduras Lempira £"></el-option>
																		<el-option value="HKD" label="Hong Kong Dollar 	$"></el-option>
																		<el-option value="HUF" label="Hungary Forint Ft"></el-option>
																		<el-option value="ISK" label="Iceland Krona kr"></el-option>
																		<el-option value="INR" label="Indian Rupee ₹"></el-option>
																		<el-option value="IDR" label="Indonesia Rupiah 	Rp"></el-option>
																		<el-option value="IRR" label="Iran Rial ﷼"></el-option>
																		<el-option value="GBP" label="Pound Sterling £"></el-option>
																		<el-option value="JPY" label="Japan Yen ¥"></el-option>
																		<el-option value="JEP" label="Jersey Pound £"></el-option>
																		<el-option value="KZT" label="Kazakhstan Tenge 	лв"></el-option>
																		<el-option value="KRW" label="Korea Won ₩"></el-option>
																		<el-option value="LBP" label="Lebanon Pound £"></el-option>
																		<el-option value="MKD" label="Macedonia Denar ден"></el-option>
																		<el-option value="MYR" label="Malaysia Ringgit 	RM"></el-option>
																		<el-option value="MXN" label="Mexico Peso $"></el-option>
																		<el-option value="MAD" label="Moroccan Dirham د.م."></el-option>
																		<el-option value="NPR" label="Nepal Rupee ₨"></el-option>
																		<el-option value="NZD" label="New Zealand Dollar $"></el-option>
																		<el-option value="NGN" label="Nigeria Naira ₦"></el-option>
																		<el-option value="NOK" label="Norway Krone 	kr"></el-option>
																		<el-option value="OMR" label="Oman Rial	﷼"></el-option>
																		<el-option value="PKR" label="Pakistani Rupee Rs"></el-option>
																		<el-option value="PAB" label="Panama Balboa B/."></el-option>
																		<el-option value="PYG" label="Paraguay Guarani 	Gs"></el-option>
																		<el-option value="PHP" label="Philippines Peso 	₱"></el-option>
																		<el-option value="QAR" label="Qatar Riyal ﷼"></el-option>
																		<el-option value="RON" label="Romania Leu lei"></el-option>
																		<el-option value="RUB" label="Russia Ruble 	₽"></el-option>
																		<el-option value="SAR" label="Saudi Arabia Riyal ﷼"></el-option>
																		<el-option value="RSD" label="Serbia Dinar Дин"></el-option>
																		<el-option value="SGD" label="Singapore Dollar 	$"></el-option>
																		<el-option value="ZAR" label="South Africa Rand R"></el-option>
																		<el-option value="LKR" label="Sri Lanka Rupee ₨"></el-option>
																		<el-option value="SEK" label="Sweden Krona kr"></el-option>
																		<el-option value="CHF" label="Switzerland Franc CHF"></el-option>
																		<el-option value="SYP" label="Syria Pound 	£"></el-option>
																		<el-option value="TWD" label="Taiwan New Dollar"></el-option>
																		<el-option value="THB" label="Thailand Baht TT$"></el-option>
																		<el-option value="TRY" label="Turkey Lira ₺"></el-option>
																		<el-option value="TND" label="Tunisian Dinar د.ت"></el-option>
																		<el-option value="UAH" label="Ukraine Hryvnia 	₴"></el-option>
																		<el-option value="GBP" label="United Kingdom Pound 	£"></el-option>
																		<el-option value="USD" label="United States Dollar 	$"></el-option>
																		<el-option value="UYU" label="Uruguay Peso $U"></el-option>
																		<el-option value="UZS" label="Uzbekistan Som лв"></el-option>
																		<el-option value="VND" label="Viet Nam Dong ₫"></el-option>
																		<el-option value="YER" label="Yemen Rial ﷼"></el-option>
																		<el-option value="ZWD" label="Zimbabwe Dollar Z$"></el-option>
																		
																		
																	</el-select>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="StoreSetup">
																<span slot="label"><b>Is this Setup a Storeroom?</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">When a "Setup" is marked as a “Storeroom”, Ogéo will prompt me to assign storeroom-related roles to the employees. </span>
																	</span>
		                                    					</span>

																<el-select filterable style="width: 100%; padding: 0px;" @change="StoreSetup" v-model="ProjectModalInfo.StoreSetup" placeholder="Mark As Store Setup?">
																    <el-option label="Yes" value="Yes"></el-option>
																    <el-option label="No" value="No"></el-option>
																</el-select>

														        </el-form-item>
															</div>
														</el-col>		

														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectPODOACriterion">
																<span slot="label"><b>Select the Delegation of Authority’s criterion applicable to Purchase Orders</b></span>

																<span style="z-index: 1;margin: -10px" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">Approving "Purchase Orders" is always done according to my organization’s Delegation of Authority (DoA). I’m offered the possibility to select the "Purchase Orders’" criterion of escalation and approval, from the following list: The Unit Rate of each "Purchase Order’s" line; The "Purchase Order" line value; or The overall value of the "Purchase Order". </span>
																	</span>
		                                    					</span>

																	<el-select style="width: 100%; padding: 0px;" v-model="ProjectModalInfo.ProjectPODOACriterion" placeholder="Select the Delegation of Authority’s criterion applicable to Purchase Orders">
																			
																	    <el-option label="The Unit Rate of each Purchase Order line" value="PO line Unit Rate Value"></el-option>
																	    <el-option label="The Purchase Order line value" value="PO line Value"></el-option>
																	    <el-option label="The overall value of the Purchase Order" value="PO value"></el-option>
																		    
																	</el-select>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="12">
															<div class="grid-content">
																<el-form-item prop="ProjectRNDOACriterion">
																<span slot="label"><b>Select the Delegation of Authority’s criterion applicable to Receipt Notes</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: 30px; left: -220px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">Approving "Receipt Notes" is always done according to my organization’s Delegation of Authority (DoA). I’m offered the possibility to select the "Receipt Notes’" criterion of escalation and approval, from the following list: The Unit Rate of each "Receipt Note’s" line; or The overall value of the "Receipt Note". </span>
																	</span>
		                                    					</span>

																	<el-select style="width: 100%; padding: 0px;" v-model="ProjectModalInfo.ProjectRNDOACriterion" placeholder="Select the Delegation of Authority’s criterion applicable to Receipt Notes">
																			
																	    <el-option label="The Unit Rate of each Receipt Note line" value="RN Line Unit Rate Value"></el-option>
																	    <el-option label="The Receipt Note line value" value="RN line Value"></el-option>

																	</el-select>
														        </el-form-item>
															</div>
														</el-col>


														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="ProjectAuctionType">
																<span slot="label"><b>Select the proposals’ sourcing methodology</b></span>

																<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																	<span class="fa-stack fa-1x">
																		<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																		<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																	</span>
																	<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																		<span class="tooltip-text2">I’m offered the possibility to choose the methodology that my organization will adopt to source proposals from the approved Vendors. The available options are: Sealed Bids; Live Auctions; or both. </span>
																	</span>
		                                    					</span>

																	<el-select filterable multiple style="width: 100%; padding: 0px;" v-model="ProjectModalInfo.ProjectAuctionType" placeholder="Select Action Type">
																		
																	    <el-option label="Live Auctions" value="Live Auctions"></el-option>
																	    <!-- <el-option label="Japanese Auction" value="Japanese Auction"></el-option> -->
																	    <el-option label="Sealed Bids" value="Sealed Bid"></el-option>
																		    
																		</el-select>
														        </el-form-item>
															</div>
														</el-col>

														

														<el-col :span="24">
															<div class="grid-content">
																<el-form-item prop="ProjectTsNCs">
																<span slot="label"><b>Setup General Terms & Conditions for Procurement</b></span>

																	<span style="z-index: 1" class="mytooltip tooltip-effect-1">
																		<span class="fa-stack fa-1x">
																			<i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
																			<i style="color: white" class="fa fa-info fa-stack-1x"></i> 
																		</span>
																		<span style="bottom: -80px; left: 220px; width: 500px;" class="tooltip-content4 clearfix">
																			<span class="tooltip-text2">I’m offered the possibility to set the General Terms & Conditions that will govern my organization’s with the Vendors. These terms will be automatically attached to all the Requests for Proposals that will be sent to the vendors.  </span>
																		</span>
			                                    					</span>

																	<el-input
																	  type="textarea"
																	  :rows="4"
																	  placeholder="Please Enter The Organization's Terms and Conditions"
																	  v-model="ProjectModalInfo.ProjectTsNCs">
																	</el-input>
												        		</el-form-item>
															</div>
														</el-col>
													</el-row>


							<el-row :gutter="20">
								<el-col :span="18">
	                                <div class="card">
			                            <div class="card-body">
			                                <h4 class="card-title">Roles Assignments & Delegation of Authority</h4>
							             	<h6 class="card-subtitle">Please complete and/or update the list of employee(s) to which the below roles are assigned.</h6>
			                                <!-- Nav tabs -->
			                                <div class="vtabs">
							                        <ul class="nav nav-tabs tabs-vertical" role="tablist" style="direction: rtl;padding:0;overflow: scroll;height: 500px;display: block;width:200px">
							                                    	<li style="background-color: rgb(252, 51, 23);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalCAD" role="tab" aria-selected="true"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Application Administrator</span> </a> </li>

							                                        <li style="background-color: rgb(128, 189, 158);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalPRO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Enquiry Initiator</span> </a> </li>

							                                        <li style="background-color: rgb(128, 189, 158);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalPRV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Enquiry Validator</span></a> </li>

							                                        <li style="background-color: rgb(228, 234, 140);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRFQM" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Request for Proposals Administrator</span></a> </li>

							                                        <li style="background-color: rgb(205, 121, 185);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalPOO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Order Initiator</span></a> </li>

							                                        <li style="background-color: rgb(205, 121, 185);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalPOV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Purchase Order Validator</span></a> </li>

							                                        <li style="background-color: rgb(25, 149, 173);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRNO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Receipt Note Initiator</span></a> </li>

							                                        <li style="background-color: rgb(25, 149, 173);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRNV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Receipt Note Validator</span></a> </li>

							                                        <li style="background-color: rgb(161, 214, 226);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalSLM" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Vendors Qualification Initiator</span></a> </li>

							                                        <li style="background-color: rgb(161, 214, 226);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalSLMV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Vendors Qualification Validator</span></a> </li>

							                                        <li style="background-color: rgb(19, 160, 255);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalSUBO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down"> Budget Update Request Initiator</span></a> </li>

							                                        <li style="background-color: rgb(19, 160, 255);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalSUBV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Budget Update Request Validator</span></a> </li>


							                                        <li style="background-color: #fb6542" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalINV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Invoice Checker</span></a> </li>
	

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ffbb00" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalALMR" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Material Update Request Initiator </span></a> </li>

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ffbb00;" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalLMA" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Materials Administrator</span></a> </li>

							                                        <li v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" style="background-color: #ffbb00;" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalALMV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Library of Materials Validator</span></a> </li>


							                                        <li style="background-color: rgb(125,163,161);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRCCO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Creation Request Initiator</span></a> </li>
							                                        
							                                        <li style="background-color: rgb(125,163,161);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRCCV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Creation Request Validator</span></a> </li>
							                                        

							                                        <li style="background-color: rgb(125,163,161);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRCO" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Initiator</span></a> </li>
							                                        
							                                        <li style="background-color: rgb(125,163,161);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRCV" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Rates' Contracts Validator</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalSIIRPT" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom In-Charge</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalASTMGR" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Manager</span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRTIRS" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Transaction Request Initiator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalATSIRS" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Transaction Request Validator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalRTSI" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Items Update Request Initiator </span></a> </li>

							                                        <li v-if="ProjectInfo.StoreSetup == 'Yes'" style="background-color: rgb(245,202,153);" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#ModalATSI" role="tab" aria-selected="false"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span class="hidden-xs-down">Storeroom Items Update Request Validator </span></a> </li>
								                                       

							                        </ul>
			                                    <!-- Tab panes -->
			                                    <div style="width: 100%" class="tab-content">
													<div class="tab-pane active show StickeyPanelModal" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Roles Assignments & Delegation of Authority:</h3>
											             		<p>Please select a tab from the left side and drag and drop the employee(s) in their specific roles.</p>
									
										                        <!-- <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Roles Assignments & Delegation of Authority:
										                            </div>
										                            <div class="card-body">
																		<h1>Roles Assignments & Delegation of Authority:</h1>
										                            </div>
										                        </div> -->
										                        
									                        </div>

							                            </div>
							                        </div>
			                                    	<div class="tab-pane StickeyPanelModal" id="ModalCAD" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Application Administrator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Application Administrator(s) will manage the organization’s account on Ogéo. The Administrator will have the ability to assign roles to the employees and create "Setups". This is the most important role under Ogéo, and as such, shall be held by employees who are fully aware of the organization’s procurement procedures, rules and regulations.</p>

							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function, or to any other function which is responsible for driving the use of ERP systems (Centre of Excellence…)</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                            	Holder(s) of the Application Administrator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.CAD_List.length == 0">
																	     	<span class="text-danger">Drag and Drop the employee(s) name(s) below this line from the employees’ list on the right</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Application Administrator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.CAD_List" element="el-collapse" @add="onAddCADModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.CAD_List.length > 0" v-for="(e, index) in ProjectModalInfo.CAD_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.CAD_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>
							                            </div>
							                        </div>


							                        <div class="tab-pane StickeyPanelModal" id="ModalPRO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Purchase Enquiry Initiator <i class="italic">role assignment</i>:</h3>
											                                    
							                                    <p>The Purchase Enquiry Initiator(s) will be tasked with creating multiline "Purchase Enquiries" for the "Setup". On Ogéo, creating a "Purchase Enquiry", which can be created for either Materials or Services, sets in motion the process of procurement.</p>
							                                    
							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions, or to any other function which is responsible for utilising the materials and/or the services which are the subject of the "Purchase Enquiry", or any other function that holds the "Setup" Profit & Loss responsibility.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Purchase Enquiry Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.PRO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Purchase Enquiry Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.PRO_List" element="el-collapse" @add="onAddPROModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.PRO_List.length > 0" v-for="(e, index) in ProjectModalInfo.PRO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.PRO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


							                        <div class="tab-pane StickeyPanelModal" id="ModalPRV" role="tabpanel">
														<div class="row">
															<div class="col-lg-12">
									                            <h3>Purchase Enquiry Validator <i class="italic">role assignment</i>:</h3>
													                            
									                            <p>The Purchase Enquiry Validator(s) will be tasked with ruling on (approving/rejecting) the multiline
																	"Purchase Enquiries" created by the employees holding the Purchase Enquiry Initiator role. To enable this, the "Purchase Enquiries" Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of validations) for the "Setup" must be uploaded onto Ogéo. Ogéo can accommodate as 
																	many as 8 levels of approvals. Level-1 Is the entry level 
																	validator whereas the Level-8 validator is the final approver.</p>
									                            <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions, or to any other function within the Customer’s organization that is responsible for utilising the materials and/or the services which are the subject of the "Purchase Enquiry" or any other function that holds the "Setup" Profit & Loss responsibility.</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.PRV_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.PRV_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.PRV_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Purchase Enquiry Validator role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.PRV_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Purchase Request Validator Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.PRV_List['level_' + iteration]" element="el-collapse" @add="onAddPRVModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.PRV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.PRV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'PRV_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.PRV_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>



							                        <div class="tab-pane StickeyPanelModal" id="ModalRFQM" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Request for Proposals Administrator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Request for Proposals Administrator(s) will be tasked with creating multiline "Requests for Proposals". 
																	The employees holding this role will be responsible for publishing the "Request for Proposal" to the approved vendors, 
																	so that they can submit their bids to the organization on Ogéo. For the sake of governance, the "Requests for Proposals" are solely created on the back of: </p>
							                                    <ul>
							                                    	<li>Approved "Purchase Enquiries"; or</li>
							                                    	<li>"Approved Rates' Contract Creation Requests."</li>
							                                    </ul>
							                                    <p>The employees holding this role interact with the approved vendors, seek “sealed Bids”
																	and/or conduct “Live Auctions”, and review and comment on the Ogéo-generated “Commercial Evaluation”
																	reports.</p>
							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Request for Proposals role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RFQM_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Request for Proposals Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RFQM_List" element="el-collapse" @add="onAddRFQMModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RFQM_List.length > 0" v-for="(e, index) in ProjectModalInfo.RFQM_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RFQM_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>



							                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanelModal" id="ModalALMR" role="tabpanel">
							                        	<div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Library of Material Update Request Initiator  <i class="italic">role assignment</i>:</h3>
							                                    <!-- <p>The Library of Material Update Request Initiator(s) will be tasked with Items in lieu of materials are added to the organization’s "Library of Materials". Such requests are subject to reviews and approvals before the Items in lieu of to the "Library of Materials". </p> -->
							                                    <p>The Library of Material Update Request Initiator(s) will be tasked with raising 03 types of requests:
																					</p><p><ul>
																						<li>
																					Requesting that a new item is added to 
																					the organization’s "Library of Materials";
																						</li>
																						<li>
																					Requesting that an active “Library of 
																					Materials” item is inactivated; and
																						</li>
																						<li>
																					Requesting that an inactive “Library of 
																					Materials” item is reactivated.
																						</li>
																					</ul>
																				Such requests are subject to reviews and approvals before the "Library of Materials" is formally updated.</p>
                                                                <p>Inactivated items for any “Setup” are no longer accessible for the creation of “Purchase Enquiries” and “Rates’ Contracts” for the said “Setup”.</p>																
																<p> It’s important to note that the use of "Libraries of Materials", when originating "Purchase Enquiries", generates a valuable amount of information that will ultimately help the organization take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time. </p>
							                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Functions or to the Procurement Function or any other function which is responsible for continuous improvement (Centre of Excellence…)</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Library of Material Update Request Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.ALMR_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Library of Material Update Request Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.ALMR_List" element="el-collapse" @add="onAddALMRModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.ALMR_List.length > 0" v-for="(e, index) in ProjectModalInfo.ALMR_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.ALMR_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
													</div>




							                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanelModal" id="ModalLMA" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Library of Materials Administrator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Library of Material Administrator(s) will be tasked with reviewing the requests to update the organization’s "Library of Materials" as raised by the employees that hold the role of Library of Material Update Request Initiator. The role involves, among other things, updating the material’s information before the approval to add to the "Library of Materials" is sought.
																</p>
																<p>In general, the Library of Material Administrator role holders are the prime responsible for safeguarding the Library of Material data integrity and avoiding duplicates.
																</p>
																<p>It’s important to note that the use of "Libraries of Materials", to create "Purchase Enquiries", generates a valuable amount of information that will ultimately help the organization take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time. </p>
							                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Functions or to the Procurement Function or any other function which is responsible for continuous improvement (Centre of Excellence…)</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Library of Materials Administrator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.LMA_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Library of Materials Administrator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.LMA_List" element="el-collapse" @add="onAddLMAModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.LMA_List.length > 0" v-for="(e, index) in ProjectModalInfo.LMA_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.LMA_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


							                        <div v-if="AccountSettingForm.companyLoMManditory != 'FreeText'" class="tab-pane StickeyPanelModal" id="ModalALMV" role="tabpanel">
							                            <div class="row">
															<div class="col-lg-12">
									                            <h3>Library of Materials Validator <i class="italic">role assignment</i>:</h3>
													                            
													            <p>The Library of Material Validator(s) will be tasked with ruling on (approving/rejecting) the decisions made by the Library of Materials Administrator(s) with regards to requests raised by the employees holding the role of Library of Material Update Request Initiator. To enable this, the organization must upload, onto Ogéo, their Library of Materials’ Delegation of Authority: The number of levels of validation, and the respective seniority in the chain of validations. (Ogéo can accommodate as many as 8 levels of approvals Level-1 Is the entry level approver whereas the Level-8 is the final approver).</p>
								                            	<p>It’s important to note that the use of Libraries of Materials, to create "Purchase Enquiries", generates a valuable amount of information that will ultimately help the organization take data-driven decisions to improve its sourcing methodology, and in the process, reduce its sourcing cost and procurement turnaround time.</p>
									                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Functions or to the Procurement Function or any other function which is responsible for continuous improvement (Centre of Excellence…)</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.ALMV_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.ALMV_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.ALMV_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Library of Materials Validator role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.ALMV_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Library of Materials Validator Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.ALMV_List['level_' + iteration]" element="el-collapse" @add="onAddALMVModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.ALMV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.ALMV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'ALMV_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.ALMV_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>


							                        


							                        <div class="tab-pane StickeyPanelModal" id="ModalPOO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Purchase Order Initiator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Purchase Order Initiator(s) will be tasked with creating multiline "Purchase Orders" for Materials and/or Services. For the sake of governance, the employees holding this role can create "Purchase Orders", solely on the back of:</p>
							                                    <ul>
							                                    	<li>The results of the finalised "Commercial Evaluations"; or</li>
							                                    	<li>The approved "Rates' Contracts".</li>            
							                                    </ul>
							                                    <p>In addition, under certain circumstances (mainly if the awardee vendor fails to deliver the sourced materials and/or services according to the organization's Terms & Conditions), the Purchase Order Initiator will be in position to reissue an already awarded "Purchase Order".</p>
							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations 
																	Functions or to any other function responsible for utilizing the materials and/or the services 
																	which are the subject of the “Purchase Enquiry” that is linked to the “Purchase Order”.</p>
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Purchase Order Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.POO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Purchase Order Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.POO_List" element="el-collapse" @add="onAddPOOModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.POO_List.length > 0" v-for="(e, index) in ProjectModalInfo.POO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.POO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


                    <div class="tab-pane StickeyPanelModal" id="ModalPOV" role="tabpanel">
                    	<div class="row">
                            <div class="col-lg-12">
	                            <p>The Purchase Order Validator(s) will be tasked with ruling on (approving/rejecting) the multiline "Purchase Orders" created by the employees holding the Purchase Order Initiator role. To enable this, the “Purchase Orders”
	 								Delegation of Authority (i.e. the number of Purchase Orders’ levels of validation, and the financial limit-based seniority in the chain of validations) for the "Setup" must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator 
									whereas the Level-8 validator is the final approver).</p>
	                            <p>In addition, the Purchase Order Validator will be in position to rule on the "Purchase Order" cancelled by the Purchase Order Initiator.</p>
	                            <p style="font-style: italic" class="italic">“Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other 
									function responsible for utilizing the materials and/or the services which are the subject of the “Purchase Enquiry” that is linked 
									to the “Purchase Order”.</p>
								<el-select style="width: 100%;" v-model="ProjectModalInfo.POV_Number" placeholder="Enter number of validators">
								    <el-option label="1" value="1"></el-option>
								    <el-option label="2" value="2"></el-option>
								    <el-option label="3" value="3"></el-option>
								    <el-option label="4" value="4"></el-option>
								    <el-option label="5" value="5"></el-option>
								    <el-option label="6" value="6"></el-option>
								    <el-option label="7" value="7"></el-option>
								    <el-option label="8" value="8"></el-option>
								</el-select>

								<br><hr>
								
								<template v-if="ProjectModalInfo.POV_Number != null">
								<div class="card" v-for="iteration in parseInt(ProjectModalInfo.POV_Number)" :key="iteration">
							        <div class="card-header">
							            Holder(s) of the Purchase Order Validator role: <i>(Level-{{ iteration }}) Approval</i>
							        </div>
							        <div class="card-body">

							        	<div align="center" v-if="ProjectModalInfo.POV_List['level_' + iteration].length == 0">
									     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
									    </div>
									    <div align="center" v-else>
									    	<span class="text-success">Employees List with Purchase Order Validator Role</span>
									    </div>
										
										<draggable :list="ProjectModalInfo.POV_List['level_' + iteration]" element="el-collapse" @add="onAddPOVModal" :options="{group:'projectEmployees'}" >

										    <el-collapse-item v-if="ProjectModalInfo.POV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.POV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
										    	<div v-bind:id="'POV_level_'+iteration" style="display: none"></div>
										    	<div class="float-left">Name: {{e.fullName}}</div><br>
										        <div class="float-left">Email: {{e.email}}</div><br>
										        <div class="float-left">Designation: {{e.designation}}</div><br>
										        <div class="float-right p-b-10">
										        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.POV_List['level_' + iteration], index)"> Remove Employee</a>
										        </div>
										     </el-collapse-item>
										 
										</draggable>

									    

							        </div>

							        <div align="center" v-if="iteration == POV_Number" class="card-footer">
							        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
							        </div>

							        <div v-if="iteration < POV_Number" v-for="(currency, index) in ProjectModalInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
							        	<template v-if="index == 0">
								        	<div slot="footer" class="input-group">
	                                            <div class="input-group-prepend">
	                                                <span class="input-group-text">{{ currency }}</span>
	                                            </div>
	                                            <input :id="'POV_Modal_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
	                                            <input :id="'POV_Modal_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control">
	                                            <div class="input-group-append">
	                                                <span class="input-group-text">.00</span>
	                                            </div>
	                                        </div>
	                                    </template>
							        </div>
							    </div>
							    </template>
	                    	</div>

	                    	
                    	</div>
                    </div>
					<div class="tab-pane StickeyPanelModal" id="ModalSUBV" role="tabpanel">
                    	<div class="row">
                            <div class="col-lg-12">
								<h3>Budget Update Request Validator <i class="italic">role assignment</i>:</h3>
								<p>The Budget Update Request Validator(s) will be tasked with ruling on (approving/rejecting) requests to amend details pertaining to a “Setup”, created by the employees holding the Budget Update Request Initiator role. To
								enable this, the Budget Update Request’s Delegation of Authority (i.e. the number of levels of validation; and the seniority in 
								the chain of validations) must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of validations. Level-1 Is
								the entry level validator whereas the Level-8 validator is the final approver)</p>
								<p><i>Holder(s) of this role will typically belong to the organization’s Operations Functions or to 
								any other function which holds the "Setup" Profit & Loss responsibility.</i></p>
                                <!-- <p>The Setup Budget Validator(s) will be tasked with ruling on (approving/rejecting) the multiline "Setup Budget" created by the employees holding the Setup Budget Initiator role. To enable this, the Setup Budget Delegation of Authority (i.e. the number of Setup Budget levels of validation, and the financial limit-based seniority in the chain of approvals) for the "Setup" must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver). </p>
                                <p>In addition, the Setup Budget Validator will be in position to rule on the "Setup Budget" cancelled by the Setup Budget Initiator.</p>
                                <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for utilising the material and/or the service that is the subject of the Setup Budget, which is itself linked to a Setup Budget .”</p> -->

								<el-select style="width: 100%;" v-model="ProjectModalInfo.SUBV_Number" placeholder="Enter number of validators">
								    <el-option label="1" value="1"></el-option>
								    <el-option label="2" value="2"></el-option>
								    <el-option label="3" value="3"></el-option>
								    <el-option label="4" value="4"></el-option>
								    <el-option label="5" value="5"></el-option>
								    <el-option label="6" value="6"></el-option>
								    <el-option label="7" value="7"></el-option>
								    <el-option label="8" value="8"></el-option>
								</el-select>

								<br><hr>
								
								<template v-if="ProjectModalInfo.SUBV_Number != null">
								<div class="card" v-for="iteration in parseInt(ProjectModalInfo.SUBV_Number)" :key="iteration">
							        <div class="card-header">
							            Holder(s) of the Budget Update Request Validator role: <i>(Level-{{ iteration }}) Approval</i>
							        </div>
							        <div class="card-body">

							        	<div align="center" v-if="ProjectModalInfo.SUBV_List['level_' + iteration].length == 0">
									     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
									    </div>
									    <div align="center" v-else>
									    	<span class="text-success">Employees List with Budget Update Request Validator Role</span>
									    </div>
										
										<draggable :list="ProjectModalInfo.SUBV_List['level_' + iteration]" element="el-collapse" @add="onAddSUBVModal" :options="{group:'projectEmployees'}" >

										    <el-collapse-item v-if="ProjectModalInfo.SUBV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.SUBV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
										    	<div v-bind:id="'SUBV_level_'+iteration" style="display: none"></div>
										    	<div class="float-left">Name: {{e.fullName}}</div><br>
										        <div class="float-left">Email: {{e.email}}</div><br>
										        <div class="float-left">Designation: {{e.designation}}</div><br>
										        <div class="float-right p-b-10">
										        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.SUBV_List['level_' + iteration], index)"> Remove Employee</a>
										        </div>
										     </el-collapse-item>
										 
										</draggable>

									    

							        </div>

							        <!-- <div align="center" v-if="iteration == SUBV_Number" class="card-footer">
							        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
							        </div>

							        <div v-if="iteration < SUBV_Number" v-for="(currency, index) in ProjectModalInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
							        	<template v-if="index == 0">
								        	<div slot="footer" class="input-group">
	                                            <div class="input-group-prepend">
	                                                <span class="input-group-text">{{ currency }}</span>
	                                            </div>
	                                            <input :id="'SUBV_Modal_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
	                                            <input :id="'SUBV_Modal_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control">
	                                            <div class="input-group-append">
	                                                <span class="input-group-text">.00</span>
	                                            </div>
	                                        </div>
	                                    </template>
							        </div> -->
							    </div>
							    </template>
	                    	</div>

	                    	
                    	</div>
                    </div>


							                        <div class="tab-pane StickeyPanelModal" id="ModalRNO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Receipt Note Initiator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Receipt Note Initiator(s) will be tasked with creating multiline "Receipt Notes" to document, on Ogéo, the receipting of the delivered materials and/or services. The employees holding this role create the "Receipt Note" at the time of the delivery by the awardee vendor, to whom the "Purchase Order" was issued. </p>
							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any 
																	other function responsible for utilizing the materials and/or the services which are the subject of the “Purchase 
																	Enquiry” that is linked to the “Purchase Order”.</p>
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Receipt Note Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RNO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Receipt Note Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RNO_List" element="el-collapse" @add="onAddRNOModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RNO_List.length > 0" v-for="(e, index) in ProjectModalInfo.RNO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RNO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


                    <div class="tab-pane StickeyPanelModal" id="ModalRNV" role="tabpanel">

                        <div class="row">
                            <div class="col-lg-12">
	                            <h3>Receipt Note Validator <i class="italic">role assignment</i>:</h3>
													                            
	                            <p>The Receipt Note Validator(s) will be tasked with ruling on (approving/rejecting) the multiline "Receipt Notes" created by the employees holding the Receipt Note Initiator role. To enable this, the "Receipt Notes" Delegation of Authority (i.e the number of Receipt Notes’ level of validation, and the financial limit-based seniority in the chain of validations) for the "Setup", must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 validator is the final approver). </p>
	                            <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function responsible for utilizing the materials and/or the services which are the subject of the “Purchase Enquiry” that is linked to the “Purchase Order”.</p>

								<el-select style="width: 100%;" v-model="ProjectModalInfo.RNV_Number" placeholder="Enter number of validators">
								    <el-option label="1" value="1"></el-option>
								    <el-option label="2" value="2"></el-option>
								    <el-option label="3" value="3"></el-option>
								    <el-option label="4" value="4"></el-option>
								    <el-option label="5" value="5"></el-option>
								    <el-option label="6" value="6"></el-option>
								    <el-option label="7" value="7"></el-option>
								    <el-option label="8" value="8"></el-option>
								</el-select>

								<br><hr>
								
								<template v-if="ProjectModalInfo.RNV_Number != null">
								<div class="card" v-for="iteration in parseInt(ProjectModalInfo.RNV_Number)" :key="iteration">
							        <div class="card-header">
							            Holder(s) of the Receipt Note Validator role: <i>(Level-{{ iteration }}) Approval</i>
							        </div>
							        <div class="card-body">

							        	<div align="center" v-if="ProjectModalInfo.RNV_List['level_' + iteration].length == 0">
									     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
									    </div>
									    <div align="center" v-else>
									    	<span class="text-success">Employees List with Receipt Note Validator Role</span>
									    </div>
										
										<draggable :list="ProjectModalInfo.RNV_List['level_' + iteration]" element="el-collapse" @add="onAddRNVModal" :options="{group:'projectEmployees'}" >

										    <el-collapse-item v-if="ProjectModalInfo.RNV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.RNV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
										    	<div v-bind:id="'RNV_level_'+iteration" style="display: none"></div>
										    	<div class="float-left">Name: {{e.fullName}}</div><br>
										        <div class="float-left">Email: {{e.email}}</div><br>
										        <div class="float-left">Designation: {{e.designation}}</div><br>
										        <div class="float-right p-b-10">
										        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RNV_List['level_' + iteration], index)"> Remove Employee</a>
										        </div>
										     </el-collapse-item>
										 
										</draggable>

									    

							        </div>

							        <div align="center" v-if="iteration == RNV_Number" class="card-footer">
							        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
							        </div>

							        <div v-if="iteration < RNV_Number" v-for="(currency, index) in ProjectModalInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
							        	<template v-if="index == 0">
								        	<div slot="footer" class="input-group">
	                                            <div class="input-group-prepend">
	                                                <span class="input-group-text">{{ currency }}</span>
	                                            </div>
	                                            <input :id="'RNV_Modal_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
	                                            <input :id="'RNV_Modal_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control" >
	                                            <div class="input-group-append">
	                                                <span class="input-group-text">.00</span>
	                                            </div>
	                                        </div>
	                                    </template>
							        </div>
							    </div>
							    </template>
	                    	</div>

	                    	
                    	</div>
                    </div>


							                        <div class="tab-pane StickeyPanelModal" id="ModalSLM" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Vendors Qualification Initiator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Vendors Qualification Initiator(s) will be tasked with qualifying, approving and
																	enlisting vendors that the organization has chosen to work with, for all of its "Setups".
																	These vendors will then be in position to access Ogéo in order to:</p>
																<ul>
																	<li>Receive "Requests for Proposals"; </li>
																	<li>Raise “Requests for Information;” to seek clarifications, from the organization, on the raised “Requests for Proposals“; </li>
																	<li>Submit "Sealed Bids"; </li>
																	<li>Participate in "Live Auctions";</li>
																	<li>Receive "Purchase Orders"; and </li>
																	<li>Submit "Invoices" to the organization. </li>
																</ul>
																<p>The process of vendors’ qualification involves providing Ogéo access credentials to the enlisted vendors’ representatives.</p>
																<p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
													
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Vendors Qualification Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.SLM_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Vendors Qualification Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.SLM_List" element="el-collapse" @add="onAddSLMModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.SLM_List.length > 0" v-for="(e, index) in ProjectModalInfo.SLM_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.SLM_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>

													<div class="tab-pane StickeyPanel" id="ModalSLMV" role="tabpanel">
											                            <div class="row">
											                            	<div class="col-lg-12">
											                                    <h3>Vendors Qualification Validator <i class="italic">role assignment</i>:</h3>
																				<hr style="background-color: red">
																				<h4 class="text-ogeo">Important Note:</h4>
																				<p class="text-ogeo">
																					This is the only role whose holders operate across all the Setups:
																					when an employee is assigned this role for any Setup, he/she shall
																					hold this role for all the Setups of the organization. Similarly,
																					withdrawing this role from a user, for any Setup, shall lead to this
																					user being stripped of this role for all the Setups.
																				</p>
																				<hr style="background-color: red">
																				
																				<p>The <i>Vendors Qualification Validator(s)</i> will be tasked with reviewing
																					(validating/rejecting) the <i>Vendor Qualification Requests</i> submitted
																					by the <i>Vendors Qualification Initiator(s)</i>. When fully validated for a
																					<i>Setup</i>, a <i>Qualified Vendor</i> ultimately accesses Ogéo to start
																					transacting with the organization for all of its <i>Setups</i>, for as long as
																					its qualification remains valid.
																				</p>
																				<p>To enable this review process, the <u class="font-italic">
																					Vendors Qualification's Delegation Of Authority</u> of this <i>Setup</i> must first be uploaded onto
																					Ogéo, specifying the number of levels of validation and the
																					seniority of each validator of <i>Vendor Qualification Requests</i>, in the
																					review chain. Ogéo can support up to 8 levels of validation, with
																					Level-1 being the entry level and Level-8 being the final one.
																				</p>
																				<p>In addition to reviewing these requests, the
																					<i>Vendors Qualification Validator(s)</i>
																					with the highest validation level, will additionally be
																					tasked with adjusting the <i>Vendors Score Setting</i>.
																				</p>
													
																				<p style="font-style: italic; background-color: black; color: white;" class="italic">Holder(s) of this role will typically belong to the organization’s Procurement Function.</p>
													
																				<el-select style="width: 100%;" v-model="ProjectModalInfo.SLMV_Number" placeholder="Enter number of validators">
																					<el-option label="1" value="1"></el-option>
																					<el-option label="2" value="2"></el-option>
																					<el-option label="3" value="3"></el-option>
																					<el-option label="4" value="4"></el-option>
																					<el-option label="5" value="5"></el-option>
																					<el-option label="6" value="6"></el-option>
																					<el-option label="7" value="7"></el-option>
																					<el-option label="8" value="8"></el-option>
																				</el-select>
																				
																				<br><hr>

																				<template v-if="ProjectModalInfo.SLMV_Number != null">
																					<div class="card" v-for="iteration in parseInt(ProjectModalInfo.SLMV_Number)" :key="iteration">
																						<div class="card-header">
																							Holder(s) of the Vendor Qualification Validator role: <i>(Level-{{ iteration }}) Approval</i>
																						</div>
																						<div class="card-body">

																							<div align="center" v-if="ProjectModalInfo.SLMV_List['level_' + iteration].length == 0">
																								<span class="text-danger">Drag and Drop the employee(s) name(s) below this line from the employees’ list on the right.</span>
																							</div>
																							<div align="center" v-else>
																								<span class="text-success">Employees List with Purchase Enquiry Validator Role</span>
																							</div>
																							
																							<draggable :list="ProjectModalInfo.SLMV_List['level_' + iteration]" element="el-collapse" @add="onAddSLMVModal" :options="{group:'projectEmployees'}" >

																								<el-collapse-item v-if="ProjectModalInfo.SLMV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.SLMV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																									<div v-bind:id="'SLMV_level_'+iteration" style="display: none"></div>
																									<div class="float-left">Name: {{e.fullName}}</div><br>
																									<div class="float-left">Email: {{e.email}}</div><br>
																									<div class="float-left">Designation: {{e.designation}}</div><br>
																									<div class="float-right p-b-10">
																										<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.SLMV_List['level_' + iteration], index)"> Remove Employee</a>
																									</div>
																									</el-collapse-item>
																								
																							</draggable>
																						</div>
																					</div>
																				</template>
													                        </div>

													                        

											                            </div>
											                        </div>


													<div class="tab-pane StickeyPanelModal" id="ModalSUBO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
															<h3>Budget Update Request Initiator <i class="italic">role assignment</i>:</h3>
															<p>The Budget Update Request Initiator(s) will be tasked with originating
															requests to amend details pertaining to a “Setup”. These
															amendments are strictly limited to increasing or decreasing the available Budget, and/or modifying the “Setup” End Date.</p>
															<p><i>Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which holds the "Setup" Profit & Loss responsibility.</i>
															</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Budget Update Request Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.SUBO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Budget Update Request Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.SUBO_List" element="el-collapse" @add="onAddSUBOModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.SUBO_List.length > 0" v-for="(e, index) in ProjectModalInfo.SUBO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.SUBO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>
							                            </div>
							                        </div>



							                        <div class="tab-pane StickeyPanelModal" id="ModalINV" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>The Invoice Checker <i class="italic">role assignment</i>:</h3>
							                                    <p>The Invoice Checker(s) will be tasked with reviewing the invoices raised, through the Vendor Portal, to ensure that the invoied amount matches the amount keyed in by the Vendors' authorized employees.</p>
							                                    <p>Vendors can raise invoices on the back of the following transactions:</p>
							                                    <ul>
							                                    	<li>Amounts, contractually due to the vendors on the basis of awarded "Purchase Orders", become due for payment as evidenced by the origination  and approval of "Receipt Notes", and</li>
							                                    	<li>If applicable, amounts contractually retained by the organization, become due for release, after the elapse of the retention timeframe and the fulfilment of the "Purchase Orders".</li>
							                                    </ul>
							                                    <p style="font-style: italic" class="italic">Holder(s) of this role will typically belong to the organization’s Finance Function (Accounts Payable).</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Invoice Checker role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.INV_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Invoice Checker Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.INV_List" element="el-collapse" @add="onAddINVModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.INV_List.length > 0" v-for="(e, index) in ProjectModalInfo.INV_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.INV_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>



							                        <div class="tab-pane StickeyPanelModal" id="ModalRCCO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Rates' Contract Creation Request Initiator <i class="italic">role assignment</i>:</h3>
																<p>The Rates' Contract Creation Request Initiator(s) will be tasked with originating requests to establish "Rates’ Contracts" for selected items from the “Library of Materials”. These requests, once validated, are sent to the employees holding the Request For Proposals Administrator Role so that offers, including both “Lead Times” & “Unit Rates”, can be sourced from approved vendors directly on Ogéo. Once these offers are sourced, the decision can then to be sign formal “Rates’ Contract”. Once signed, these “Rates’ Contracts” can be called upon by the employees holding the Purchase Enquiry Initiator role within the organization, regardless of the “Setup” these employees are enrolled under.</p>
																<p style="font-style: italic;">Holder(s) of this role will typically belong to the Customer’s Operations Functions or to any other function which is responsible for streamlining the procurement processes.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Rates' Contract Creation Request Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RCCO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Rates' Contract Creation Request Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RCCO_List" element="el-collapse" @add="onAddRCCOModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RCCO_List.length > 0" v-for="(e, index) in ProjectModalInfo.RCCO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RCCO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>
							                        

							                        <div class="tab-pane StickeyPanelModal" id="ModalRCCV" role="tabpanel">
							                            <div class="row">
															<div class="col-lg-12">
									                            <h3>Rates' Contract Creation Request Validator <i class="italic">role assignment</i>:</h3>
													                            
									                            <p>The Rates' Contract Creation Request Validator(s) will be tasked with ruling on (approving/rejecting) the "Rates' 
																Contract" Creation Requests that were initiated by the authorized employees holding the Rates' Contract Creation 
																Request Initiator role. To enable this, the organization must upload, onto Ogéo, its Rates’ Contracts Creation Requests
																Delegation of Authority: The number of levels of validation, and the respective seniority in the chain of approvals. (Ogéo 
																can accommodate as many as 8 levels of validations Level-1 Is the entry level approver whereas the Level-8 is the final 
																approver).</p>
									                            <p>It’s important to note that the use of "Rates’ Contracts" enables a speedy sourcing of materials when the "Purchase Enquiries" are originated.</p>
									                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the Customer’s Operations Functions or to any other function which is responsible for streamlining the procurement processes.</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.RCCV_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.RCCV_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.RCCV_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Rates' Contract Creation Request Validator role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.RCCV_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Contract Creation Request Approver Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.RCCV_List['level_' + iteration]" element="el-collapse" @add="onAddRCCVModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.RCCV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.RCCV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'RCCV_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RCCV_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>


							                        <div class="tab-pane StickeyPanelModal" id="ModalRCO" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Rates' Contract Initiator <i class="italic">role assignment</i>:</h3>
																<p>The Rates' Contract Initiator(s) will be tasked with initiating the process of creating 
																"Rates’ Contracts", on the back of approved Rates' Contract Creation Requests. Once the 
																"Rates’ Contracts" are originated by the employees holding this role, they are sent for 
																approval before the vendors are asked to submit their offers.</p>
																<p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for streamlining procurement processes.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Rates' Contract Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RCO_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Rates' Contract Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RCO_List" element="el-collapse" @add="onAddRCOModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RCO_List.length > 0" v-for="(e, index) in ProjectModalInfo.RCO_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RCO_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


					<div class="tab-pane StickeyPanelModal" id="ModalRCV" role="tabpanel">
                    	<div class="row">
                            <div class="col-lg-12">
	                            <h3>Rates' Contract Validator <i class="italic">role assignment</i>:</h3>
													                            
	                            <p>The Rates' Contract Validator(s) will be tasked with ruling on (approving/rejecting) the
								establishment of "Rates’ Contracts" with vendors. To enable this, the organization must 
								upload, onto Ogéo, its Rates’ Contracts’ Delegation of Authority: The number of levels of 
								validation, and the financial limit-based seniority in the chain of validations (Ogéo can 
								accommodate as many as 8 levels of validations. Level-1 Is the entry level validator 
								whereas the Level-8 is the final approver).</p>
	                            <p>The "Rates’ Contracts" will solely be created on the back of the proposals submitted by the different vendors that received the Requests For Proposals.</p>
	                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Functions or to any other function which is responsible for streamlining procurement processes.</p>

								<el-select style="width: 100%;" v-model="ProjectModalInfo.RCV_Number" placeholder="Enter number of validators">
								    <el-option label="1" value="1"></el-option>
								    <el-option label="2" value="2"></el-option>
								    <el-option label="3" value="3"></el-option>
								    <el-option label="4" value="4"></el-option>
								    <el-option label="5" value="5"></el-option>
								    <el-option label="6" value="6"></el-option>
								    <el-option label="7" value="7"></el-option>
								    <el-option label="8" value="8"></el-option>
								</el-select>

								<br><hr>
								
								<template v-if="ProjectModalInfo.RCV_Number != null">
								<div class="card" v-for="iteration in parseInt(ProjectModalInfo.RCV_Number)" :key="iteration">
							        <div class="card-header">
							            Holder(s) of the Rates' Contract Validator role: <i>(Level-{{ iteration }}) Approval</i>
							        </div>
							        <div class="card-body">

							        	<div align="center" v-if="ProjectModalInfo.RCV_List['level_' + iteration].length == 0">
									     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
									    </div>
									    <div align="center" v-else>
									    	<span class="text-success">Employees List with Rates' Contract Validator Role</span>
									    </div>
										
										<draggable :list="ProjectModalInfo.RCV_List['level_' + iteration]" element="el-collapse" @add="onAddRCVModal" :options="{group:'projectEmployees'}" >

										    <el-collapse-item v-if="ProjectModalInfo.RCV_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.RCV_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
										    	<div v-bind:id="'POV_level_'+iteration" style="display: none"></div>
										    	<div class="float-left">Name: {{e.fullName}}</div><br>
										        <div class="float-left">Email: {{e.email}}</div><br>
										        <div class="float-left">Designation: {{e.designation}}</div><br>
										        <div class="float-right p-b-10">
										        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RCV_List['level_' + iteration], index)"> Remove Employee</a>
										        </div>
										     </el-collapse-item>
										 
										</draggable>

									    

							        </div>

							        <div align="center" v-if="iteration == RCV_Number" class="card-footer">
							        	<span class="text-primary" slot="footer">The Highest Level Validator Doesn't Have Any Financial Limit.</span>
							        </div>

							        <div v-if="iteration < RCV_Number" v-for="(currency, index) in ProjectModalInfo.ProjectCompanyCurrency.split(',')" class="card-footer">
							        	<template v-if="index == 0">
								        	<div slot="footer" class="input-group">
	                                            <div class="input-group-prepend">
	                                                <span class="input-group-text">{{ currency }}</span>
	                                            </div>
	                                            <input :id="'RCV_Modal_List_level_'+iteration+'_currency_'+index" type="hidden" :value="currency" style="display: none">
	                                            <input :id="'RCV_Modal_List_level_'+iteration+'_limit_'+index" type="number" value="" class="form-control">
	                                            <div class="input-group-append">
	                                                <span class="input-group-text">.00</span>
	                                            </div>
	                                        </div>
	                                    </template>
							        </div>
							    </div>
							    </template>
	                    	</div>

	                    	
                    	</div>
                    </div>


		
                    								<div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalASTMGR" role="tabpanel">
							                            <div class="row">
															<div class="col-lg-12">
									                            <h3>Storeroom Manager <i class="italic">role assignment</i>:</h3>
													                            
									                            <p>The Storeroom Manager(s) have access to ALL the Storerooms information across the organizations.</p>
									                            <p>The Storeroom Manager(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities:</p>
									                            <ul>
									                            	<li>Ruling on (approving/rejecting) the request to add new “Storeroom Items”, as originated by the Storeroom In-Charge;</li>
									                            	<li>Ruling on (approving/rejecting) the request to suspend the “Storeroom Items” status, as originated by the Storeroom In-Charge;</li>
									                            	<li>Ruling on (approving/rejecting) the outcome of the periodic Stock Count Exercises, as originated by the Storeroom In-Charge; and</li>
									                            	<li>Originating and/or approving the Storeroom Items Transfer between Storerooms. (Level 1 will originate the transfer while Level 2 and above will approve)</li>
									                            </ul>
									                            <p>To enable this, the Storerooms’ Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
									                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.ASTMGR_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.ASTMGR_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.ASTMGR_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Storeroom Manager role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.ASTMGR_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom Manager Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.ASTMGR_List['level_' + iteration]" element="el-collapse" @add="onAddASTMGRModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.ASTMGR_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.ASTMGR_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'ASTMGR_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.ASTMGR_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>

							                        <div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalSIIRPT" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>Storeroom In-Charge <i class="italic">role assignment</i>:</h3>
							                                    <p>The Storeroom In-Charge will be tasked will be tasked, for the “Setup” (in this case a Storeroom), with the following activities: </p>
							                                    <ul>
							                                    	<li>Accepting the requests to issue Storeroom Items on the back of approved “Storeroom Item Issue Notes”, and actually issuing the concerned “Storeroom Items” from the Storeroom; </li>
							                                    	<li>Accepting the requests to return Storeroom Items on the back of approved “Storeroom Item Return Notes”, and actually accepting the return of the concerned Storeroom Items to the Storeroom; </li>
							                                    	<li>Originating the addition of new Storeroom Items, as approved by the Storeroom Items Update Request Validator;</li>
							                                    	<li>Originating the suspension of “Storeroom Items” status, as approved by the Storeroom Items Update Request Validator;</li>
							                                    	<li>Incrementing the levels of “Storeroom Items”, to document the receipting of such “Storeroom Items”, when the related “Receipt Notes’ are accepted by created by the employees holding the Receipt Note Validator role;</li>
							                                    	<li>Originating periodic Stock Count Exercises; and</li>
							                                    	<li>
							                                    		Executing forced “Storeroom Items” level movement on the back of:
							                                    		<ul>
							                                    			<li>Stock Count Exercise outcome, as approved by the Storeroom Manager; and</li>
							                                    			<li>
							                                    				Storeroom Items Transfer as  approved by Storeroom Manager and, as the case may be:
							                                    				<ul>
							                                    					<li>Actually issuing the concerned “Storeroom Items”, from the Storeroom, for the Storeroom Items Transfer to happen; or</li>
							                                    					<li>Actually accepting the return of the concerned “Storeroom Items” to the Storeroom, for the Storeroom Items Transfer to happen.</li>
							                                    				</ul>
							                                    			</li>
							                                    		</ul>
							                                    	</li>
							                                    </ul>
							                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Storeroom In-Charge role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.SIIRPT_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom In-Charge Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.SIIRPT_List" element="el-collapse" @add="onAddSIIRPTModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.SIIRPT_List.length > 0" v-for="(e, index) in ProjectModalInfo.SIIRPT_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.SIIRPT_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


							                        <div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalRTIRS" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>The Storeroom Transaction Request Initiator <i class="italic">role assignment</i>:</h3>
							                                    <p>The Storeroom Transaction Request Initiator(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities:</p>
							                                    <ul>
							                                    	<li>Originating “Storeroom Item Issue Notes”; and </li>
							                                    	<li>Originating “Storeroom Item Return Notes”.</li>
							                                    </ul>
							                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Function or any other Function which is responsible for utilising the “Storeroom Item” or any other function that holds a Profit & Loss responsibility.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Storeroom Transaction Request Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RTIRS_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom Transaction Request Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RTIRS_List" element="el-collapse" @add="onAddRTIRSModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RTIRS_List.length > 0" v-for="(e, index) in ProjectModalInfo.RTIRS_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RTIRS_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


							                        <div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalATSIRS" role="tabpanel">
							                            <div class="row">
															<div class="col-lg-12">
									                            <h3>The Storeroom Transaction Request Validator <i class="italic">role assignment</i>:</h3>
									                            <p>The Storeroom Transaction Request Validator(s) will be tasked with ruling on (approving/rejecting) the requests created by the employees holding the Storeroom Transaction Request Initiator role. </p>
									                            <p>To enable this, the Storeroom Transactions’ Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
									                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Operations Function or any other Function which is responsible for utilising the “Storeroom Item” or any other function that holds a Profit & Loss responsibility.</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.ATSIRS_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.ATSIRS_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.ATSIRS_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Storeroom Transaction Request Validator role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.ATSIRS_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom Transaction Request Validator Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.ATSIRS_List['level_' + iteration]" element="el-collapse" @add="onAddATSIRSModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.ATSIRS_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.ATSIRS_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'ATSIRS_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.ATSIRS_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>

							                        <div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalRTSI" role="tabpanel">
							                            <div class="row">
							                            	<div class="col-lg-12">
							                                    <h3>The Storeroom Items Update Request Initiator  <i class="italic">role assignment</i>:</h3>
							                                    <p>The Storeroom Items Update Request Initiator(s) will be tasked, for the “Setup” (in this case a Storeroom), with the following activities: </p>
							                                    <ul>
							                                    	<li>Requesting that new materials, extracted from the “Library of Materials” are turned into “Storeroom Items”; and </li>
							                                    	<li>Requesting, that the status of “Storeroom Items” is suspended for existing Storeroom Items; </li>
							                                    </ul>
							                                    <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations. This role can only also be held by employees of the organization’s Operations Function.</p>
									
										                        <div class="card">
										                            <div class="card-header">
										                                Holder(s) of the Storeroom Items Update Request Initiator role:
										                            </div>
										                            <div class="card-body">

										                            	<div align="center" v-if="ProjectModalInfo.RTSI_List.length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom Items Update Request Initiator Role</span>
																	    </div>

																		<draggable :list="ProjectModalInfo.RTSI_List" element="el-collapse" @add="onAddRTSIModal" :options="{group:'projectEmployees'}" >
																		    <el-collapse-item v-if="ProjectModalInfo.RTSI_List.length > 0" v-for="(e, index) in ProjectModalInfo.RTSI_List" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.RTSI_List, index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
											                            
										                            </div>
										                        </div>
									                        </div>

									                        

							                            </div>
							                        </div>


							                        <div v-if="ProjectModalInfo.StoreSetup == 'Yes'" class="tab-pane StickeyPanelModal" id="ModalATSI" role="tabpanel">
							                            <div class="row">
															<div class="col-lg-12">
									                            <h3>The Storeroom Items Update Request Validator <i class="italic">role assignment</i>:</h3>
													                            
									                            <p>The Storeroom Items Update Request Validator(s) will be tasked with ruling on (approving/rejecting) the requests created by the employees holding the Storeroom Items Update Request Initiator role. </p>
									                            <p>To enable this, the Storeroom Items Update Delegation of Authority (i.e. the number of levels of validation; and the seniority in the chain of approvals) for the “Setup” must be uploaded onto Ogéo. (Ogéo can accommodate as many as 8 levels of approvals. Level-1 Is the entry level validator whereas the Level-8 is the final approver).</p>
									                            <p style="font-style: italic;">Holder(s) of this role will typically belong to the organization’s Warehousing Functions, or to any other function within the Customer’s organization that is responsible for the Supply Chain Operations. This role can only also be held by employees of the organization’s Operations Function.</p>

																<el-select style="width: 100%;" v-model="ProjectModalInfo.ATSI_Number" placeholder="Enter number of validators">
																    <el-option label="1" value="1"></el-option>
																    <el-option label="2" value="2"></el-option>
																    <el-option label="3" value="3"></el-option>
																    <el-option label="4" value="4"></el-option>
																    <el-option label="5" value="5"></el-option>
																    <el-option label="6" value="6"></el-option>
																    <el-option label="7" value="7"></el-option>
																    <el-option label="8" value="8"></el-option>
																</el-select>

																<br><hr>
																
																<template v-if="ProjectModalInfo.ATSI_Number != null">
																<div class="card" v-for="iteration in parseInt(ProjectModalInfo.ATSI_Number)" :key="iteration">
															        <div class="card-header">
															            Holder(s) of the Storeroom Items Update Request role: <i>(Level-{{ iteration }}) Approval</i>
															        </div>
															        <div class="card-body">

															        	<div align="center" v-if="ProjectModalInfo.ATSI_List['level_' + iteration].length == 0">
																	     	<span class="text-danger">Drag & Drop The Employee Name Below This Line</span>
																	    </div>
																	    <div align="center" v-else>
																	    	<span class="text-success">Employees List with Storeroom Items Update Request Role</span>
																	    </div>
																		
																		<draggable :list="ProjectModalInfo.ATSI_List['level_' + iteration]" element="el-collapse" @add="onAddATSIModal" :options="{group:'projectEmployees'}" >

																		    <el-collapse-item v-if="ProjectModalInfo.ATSI_List['level_' + iteration].length != 0" v-for="(e, index) in ProjectModalInfo.ATSI_List['level_' + iteration]" :title="index+1 +'. '+e.fullName.substring(0, 75) + '...' " :name="e.fullName" :key="e.email">
																		    	<div v-bind:id="'ATSI_level_'+iteration" style="display: none"></div>
																		    	<div class="float-left">Name: {{e.fullName}}</div><br>
																		        <div class="float-left">Email: {{e.email}}</div><br>
																		        <div class="float-left">Designation: {{e.designation}}</div><br>
																		        <div class="float-right p-b-10">
																		        	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(ProjectModalInfo.ATSI_List['level_' + iteration], index)"> Remove Employee</a>
																		        </div>
																		     </el-collapse-item>
																		 
																		</draggable>
															        </div>
															    </div>
																</template>
									                    	</div>
									                    	

							                            </div>
							                        </div>

													
							                        
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
	                            </el-col>

								<el-col class="StickeyPanel" :span="6">
									
									<div class="row "> <!-- b-l -->

										<div class="col-lg-12 " style="overflow: scroll;height:500px"> <!-- p-t-10 b-l -->
											<div class="card" style="margin-bottom: 5px">
												<div class="card-header">
													Employees List
												</div>
												<h4 class="col-lg-12 p-t-10" style="margin-top:20px">Add Employees in Bulk:</h4>
												<form id="mainForm" class="col-lg-12 p-t-10" style="width=100%">
													<div class="form-group">
														<a class="btn btn-danger btn-block" href="/downloads/Ogéo-Users.xlsx" download>Step 1: Download</a>
														<label class="btn btn-success btn-block">Step 2: Select File
															<input style="display:none" type="file" name="select_file" />
														</label>
														<label class="btn btn-primary btn-block"> Step 3: Upload
															<input style="display:none" @click.prevent="getExcelFile" type="submit" name="upload" value="Upload">
														</label>
													</div>
												</form>
												<hr>
												<div style="text-align:center;margin-top: -30px ">
													<span class="button" style="color:white;padding:10px;background:black;border-radius:10px">OR</span>
												</div>	
												<h4 class="col-lg-12 p-t-10">Add Employees, One At The Time:</h4>
												<div class="col-lg-12 p-t-10"> <!-- b-l -->
													<!-- <label class="">New Employee Name</label> -->
													<input type="text" v-model="NewEmployee.fullName" class="form-control" placeholder="Enter Employee Name">
													<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.fullName">{{ NewEmployeeErrors.fullName[0] }}</span>
												</div>

												<div class="col-lg-12 p-t-10"> <!-- b-l -->
													<!-- <label class="">New Employee Email</label> -->
													<input type="email" v-model="NewEmployee.email" class="form-control" placeholder="Enter Employee Email">
													<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.email">{{ NewEmployeeErrors.email[0] }}</span>
												</div>

												<div class="col-lg-12 p-t-10 p-b-10"> <!--  b-b b-l -->
													<!-- <label class="">Designation</label> -->
													<input type="text" v-model="NewEmployee.designation"  class="form-control" placeholder="Enter Employee Designation">
													<span class="text-danger" style="font-size: 12px" v-if="NewEmployeeErrors && NewEmployeeErrors.designation">{{ NewEmployeeErrors.designation[0] }}</span>
												</div>

												<div class="col-lg-12 p-t-10 ">
													<a class="btn btn-success btn-block" style="color: white" @click="creatNewEmployee">Create New Employee</a>
												</div>
												<div class="card-body">
													<div class="drag">
														<!-- <draggable v-model="list" class="dragArea" :options="{group:{ name:'people',  pull:'clone', put:false }}">
															<div v-for="element in list">{{element.name}}</div>
														</draggable> -->
														<draggable element="el-collapse" :list="EmployeesList" :options="{group:{ name:'projectEmployees',  pull:'clone', put:false }}" >
															
															<el-collapse-item v-if="EmployeesList.length != 0" v-for="(employee, index) in EmployeesList" :title="employee.fullName.substring(0, 20) + '...' " :name="employee.fullName" :key="employee.email">
																<div class="float-left">Name: {{employee.fullName}}</div><br>
																<div class="float-left"><b>Email:</b> {{employee.email}}</div><br>
																<div class="float-left"><b>Designation:</b> {{employee.designation}}</div><br>
																<div class="float-right p-b-10">
																	<a style="font-size: 12px" href="#" class="btn btn-danger" v-on:click.prevent="deleteEmployee(EmployeesList, index, 'EmployeesList', 0, employee.email)"> Remove Employee</a>
																</div>
															</el-collapse-item>
														
															<div align="center" v-if="EmployeesList.length == 0">
																<span>Please Add Employee</span>
															</div>

														</draggable>
													</div>
												</div>
											</div>
										</div>
										
										<hr>
									</div>
								</el-col>
							</el-row>
						</el-form>
	                    
	                </div>
	                <div class="modal-footer">
	                    <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Close</button>
	                    <button type="button" @click="updateProjectDetails" class="btn btn-success waves-effect text-left">Save and Update</button>
	                </div>
	            </div>
	            <!-- /.modal-content -->
	        </div>
	        <!-- /.modal-dialog -->
	    </div>

	</div>

</template>


<script>
	
	//import { CustomJs } from '../../helpers/custom.js';
	import validateJs from 'validate.js';
	import draggable from 'vuedraggable';
	import moment from 'moment';

	export default {
		name: 'account-setting',
		components: { 
			draggable,
		},
		data(){
			return{
				logopreview: "",
		  hideUploadEdit: false,
    AllTimeZone:[
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', 
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', 
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
],
			countries: [],
			cities: [],
			    EmployeesList: [],
			    ProjectKey: "",
			    CAD_List: [],
			    PRO_List: [],
			    PRV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    PRV_Number: null,
			    ALMR_List: [],
			    LMA_List: [],
			    ALMV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    ALMV_Number: null,
			    RFQM_List: [],
			    POO_List: [],
			    POV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
				},
				POV_Number: null,
				SUBV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
				},
				SUBV_Number: null,
			    RNO_List: [],
			    RNV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    RNV_Number: null,
			    SLM_List: [],
				SLMV_List: {
                    level_1: [],
                    level_2: [],
                    level_3: [],
                    level_4: [],
                    level_5: [],
                    level_6: [],
                    level_7: [],
                    level_8: []
                },
                SLMV_Number: null,
			    SUBO_List: [],
			    INV_List: [],

			    ASTMGR_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    ASTMGR_Number: null,
                SIIRPT_List: [],
                RTIRS_List: [],
                ATSIRS_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    ATSIRS_Number: null,
                RTSI_List: [],
                ATSI_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    ATSI_Number: null,

			    RCCO_List: [],
			    RCCV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    RCCV_Number: null,
			    RCO_List: [],
			    RCV_List: {
			    	level_1: [],
			    	level_2: [],
			    	level_3: [],
			    	level_4: [],
			    	level_5: [],
			    	level_6: [],
			    	level_7: [],
			    	level_8: []
			    },
			    RCV_Number: null,
			    POV_Financial_Details: [],
			    SUBV_Financial_Details: [],
			    RNV_Financial_Details: [],
			    RCV_Financial_Details: [],
				EmployeeRange: [
					{
			          value: '1 - 5',
			          label: '1 - 5'
			        },{
			          value: '6 - 10',
			          label: '6 - 10'
			        }, {
			          value: '11 - 50',
			          label: '11 - 50'
			        }, {
			          value: '51 - 100',
			          label: '51 - 100'
			        }, {
			          value: '101 - 500',
			          label: '101 - 500'
			        }, {
			          value: '501 - 1000',
			          label: '501 - 1000'
			        }, {
			          value: '1001 - 5000',
			          label: '1001 - 5000'
			        }, {
			          value: '5001 - 10000+',
			          label: '5001 - 10000+'
			        }
				],
				Tduplicatecount : 0,	
		        NewEmployee: {
		        	fullName: "",
					email: "",
					designation: "",
		        },
		        NewEmployeeErrors: null,
		        loading: false,
                FirstStepData:{
					CompanyLogo: "",
                    companyName: "",
                    companyTime:"",
                    CompanyEmployeeRange: [],
                    CompanyIndustry: [],
                    CompanyCities: [],
                    CompanyCountries: [],
				},
				SecondStepData:{
                    companyPEPrefix: "",
					companyRFQPrefix: "",
					companyComEvalPrefix: "",
					companyPOPrefix: "",
					companySTRIPrefix: "",
					companySTRRPrefix: "",
					companyRNPrefix: "",
					companyAdditionalField: "",
					companyStoreAdditionalField: "",
					CustomizationNumbering: "",
				},
				ThirdStepData:{
				  companyLoMManditory:"",
				  companyLoMPrefix: "",
				},
				AccountSettingForm: {
					companyName: "",
					companyTime:"",
					CompanyEmployeeRange: [],
					CompanyIndustry: [],
					CompanyCities: [],
					CompanyCountries: [],
					SubscriptionType: "",
					BillingInfo: "",
					companyPEPrefix: "",
					companyRFQPrefix: "",
					companyComEvalPrefix: "",
					companyPOPrefix: "",
					companySTRIPrefix: "",
					companySTRRPrefix: "",
					companyRNPrefix: "",
					companyLoMManditory: "",
					companyLoMPrefix: "",
					procurementSetupMode: "Project Wise",
					companyAdditionalField: "",
					companyStoreAdditionalField: "",
					PODOACriterion: "",
					RNDOACriterion: "",
					CompanyCurrency: "",
					CompanyBudget: "",
					companyTsNCs: "",
					AuctionType: "",
				},
				ProjectsDetails: [],
				ShowProjectDetails: false,
				ProjectModalInfo: "",
				ProjectInfo: {
					StoreSetup: "",
					ProjectTitle: "",
					ProjectPeriod: "",
					ProjectValue: "",
					//ProjectValueCurrency: "",
					ProjectPODOACriterion: "",
					ProjectRNDOACriterion: "",
					ProjectAuctionType: [],
					ProjectCompanyCurrency: "",
					ProjectTsNCs: ""
				},
				Industries: [],
				// Countries: [],
				searchedCities: [],
			    rules1: {
					companyName: [{
						required: true,
						message: 'Please enter the organization name',
						trigger: 'blur'
					}, {
						min: 3,
						message: 'The minimum length is 3 characters',
						trigger: 'blur'
					}],
					CompanyEmployeeRange: [{
						required: true,
						message: 'Please select range of employee numbers',
						trigger: 'blur'
					}],
					CompanyIndustry: [{
						required: true,
						message: 'Please select an insdustry',
						trigger: 'blur'
					}],
					CompanyCities: [{
						required: true,
						message: 'Please select a city',
						trigger: 'blur'
					}],
					CompanyCountries: [{
						required: true,
						message: 'Please select a country',
						trigger: 'blur'
					}],
				},
				rules2: {
					companyPEPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companyRFQPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companyComEvalPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companyPOPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companySTRIPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companySTRRPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companyRNPrefix: [{
						max: 4,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
					companyAdditionalField: [{
						required: true,
						message: 'This field is mandatory',
						trigger: 'blur'
					}],
					companyStoreAdditionalField: [{
						required: true,
						message: 'This field is mandatory',
						trigger: 'blur'
					}],
			    },
			    rules3: {
			    	procurementSetupMode: [{
						required: true,
						message: 'Please setup Mode',
						trigger: 'change'
					}],
			    	PODOACriterion: [{
			    		required: true,
			    		message: "Please select PO DOA Criterion",
			    		trigger: "change"
			    	}],
			    	RNDOACriterion: [{
			    		required: true,
			    		message: "Please select RN DOA Criterion",
			    		trigger: "change"
			    	}],
			    	CompanyCurrency: [{
			    		required: true,
			    		message: "Please select currency",
			    		trigger: ["blur", "change"]
			    	}],
			    	CompanyBudget: [{
			    		required: true,
			    		message: "Please enter company budget",
			    		trigger: ["blur", "change"]
			    	}],
			    	AuctionType: [{
			    		required: true,
			    		message: "Please select auction type",
			    		trigger: ["blur", "change"]
			    	}],
			    },
			    rules4: {
			    	procurementSetupMode: [{
						required: true,
						message: 'Please setup Mode',
						trigger: 'change'
					}],
			    	StoreSetup: [{
			    		required: true,
			    		message: "Please Select Setup Type",
			    		trigger: "blur"
			    	}],
			    	ProjectTitle: [{
			    		required: true,
			    		message: "Please Enter Setup Title",
			    		trigger: "blur"
			    	}],
			    	ProjectPeriod: [{
			    		required: true,
			    		message: "Please Select Project Period",
			    		trigger: "change"
			    	}],
			    	ProjectValue: [{
			    		required: true,
			    		message: "Please Enter The Setup’s Budget",
			    		trigger: "blur"
			    	}],
			    	ProjectPODOACriterion: [{
			    		required: true,
			    		message: "Please Enter Setup PO DOA Criterion",
			    		trigger: "change"
			    	}],
			    	ProjectRNDOACriterion: [{
			    		required: true,
			    		message: "Please Enter Setup RN DOA Criterion",
			    		trigger: "change"
			    	}],
			    	ProjectAuctionType: [{
			    		required: true,
			    		message: "Please Select Applicable Auction Types",
			    		trigger: "change"
			    	}],
					ProjectTsNCs: [{
			    		required: true,
			    		message: "Please Select Applicable Auction Types",
			    		trigger: "change"
			    	}],
			    	ProjectCompanyCurrency: [{
			    		required: true,
			    		message: "Please Select Applicable Currency",
			    		trigger: "change"
			    	}]
			    },
			    LoMrules: {
			    	companyLoMManditory: [{
						required: true,
						message: 'Please choose an option',
						trigger: 'change'
					}],
					companyLoMPrefix: [{
						max: 3,
						message: 'Maximum Length is 4 characters',
						trigger: 'blur'
					}],
			    },
			    EmployeeCreateRules: {
			    	fullName: {
                        presence: true,
                        length: {
                            minimum: 3,
                            message: "must be atleast 3 characters"
                        }
                    },
                    email: {
                        presence: true,
                        email: true
                    },
                    designation: {
                        presence: true,
                        length: {
                            minimum: 3,
                            message: 'must be at least 3 characters'
                        }
                    }	
			    },

			    /* Item Template Creation */
				DataTableItemTemplate: "",
			    Set_Required: false,
			    TemplatesDetails: [],
				ThirdPartyVendors:{
					active: ''
				},
                ItemStructureSetup: {	
                    ID: "",
                    Template_Name: "",
                    Field_1_Required: true,
                    Field_1_Header: "",
                    Field_1_Mandatory: "Yes",
                    Field_1_ConsolidatedTitles: "",
                    Field_2_Required: false,
                    Field_2_Header: "",
                    Field_2_Mandatory: "",
					Field_2_ConsolidatedTitles: "",
                    Field_3_Required: false,
                    Field_3_Header: "",
                    Field_3_Mandatory: "",
					Field_3_ConsolidatedTitles: "",
                    Field_4_Required: false,
                    Field_4_Header: "",
                    Field_4_Mandatory: "",
					Field_4_ConsolidatedTitles: "",
                    Field_5_Required: false,
                    Field_5_Header: "",
                    Field_5_Mandatory: "",
					Field_5_ConsolidatedTitles: "",
                    Field_6_Required: false,
                    Field_6_Header: "",
                    Field_6_Mandatory: "",
					Field_6_ConsolidatedTitles: "",
                    Field_7_Required: false,
                    Field_7_Header: "",
                    Field_7_Mandatory: "",
					Field_7_ConsolidatedTitles: "",
                    Field_8_Required: false,
                    Field_8_Header: "",
                    Field_8_Mandatory: "",
					Field_8_ConsolidatedTitles: "",
                    Field_9_Required: false,
                    Field_9_Header: "",
                    Field_9_Mandatory: "",
					Field_9_ConsolidatedTitles: "",
                    Field_10_Required: false,
                    Field_10_Header: "",
                    Field_10_Mandatory: "",
					Field_10_ConsolidatedTitles: "",
                    Field_11_Required: false,
                    Field_11_Header: "",
                    Field_11_Mandatory: "",
					Field_11_ConsolidatedTitles: "",
                    Field_12_Required: false,
                    Field_12_Header: "",
                    Field_12_Mandatory: "",
					Field_12_ConsolidatedTitles: "",
                    Field_13_Required: false,
                    Field_13_Header: "",
                    Field_13_Mandatory: "",
					Field_13_ConsolidatedTitles: "",
                    Field_14_Required: false,
                    Field_14_Header: "",
                    Field_14_Mandatory: "",
					Field_14_ConsolidatedTitles: "",
                    Field_15_Required: false,
                    Field_15_Header: "",
                    Field_15_Mandatory: "",
					Field_15_ConsolidatedTitles: "",
                    Field_16_Required: false,
                    Field_16_Header: "",
                    Field_16_Mandatory: "",
					Field_16_ConsolidatedTitles: "",
                    Field_17_Required: false,
                    Field_17_Header: "",
                    Field_17_Mandatory: "",
					Field_17_ConsolidatedTitles: "",
                    Field_18_Required: false,
                    Field_18_Header: "",
                    Field_18_Mandatory: "",
					Field_18_ConsolidatedTitles: "",
                    Field_19_Required: false,
                    Field_19_Header: "",
                    Field_19_Mandatory: "",
					Field_19_ConsolidatedTitles: "",
                    Field_20_Required: false,
                    Field_20_Header: "",
                    Field_20_Mandatory: "",
					Field_20_ConsolidatedTitles: "",
					ConsolidatedTitles_asterisk: "",
                    ConsolidatedTitles: "",
                    Action: "",
                },
                FormRules: {
                    Template_Name: [{
                        required: true,
                        message: "Category Name Required",
                        trigger: ["blur"]
                    }],
                    Field_1_Required: [{
                        required: true,
                        message: "This Field Is Mandatory",
                        trigger: ["blur", "change"]
                    }],
                    Field_1_Header: [{
                        required: true,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_1_Mandatory: [{
                        required: true,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_2_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_2_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_2_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_3_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_3_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_3_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_4_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_4_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_4_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_5_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_5_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_5_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_6_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_6_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_6_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_7_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_7_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_7_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_8_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_8_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_8_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_9_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_9_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_9_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_10_Required: [{
                        required: false,
                        message: "Do You Want to Set This Field",
                        trigger: ["blur", "change"]
                    }],
                    Field_10_Header: [{
                        required: false,
                        message: "Enter Header Name",
                        trigger: "blur"
                    }],
                    Field_10_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_11_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_12_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_13_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_14_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_15_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_16_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_17_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_18_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_19_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
                    Field_20_Mandatory: [{
                        required: false,
                        message: "Please Choose 'Yes' or 'No's",
                        trigger: ["blur", "change"]
                    }],
				},
				fileList: [],
				SetupCompleted: false,
				exceluser:[],
				exceltamplate:[],
				TermConditions: '',
			}
		
		},

		computed:{
            currentUser(){
                return this.$store.getters.currentUser
            }
        },
		created() {
            this.getCountries()
            this.GetLastTC()
        },
		methods:{
			GetLastTC(){
                axios.get('/api/system_management/get_last_TC')
                .then((response) => {
                    this.TermConditions = response.data.term_conditions
                })
            },
			clickitem(e) {
				console.log(e);
				e === this.ThirdPartyVendors.active ? this.ThirdPartyVendors.active = '' : this.ThirdPartyVendors.active = e
			},
			checkHeader1(){
				if(this.ItemStructureSetup.Field_1_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_1_ConsolidatedTitles = this.ItemStructureSetup.Field_1_Header + '*';
				} 
			},
			checkHeader2(){
				if(this.ItemStructureSetup.Field_2_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_2_ConsolidatedTitles = this.ItemStructureSetup.Field_2_Header + '*';
				}
				else{
					this.ItemStructureSetup.Field_2_ConsolidatedTitles = ''
				} 
			},
			checkHeader3(){
				if(this.ItemStructureSetup.Field_3_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_3_ConsolidatedTitles = this.ItemStructureSetup.Field_3_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_3_ConsolidatedTitles = ''

				} 
			},
			checkHeader4(){
				if(this.ItemStructureSetup.Field_4_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_4_ConsolidatedTitles = this.ItemStructureSetup.Field_4_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_4_ConsolidatedTitles = ''
				} 
			},
			checkHeader5(){
				if(this.ItemStructureSetup.Field_5_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_5_ConsolidatedTitles = this.ItemStructureSetup.Field_5_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_5_ConsolidatedTitles = ''
				} 
			},
			checkHeader6(){
				if(this.ItemStructureSetup.Field_6_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_6_ConsolidatedTitles = this.ItemStructureSetup.Field_6_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_6_ConsolidatedTitles = ''
				} 
			},
			checkHeader7(){
				if(this.ItemStructureSetup.Field_7_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_7_ConsolidatedTitles = this.ItemStructureSetup.Field_7_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_7_ConsolidatedTitles = ''
				} 
			},
			checkHeader8(){
				if(this.ItemStructureSetup.Field_8_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_8_ConsolidatedTitles = this.ItemStructureSetup.Field_8_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_8_ConsolidatedTitles = ''
				} 
			},
			checkHeader9(){
				if(this.ItemStructureSetup.Field_9_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_9_ConsolidatedTitles = this.ItemStructureSetup.Field_9_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_9_ConsolidatedTitles = ''
				} 
			},
			checkHeader10(){
				if(this.ItemStructureSetup.Field_10_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_10_ConsolidatedTitles = this.ItemStructureSetup.Field_10_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_10_ConsolidatedTitles = ''
				} 
			},
			checkHeader11(){
				if(this.ItemStructureSetup.Field_11_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_11_ConsolidatedTitles = this.ItemStructureSetup.Field_11_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_11_ConsolidatedTitles = ''
				} 
			},
			checkHeader12(){
				if(this.ItemStructureSetup.Field_12_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_12_ConsolidatedTitles = this.ItemStructureSetup.Field_12_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_12_ConsolidatedTitles = ''
				} 
			},
			checkHeader13(){
				if(this.ItemStructureSetup.Field_13_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_13_ConsolidatedTitles = this.ItemStructureSetup.Field_13_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_13_ConsolidatedTitles = ''
				} 
			},
			checkHeader14(){
				if(this.ItemStructureSetup.Field_14_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_14_ConsolidatedTitles = this.ItemStructureSetup.Field_14_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_14_ConsolidatedTitles = ''
				} 
			},
			checkHeader15(){
				if(this.ItemStructureSetup.Field_15_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_15_ConsolidatedTitles = this.ItemStructureSetup.Field_15_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_15_ConsolidatedTitles = ''
				} 
			},
			checkHeader16(){
				if(this.ItemStructureSetup.Field_16_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_16_ConsolidatedTitles = this.ItemStructureSetup.Field_16_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_16_ConsolidatedTitles = ''
				} 
			},
			checkHeader17(){
				if(this.ItemStructureSetup.Field_17_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_17_ConsolidatedTitles = this.ItemStructureSetup.Field_17_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_17_ConsolidatedTitles = ''
				} 
			},
			checkHeader18(){
				if(this.ItemStructureSetup.Field_18_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_18_ConsolidatedTitles = this.ItemStructureSetup.Field_18_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_18_ConsolidatedTitles = ''
				} 
			},
			checkHeader19(){
				if(this.ItemStructureSetup.Field_19_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_19_ConsolidatedTitles = this.ItemStructureSetup.Field_19_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_19_ConsolidatedTitles = ''
				} 
			},
			checkHeader20(){
				if(this.ItemStructureSetup.Field_20_Mandatory == 'Yes' )
				{
					this.ItemStructureSetup.Field_20_ConsolidatedTitles = this.ItemStructureSetup.Field_20_Header + '*';
					
				}
				else{
					this.ItemStructureSetup.Field_20_ConsolidatedTitles = ''
				} 
			},
			commaprice() {
                this.ProjectInfo.ProjectValue = this.ProjectInfo.ProjectValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.ProjectModalInfo.ProjectValue = this.ProjectModalInfo.ProjectValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			getCountries() {
                axios.get('api/get/countries')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
			getCities() {
				if(this.FirstStepData.CompanyCountries != '' || this.FirstStepData.CompanyCountries != null ){
					axios.get('api/get/cities/' + this.FirstStepData.CompanyCountries)
					.then((res) => {
						this.cities = res.data
					})
					.catch((err) => {
					})
				}
            },
			/* Item Template Methods */
		    deleteTemplate(RecordID){

                Swal({
                    title: 'Delete This Template',
                    text: "Are you sure you what to delete this template.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
                        this.TemplatesDetails.splice(RecordID, 1);                 
                       	Swal('Template Removed Successfully', 'Template was removed from the list successfully', 'success');
                    }
				})
			},
			getExcelFile(){
                var $mainForm = $('#mainForm');
                var data = new FormData(mainForm);
                axios.post('/api/users/import_excel/import', data)
                .then((response) => {
					if(response.data.message == "Success"){
						this.exceluser = response.data.data
						this.creatNewEmployeeExcel()
					}
					else{
                		Swal('Excel', 'Please select .xlsx files Accepted', 'error');
					}
                })
			},
			getExcelFileTamplate(){
				// alert('in funciton');
				var $mainFormTamplate = $('#mainFormTamplate');
                var data = new FormData(mainFormTamplate);
                axios.post('/api/users/import_excel/tamplate', data)
                .then((response) => {
					if(response.data.message == "Success"){
						this.exceltamplate = response.data.data
						this.creatNewEmployeeExcelTamplate()
                		// Swal('Uploaded', 'Your Bulk Template has been uploaded successfully', 'success');

					}
					else{
                		Swal('Excel', 'Please select .xlsx files Accepted', 'error');
					}
                })
			},
			
			creatNewEmployeeExcel() {
				// alert('enters');
				
                const NewlyAdded = this.exceluser;
				
				
				
				for(var i=0;i<this.exceluser.length;i++){

					const NewlyAddedusers = this.exceluser[i].employee_email;

					const exsits = this.EmployeesList.filter(function(elem) {
						if(elem.email == NewlyAddedusers) {
							return true;
						}
					});

					if(exsits.length > 0){
						Swal('Duplicate Employee.', 'Employee already exists in Employees List.', 'warning')
					} else {

						// this.EmployeesList.push({
						// 	fullName: this.NewEmployee.fullName,
						// 	email: this.NewEmployee.email,
						// 	designation: this.NewEmployee.designation,
						// });
						this.EmployeesList.push({
							fullName: this.exceluser[i].employee_name,
							email: this.exceluser[i].employee_email,
							designation: this.exceluser[i].employee_name,
						});
						this.NewEmployee.fullName = "";
						this.NewEmployee.email = "";
						this.NewEmployee.designation = "";
						this.NewEmployeeErrors = null;
                		Swal('Employee(s) Added', 'Employee(s) Was/Were Successfully Added To The Employees List.', 'success');
						// Swal('Employee has been created successfully', '', 'success');
					}
                    
                }

                // this.NewEmployee.fullName = "";
                // this.NewEmployee.email = "";
                // this.NewEmployee.designation = "";
                // this.NewEmployeeErrors = null;

			},
			// SubmitItemSetupFormExcelTamplate: function() {
            // 	let self = this;
            // 	let duplicated = false;
            // 	self.ItemStructureSetup.ConsolidatedTitles = "";
            //     this.$refs.ItemStructureSetupForm.validate((validation) => {

            //     	/* Check Category Name Exists */
            //     	$.each(self.TemplatesDetails, function(index, Template) {
            //     		if(Template.Template_Name == self.ItemStructureSetup.Template_Name){
            //     			Swal('Duplicated Category Name', 'Duplicated Category Names are not allowed.', 'warning');
	        //         		 /* iterate through array or object */
	        //         		 validation = false;
	        //         		 duplicated = true;
	        //         		 return false;
            //     		}
            //     	});

            //         if(validation){
            //         	let TemplateIndexLocation = self.TemplatesDetails.length;
            //         	$.each(this.ItemStructureSetup, function(index, val) {
            //         		if(val != '' && index.includes('Header')){
            //         			self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
            //         		}
            //         	});
            //         	self.ItemStructureSetup.Action = '<button type="button" class="btn btn-block btn-danger"><i class="fa fa-remove"></i> </button>';
            //         	self.ItemStructureSetup.ID = TemplateIndexLocation;
            //         	self.TemplatesDetails.push(Object.assign({}, self.ItemStructureSetup));
			// 			Swal('Template Added Successfully', 'The Library of Materials Has a New Template', 'success');                            
            //         } else if (validation == false && duplicated == false) {
            //         	Swal('Missing Info', 'Please Fill The Mandatory Fields', 'warning');
            //         }
            //     })
            // },
			creatNewEmployeeExcelTamplate() {

                // const NewlyAdded = this.exceltamplate;
                for(var i=0;i<this.exceltamplate.length;i++){
					
					this.ItemStructureSetup.Template_Name = this.exceltamplate[i].Template_Name;
					
					if(this.exceltamplate[i].Field_1_Header != null)
					{
						this.ItemStructureSetup.Field_1_Header = this.exceltamplate[i].Field_1_Header;
						this.ItemStructureSetup.Field_1_Mandatory = this.exceltamplate[i].Field_1_Mandatory;
						this.ItemStructureSetup.Field_1_Required = true;
					};
					if(this.exceltamplate[i].Field_2_Header != null)
					{
						this.ItemStructureSetup.Field_2_Header = this.exceltamplate[i].Field_2_Header;
						this.ItemStructureSetup.Field_2_Mandatory = this.exceltamplate[i].Field_2_Mandatory;
						this.ItemStructureSetup.Field_2_Required = true;
					};
					if(this.exceltamplate[i].Field_3_Header != null)
					{
						this.ItemStructureSetup.Field_3_Header = this.exceltamplate[i].Field_3_Header;
						this.ItemStructureSetup.Field_3_Mandatory = this.exceltamplate[i].Field_3_Mandatory;
						this.ItemStructureSetup.Field_3_Required = true;
					};
					if(this.exceltamplate[i].Field_4_Header != null)
					{
						this.ItemStructureSetup.Field_4_Header = this.exceltamplate[i].Field_4_Header;
						this.ItemStructureSetup.Field_4_Mandatory = this.exceltamplate[i].Field_4_Mandatory;
						this.ItemStructureSetup.Field_4_Required = true;
					};
					if(this.exceltamplate[i].Field_5_Header != null)
					{
						this.ItemStructureSetup.Field_5_Header = this.exceltamplate[i].Field_5_Header;
						this.ItemStructureSetup.Field_5_Mandatory = this.exceltamplate[i].Field_5_Mandatory;
						this.ItemStructureSetup.Field_5_Required = true;
					};
					if(this.exceltamplate[i].Field_6_Header != null)
					{
						this.ItemStructureSetup.Field_6_Header = this.exceltamplate[i].Field_6_Header;
						this.ItemStructureSetup.Field_6_Mandatory = this.exceltamplate[i].Field_6_Mandatory;
						this.ItemStructureSetup.Field_6_Required = true;
					};
					if(this.exceltamplate[i].Field_7_Header != null)
					{
						this.ItemStructureSetup.Field_7_Header = this.exceltamplate[i].Field_7_Header;
						this.ItemStructureSetup.Field_7_Mandatory = this.exceltamplate[i].Field_7_Mandatory;
						this.ItemStructureSetup.Field_7_Required = true;
					};
					if(this.exceltamplate[i].Field_8_Header != null)
					{
						this.ItemStructureSetup.Field_8_Header = this.exceltamplate[i].Field_8_Header;
						this.ItemStructureSetup.Field_8_Mandatory = this.exceltamplate[i].Field_8_Mandatory;
						this.ItemStructureSetup.Field_8_Required = true;
					};
					if(this.exceltamplate[i].Field_9_Header != null)
					{
						this.ItemStructureSetup.Field_9_Header = this.exceltamplate[i].Field_9_Header;
						this.ItemStructureSetup.Field_9_Mandatory = this.exceltamplate[i].Field_9_Mandatory;
						this.ItemStructureSetup.Field_9_Required = true;
					};
					if(this.exceltamplate[i].Field_10_Header != null)
					{
						this.ItemStructureSetup.Field_10_Header = this.exceltamplate[i].Field_10_Header;
						this.ItemStructureSetup.Field_10_Mandatory = this.exceltamplate[i].Field_10_Mandatory;
						this.ItemStructureSetup.Field_10_Required = true;
					};
					if(this.exceltamplate[i].Field_11_Header != null)
					{
						this.ItemStructureSetup.Field_11_Header = this.exceltamplate[i].Field_11_Header;
						this.ItemStructureSetup.Field_11_Mandatory = this.exceltamplate[i].Field_11_Mandatory;
						this.ItemStructureSetup.Field_11_Required = true;
					};
					if(this.exceltamplate[i].Field_12_Header != null)
					{
						this.ItemStructureSetup.Field_12_Header = this.exceltamplate[i].Field_12_Header;
						this.ItemStructureSetup.Field_12_Mandatory = this.exceltamplate[i].Field_12_Mandatory;
						this.ItemStructureSetup.Field_12_Required = true;
					};
					if(this.exceltamplate[i].Field_13_Header != null)
					{
						this.ItemStructureSetup.Field_13_Header = this.exceltamplate[i].Field_13_Header;
						this.ItemStructureSetup.Field_13_Mandatory = this.exceltamplate[i].Field_13_Mandatory;
						this.ItemStructureSetup.Field_13_Required = true;
					};
					if(this.exceltamplate[i].Field_14_Header != null)
					{
						this.ItemStructureSetup.Field_14_Header = this.exceltamplate[i].Field_14_Header;
						this.ItemStructureSetup.Field_14_Mandatory = this.exceltamplate[i].Field_14_Mandatory;
						this.ItemStructureSetup.Field_14_Required = true;
					};
					if(this.exceltamplate[i].Field_15_Header != null)
					{
						this.ItemStructureSetup.Field_15_Header = this.exceltamplate[i].Field_15_Header;
						this.ItemStructureSetup.Field_15_Mandatory = this.exceltamplate[i].Field_15_Mandatory;
						this.ItemStructureSetup.Field_15_Required = true;
					};
					if(this.exceltamplate[i].Field_16_Header != null)
					{
						this.ItemStructureSetup.Field_16_Header = this.exceltamplate[i].Field_16_Header;
						this.ItemStructureSetup.Field_16_Mandatory = this.exceltamplate[i].Field_16_Mandatory;
						this.ItemStructureSetup.Field_16_Required = true;
					};
					if(this.exceltamplate[i].Field_17_Header != null)
					{
						this.ItemStructureSetup.Field_17_Header = this.exceltamplate[i].Field_17_Header;
						this.ItemStructureSetup.Field_17_Mandatory = this.exceltamplate[i].Field_17_Mandatory;
						this.ItemStructureSetup.Field_17_Required = true;
					};
					if(this.exceltamplate[i].Field_18_Header != null)
					{
						this.ItemStructureSetup.Field_18_Header = this.exceltamplate[i].Field_18_Header;
						this.ItemStructureSetup.Field_18_Mandatory = this.exceltamplate[i].Field_18_Mandatory;
						this.ItemStructureSetup.Field_18_Required = true;
					};
					if(this.exceltamplate[i].Field_19_Header != null)
					{
						this.ItemStructureSetup.Field_19_Header = this.exceltamplate[i].Field_19_Header;
						this.ItemStructureSetup.Field_19_Mandatory = this.exceltamplate[i].Field_19_Mandatory;
						this.ItemStructureSetup.Field_19_Required = true;
					};
					if(this.exceltamplate[i].Field_20_Header != null)
					{
						this.ItemStructureSetup.Field_20_Header = this.exceltamplate[i].Field_20_Header;
						this.ItemStructureSetup.Field_20_Mandatory = this.exceltamplate[i].Field_20_Mandatory;
						this.ItemStructureSetup.Field_20_Required = true;
					};
					let self = this;
					let duplicated = false;
					self.ItemStructureSetup.ConsolidatedTitles = "";

					this.$refs.ItemStructureSetupForm.validate((validation) => {
						/* Check Category Name Exists */
						$.each(self.TemplatesDetails, function(index, Template) {
							if(Template.Template_Name == self.ItemStructureSetup.Template_Name){
								
								// Swal('Duplicate Template', 'Duplicated Templates are not allowed', 'warning');
								// alert('Duplicate Template')
								self.Tduplicatecount = self.Tduplicatecount + 1
								// console.log(self.count)
								/* iterate through array or object */
								validation = false;
								duplicated = true;
								return false;
							}
						});
						if(self.Tduplicatecount > 0){
							Swal('Duplicate Template', 'Duplicated Templates are not allowed', 'warning');
							self.Tduplicatecount = 0;
						}

						if(validation){
							let TemplateIndexLocation = self.TemplatesDetails.length;
							$.each(this.ItemStructureSetup, function(index, val) {
								if(val != '' && index.includes('Header')){
									// self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									if(index == 'Field_1_Header')
									{
										if(self.ItemStructureSetup.Field_1_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_1_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_2_Header')
									{
										if(self.ItemStructureSetup.Field_2_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_2_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_3_Header')
									{
										if(self.ItemStructureSetup.Field_3_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_3_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_4_Header')
									{
										if(self.ItemStructureSetup.Field_4_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_4_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_5_Header')
									{
										if(self.ItemStructureSetup.Field_5_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_5_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_6_Header')
									{
										if(self.ItemStructureSetup.Field_6_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_6_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_7_Header')
									{
										if(self.ItemStructureSetup.Field_7_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_7_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_8_Header')
									{
										if(self.ItemStructureSetup.Field_8_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_8_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_9_Header')
									{
										if(self.ItemStructureSetup.Field_9_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_9_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_10_Header')
									{
										if(self.ItemStructureSetup.Field_10_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_10_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_11_Header')
									{
										if(self.ItemStructureSetup.Field_11_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_11_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_12_Header')
									{
										if(self.ItemStructureSetup.Field_12_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_12_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_13_Header')
									{
										if(self.ItemStructureSetup.Field_13_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_13_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_14_Header')
									{
										if(self.ItemStructureSetup.Field_14_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_14_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_15_Header')
									{
										if(self.ItemStructureSetup.Field_15_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_15_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_16_Header')
									{
										if(self.ItemStructureSetup.Field_16_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_16_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_17_Header')
									{
										if(self.ItemStructureSetup.Field_17_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_17_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_18_Header')
									{
										if(self.ItemStructureSetup.Field_18_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_18_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_19_Header')
									{
										if(self.ItemStructureSetup.Field_19_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_19_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
									if(index == 'Field_20_Header')
									{
										if(self.ItemStructureSetup.Field_20_Mandatory == 'Yes')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

										}
										if(self.ItemStructureSetup.Field_20_Mandatory == 'No')
										{
											self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
										}
										
									}
								}
							});
							self.ItemStructureSetup.Action = '<button type="button" class="btn btn-block btn-danger"><i class="fa fa-remove"></i> </button>';
							self.ItemStructureSetup.ID = TemplateIndexLocation;
							self.TemplatesDetails.push(Object.assign({}, self.ItemStructureSetup));
							Swal('Template Added Successfully', 'The Library of Materials Has a New Template', 'success');                            
						} else if (validation == false && duplicated == false) {
							Swal('Missing Info', 'Please Fill The Mandatory Fields', 'warning');
						}
					})


				}
				this.ItemStructureSetup = {	
                    ID: "",
                    Template_Name: "",
                    Field_1_Required: true,
                    Field_1_Header: "",
                    Field_1_Mandatory: "Yes",
                    Field_2_Required: false,
                    Field_2_Header: "",
                    Field_2_Mandatory: "",
                    Field_3_Required: false,
                    Field_3_Header: "",
                    Field_3_Mandatory: "",
                    Field_4_Required: false,
                    Field_4_Header: "",
                    Field_4_Mandatory: "",
                    Field_5_Required: false,
                    Field_5_Header: "",
                    Field_5_Mandatory: "",
                    Field_6_Required: false,
                    Field_6_Header: "",
                    Field_6_Mandatory: "",
                    Field_7_Required: false,
                    Field_7_Header: "",
                    Field_7_Mandatory: "",
                    Field_8_Required: false,
                    Field_8_Header: "",
                    Field_8_Mandatory: "",
                    Field_9_Required: false,
                    Field_9_Header: "",
                    Field_9_Mandatory: "",
                    Field_10_Required: false,
                    Field_10_Header: "",
                    Field_10_Mandatory: "",
                    Field_11_Required: false,
                    Field_11_Header: "",
                    Field_11_Mandatory: "",
                    Field_12_Required: false,
                    Field_12_Header: "",
                    Field_12_Mandatory: "",
                    Field_13_Required: false,
                    Field_13_Header: "",
                    Field_13_Mandatory: "",
                    Field_14_Required: false,
                    Field_14_Header: "",
                    Field_14_Mandatory: "",
                    Field_15_Required: false,
                    Field_15_Header: "",
                    Field_15_Mandatory: "",
                    Field_16_Required: false,
                    Field_16_Header: "",
                    Field_16_Mandatory: "",
                    Field_17_Required: false,
                    Field_17_Header: "",
                    Field_17_Mandatory: "",
                    Field_18_Required: false,
                    Field_18_Header: "",
                    Field_18_Mandatory: "",
                    Field_19_Required: false,
                    Field_19_Header: "",
                    Field_19_Mandatory: "",
                    Field_20_Required: false,
                    Field_20_Header: "",
                    Field_20_Mandatory: "",
                    ConsolidatedTitles: "",
                    Action: "",
                };

            },
			/* Item Template Methods */
		    deleteTemplate(RecordID){

                Swal({
                    title: 'Delete This Template',
                    text: "Are you sure you what to delete this template.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!',
                    cancelButtonText: 'No'
                }).then((result) => {
                    if (result.value) {
						// console.log(RecordID)
						this.TemplatesDetails.splice(RecordID, 1);                 
						// console.log(this.TemplatesDetails.length)
						if(this.TemplatesDetails.length == 0)
						{
							// alert(this.TemplatesDetails.length)
							this.TemplatesDetails == null
						}
                       	Swal('Template Removed Successfully', 'Template was removed from the list successfully', 'success');
                    }

                    
                })
            },
            SubmitItemSetupForm: function() {
				let self = this;
            	let duplicated = false;
            	self.ItemStructureSetup.ConsolidatedTitles = "";
                this.$refs.ItemStructureSetupForm.validate((validation) => {
					
                	/* Check Category Name Exists */
                	$.each(self.TemplatesDetails, function(index, Template) {
						if(Template.Template_Name == self.ItemStructureSetup.Template_Name){
                			Swal('Duplicate Template', 'Duplicated Templates are not allowed', 'warning');
	                		 /* iterate through array or object */
	                		 validation = false;
	                		 duplicated = true;
	                		 return false;
                		}
                	});

                    if(validation){
						let TemplateIndexLocation = self.TemplatesDetails.length;
                    	$.each(this.ItemStructureSetup, function(index, val) {
							if(val != '' && index.includes('Header')){
								if(index == 'Field_1_Header')
								{
									if(self.ItemStructureSetup.Field_1_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_1_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_2_Header')
								{
									if(self.ItemStructureSetup.Field_2_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_2_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_3_Header')
								{
									if(self.ItemStructureSetup.Field_3_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_3_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_4_Header')
								{
									if(self.ItemStructureSetup.Field_4_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_4_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_5_Header')
								{
									if(self.ItemStructureSetup.Field_5_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_5_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_6_Header')
								{
									if(self.ItemStructureSetup.Field_6_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_6_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_7_Header')
								{
									if(self.ItemStructureSetup.Field_7_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_7_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_8_Header')
								{
									if(self.ItemStructureSetup.Field_8_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_8_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_9_Header')
								{
									if(self.ItemStructureSetup.Field_9_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_9_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_10_Header')
								{
									if(self.ItemStructureSetup.Field_10_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_10_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_11_Header')
								{
									if(self.ItemStructureSetup.Field_11_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_11_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_12_Header')
								{
									if(self.ItemStructureSetup.Field_12_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_12_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_13_Header')
								{
									if(self.ItemStructureSetup.Field_13_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_13_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_14_Header')
								{
									if(self.ItemStructureSetup.Field_14_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_14_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_15_Header')
								{
									if(self.ItemStructureSetup.Field_15_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_15_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_16_Header')
								{
									if(self.ItemStructureSetup.Field_16_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_16_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_17_Header')
								{
									if(self.ItemStructureSetup.Field_17_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_17_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_18_Header')
								{
									if(self.ItemStructureSetup.Field_18_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_18_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_19_Header')
								{
									if(self.ItemStructureSetup.Field_19_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_19_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
								if(index == 'Field_20_Header')
								{
									if(self.ItemStructureSetup.Field_20_Mandatory == 'Yes')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val + '*' +' | ';	

									}
									if(self.ItemStructureSetup.Field_20_Mandatory == 'No')
									{
										self.ItemStructureSetup.ConsolidatedTitles = self.ItemStructureSetup.ConsolidatedTitles + val +' | ';	
									}
									
								}
									
                    		}
                    	});
                    	self.ItemStructureSetup.Action = '<button type="button" class="btn btn-block btn-danger"><i class="fa fa-remove"></i> </button>';
                    	self.ItemStructureSetup.ID = TemplateIndexLocation;
                    	self.TemplatesDetails.push(Object.assign({}, self.ItemStructureSetup));
						Swal('Template Added Successfully', 'The Library of Materials Has a New Template', 'success');  
						self.ItemStructureSetup = {	
							ID: "",
							Template_Name: "",
							Field_1_Required: true,
							Field_1_Header: "",
							Field_1_Mandatory: "Yes",
							Field_2_Required: false,
							Field_2_Header: "",
							Field_2_Mandatory: "",
							Field_3_Required: false,
							Field_3_Header: "",
							Field_3_Mandatory: "",
							Field_4_Required: false,
							Field_4_Header: "",
							Field_4_Mandatory: "",
							Field_5_Required: false,
							Field_5_Header: "",
							Field_5_Mandatory: "",
							Field_6_Required: false,
							Field_6_Header: "",
							Field_6_Mandatory: "",
							Field_7_Required: false,
							Field_7_Header: "",
							Field_7_Mandatory: "",
							Field_8_Required: false,
							Field_8_Header: "",
							Field_8_Mandatory: "",
							Field_9_Required: false,
							Field_9_Header: "",
							Field_9_Mandatory: "",
							Field_10_Required: false,
							Field_10_Header: "",
							Field_10_Mandatory: "",
							Field_11_Required: false,
							Field_11_Header: "",
							Field_11_Mandatory: "",
							Field_12_Required: false,
							Field_12_Header: "",
							Field_12_Mandatory: "",
							Field_13_Required: false,
							Field_13_Header: "",
							Field_13_Mandatory: "",
							Field_14_Required: false,
							Field_14_Header: "",
							Field_14_Mandatory: "",
							Field_15_Required: false,
							Field_15_Header: "",
							Field_15_Mandatory: "",
							Field_16_Required: false,
							Field_16_Header: "",
							Field_16_Mandatory: "",
							Field_17_Required: false,
							Field_17_Header: "",
							Field_17_Mandatory: "",
							Field_18_Required: false,
							Field_18_Header: "",
							Field_18_Mandatory: "",
							Field_19_Required: false,
							Field_19_Header: "",
							Field_19_Mandatory: "",
							Field_20_Required: false,
							Field_20_Header: "",
							Field_20_Mandatory: "",
							ConsolidatedTitles: "",
							Action: "",
						};                          
                    } else if (validation == false && duplicated == false) {
                    	Swal('Missing Info', 'Please Fill The Mandatory Fields', 'warning');
                    }
				})
				
            },
            SetRequired: function(fieldNumber, event){
                let SelectedHeader = 'Field_'+fieldNumber+'_Header';
                let MandaHeader = 'Field_'+fieldNumber+'_Mandatory';
                console.log(event);
                if(!event){
                    this.FormRules[SelectedHeader][0].required = true;
                    this.FormRules[MandaHeader][0].required = true;
                } else {
                    this.FormRules[SelectedHeader][0].required = false;
                    this.FormRules[MandaHeader][0].required = false;
                }    
            },
			handleExceed: function(files, fileList){
                Swal('The Limit is ' + fileList.length + ' Logo', 'The limit is ' + fileList.length + ' logo per company, if you would like to upload more than one logo please contact Ogéo support team', 'warning'); 
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
				
            },
			deleteLogoPreview(){
				axios.get('api/delete/logopreview/' + this.logopreview)
				.then((res) => {
					this.$refs.CompanyLogo.clearFiles();
					this.logopreview = ''
				})
			},
			handleEditChange() {
				if(!this.$refs.CompanyLogo.uploadFiles[0]){
					Swal('Information is missing', 'Please enter the needed information to continue the registration process.', 'warning')
					resolve(false);
				}
			
				let formData = new FormData();
				formData.append('CompanyLogo',this.$refs.CompanyLogo.uploadFiles[0].raw);

				axios.post( 'api/save/companyLogo',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
					).then((response) => {
						this.logopreview = response.data.file


					})			
			},
			showProjectDetails: function(key, event){
				if (event) event.preventDefault();



			
				this.ProjectModalInfo = "";
				this.ProjectKey = key;
				this.ProjectModalInfo = Object.assign({}, this.ProjectsDetails[key]);
				console.log(	this.ProjectModalInfo);
				/* Handle if the original selection was non store and to be changed to store */
				if(this.ProjectModalInfo.StoreSetup == "No"){

					this.ProjectModalInfo.ASTMGR_List = this.ASTMGR_List;
					this.ProjectModalInfo.SIIRPT_List = []; 
					this.ProjectModalInfo.RTIRS_List = []; 
					this.ProjectModalInfo.ATSIRS_List = {
				    	level_1: [],
				    	level_2: [],
				    	level_3: [],
				    	level_4: [],
				    	level_5: [],
				    	level_6: [],
				    	level_7: [],
				    	level_8: []
				    },
					this.ProjectModalInfo.RTSI_List = []; 
					this.ProjectModalInfo.ATSI_List = {
				    	level_1: [],
				    	level_2: [],
				    	level_3: [],
				    	level_4: [],
				    	level_5: [],
				    	level_6: [],
				    	level_7: [],
				    	level_8: []
				    };
				}

				this.$forceUpdate();

				this.$refs.AccountSettingForm3.validate((validation) => {
					$('#ProjectDetailsModalId').modal('toggle');

					//adding Purchase Order Validator and Receipt Note Validator values in input fields
					$.each(this.ProjectModalInfo.POV_Financial_Details, function(index, val) {
						$('#POV_Modal_List_level_'+val['POV_Level']+'_limit_'+index).val(parseInt(val['Limit']));
						$('#POV_Modal_List_level_'+val['POV_Level']+'_currency_'+index).val(parseInt(val['Currency']));
					});
					$.each(this.ProjectModalInfo.SUBV_Financial_Details, function(index, val) {
						$('#SUBV_Modal_List_level_'+val['POV_Level']+'_limit_'+index).val(parseInt(val['Limit']));
						$('#SUBV_Modal_List_level_'+val['POV_Level']+'_currency_'+index).val(parseInt(val['Currency']));
					});
					$.each(this.ProjectModalInfo.RNV_Financial_Details, function(index, val) {
						$('#RNV_Modal_List_level_'+val['POV_Level']+'_limit_'+index).val(parseInt(val['Limit']));
						$('#RNV_Modal_List_level_'+val['POV_Level']+'_currency_'+index).val(parseInt(val['Currency']));
					});
					$.each(this.ProjectModalInfo.RCV_Financial_Details, function(index, val) {
						$('#RCV_Modal_List_level_'+val['POV_Level']+'_limit_'+index).val(parseInt(val['Limit']));
						$('#RCV_Modal_List_level_'+val['POV_Level']+'_currency_'+index).val(parseInt(val['Currency']));
					});

				})
			},
			updateProjectDetails: function() {

				console.log(this.ProjectModalInfo);

				//Check the roles completness
				const allRoles = {};
				allRoles['Application Administrator Role'] = this.ProjectModalInfo.CAD_List; 
				allRoles['Purchase Enquiry Initiator Role'] = this.ProjectModalInfo.PRO_List; 
				allRoles['Purchase Enquiry Validator Role'] = this.ProjectModalInfo.PRV_List;

				if(this.AccountSettingForm.companyLoMManditory != 'FreeText') { 
					allRoles['Library of Material Update Request Initiator Role'] = this.ProjectModalInfo.ALMR_List; 
					allRoles['Library of Materials Administrator Role'] = this.ProjectModalInfo.LMA_List; 
					allRoles['Library of Materials Validator Role'] = this.ProjectModalInfo.ALMV_List; 
				}

				allRoles['Request for Proposals Administrator Role'] = this.ProjectModalInfo.RFQM_List; 
				allRoles['Purchase Order Initiator Role'] = this.ProjectModalInfo.POO_List; 
				allRoles['Purchase Order Validator Role'] = this.ProjectModalInfo.POV_List; 
				allRoles['Receipt Note Initiator Role'] = this.ProjectModalInfo.RNO_List; 
				allRoles['Receipt Note Validator Role'] = this.ProjectModalInfo.RNV_List; 
				allRoles['Vendors Qualification Initiator Role'] = this.ProjectModalInfo.SLM_List; 
				allRoles['Vendors Qualification Validator Role'] = this.ProjectModalInfo.SLMV_List; 
				allRoles['Setup Budget Initiator Role'] = this.ProjectModalInfo.SUBO_List; 
				allRoles['Setup Budget Initiator Validator Role'] = this.ProjectModalInfo.SUBV_List; 
				allRoles['Invoice Checker Role'] = this.ProjectModalInfo.INV_List; 

				/* handles changes to store info in modification mode and reseting Roles values if no store is re-selected */
				if(this.ProjectModalInfo.StoreSetup == "Yes"){
					allRoles['Storeroom Manager Role'] = this.ProjectModalInfo.ASTMGR_List; 
					allRoles['Storeroom In-Charge Role'] = this.ProjectModalInfo.SIIRPT_List; 
					allRoles['Storeroom Transaction Request Initiator Role'] = this.ProjectModalInfo.RTIRS_List; 
					allRoles['Storeroom Transaction Request Validator Role'] = this.ProjectModalInfo.ATSIRS_List; 
					allRoles['Storeroom Items Update Request Initiator Role'] = this.ProjectModalInfo.RTSI_List; 
					allRoles['Storeroom Items Update Request Validator Role'] = this.ProjectModalInfo.ATSI_List;
				} else {
					this.ProjectModalInfo.ASTMGR_List = this.ProjectModalInfo.ASTMGR_List;
				    this.ProjectModalInfo.ASTMGR_Number = null;
	                this.ProjectModalInfo.SIIRPT_List = [];
	                this.ProjectModalInfo.RTIRS_List = [];
	                this.ProjectModalInfo.ATSIRS_List = {
				    	level_1: [],
				    	level_2: [],
				    	level_3: [],
				    	level_4: [],
				    	level_5: [],
				    	level_6: [],
				    	level_7: [],
				    	level_8: []
				    };
				    this.ProjectModalInfo.ATSIRS_Number = null;
	                this.ProjectModalInfo.RTSI_List = [];
	                this.ProjectModalInfo.ATSI_List = {
				    	level_1: [],
				    	level_2: [],
				    	level_3: [],
				    	level_4: [],
				    	level_5: [],
				    	level_6: [],
				    	level_7: [],
				    	level_8: []
				    };
				    this.ProjectModalInfo.ATSI_Number = null;
				}

				allRoles['Rates Contract Creation Request Initiator Role'] = this.ProjectModalInfo.RCCO_List; 
				allRoles['Rates Contract Creation Request Validator Role'] = this.ProjectModalInfo.RCCV_List; 
				allRoles['Rates Contract Initiator Role'] = this.ProjectModalInfo.RCO_List; 
				allRoles['Rates Contract Validator Role'] = this.ProjectModalInfo.RCV_List;
				
				let RolesError = false;

				$.each(allRoles, function(RoleName, RoleInfo) {
					// console.log(RoleInfo);
					// console.log(Array.isArray(RoleInfo));
					// console.log(RoleInfo.length == 0);
					// Exclude Role Names With RC
					if(!RoleName.includes("Rates' Contract")){
						if(Array.isArray(RoleInfo)){
							if(RoleInfo.length == 0){
								Swal(""+RoleName+" Has No Employees", "You have not added any employees to "+RoleName+".", 'warning');
								RolesError = true;
								return false;
							}
						} else {
							
							let NoEmployees = true;
							$.each(RoleInfo, function(index, RoleLevel) {
								if(RoleLevel.length > 0){
									NoEmployees = false;
								}
							});

							if(NoEmployees){
								Swal(""+RoleName+" Has No Employees", "You have not added any employees to "+RoleName+".", 'warning');
								RolesError = true;
								return false;
							}
						}
					}
				});

				//do not move wizard next if there are role errors
				if(RolesError){
					return false;
				}

				let POV_Number = this.ProjectModalInfo.POV_Number;
				let SUBV_Number = this.ProjectModalInfo.SUBV_Number;
				let RNV_Number = this.ProjectModalInfo.RNV_Number;
				let RCV_Number = this.ProjectModalInfo.RCV_Number;

				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; POV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#POV_Modal_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Purchase Order Validator of Level ("+x+")", 'warning');
								return false;
							}
						}
					}
				}
				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; SUBV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#SUBV_Modal_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Setup Budget Validator of Level ("+x+")", 'warning');
								return false;
							}
						}
					}
				}
				
				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; RNV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#RNV_Modal_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Receipt Note Validator Level ("+x+")", 'warning');
								return false;
							}
						}
					}
				}
				//check completeness of the Rates' Contract Validator value entries
				for (var x = 1; RCV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#RCV_Modal_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Rates' Contract Validator Level ("+x+")", 'warning');
								return false;
							}
						}
					}
				}

				/*Storing Purchase Order Validator and Receipt Note Validator and Rates' Contract Validator Values Correctly*/
				this.ProjectModalInfo.POV_Financial_Details = [];
				this.ProjectModalInfo.SUBV_Financial_Details = [];
				this.ProjectModalInfo.RNV_Financial_Details = [];
				this.ProjectModalInfo.RCV_Financial_Details = [];

				//Update Purchase Order Validator financial limits
				for (var x = 1; POV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							this.ProjectModalInfo.POV_Financial_Details.push({
								POV_Level: x, 
								Currency: $('#POV_Modal_List_level_'+x+'_currency_'+i).val(),
								Limit: $('#POV_Modal_List_level_'+x+'_limit_'+i).val(),
							});
						}
					}
				}

				//Update Setup Budget Validator financial limits
				for (var x = 1; SUBV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							this.ProjectModalInfo.SUBV_Financial_Details.push({
								SUBV_Level: x, 
								Currency: $('#SUBV_Modal_List_level_'+x+'_currency_'+i).val(),
								Limit: $('#SUBV_Modal_List_level_'+x+'_limit_'+i).val(),
							});
						}
					}
				}
				//Update Receipt Note Validator financial limits
				for (var x = 1; RNV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							this.ProjectModalInfo.RNV_Financial_Details.push({
								RNV_Level: x, 
								Currency: $('#RNV_Modal_List_level_'+x+'_currency_'+i).val(),
								Limit: $('#RNV_Modal_List_level_'+x+'_limit_'+i).val(),
							});
						}
					}
				}

				//Update Rates' Contract Validator financial limits
				for (var x = 1; RCV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectModalInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							this.ProjectModalInfo.RCV_Financial_Details.push({
								RCV_Level: x, 
								Currency: $('#RCV_Modal_List_level_'+x+'_currency_'+i).val(),
								Limit: $('#RCV_Modal_List_level_'+x+'_limit_'+i).val(),
							});
						}
					}
				}


				this.ProjectsDetails[this.ProjectKey] = Object.assign({}, this.ProjectModalInfo);
				this.$forceUpdate();

				if(this.ProjectModalInfo.StoreSetup == "Yes"){
					/* Update this.ASTMGR_List from current this.ProjectModalInfo.ASTMGR_List and all projects  elf.$data.ProjectsDetails[index].ASTMGR_List */
					this.ASTMGR_List = this.ProjectModalInfo.ASTMGR_List;
					let self = this;
					$.each(this.$data.ProjectsDetails, function(index, project) {
			  			self.$data.ProjectsDetails[index].ASTMGR_List = self.ProjectModalInfo.ASTMGR_List;
			  			self.$data.ProjectsDetails[index].ASTMGR_Number = self.ProjectModalInfo.ASTMGR_Number;
			  		});
				}

				/*Close Update Modal */
				$('#ProjectDetailsModalId').modal('toggle');
			},
			scrollTo: function(to, duration) {

			    const
			    element = document.scrollingElement || document.documentElement,
			    start = element.scrollTop,
			    change = to - start,
			    startDate = +new Date(),
			    // t = current time
			    // b = start value
			    // c = change in value
			    // d = duration
			    easeInOutQuad = function(t, b, c, d) {
			        t /= d/2;
			        if (t < 1) return c/2*t*t + b;
			        t--;
			        return -c/2 * (t*(t-2) - 1) + b;
			    },
			    animateScroll = function() {
			        const currentDate = +new Date();
			        const currentTime = currentDate - startDate;
			        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			        if(currentTime < duration) {
			            requestAnimationFrame(animateScroll);
			        }
			        else {
			            element.scrollTop = to;
			        }
			    };
			    animateScroll();
			},
			CreateNewProject: function (event) {
				if (event) event.preventDefault();

				//Check for duplicated Names when adding new project (Must be done here)


				//Check the roles completness
				const allRoles = {};
				allRoles['Application Administrator Role'] = this.CAD_List; 
				allRoles['Purchase Enquiry Initiator Role'] = this.PRO_List; 
				allRoles['Purchase Enquiry Validator Role'] = this.PRV_List; 
				 
				if(this.AccountSettingForm.companyLoMManditory != 'FreeText') {
					allRoles['Library of Material Update Request Initiator Role'] = this.ALMR_List;
					allRoles['Library of Materials Administrator Role'] = this.LMA_List; 
					allRoles['Library of Materials Validator Role'] = this.ALMV_List; 
				}
				
				allRoles['Request for Proposals Administrator Role'] = this.RFQM_List; 
				allRoles['Purchase Order Initiator Role'] = this.POO_List; 
				allRoles['Purchase Order Validator Role'] = this.POV_List; 
				allRoles['Receipt Note Initiator Role'] = this.RNO_List; 
				allRoles['Receipt Note Validator Role'] = this.RNV_List; 
				allRoles['Vendors Qualification Initiator Role'] = this.SLM_List; 
				allRoles['Vendors Qualification Validator Role'] = this.SLMV_List; 
				allRoles['Setup Budget Initiator Role'] = this.SUBO_List; 
				allRoles['Setup Budget Validator Role'] = this.SUBV_List; 
				allRoles['Invoice Checker Role'] = this.INV_List; 

				allRoles['Rates Contract Creation Request Initiator Role'] = this.RCCO_List; 
				allRoles['Rates Contract Creation Request Validator Role'] = this.RCCV_List; 
				allRoles['Rates Contract Initiator Role'] = this.RCO_List; 
				allRoles['Rates Contract Validator Role'] = this.RCV_List;

				if(this.ProjectInfo.StoreSetup == "Yes"){
					allRoles['Storeroom Manager Role'] = this.ASTMGR_List; 
					allRoles['Storeroom In-Charge Role'] = this.SIIRPT_List; 
					allRoles['Storeroom Transaction Request Initiator Role'] = this.RTIRS_List; 
					allRoles['Storeroom Transaction Request Validator Role'] = this.ATSIRS_List; 
					allRoles['Storeroom Items Update Request Initiator Role'] = this.RTSI_List; 
					allRoles['Storeroom Items Update Request Validator Role'] = this.ATSI_List; 
				}

				let RolesError = false;

				$.each(allRoles, function(RoleName, RoleInfo) {
					// Exclude Role Names With RC
					if(!RoleName.includes("Rates' Contract")){

						if(Array.isArray(RoleInfo)){
							if(RoleInfo.length == 0){
								Swal(""+RoleName+" Has No Employees", "You have not added any employees to "+RoleName+".", 'warning');
								RolesError = true;
								return false;
							}
						} else {
							
							let NoEmployees = true;
							$.each(RoleInfo, function(index, RoleLevel) {
								if(RoleLevel.length > 0){
									NoEmployees = false;
								}
							});

							if(NoEmployees){
								Swal(""+RoleName+" Has No Employees", "You have not added any employees to "+RoleName+".", 'warning');
								RolesError = true;
								return false;
							}
						}
					}
				});

				//do not move wizard next if there are role errors
				if(RolesError){
					return false;
				}

				let POV_Number = this.POV_Number;
				let SUBV_Number = this.SUBV_Number;
				let RNV_Number = this.RNV_Number;
				let RCV_Number = this.RCV_Number;


				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; POV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#POV_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Purchase Order Validator of Level ("+x+")", 'warning');
								return false;
							}
						}							
					}
				}

				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; SUBV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#SUBV_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Setup Budget Validator of Level ("+x+")", 'warning');
								return false;
							}
						}							
					}
				}

				//check completeness of the Purchase Order Validator and Receipt Note Validator value entries
				for (var x = 1; RNV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#RNV_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Receipt Note Validator of Level ("+x+")", 'warning');
								return false;
							}
						}
						
					}
				}


				//check completeness of the Rates' Contract Validator value entries
				for (var x = 1; RCV_Number > x; x++) {
					for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
						if(i == 0){
							if($('#RCV_List_level_'+x+'_limit_'+i).val() == ""){
								Swal("Missing A Financial Limit Information", "Please Check the Financial Limit for the Rates' Contract Validator of Level ("+x+")", 'warning');
								return false;
							}
						}
						
					}
				}


				//Check fields completness
				this.$refs.AccountSettingForm3.validate((validation) => {

					//only stop/return when validation is false when 
					if(validation){ 

						let PRV_Number = this.PRV_Number;
						let ALMV_Number = this.ALMV_Number;
						let POV_Number = this.POV_Number;
						let SUBV_Number = this.SUBV_Number;
						let SLMV_Number = this.SLMV_Number;
						let RNV_Number = this.RNV_Number;
						let RCCV_Number = this.RCCV_Number;
						let RCV_Number = this.RCV_Number;

						this.POV_Financial_Details= [];
						this.SUBV_Financial_Details= [];
						this.RNV_Financial_Details= [];
						this.RCV_Financial_Details= [];

						//getting financial limits for POV
						for (var x = 1; POV_Number > x; x++) {
							for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
								if(i == 0){
									this.POV_Financial_Details.push({
										POV_Level: x, 
										Currency: $('#POV_List_level_'+x+'_currency_'+i).val(),
										Limit: $('#POV_List_level_'+x+'_limit_'+i).val(),
									});
								}
							}
						}

						for (var x = 1; SUBV_Number > x; x++) {
							for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
								if(i == 0){
									this.SUBV_Financial_Details.push({
										SUBV_Level: x, 
										Currency: $('#SUBV_List_level_'+x+'_currency_'+i).val(),
										Limit: $('#SUBV_List_level_'+x+'_limit_'+i).val(),
									});
								}
							}
						}

						//getting financial limits for RNV
						for (var x = 1; RNV_Number > x; x++) {
							for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
								if(i == 0){
									this.RNV_Financial_Details.push({
										RNV_Level: x, 
										Currency: $('#RNV_List_level_'+x+'_currency_'+i).val(),
										Limit: $('#RNV_List_level_'+x+'_limit_'+i).val(),
									});
								}
							}
						}

						//getting financial limits for RCV
						for (var x = 1; RCV_Number > x; x++) {
							for (var i = 0; this.$data.ProjectInfo.ProjectCompanyCurrency.split(',').length > i; i++) {
								if(i == 0){
									this.RCV_Financial_Details.push({
										RCV_Level: x, 
										Currency: $('#RCV_List_level_'+x+'_currency_'+i).val(),
										Limit: $('#RCV_List_level_'+x+'_limit_'+i).val(),
									});
								}
							}
						}

						console.log(this.POV_Financial_Details);
						console.log(this.SUBV_Financial_Details);
						console.log(this.RNV_Financial_Details);
						console.log(this.RCV_Financial_Details);
						
						//Copy and Store all assigned Roles into new object
						if(this.ProjectInfo.StoreSetup == "Yes"){
							
							let ASTMGR_Number = this.ASTMGR_Number;
							let SIIRPT_Number = this.SIIRPT_Number;
							let RTIRS_Number = this.RTIRS_Number;
							let ATSIRS_Number = this.ATSIRS_Number;
							let RTSI_Number = this.RTSI_Number;
							let ATSI_Number = this.ATSI_Number;
							
							let AllTempRoles = {
								CAD_List: JSON.parse(JSON.stringify(this.CAD_List)),
								PRO_List: JSON.parse(JSON.stringify(this.PRO_List)),
								PRV_List: JSON.parse(JSON.stringify(this.PRV_List)),
								ALMR_List: JSON.parse(JSON.stringify(this.ALMR_List)),
								LMA_List: JSON.parse(JSON.stringify(this.LMA_List)),
								ALMV_List: JSON.parse(JSON.stringify(this.ALMV_List)),
								RFQM_List: JSON.parse(JSON.stringify(this.RFQM_List)),
								POO_List: JSON.parse(JSON.stringify(this.POO_List)),
								POV_List: JSON.parse(JSON.stringify(this.POV_List)),
								RNO_List: JSON.parse(JSON.stringify(this.RNO_List)),
								RNV_List: JSON.parse(JSON.stringify(this.RNV_List)),
								SLM_List: JSON.parse(JSON.stringify(this.SLM_List)),
								SLMV_List: JSON.parse(JSON.stringify(this.SLMV_List)),
								SUBO_List: JSON.parse(JSON.stringify(this.SUBO_List)),
								SUBV_List: JSON.parse(JSON.stringify(this.SUBV_List)),
								INV_List: JSON.parse(JSON.stringify(this.INV_List)),

								RCCO_List: JSON.parse(JSON.stringify(this.RCCO_List)),
								RCCV_List: JSON.parse(JSON.stringify(this.RCCV_List)),
								RCO_List: JSON.parse(JSON.stringify(this.RCO_List)),
								RCV_List: JSON.parse(JSON.stringify(this.RCV_List)),
								POV_Financial_Details: JSON.parse(JSON.stringify(this.POV_Financial_Details)),
								SUBV_Financial_Details: JSON.parse(JSON.stringify(this.SUBV_Financial_Details)),
								RNV_Financial_Details: JSON.parse(JSON.stringify(this.RNV_Financial_Details)),
								

								ASTMGR_List: JSON.parse(JSON.stringify(this.ASTMGR_List)),
								SIIRPT_List: JSON.parse(JSON.stringify(this.SIIRPT_List)),
								RTIRS_List: JSON.parse(JSON.stringify(this.RTIRS_List)),
								ATSIRS_List: JSON.parse(JSON.stringify(this.ATSIRS_List)),
								RTSI_List: JSON.parse(JSON.stringify(this.RTSI_List)),
								ATSI_List: JSON.parse(JSON.stringify(this.ATSI_List)),

								ASTMGR_Number: ASTMGR_Number,
								ATSIRS_Number: ATSIRS_Number,
								ATSI_Number: ATSI_Number,
								
								PRV_Number: PRV_Number,
							    ALMV_Number: ALMV_Number,
							    POV_Number: POV_Number,
							    SUBV_Number: SUBV_Number,
							    SLMV_Number: SLMV_Number,
							    RNV_Number: RNV_Number,
							    RCCV_Number: RCCV_Number,
							    RCV_Number: RCV_Number,
								RCV_Financial_Details: JSON.parse(JSON.stringify(this.RCV_Financial_Details)),
							}

							//handling breakline in terms and condiditions
							this.ProjectInfo.ProjectTsNCs = this.ProjectInfo.ProjectTsNCs.replace(/\r\n|\r|\n/g,'<br>');

							this.ProjectsDetails.push(Object.assign({}, this.ProjectInfo, AllTempRoles));

							let self = this;

							$.each(this.$data.ProjectsDetails, function(index, project) {
					  			self.$data.ProjectsDetails[index].ASTMGR_List = self.ASTMGR_List;
					  			self.$data.ProjectsDetails[index].ASTMGR_Number = ASTMGR_Number;
					  		});
							

							//Reseting Project Info
							this.ProjectInfo.ProjectTitle = "";
							this.ProjectInfo.StoreSetup = "";
							this.ProjectInfo.ProjectPeriod = "";
							this.ProjectInfo.ProjectValue = "";
							this.ProjectInfo.ProjectPODOACriterion = "";
							this.ProjectInfo.ProjectRNDOACriterion = "";
							this.ProjectInfo.ProjectAuctionType = [];
							this.ProjectInfo.ProjectCompanyCurrency = "";
							this.ProjectInfo.ProjectTsNCs = "";

						} else {
							
							let AllTempRoles = {
								CAD_List: JSON.parse(JSON.stringify(this.CAD_List)),
								PRO_List: JSON.parse(JSON.stringify(this.PRO_List)),
								PRV_List: JSON.parse(JSON.stringify(this.PRV_List)),
								ALMR_List: JSON.parse(JSON.stringify(this.ALMR_List)),
								LMA_List: JSON.parse(JSON.stringify(this.LMA_List)),
								ALMV_List: JSON.parse(JSON.stringify(this.ALMV_List)),
								RFQM_List: JSON.parse(JSON.stringify(this.RFQM_List)),
								POO_List: JSON.parse(JSON.stringify(this.POO_List)),
								POV_List: JSON.parse(JSON.stringify(this.POV_List)),
								RNO_List: JSON.parse(JSON.stringify(this.RNO_List)),
								RNV_List: JSON.parse(JSON.stringify(this.RNV_List)),
								SLM_List: JSON.parse(JSON.stringify(this.SLM_List)),
								SLMV_List: JSON.parse(JSON.stringify(this.SLMV_List)),
								SUBO_List: JSON.parse(JSON.stringify(this.SUBO_List)),
								SUBV_List: JSON.parse(JSON.stringify(this.SUBV_List)),
								INV_List: JSON.parse(JSON.stringify(this.INV_List)),

								RCCO_List: JSON.parse(JSON.stringify(this.RCCO_List)),
								RCCV_List: JSON.parse(JSON.stringify(this.RCCV_List)),
								RCO_List: JSON.parse(JSON.stringify(this.RCO_List)),
								RCV_List: JSON.parse(JSON.stringify(this.RCV_List)),
								POV_Financial_Details: JSON.parse(JSON.stringify(this.POV_Financial_Details)),
								SUBV_Financial_Details: JSON.parse(JSON.stringify(this.SUBV_Financial_Details)),
								RNV_Financial_Details: JSON.parse(JSON.stringify(this.RNV_Financial_Details)),
								
								PRV_Number: PRV_Number,
							    ALMV_Number: ALMV_Number,
							    POV_Number: POV_Number,
							    SUBV_Number: SUBV_Number,
							    SLMV_Number: SLMV_Number,
							    RNV_Number: RNV_Number,
							    RCCV_Number: RCCV_Number,
							    RCV_Number: RCV_Number,
								RCV_Financial_Details: JSON.parse(JSON.stringify(this.RCV_Financial_Details)),

								ASTMGR_Number: this.ASTMGR_Number,
								ATSIRS_Number: this.ATSIRS_Number,
								ATSI_Number: this.ATSI_Number,
							}
							
							//handling breakline in terms and condiditions
							this.ProjectInfo.ProjectTsNCs = this.ProjectInfo.ProjectTsNCs.replace(/\r\n|\r|\n/g,'<br>');

							this.ProjectsDetails.push(Object.assign({}, this.ProjectInfo, AllTempRoles));

							//Reseting Project Info
							this.ProjectInfo.ProjectTitle = "";
							this.ProjectInfo.ProjectPeriod = "";
							this.ProjectInfo.ProjectValue = "";
							this.ProjectInfo.ProjectPODOACriterion = "";
							this.ProjectInfo.ProjectRNDOACriterion = "";
							this.ProjectInfo.ProjectAuctionType = [];
							this.ProjectInfo.ProjectCompanyCurrency = "";
							this.ProjectInfo.ProjectTsNCs = "";
						}

						
						
						

						//Reseting Role Info
						Swal({
		                  title: 'Would You Like to Preserve The Entered information?',
		                  text: "This Will Help Save Time for The Creation of Your Next <i>Account</i>",
		                  type: 'warning',
		                  showCancelButton: true,
		                  confirmButtonColor: '#3085d6',
		                  cancelButtonColor: '#d33',
		                  confirmButtonText: 'Yes, Preserve!',
		                  cancelButtonText: 'No, Reset!'
		                }).then((result) => {
		                    if (!result.value) {

								this.CAD_List= [];
								this.PRO_List= [];
							    this.PRV_List= {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.PRV_Number = null;
							    this.ALMR_List = [];
							    this.LMA_List = [];
							    this.ALMV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.ALMV_Number = null;
							    this.RFQM_List = [];
							    this.POO_List = [];
							    this.POV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    },
								this.POV_Number = null;
								this.SUBV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    },
							    this.SUBV_Number = null;
							    this.RNO_List = [];
							    this.RNV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    },
							    this.RNV_Number = null;
							    this.SLM_List = [];
							    this.SLMV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    },
							    this.SLMV_Number = null;
							    this.SUBO_List = [];
							    this.INV_List = [];
							    /* This role is acorss stores so its not reset at any point */
							    // this.ASTMGR_List = {
							    // 	level_1: [],
							    // 	level_2: [],
							    // 	level_3: [],
							    // 	level_4: [],
							    // 	level_5: [],
							    // 	level_6: [],
							    // 	level_7: [],
							    // 	level_8: []
							    // };
							    // this.ASTMGR_Number = null;
				                this.SIIRPT_List = [];
				                this.RTIRS_List = [];
				                this.ATSIRS_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.ATSIRS_Number = null;
				                this.RTSI_List = [];
				                this.ATSI_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.ATSI_Number = null;

							    this.RCCO_List = [];
							    this.RCCV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.RCCV_Number = null;
							    this.RCO_List = [];
							    this.RCV_List = {
							    	level_1: [],
							    	level_2: [],
							    	level_3: [],
							    	level_4: [],
							    	level_5: [],
							    	level_6: [],
							    	level_7: [],
							    	level_8: []
							    };
							    this.RCV_Number = null;
							    this.POV_Financial_Details = [];
							    this.SUBV_Financial_Details = [];
							    this.RNV_Financial_Details = [];
							    this.RCV_Financial_Details = [];

		                    }
		                });

						
						this.scrollTo(400, 1000);

						// Swal({ type: 'success', title: 'Project Saved', text: "Project Details Saved, You Can Enter New Project Details", showConfirmButton: false, timer: 1500
						// });
		
					// } else {
					// 	this.scrollTo(400, 1000);
					// 	return validation;
					}
					
				});	
			},
			RemoveProject: function(key, event) {
				if (event) event.preventDefault();
				this.ProjectsDetails.splice(key, 1);
			},
			formatPrice(value) {
		        let val = (value/1).toFixed(2).replace(',', '.')
		        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		    },
			submitUpload: function() {
		    	this.$refs.upload.submit();
		    },
		    onAddCAD: function(evt) {

		    	console.log(this.CAD_List);
		    	const NewlyAdded = this.CAD_List[evt.newIndex];
				const exsits = this.CAD_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.CAD_List, evt.newIndex); 
                	Swal('Employee Exists', 'Employee already exists in Application Administrator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                })
                	}

            },
            onAddPRO: function(evt) {
		    	console.log(this.PRO_List);
		    	const NewlyAdded = this.PRO_List[evt.newIndex];
				const exsits = this.PRO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.PRO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Purchase Enquiry Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) } 
            },
		    onAddPRV: function(evt) {
		    	console.log(this.PRV_List);
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);
		    	
		    	const NewlyAdded = this.PRV_List[ListName][evt.newIndex];
				const exsits = this.PRV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.PRV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Purchase Enquiry Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddALMR: function(evt) {
		    	const NewlyAdded = this.ALMR_List[evt.newIndex];
				const exsits = this.ALMR_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.ALMR_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Library of Material Update Request Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddLMA: function(evt) {
		    	const NewlyAdded = this.LMA_List[evt.newIndex];
				const exsits = this.LMA_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.LMA_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Library of Materials Administrator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddALMV: function(evt) {

		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.ALMV_List[ListName][evt.newIndex];
				const exsits = this.ALMV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ALMV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Library of Materials Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddRFQM: function(evt) {
		    	const NewlyAdded = this.RFQM_List[evt.newIndex];
				const exsits = this.RFQM_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RFQM_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Request for Proposals Administrator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddPOO: function(evt) {
		    	console.log(this.POO_List);
		    	const NewlyAdded = this.POO_List[evt.newIndex];
				const exsits = this.POO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.POO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Purchase Order Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddPOV: function(evt) {

		    	// $.toast({
				// 	heading: 'Welcome to Monster admin',
				// 	text: 'Use the predefined ones, or specify a custom position object.',
				// 	position: 'top-right',
				// 	loaderBg:'#ff6849',
				// 	icon: 'warning',
				// 	hideAfter: 3500, 
				// 	stack: 6
				// });

		
				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				console.log(ListName);
				console.log(this.POV_List[ListName]);
		    	
		    	const NewlyAdded = this.POV_List[ListName][evt.newIndex];
				const exsits = this.POV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.POV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Purchase Order Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }	    	
			},
			onAddSUBV: function(evt) {

				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				console.log(ListName);
				console.log(this.SUBV_List[ListName]);
		    	
		    	const NewlyAdded = this.SUBV_List[ListName][evt.newIndex];
				const exsits = this.SUBV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.SUBV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Setup Budget Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddRNO: function(evt) {
		    	const NewlyAdded = this.RNO_List[evt.newIndex];
				const exsits = this.RNO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RNO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Receipt Note Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRNV: function(evt) {
		    	
		    	// $.toast({
				// 	heading: 'Welcome to Monster admin',
				// 	text: 'Use the predefined ones, or specify a custom position object.',
				// 	position: 'top-right',
				// 	loaderBg:'#ff6849',
				// 	icon: 'warning',
				// 	hideAfter: 3500, 
				// 	stack: 6
				// });

				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				console.log(ListName);
				console.log(this.RNV_List[ListName]);
		    	
		    	const NewlyAdded = this.RNV_List[ListName][evt.newIndex];
				const exsits = this.RNV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.RNV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Receipt Note Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddSLM: function(evt) {
		    	const NewlyAdded = this.SLM_List[evt.newIndex];
				const exsits = this.SLM_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.SLM_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Vendors Qualification Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
			},
            onAddSLMV: function(evt) {
                                // $.toast({
                //  heading: 'Welcome to Monster admin',
                //  text: 'Use the predefined ones, or specify a custom position object.',
                //  position: 'top-right',
                //  loaderBg:'#ff6849',
                //  icon: 'warning',
                //  hideAfter: 3500, 
                //  stack: 6
                // });

                var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
                ListName = ListName.slice(4);

                console.log(ListName);
                console.log(this.SLMV_List[ListName]);
                
                const NewlyAdded = this.SLMV_List[ListName][evt.newIndex];
                const exsits = this.SLMV_List[ListName].filter(function(elem) {
                    if(elem.email == NewlyAdded.email) {
                        return elem;
                    }
                });
                
                if(exsits.length > 1){
                    this.$delete(this.SLMV_List[ListName], evt.newIndex);
                    Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Vendors Qualification Validator List", timer: 2500
                    });
                } else {
                    this.$bvToast.toast('Employee has been assigned the role successfully', {
                      title: 'Employee Assigned Successfully',
                      autoHideDelay: 3000,
                      variant: 'success',
                      solid: true,
                    })
                }
            },
			onAddSUBO: function(evt) {
		    	const NewlyAdded = this.SUBO_List[evt.newIndex];
				const exsits = this.SUBO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.SUBO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Setup Budget Request List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddINV: function(evt) {
		    	const NewlyAdded = this.INV_List[evt.newIndex];
				const exsits = this.INV_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.INV_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Invoice Checker List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },



			onAddASTMGR: function(evt) {


				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(7);

		    	const NewlyAdded = this.ASTMGR_List[ListName][evt.newIndex];
				const exsits = this.ASTMGR_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ASTMGR_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Manager List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }

				
		    },
		    onAddSIIRPT: function(evt) {
		    	const NewlyAdded = this.SIIRPT_List[evt.newIndex];
				const exsits = this.SIIRPT_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.SIIRPT_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom In-Charge List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRTIRS: function(evt) {
		    	const NewlyAdded = this.RTIRS_List[evt.newIndex];
				const exsits = this.RTIRS_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RTIRS_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom Transaction Request Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddATSIRS: function(evt) {
		    	console.log(this.ATSIRS_List);
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(7);
		    	
		    	const NewlyAdded = this.ATSIRS_List[ListName][evt.newIndex];
				const exsits = this.ATSIRS_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ATSIRS_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Transaction Request Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddRTSI: function(evt) {
		    	const NewlyAdded = this.RTSI_List[evt.newIndex];
				const exsits = this.RTSI_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RTSI_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom Items Update Request Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddATSI: function(evt) {
		    	console.log(this.ATSI_List);
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.ATSI_List[ListName][evt.newIndex];
				const exsits = this.ATSI_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ATSI_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Items Update Request Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    


		    onAddRCCO: function(evt) {
		    	const NewlyAdded = this.RCCO_List[evt.newIndex];
				const exsits = this.RCCO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RCCO_List, evt.newIndex); Swal('Employee Exists', "Employee already exists in Rates' Contract Creation Request Initiator List", 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRCCV: function(evt) {
		    	
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.RCCV_List[ListName][evt.newIndex];
				const exsits = this.RCCV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.RCCV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Rates' Contract Creation Request Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddRCO: function(evt) {
		    	const NewlyAdded = this.RCO_List[evt.newIndex];
				const exsits = this.RCO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.RCO_List, evt.newIndex); Swal('Employee Exists', "Employee already exists in Rates' Contract Initiator List", 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRCV: function(evt) {
		    	
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);
		    	
		    	const NewlyAdded = this.RCV_List[ListName][evt.newIndex];
				const exsits = this.RCV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.RCV_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Rates' Contract Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    deleteEmployee(DataList, index, ListName = "Non-ASTMGR", DeletLevel = 0, DeletedEmail){
		    		
		    	Swal({
				  title: 'Are you sure?',
				  text: "You can still added employee again!",
				  type: 'warning',
				  showCancelButton: true,
				  confirmButtonColor: '#3085d6',
				  cancelButtonColor: '#d33',
				  confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
				  if (result.value) {

				  	/* Check if the employee exists in any roles*/
				  	if(ListName == 'EmployeesList'){

				  		let RolesError = false;

				  		/* Check employee existance in existing projects */
						$.each(this.$data.ProjectsDetails, function(key, Project) {
							$.each(Project, function(index, ProjectDetails) {
								var RoleName= index.substr(0, index.indexOf('_'));
								console.log(RoleName);
								if(index.substr(index.length - 5) == '_List'){
									console.log(Project);
									if(Array.isArray(ProjectDetails)){
										if(ProjectDetails.length > 0){
											$.each(ProjectDetails, function(index, Employee) {
												
												if(Employee.email == DeletedEmail){
													Swal(""+ RoleName +" Has This Employee in "+ Project.ProjectTitle +"", "Employee Must Be Removed From This Role First", 'warning');
													RolesError = true;
													return false;
												}
											})
											
										}
									} else {
										
										$.each(ProjectDetails, function(index, RoleLevel) {
											var RoleName= index.substr(0, index.indexOf('_'));
											if(RoleLevel.length > 0){
												$.each(RoleLevel, function(index, Employee) {
													if(Employee.email == DeletedEmail){
														Swal(""+ RoleName +" Has This Employee in "+ Project.ProjectTitle +"", "Employee Must Be Removed From This Role First", 'warning');
														RolesError = true;
														return false;
													}
												});										
											}
										});
									}

								}
							});
						});


				  		//Check the roles completness
						const allRoles = {};
						allRoles['Application Administrator Role'] = this.CAD_List; 
						allRoles['Purchase Enquiry Initiator Role'] = this.PRO_List; 
						allRoles['Purchase Enquiry Validator Role'] = this.PRV_List; 
						 
						if(this.AccountSettingForm.companyLoMManditory != 'FreeText') {
							allRoles['Library of Material Update Request Initiator Role'] = this.ALMR_List;
							allRoles['Library of Materials Administrator Role'] = this.LMA_List; 
							allRoles['Library of Materials Validator Role'] = this.ALMV_List; 
						}
						
						allRoles['Request for Proposals Administrator Role'] = this.RFQM_List; 
						allRoles['Purchase Order Initiator Role'] = this.POO_List; 
						allRoles['Purchase Order Validator Role'] = this.POV_List; 
						allRoles['Receipt Note Initiator Role'] = this.RNO_List; 
						allRoles['Receipt Note Validator Role'] = this.RNV_List; 
						allRoles['Vendors Qualification Initiator Role'] = this.SLM_List; 
						allRoles['Vendors Qualification Validator Role'] = this.SLMV_List; 
						allRoles['Setup Budget Initiator Role'] = this.SUBO_List; 
						allRoles['Setup Budget Validator Role'] = this.SUBV_List; 
						allRoles['Invoice Checker Role'] = this.INV_List; 

						allRoles['Rates Contract Creation Request Initiator Role'] = this.RCCO_List; 
						allRoles['Rates Contract Creation Request Validator Role'] = this.RCCV_List; 
						allRoles['Rates Contract Initiator Role'] = this.RCO_List; 
						allRoles['Rates Contract Validator Role'] = this.RCV_List;

						if(this.ProjectInfo.StoreSetup == "Yes"){
							allRoles['Storeroom Manager Role'] = this.ASTMGR_List; 
							allRoles['Storeroom In-Charge Role'] = this.SIIRPT_List; 
							allRoles['Storeroom Transaction Request Initiator Role'] = this.RTIRS_List; 
							allRoles['Storeroom Transaction Request Validator Role'] = this.ATSIRS_List; 
							allRoles['Storeroom Items Update Request Initiator Role'] = this.RTSI_List; 
							allRoles['Storeroom Items Update Request Validator Role'] = this.ATSI_List; 
						}

						

						$.each(allRoles, function(RoleName, RoleInfo) {

							//console.log(RoleName, RoleInfo, Array.isArray(RoleInfo), RoleInfo.length > 0);
							if(Array.isArray(RoleInfo)){
								if(RoleInfo.length > 0){
									$.each(RoleInfo, function(index, Employee) {
										if(Employee.email == DeletedEmail){
											Swal(""+RoleName+" Has This Employee", "Employee Must Be Removed From This Role First", 'warning');
											RolesError = true;
											return false;
										}
									})
									
								}
							} else {
								
								$.each(RoleInfo, function(index, RoleLevel) {
									if(RoleLevel.length > 0){
										$.each(RoleLevel, function(index, Employee) {
											if(Employee.email == DeletedEmail){
												Swal(""+RoleName+" Has This Employee", "Employee Must Be Removed From This Role First", 'warning');
												RolesError = true;
												return false;
											}
										});										
									}
								});
							}
							
						});



						

						//do not move wizard next if there are role errors
						if(!RolesError){
							this.$delete(DataList, index);
				  			Swal(
						      'Deleted!',
						      'This employee is no longer holding the role',
						      'success'
						    )
						}

			    		
			    	} else {

			    		/* check if data list is Storeroom Manager in and check if the existing projects have this role and prevent deletion */
					  	if(ListName == 'ASTMGR'){
					  		let level_counter = 1;
					  		let employeeMatch = false;
					  		let projectName = "";

					  		$.each(this.$data.ProjectsDetails, function(index0, project) {
					  			$.each(project.ASTMGR_List, function(index1, level) {
					  				if(DeletLevel == level_counter){
					  					if(project.ASTMGR_List['level_' + level_counter].length > 0){
					  						$.each(project.ASTMGR_List['level_' + level_counter], function(index, employee) {
					  							if(employee.email == DeletedEmail){
					  								employeeMatch = true;
					  								projectName = project.ProjectTitle;
					  							}
					  						});
					  					}
					  				}
					  				level_counter++;
					  			});
					  			level_counter = 1;
					  		});

					  		if(employeeMatch){
					  			Swal(
							      'Cannot Be Deleted!',
							      'Employee Cannot be deleted from the list. Please remove it from store: (' + projectName + ') first.',
							      'error'
							    )
					  		} else {
					  			this.$delete(DataList, index);
					  			Swal(
							      'Deleted!',
							      'This employee is no longer holding the role',
							      'success'
							    )
					  		}
					  		

					  	} else {
					  		this.$delete(DataList, index);
					  		Swal(
						      'Deleted!',
						      'This employee is no longer holding the role',
						      'success'
						    )
					  	}

			    	}


				  	
				    
				  }
				})
		    },
			creatNewEmployee() {

				const constraints = this.EmployeeCreateRules;
                const NewEmployeeErrors = validateJs(this.$data.NewEmployee, constraints);
                if(NewEmployeeErrors) {
                    this.NewEmployeeErrors = NewEmployeeErrors;
                    return;
                }

                const NewlyAdded = this.NewEmployee;
                const exsits = this.EmployeesList.filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return true;
				    }
				});

                if(exsits.length > 0){
                	Swal('Duplicate Employee.', 'Employee already exists in Employees List.', 'warning')
                } else {

                	this.EmployeesList.push({
				      	fullName: this.NewEmployee.fullName,
						email: this.NewEmployee.email,
						designation: this.NewEmployee.designation,
				    });

                	this.NewEmployee.fullName = "";
                	this.NewEmployee.email = "";
                	this.NewEmployee.designation = "";
			    	this.NewEmployeeErrors = null;
			    	Swal('Employee has been created successfully', '', 'success');
                }			
			},
			StoreSetup($event) {
				//show corresponding roles for stores
				//Restore 
				console.log(this.ProjectInfo.StoreSetup)
			},
			StoreSetupModal($event) {
				//show corresponding roles for stores
				console.log(this.ProjectModalInfo.StoreSetup)
			},
			getIndustries() {

	            axios.get('/data/industries.json').
	            	then((response) => {
			       		this.Industries = response.data.industries;
			      	})
	            	.catch(error => { 
	            		console.log(error); 
	            	});
			},

         getFirstStep() {
			 
	           axios.post( '/api/users/get/firstStep',
                       
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {
								console.log(response.data);
							this.FirstStepData=response.data.company;
							// var obj = {
							// 	name : response.data.company.CompanyLogo,
							// 	url : 'uploads/Logos/'+response.data.company.CompanyLogo
							// }
							// console.log(obj);
							// this.fileList.push(obj);
							 this.$store.commit('updateUserStep', response.data.step);
							 console.log(this.currentUser);
							 this.$refs.onboarding_wizard.changeTab(0,this.currentUser.step)

							})
							.catch(function(){
								
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
						
		 
			},


			    getSecondStep() {

	           axios.post( '/api/users/get/secondStep',
                       
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {
								console.log(response.data);
                            this.SecondStepData=response.data.company;

							})
							.catch(function(){
								
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
						
		 
			},

			getThirdStep(){
                    axios.post( '/api/users/get/thirdStep',
                       
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {
								console.log(response.data);
							this.ThirdStepData=response.data.company;
							this.TemplatesDetails=response.data.templates;

							})
							.catch(function(){
								
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
			},


					getFourthStep(){
                    axios.post( '/api/users/get/fourthStep',
                       
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {
								console.log(response.data);
							this.ProjectsDetails=response.data.projects;
							
							})
							.catch(function(){
								
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
			},




			onComplete() {

				//Post All Data To Back End We Have Multiple Projects
			//	if(this.ProjectsDetails.length > 0){
					
					// let formData = new FormData();

                    // formData.append('CompanyLogo',this.$refs.CompanyLogo.uploadFiles[0].raw);
                    // formData.append('ProjectsDetails', JSON.stringify(this.$data.ProjectsDetails));
                    // formData.append('AccountSettingForm', JSON.stringify( this.$data.AccountSettingForm));
                    // formData.append('TemplatesDetails', JSON.stringify( this.$data.TemplatesDetails));

                    axios.post( '/api/users/complete_onboading',
                        
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response) => {

                    	//update User localStorage Data
		                axios.get('/api/users/get_user_details')
			                .then(response => {
			            	
			            		this.SetupCompleted = true; // this will allow us to move to next route without navigating aways message

			                    this.$store.commit('setUser', response.data);

			                    Swal({ type: 'success', title: 'Organization Registration Completed', html: "Congratulations <strong class='fw-500'>"+  response.data.Name  +"</strong> !! You have completed the required configuration for <strong class='fw-500'>"+ response.data.CompanyName +"</strong>. The users to whom you have assigned roles, can now log in to start using <span class='text-ogeo'>Ogéo</span>.", showConfirmButton: true, timer: 20000});

									
							})
							if(response.data.onboarding == 0){
								this.$store.dispatch('logout')
								.then(() => {
								this.$router.push('/')
								});
							}

	                })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
                    });
              //  } 
	
	        },
			validateFirstStep() {
				//Check if Logo is uploaded

				return new Promise((resolve, reject) => {
					this.$refs.AccountSettingForm1.validate((valid) => {
						if(!this.$refs.CompanyLogo.uploadFiles[0]){
		                    Swal('Information is missing', 'Please enter the needed information to continue the registration process.', 'warning')
		                    resolve(false);
						}
						console.log(this.FirstStepData);
						let formData = new FormData();
						formData.append('CompanyLogo',this.$refs.CompanyLogo.uploadFiles[0].raw);
						formData.append('FirstStepData', JSON.stringify(this.$data.FirstStepData));

						axios.post( '/api/users/set/firstStep',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
							).then((response) => {

							console.log(response.data);	

							resolve(true);

							})
							.catch(function(){
								resolve(false);
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
						
					});

					
				})
			},
			validateSecondStep() {
				return new Promise((resolve, reject) => {
					this.$refs.AccountSettingForm2.validate((valid) => {
                    	let formData = new FormData();
					
						formData.append('SecondStepData', JSON.stringify(this.$data.SecondStepData));

					  axios.post( '/api/users/set/secondStep',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {

							console.log(response.data);	

							resolve(valid);

							})
							.catch(function(){
								resolve(false);
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});

						
					});
				})
			},
			LoMvalidateStep() {
				return new Promise((resolve, reject) => {
					this.$refs.AccountSettingFormLoM.validate((valid) => {
						
						let formData = new FormData();
				
						formData.append('ThirdStepData', JSON.stringify(this.$data.ThirdStepData));
						formData.append('TemplatesDetails', JSON.stringify(this.$data.TemplatesDetails));
						formData.append('ThirdPartyVendors', this.ThirdPartyVendors.active);
						console.log(JSON.stringify(this.$data.TemplatesDetails));

					  axios.post( '/api/users/set/thirdStep',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {

							console.log(response.data);	

							resolve(valid);

							})
							.catch(function(){
								resolve(false);
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
					});
				})
			},

			
			validateThirdStep() {

				if(this.AccountSettingForm.procurementSetupMode == "Project Wise"){
					if(this.ProjectsDetails == 0){
						
						Swal({ type: 'warning', title: 'No Setups Found', text: "To proceed please make sure you have created at least one Setup.", showConfirmButton: true, timer: 10000});

						return false;

					} else {

						this.scrollTo(0, 100);

						let formData = new FormData();
				
						formData.append('ProjectsDetails', JSON.stringify(this.$data.ProjectsDetails));
						formData.append('AccountSettingForm', JSON.stringify(this.$data.AccountSettingForm));
						console.log(JSON.stringify(this.$data.ProjectsDetails));

					  axios.post( '/api/users/set/fourthStep',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
							).then((response) => {

							console.log(response.data);	

							resolve(valid);

							})
							.catch(function(){
								resolve(false);
								Swal('Error Occured', 'A technical error has occured, please contact system administrator to solve the problem (Account Setup Form)', 'error');
							});
								
						return true;
					}

				} else {
					Swal({ type: 'warning', title: 'Select Setup Type', text: "To proceed please select procurement setup type", showConfirmButton: true, timer: 10000});
				}			
			},
			onAddCADModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.CAD_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.CAD_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.CAD_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Application Administrator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
			onAddPROModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.PRO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.PRO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.PRO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Purchase Enquiry Initiator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddPRVModal: function(evt) {

		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.PRV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.PRV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.PRV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Purchase Enquiry Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
		    },
		    onAddALMRModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.ALMR_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.ALMR_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.ALMR_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Library of Material Update Request Initiator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddLMAModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.LMA_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.LMA_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.LMA_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Library of Materials Administrator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddALMVModal: function(evt) {

		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.ALMV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.ALMV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.ALMV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Library of Materials Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
		    },
		    onAddRFQMModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RFQM_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RFQM_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RFQM_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Request for Proposals Administrator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddPOOModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.POO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.POO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.POO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Purchase Order Initiator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddPOVModal: function(evt) {

		    	// $.toast({
				// 	heading: 'Welcome to Monster admin',
				// 	text: 'Use the predefined ones, or specify a custom position object.',
				// 	position: 'top-right',
				// 	loaderBg:'#ff6849',
				// 	icon: 'warning',
				// 	hideAfter: 3500, 
				// 	stack: 6
				// });

				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				console.log(ListName);
				console.log(this.ProjectModalInfo.POV_List[ListName]);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.POV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.POV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.POV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Purchase Order Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
			},
			onAddSUBVModal: function(evt) {

		    	// $.toast({
				// 	heading: 'Welcome to Monster admin',
				// 	text: 'Use the predefined ones, or specify a custom position object.',
				// 	position: 'top-right',
				// 	loaderBg:'#ff6849',
				// 	icon: 'warning',
				// 	hideAfter: 3500, 
				// 	stack: 6
				// });

				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				console.log(ListName);
				console.log(this.ProjectModalInfo.SUBV_List[ListName]);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.SUBV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.SUBV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.SUBV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Setup Budget Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
		    },
		    onAddRNOModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RNO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RNO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RNO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Receipt Note Initiator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRNVModal: function(evt) {
		    	
		    	// $.toast({
				// 	heading: 'Welcome to Monster admin',
				// 	text: 'Use the predefined ones, or specify a custom position object.',
				// 	position: 'top-right',
				// 	loaderBg:'#ff6849',
				// 	icon: 'warning',
				// 	hideAfter: 3500, 
				// 	stack: 6
				// });

				var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);

				//console.log(ListName);
				//console.log(this.ProjectModalInfo.RNV_List[ListName]);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.RNV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.RNV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.RNV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Receipt Note Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
		    },
		    onAddSLMModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.SLM_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.SLM_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.SLM_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Vendors Qualification Initiator List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
			},
            onAddSLMVModal: function(evt) {
                // $.toast({
                //  heading: 'Welcome to Monster admin',
                //  text: 'Use the predefined ones, or specify a custom position object.',
                //  position: 'top-right',
                //  loaderBg:'#ff6849',
                //  icon: 'warning',
                //  hideAfter: 3500, 
                //  stack: 6
                // });

                var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
                ListName = ListName.slice(4);

                console.log(ListName);
                console.log(this.ProjectModalInfo.SLMV_List[ListName]);
                
                const NewlyAdded = this.ProjectModalInfo.SLMV_List[ListName][evt.newIndex];
                const exsits = this.ProjectModalInfo.SLMV_List[ListName].filter(function(elem) {
                    if(elem.email == NewlyAdded.email) {
                        return elem;
                    }
                });
                
                if(exsits.length > 1){
                    this.$delete(this.ProjectModalInfo.SLMV_List[ListName], evt.newIndex);
                    Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Vendors Qualification Validator List", timer: 2500
                    });
                } else {
                    this.$bvToast.toast('Employee has been assigned the role successfully', {
                      title: 'Employee Assigned Successfully',
                      autoHideDelay: 3000,
                      variant: 'success',
                      solid: true,
                    })
                }
            },
			onAddSUBOModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.SUBO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.SUBO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.SUBO_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Setup Budget List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
			onAddINVModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.INV_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.INV_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.INV_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Invoice Checker Role List', 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },


		    onAddASTMGRModal: function(evt) {

		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(7);

		    	const NewlyAdded = this.ASTMGR_List[ListName][evt.newIndex];
				const exsits = this.ASTMGR_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ASTMGR_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Manager List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddSIIRPTModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.SIIRPT_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.SIIRPT_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.SIIRPT_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom In-Charge List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRTIRSModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RTIRS_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RTIRS_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RTIRS_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom Transaction Request Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddATSIRSModal: function(evt) {
		    	console.log(evt.to.children);
		    	console.log(this.ProjectModalInfo.ATSIRS_List);
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(7);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.ATSIRS_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.ATSIRS_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ProjectModalInfo.ATSIRS_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Transaction Request Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },
		    onAddRTSIModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RTSI_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RTSI_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
                if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RTSI_List, evt.newIndex); Swal('Employee Exists', 'Employee already exists in Storeroom Items Update Request Initiator List', 'warning') } else { 
                		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddATSIModal: function(evt) {
		    	console.log(evt.to.children);
		    	console.log(this.ProjectModalInfo.ATSI_List);
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.ATSI_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.ATSI_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
                	this.$delete(this.ProjectModalInfo.ATSI_List[ListName], evt.newIndex);
                	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Storeroom Items Update Request Validator List", timer: 2500
					});
                } else {
                	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
                }
		    },


		    onAddRCCOModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RCCO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RCCO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RCCO_List, evt.newIndex); Swal('Employee Exists', "Employee already exists in Rates' Contract Creation Request Initiator List", 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRCCVModal: function(evt) {
		    	
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(5);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.RCCV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.RCCV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.RCCV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Rates' Contract Creation Request Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
	                })
	            }
		    },
		    onAddRCOModal: function(evt) {
		    	const NewlyAdded = this.ProjectModalInfo.RCO_List[evt.newIndex];
				const exsits = this.ProjectModalInfo.RCO_List.filter(function(elem) { if(elem.email == NewlyAdded.email) { return elem; } });
	            if(exsits.length > 1){ this.$delete(this.ProjectModalInfo.RCO_List, evt.newIndex); Swal('Employee Exists', "Employee already exists in Rates Contract Initiator List", 'warning') } else { 
	            		this.$bvToast.toast('The Employee has been assigned the role successfully', {
		                  title: 'Employee Assigned Successfully',
		                  autoHideDelay: 3000,
		                  variant: 'success',
		                  solid: true,
		                }) }
		    },
		    onAddRCVModal: function(evt) {
		    	
		    	var ListName = evt.to.children[0].children[1].children[0].children[0].attributes[0].nodeValue;
				ListName = ListName.slice(4);
		    	
		    	const NewlyAdded = this.ProjectModalInfo.RCV_List[ListName][evt.newIndex];
				const exsits = this.ProjectModalInfo.RCV_List[ListName].filter(function(elem) {
				    if(elem.email == NewlyAdded.email) {
				    	return elem;
				    }
				});
				
				if(exsits.length > 1){
	            	this.$delete(this.ProjectModalInfo.RCV_List[ListName], evt.newIndex);
	            	Swal({ type: 'warning', title: 'Employee Exists', text: "Employee already exists in Rates' Contract Validator List", timer: 2500
					});
	            } else {
	            	this.$bvToast.toast('The Employee has been assigned the role successfully', {
	                  title: 'Employee Assigned Successfully',
	                  autoHideDelay: 3000,
	                  variant: 'success',
	                  solid: true,
	                })
	            }
		    },

		},
		
		mounted(){

			//console.log(this.$refs.CompanyLogo.uploadFiles);

			//Add Logged in user initially to the list
			if(this.currentUser.CompanyId != null){
				axios.post('/api/data/get_company_users_list_full', {CompanyId: this.currentUser.CompanyId}).then(response => {
				
					console.log(response.data);
					let vm = this;
					$.each(response.data, function(index, data){
						vm.EmployeesList.push({
							fullName: data.name,
							email: data.email,
							designation: data.designation,
						})
					});
				});
			}
			else{

				this.EmployeesList.push({
					fullName: this.currentUser.Name,
					email: this.currentUser.Email,
					designation: this.currentUser.Designation,
				});

			}
		
			

			//CustomJs();


			var $videoSrc = "https://www.youtube.com/embed/ODrnn3cci9I";  
			
			// when the modal is opened autoplay it  
			$('#VideoModal').on('shown.bs.modal', function (e) {   
				// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
				$("#video").attr('src',$videoSrc + "?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1" ); 
			})
			  
			  
			// stop playing the youtube video when I close the modal
			$('#VideoModal').on('hide.bs.modal', function (e) {
			    // a poor man's stop video
			    $("#video").attr('src',$videoSrc); 
			}) 
			    

			this.getIndustries();
			// this.getCity();
			this.getFirstStep();
			this.getSecondStep();
			this.getThirdStep();
			this.getFourthStep();
				

	    },

		beforeRouteEnter (to, from, next) {
            let currentUser = JSON.parse(localStorage.getItem("user"));

            if(currentUser.hasOwnProperty('CAD') && currentUser.CompanyId == null){
            	
                //check with the backend if user has CAD privileges
                axios.get('/api/users/get_user_details')
                    .then(response => {
                        //console.log(response.data);
                        if(response.data.hasOwnProperty('CAD') && response.data.CompanyId == null){
                            next();
						} 
						  next();
						// else {

                        //     Swal("Fraud Offence", "You do not have the right to access this page, this instance will be reported. \n. ("+ response.data.Name +")" , 'error');

                        //     //this instance must be reported to since there is fraud envolved.
                        //     next('/login');
                        // }
                    })

			 } 
			   next();
			 //else {

            // 	if(from.path != '/'){
            // 		Swal("Access Denied", "You do not have the right to access this page", 'error');
            // 	}

            //     next('/login');
                
            //}       
        },
        beforeRouteLeave (to, from, next) {
            if(!this.SetupCompleted){
                Swal({
                    title: 'Navigate Away?',
                    text: "Are you sure you want to navigate away, all unsubmitted data will be lost?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                }).then((result) => {
                    if (result.value) {

                    	if(to.path == '/'){ // This check if we are actually logging out
                    		this.$store.dispatch('logout')
		                	.then(() => {
		                		next()
		                	});
                    	} else {
                    		next()
                    	}

                        

                    } else {
                        next(false)
                    }
                })
            } else {
                next();
            }
        }

	}

</script>
<style>
    @media print {
      body * {
          visibility: hidden;
      }
	  #non-print {
		  display: none;
	  }
      #SubscriberAgreement, #SubscriberAgreement * {
        visibility: visible;
      }
      #SubscriberAgreement {
        margin-top: -400px;
		position: absolute;
        left: 0;
        top: 0;
      }
    }
</style>
<style>

   .el-radio__input.is-checked + .el-radio__label {
       color: #ff2f2f !important;
   }
   .el-radio__input.is-checked .el-radio__inner {
       background: #ff2f2f !important;
       border-color: #ff2f2f !important;
   }
   .el-checkbox__input.is-checked + .el-checkbox__label {
       color: #ff2f2f !important;
   }
   .el-checkbox__input is-checked{
	   background: #ff2f2f !important;
       border-color: #ff2f2f !important;
   }
   .el-radio__inner{
	   border-radius: 100%;
   }

	.smallerfont {
		font-size: 5px;
	}

    #ProjectDetailsModalId .modal-dialog {
        min-width:70%;
    }
	
	/* Enter and leave animations can use different */
	/* durations and timing functions.              */
	.slide-fade-enter-active {
	  transition: all .3s ease;
	}
	.slide-fade-leave-active {
	  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
	  transform: translateY(10px);
	  opacity: 0;
	}


	.modal-dialog-video {
    	max-width: 800px;
	  	text-align: left;
	  	margin-top: 10%; 
    	vertical-align: middle;
  	}

	.modal-body-video {
	 	position:relative;
	  	padding:0px;
		z-index: 9999 !important;
	}

	.close-video {
	    background: #fc4b6c; 
	  	font-size:2rem;
	  	font-weight: normal;
	  	color:#fff;
	  	position:absolute;
	  	right:-25px;
	  	top:-25px;
	  	z-index:999;
		width: 50px;
	    height: 50px;
	    -moz-border-radius: 70px; 
	    -webkit-border-radius: 70px; 
	    border-radius: 70px;
	  	opacity:1;
	}

	.itemTable >tr>td{
      height:20px;
      padding:0px;
      border-top: 0px;
    }
	

	.StickeyPanel { 
		position: -webkit-sticky; 
		position: sticky; 
		top: 130px;
	}

	.StickeyPanelModal { 
		position: -webkit-sticky; 
		position: sticky; 
		top: 15px;
	}

	.StickeyEmployeelModal { 
		position: -webkit-sticky; 
		position: sticky; 
		top: 5px;
	}

</style>
