<template>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 style="display: contents;"><b><i>Materials Purchase Enquiries</i> List</b></h4>
                    <!-- <div class="text-ogeo float-right font-500">
                        <el-switch style="margin-top:0px" v-model="switchbutton" @change="changeTable()" active-color="rgb(255, 47, 47)" inactive-color="rgb(255, 47, 47)" active-text="Materials" inactive-text="Services" active-value="Material" inactive-value="Service"></el-switch>
                    </div> -->
                </div>
                <div class="card-body">
                    
                    <table ref="table" id="ProjectPEList" class="display responsive table table-bordered table-hover" style="width:100%">
                        <thead>
                            <tr>
                                <th><i>Purchase Enquiry</i> ID#</th>
                                <!-- <th v-else>{{ CompanySetup.pe_prefix }} #</th> -->
                                <th>GroupID</th>
                                <th><i>Account</i> <br>Name</th>
                                <th>Initiated By</th>
                                <th>Current Level Of <br>Validation</th>
                                <th>Item <br>Detail</th>
                                <th style="width: 30px;">Quantity</th>
                                <th>Unit of Measure</th>
                                <th>Expected Cost</th>
                                <th>Delivery Location Details</th>
                                <th>Country | City of Delivery</th>
                                <th>List Of Validators</th>
                                <th><i>Advance Payment</i> Arrangement</th>
                                <th><i>Retention</i> Arrangement</th>
                                <th>Selected <i>Materials Rates' Contract</i></th>
                                <th>Sourcing Priority</th>
                                <th><i>Purchase Enquiry</i> Underlying Transaction</th>
                                <th>Required Documents From The Vendors</th>
                                <th>Awardee Vendor</th>
                                <th>Initiated On</th>
                                <th>Last Updated On</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>

        <div id="PurchaseEnquiryUpdateModalView" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="PurchaseEnquiryUpdateModalView" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width: 50%;">
                            <p style="margin-top: 4px;">
                                <span style="margin-left: 50px; display: block; font-size: 1.5em; line-height: 1.3; color: black;" class="modal-title ogeo-text">
                                    <b><b><i>Materials Purchase Enquiry</i> Line ID#:</b></b>
                                    <span style="color: rgb(252, 51, 23);"><b>{{ ViewModalInfo.show_id }}</b></span>
                                </span>
                                <span v-if="ViewModalInfo.RateContractSelected" style="margin-left: 198px; display: block; font-size: 0.9em; color: rgb(0,0,0); font-weight: bold; margin-top: -5px;">
                                    <b>
                                        <span style="color: white; background-color: rgb(0, 114, 187); padding-left: 10px; padding-right: 10px; border-radius: 10px; border: 2px solid white;">Linked To A <i>Materials Rates' Contract</i></span>
                                    </b>
                                </span>
                            </p>
                        </div>    
                        <div style="width:50%" v-if="this.CompanySetup.logo">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:40px;height:40px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                X
                            </button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="viewDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <table class="table sptable">
                                            <tr>                         
                                                <td style="width: 75%; text-align: right; padding-right: 20px; font-weight: 700;"><b><i>Account</i> Information:</b></td>   
                                                <td width="25%">
                                                        <span><b class="font-700"><i>Account</i> Name:</b> {{ ViewModalInfo.project.title }}</span><br>
                                                        <span><b class="font-700">Start Date:</b> {{ ViewModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                        <span><b class="font-700">End Date:</b> {{ ViewModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                        <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                                </td>     
                                            </tr>  
                                            <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                            </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ ViewModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ ViewModalInfo.created_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700" style="white-space: nowrap;">{{ ViewModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ ViewModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Current Level<br>Of Validation: </b></td>
                                            <td>{{ ViewModalInfo.approval_level }} out of {{ ViewModalInfo.count_role }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">List Of Validators: </b></td>
                                            <td>
                                                <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">
                                                    <li v-for="(his, index) in validatorsList" :key="index" style="padding-left: 0px;">
                                                        {{ his.name }} <i>(Level {{ his.level }})</i>
                                                        <span v-if="his.date"> | {{ his.date }} | </span>
                                                        <span v-if="his.decision" class="font-italic">({{ his.decision }})</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ ViewModalInfo.sourcing_priority }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                            </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i><br>Underlying Transaction: </b></td>
                                            <td>{{ ViewModalInfo.underlying_transaction }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700"><i>Purchase Enquiry</i>  <br>Type: </b></td>
                                            <td>{{ ViewModalInfo.enquiry_type }}s</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Delivery Address <br>Details:</b></td>
                                            <td>
                                                <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Delivery Country | City:</b> {{ ViewModalInfo.country_of_delivery }} | {{ ViewModalInfo.cities_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Delivery Country | City:</b> {{ ViewModalInfo.country_of_delivery }} | {{ ViewModalInfo.cities_of_delivery }}</span><br>
                                                <!--<span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span>-->
                                                <!--<span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span><br>-->
                                                <span><b class="font-700 font-italic">Location Name:</b> {{ ViewModalInfo.location_name }}</span><br>
                                                <span><b class="font-700 font-italic">Longitude:</b> {{ ViewModalInfo.longitude }}</span><br>
                                                <span><b class="font-700 font-italic">Latitude:</b> {{ ViewModalInfo.latitude }}</span><br> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"><hr style="margin: 10px 0;"></td>
                                        </tr>
                                </table>
                            </div>
                            <!-- Purchase Enquiry General Infomation Panel End -->

                            <!-- Materials Infomation Panel -->
                            <div class="col-lg-12">
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="ViewModalInfo.RateContractSelected"><b class="font-700">Are <i>Advance Payments</i> Considered For The Items <br>Sourced Under The Selected <i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">Are <i>Advance Payments</i> Considered For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.advanced_payment }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="ViewModalInfo.RateContractSelected"><b class="font-700">Are Collaterals Required From The Vendors Against<br>The <i>Advance Payments</i>, Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">Are Collaterals Required From The Vendors Against<br>The <i>Advance Payments</i>, For The Item> ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.advanced_payment_guarantee ? ViewModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="ViewModalInfo.RateContractSelected"><b class="font-700">What Is The <i>Retention Percentage</i> For The Items <br>Sourced Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">What Is The <i>Retention Percentage</i> For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.retention_percentage	 ? ViewModalInfo.retention_percentage+'%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="ViewModalInfo.RateContractSelected"><b class="font-700">What Is The <i>Retention Timeframe</i>* For<br>The Items Sourced Under The Selected<br><i>Materials Rates' Contract</i> ? </b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">What Is The <i>Retention Timeframe</i>* For The Item ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ ViewModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <!-- First Column: Documents title -->
                                        <td style="padding-left: 80px;"><b class="font-700">Documents Required From The Vendors: </b></td>

                                        <!-- Second Column: Display list of documents if available -->
                                        <td v-if="ViewModalInfo.required_documents" style="padding-left: 20px;">
                                            <ul style="columns: 3; -webkit-columns: 3; -moz-columns: 3; width: 100%; padding-left: 20px; margin-bottom: 5px; list-style-position: inside;">
                                                <li v-for="(document, index) in ViewModalInfo.required_documents.split(',')" :key="index" style="margin-bottom: 5px;">
                                                    {{ document }}
                                                </li>
                                            </ul>
                                        </td>
                                        <!-- Second Column (else): Message when no documents are required -->
                                        <td v-else>
                                            No Documents Are Required From The Vendors.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>                                      
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials' && (ViewModalInfo.enquiry_from_item_master == 'Yes' || ViewModalInfo.item.field_1)">
                                        <td style="padding-left: 80px; width: 40%; border: none;">
                                            <b class="font-700"><i>Library of Materials</i> <br>Item Details: </b>
                                        </td>
                                        <td style="position: relative; left: -150px; width: 75%;">
                                            <div style="width: 70%;">
                                                <span v-if="!isJSON(ViewModalInfo.item.picture)">
                                                    <img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+ViewModalInfo.item.picture">
                                                </span>
                                                <span class="text-center" v-else v-for="(img, index) in JSON.parse(ViewModalInfo.item.picture)" :key="index">
                                                    <img style="width: 8vw; height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img">
                                                </span>
                                                <br><br>
                                                <span><b class="font-700"><span>LoM-</span>{{ ViewModalInfo.item.item_group_id }}-{{ ViewModalInfo.item.item_ingroup_id }}</b></span><br>
                                                <span>
                                                    <template v-for="index in 20">      
                                                        <span v-if="ViewModalInfo.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b class="font-700 text-uppercase">{{ ViewModalInfo.item.item_template['field_'+index] }}:</b> {{ ViewModalInfo.item['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br><br>
                                                <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measure:</b> {{ ViewModalInfo.item.u_o_m.toUpperCase() }}</span><br>
                                            </div>
                                        </td>
                                        <hr style="border-top: 1px solid #f0f0f0; margin: 0;">
                                    </tr>
                                    <tr v-else>
                                        <td style="padding-left: 80px; width: 40%; border: none;">
                                            <b class="font-700">Item <br>Description: </b>
                                        </td>
                                        <td class="dont-break-out" style="position: relative; left: -150px; width: 75%;">
                                            <div style="width: 60%;">
                                                <span v-if="ViewModalInfo.p_images.length > 0" v-for="(img, index) in JSON.parse(ViewModalInfo.p_images)">
                                                    <img style="width: 8vw; height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/images/'+img">
                                                </span><br><br>
                                                <span>{{ ViewModalInfo.item_description }}</span><br><br>
                                                <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ ViewModalInfo.u_o_m }}</span><br>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style="border: none;"><td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td></tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Quantity:</b></td>
                                        <td><span style="font-weight: 900;"><b class="font-700">{{ ViewModalInfo.quantity }}</b></span><span style="font-weight: normal; font-size: 15px;"> ( {{ ViewModalInfo.u_o_m }} )</span><br></td>                                    
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px; width: 33%; vertical-align: top;">
                                            <b style="color: rgb(0, 114, 187); font-weight: bold;">Selected <br><i>Materials Rates’ Contract</i> <br>Information:</b>
                                        </td>
                                        <td v-if="ViewModalInfo.RateContractSelected" style="width: 45%; vertical-align: top;">
                                            <ul style="padding-left: 0; color: rgba(0, 0, 0, 1); list-style-type: disc;">
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Name | ID#:</b> {{ ViewModalInfo.RateContractSelected.vendor_name }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Score:</b> {{ ViewModalInfo.RateContractSelected.vendor_score }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Start Date | End Date:</b> {{ ViewModalInfo.RateContractSelected.date }} </span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Unit Rate:</b> {{ formatPrice(ViewModalInfo.RateContractSelected.unit_rate) }} {{ ViewModalInfo.project.currency }} per <b class="font-700">{{ ViewModalInfo.u_o_m }}</b></span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Lead Time:</b> {{ ViewModalInfo.RateContractSelected.lead_time }}</span><br></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700"><i>Volume-discount Arrangement</i> | <i>Minimal Procured Value</i>:</b></span></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Applicable discount rate for the Unit Rate:</b></span></li>
                                                <li style="list-style-type: none; padding: 0; margin: 0.5px 0;"><hr style="margin: 0; width: 600px; border: none; border-top: 1px solid rgb(0, 114, 187);"></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Number of <i>Purchase Orders</i> <br>awarded under this <i>Materials Rates' Contract</i>:</b></span></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Turnover (Purchase Orders) <br>under this <i>Materials Rates' Contract</i>:</b></span></li>
                                            </ul>
                                        </td>
                                        <td v-else-if="ViewModalInfo.enquiry_from_item_master !== 'Yes'">
                                            This Isn't An Item From The <i>Library of Materials</i>. <br>Thus, There Are No <i>Materials Rates’ Contracts</i> For It.
                                        </td>
                                        <td v-else>
                                            No <i>Materials Rates’ Contract</i> Was Selected For This Item.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Unit Rate: <br><span style="font-size: 85%;">If a <i>Materials Rates' Contract</i>  is selected.</span></b></td>                           
                                        <td colspan="2" v-if="ViewModalInfo.RateContractSelected">
                                            <b class="font-700">{{ formatPrice(ViewModalInfo.RateContractSelected.unit_rate) }}</b>
                                        </td>
                                        <td v-else-if="ViewModalInfo.enquiry_from_item_master == 'No'">
                                            Not Applicable As It Is A Free-Text Described Item <br>(Not An Item From The <i>Library of Materials</i>.)
                                        </td>
                                        <td v-else>
                                            Not Available As No <i>Materials Rates' Contract</i> Was Selected For This Item.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;" width="40%"><b class="font-700">Expected Cost: <br><span style="font-size: 85%;">If a <i>Materials Rates' Contract</i>  is selected.</span></b></td>
                                        <td colspan="2" v-if="ViewModalInfo.RateContractSelected">
                                            <b class="font-700">{{ formatPrice(ViewModalInfo.RateContractSelected.unit_rate * ViewModalInfo.quantity) }} {{ ViewModalInfo.project.currency }}</b>
                                        </td>
                                        <td v-else-if="ViewModalInfo.enquiry_from_item_master == 'No'">
                                            Not Applicable As It Is A Free-Text Described Item <br>(Not An Item From The <i>Library of Materials</i>.)
                                        </td>
                                        <td v-else>
                                            Not Available As No <i>Materials Rates' Contract</i> Was Selected For This Item.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;"><b class="font-700">Additional Notes:</b></td>
                                        <td v-if="!ViewModalInfo.ViewLineNote">No Additional Notes.</td>
                                        <td v-else>{{ ViewModalInfo.ViewLineNote }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;"><b class="font-700">Awardee Vendor:</b></td>
                                        <td>Not Yet Awarded.</td>
                                    </tr>
                                    <tr v-if="ViewModalInfo.enquiry_from_item_master == 'Yes'">
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    
                                    <!-- This row keeps the height consistent without adding a visible line -->
                                    
                                    <tr v-else>
                                        <td colspan="3" style="border-top: none; padding: 0; height: 0.75px;"></td>
                                    </tr>
                                    
                                </table>


                            </div>
                            <!-- Materials Infomation Panel End -->
                            
                             <!-- List of Valid Rates Contracts -->
                             <div class="col-lg-12" v-if="ViewModalInfo.enquiry_from_item_master == 'Yes'">
                                <div style="background-color: rgba(0, 114, 187, 0.75); padding: 10px 0; text-align: center; width: 100%; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); position: relative; bottom: -1px;">
                                    <h3 style="margin: 0; color: white;">
                                        Valid <i>Materials Rates’ Contracts</i> For The Item
                                    </h3>
                                </div>
                                <table class="display table table-bordered" style="width:80vw !important;">
                                    <thead style="width:80vw !important;">
                                            <tr>
                                                <th><b>Vendor Name | ID#</b></th>
                                                <th><b>Country | Cities</b></th>
                                                <th><b>Unit Rate</i></b></th>
                                                <th><b>Lead Time</b></th>
                                                <th><b>Start Date | End Date</b></th>
                                                <th><b><i>Volume-discount Arrangement</i> | <br><i>Minimal Procured Value</i></b></th>
                                                <th><b>Unit Rate Discount</b></th>
                                                <th><b><i>Advanced Payment</i> Arrangement</b></th>
                                                <th><b><i>Retention</i> Arrangement</b></th>
                                                <th><b>Total Turnover <br>Till Date</b></th>
                                            </tr>
                                        </thead>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                <td>{{ item.vendor_name }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </table>
                                    
                            </div>
                            <!-- List of Valid Rates Contracts End -->

                        </div>
                        
                        <div class="col-lg-12">
                            <hr> 
                        </div>
                        <div class="col-lg-12">
                            <h3 style="background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>   
                            <table class="display table table-bordered" style="width:80vw !important;">
                                <thead style="width:80vw !important;">
                                    <tr>
                                        <th><b>#</b></th>
                                        <th><b>Action Description</b></th>
                                        <th><b>Action By</b></th>
                                        <th><b>Action Level</b></th>
                                        <th><b>Decision-<i>Note</i></b></th>
                                        <th><b>Action Date</b></th>
                                        <th><b>Timespan Between Actions</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Description</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Action By</b></th>
                                        <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Approval Level</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                    <tr v-for="(item, key) in ViewModalInfo.history" :key="item.id">
                                        <td>{{ key + 1 }}</td>
                                        <td>{{ item.current_action_description }}</td>
                                        <td>{{ item.current_action_by.name }}</td>
                                        <td>{{ item.current_approval_level }}</td>
                                        <td><b>{{ item.decision }}</b> - <i>{{ item.decision_notes }}</i></td>
                                        <td>{{ item.created_at }}</td>
                                        <td v-if="key == 0">Not Applicable</td>
                                        <!-- <td v-else>{{ item.created_at }}</td> -->
                                        <td v-else>{{ item.created_at | getDifference(ViewModalInfo.history[key-1].created_at) }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_description }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_owners }}</td>
                                        <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_approval_level }}</td>
                                        
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block pull-left">            
                                <span>
                                    <b>Document Generated By:</b> <span style="color: rgb(0, 0, 0);">{{ currentUser.Name }}</span>
                                </span>
                            </div>                                
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" :src="'/assets/images/poweredby.png'" alt=""></span>
                            </div>
                        </div>
                            <br><br><br><br>
                        <div class="col-lg-24; text-center">
                            <span style="color: black;"><i>The information provided in this document is accurate at the below time:</i></span>
                            <br>
                            <span style="color: rgb(252, 51, 23)">{{ new Date() }}</span>
                                <div class="d-flex justify-content-center mt-3">
                                    <button class="btn btn-success waves-effect text-center" style="box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.3); transition: box-shadow 0.3s ease, transform 0.3s ease;" onmouseover="this.style.boxShadow='4px 12px 16px rgba(0, 0, 0, 0.4)'; this.style.transform='translateY(-5px)';" onmouseout="this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.3)'; this.style.transform='translateY(0)';" onclick="document.title = 'Materials Purchase Enquiry Line Information';window.print()">
                                        Download PDF
                                    </button>
                                </div>
                        </div>
                                         
                    </div>
                </div>
            </div>
        </div>
        <div id="PurchaseEnquiryUpdateModalEdit" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="PurchaseEnquiryUpdateModalEdit" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div  class="modal-header">
                        <div style="width:50%">
                            <p style="margin-top: 4px;">
                                <span style="margin-left: 50px; display: block; font-size: 1.5em; line-height: 1.3; color: black;" class="modal-title ogeo-text">
                                    <b><b><i>Materials Purchase Enquiry</i> Line ID#:</b></b>
                                    <span style="color: rgb(252, 51, 23);"><b>{{ ViewModalInfo.show_id }}</b></span>
                                </span>
                                <span v-if="EditModalInfo.RateContractSelected" style="margin-left: 198px; display: block; font-size: 0.9em; color: rgb(0,0,0); font-weight: bold; margin-top: -5px;">
                                    <b><span style="color: white; background-color: rgb(0, 114, 187); padding-left: 10px; padding-right: 10px; border-radius: 10px; border: 2px solid white;">Linked To A <i>Materials Rates' Contract</i></span></b>
                                </span>
                            </p>
                        </div>
                        <div style="width:50%" class="my-auto">
                            <img :src="'/uploads/Logos/'+this.CompanySetup.logo" style="width:80px;height:80px;border-radius:25px">
                        </div>
                        <div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                ×
                            </button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="editDataLoaded">
                        <div class="row">
                            <div class="col-lg-12">
                                <table class="table sptable">
                                            <tr>                         
                                                <td style="width: 75%; text-align: right; padding-right: 20px; font-weight: 700;"><b><i>Account</i> Information:</b></td>   
                                                <td width="25%">
                                                        <span><b class="font-700"><i>Account</i> Name:</b> {{ EditModalInfo.project.title }}</span><br>
                                                        <span><b class="font-700">Start Date:</b> {{ EditModalInfo.project.start_date | formatDateNTime }}</span><br>
                                                        <span><b class="font-700">End Date:</b> {{ EditModalInfo.project.end_date | formatDateNTime }}</span><br>
                                                        <!--<span><b class="font-700 font-italic">Current Budget:</b> {{ formatPrice(ViewModalInfo.project.value - ViewModalInfo.project.consumed_budget) }} {{ ViewModalInfo.project.currency }}</span>-->
                                                </td>     
                                            </tr>  
                                            <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                            </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table class="table sptable">
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Line Initiated By: </b></td>
                                            <td>{{ EditModalInfo.creator.name }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Line Initiated On: </b></td>
                                            <td>{{ EditModalInfo.created_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700 text-ogeo">Line Status: </b></td>
                                            <td class="text-ogeo font-700" style="white-space: nowrap;">{{ EditModalInfo.status }}</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Last Updated On: </b></td>
                                            <td>{{ EditModalInfo.updated_at }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Current Level<br>Of Validation: </b></td>
                                            <td>{{ EditModalInfo.approval_level }} out of {{ EditModalInfo.count_role }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">List Of Validators: </b></td>
                                            <td>
                                                <ul style="padding-left: 50px; margin: 0; list-style-position: outside;">
                                                    <li v-for="(his, index) in validatorsList" :key="index" style="padding-left: 0px;">
                                                        {{ his.name }} <i>(Level {{ his.level }})</i>
                                                        <span v-if="his.date"> | {{ his.date }} | </span>
                                                        <span v-if="his.decision" class="font-italic">({{ his.decision }})</span>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>       
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700">Sourcing Priority: </b></td>
                                            <td>{{ EditModalInfo.sourcing_priority }}</td>
                                        </tr>       
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700"><i>Purchase Enquiry</i><br>Underlying Transaction: </b></td>
                                            <td>{{ EditModalInfo.underlying_transaction }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;"><b class="font-700"><i>Purchase Enquiry</i>  Type: </b></td>
                                            <td>{{ EditModalInfo.enquiry_type }}</td>
                                        </tr>
                                        <tr style="border: none;">
                                                <td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left: 80px;" width="40%"><b class="font-700">Delivery Address <br>Details:</b></td>
                                            <td>
                                                <span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">Delivery Country | City:</b> {{ EditModalInfo.country_of_delivery }} | {{ EditModalInfo.cities_of_delivery }}</span>
                                                <span v-else><b class="font-700 font-italic">Delivery Country | City:</b> {{ EditModalInfo.country_of_delivery }} | {{ EditModalInfo.cities_of_delivery }}</span><br>
                                                <!--<span v-if="EditModalInfo.enquiry_type == 'Materials'"><b class="font-700 font-italic">City of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span>-->
                                                <!--<span v-else><b class="font-700 font-italic">Cities of Delivery:</b> {{ EditModalInfo.cities_of_delivery }}</span><br>-->
                                                <span><b class="font-700 font-italic">Location Name:</b> {{ EditModalInfo.location_name }}</span><br>
                                                <span><b class="font-700 font-italic">Longitude:</b> {{ EditModalInfo.longitude }}</span><br>
                                                <span><b class="font-700 font-italic">Latitude:</b> {{ EditModalInfo.latitude }}</span><br>   
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"><hr style="margin: 10px 0;"></td>
                                        </tr>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="EditModalInfo.RateContractSelected"><b class="font-700">Are <i>Advance Payments</i> Considered For The Items <br>Sourced Under The Selected <i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">Are <i>Advance Payments</i> Considered For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.advanced_payment }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="EditModalInfo.RateContractSelected"><b class="font-700">Are Collaterals Required From The Vendors Against<br> The <i>Advance Payments</i>, Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">Are Collaterals Required From The Vendors <br>Against The <i>Advance Payments</i> For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.advanced_payment_guarantee ? EditModalInfo.advanced_payment_guarantee : 'Not Applicable' }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="EditModalInfo.RateContractSelected"><b class="font-700">What Is The <i>Retention Percentage</i> For The Items <br>Sourced Under The Selected<br><i>Materials Rates' Contract</i> ?</b></td>
                                        <td style="padding-left: 80px;" width="40%" v-else><b class="font-700">What Is The <i>Retention Percentage</i> For The Item ?</b></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.retention_percentage	 ? EditModalInfo.retention_percentage+'%' : 'Not Applicable' }}</td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-if="EditModalInfo.RateContractSelected"><b class="font-700">What Is The <i>Retention Timeframe</i>* For<br>The Items Sourced Under The Selected<br><i>Materials Rates' Contract</i> ? </b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="padding-left: 80px; line-height: 1.05rem;" width="40%" v-else><b class="font-700">What Is The <i>Retention Timeframe</i>* For The Item ?</b><hr style="margin: 10px 0 4px -5px; width: 70%;"><span style="font-size: 0.9rem;"><p style="margin: 0;">* From The Date Of Either Receiving The Total Quantity</p><p style="margin: 0; margin-top: -4px;">Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i></p></span></td>
                                        <td style="position: relative; left: -110px; padding-left: 5px;">{{ EditModalInfo.retention_days ? ViewModalInfo.retention_days + " Day(s)" : 'Not Applicable' }}</td>
                                    </tr>
                                </table>
                                
                                <table style="width: 100%; border-collapse: collapse; color: black;">
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr>
                                        <!-- First Column: Documents title -->
                                        <td style="padding-left: 80px;"><b class="font-700">Documents Required From The Vendors: </b></td>

                                        <!-- Second Column: Display list of documents if available -->
                                        <td v-if="EditModalInfo.required_documents" style="padding-left: 20px;">
                                            <ul style="columns: 3; -webkit-columns: 3; -moz-columns: 3; width: 100%; padding-left: 20px; margin-bottom: 5px; list-style-position: inside;">
                                                <li v-for="(document, index) in EditModalInfo.required_documents.split(',')" :key="index" style="margin-bottom: 5px;">
                                                    {{ document }}
                                                </li>
                                            </ul>
                                        </td>

                                        <!-- Second Column (else): Message when no documents are required -->
                                        <td v-else>
                                            No Documents Are Required From The Vendors.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>                                     
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials' && (EditModalInfo.enquiry_from_item_master == 'Yes' || EditModalInfo.item.field_1)">
                                        <td style="padding-left: 80px; width: 40%; border: none;">
                                            <b class="font-700"><i>Library of Materials</i> <br>Item Details: </b>
                                        </td>
                                        <td style="position: relative; left: -150px; width: 75%;">
                                            <div style="width: 70%;">
                                                <span v-if="!isJSON(EditModalInfo.item.picture)">
                                                    <img style="width:8vw; height: 8vw; text-align: center;" :src="'/uploads/ItemMasterPictures/'+EditModalInfo.item.picture">
                                                </span>
                                                <span class="text-center" v-else v-for="(img, index) in JSON.parse(EditModalInfo.item.picture)" :key="index">
                                                    <img style="width: 8vw; height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/ItemMasterPictures/'+img">
                                                </span>
                                                <br><br>
                                                <span><b class="font-700"><span>LoM-</span>{{ EditModalInfo.item.item_group_id }}-{{ EditModalInfo.item.item_ingroup_id }}</b></span><br>
                                                <span>
                                                    <template v-for="index in 20">      
                                                        <span v-if="EditModalInfo.item.item_template['field_'+index] !== 'Ogeo-None'">
                                                            <b class="font-700 text-uppercase">{{ EditModalInfo.item.item_template['field_'+index] }}:</b> {{ EditModalInfo.item['field_'+index] }} | 
                                                        </span>
                                                    </template>
                                                </span><br><br>
                                                <span><b style="text-transform: uppercase; font-weight: 700;">Unit of Measure:</b> {{ EditModalInfo.item.u_o_m.toUpperCase() }}</span><br>
                                            </div>
                                        </td>
                                        <hr style="border-top: 1px solid #f0f0f0; margin: 0;">
                                    </tr>
                                    <tr v-else>
                                        <td style="padding-left: 80px; width: 40%; border: none;">
                                            <b class="font-700">Item <br>Description: </b>
                                        </td>
                                        <td class="dont-break-out" style="position: relative; left: -150px; width: 75%;">
                                            <div style="width: 60%;">
                                                <span v-if="EditModalInfo.p_images.length > 0" v-for="(img, index) in JSON.parse(EditModalInfo.p_images)">
                                                    <img style="width: 8vw; height: 8vw; text-align: center; margin: 10px;" :src="'/uploads/images/'+img">
                                                </span><br><br>
                                                <span>{{ EditModalInfo.item_description }}</span><br><br>
                                                <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ EditModalInfo.u_o_m }}</span><br>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style="border: none;"><td colspan="2" style="padding: 0; border: none;"><hr style="margin: 5px 0;"></td></tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px; width: 25%; border: none;"><b class="font-700">Quantity: </b></td>
                                        <td><span style="font-weight: 900;"><b class="font-700">{{ EditModalInfo.quantity }}</b></span><span style="font-weight: normal; font-size: 15px;"> ( {{ EditModalInfo.u_o_m }} )</span><br></td>                                    
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px; width: 33%; vertical-align: top;">
                                            <b style="color: rgb(0, 114, 187); font-weight: bold;">Selected <br><i>Materials Rates’ Contract</i> <br>Information:</b>
                                        </td>
                                        <td v-if="EditModalInfo.RateContractSelected" style="width: 45%; vertical-align: top;">
                                            <ul style="padding-left: 0; color: rgba(0, 0, 0, 1); list-style-type: disc;">
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Name | ID#:</b> {{ EditModalInfo.RateContractSelected.vendor_name }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Vendor Score:</b> {{ EditModalInfo.RateContractSelected.vendor_score }}</span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Start Date | End Date:</b> {{ EditModalInfo.RateContractSelected.date }} </span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Unit Rate:</b> {{ formatPrice(EditModalInfo.RateContractSelected.unit_rate) }} {{ EditModalInfo.project.currency }} per <b class="font-700">{{ EditModalInfo.u_o_m }}</b></span><br></li>
                                                <li style="margin-bottom: 1px;"><span><b class="font-700">Lead Time:</b> {{ EditModalInfo.RateContractSelected.lead_time }}</span><br></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700"><i>Volume-discount Arrangement</i> | <i>Minimal Procured Value</i>:</b></span></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Applicable discount rate for the Unit Rate:</b></span></li>
                                                <li style="list-style-type: none; padding: 0; margin: 0.5px 0;"><hr style="margin: 0; width: 600px; border: none; border-top: 1px solid rgb(0, 114, 187);"></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Number of <i>Purchase Orders</i> <br>awarded under this <i>Materials Rates' Contract</i>:</b></span></li>
                                                <li style="margin-top: 1px;"><span><b class="font-700">Turnover (Purchase Orders) <br>under this <i>Materials Rates' Contract</i>:</b></span></li>
                                            </ul>
                                        </td>
                                        <td v-else-if="EditModalInfo.enquiry_from_item_master !== 'Yes'">
                                            This Isn't An Item From The <i>Library of Materials</i>. <br>Thus, There Are No <i>Materials Rates’ Contracts</i> For It.
                                        </td>
                                        <td v-else>
                                            No <i>Materials Rates’ Contract</i> Was Selected For This Item.
                                        </td>
                                    </tr>
                                
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;" width="40%">
                                            <b class="font-700">Expected Cost: <br><span style="font-size: 85%;">As per the selected <i>Materials Rates' Contract</i>.</span></b>
                                        </td>
                                        <td colspan="2" v-if="EditModalInfo.RateContractSelected">
                                            <b class="font-700">{{ formatPrice(EditModalInfo.RateContractSelected.unit_rate * EditModalInfo.quantity) }} {{ EditModalInfo.project.currency }}</b>
                                        </td>
                                        <td colspan="2" v-else-if="EditModalInfo.enquiry_from_item_master == 'No'">
                                            Not Applicable As It Is A Free-Text Described Item (Not An Item From The <i>Library of Materials</i>.)
                                        </td>
                                        <td colspan="2" v-else>
                                            Not Available As No <i>Materials Rates' Contract</i> Was Selected For This Item.
                                        </td>
                                    </tr>
                                        <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;"><b class="font-700">Additional Notes:</b></td>
                                        <td v-if="!EditModalInfo.ViewLineNote">No Additional Notes.</td>
                                        <td v-else>{{ EditModalInfo.ViewLineNote }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_type == 'Materials'">
                                        <td style="padding-left: 80px;"><b class="font-700">Awardee Vendor:</b></td>
                                        <td>Not Yet Awarded.</td>
                                    </tr>
                                    <tr v-if="EditModalInfo.enquiry_from_item_master == 'Yes'">
                                        <td colspan="3"><hr style="margin: 10px 0;"></td>
                                    </tr>
                                    
                                    <!-- This row keeps the height consistent without adding a visible line -->
                                    
                                    <tr v-else>
                                        <td colspan="3" style="border-top: none; padding: 0; height: 0.75px;"></td>
                                    </tr>
                                    
                                </table>
                            </div>
                            <!-- Materials Infomation Panel End -->

                             <!-- List of Valid Rates Contracts -->
                             <div class="col-lg-12" v-if="EditModalInfo.enquiry_from_item_master == 'Yes'">  
                                        <div style="background-color: rgba(0, 114, 187, 0.75); padding: 10px 0; text-align: center; width: 100%;">
                                                    <h3 style="margin: 0; color: white;">
                                                        Valid <i>Materials Rates’ Contracts</i> For The Item
                                                    </h3>
                                                </div> 
                                        <table class="display table table-bordered" style="width:80vw !important;">
                                        <thead style="width:80vw !important;">
                                            <tr>
                                                <th><b>Vendor Name | ID#</b></th>
                                                <th><b>Country | Cities</b></th>
                                                <th><b>Unit Rate</i></b></th>
                                                <th><b>Lead Time</b></th>
                                                <th><b>Start Date | End Date</b></th>
                                                <th><b><i>Volume-discount Arrangement</i> | <i>Minimal Procured Value</i></b></th>
                                                <th><b>Discount Rate To The Unit Rate</b></th>
                                                <th><b><i>Advanced Payments</i> Arrangement</b></th>
                                                <th><b><i>Retention</i> Arrangement</b></th>
                                                <th><b>Total Turnover <br>Till Date</b></th>
                                            </tr>
                                        </thead>
                                            <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                <td>{{ item.vendor_name }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.unit_rate }} USD </td>
                                                <td>{{ item.lead_time }}</td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                                <td>{{ item.vendor_score }}</td>
                                            </tr>
                                        </table>
                                    
                            </div>
                            <!-- List of Valid Rates Contracts End -->


                            <div class="col-lg-12">
                                <hr>

                                <el-form v-if="EditModalInfo.retention_percentage > 0 && !FullyInvoiced && EditModalInfo.receipt_note" :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseRequestRetentionUpdateForm">
                                <el-row :gutter="20">
                                    <el-col :span="24">

                                        <el-col :span="24">
                                            <hr>
                                            <h5>Adjust The Retention Timeframe</h5>
                                        </el-col>
                                        
                                        <el-col :span="24" >
                                            <div class="grid-content">
                                                <el-form-item label="Adjust The Retention Timeframe" prop="RetentionDays">
                                                        
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                The Rentention Timeframe initially opted for at the time of the Purchase Enquiry origination, can be revisited at any time as long as the invoicing by the vendor, hasn't been completed in full.
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="ValidationInfo.RetentionDays" placeholder="Select Retention Days From Delivery date">
                                                        
                                                        <el-option v-for="n in ValidationInfo.RetentionDays" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <div class="col-lg-12 float-right">
                                            <button type="button" @click="PurchaseEnquiryRententionUpdate" class="btn btn-block btn-success waves-effect"
                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                Update Retention Timeframe
                                            </button>
                                        </div>
                                        
                                    </el-col>
                                </el-row>
                            </el-form>   











                            

                            <!-- Show approval form -->
                            <div class="row" v-if="EditModalInfo.status.indexOf('Created and Assigned to ') !== -1 || EditModalInfo.status.indexOf('PE line Approved by Level') !== -1 || EditModalInfo.status.indexOf('Resubmitted and Assigned') !== -1 || EditModalInfo.status.indexOf('for Review') !== -1">
                                <div class="col-lg-12">
                                    
                                <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                    <div class="row">
                                        <div class="col-lg-12">   
                                            <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                                 
                                                <div class="alert alert-info" style="background-color: rgba(0, 128, 0, 0.09); text-align: left; margin-top: 0; border: 2px solid rgba(0, 128, 0, 0.09); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">
                                                        <table style="width: 100%; border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        background-color: rgba(0, 128, 0); 
                                                                        color: white; 
                                                                        text-align: center; 
                                                                        padding: 0;
                                                                        border: none;
                                                                    ">
                                                                        <div style="
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: center;
                                                                            width: 100%;
                                                                        ">
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                            <span style="
                                                                                color: white; 
                                                                                padding: 5px 20px; 
                                                                                font-size: 20px; 
                                                                                font-weight: bold;
                                                                            ">
                                                                                DECISION
                                                                            </span>
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        border: none; 
                                                                        border-top: 1px solid rgba(0, 0, 0, 0.1); 
                                                                        height: 15px;
                                                                    ">
                                                                        <!-- Empty row for horizontal line -->
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 1 Content -->
                                                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>
                                                                    </td>                                                                 
                                                                    <td v-if="EditModalInfo.RateContractSelected" style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <p style="margin: 0;">
                                                                            <b>If a <i>Materials Purchase Enquiry</i> line, <u>linked to a <i>Materials Rates' Contract</i></u>, is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role.<br><br>
                                                                            They can either delete the line permanently from the <i>Materials Purchase Enquiry</i> or amend some details and resubmit it for validation.</b>
                                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII=" />
                                                                        </p>
                                                                        <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                        </td>
                                                                    <td v-else style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <p style="margin: 0;">
                                                                            <b>If a <i>Materials Purchase Enquiry</i> line, <u>unrelated to a <i>Materials Rates' Contract</i></u>, is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role.<br><br>
                                                                            They can either delete the line permanently from the <i>Materials Purchase Enquiry</i> or amend some details and resubmit it for validation.</b>
                                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII=" />
                                                                        </p>
                                                                        <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                    </td>
                                                                    <td style="width: 35px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 3 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                    <td v-if="EditModalInfo.RateContractSelected" style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.2; padding-left: 80px;">
                                                                        <!-- Column 4 Content -->
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Advance Payment</i></b> and <b><i>Retention</i></b> Arrangements, the <b>Underlying Transaction Reference</b>, the <b>Sourcing Priority</b>, the <b>Delivery Location Address</b>, the required <b>Quantity</b>, the 
                                                                            <b>Documents Required From The Vendors</b>, and the <b>Additional Notes</b> <u>can all be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Account</i></b>, the <b><i>Purchase Enquiry</i> Type</b>, and the <b>item</b> descriptiob <u>can’t be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                    </td>

                                                                    <td v-else style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The item description, the <b><i>Advance Payment</i></b> and <b><i>Retention</i></b> Arrangements, the <b>Underlying Transaction</b>, the <b>Sourcing Priority</b>, the <b>Delivery Location Details</b>, the <b>Required Quantity</b>, the <b>Documents Required From The Vendors</b> & the <b>Additional Notes</b> <u>can all be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Account</i></b>, and the <b><i>Purchase Enquiry</i></b> Type <u>can’t be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                    </td>

                                                                    <td style="width: 10px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 5 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                </tr>
                                                        </table>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item prop="DecisionNotes">
                                                            
                                                            <el-input
                                                              type="textarea"
                                                              :rows="3"
                                                              placeholder="Enter Decision Notes. Notes are mandatory when the line is rejected."
                                                              v-model="ValidationInfoApproval.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="DecisionApprove" 
                                                        class="btn btn-block btn-success waves-effect" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Validate
                                                    </button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionReject" 
                                                        class="btn btn-block btn-danger waves-effect" 
                                                        style="background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Reject
                                                    </button>
                                                </div>

                                                <!-- <div class="col-lg-12">
                                                    <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                                </div> -->
                                                    
                                                
                                            </div>
                                        </div>
                                    </div>
                                </el-form> 

                            </div>
                        </div>


                            <div class="row" v-else-if="EditModalInfo.status.indexOf('for Further Approval') !== -1">
                                <div class="col-lg-12">
                                    
                                    <el-form :model="ValidationInfoApproval" class="demo-form-inline" :rules="ApprovalRules" ref="PurchaseEnquiryApprovalForm">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                
                                                <!-- <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item label="Approve Or Reject Request" prop="Decision">
                                                            <el-select @change="DecisionSelection" style="width: 100%; padding: 0px;" v-model="ValidationInfoApproval.Decision" placeholder="Select Yes/No">
                                                                  <el-option label="Approve" value="Approved"></el-option>
                                                                  <el-option label="Reject" value="Rejected"></el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                    </div> 
                                                </div> -->
                                                
                                                <h3 style="background-color: rgb(255, 47, 47); color: white; padding-left: 10px; text-align: center;">DECISION</h3>
                                                <b class="text-ogeo">Rejected Items Will Be Sent Back To The Purchase Request Initiator Who Can Either Drop Them From The Purchase Request, Or Amend Details Such As The Advance Payment  And Retention Payment  Arrangements, The Underlying Transaction, The Sourcing Priority, The Delivery Location Details, The Required Quantity & The Documents Required From The Vendors. 
                                                Only The <i>Account</i>, The Purchase Enquiry Type & The Item Details Are Inalterable.
                                                </b><br><br>   
                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item prop="DecisionNotes">
                                                            <el-input
                                                              type="textarea"
                                                              :rows="5"
                                                              placeholder="Enter Decision Notes. Notes are mandatory when the line is rejected."
                                                              v-model="ValidationInfoApproval.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="DecisionApprove" 
                                                        class="btn btn-block btn-success waves-effect" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Validate
                                                    </button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionReject" 
                                                        class="btn btn-block btn-danger waves-effect" 
                                                        style="background-color: #f10623; border-color: #f10623; 
                                                        color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                        Reject
                                                    </button>
                                                </div>

                                                <!-- <div class="col-lg-12">
                                                    <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                                </div> -->
                                                    
                                                
                                            </div>
                                        </div>
                                    </el-form> 

                                </div>
                            </div>

                            <div class="col-lg-12" v-else-if="EditModalInfo.status.includes('Rejected') && !showAmend">
                                
                                <el-form :model="ValidationInfo" class="demo-form-inline" :rules="rules" ref="PurchaseEnquiryApprovalForm">
                                    <div class="row">
                                        <div class="col-lg-12">   
                                            <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                                 
                                                <div class="alert alert-info" style="background-color: rgba(0, 128, 0, 0.09); text-align: left; margin-top: 0; border: 2px solid rgba(0, 128, 0, 0.09); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">
                                                        <table style="width: 100%; border-collapse: collapse;">
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        background-color: rgba(0, 128, 0); 
                                                                        color: white; 
                                                                        text-align: center; 
                                                                        padding: 0;
                                                                        border: none;
                                                                    ">
                                                                        <div style="
                                                                            display: flex;
                                                                            align-items: center;
                                                                            justify-content: center;
                                                                            width: 100%;
                                                                        ">
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                            <span style="
                                                                                color: white; 
                                                                                padding: 5px 20px; 
                                                                                font-size: 20px; 
                                                                                font-weight: bold;
                                                                            ">
                                                                                DECISION
                                                                            </span>
                                                                            <hr style="flex: 1; border: none; border-top: 2px solid white; margin: 0 10px;">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="5" style="
                                                                        border: none; 
                                                                        border-top: 1px solid rgba(0, 0, 0, 0.1); 
                                                                        height: 15px;
                                                                    ">
                                                                        <!-- Empty row for horizontal line -->
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="width: 20px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 1 Content -->
                                                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAORJREFUSEvt1iFuAkEUxvEfhqSi4Kmimgvg4CQ4BK6yhqSuSQ+AxXEDLCgOwAlw1a2roc0mu83sZmCHhDWElfv+874333s7Oy0NP62G87tdgSdMMcAz+ugS3fFZNmbRC97RjvSnytey1QVvmJ9o/Cd6QSyJDQWG2FWSH/GBFfb4zePJbCiwxKQiMMMisqNkNhT4QidIllX7gJ+IQDJbCDziu+aj22KMS9jS2BX+ntMpCkpmQ4uSFwXNri3mLnC3qOTAIT9Vs5d1E/fPhlO0weiEqdk59Ip1Hk9mb/ePdrW7QOMW/QF1NDgZk5fQhAAAAABJRU5ErkJggg=="/>
                                                                    </td>                                                                 
                                                                    <td v-if="EditModalInfo.RateContractSelected" style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <p style="margin: 0;">
                                                                            <b>If a <i>Materials Purchase Enquiry</i> line, <u>linked to a <i>Materials Rates' Contract</i></u>, is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role.<br><br>
                                                                            They can either delete the line permanently from the <i>Materials Purchase Enquiry</i> or amend some details and resubmit it for validation.</b>
                                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII=" />
                                                                        </p>
                                                                        <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                        </td>
                                                                    <td v-else style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <p style="margin: 0;">
                                                                            <b>If a <i>Materials Purchase Enquiry</i> line, <u>unrelated to a <i>Materials Rates' Contract</i></u>, is rejected, it will be returned to the users holding the <u><i>Purchase Enquiry Initiator</i></u> role.<br><br>
                                                                            They can either delete the line permanently from the <i>Materials Purchase Enquiry</i> or amend some details and resubmit it for validation.</b>
                                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATZJREFUSEvtlb9KA0EQh79oofbBlLFLOvEBArGx1SbgO4hV/phGBLEwWCjii8TWQvEJJI0+gRrtE1FM3Il7sBn2diFwTcg0OTLfzm/4zc1ejowjl3F95ktgDzgHSim23QE7Nrdr2XIK+wBsS861qA+sB2YyApZt/h0oROY3qZ0ILAG/kQNjQDgJeY7FQiDm0L/9ixmEfJqySMBHoBI48QWs2fw9UA2w38CKXjSXXwUGahGfgC1PUSk0VGwP2AwJHALXqtipWbATj8ABcKP+PzOLe6wFxDPpcB9oqI5+gCLwZgslbM10f+RhN4BXV0AOfJhO8im+toALJ/cZYNtmVp2ETfZAfuUy0yHDqnss8N1F8hI0NZu2aC/ALXDl2OKKuwLPxtoucGldmGpyvr5o0dtrFiBzi/4AJXFAGaQ9eusAAAAASUVORK5CYII=" />
                                                                        </p>
                                                                        <hr style="border-top: 1px solid rgba(0, 0, 0);">
                                                                    </td>
                                                                    <td style="width: 35px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 3 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                    <td v-if="EditModalInfo.RateContractSelected" style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.2; padding-left: 80px;">
                                                                        <!-- Column 4 Content -->
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Advance Payment</i></b> and <b><i>Retention</i></b> Arrangements, the <b>Underlying Transaction Reference</b>, the <b>Sourcing Priority</b>, the <b>Delivery Location Address</b>, the required <b>Quantity</b>, the 
                                                                            <b>Documents Required From The Vendors</b>, and the <b>Additional Notes</b> <u>can all be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Account</i></b>, the <b><i>Purchase Enquiry</i> Type</b>, and the <b>item</b> descriptiob <u>can’t be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                    </td>

                                                                    <td v-else style="width: 700px; vertical-align: top; color: black; font-size: 17px; line-height: 1.3;">
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The item description, the <b><i>Advance Payment</i></b> and <b><i>Retention</i></b> Arrangements, the <b>Underlying Transaction</b>, the <b>Sourcing Priority</b>, the <b>Delivery Location Details</b>, the <b>Required Quantity</b>, the <b>Documents Required From The Vendors</b> & the <b>Additional Notes</b> <u>can all be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                        <ul style="margin-top: 0; padding-left: 0;">
                                                                            <li style="margin-bottom: 5px;">
                                                                            The <b><i>Account</i></b>, and the <b><i>Purchase Enquiry</i></b> Type <u>can’t be amended</u>.
                                                                            </li>
                                                                        </ul>
                                                                    </td>

                                                                    <td style="width: 10px; vertical-align: top; text-align: left;">
                                                                        <!-- Column 5 Content -->
                                                                        <!-- This column can contain additional content or be left empty as needed -->
                                                                    </td>
                                                                </tr>
                                                        </table>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="grid-content">
                                                        <el-form-item class="decision" prop="DecisionNotes" >
                                                            <el-input
                                                            type="textarea"
                                                            :rows="3"
                                                            placeholder="Enter Decision Notes. Notes aren't mandatory at this stage even when the line is to be permanently deleted."
                                                            v-model="ValidationInfo.DecisionNotes">
                                                            </el-input>
                                                        </el-form-item>
                                                    </div> 
                                                </div>

                                                <div class="col-lg-6 float-right">
                                                    <button type="button" @click="showAmend = true" 
                                                        style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);" class="btn btn-block btn-success waves-effect"
                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">

                                                    Amend Line Information And Resubmit For Validation
                                                    </button>
                                                </div>

                                                <div class="col-lg-6 float-left">
                                                    <button type="button" @click="DecisionRejectPermanent" 
                                                    class="btn btn-block btn-danger waves-effect" style="background-color: #f10623; border-color: #f10623; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                    Delete The Line From the <i>Materials Purchase Enquiry</i>
                                                    </button>
                                                </div>

                                            <!-- <div class="col-lg-12">
                                                <button type="button" @click="PurchaseEnquiryDecision" class="btn btn-primary btn-block waves-effect">Submit Decision</button>
                                            </div> -->
                                                
                                            </div>
                                        </div>
                                    
                                    </div>
                                </el-form>
                                
                            </div>

                            <!-- Form for update and Resubmitt -->
                            <div class="col-lg-12" v-else>
                                <div class="grid-content" style="border: 5px solid rgba(0, 128, 0); box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); padding: 20px; border-radius: 10px;">
                                <el-form id="PEForm" :model="PurchaseEnquiryLine" class="demo-form-inline" :rules="PeEditRules" ref="PurchaseEnquiryForm">

                                    <el-row :gutter="20">
                                        <el-col :span="24">
                                        <div class="grid-content">
                                            <h3 style="
                                                background-color: #008000; 
                                                color: white; 
                                                text-align: center; 
                                                padding: 10px; 
                                                position: relative; 
                                                width: 100%; 
                                                margin: 0; 
                                                box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);">
                                                <span style="background-color: #008000; padding: 0 20px; position: relative; z-index: 1;">
                                                    Amend The <i>Materials Purchase Enquiry</i> Line
                                                </span>
                                                <div style="
                                                    position: absolute;
                                                    top: 50%; 
                                                    left: 0; 
                                                    right: 0; 
                                                    height: 1px; 
                                                    background-color: white; 
                                                    z-index: 0;">
                                                </div>
                                            </h3>

                                                
                                                <!-- <span class="text-ogeo font-700">Rates’ Contracts are pre-negotiated agreements with 
                                                vendors intended to freeze unit prices and lead times for materials 
                                                (strictly those from the Library of Materials) and services, before the 
                                                demand for them actually arises, so as to reduce cost and workload 
                                                (Purchase Enquiries linked to Rates’ Contracts can be directly turned into 
                                                Purchase Orders). Establishing Rates’ Contract starts with raising a 
                                                formal Rates’ Contract Creation Requests.</span> -->
                                            </div>
                                        </el-col>   

                                        <transition v-if="CompanySetup.setup_mode == 'Project Wise'">
                                            <el-col :span="24">
                                                <div class="grid-content">
                                                    <el-form-item label="temp" prop="JobNumber">
                                                        <span slot="label"><b>The <i>Account</i></b></span>

                                                        <el-select disabled id="SetupSelection" @change="getProjectCurrency()" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.JobNumber" placeholder="Select an 'Account' for which you hold the Purchase Enquiry Initiator role'">
                                                            
                                                            <el-option  v-for="(JobNumber, JobNumberId, index) in JobNumbers" :key="JobNumberId" :label="JobNumber" :value="JobNumberId"></el-option>
                                                            
                                                        </el-select>
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </transition>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryType">
                                                    <span slot="label"><b>The <i>Purchase Enquiry</i>  type</b></span>

                                                    <el-select disabled filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryType" placeholder="Select Purchase Enquiry Type (Material Or Service)">
                                                            
                                                        <el-option label="Materials" value="Materials"></el-option>
                                                        <el-option label="Services" value="Service"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.JobNumber">
                                            <hr style="border: 1px solid rgba(252, 51, 23, 0.90);">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="SourcingPriority">
                                                    <span slot="label"><b>Amend the sourcing priority ?</b></span>

                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span style="bottom: 23px; left: -42px; width: 1150px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <p>The sourcing priority but can be set to <i>Standard</i>, <i>Urgent</i> or <i>Very Urgent</i>, as needed.</p>
                                                                <p>This setting determines the urgency with which proposals from vendors should be sourced during the tendering process.</p>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.SourcingPriority" placeholder="">
                                                            
                                                        <el-option label="Standard" value="Standard"></el-option>
                                                        <el-option label="Urgent" value="Urgent"></el-option>
                                                        <el-option label="Very Urgent" value="Very Urgent"></el-option>
                                                            
                                                    </el-select>

                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service') && (this.CompanySetup.additional_required_1 == 'Yes')">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="UnderlyingTransaction">
                                                    <span slot="label"><b>Enter the underlying transaction reference</b></span>

                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                <strong>The Underlying Transaction Reference</strong> is 
                                                                the reference of the transaction that has led to the initiation
                                                                of this specific Purchase Enquiry. It can be a particular Job 
                                                                Ticket, a Sales Order, a Work Order, a Client Request,…etc
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <el-input v-model="PurchaseEnquiryLine.UnderlyingTransaction" placeholder="e.g. Sales Order#1213412.... Or SO#1213412 Or Order#45590"></el-input>

                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <!-- Delivery Location Selection -->
                                        <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                                        <div class="grid-content">
                                                            <el-form-item prop="Country">
                                                                <span slot="label"><b>Change the country of delivery ?</b></span>
                                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                                    <span class="tooltip-item2">
                                                                                <span class="fa-stack fa-1x">
                                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                </span>
                                                                    </span>
                                                                    <span style="bottom: 23px; left: 25px; width: 400px;" class="tooltip-content4 clearfix">
                                                                        <span class="tooltip-text2"><p>Select the country where delivery is needed.</p></span>
                                                                    </span>
                                                                </span>
                                                                <!-- <el-select filterable collapse-tags @change="getCities()" style="width: 100%; padding: 0px;" v-model="FirstStepData.CompanyCountry" placeholder="Select Country"> -->
                                                                    <el-select filterable collapse-tags id="country" class="country" @change="getCities()" style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.Country" placeholder="Select Country">                     
                                                                        <el-option
                                                                            v-for="(item,CompanyCountry) in countries"
                                                                            :key="CompanyCountry"
                                                                            :label="CompanyCountry"
                                                                            :value="CompanyCountry">
                                                                            <span style="float: left">{{ CompanyCountry }}</span>
                                                                        </el-option>

                                                                        
                                                                    </el-select>
                                                            </el-form-item>
                                                        </div>
                                        </el-col>                                   
                                        <el-col :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                            <div class="grid-content">
                                                <el-form-item prop="Cities">
                                                    <span slot="label"><b>Change the city of delivery ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                        <span style="bottom: 23px; left: 25px; width: 400px;" class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">Select the city where delivery is needed.</span>
                                                        </span>
                                                    </span>
                                                    <!-- <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="FirstStepData.companyCity" placeholder="Select City"> -->
                                                    <el-select filterable collapse-tags style="width: 100%; padding: 0px;" class="city" id="city" v-model="PurchaseEnquiryLine.Cities" placeholder="Select City" @change="checkCityRC($event)">
                                                        
                                                        <el-option
                                                            v-for="(item,companyCity) in cities"
                                                            :key="companyCity"
                                                            :value="item"
                                                            :label="item">
                                                            <span style="float: left">{{ item }}</span>
                                                        </el-option>
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp">
                                                <span slot="label"><b>Amend the delivery location address on the map ?</b></span>
                                                <span style="z-index: 1" class="mytooltip tooltip-effect-1">
                                                        <span class="tooltip-item2">
                                                            <span class="fa-stack fa-1x">
                                                                <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                            </span>
                                                        </span>
                                                    <span style="bottom: 23px; left: -195px; width: 500px;" class="tooltip-content4 clearfix">
                                                        <span class="tooltip-text2">
                                                            You may use Google Map and drop a marker in the amended delivery location.                                            
                                                        </span>
                                                    </span>
                                                </span>

                                                <button v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" type="button" data-toggle="modal" id="get_map" data-target="#DeliveryLocation" class="btn btn-primary btn-block waves-effect text-center" 
                                                    style="background-color: rgb(64, 64, 64); color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4); transition: all 0.3s ease; display: block; width: 100%; text-align: center; padding: 10px 0; border: none; cursor: pointer;" 
                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                    Identify a new delivery location with a marker ?
                                                </button>
                                            </el-form-item>
                                        </el-col> 
                                        <el-col :span="24" v-if="(PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.Cities.length > 0) || (PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.Cities.length > 0)">
                                            <br style="margin-top: 10px; margin-bottom: -2px;">
                                            <div style="display: flex; align-items: center; justify-content: center; position: relative; text-align: center; color: rgb(64, 64, 64); font-size: 16px; margin: 0;">
                                                <hr style="border: 1px solid rgb(64, 64, 64); width: 100%; margin: 0;">
                                                <div style="position: absolute; background: #fff; padding: 0 10px; color: rgb(64, 64, 64);">
                                                    <b>OR</b>
                                                </div>
                                            </div>
                                            <br style="margin-top: 10px; margin-bottom: -2px;">
                                        </el-col>
                                        <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="Longitude">
                                                <span slot="label"><b>Change the delivery location longitude ?</b></span>
                                                <el-input type="number" readonly  placeholder="Enter the delivery location longitude or drop a mraker on the map" v-model="PurchaseEnquiryLine.Longitude"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col  :span="12" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="Latitude">
                                                <span slot="label"><b>Change the delivery location latitude ?</b></span>
                                                <el-input type="number"  readonly placeholder="Enter the delivery location latitude or drop a mraker on the map" v-model="PurchaseEnquiryLine.Latitude"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <el-form-item label="temp" prop="LocationName">
                                                <span slot="label"><b>Amend the delivery location name ?</b></span>
                                                <el-input type="text" placeholder="Enter Location Name" v-model="PurchaseEnquiryLine.LocationName"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <!-- Delivery Location Selection -->

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'LibrariesNFreeText'">

                                            <div>
                                                <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                                    <span slot="label"><b>Is the item selected from the <i>Library of Materials</i> ?</b></span>

                                                    <el-select disabled @change="EnquiryItemDescriptionSelection" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.EnquiryFromItemMaster" placeholder="Do You Want To Select An Item From The Library of Materials ?">
                                                            
                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>

                                            <div v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'">
                                                <el-form-item label="temp" prop="ItemNumber">
                                                    <span slot="label"><b>The <i>Library of Materials</i> item:</b></span>
                                                    <el-select style="width: 100%"
                                                        popper-class="itemSelect"
                                                        v-model="PurchaseEnquiryLine.ItemNumber"
                                                        @change="checkRateContract()"
                                                        filterable
                                                        remote
                                                        disabled
                                                        :remote-method="searchItems"
                                                        :loading="searchItemsLoading"
                                                        placement="top-start"
                                                        placeholder="Search and Select an Item">
                                                        <el-option style="max-width: 1200px; min-height: 150px"
                                                            v-for="item in FoundItems"                                                           
                                                            :key="item.id"
                                                            :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                            :value="item.id" :selected="item.id == PurchaseEnquiryLine.ItemNumber">

                                                            <div class="row" style="border: 2px solid black">
                                                                <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                                <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                                    
                                                                </div>
                                                                <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                                    <table class="table itemTable">
                                                                        <tr>

                                                                            <td>
                                                                                <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                                <span style=" white-space: normal; line-height: 1.5em;">
                                                                                    <template v-for="index in 20">      
                                                                                        <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                            <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                                        </span>
                                                                                    </template>
                                                                                </span><br><br>
                                                                                <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                            </td>
                                                                        </tr>

                                                                    </table>
                                                                </div>
                                                                <div class="col-sm-3 text-wrap w-100 mr-5" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid">
                                                                    <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                                    <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                                        <tr>
                                                                            <table>
                                                                                <tr>
                                                                                    <th><b class="font-700">Vendor Name</b></th>
                                                                                    <th><b class="font-700">Vendor Score</b></th>
                                                                                    <th><b class="font-700">Expiry Date</b></th>
                                                                                    <th><b class="font-700">Unit Rate</b></th>
                                                                                    <th><b class="font-700">Lead Time</b></th>
                                                                                </tr>
                                                                                <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                                    <td>{{ item.vendor_name }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.date }}</td>
                                                                                    <td>{{ item.unit_rate }} USD </td>
                                                                                    <td>{{ item.lead_time }}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid"">
                                                                    <h4 class="text-center">Last Transactions Info</h4>
                                                                    <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                                        <tr>
                                                                            <table>
                                                                                <tr>
                                                                                    <th><b class="font-700">Unit Rate</b></th>
                                                                                    <th><b class="font-700"><i>Account</i> Name</b></th>
                                                                                    <th><b class="font-700">City</b></th>
                                                                                    <th><b class="font-700">Date</b></th>
                                                                                    <th><b class="font-700">Quantity</b></th>
                                                                                    <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                                </tr>
                                                                                <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                                    <td>{{ item.unit_rate }}</td>
                                                                                    <td>{{ item.concern }}</td>
                                                                                    <td>{{ item.city }}</td>
                                                                                    <td>{{ item.date }}</td>
                                                                                    <td>{{ item.quantity }}</td>
                                                                                    <td>{{ item.pobid }}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            
                                                        </el-option>
                                                    </el-select>

                                                </el-form-item>
                                            </div>

                                        

                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                                <el-form-item label="temp" prop="ItemDescription">
                                                    <span slot="label"><b>Amend the item description ?</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter a detailed description of the item required."
                                                        v-model="PurchaseEnquiryLine.ItemDescription">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">
                                                <el-row :gutter="20">
                                                    <el-col :span="12">
                                                        <div class="grid-content">
                                                            <el-upload
                                                                style="width:654px;hight:270px;"
                                                                action="#"
                                                                list-type="picture-card"
                                                                :limit="4"
                                                                id='Images'
                                                                :auto-upload="false"
                                                                ref="Images"
                                                                accept=".jpg, .jpeg, .png"
                                                                :on-change="handleChange"
                                                                :on-exceed="handleExceed"
                                                                :on-remove="handleRemove">
                                                                <i class="el-icon-plus"></i>
                                                            </el-upload>
                                                            <span>You can upload 4 Images maximum size of 1 MB.</span>
                                                            
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>


                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryFromItemMaster == 'No'">

                                                <el-form-item label="temp" prop="UnitOfMeasurement">
                                                    <span slot="label"><b>Amend the item Unit of Measure ?</b></span>

                                                    <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                                </el-form-item>

                                            </div>

                                        </el-col>

                                        <el-col :span="24" v-else-if="this.CompanySetup.lom_manditory == 'Libraries'">
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="ItemNumber">
                                                    <span slot="label"><b>Select The Item From The <i>Library of Materials</i></b></span>
                                                    <el-select style="width: 100%"
                                                        popper-class="itemSelect"
                                                        v-model="PurchaseEnquiryLine.ItemNumber"
                                                        @change="checkRateContract()"
                                                        filterable
                                                        remote
                                                        disabled
                                                        :remote-method="searchItems"
                                                        :loading="searchItemsLoading"
                                                        placement="top-start"
                                                        placeholder="Search and Select an Item">
                                                        <el-option style="max-width: 1200px; min-height: 150px"
                                                            v-for="item in FoundItems"
                                                            :id="item.id"
                                                            :selected="PurchaseEnquiryLine.ItemNumber"
                                                            :key="item.id"
                                                            :label="'LoM-'+item.item_group_id+'-'+item.item_ingroup_id+': '+item.description"
                                                            :value="item.id" >

                                                            <div class="row" style="border: 2px solid black">
                                                                <div class="col-sm-1 b-r" style="padding-left: 0px; padding-top: 5px; padding-bottom: 0px; padding-right: 0px;" >
                                                                <img  class="img-thumbnail vertical-middle" :src="hostName+'/uploads/ItemMasterPictures/'+item.picture">
                                                                    
                                                                </div>
                                                                <div class="col-sm-3 b-r" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; line-height: 10px; z-index: 1" >
                                                                    <table class="table itemTable">
                                                                        <tr>

                                                                            <td>
                                                                                <span><b class="font-700">LoM-{{ item.item_group_id }}-{{ item.item_ingroup_id }}</b></span><br>
                                                                                <span style=" white-space: normal; line-height: 1.5em;">
                                                                                    <template v-for="index in 20">      
                                                                                        <span v-if="item.item_template['field_'+index] !== 'Ogeo-None' && item['field_'+index] !== ''">
                                                                                            <b style="text-transform: uppercase; font-weight: 700;">{{ item.item_template['field_'+index] }}:</b> {{ item['field_'+index] }} | 
                                                                                        </span>
                                                                                    </template>
                                                                                </span><br><br>
                                                                                <span><b class="font-700 text-uppercase">Unit of Measure:</b> {{ item.u_o_m.toUpperCase() }}</span><br>
                                                                            </td>
                                                                        </tr>

                                                                    </table>
                                                                </div>
                                                                <div class="col-sm-3 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; margin-right: 5%; word-wrap: break-word; border-left: 2px solid black">
                                                                    <h4 class="text-center" style="margin-right:-20%;">Valid Rates’ Contracts</h4>
                                                                    <table class="table itemTable lomitemtable" style="border-top: 2px solid">
                                                                        <tr>
                                                                            <table>
                                                                                <tr>
                                                                                    <th><b class="font-700">Vendor Name</b></th>
                                                                                    <th><b class="font-700">Vendor Score</b></th>
                                                                                    <th><b class="font-700">Expiry Date</b></th>
                                                                                    <th><b class="font-700">Unit Rate</b></th>
                                                                                    <th><b class="font-700">Lead Time</b></th>
                                                                                </tr>
                                                                                <tr v-for="(item, index) in RateContractSelection" :key="index">
                                                                                    <td>{{ item.vendor_name }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.date }}</td>
                                                                                    <td>{{ item.unit_rate }} USD </td>
                                                                                    <td>{{ item.lead_time }}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div class="col-sm-4 text-wrap b-l" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border-left: 2px solid black">
                                                                    <h4 class="text-center">Last Transactions Info</h4>
                                                                    <table class="table itemTable lomitemtable text-center" style="border-top: 2px solid">
                                                                        <tr>
                                                                            <table>
                                                                                <tr>
                                                                                    <th><b class="font-700">Unit Rate</b></th>
                                                                                    <th><b class="font-700"><i>Account</i> Name</b></th>
                                                                                    <th><b class="font-700">City</b></th>
                                                                                    <th><b class="font-700">Date</b></th>
                                                                                    <th><b class="font-700">Quantity</b></th>
                                                                                    <th><b class="font-700">Purchase Order or Bid</b></th>
                                                                                </tr>
                                                                                <tr v-for="(item, index) in LastPurchase" :key="index">
                                                                                    <td>{{ item.unit_rate }}</td>
                                                                                    <td>{{ item.concern }}</td>
                                                                                    <td>{{ item.city }}</td>
                                                                                    <td>{{ item.date }}</td>
                                                                                    <td>{{ item.quantity }}</td>
                                                                                    <td>{{ item.pobid }}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            
                                                        </el-option>
                                                    </el-select>
                                        
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        


                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && this.CompanySetup.lom_manditory == 'FreeText'" >
                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="ItemDescription">
                                                    <span slot="label"><b>Enter Material Description</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter a detailed description of the item."
                                                        v-model="PurchaseEnquiryLine.ItemDescription">
                                                    </el-input>
                                                </el-form-item>
                                                <!-- <span style="margin-top: -20px;" class="float-right">50/{{PurchaseEnquiryLine.ItemDescription.length}}</span> -->
                                            </div>

                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="UnitOfMeasurement">
                                                    <span slot="label"><b>Enter Unit of Measure</b></span>

                                                    <el-input v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="e.g. Dozen, Kilogram, Liters, Boxes of 24 Bottles..."></el-input>

                                                    <!-- <el-select filterable allow-create style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.UnitOfMeasurement" placeholder="Enter Unit of Measure">
                                                            
                                                        <el-option label="Metre" value="Metre"></el-option>
                                                        <el-option label="Millimeter" value="Millimeter"></el-option>
                                                        <el-option label="Centimeter" value="Centimeter"></el-option>
                                                        <el-option label="Decimeter" value="Decimeter"></el-option>
                                                        <el-option label="Kilometer" value="Kilometer"></el-option>
                                                        <el-option label="Cup" value="Cup"></el-option>
                                                        <el-option label="Gill" value="Gill"></el-option>
                                                        <el-option label="Pint" value="Pint"></el-option>
                                                        <el-option label="Grams" value="Grams"></el-option>
                                                        <el-option label="Kilogram" value="Kilogram"></el-option>
                                                        <el-option label="Grain" value="Grain"></el-option>
                                                        <el-option label="Dram" value="Dram"></el-option>
                                                        <el-option label="Ounce" value="Ounce"></el-option>
                                                        <el-option label="Pound" value="Pound"></el-option>
                                                        <el-option label="Dozen" value="Dozen"></el-option>
                                                            
                                                    </el-select> -->
                                                </el-form-item>

                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" >
                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="Quantity">
                                                    <span slot="label"><b>Amend the needed quantity ?</b></span>
                                                    <el-input min='0.0001' type="number" placeholder="Enter Item Quantity" v-model="PurchaseEnquiryLine.Quantity">
                                                    </el-input>
                                                </el-form-item>

                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24"  v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'">
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b>Amend the list of documents required from the vendors ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                            <span class="tooltip-item2">
                                                                                    <span class="fa-stack fa-1x">
                                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                                    </span>
                                                                            </span>
                                                                    <span style="bottom: 23px; left: -240px; width: 717px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>These documents could be as diverse as spreadsheets which the vendors must complete and submit along with their <i>Vendor Proposal</i>, if required.</p>                                                </span>
                                                                    </span>
                                                                </span>

                                                    <el-select filterable collapse-tags multiple style="width: 100%; padding: 0px;" v-model="ReqDocTmp" placeholder="Select Required Document" id="reqDocs">
                                                        
                                                        <el-option label="Customers Testimonials" value="Customers Testimonials"></el-option> 
                                                        <el-option label="Country of Origin Certificate" value="Country of Origin Certificate"></el-option> 
                                                        <el-option label="Liability Insurance Certificate" value="Liability Insurance Certificate"></el-option> 
                                                        <el-option label="Material Catalogue" value="Material Catalogue"></el-option> 
                                                        <el-option label="MSDS - Material Safety Data Sheet" value="MSDS - Material Safety Data Sheet"></el-option> 
                                                        <el-option label="Testing Certificate" value="Testing Certificate"></el-option> 
                                                        <el-option label="Warranty Certificate" value="Warranty Certificate"></el-option> 
                                                        <el-option label="Other Documents" value="Other"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            
                                        </el-col>
                                        <el-col :span="24" v-if="ReqDocTmp.includes('Other')" >
                                            <div class="grid-content">
                                                <el-form-item label="temp">
                                                    <span slot="label"><b></b></span>
                                                    <table class="table-table table table-striped thead-inverse dataex-res-configuration bg-white" id="otDocsF">
                                                        <thead class="text-white bg-dark w-100">
                                                            <tr style="width: 100% !important;">
                                                                <th><span style="color:white;"></span>Other Documents Required From The Vendors
                                                                    <!-- <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                        <span class="tooltip-item2">
                                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                                            
                                                                        </span>
                                                                        <span class="tooltip-content4 clearfix" style="left: 150px;">
                                                                            <span class="tooltip-text2">
                                                                                These headers help better describe the required Services. They can include Scope of Works, Activities, 
                                                                                Inclusions, Exclusions, SLAs (Service Level Agreements), KPIs (Key Performance Indicators), Penalty Matrixes, 
                                                                                Manpower Skills, Quantities, Invoicing Instructions, Termination & Suspension Conditions, Force Majeure, IP 
                                                                                rights, Litigation...
                                                                            </span>
                                                                        </span>
                                                                    </span> -->
                                                                </th>
                                                                
                                                                <th class="text-center" width="70"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in OtherDocTmpHolder"
                                                                :key="index">
                                                                <td>
                                                                    <input v-model="item.name" class="form-control">
                                                                </td>
                                                                <td class="text-center">
                                                                    <button style="padding: 0px 13px" type="button" @click="delOtherDocs(index)" 
                                                                        class="btn-pointer btn-danger btn-floating"
                                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <input class="form-control" v-model="OtherDocTmp" placeholder="Please enter the name(s) of the required documents" maxlength="200">
                                                                </td>
                                                                <td class="text-center my-auto" width="10%">
                                                                    <button v-if="OtherDocTmp" style="height: 40px; width: 40px; margin: 0; margin-top: -5px; padding: 0;" @click.prevent="addOtherDocs()" 
                                                                        class="btn-pointer btn-success btn-floating"
                                                                        onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                                        onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                                        <i class="fa fa-plus"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </el-form-item>
                                            </div>

                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content" v-if="this.showQuestionRateMaterial == true">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="EnquiryFromItemMaster">
                                                    <span slot="label"><b>This item from the Library of Materials has (the number of existing, valid, Purchase Enquiry) Purchase Enquiry(s) attached to it. Do you want to select one of them (if there are many) it (if there is only one) to reduce the sourcing turnaround time ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                A Rate Contract is a framework agreement signed with a Vendor, for a list of materials, which freezes both Unit Rates' & Lead Times over a given period of time. The use of Purchase Enquiry helps reduce turnaround times since a validated Purchase Enquiry line, linked to a Rate Contract, can reasdily be turned into a Purcase Order, without the need to source proposals.                                            
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <el-select @change="ShowPopUpIgnoreRateContract" filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ShowPopUpIgnoreRateContract" placeholder="Do You Want To Select An Item From The Library of Materials ?">
                                                            
                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>

                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' && PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes' && PurchaseEnquiryLine.ItemNumber && checkCity">

                                                <el-form-item label="temp">
                                                <span slot="label"><b>Select From Existing Purchase Enquiry</b></span>
                                                <span style="z-index: 1; margin-right: 15px;" class="mytooltip tooltip-effect-2">
                                                        <span class="tooltip-item2">
                                                            <span class="text-success"><span class="fa fa-info-circle fa-lg text-success"></span></span>
                                                            
                                                        </span>
                                                        <span class="tooltip-content4 clearfix">
                                                            <span class="tooltip-text2">
                                                                Materials Rates’ Contracts 
                                                                are pre-negotiated agreements with vendors intended to 
                                                                freeze unit prices and lead times for items extracted from the 
                                                                Library of Materials, before the demand for them actually 
                                                                arises, so as to reduce cost and workload (Purchase Enquiries 
                                                                linked to Rates’ Contracts can be directly turned into Purchase 
                                                                Orders).
                                                            </span>
                                                        </span>
                                                    </span>
                                                <input type="checkbox" id="rtcheckbox" true-value="yes" false-value="no" @click="ResetRateContractDropdown()" v-model="PurchaseEnquiryLine.rtYN">
                                                <label for="rtcheckbox" :style="PurchaseEnquiryLine.rtYN == 'yes' ? 'color: red': 'color: black'">I'll proceed without a Purchase Enquiry</label>
                                                    <el-select  v-if="PurchaseEnquiryLine.rtYN === 'no'" style="width: 100%"
                                                        v-model="PurchaseEnquiryLine.RateContractSelected"
                                                        @change="checkLowestRateContract()"
                                                        placeholder="Select Rate Contract">
                                                        <el-option style="max-width: 960px; min-height: 150px"
                                                            v-for="item in RateContractSelection"
                                                            :key="item.value"
                                                            :label="'Vendor Name: '+item.vendor_name+' | Vendor Score: '+item.vendor_score+' | Unit Rate: '+item.unit_rate+ 'USD | Lead Time: ' +item.lead_time+' | Rates’ Contract Reference: '+item.rate_contract_reference+' | Rate Contract Expiry Date: '+item.date"
                                                            :value="item" v-if="item.city == PurchaseEnquiryLine.Cities">
                                                            <div class="row" >
                                                                <div class="col-sm-12 text-wrap w-100" style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px; padding-right: 0px; word-wrap: break-word; border: 2px solid">
                                                                    <table class="table itemTable lomitemtable mx-auto w-100" style="margin-left:auto;margin-right:auto;">
                                                                        <tr>
                                                                            <table style="margin-left:auto;margin-right:auto;">
                                                                                <tr>
                                                                                    <th><b class="font-700">Vendor Name</b></th>
                                                                                    <th><b class="font-700">Vendor Score</b></th>
                                                                                    <th><b class="font-700">Expiry Date</b></th>
                                                                                    <th><b class="font-700">Unit Rate</b></th>
                                                                                    <th><b class="font-700">Lead Time</b></th>
                                                                                    <th><b class="font-700">Volume To Trigger Discount</b></th>
                                                                                    <th><b class="font-700">Discount Rate</b></th>
                                                                                    <th><b class="font-700">Total Procured So Far</b></th>
                                                                                    <th><b class="font-700"><i>Advance Payment</i> Arrangement</b></th>
                                                                                    <th><b class="font-700"><i>Retention</i> Arrangement</b></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ item.vendor_name }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.date }}</td>
                                                                                    <td>{{ item.unit_rate }} USD </td>
                                                                                    <td>{{ item.lead_time }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                    <td>{{ item.vendor_score }}</td>
                                                                                </tr>
                                                                            </table>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </el-option>
                                                    </el-select>

                                                </el-form-item>
                                                <hr>
                                            </div>
                                            <!-- <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'"><hr></el-col> -->
                                            <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service'"><hr></el-col>
                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'">
                                                <el-form-item label="temp" prop="ServiceOneOff">
                                                    <span slot="label"><b>Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time?</b></span>
                                                    <el-select style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceOneOff" placeholder="Will these Services Be One/Off Or Will They Be Delivered Over A Period Of Time ?">
                                                            
                                                        <el-option label="One/Off" value="One/Off"></el-option>
                                                        <el-option label="Over A Period Of Time" value="Over A Period Of Time"></el-option>
                                                            
                                                    </el-select>

                                                </el-form-item>
                                            </div>
                                            <div class="grid-content" v-if="PurchaseEnquiryLine.EnquiryType == 'Service' && PurchaseEnquiryLine.ServiceOneOff == 'Over A Period Of Time'">
                                                <el-form-item label="temp" prop="ServiceTerm">
                                                    <span slot="label"><b>What Is The Term, In Days, Of The Services?</b></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.ServiceTerm" placeholder="What Is The Term, In Days, Of The Services?">
                                                        
                                                        <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                        
                                                    </el-select>

                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Fixed-price contract' && PurchaseEnquiryLine.EnquiryType == 'Service' " >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please select 1 of the below 3 options">
                                                            
                                                        <el-option label="Total Fixed Price" value="Fixed Price Only"></el-option>
                                                        <el-option label="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials Prices" value="Breakdown of Manpower, Subcontractors, Tools/Equipment & Materials price"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-reimbursement contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please select 1 of the below 3 options">
                                                            
                                                        <el-option label="Total Reimbursement Price" value="Total Reimbursement Price"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices " value="Subcontractors, Tools/Equipment & Materials Prices"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.TypeOfServicesContract == 'Cost-plus fixed fee contract' && PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item style="font-weight: bolder" label="temp" prop="VendorCommercialOffer">
                                                    <span slot="label"><b>Vendor's Commercial Offer Required Format</b></span>
                                                    
                                                    <el-select  style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.VendorCommercialOffer" placeholder="Please select 1 of the below 2 options">
                                                            
                                                        <el-option label="Total Contract Price + Fee (in %)" value="Total Contract Price + Fee (in %)"></el-option>
                                                        <el-option label="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)" value="Subcontractors, Tools/Equipment & Materials Prices + Fee (in %)"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>

                                        <!-- <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="PELineNote">
                                                    <span slot="label"><b>Title</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Title"
                                                        v-model="PurchaseEnquiryLine.Title">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col> -->
                                        <el-col :span=24 v-if="PurchaseEnquiryLine.EnquiryType == 'Service'"><hr></el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="AdvancedPayment">
                                                    <span slot="label"><b>Amend the <i>Advance Payment</i> Arrangement ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                                <span class="tooltip-item2">
                                                                    <span class="fa-stack fa-1x">
                                                                        <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                        <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                    </span>
                                                                </span>
                                                                <span style="bottom: 23px; left: -170px; width: 1700px;" class="tooltip-content4 clearfix">
                                                                    <span class="tooltip-text2">
                                                                        <p>You have the possibility to signal whether or not your 
                                                                        organization is willing to make an <i>Advance Payment</i> to
                                                                        the <i>Awardee Vendor</i>, at the time of the <i>Purchase 
                                                                        Order</i> issuance for this item. </p>
                                                                        <p>If 'Yes' is selected, the vendors will be able to 
                                                                        submit, in their proposal, their desired <i>Advance Payment</i> 
                                                                        requirement, expressed in percentage of their total
                                                                        commercial proposal.</p>
                                                                        <p> When a <i>Materials Rates’ Contract</i> is selected for 
                                                                        this <i>Purchase Enquiry</i> item, the <i>Advance Payments</i> 
                                                                        arrangements are those applicable to the said 
                                                                        <i>Materials Rates’ Contract</i>, as agreed with the 
                                                                        concerned <i>Awardee Vendor</i>.</p>
                                                                        <p>Finally, you can choose whether or not to request collaterals from the <i>Awardee Vendor</i>, 
                                                                        when your organization agrees to the <i>Advance Payment</i>.</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.AdvancedPayment" placeholder="Will You Consider Offering An Advance Payment ?">
                                                            
                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.AdvancedPayment == 'Yes'" >
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="AdvancedPayment">
                                                    <span slot="label"><b>Amend the <i>Advance Payment</i> collateral requirment ?</b></span>

                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.GuaranteeNeedForAdvancePayment" placeholder="Is a collateral needed from the Awardee Vendor against for The Advance Payment?">
                                                            
                                                        <el-option label="Yes" value="Yes"></el-option>
                                                        <el-option label="No" value="No"></el-option>
                                                            
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>
                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">                                    

                                                <el-form-item label="temp" prop="RetentionPercentage">
                                                    <span slot="label"><b>Amend the <i>Retention Percentage</i> ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: 23px; left: -95px; width: 1100px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Percentage</i> is the portion of a payment, expressed as a percentage, that your organization 
                                                                        <u>temporary</u> withholds from the <i>Awardee Vendor</i> for all <i>Purchase Orders</i> issued under this <i>Rates' Contract</i> until the 
                                                                        <i>Retention Timeframe</i> elapses. </p>
                                                                        <p>This retention acts as a security or guarantee to ensure that the <i>Awardee Vendor</i> 
                                                                        fulfills all contractual obligations, including rectifying any defects or deficiencies identified during or after the 
                                                                        delivery of the <i>Materials</i> or <i>Services</i>.</p>
                                                                        <p>The retention will be released back to the <i>Awardee Vendor</i> as soon as the <i>Retention Timeframe</i> elapses.</p> 
                                                                        <p>When a <i>Materials Rates’ Contract</i> is selected for the item, the 
                                                                    retention arrangements are those applicable to the said <i>Materials Rates’ 
                                                                    Contract</i>, as agreed with the concerned <i>Awardee Vendor</i>.</p>
                                                                </span>
                                                            </span>
                                                        </span>

                                                    <el-input min='0.0001' max="100" type="number" placeholder="Enter The Retention Percentage " v-model.number="PurchaseEnquiryLine.RetentionPercentage">
                                                    </el-input>
                                                </el-form-item>

                                            </div>
                                        </el-col> 

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.RetentionPercentage != 0 || PurchaseEnquiryLine.RetentionPercentage != ''" >
                                            <div class="grid-content">
                                                <el-form-item label="temp" prop="RetentionDays">
                                                    <span slot="label"><b>Amend the <i>Retention Timeframe</i> ?</b></span>
                                                    <span style="z-index: 1" class="mytooltip tooltip-effect-2">
                                                            <span class="tooltip-item2">
                                                                <span class="fa-stack fa-1x">
                                                                    <i style="color: #FF2F2F" class="fa fa-circle fa-lg fa-stack-1x"></i>
                                                                    <i style="color: white" class="fa fa-info fa-stack-1x"></i> 
                                                                </span>                                                
                                                            </span>
                                                            <!--<span class="tooltip-content4 clearfix">-->
                                                            <span style="bottom: 23px; left: -81px; width: 850px;" class="tooltip-content4 clearfix">
                                                                <span class="tooltip-text2">
                                                                    <p>The <i>Retention Timeframe</i> begins on the date when either:
                                                                                    <div style="margin-left: 40px;">
                                                                                        1. The the complete quantity specified in the <i>Purchase Order</i> is received from the <i>Awardee Vendor</i>; or<br>
                                                                                        2. the <i>Purchase Order</i> is closed.                     
                                                                                    </div>
                                                                    </p>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="PurchaseEnquiryLine.RetentionDays" placeholder="Select Retention Timeframe From The Delivery Date">
                                                        
                                                        <el-option v-for="n in 900" :key="n" :label="n+ ' Days'" :value="n"></el-option> 
                                                        
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </el-col>


                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                                        <el-col :span="24" v-if="PurchaseEnquiryLine.EnquiryType == 'Materials' || PurchaseEnquiryLine.EnquiryType == 'Service'" >
                                            <div class="grid-content">

                                                <el-form-item label="temp" prop="PELineNote">
                                                    <span slot="label"><b>Amend the Additional Notes ?</b></span>
                                                    <el-input
                                                        type="textarea"
                                                        :rows="4"
                                                        placeholder="Enter Purchase Enqiury Line Note."
                                                        v-model="PurchaseEnquiryLine.PELineNote">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </el-col>
                                        <el-col :span="24"><hr v-if="PurchaseEnquiryLine.JobNumber" style="border: 1px solid rgba(252, 51, 23, 0.90);"></el-col>

                                            <button v-if="PurchaseEnquiryLine.EnquiryType == 'Materials'" type="button" @click="SubmitUpdatePurchaseRequest" 
                                                class="btn btn-primary btn-block waves-effect text-center" 
                                                style="background-color: #008000; border-color: #008000; color: white; box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.4);"
                                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                Reattach The Item To The <i>Materials Purchase Enquiry</i> And Resubmit For Validation
                                            </button>


                                    </el-row>
                                </el-form>
                            </div>
                            
                            
                        </div>




                            </div>


                            <div class="col-lg-12">
                                <hr> 
                            </div>

                            <!-- History Information Panel -->
                            <div class="col-lg-12">
                                <h3 style="background-color: black; color: white; padding-left: 10px;" class="italic;">History</h3>   
                                <table class="display table table-bordered">
                                    <thead>
                                        <tr>
                                            <th><b>#</b></th>
                                            <th><b>Action Description</b></th>
                                            <th><b>Action By</b></th>
                                            <th><b>Action Level</b></th>
                                            <th><b>Decision-<i>Note</i></b></th>
                                            <th><b>Action Date</b></th>
                                            <th><b>Timespan Between Action</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Description</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Action By</b></th>
                                            <th style="background-color: rgb(252, 51, 23, 0.15);"><b>Next Action Approval Level</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template>
                                        <tr v-for="(item, key) in EditModalInfo.history" :key="item.id">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ item.current_action_description }}</td>
                                            <td>{{ item.current_action_by.name }}</td>
                                            <td>{{ item.current_approval_level }}</td>
                                            <td><b>{{ item.decision }}</b> - <i>{{ item.decision_notes }}</i></td>
                                            <td>{{ item.created_at }}</td>
                                            <td v-if="key == 0">Not Applicable</td>
                                            <!-- <td v-else>{{ item.created_at }}</td> -->
                                            <td v-else>{{ item.created_at | getDifference(EditModalInfo.history[key-1].created_at) }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_description }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_action_owners }}</td>
                                            <td style="background-color: rgb(252, 51, 23, 0.15);">{{ item.next_approval_level }}</td>
                                            
                                        </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-lg-12">
                            <hr>
                            <div class="d-inline-block">
                                <b>Date:</b> <span style="color: rgb(0, 0, 0);">{{ new Date() }}</span><br>
                                <b>Document Generated By:</b> <span style="color: rgb(0, 0, 0);">{{ currentUser.Name }}</span> 
                            </div>
                            <div class="d-inline-block pull-right">
                                <span><img width="250px" src="/assets/images/poweredby.png" alt=""></span>
                            </div>
                        </div>
                        </div>
                                    
                    </div>
                </div>
            </div>
        </div>
        <div id="RFIModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="RFIModal" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="RFIDataLoaded">
                        <div  class="modal-header">
                            <h4 class="modal-title text-ogeo">Request For Information <span style="color: black">(<span v-if="CompanySetup.rfq_prefix == ''">RfP-</span><span v-else>{{ CompanySetup.rfq_prefix }}-</span>{{ RFIDetails[0].quotation.quotation_group_id }} / <span v-if="CompanySetup.pe_prefix == ''">PE-</span><span v-else>{{ CompanySetup.pe_prefix }}-</span>{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_group_id }}-{{ RFIDetails[0].purchase_enquiry.purchase_enquiry_ingroup_id }})</span></h4>
                            <button type="button" 
                                class="close" data-dismiss="modal" aria-hidden="true"
                                onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                ×
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="chat-rbox" style="border: 1px dashed gray; border-radius: 10px; max-height: 450px; overflow-y: auto;">
                                        <ul class="chat-list">
                                            
                                            <!--chat Row -->
                                            <template v-for="message in RFIDetails">
                                              
                                                <li v-if="message.user_id != currentUser.userId">
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-info" >{{ message.message }}</div>
                                                    </div>
                                                    <div class="chat-time" style="min-width: 10%">{{ message.created_at }}</div>
                                                </li>
                                                <li v-else class="reverse">
                                                    <div class="chat-time">{{ message.created_at }}</div>
                                                    <div class="chat-content">
                                                        <h5>ID# {{ message.id }} - <i v-if="message.reply_id">(In Reponse to ID#: {{ message.reply_id }}) -</i> By {{ message.user_type }} - <span v-if="message.user_type == 'Vendor'">{{ message.vendor_user.name }}</span> <span v-else>{{ message.user.name }}</span></h5>
                                                        <div class="box bg-light-ogeo" style="min-width: 10%">{{ message.message }}</div>
                                                    </div>
                                                </li>

                                            </template>
                                       
                                        </ul>
                                    </div>
                                    <div v-if="!RFIDetails[0].quotation.status.includes('Canceled') || !RFIDetails[0].quotation.status.includes('Ended') || !RFIDetails[0].quotation.status.includes('Submitted')" class="card-body b-t">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="grid-content">
                                                    <label><b>Select Message To Reply</b></label></span>
                                                    <el-select filterable style="width: 100%; padding: 0px;" v-model="RFIData.RFIMessageID" placeholder="Select Message ID">   
                                                        <el-option v-for="message in RFIDetails" :key="message.id" :label="'ID#: '+message.id" :value="message.id"></el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <br>
                                                <el-input
                                                    type="textarea"
                                                    rows="6"
                                                    placeholder="Enter Your Request For Information..."
                                                    v-model="RFIData.RFImessage">
                                                </el-input>
                                            </div>
                                            <div class="col-lg-12">
                                                <br>
                                                <button type="button" 
                                                    class="btn btn-block btn-success" @click="SubmitRFI"
                                                    onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                                                    onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                                                
                                                    Submit Answers to RFI
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-12">
                                    <table ref="RFITable" id="RFIMessagesTable" class="display responsive table table-bordered table-hover" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Communication #</th>
                                                <th>By</th>
                                                <th>User Name</th>
                                                <th>Message</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="message in RFIDetails">
                                                <th>{{ message.id }}</th>
                                                <th>{{ message.user_type }}</th>
                                                <template v-if="message.user_type == 'Vendor'">
                                                    <th>{{ message.vendor_user.name }}</th>
                                                </template>
                                                <template v-else>
                                                    <th>{{ message.user.name }}</th>
                                                </template>
                                                <th>{{ message.message }}</th>
                                                <th>{{ message.created_at }}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- Locate on Map -->
        <div class="modal fade" id="DeliveryLocation" tabindex="-1" aria-labelledby="DeliveryLocation" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Locate with Marker</h4>
                        <button type="button" 
                            class="close" data-dismiss="modal" aria-hidden="true"
                            onmouseover="this.style.transform='translateY(-5px)'; this.style.boxShadow='4px 12px 15px rgba(0, 0, 0, 0.5)';" 
                            onmouseout="this.style.transform=''; this.style.boxShadow='2px 8px 10px rgba(0, 0, 0, 0.4)';">
                            ×
                        </button>
                    </div>

                    <div class="modal-body">
                        <div id="map-canvas"></div>
                        <br>
                        <p class="text-muted">Select The Delivery Location by Dragging and Dropping the Pin on The Map</p>
                    </div>
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-dismiss="modal">Done</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
            
</template>

<script>
    import validate, { isEmpty } from 'validate.js';
    export default {
        name: 'purchase-enquiry-list',
        data(){
            var checkRetentionValue = (rule, value, callback) => {
                if(value == 0){
                    callback();
                    this.PurchaseEnquiryLine.RetentionDays = "";
                } else if (!value) { 
                    return callback(new Error('Please enter the Retention Percentage')); 
                }
                
                if (!Number.isInteger(value)) {
                    callback(new Error('Please only enter numbers'));
                } else {
                    if (value > 100 || value < 0) {
                        callback(new Error('Rentention Amount Should Between 0% and 100%'));
                    } else {
                        callback();
                    }
                }
                
            };
            var checkRetentionDays = (rule, value, callback) => {
                if (!value && this.PurchaseEnquiryLine.RetentionPercentage != '') { 
                    return callback(new Error('Please select the Retention Timeframe')); 
                } else {
                    callback();
                }
            };
            return{
                show_service_table: false,
                show_material_table: true,
                hostName: window.location.protocol+'//'+window.location.hostname,
                DataTable: "",
                CompanySetup: "",
                viewDataLoaded: false,
                editDataLoaded: false,
                JobNumbers: false,
                searchItemsLoading: false,
                EditModalInfo: {},
                ViewModalInfo: {},
                FullyInvoiced: false,
                FoundItems: {},
                RFIDataLoaded: false,
                RFIDetails: [],
                switchbutton: 'Material',
                showAmend: false,
                ReqDocTmp: [],
                OtherDocTmp: '',
                OtherDocTmpHolder: [],
                countries: [],
                cities: [],
                validatorsList: [],
                showQuestionRateMaterial: false,
                checkCity: false,
                RFIData: {
                    RFIMessageID: "",
                    RFImessage: "",
                    VendorId: "",
                    CompanyId: "",
                    QuotationId: "",
                    QuotationType: "",
                    ReplyBy: "Purchase Enquiry Initiator",
                    PEId: "",
                },
                RateContractSelection: [
					{
			          value: 1,
			          vendor_name: 'Gucci',
			          vendor_score: '25',
			          unit_rate: '25',
			          lead_time: '1 Day',
			          rate_contract_reference: 'Gucci',
			          date: '17 june 2022',
			        },
                    {
			          value: 2,
			          vendor_name: 'Versace',
			          vendor_score: '30',
			          unit_rate: '30',
			          lead_time: '2 Days',
			          rate_contract_reference: 'Versace',
			          date: '25 march 2022',
			        },
                    {
			          value: 3,
			          vendor_name: 'Nike',
			          vendor_score: '10',
			          unit_rate: '10',
			          lead_time: '5 Days',
			          rate_contract_reference: 'Nike',
			          date: '09 july 2023',
			        }

				],
                LastPurchase: [
					{
			          value: 1,
			          unit_rate: '25 USD',
			          concern: 'Dubai Main Branch',
			          city: 'Dubai',
			          date: '1 Jan 2019',
			          quantity: '50',
			          pobid: 'Purchase Order',
			        },
					{
			          value: 2,
			          unit_rate: '35 USD',
			          concern: 'Abu Dhabi Branch',
			          city: 'Abu Dhabi',
			          date: '10 Jan 2020',
			          quantity: '150',
			          pobid: 'Purchase Order',
			        }
                  
				],
                TmpEditModalInfo: {},
                PurchaseEnquiryLine: {
                    JobNumber: "",
                    SetupName: "",
                    UnderlyingTransaction: "",
                    SourcingPriority: "Standard",
                    EnquiryType: "",
                    StoreItemrequest: "No",
                    EnquiryFromItemMaster: "",
                    ItemNumber: "",
                    ItemLibraryDescription: "",
                    ItemDescription: "",
                    Quantity: "",
                    Images:[],
                    UnitOfMeasurement: "",
                    AdvancedPayment: "",
                    GuaranteeNeedForAdvancePayment: "",
                    RetentionPercentage: "",
                    RetentionDays: "",
                    RequiredDocuments: [],
                    PELineNote: "",
                    Country: [],
                    Cities: [],
                    Longitude: '',
                    Latitude: '',
                    LocationName: '',
                    ShowPopUpIgnoreRateContract: '',
                    RateContractSelected: {},
                    Title: '',
                    VendorCommercialOffer:'',
                    rtYN: 'no',
                },
                ValidationInfo: {
                    PurchaseEnquiryId: "",
                    RetentionDays: "",
                },
                rules: {
                    RetentionDays: [{
                        required: true,
                        message: "Please select the Retention Timeframe",
                        trigger: "blur"
                    }]
                },
                PeEditRules: {
                    JobNumber: [{
                        required: true, message: "Please select an Account", trigger: ["blur"]
                    }],
                    UnderlyingTransaction: [{
                        required: false,
                        message: "Please enter the underlying transaction reference",
                        trigger: "blur"
                    }],
                    SourcingPriority: [{
                        required: false,
                        message: "Please select the sourcing priority",
                        trigger: "blur"
                    }],
                    EnquiryType: [{
                        required: true,
                        message: "Please select the Purchase Enquiry type",
                        trigger: ["blur"]
                    }],
                    ServiceDescription: [{
                        required: true,
                        message: "Please Enter the Services short description",
                        trigger: "blur"
                    }],
                    ItemNumber: [{
                        required: false,
                        message: "Please enter the item description",
                        trigger: "blur"
                    }],
                    ItemDescription: [{
                        required: false,
                        message: "Please select an item",
                        trigger: "blur"
                    }],
                    Quantity: [{
                        required: true,
                        message: "Please enter the quantity",
                        trigger: "blur"
                    }],
                    UnitOfMeasurement: [{
                        required: true,
                        message: "Please enter the Unit of Measure",
                        trigger: ["blur"]
                    }],
                    AdvancedPayment: [{
                        required: true,
                        message: "Please select the Advance Payment Arrangement",
                        trigger: "blur"
                    }],
                    RetentionPercentage: [{validator: checkRetentionValue, trigger: 'blur'}],
                    RetentionDays: [{validator: checkRetentionDays, trigger: 'blur'}],
                    RequiredDocuments: [{
                        required: false,
                        message: "Please select a document.",
                        trigger: "blur"
                    }],
                    Longitude: [{
                        required: true,
                        message: "Please select the delivery location.",
                        trigger: "blur"
                    }],
                    Latitude: [{
                        required: true,
                        message: "Please enter the delivery Location.",
                        trigger: "blur"
                    }],
                    LocationName: [{
                        required: true,
                        message: "Please enter the location name.",
                        trigger: "blur"
                    }]
                },
                ValidationInfoApproval: {
                    PurchaseEnquiryId: "",
                    Decision: "",
                    DecisionNotes: "",
                },
                ApprovalRules: {
                    Decision: [{
                        required: true,
                        message: "Please Approve or Reject",
                        trigger: "blur"
                    }],
                    DecisionNotes: [{
                        required: false,    
                        message: "Decision Notes are required in case of rejection.",
                        trigger: ["blur", "change"]
                    }],
                },
            }
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            }
        },
        methods: {
            // changeTable(){
            //     if(this.switchbutton == 'Service')
            //         this.$router.push('/app/purchase_enquiry/service-list')
            //     else
            //         this.$router.push('/app/purchase_enquiry/list')
            // },
            checkArray(data)
            {
                return Array.isArray(data);
            },
            isJSON(text){
                if (typeof text!=="string"){
                    return false;
                }
                try{
                    var json = JSON.parse(text);
                    return (typeof json === 'object');
                }
                catch (error){
                    return false;
                }
            },
            ShowPopUpIgnoreRateContract()
            {
                if(this.PurchaseEnquiryLine.ShowPopUpIgnoreRateContract == "No")
                {
                    Swal('Warning', 'Please Note That You Have Ignored A Rates’ Contracts With A Lower Unit Rate.' , 'error'); 
                    $(".swal2-tital").css('background-color', '#000');
                }
            },
            checkRateContract()
            {
                event.preventDefault();

                axios.post('/api/data/check_item_rate_contract_details', this.PurchaseEnquiryLine.ItemNumber)
                    .then((response) => {
                        if(response.data)
                        {
                            this.showQuestionRateMaterial = false
                        }
                        else
                        {
                            this.showQuestionRateMaterial = true
                        }
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            checkLowestRateContract()
            {

                var numbers = [25, 30, 10];
							
                var sorted = numbers.slice().sort(function(a, b) {
                return a - b;
                });

                var smallest = sorted[0],                      
                    secondSmallest = sorted[1],                
                    secondLargest = sorted[sorted.length - 2], 
                    largest  = sorted[sorted.length - 1];
                if(this.PurchaseEnquiryLine.RateContractSelected.unit_rate > smallest)
                {
                    Swal('Warning', 'Please Note That You Have Ignored A Rates’ Contract With A Lower Unit Rate.' , 'error'); 
                }

            },
            checkCityRC(e){

            this.checkCity = false;
            this.RateContractSelection.forEach(item => {
                if(item.city == e)
                    this.checkCity = true;
            })
            },
            getCountries() {
               axios.get('/data/countries.json')
                    .then((res) => {
                        this.countries = res.data
                    })
                    .catch((err) => {
                    })
            },
           
			getCities() {
                this.cities = [];
                if(this.PurchaseEnquiryLine.EnquiryType == 'Materials')
                    this.PurchaseEnquiryLine.Cities = [];
                if ((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null) && Array.isArray(this.PurchaseEnquiryLine.Country)){
                    this.PurchaseEnquiryLine.Country.forEach((item) => {
                        this.cities.push(...this.countries[item])
                    });
                }
				else if((this.PurchaseEnquiryLine.Country != '' || this.PurchaseEnquiryLine.Country != null)){
                  this.cities = this.countries[this.PurchaseEnquiryLine.Country];
               }
                else {
                    this.cities = [];
                }
            },

            loadCities(){
                this.cities = this.countries[this.PurchaseEnquiryLine.Country];
            },
            addOtherDocs() {
                if (this.existOtherDocs() == true) {
                    this.OtherDocTmpHolder.push({name: this.OtherDocTmp})
                    this.OtherDocTmp = ''
                } else {
                    Swal('Error', 'This Document Already Exists.' , 'error'); 
                }
            },
            existOtherDocs() {
                if (this.OtherDocTmpHolder.find(item => item.name === this.OtherDocTmp)) {
                    return false;
                } else {
                    return true;
                }
            },
            delOtherDocs(index) {
                this.$delete(this.OtherDocTmpHolder, index);
            },
            DecisionApprove(){
                let self = this;
                this.ValidationInfoApproval.Decision = "Approved";
                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                        self.ValidationInfoApproval.Decision = "";
                        self.ValidationInfoApproval.DecisionNotes = "";

                        self.DataTable.ajax.reload( null, false );

                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Purchase Request Approval)', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                this.ApprovalRules.DecisionNotes[0].required = false;
                
                

            },
            DecisionReject(){
                let self = this;
                this.ApprovalRules.DecisionNotes[0].required = true;
                this.ValidationInfoApproval.Decision = "Rejected";
                this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                    if(validation){
                        axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                self.ValidationInfoApproval.Decision = "";
                                self.ValidationInfoApproval.DecisionNotes = "";

                                self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Purchase Request Approval)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                        this.ApprovalRules.DecisionNotes[0].required = false;

                        

                    }
                })
            },
            DecisionRejectPermanent(){
                Swal({
                    type: "warning", 
                    title: "Are You Sure You Want to Permanently Cancel This Purchase Enquiry Line ?", 

                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#f10623",
                    confirmButtonColor: "#008000",
                })
                .then(response => {
                    if(response.value) {
                        let self = this;
                        this.ApprovalRules.DecisionNotes[0].required = true;
                        this.ValidationInfoApproval.Decision = "RejectedPermanent";
                        this.$refs.PurchaseEnquiryApprovalForm.validate((validation) => {
                            if(validation){
                                axios.post('/api/purchase_enquiry/validate_purchase_enquiry_creation', this.ValidationInfoApproval)
                                    .then(function(response){
                                        Swal({ 
                                            type: response.data.messageType, 
                                            title: response.data.messageTitle, 
                                            text: response.data.message,
                                            showConfirmButton: true,
                                            timer: 10000
                                        });

                                        self.ValidationInfoApproval.PurchaseEnquiryId = "";
                                        self.ValidationInfoApproval.Decision = "";
                                        self.ValidationInfoApproval.DecisionNotes = "";

                                        self.DataTable.ajax.reload( null, false );

                                    })
                                    .catch(function(){
                                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Purchase Request Approval)', 'error');
                                    });

                                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                                this.ApprovalRules.DecisionNotes[0].required = false;

                                

                            }
                        })
                    }
                })
            },
            CancelPurchaseEnquiry(){
                let self = this;
                axios.post( '/api/purchase_enquiry/cancel_purchase_enquiry_request', {PurchaseEnquiryId: this.EditModalInfo.id})
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 5000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem', 'error');
                    });

                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

            },
            SubmitUpdatePurchaseRequest() {

                let self = this;
                if (event) event.preventDefault();

                if ( Object.entries(this.PurchaseEnquiryLine).toString() !== Object.entries(this.TmpEditModalInfo).toString()){
                    Swal({
                        type: 'warning',
                        title: 'Are You Sure You Want To Proceed With These Amendments ?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#f10623",
                        confirmButtonColor: "#008000",
                    }).then((response) => {
                        if(response.value)
                            this.SubmitChanges();
                    });
                }
                else{
                    Swal({
                        type: 'warning',
                        title: 'No Information Has been Amended. Are You Sure You Want To Proceed ?',

                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: "#f10623",
                        confirmButtonColor: "#008000",
                    }).then((confirm) => {
                        if(confirm.value)
                            this.SubmitChanges();
                    });
                }


            },
            SubmitChanges(){
                let self = this;
                this.PurchaseEnquiryLine.RequiredDocuments = [];
                this.ReqDocTmp.map(item => {
                            if(item !== 'Other')
                                this.PurchaseEnquiryLine.RequiredDocuments.push(item)
                                })
                this.OtherDocTmpHolder.map(item => {
                        this.PurchaseEnquiryLine.RequiredDocuments.push(item.name)
                        })
                
                this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project_id;

                axios.post('/api/purchase_enquiry/update_purchase_enquiry_request', this.PurchaseEnquiryLine)
                    .then(function(response){
                        Swal({ 
                            type: response.data.messageType, 
                            title: response.data.messageTitle, 
                            text: response.data.message,
                            showConfirmButton: true,
                            timer: 10000
                        });

                        self.DataTable.ajax.reload( null, false );
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Purchase Enquiry Resubmission)', 'error');
                    });


                    $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');
                    
            },
            searchItems(value) {
                this.ItemRateContractDetails = [];
                this.RateContractDataLoaded = false;
                this.PurchaseEnquiryLine.ItemNumber = "";
                this.searchItemsLoading = true;
                axios.post('/api/data/get_search_items_lom', {searchQuery: value, isActive: 'inactivate_item', project_id: this.EditModalInfo.project_id})
                    .then((response) => {
                        this.FoundItems = response.data;
                        this.searchItemsLoading = false;
                        //console.log(this.FoundItems);
                    })
            },
            showRateContract(ItemId){

                event.preventDefault();

                axios.post('/api/data/get_item_rate_contract_details', {ItemId: ItemId})
                    .then((response) => {
                        this.ItemRateContractDetails = response.data;
                        this.RateContractDataLoaded = true;
                    })
                    .catch(function(){
                        Swal('Error Occured', 'A technical error has occured, please contact the system administrator to solve the problem (Getting Item Rate Contract Details)', 'error');
                    });

            },
            loadValidations(d) {
                this.validatorsList = [];
                d.validators_list.forEach((item, index) => {
                    var flag = false
                    // d.history.forEach((his) => {
                    //     if(item == his.current_action_by.name && his.decision != 'N/A' && his.current_approval_level > 0){
                    //         this.validatorsList.push({name: item, date: his.created_at, decision: his.decision, level: d.validators_list_levels[index]})
                    //         flag = true;
                    //     }
                    // })
                    if(flag == false)
                        this.validatorsList.push({name: item, date: '', decision:'', level: d.validators_list_levels[index]})
                })
                
            },
            showViewModal(elquentClass, recordId){
                this.ViewModalInfo = {};
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.ViewModalInfo = response.data;
                        if(this.ViewModalInfo.expected_price)
                            this.RateContractSelection.forEach(item => {
                                if(item.unit_rate * this.ViewModalInfo.quantity == this.ViewModalInfo.expected_price)
                                    this.ViewModalInfo.RateContractSelected = item;

                            });
                        this.loadValidations(response.data)
                        this.viewDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalView').modal('toggle');
                    });
                this.viewDataLoaded = false;
            },
            showEditModal(elquentClass, recordId){
                this.EditModalInfo = {};
                this.showAmend = false;
                axios.post('/api/data/get_purchase_request_record_details', [elquentClass, recordId])
                    .then((response) => {
                        this.EditModalInfo = response.data;
                        if(this.EditModalInfo.expected_price)
                            this.RateContractSelection.forEach(item => {
                                if(item.unit_rate * this.EditModalInfo.quantity == this.EditModalInfo.expected_price)
                                    this.EditModalInfo.RateContractSelected = item;

                            });
                            
                        this.ValidationInfo.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfoApproval.PurchaseEnquiryId = this.EditModalInfo.id;
                        this.ValidationInfo.RetentionDays = this.EditModalInfo.retention_days;

                        /* Check if Fully Invoiced So User Cannot Adjust The Retention Date (Known Bugs if the vendor invoices the client partially on the Retention Money */
                        if(this.EditModalInfo.invoicing_status == 'Invoicing Completed'){
                            this.FullyInvoiced = true;
                        }

                        /* Updating Edit Model Information */
                        if(this.EditModalInfo.status.indexOf('Rejected') !== -1){
                            this.PurchaseEnquiryLine.ID = this.EditModalInfo.id;
                            this.PurchaseEnquiryLine.JobNumber = this.EditModalInfo.project.title;
                            this.PurchaseEnquiryLine.UnderlyingTransaction = this.EditModalInfo.underlying_transaction;
                            this.PurchaseEnquiryLine.SourcingPriority = this.EditModalInfo.sourcing_priority;
                            this.PurchaseEnquiryLine.EnquiryType = this.EditModalInfo.enquiry_type;
                            this.PurchaseEnquiryLine.Country = this.EditModalInfo.country_of_delivery;
                            this.PurchaseEnquiryLine.Cities = this.EditModalInfo.cities_of_delivery;
                            this.PurchaseEnquiryLine.EnquiryFromItemMaster = this.EditModalInfo.enquiry_from_item_master;
                            this.searchItems();
                            this.PurchaseEnquiryLine.ItemNumber = this.EditModalInfo.item_id;
                            this.PurchaseEnquiryLine.ItemDescription = this.EditModalInfo.item_description;
                            this.PurchaseEnquiryLine.Quantity = this.EditModalInfo.quantity;
                            this.PurchaseEnquiryLine.UnitOfMeasurement = this.EditModalInfo.u_o_m;
                            this.PurchaseEnquiryLine.ServiceDescription = this.EditModalInfo.service_description;
                            this.PurchaseEnquiryLine.AdvancedPayment = this.EditModalInfo.advanced_payment;
                            this.PurchaseEnquiryLine.RetentionPercentage = this.EditModalInfo.retention_percentage;
                            this.PurchaseEnquiryLine.RetentionDays = this.EditModalInfo.retention_days;
                            this.PurchaseEnquiryLine.RequiredDocuments = [];
                            this.PurchaseEnquiryLine.RequiredDocuments = this.EditModalInfo.required_documents;
                            this.ReqDocTmp = [];
                            this.OtherDocTmp = "";
                            this.OtherDocTmpHolder = [];
                            this.ReqDocTmp = this.EditModalInfo.required_documents.split(',');
                            this.PurchaseEnquiryLine.PELineNote = this.EditModalInfo.notes;
                            this.PurchaseEnquiryLine.Longitude = this.EditModalInfo.longitude;
                            this.PurchaseEnquiryLine.Latitude = this.EditModalInfo.latitude;
                            this.PurchaseEnquiryLine.LocationName = this.EditModalInfo.location_name;
                            this.ItemRateContractDetails = [];
                            this.TmpEditModalInfo = Object.assign({}, this.PurchaseEnquiryLine);
                            this.RateContractDataLoaded = false;




                            /*
                             * Google Map inside modal
                             */
                            var initializeMap = function(id) {

                                var myLatLng = {lat: this.PurchaseEnquiryLine.Latitude, lng: this.PurchaseEnquiryLine.Longitude};

                                // Map Options
                                var mapOptions = {
                                    zoom: 10,
                                    center: new google.maps.LatLng(this.PurchaseEnquiryLine.Latitude, this.PurchaseEnquiryLine.Longitude),
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                };


                                // Initialize the map with options (inside #map element)
                                var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);


                                var marker = new google.maps.Marker({
                                    position: myLatLng,
                                    map: map,
                                    draggable: true

                                });


                                google.maps.event.addListener(marker, 'position_changed', function () {
                                    self.PurchaseEnquiryLine.Longitude = marker.getPosition().lat();
                                    self.PurchaseEnquiryLine.Latitude = marker.getPosition().lng();
                                });


                                // When modal window is open, this script resizes the map and resets the map center
                                $("#DeliveryLocation").on("shown.bs.modal", function(e) {
                                  google.maps.event.trigger(map, "resize");
                                  return map.setCenter(myLatLng);
                                });


                                google.maps.event.addDomListener(window, "load", initializeMap("#map-canvas"));

                            }

                        }
                        this.getCountries();
                        this.loadCities();
                        this.loadValidations(response.data)
                        this.editDataLoaded = true;
                        $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    })
                this.editDataLoaded = false;
            },
            EnquiryItemDescriptionSelection(){
                if(this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'Yes'){
                    this.rules.ItemNumber[0].required = true;
                    this.rules.ItemDescription[0].required = false;
                } else if (this.PurchaseEnquiryLine.EnquiryFromItemMaster == 'No') {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = true;
                } else {
                    this.rules.ItemNumber[0].required = false;
                    this.rules.ItemDescription[0].required = false;
                }
                
            },
            PurchaseEnquiryRententionUpdate(){
                let self = this;

                this.$refs.PurchaseRequestRetentionUpdateForm.validate((validation) => {
                    if(validation){

                        axios.post('/api/purchase_enquiry/update_purchase_enquiry_request_by_peo', this.ValidationInfo)
                            .then(function(response){
                                Swal({ 
                                    type: response.data.messageType, 
                                    title: response.data.messageTitle, 
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 10000
                                });

                                 self.DataTable.ajax.reload( null, false );

                            })
                            .catch(function(){
                                Swal('Error Occured', 'A technical error has occured, please the contact system administrator to solve the problem (Update Purchase Request Details)', 'error');
                            });

                            $('#PurchaseEnquiryUpdateModalEdit').modal('toggle');

                    }
                });

            },
            showAllRFIModal(recordId, RecordType, QuotationId) {
                this.RFIData.CompanyId = this.CompanySetup.id
                this.RFIData.QuotationId = QuotationId;
                this.RFIData.PEId = recordId;
                this.RFIData.QuotationType = RecordType;
                this.RFIDetails = [];
                axios.post('/api/data/get_all_pe_rfi_details', {PEId: recordId, QuotationType: RecordType})
                    .then((response) => {
                        this.RFIDetails = response.data;
                        this.RFIDataLoaded = true;
                        // console.log(this.RFIDetails);

                        $('#RFIModal').modal('toggle');

                        this.$nextTick(() => {
                            $('#RFIMessagesTable').DataTable({
                                responsive: true,
                                colReorder: true,
                                dom: '<"html5buttons">Brfgtip',
                                pageLength: 100,
                                lengthMenu: [
                                    [ 100, 500, 1000, -1 ],
                                    [ '100 Records', '500 Records', '1000 Records', 'All Records' ]
                                ],
                                order: [[ 4, "DESC" ]],
                                buttons: [
                                    { extend: 'pageLength', className: 'btn btn-success' },
                                    { extend: 'copy', className: 'btn btn-success' },
                                    { extend: 'excel', className: 'btn btn-success', title: 'QuotationsLinesList' },
                                    { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] }
                                ],
                            }); 
                        });
                    });

                this.RFIDataLoaded = false;
            },
            SubmitRFI(){
                //check if empty
                if(this.RFIData.RFImessage == ""){
                    Swal('Message Required', 'Message field can not be left empty, please type your message here', 'warning')
                    return false;
                } else {
                    axios.post( '/api/quotations_management/create_rfi_message', this.RFIData)
                        .then((response) => {
                            Swal({ 
                                type: response.data.messageType, 
                                title: response.data.messageTitle, 
                                text: response.data.message,
                                showConfirmButton: true,
                            });
                            this.RFIData.RFImessage = "";
                            this.RFIData.RFIMessageID = "";
                        })

                    

                    $('#RFIModal').modal('toggle');
                    this.DataTable.ajax.reload( null, false );
                }         
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        },
        mounted(){
            let self = this;

            var groupColumn = 1;

            axios.get('/api/data/get_company_details')
            .then((response) => {
                // console.log(response.data);
                self.CompanySetup = response.data;
            });

            axios.get('/api/data/get_user_projects_list')
                .then((response) => {
                    this.JobNumbers = response.data;
                });


            setTimeout(() => {

                this.DataTable = $('#ProjectPEList').DataTable({
                    fixedHeader: {
                        header: true,
                        headerOffset: 117
                    },
                    
                    stateSave:  false,
                    responsive: true,
                    colReorder: true,
                    processing: true,
                    autoWidth: true,
                    serverSide: true,
                    ajax: '/api/data/get_purchase_request_list_for_peos',
                    columns: [
                        { data: 'show_id', name: 'show_id' },
                        { data: 'purchase_enquiry_group_id', name: 'purchase_enquiry_group_id' },
                        { data: 'project.title', name: 'project.title' },
                        { data: 'creator.name', name: 'creator.name' },
                        { data: 'level_of_validations', name: 'level_of_validations' },
                        { data: 'complete_description_with_headers', render: function(data, type, full){
                            if(data == null){
                                return '';
                            }else{
                                let dec = data.substr(0,250)+'...';
                                return "<span style='z-index: 0' class='mytooltip tooltip-effect-2'> <span class=''><span class='text-dark' style='z-index: -10000;'>"+dec+"</span></span><span class='tooltip-content4 clearfix' style='width: 35vh'><span class='tooltip-text4'>"+data+"</span></span></span>";
                            }
                        }},
                        { data: 'quantity', name: 'quantity' },
                        { data: 'item.u_o_m', render: function(data){
                            if(data == null){
                                return 'N/A';
                            }else{
                                return data.toUpperCase();
                            }
                        }},
                        { data: 'expected_price', render: function(data, type, full) {
                            if(data > 0)
                                return self.formatPrice(data)+ " " +full.project.currency;
                            else if(full.enquiry_from_item_master == 'No')
                                return "Not Applicable As It Is A Free-Text Described Item (Not An Item From The <i>Library of Materials</i>.)"
                            else
                                return "Not Available."
                        } },
                        
                        { data: 'location_details', name: 'location_details' },
                        { data: 'countryCities', name: 'countryCities' },
                        { data: 'validationList', render: function(data, type, full) {
                            if(data)
                                return data
                            else
                                return "N/A"
                        } },
                        { data: 'advanced_payment', render: function (data, row, full){
                        if(data == 'Yes')
                            if(full.advanced_payment_guarantee == 'Yes')
                                return 'Yes | Collaterals, Against The <i>Advance Payment</i>, Will Be Required.'
                            else
                                return 'Yes | No Collaterals, Against The <i>Advance Payment</i>, Will Be Required.'
                            else
                                return 'Not Applicable.'    

                        } },
                        { data: 'retention_percentage', render: function (data, row, full){
                            if(data > 0)
                                return data +'% | '+full.retention_days+' Day(s) From The Date Of Either Receiving The Total Quantity Specified In The <i>Purchase Order</i>, Or Closing The <i>Purchase Order.</i>'
                            else
                                return "Not Applicable.";
                        } },
                        { data: 'selected_RC', render: function(data, type, full) {
                            if(full.enquiry_from_item_master == 'Yes' && full.expected_price > 0)
                                return "| Vendor Name: "+ self.RateContractSelection[1].vendor_name+ " | Unit Rate: " + self.RateContractSelection[1].unit_rate + " " + full.project.currency + " | Lead Time: " + self.RateContractSelection[1].lead_time + " |";
                            else if(full.enquiry_from_item_master == 'No')   
                                return "This Isn't An Item From The <i>Library of Materials</i>. There Are No <i>Materials Rates’ Contracts</i> For It."
                            else
                                return "No <i>Materials Rates’ Contract</i> Was Selected For This Item."
                        },  orderable: false, searchable: false },
                        { data: 'sourcing_priority', name: 'sourcing_priority' },
                        { data: 'underlying_transaction', name: 'underlying_transaction' },
                        { data: 'required_documents', name: 'required_documents' },
                        { data: 'awardeeVendor', name: 'awardeeVendor' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'updated_at_human', render: function(data, type, full){
                            if(full.updated_at_human == null){
                                return '';
                            }else{
                                return full.updated_at_human.substr(0,200);
                            }
                        }},
                        // { data: 'status', name: 'status' },
                        { data: 'action', name: 'action', orderable: false, searchable: false }
                    ],
                    columnDefs: [
                        {"orderable":false, "targets":9},
                        { visible: false, targets: groupColumn },
                        { width: '30%', targets: 5 },
                        { responsivePriority: 1, targets: 0 },
                        { responsivePriority: 2, targets: 21 },
                        { responsivePriority: 3, targets: 22 },
                    ],
                    dom: '<"html5buttons">Brfgtip',
                    pageLength: 10,
                    lengthMenu: [
                        [ 10, 25, 50, 100, 500, 1000, -1 ],
                        [ '10 Records', '25 Records', '50 Records', '100 Records', '500 Records', '1000 Records', 'All Records' ]
                    ],
                    order: [[ groupColumn, 'ASC' ]],
                    "drawCallback": function ( settings ) {
                        var api = this.api();
                        var rows = api.rows( {page:'current'} ).nodes();
                        var last=null;

                        api.column({page:'current'} ).data().each( function ( group, i ) {
                        var res = group.slice(0, -2);
                            if ( last !== res ) {
                                $(rows).eq( i ).before(
                                    '<tr class="group"><td colspan="12"><i>Materials Purchase Enquiry</i> ID#: '+res+'</td></tr>'
                                );
             
                                last = res;
                            }
                        } );
                    },
                    buttons: [
                        { extend: 'pageLength', className: 'btn btn-success' },
                        { extend: 'copy', className: 'btn btn-success' },
                        { extend: 'excel', className: 'btn btn-success', title: 'PurchaseEnquiriesList '+new Date() },
                        { extend: 'colvis', className: 'btn btn-success', postfixButtons: [ 'colvisRestore' ] },
                        {
                            text: '<i class="fa fa-refresh"></i>',
                            className: 'btn btn-success',
                            action: function ( e, dt, node, config ) {
                                dt.ajax.reload();
                            }
                        },
                    ],
                    "language": {
                        processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                    }, 
                    "rowCallback": function( row, data, index ) {
                        // console.log(data);
                        // if (data.status.indexOf('Rejected') !== -1) {
                        //     $(row).css('background-color', 'rgb(252, 51, 23, 0.3)')
                        // }
                        if(data.action.indexOf('edit-placeholder') !== -1){
                            $(row).css('background-color', 'rgb(249, 177, 69, 0.1)')
                        }
                    } 
                });

                

                $('tbody', this.$refs.table).on('click', '.view-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showViewModal(elquentClass, RecordID);
                });

                $('tbody', this.$refs.table).on('click', '.view-rfi', function(){
                    var $this = $(this);
                    var RecordID = $(this).data('recordid');
                    var RecordType = $(this).data('recordtype');
                    var Quotationid = $(this).data('quotationid');
                    self.showAllRFIModal(RecordID, RecordType, Quotationid);
                });

                $('tbody', this.$refs.table).on('click', '.edit-placeholder', function(){
                    var $this = $(this);
                    var elquentClass = $(this).data('elquentclass');
                    var RecordID = $(this).data('recordid');
                    self.showEditModal(elquentClass, RecordID);
                });

            }, 500);


        },
        beforeRouteLeave (to, from, next) {
            this.DataTable.destroy();
            next();
        }
    }

</script>

<style>
@media print {
    body * {
    visibility: hidden;
  }
  #PurchaseEnquiryUpdateModalView, #PurchaseEnquiryUpdateModalView * {
    visibility: visible;
  }
  #PurchaseEnquiryUpdateModalView::-webkit-scrollbar { width: 0 !important }
  #PurchaseEnquiryUpdateModalView:last-child {
     page-break-after: auto;
    }
   #PurchaseEnquiryUpdateModalView:last-child {
     page-break-before: none;
    }
  #PurchaseEnquiryUpdateModalView {
    position: relative;
    margin-top: -210%;
    height: 100%;
    width: 400mm !important;
  }
  button {
    display: none;
  }
}

.el-switch__label * {
        font-size: 18px;
        font-weight: bold;
    }
.el-switch__label.is-active {
    color: rgb(252, 51, 23);
}

</style>




<style scoped>
    .lomBold {
        font-weight: 500;
    }
    .sptable tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .font-500{
        font-weight: 500;
    }

    .ogeo-background {
        background-color: rgb(252, 51, 23);
    }

    #PurchaseEnquiryUpdateModalView .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    }

    #PurchaseEnquiryUpdateModalEdit .modal-dialog {
        min-width:80%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    }

    #map-canvas {
        width: 100%;
        height: 450px;
        margin: 0 auto;
    }
    
    #DeliveryLocation .modal-dialog {
        width:80%;
    }

    .el-select-dropdown {
        z-index: 20000 !important;
    }

    .el-popper {
        z-index: 20000 !important;
    }

    .el-scrollbar {
        z-index: 20000 !important;
        overflow-y: auto;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }
    .el-scrollbar__wrap{
        z-index: 20000 !important;
        max-width: 1200px !important;
        max-height: 500px !important;
        z-index: 1;
    }

    .ogeo-text{
        color: rgb(252, 51, 23, 1);
    }

</style>