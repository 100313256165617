<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h3>Track Changes to the Organization's settings</h3>
                    <table ref="table" id="OrganizationChanges" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>PE_prefix</th>
                                <th>RFP_prefix</th>
                                <th>CE_prefix</th>
                                <th>PO_prefix</th>
                                <!-- <th>SIN_prefix</th> -->
                                <!-- <th>SRN_prefix</th> -->
                                <th>RN_prefix</th>
                                <th>Customizing numbering</th>
                                <th>PE_transaction</th>
                                <th>SI_transaction</th>
                                <th>PE_material</th>
                                <th>Third Party Vendor</th>
                                <th>Date Time</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <hr>
                <div class="card-body">
                    <h3>Track Changes to the Setup's settings</h3>
                    <table ref="tableSetup" id="SetupHistory" class="responsive table table-bordered table-hover"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Change ID</th>
                                <th>DOA criterion applicable to PO</th>
                                <th>DOA criterion applicable to RN</th>
                                <th>Proposals’ sourcing methodology</th>
                                <th>Terms & Conditions for Procuremen</th>
                                <th>Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr v-for="(ProjectDetails, key, index) in ProjectsDetails" :key="index">
                                <td>{{ ProjectDetails.ProjectTitle }}</td>
                                <td width="50px">{{ ProjectDetails.StoreSetup }}</td>
                                <td><b>Start Date: </b>{{ ProjectDetails.ProjectPeriod[0] | formatDateNoTime }}<br><b>End Date: </b>{{ ProjectDetails.ProjectPeriod[1] | formatDateNoTime }}</td>
                                <td align="right">{{ formatPrice(ProjectDetails.ProjectValue) }} {{ ProjectDetails.ProjectCompanyCurrency }}</td>
                                <td align="right">{{ formatPrice(ProjectDetails.ConsumedBudget) }} {{ ProjectDetails.ProjectCompanyCurrency }}</td>
                                <td align="right">{{ formatPrice(ProjectDetails.ProjectValue - ProjectDetails.ConsumedBudget) }} {{ ProjectDetails.ProjectCompanyCurrency }}</td>
                                <td width="18%">
                                    <a class="btn btn-success float-left" href="#" @click="showProjectDetails(key, $event)"><i class="fa fa-edit"></i></a>
                                    <a class="btn btn-primary float-left m-l-5" href="#" @click="ShowRoles(key, $event)"><i class="fa fa-group"></i></a> 
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                // DataTable: "",  
            }
        },
        methods: {
            
        },
        mounted() {
            var groupColumn = 1;
            const table = $('#OrganizationChanges').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/users/track_organization_changes',
                columns: [
                    {
                        data: 'id',
                        name: 'id'
                    },
                    {
                        data: 'PE_prefix',
                        name: 'PE_prefix'
                    },
                    {
                        data: 'RFP_prefix',
                        name: 'RFP_prefix'
                    },
                    {
                        data: 'CE_prefix',
                        name: 'CE_prefix'
                    },
                    {
                        data: 'PO_prefix',
                        name: 'PO_prefix'
                    },
                    // {
                    //     data: 'SIN_prefix',
                    //     name: 'SIN_prefix'
                    // },
                    // {
                    //     data: 'SRN_prefix',
                    //     name: 'SRN_prefix'
                    // },
                    {
                        data: 'RN_prefix',
                        name: 'RN_prefix'
                    },
                    {
                        data: 'alternative_numbering',
                        name: 'alternative_numbering'
                    },
                    {
                        data: 'PE_transaction',
                        name: 'PE_transaction'
                    },
                    {
                        data: 'SI_transaction',
                        name: 'SI_transaction'
                    },
                    {
                        data: 'PE_materials',
                        name: 'PE_materials'
                    },
                    {
                        data: 'third_party_vendor',
                        name: 'third_party_vendor'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [
                    {"orderable":false, "targets":9},
                    { width: '30%', targets: 6 },
                    { responsivePriority: 1, targets: -1 },
                    { responsivePriority: 2, targets: 0 },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 7,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [groupColumn, 'ASC']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
            const table2 = $('#SetupHistory').DataTable({
                stateSave: true,
                responsive: true,
                colReorder: true,
                processing: true,
                serverSide: true,
                ajax: '/api/users/track_set_up_changes',
                columns: [
                    {
                        data: 'id',
                        name: 'id'
                    },
                    {
                        data: 'doa_po',
                        name: 'doa_po'
                    },
                    {
                        data: 'doa_rn',
                        name: 'doa_rn'
                    },
                    {
                        data: 'proposal_sourcing_method',
                        name: 'proposal_sourcing_method'
                    },
                    {
                        data: 'term_conditions',
                        name: 'term_conditions'
                    },
                    {
                        data: 'created_at',
                        name: 'created_at'
                    },
                ],
                columnDefs: [
                    { responsivePriority: 1, targets: -1 },
                    { responsivePriority: 2, targets: 0 },
                ],
                dom: '<"html5buttons">Brfgtip',
                pageLength: 7,
                lengthMenu: [
                    [10, 25, 50, 100, 500, 1000, -1],
                    ['10 Records', '25 Records', '50 Records', '100 Records', '500 Records',
                        '1000 Records', 'All Records'
                    ]
                ],
                order: [
                    [groupColumn, 'ASC']
                ],
                buttons: [{
                        extend: 'pageLength',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'copy',
                        className: 'btn btn-success'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-success',
                        title: 'RateContractRequests'
                    },
                    {
                        extend: 'colvis',
                        className: 'btn btn-success',
                        postfixButtons: ['colvisRestore']
                    },
                    {
                        text: '<i class="fa fa-refresh"></i>',
                        className: 'btn btn-success',
                        action: function (e, dt, node, config) {
                            dt.ajax.reload();
                        }
                    },
                ],
                "language": {
                    processing: 'Loading Data... <i class="fa fa-spinner fa-spin fa-fw"></i>'
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                },
            });
        },
    }

</script>
